import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  createdDate: (
    <FormattedMessage
      id="comments.CreatedDate"
      defaultMessage="Created Date"
    ></FormattedMessage>
  ),
  contactName: (
    <FormattedMessage
      id="comments.ContactName"
      defaultMessage="Contact Name"
    ></FormattedMessage>
  ),
  comments: (
    <FormattedMessage
      id="comments.Comments"
      defaultMessage="Comments"
    ></FormattedMessage>
  ),
  edit: (
    <FormattedMessage
      id="comments.edit"
      defaultMessage="Edit"
    ></FormattedMessage>
  ),
  delete: (
    <FormattedMessage
      id="comments.delete"
      defaultMessage="Delete"
    ></FormattedMessage>
  ),
  loadingText: (
    <FormattedMessage
      id="comments.loading"
      defaultMessage="Loading"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
