import React, { Fragment } from 'react';
import { Collapse } from 'react-bootstrap';
import { divide } from '../../../../utils/maths';

const ListItem = ({
  id,
  date = '',
  smsId = '',
  dateRelative = '',
  logLevel = '',
  origTn = 'N/A',
  destTn = 'N/A',
  result = '',
  onClickSmsId = null,
  onClickAuth = null,
  onClickResult = null,
  collapse = false,
  jsonObject = {},
  tag = '',
}) => {
  let message = JSON.stringify(jsonObject, null, 4);
  const dateRelativeInSeconds = divide(dateRelative, 1000);
  return (
    <Fragment>
      <tr key={`list${id}`} id={id} onClick={() => onClickResult(id)}>
        <td className="column-right column-left" name="date">
          <div className="icon-inline">
            <i className="material-icons">
              {collapse ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
            </i>
            <span> &nbsp; {date} </span>
          </div>
        </td>
        <td className="" name="smsId">
          {formatUUID(smsId, tag, onClickSmsId)}
        </td>
        <td className="" name="originTn">
          <span>{origTn}</span>
        </td>
        <td className="" name="destTn">
          <span>{destTn}</span>
        </td>
        <td className="" name="processingTime">
          <span>{dateRelativeInSeconds + ' secs'}</span>
        </td>
        <td className="column-right" name="result">
          {formatResult(logLevel, onClickAuth, result)}
        </td>
      </tr>
      <tr></tr>
      <tr
        className={
          'collapsible-table column-right column-left' +
          (collapse ? ' column-bottom' : ' column-bottom-hidden')
        }
      >
        <td colSpan="7">
          <Collapse in={collapse}>
            <div>
              <div>{message}</div>
            </div>
          </Collapse>
        </td>
      </tr>
    </Fragment>
  );
};

/**
 * A function that helps format the contents of the last column based on whether it is an
 * error and whether the auth modal exists.
 * @param {String} logLevel string that holds the log level
 * @param {function} onClickAuth function to display auth modal
 * @param {String} result string to display for result (log message)
 * @returns JSX for the last column
 */
const formatResult = (logLevel, onClickAuth, result) => {
  let textClass = logLevel === 'Error' ? 'red' : null;
  if (onClickAuth) {
    if (textClass === null) textClass = 'blue';
    textClass += ' underline';
    return (
      <span className={textClass} onClick={() => onClickAuth()}>
        {result}
      </span>
    );
  }
  return <span className={textClass}>{result}</span>;
};

/**
 * A convenience function that returns what to display for the 'SMS id' column
 * @param {String} smsId
 * @param {String} tag
 * @returns text for the sms id depending on whether it exists or not
 */
const formatUUID = (smsId, tag, onClickSmsId) => {
  let text;
  let textClass = '';
  if (smsId && smsId !== undefined) {
    textClass = 'blue';
    text = smsId;
  } else if (tag === 'AUTH_FINAL') text = 'N/A';
  else {
    textClass = 'red';
    text = 'missing';
  }
  return (
    <span className={textClass} onClick={() => onClickSmsId(smsId)}>
      {text}
    </span>
  );
};

export default ListItem;
