import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  cardDetails: (
    <FormattedMessage id="Global.CardDetails" defaultMessage="Card Details" />
  ),
  cardName: (
    <FormattedMessage id="Global.CardName" defaultMessage="Card Name" />
  ),
  cardEnding: (
    <FormattedMessage id="Global.CardEnding" defaultMessage="Card Ending" />
  ),
  cardNumber: (
    <FormattedMessage id="Global.CardNumber" defaultMessage="Card Number" />
  ),
  cardExpiry: (
    <FormattedMessage id="Global.CardExpiry" defaultMessage="Card Expiry" />
  ),
  cardCVV: <FormattedMessage id="Global.CardCVV" defaultMessage="Card CVV" />,
  replaceCard: (
    <FormattedMessage id="Global.ReplaceCard" defaultMessage="Replace Card" />
  ),
  addCard: <FormattedMessage id="Global.AddCard" defaultMessage="Add Card" />,
  saveCard: (
    <FormattedMessage id="Global.SaveCard" defaultMessage="Save Card" />
  ),
};

export default FormattedMessages;
