/*
  DeviceRequest.js - Service Config - Device Networking File
  
  Contains networking functionality for Service Config - Device Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../../RequestRabbit';

// CONFIGURATION
import config from '../../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class DeviceRequest {
  /*
    Gets ALL Devices within the system
  */
  getAllDevices = async (
    isCurrentlyDedicated,
    isCurrentlyPooled,
    errorHandler
  ) => {
    console.log('edits', isCurrentlyDedicated, isCurrentlyPooled);
    let url = `${config.baseServerURL}/admin/device?isCurrentlyDedicated=${isCurrentlyDedicated}`;
    if (isCurrentlyPooled)
      url = url + `&isCurrentlyPooled=${isCurrentlyPooled}`;
    const options = {
      method: 'GET',
    };
    // Send request
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Returns any Devices that match the set of criteria from the search form.
  */
  searchDevices = async (data, errorHandler) => {
    const url = `${config.baseServerURL}/admin/device/search`;
    console.log('Got data:', data);
    const body = JSON.stringify(data);
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      body: body,
      headers: headers,
    };

    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  getStatusTypeClassData = async (errorHandler) => {
    const url = `${config.baseServerURL}/admin/device/codes`;
    const options = {
      method: 'GET',
    };
    // Send request
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Creates a new Device with the specific details provided in the form.
  */
  createDevice = async (newDevice, errorHandler) => {
    const url = `${config.baseServerURL}/admin/device`;
    const body = JSON.stringify(newDevice);
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      body: body,
      headers: headers,
    };

    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Updates the specified Device with the given update object
  */
  updateDevice = async (deviceId, updates, errorHandler) => {
    const url = `${config.baseServerURL}/admin/device/${deviceId}`;
    const body = JSON.stringify(updates);
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      body: body,
      headers: headers,
    };

    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Deletes the Device for the given Id
  */
  deleteDevice = async (deviceId, errorHandler) => {
    let url = `${config.baseServerURL}/admin/device/${deviceId}`;
    const options = {
      method: 'DELETE',
    };

    await requestRabbit.request(url, options, errorHandler);
  };

  /*
    Returns a list of all the Carriers that a given device Id can be attached to.
  */
  getAllSelectableCarriers = async (deviceId, errorHandler) => {
    const url = `${config.baseServerURL}/admin/device/${deviceId}/carrier`;
    const options = {
      method: 'GET',
    };
    // Send request
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Creates new Carrier Device mappings for the given Carrier and Device Ids and
    save method (this provider, all dependents or all providers)
  */
  createNewDeviceCarrierDevice = async (
    deviceId,
    deviceType,
    carrierId,
    saveType,
    errorHandler
  ) => {
    const url = `${config.baseServerURL}/admin/device/${deviceId}/carrier`;
    const body = JSON.stringify({
      carrier_id: carrierId,
      save_type: saveType,
      device_type: deviceType,
    });
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      body: body,
      headers: headers,
    };

    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };
}
