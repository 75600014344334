import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  expiredCardsTitle: (
    <FormattedMessage
      id="ExpiredCardsReport.Title"
      defaultMessage="List of Expired Cards"
    />
  ),
  account: (
    <FormattedMessage
      id="ExpiredCardsReport.account"
      defaultMessage="Account"
    />
  ),

  name: <FormattedMessage id="ExpiredCardsReport.name" defaultMessage="Name" />,
  email: (
    <FormattedMessage id="ExpiredCardsReport.email" defaultMessage="Email" />
  ),
  phone: (
    <FormattedMessage id="ExpiredCardsReport.phone" defaultMessage="Phone" />
  ),
  nameOnCard: (
    <FormattedMessage
      id="ExpiredCardsReport.nameOnCard"
      defaultMessage="Name on Credit Card"
    />
  ),
  cardType: (
    <FormattedMessage
      id="ExpiredCardsReport.cardType"
      defaultMessage="Card Type"
    />
  ),
  cardNumber: (
    <FormattedMessage
      id="ExpiredCardsReport.cardNumber"
      defaultMessage="Card Number"
    />
  ),
  expiry: (
    <FormattedMessage
      id="ExpiredCardsReport.ExpiryDate"
      defaultMessage="ExpiryDate"
    />
  ),
  loading: <FormattedMessage id="Global.Loading" defaultMessage="Loading..." />,
};

export default FormattedMessages;
