import React from 'react';

const ListItem = ({
  id,
  name,
  provider,
  activeUsers,
  Direct = { sent: 0, parts: 0 },
  Standard = { sent: 0, parts: 0 },
  Replies = { sent: 0, parts: 0 },
  Sent = { sent: 0, parts: 0 },
  onClickName,
}) => (
  <tr key={'list' + id} id={id} className="">
    <td className="medium textLeft">
      <span className="blue" onClick={() => onClickName(id)}>
        {id}
      </span>
    </td>
    <td className="medium textLeft">
      <span className="blue" onClick={() => onClickName(id)}>
        {name}
      </span>
    </td>
    <td className="medium textLeft">
      <span>{provider}</span>
    </td>
    <td className="medium">
      <span>{activeUsers}</span>
    </td>
    <td className="medium column-left">
      <span>{Standard.sent + ` (${Standard.parts})`}</span>
    </td>
    <td className="medium">
      <span>{Direct.sent + ` (${Direct.parts})`}</span>
    </td>
    <td className="medium column-right">
      <span>{Replies.sent + ` (${Replies.parts})`}</span>
    </td>
    <td className="medium">
      <span>{Sent.sent + ` (${Sent.parts})`}</span>
    </td>
  </tr>
);

export default ListItem;
