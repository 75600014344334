import React from 'react';

const ListItem = ({
  id,
  list,
  onClick,
  name = '',
  email = '',
  phone = '',
  ...props
}) => (
  <tr key={list + id} id={id} className="table-click" onClick={onClick}>
    <td>{name}</td>
    <td>{email}</td>
    <td>{phone}</td>
    <td>
      <i id={id + 'icon'} className="material-icons floatRight">
        keyboard_arrow_right
      </i>
    </td>
  </tr>
);

export default ListItem;
