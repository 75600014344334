import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  createNewButton: (
    <FormattedMessage
      id="Websms.CreateNewButton"
      defaultMessage="Create New"
    ></FormattedMessage>
  ),
  personalTemplateTitle: (
    <FormattedMessage
      id="Websms.PersonalTemplateTitle"
      defaultMessage="Personal Templates"
    ></FormattedMessage>
  ),
  searchByName: (
    <FormattedMessage
      id="Websms.SearchByName"
      defaultMessage="Search Template By Name"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
