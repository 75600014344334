// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Table, Button, FormControl } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Paginator from '../../../../components/Paginator';

// COMPONENTS
import ListItem from '../ListItem';

// CONFIG
import config from '../../../../Config';

export default class PersonalList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      pageNumber: 1,
      contactSearch: '',
    };
    this.pageLength = config.defaultPL;
  }

  /*
    Handles the change event when clicking a pagination number
  */
  changeEvent = (i) => {
    return this.setState({
      pageNumber: i + 1,
    });
  };

  returnListItem = (element) => {
    this.props.callBack(element);
  };

  /*
    Handles changes in the form
  */
  handleContactSearchChange = async (event) => {
    this.setState({
      contactSearch: event.target.value,
      pageNumber: 1,
    });
    this.resetFunction();
  };

  generateContactSearch = () => {
    let searchContact = (
      <FormattedMessage
        id="Websms.SearchContact"
        defaultMessage="Search Contacts By Name or Email Address or Number"
      />
    );
    let importContacts = (
      <FormattedMessage
        id="Websms.ImportContacts"
        defaultMessage="Import Contacts"
      />
    );
    let exportContacts = (
      <FormattedMessage
        id="Websms.ExportContacts"
        defaultMessage="Export Contacts"
      />
    );
    return (
      <Fragment>
        <div className="main">
          <h5>{searchContact}</h5>
          <FormControl
            id="contactSearch"
            autoFocus
            type="text"
            defaultValue=""
            onChange={this.handleContactSearchChange}
          />
          <Button
            className="maxHeight"
            onClick={this.props.showContactImporter}
          >
            {importContacts}
          </Button>
          <Button className="maxHeight" onClick={this.props.exportContacts}>
            {exportContacts}
          </Button>
          <br />
          <br />
        </div>
      </Fragment>
    );
  };

  handleSearchFilter = () => {
    let list = this.props.list;
    if (this.state.contactSearch) {
      list = this.props.list.filter((contact) => {
        return `${contact.number} ${contact.entry_name} ${contact.email}`
          .toLowerCase()
          .includes(this.state.contactSearch.toLowerCase());
      });
    }
    console.log('list', list);
    return list;
  };

  /*
    Generate the table for Contacts
  */
  generateTableRows = (list) => {
    try {
      let fullList = [];
      let { pageNumber } = this.state;

      if (list && typeof list !== 'undefined' && Array.isArray(list)) {
        let displayList = list.slice(
          (pageNumber - 1) * this.pageLength,
          pageNumber * this.pageLength - 1 + 1
        );
        for (let i = 0; i < displayList.length; i++) {
          let element = displayList[i];
          fullList.push(
            <ListItem
              onClick={() => this.returnListItem(element)}
              list="p"
              key={list.indexOf(element)}
              id={element.addressbook_id}
              name={element.entry_name}
              email={element.email}
              phone={element.number}
            />
          );
        }
      }
      return fullList;
    } catch (e) {
      return this.setState({
        hasError: true,
        error: 'Network Error',
      });
    }
  };

  /*
   Generate the table for Contacts
 */
  generateMobileTableRows = (list) => {
    try {
      let fullList = [];
      let { pageNumber } = this.state;

      if (list && typeof list !== 'undefined' && Array.isArray(list)) {
        let displayList = list.slice(
          (pageNumber - 1) * this.pageLength,
          pageNumber * this.pageLength - 1 + 1
        );
        for (let i = 0; i < displayList.length; i++) {
          let element = displayList[i];
          fullList.push(
            <tr
              key={list.indexOf(element)}
              id={element.addressbook_id}
              className="global-list-row"
              valign="top"
            >
              <td>
                <b>{element.entry_name}</b>
                <br />
                {element.email}
                <br />
                {element.number}
              </td>
              <td
                className="textRight smallText"
                onClick={() => this.returnListItem(element)}
              >
                edit
              </td>
            </tr>
          );
        }
      }
      return fullList;
    } catch (e) {
      return this.setState({
        error: 'Network Error',
      });
    }
  };

  render() {
    let NameText = (
      <FormattedMessage id="Websms.NameText" defaultMessage="Name" />
    );
    let EmailText = (
      <FormattedMessage id="Websms.EmailText" defaultMessage="Email Address" />
    );
    let MobileText = (
      <FormattedMessage id="Websms.MobileText" defaultMessage="Mobile Number" />
    );
    let CreateNewButton = (
      <FormattedMessage id="Websms.CreateNewButton" defaultMessage="Add New" />
    );

    let list = this.handleSearchFilter();
    let listLen = list ? list.length : 0;

    return (
      <Fragment>
        <div className="visible-desktop">
          {this.generateContactSearch()}
          <button
            className="btn add-new-template float-right maxHeight"
            onClick={() => this.props.callBack(null)}
          >
            {CreateNewButton}
          </button>
          <br />
          <br />
          <br />
          <Table id="personal-list">
            <thead>
              <tr key="head">
                <th className="medium textLeft tableTop">{NameText}</th>
                <th className="medium textLeft tableTop">{EmailText}</th>
                <th className="medium textLeft tableTop" colSpan="2">
                  {MobileText}
                </th>
              </tr>
            </thead>
            <tbody>{this.generateTableRows(list)}</tbody>
          </Table>
          <Paginator
            itemCount={listLen}
            changePageFunction={(ref) => (this.resetFunction = ref)}
            changeEvent={this.changeEvent}
          />
        </div>
        <div className="visible-device">
          <button
            className="btn add-new-template float-right maxHeight"
            onClick={() => this.props.callBack(null)}
          >
            + {CreateNewButton}
          </button>
          <br />
          <br />
          <table
            id="personal-list"
            border="0"
            cellPadding="5px"
            cellSpacing="0"
            width="100%"
          >
            <thead>
              <tr key="head"></tr>
            </thead>
            <tbody>{this.generateMobileTableRows(list)}</tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}
