// OUR MODULES
import RequestRabbit from '../../RequestRabbit';
import config from '../../../Config';

// INSTANTIATE
const handleRequest = new RequestRabbit();

export default class SearchRequest {
  /*
    Get Report Data for Date Range
  */
  getReportData = async (searchObject, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/report/user/top`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify(searchObject) || null,
        headers: headers,
      };
      console.log('searchObject');
      console.log(searchObject);
      // Request data
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      console.log(responseJSON);
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };

  /*
  Get Report Data for a Single User
*/
  userReport = async (id, errorHandler) => {
    const url = `${config.baseServerURL}/reports/users/${id}`;
    const header = {
      'Content-Type': 'application/json',
    };
    let options = {
      method: 'get',
      headers: header,
    };
    try {
      // Request data
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      //Error catching
      errorHandler(e);
    }
  };

  /*
  Gets all Sent Messages for a given User and type.  Type being Direct,
  Standard and Replies
*/
  getMessagesForId = async (userId, type, fromDate, toDate, errorHandler) => {
    try {
      let url = `${config.baseServerURL}/sentsms/?user_id=${userId}&type=${type}`;
      if (fromDate) url += `&fromDate=${fromDate}`;
      if (toDate) url += `&toDate=${toDate}`;
      const options = {
        method: 'GET',
      };
      // Request data
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      console.log('Get messages for', type, ', got response:', responseJSON);
      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };
}
