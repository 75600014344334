/*
  Message.js - Index Component for Message Reports

*/

// NPM
import React, { Component, Fragment } from 'react';
import { Form, Table } from 'react-bootstrap';
import Paginator from '../../../components/Paginator';

// COMPONENTS
import SingleView from './Single';
import DateRangeSelector from '../../../components/DateRangeSelector';
import ReportTabs from '../index';

// CONFIG
import config from '../../../Config';

// UTILS
import { getZoneOffsettedDate } from '../../../utils/dates';

// NETWORKING
import MessageReportRequest from './MessageReportRequest';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';
import { errorCodes } from '../../../utils/errorCodes';

// INSTANTIATE
const messageReportRequest = new MessageReportRequest();

export default class Message extends Component {
  constructor(props) {
    super(props);

    console.log('Got MessageReport props:', props);

    this.state = {
      data: [],
      paginatedData: [],
      isSearching: true,
      selectedData: null,
      open: false,
      currentlySearching: {},
      searchParams: null,
      fromFilter: null,
      toFilter: null,
      timeRange: null,
      typeFilter: null,
      userFilter: null,
      statusFilter: null,
      errorFilter: null,
      selectorKeyRefresh: false,
      pageLength: config.defaultPL,
    };
  }

  /*
    Handles searching User Data between two dates
  */
  handleMessageDataSearch = (fromDate, toDate, timeRange) => {
    try {
      this.setState(
        {
          data: [],
          isSearching: true,
        },
        async () => {
          let searchParams = {
            fromDate: fromDate,
            toDate: toDate,
            timeRange: timeRange,
            timeRangeStart: fromDate,
            timeRangeEnd: toDate,
            typeFilter: this.state.typeFilter,
            userFilter: this.state.userFilter,
            fromFilter: this.state.fromFilter,
            toFilter: this.state.toFilter,
            statusFilter: this.state.statusFilter,
            errorFilter: this.state.errorFilter,
          };
          console.log('searchParams', searchParams);
          let data = await messageReportRequest.getMessageReportData(
            searchParams,
            this.props.errorHandler
          );
          console.log('data', data);

          this.setState(
            {
              data: data,
              currentlySearching: searchParams,
              isSearching: false,
              pageNumber: 1,
            },
            this.setPaginatedResults
          );
        }
      );
    } catch (e) {
      console.error(e);
      return this.setState({
        data: e.message,
        isSearching: false,
      });
    }
  };

  /* 
  Changes the amount of entries per pagination page. 
  */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState(
        { pageLength: value, pageNumber: 1 },
        this.setPaginatedResults
      );
    }
  };

  setPaginatedResults = async () => {
    let { data, pageNumber, pageLength } = this.state;
    const newData = data.slice(
      (pageNumber - 1) * pageLength,
      pageNumber * pageLength
    );
    this.setState({
      paginatedData: newData,
    });
  };

  /*
    Handles the change event when clicking a pagination number
  */
  changeEvent = async (i) => {
    this.setState(
      {
        pageNumber: i + 1,
      },
      this.setPaginatedResults
    );
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div className="lander">
        <h3>{formattedMessages.messageReportsTitle}</h3>
      </div>
    );
  };

  toggleMenu = () => this.setState(({ open }) => ({ open: !open }));

  typeDropdown = () => {
    let list = [];
    let messageType = [
      'Binary',
      'Red Alert',
      'Reminder',
      'Reply',
      'Scheduled',
      'Standard',
    ];

    if (!this.state.typeFilter)
      list.push(
        <option disabled selected hidden key={null} value>
          Select Type
        </option>
      );
    for (let i = 0; i < messageType.length; i++) {
      list.push(
        <option key={messageType[i]} value={messageType[i]}>
          {messageType[i]}
        </option>
      );
    }
    return (
      <Form>
        <Form.Control
          as="select"
          name="typeFilter"
          className="filter-control"
          onChange={(e) => this.handleInputChange(e)}
        >
          {list}
        </Form.Control>
      </Form>
    );
  };

  statusDropdown = () => {
    let list = [];
    let sendStatus = [
      'Blacklisted',
      'Cancelled',
      'Delivered',
      'Failed Delivery',
      'Invalid Number',
      'Pending',
      'Processing',
      'Presumed Delivered',
      'Scheduled',
      'Too Many Retries',
    ];

    if (!this.state.statusFilter)
      list.push(
        <option disabled selected hidden key={null} value>
          Select Status
        </option>
      );
    for (let i = 0; i < sendStatus.length; i++) {
      list.push(
        <option key={sendStatus[i]} value={sendStatus[i]}>
          {sendStatus[i]}
        </option>
      );
    }
    return (
      <Form>
        <Form.Control
          as="select"
          name="statusFilter"
          className="filter-control"
          onChange={(e) => this.handleInputChange(e)}
        >
          {list}
        </Form.Control>
      </Form>
    );
  };

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    console.log('handleInputChange event name:', name);
    console.log('handleInputChange event value:', value);

    this.setState({
      [name]: value,
    });
  }

  clearFilters = () => {
    this.setState({
      typeFilter: null,
      userFilter: null,
      fromFilter: null,
      toFilter: null,
      statusFilter: null,
      errorFilter: null,
    });
    document.getElementById('filter-form').reset();
  };

  /*
    Generates the Search Tools for querying the data
  */
  generateSearchTools = () => {
    // Have Sent SMS selected, hide search
    if (this.state.selected) return null;

    const { open, currentlySearching, selectorKeyRefresh } = this.state;

    // Destructures which filters the table is currently displaying fields on.
    let {
      typeFilter,
      userFilter,
      fromFilter,
      toFilter,
      statusFilter,
      errorFilter,
    } = currentlySearching || {};

    let filterText = [];
    let values = ['Type:', 'User:', 'From:', 'To:', 'Status:', 'Error'];

    // Pushes spans to an array for rendering. Iterates to avoid code duplication.
    [
      typeFilter,
      userFilter,
      fromFilter,
      toFilter,
      statusFilter,
      errorFilter,
    ].map((filter, i) => {
      if (filter)
        filterText.push(
          <span>
            {filterText.length === 0 ? '' : '; '} <b>{values[i]}</b> {filter}
          </span>
        );
    });

    return (
      <Fragment>
        <DateRangeSelector
          key={selectorKeyRefresh ? 1 : 0} // Changing the key remounts it
          handleDateSearch={(fromDate, toDate, timeRange) =>
            this.handleMessageDataSearch(fromDate, toDate, timeRange)
          }
        ></DateRangeSelector>

        <div className="Home">
          <div className="lander">
            <br />
            <span className="showHideLink-left">
              <span className="advanced-filters" onClick={this.toggleMenu}>
                {open ? (
                  <i className="material-icons">keyboard_arrow_down</i>
                ) : (
                  <i className="material-icons">keyboard_arrow_right</i>
                )}
                Advanced Filters
              </span>

              <div className="clear-filters" onClick={this.clearFilters}>
                {formattedMessages.clear}
              </div>
            </span>

            {open && (
              <div>
                <Form id="filter-form">
                  <table className="no-table-row-background">
                    <tbody className="filter-table">
                      <div className="filter">
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Type:
                            </Form.Label>
                          </td>
                          <td>
                            <div>{this.typeDropdown()}</div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              User:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="userFilter"
                                id="userFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search User"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              From:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="fromFilter"
                                id="fromFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Number"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              To:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="toFilter"
                                id="toFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Number"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Status:
                            </Form.Label>
                          </td>
                          <td>
                            <div>{this.statusDropdown()}</div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Error:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="errorFilter"
                                id="errorFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Error"
                              />
                            </div>
                          </td>
                        </div>
                      </div>
                    </tbody>
                  </table>
                </Form>
                <br></br>
              </div>
            )}
            <div className="flex-between">
              {filterText.length > 0 && <div>{filterText}</div>}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  generateTable = () => {
    if (this.state.isSearching) {
      return <div>{formattedMessages.searchingText}</div>;
    } else if (this.state.data.length === 0) {
      return (
        <div>
          <span>No results found.</span>
        </div>
      );
    }

    return (
      <div className="messageReportsTable">
        <Table className="table-new">
          <thead>
            <tr>
              <td className="medium tableTop textLeft">
                {formattedMessages.dateText}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.typeText}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.userText}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.fromText}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.toText}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.countryText}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.statusText}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.errorCode}
              </td>
              <td className="medium tableTop textLeft">
                {formattedMessages.errorText}
              </td>
            </tr>
          </thead>
          <tbody>{this.generateTableRows()}</tbody>
        </Table>
        <Paginator
          itemCount={this.state.data.length}
          changeEvent={this.changeEvent}
          pageLength={this.state.pageLength}
          editFunction={this.changePageLength}
        />
      </div>
    );
  };

  /*
    Loops and generates Table rows from the data
  */
  generateTableRows = () => {
    let { paginatedData } = this.state;
    console.log(paginatedData);
    let rows = [];

    for (let i = 0; i < paginatedData.length; i++) {
      console.log('data[i]', paginatedData[i]);
      let row = paginatedData[i];

      // If error code present, retrieves the error code's meaning from the hardcoded error codes util file (on the frontend)
      let errorMeaning = 'N/A';
      if (row.errorCode && row.errorCode !== 'N/A')
        errorMeaning = errorCodes(row.errorCode).meaning;

      const rezonedDate = getZoneOffsettedDate(row.smsDate);
      rows.push(
        <tr key={i} id={row.entryId} className="default-cursor">
          <td className="medium column-left textLeft">{rezonedDate}</td>
          <td className="medium column-left textLeft">{row.type}</td>
          <td className="medium column-left textLeft">{row.userEmail}</td>
          <td className="medium column-left textLeft">{row.originator}</td>
          <td
            className="medium column-left textLeft blue"
            onClick={() =>
              this.setState({
                selectedData: {
                  smsId: row.entryId,
                  isEmailReply: row.isEmailReply,
                },
              })
            }
          >
            {row.dest_tn}
          </td>
          <td className="medium column-left textLeft">
            {row.country_code_name}
          </td>
          <td className="medium column-left textLeft"> {row.status}</td>
          <td className="medium column-left textLeft">{row.errorCode}</td>
          <td className="medium column-left textLeft">{errorMeaning}</td>
        </tr>
      );
    }
    return rows;
  };

  render() {
    let { accessLevel, accessType } = this.props;
    if (accessLevel <= '0' || accessType === '0') {
      return null;
    }

    if (this.state.selectedData) {
      return (
        <ReportTabs
          tab="message"
          accessLevel={accessLevel}
          accessType={accessType}
        >
          <SingleView
            selected={this.state.selectedData}
            backButtonClick={() => this.setState({ selectedData: null })}
            errorHandler={this.props.errorHandler}
          />
        </ReportTabs>
      );
    }

    return (
      <ReportTabs
        tab="message"
        accessLevel={accessLevel}
        accessType={accessType}
      >
        <div className="Home">
          {this.generateLander()}
          {this.generateSearchTools()}
          {this.generateTable()}
        </div>
      </ReportTabs>
    );
  }
}
