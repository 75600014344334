import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  customerReportsTitle: (
    <FormattedMessage
      id="Reports.CustomerTitle"
      defaultMessage="Customer Reports"
      tagName="div"
    ></FormattedMessage>
  ),
  accountText: (
    <FormattedMessage
      id="Global.AccountText"
      defaultMessage="Account:"
    ></FormattedMessage>
  ),
  backText: (
    <FormattedMessage
      id="Global.BackText"
      defaultMessage="Back"
    ></FormattedMessage>
  ),
  messagesText: (
    <FormattedMessage
      id="ReportsList.Messages"
      defaultMessage="Messages"
    ></FormattedMessage>
  ),
  whenText: (
    <FormattedMessage
      id="ReportsList.When"
      defaultMessage="When"
    ></FormattedMessage>
  ),
  dayText: (
    <FormattedMessage
      id="ReportsList.Day"
      defaultMessage="Day"
    ></FormattedMessage>
  ),
  dateText: (
    <FormattedMessage
      id="ReportsList.Date"
      defaultMessage="Date"
    ></FormattedMessage>
  ),
  timeText: (
    <FormattedMessage
      id="ReportsList.Time"
      defaultMessage="Time"
    ></FormattedMessage>
  ),
  statusText: (
    <FormattedMessage
      id="ReportsList.Status"
      defaultMessage="Status"
    ></FormattedMessage>
  ),
  phoneNumberText: (
    <FormattedMessage
      id="ReportsList.PhoneNumber"
      defaultMessage="Phone Number"
    ></FormattedMessage>
  ),
  countryText: (
    <FormattedMessage
      id="ReportsList.Country"
      defaultMessage="Country"
    ></FormattedMessage>
  ),
  localFlagText: (
    <FormattedMessage
      id="ReportsList.LocalFlag"
      defaultMessage="Local Flag"
    ></FormattedMessage>
  ),
  quantityText: (
    <FormattedMessage
      id="ReportsList.Quantity"
      defaultMessage="Quantity"
    ></FormattedMessage>
  ),
  creditsUsedText: (
    <FormattedMessage
      id="ReportsList.CreditsUsed"
      defaultMessage="Credits Used"
    ></FormattedMessage>
  ),
  dataText: (
    <FormattedMessage
      id="ReportsList.Data"
      defaultMessage="Data"
    ></FormattedMessage>
  ),
  typeText: (
    <FormattedMessage
      id="ReportsList.Type"
      defaultMessage="Type"
    ></FormattedMessage>
  ),
  customerRef: (
    <FormattedMessage
      id="Websms.CustomerRef"
      defaultMessage="Customer Reference No"
    ></FormattedMessage>
  ),
  defaulOriginator: (
    <FormattedMessage
      id="Websms.DefaulOriginator"
      defaultMessage="Default Originator"
    ></FormattedMessage>
  ),
  typeClass: (
    <FormattedMessage
      id="Websms.TypeClass"
      defaultMessage="Type - Class"
    ></FormattedMessage>
  ),
  status: (
    <FormattedMessage
      id="Websms.Status"
      defaultMessage="Status"
    ></FormattedMessage>
  ),
  active: (
    <FormattedMessage
      id="Websms.Active"
      defaultMessage="Active"
    ></FormattedMessage>
  ),
  cancelled: (
    <FormattedMessage
      id="Websms.Cancelled"
      defaultMessage="Cancelled"
    ></FormattedMessage>
  ),
  pendingCancelled: (
    <FormattedMessage
      id="Websms.PendingCancelled"
      defaultMessage="Pending Cancelled"
    ></FormattedMessage>
  ),
  suspended: (
    <FormattedMessage
      id="Websms.Suspended"
      defaultMessage="Suspended"
    ></FormattedMessage>
  ),
  salesAgent: (
    <FormattedMessage
      id="Websms.SalesAgent"
      defaultMessage="Sales Agent"
    ></FormattedMessage>
  ),
  tariffPlan: (
    <FormattedMessage
      id="Websms.TariffPlan"
      defaultMessage="Tariff Plan"
    ></FormattedMessage>
  ),
  country: (
    <FormattedMessage
      id="Websms.Country"
      defaultMessage="Country"
    ></FormattedMessage>
  ),
  contractId: (
    <FormattedMessage
      id="Websms.ContractId"
      defaultMessage="Contract Form Id"
    ></FormattedMessage>
  ),
  language: (
    <FormattedMessage
      id="Websms.Language"
      defaultMessage="Language on Web Pages"
    ></FormattedMessage>
  ),
  activeCreation: (
    <FormattedMessage
      id="Websms.ActiveCreation"
      defaultMessage="Active Creation"
    ></FormattedMessage>
  ),
  redAlertEnabled: (
    <FormattedMessage
      id="Websms.RedAlertEnabled"
      defaultMessage="Red Alert Enabled"
    ></FormattedMessage>
  ),
  gSTApplicable: (
    <FormattedMessage
      id="Websms.GSTApplicable"
      defaultMessage="GST Applicable"
    ></FormattedMessage>
  ),
  oMSFlag: (
    <FormattedMessage
      id="Websms.OMSFlag"
      defaultMessage="Outlook Mobile Service Flag"
    ></FormattedMessage>
  ),
  receiptFlag: (
    <FormattedMessage
      id="Websms.ReceiptFlag"
      defaultMessage="Receipt Flag"
    ></FormattedMessage>
  ),
  employeeTrustFlag: (
    <FormattedMessage
      id="Websms.EmployeeTrustFlag"
      defaultMessage="Employee Trust Flag"
    ></FormattedMessage>
  ),
  messagePriority: (
    <FormattedMessage
      id="Websms.MessagePriority"
      defaultMessage="Message Priority"
    ></FormattedMessage>
  ),
  low: (
    <FormattedMessage id="Websms.Low" defaultMessage="Low"></FormattedMessage>
  ),
  med: (
    <FormattedMessage
      id="Websms.Medium"
      defaultMessage="Medium"
    ></FormattedMessage>
  ),
  high: (
    <FormattedMessage id="Websms.High" defaultMessage="High"></FormattedMessage>
  ),
  smsInCred: (
    <FormattedMessage
      id="Websms.SmsInCred"
      defaultMessage="SMS In Credit"
    ></FormattedMessage>
  ),
  smsInExcess: (
    <FormattedMessage
      id="Websms.SmsInExcess"
      defaultMessage="SMS In Excess"
    ></FormattedMessage>
  ),
  httpReplyTempl: (
    <FormattedMessage
      id="Websms.HttpReplyTempl"
      defaultMessage="HTTP Reply Template"
    ></FormattedMessage>
  ),
  none: (
    <FormattedMessage id="Websms.None" defaultMessage="None"></FormattedMessage>
  ),
  httpReplyUrl: (
    <FormattedMessage
      id="Websms.HttpReplyUrl"
      defaultMessage="HTTP Reply URL"
    ></FormattedMessage>
  ),
  save: (
    <FormattedMessage id="Websms.Save" defaultMessage="Save"></FormattedMessage>
  ),
  loading: (
    <FormattedMessage
      id="Websms.Loading"
      defaultMessage="Loading..."
    ></FormattedMessage>
  ),
  customerInformation: (
    <FormattedMessage
      id="Global.customerInformation"
      defaultMessage="Customer Information"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
