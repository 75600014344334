/*
  AdministratorsComponent.js - Customer Contact (Administrator) Main Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

// COMPONENTS
import List from './List';
import Add from './Add';
import Edit from './Edit';
import UsersTabs from '../index';
import Paginator from '../../../components/Paginator';

// I18N
import formattedMessages from './FormattedMessages';

// CONFIG
import config from '../../../Config';

// NETWORK
import AdministratorRequest from './AdministratorRequest';
const administratorRequest = new AdministratorRequest();

export default class AdminContainer extends Component {
  constructor(props) {
    super(props);

    /*
      PROPS LIST
        - errorHandler: Function to send errors to
    */

    this.state = {
      admins: [],
      paginatedAdmins: [],
      isHidingList: false, // Whether main list view is shown or not
      selected: null, // User selected for an action to take place
      selectedIndex: null,
      pageNumber: 1,
      pageLength: config.defaultPL,
      filterLetter: '',
      letters: [],
      count: 0,
    };
  }

  async componentDidMount() {
    if (this.props.customer && this.props.customer !== null) {
      // Get Admin data and save in index Component
      let data = await administratorRequest.getAdministrators(
        this.props.errorHandler
      );
      // Strictly for redirects to single admin
      if (this.props.location.state?.selected) {
        let selectedId = this.props.location.state.selected;
        for (let i = 0; i < data.length; i++) {
          if (data[i].customer_contact_id === selectedId) {
            this.setState({
              selected: data[i],
              isHidingList: true,
            });
            break;
          }
        }
      } else {
        let sortedList = this.sortList(data);
        let letters = await this.sortLetters(sortedList);

        this.setState(
          {
            admins: sortedList,
            letters: letters,
          },
          this.setPaginatedResults
        );
      }
    }
  }

  sortList(data) {
    let newList = [].slice.call(data).sort(function (a, b) {
      if (a.contact_name.toLowerCase() < b.contact_name.toLowerCase())
        return -1;
      if (a.contact_name.toLowerCase() > b.contact_name.toLowerCase()) return 1;
      return 0;
    });
    return newList;
  }

  sortLetters = async (admins) => {
    let letters = [];
    admins.forEach((Contact) => {
      if (!letters.includes(Contact.contact_name.toUpperCase().charAt(0))) {
        letters.push(Contact.contact_name.toUpperCase().charAt(0));
      }
    });
    return letters.sort();
  };

  generateFirstLetterFilter = () => {
    let letterHtml = this.state.letters.map((el) => {
      return (
        <span key={el}>
          <span
            key={el}
            className={
              this.state.filterLetter === el ? 'clicked-object' : 'click-object'
            }
            onClick={() => this.handleLetterFilter(el)}
          >
            {el}
          </span>{' '}
        </span>
      );
    });
    return <div>{letterHtml}</div>;
  };

  /*
    Handles click from the 'letter filter' component.
  */
  handleLetterFilter = async (letter) => {
    if (letter === this.state.filterLetter) {
      this.setState(
        {
          filterLetter: '',
          pageNumber: 1,
        },
        this.setPaginatedResults
      );
    } else {
      this.setState(
        {
          filterLetter: letter,
          pageNumber: 1,
        },
        this.setPaginatedResults
      );
    }
    this.changeFunction();
  };

  /*
    Handles when a back button is clicked in one of the child components
  */
  handleBackClick = async (refreshFlag) => {
    let defaultState = {
      isHidingList: false,
      selected: null,
      selectedIndex: null,
    };

    if (refreshFlag) {
      let data = await administratorRequest.getAdministrators(
        this.props.errorHandler
      );
      let sortedList = this.sortList(data);
      defaultState.admins = sortedList;
    }

    // Return to default state
    return this.setState(defaultState, this.setPaginatedResults);
  };

  /*
    Handles Actions on the Main Table View such as Add New,
    and Edit or Delete Administrator.
  */
  handleTableRowClick = async (admin) => {
    let state = {
      isHidingList: true,
    };
    // if have selected Contact
    if (admin) state.selected = admin;
    this.setPaginatedResults();
    return this.setState(state);
  };

  /* Changes the amount of entries per pagination page. */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState(
        { pageLength: value, pageNumber: 1 },
        this.setPaginatedResults
      );
    }
  };

  setPaginatedResults = async () => {
    let { admins, pageNumber, pageLength } = this.state;
    if (admins && typeof admins !== 'undefined' && Array.isArray(admins)) {
      let newList = [];
      let paginatedAdmins = [];
      if (this.state.filterLetter) {
        admins.forEach((contact) => {
          if (
            this.state.filterLetter.toLowerCase() ===
            contact.contact_name.charAt(0).toLowerCase()
          ) {
            newList.push(contact);
          }
        });
        paginatedAdmins = newList.slice(
          (pageNumber - 1) * pageLength,
          pageNumber * pageLength
        );
      } else {
        paginatedAdmins = admins.slice(
          (pageNumber - 1) * pageLength,
          pageNumber * pageLength
        );
      }
      this.setState(
        {
          paginatedAdmins: paginatedAdmins,
          count: this.state.filterLetter ? newList.length : admins.length,
        },
        () => this.changeFunction(this.state.pageNumber - 1)
      );
    }
  };

  changeFunction(page = 0) {
    this.setState({
      activePage: page,
    });
  }

  /*
    Handles back button click to return to main view
  */
  showListCallback = () => {
    return this.setState({
      isHidingList: false,
      selected: null,
    });
  };

  /**
   * Handles the change event when clicking a pagination number
//    */
  changeEvent = async (i) => {
    this.setState(
      {
        pageNumber: i + 1,
      },
      this.setPaginatedResults
    );
  };

  render() {
    let { accessLevel, accessType } = this.props;

    if (accessLevel <= '0' || accessType === '0') {
      return null;
    }

    const { isHidingList, selected } = this.state;

    // Add a new Administrator
    if (!selected && isHidingList) {
      return (
        <Add
          handleBackClick={this.handleBackClick}
          errorHandler={this.props.errorHandler}
        ></Add>
      );
    }
    // Edit Administrator
    else if (selected && isHidingList) {
      return (
        <Edit
          selected={selected}
          handleBackClick={this.handleBackClick}
          errorHandler={this.props.errorHandler}
        ></Edit>
      );
    }

    // display list of results.
    let { paginatedAdmins, pageLength, count } = this.state;
    console.log(paginatedAdmins);

    // Return list of Administrators
    return (
      <UsersTabs tab="admin">
        <div className="Home">
          <div className="lander flex">
            <h3>{formattedMessages.admins}</h3>
            <Button
              className="d-inline flexRight btn add-new-template"
              onClick={() => this.handleTableRowClick()}
            >
              {formattedMessages.addNew}
            </Button>
          </div>
          <span className="main">
            <List
              admins={paginatedAdmins}
              handleTableRowClick={this.handleTableRowClick}
              errorHandler={this.props.errorHandler}
              generateFirstLetterFilter={this.generateFirstLetterFilter}
            />
            <Paginator
              itemCount={count}
              changeEvent={this.changeEvent}
              pageLength={pageLength}
              editFunction={this.changePageLength}
              changePageFunction={(ref) => (this.changeFunction = ref)}
            />
          </span>
        </div>
      </UsersTabs>
    );
  }
}
