/*
  All.js - Admin Device Carrier Device List

  Lists all Carriers that a Device can be attached to.

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Form, Table, Col, Row, Button } from 'react-bootstrap';

// NETWORK
import DeviceRequest from '../DeviceRequest';

// COMPONENTS
import AllListItem from './AllListItem';
import formattedMessages from '../FormattedMessages';
import BackButton from '../../../../../components/CustomButtons/BackButton';

// INIT
const deviceRequest = new DeviceRequest();

export default class AllCarrierSelectList extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - device: A Device object
        - backButtonClick: A function to handle going back to the parent Component
        - errorHandler: A function to pass errors to
    */

    this.state = {
      carriers: [],
      selectedCarrier: null,
      save_option: 't',
      loaded: false,
      isSaving: false,
    };
  }

  async componentDidMount() {
    let { device } = this.props;
    let carriers = await deviceRequest.getAllSelectableCarriers(
      device.device_id,
      this.props.errorHandler
    );
    console.log('Got selectable carriers:', carriers);
    return this.setState({
      carriers: carriers,
      loaded: true,
    });
  }

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let newState = {};
    newState[name] = value;

    return this.setState(newState);
  };

  /*
    Handles when a Carrier is selected in the List
  */
  handleCarrierSelect = (carrier) => {
    return this.setState({
      selectedCarrier: carrier,
    });
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    e.preventDefault();
    let { device } = this.props;
    let { save_option, selectedCarrier } = this.state;
    this.setState({ isSaving: true }, async () => {
      // Create the Carrier Device Carrier
      await deviceRequest.createNewDeviceCarrierDevice(
        device.device_id,
        device.device_type,
        selectedCarrier.carrier_id,
        save_option
      );
      this.props.backButtonClick('refresh');
    });
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="CarrierSelectAll" className="lander">
        <BackButton onClick={() => this.props.backButtonClick('refresh')} />
        <div className="flex">
          <h3>Add New Carrier Devices</h3>
        </div>
      </div>
    );
  };

  generateContent = () => {
    let { selectedCarrier, isSaving } = this.state;

    if (selectedCarrier) {
      return (
        <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
          {this.generateSelect()}
          {!isSaving && (
            <Button className="maxHeight" variant="primary" type="submit">
              Save
            </Button>
          )}
          {isSaving && 'Saving...'}
        </Form>
      );
    }

    return this.generateTable();
  };

  generateSelect = () => {
    return (
      <Form.Group as={Row} controlId={'save_option'}>
        <Form.Label column sm={2} className="info-label">
          Device Create Method:
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="select"
            name={'save_option'}
            defaultValue={this.state.save_option}
          >
            <option key={'t'} value={'t'}>
              {'This Provider Record'}
            </option>
            <option key={'d'} value={'d'}>
              {'All Dependent Records'}
            </option>
            <option key={'a'} value={'a'}>
              {'All Providers Records'}
            </option>
          </Form.Control>
        </Col>
      </Form.Group>
    );
  };

  generateTable() {
    let htmlRows = [];
    let { carriers } = this.state;

    if (carriers.length === 0) return <div>No Selectable Carriers</div>;

    for (let i = 0; i < carriers.length; i++) {
      htmlRows.push(
        <AllListItem
          key={carriers[i].carrier_id}
          id={carriers[i].carrier_id}
          carrierName={carriers[i].carrier_name}
          carrier={carriers[i]}
          onClickCarrierName={(e) => this.handleCarrierSelect(e)}
        ></AllListItem>
      );
    }

    return (
      <Table className="table-new">
        <thead>
          <tr>
            <td className="medium tableTop textLeft">Carrier</td>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  }

  render() {
    if (this.state.loaded) {
      return (
        <div key="DeviceAllList" className="table">
          {this.generateLander()}
          {this.generateContent()}
        </div>
      );
    }
    return (
      <div key="DeviceAllList" className="table">
        {formattedMessages.loading}
      </div>
    );
  }
}
