/*
  Failed/List.js - Component to generate the list of Failed SMS items

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';

import { FormattedMessage } from 'react-intl';
import formattedMessages from '../FormattedMessages';
import { DateTime } from 'luxon';
// COMPONENTS
import ListItem from './ListItem';
import { errorCodes } from '../../../../utils/errorCodes';
// CONFIG
import config from '../../../../Config';

export default class ListComponent extends Component {
  constructor(props) {
    super(props);

    /*
			PROPS LIST
				- list: List of Failed SMS objects to be displayed
				- showSingleFailedSms: A function to call when clicking on a Failed SMS message field
		*/

    this.state = {
      pageNumber: 1,
      error: null,
    };
    this.pageLength = config.defaultPL;
  }

  /*
    Handles the change event when clicking a pagination number
  */
  changeEvent = (i) => {
    return this.setState({
      pageNumber: i + 1,
    });
  };

  generateTableBody = () => {
    let { list } = this.props;
    let rows = [];
    let failedDate;
    let rezoned;
    // console.log('list:', list.length, list);
    for (let i = 0; i < list.length; i++) {
      let element = list[i];
      failedDate = DateTime.fromISO(element.sms_sent);
      rezoned = failedDate.setZone(localStorage.getItem('timeShort'), {
        keepLocalTime: false,
      });
      let errorText = errorCodes(element.error_code_text);

      let smsText;
      try {
        smsText = decodeURIComponent(element.sms_text);
      } catch (err) {
        smsText = 'ERROR: MALFORMED TEXT';
      }

      rows.push(
        <ListItem
          key={element.sms_id}
          id={element.sms_id}
          createdDate={rezoned.toFormat('dd-LLL-yyyy h:mm a')}
          from={element.originator || 'N/A'}
          to={element.dest_tn || ''}
          name={element.dest_name || element.dest_email || element.dest_tn || ''}
          status={element.sent_flag_name || ''}
          code={errorText.roxCode || ''}
          errorText={errorText.meaning || ''}
          failedSms={element}
          onClickStatus={(e) => this.props.showSingleFailedSms(e)}
        />
      );
    }
    return rows;
  };

  generateMobileTableBody = () => {
    let { list } = this.props;
    let rows = [];
    let { pageNumber } = this.state;
    let failedDate;
    let rezoned;
    let displayList = list.slice(
      (pageNumber - 1) * this.pageLength,
      pageNumber * this.pageLength - 1 + 1
    );
    for (let i = 0; i < displayList.length; i++) {
      let element = displayList[i];

      failedDate = DateTime.fromISO(element.sms_sent);
      rezoned = failedDate.setZone(localStorage.getItem('timeShort'), {
        keepLocalTime: false,
      });

      let smsText;
      try {
        smsText = decodeURIComponent(element.sms_text);
      } catch (err) {
        smsText = 'ERROR: MALFORMED TEXT';
      }

      rows.push(
        <tr
          key={i}
          id={element.sms_id}
          valign="top"
          onClick={() => this.props.showSingleFailedSms(element)}
        >
          <td>
            <b>{rezoned.toFormat('dd-LLL-yyyy h:mm a')}</b>
            <br />
            {element.dest_name || element.dest_tn || element.dest_email || ''}
            <br />
            {smsText || ''}
          </td>
        </tr>
      );
    }
    return rows;
  };

  showExpandedDetails(elementId) {
    console.log('here');
  }

  render() {
    return (
      <Fragment>
        <div className="visible-desktop">
          <Table id="inbox">
            <thead>
              <tr>
                <td className="medium tableTop textLeft">
                  {formattedMessages.tableHeaderDateTime}
                </td>
                <td className="medium tableTop textLeft">
                  {formattedMessages.fromHeader}
                </td>
                <td className="medium tableTop textLeft">
                  {formattedMessages.toHeader}
                </td>
                <td className="medium tableTop textLeft">
                  {formattedMessages.nameHeader}
                </td>
                <td className="medium tableTop textLeft">
                  {formattedMessages.tableHeaderErrorStatus}
                </td>
                <td className="medium tableTop textLeft">
                  {formattedMessages.tableHeaderErrorCode}
                </td>
                <td className="medium tableTop textLeft">
                  {formattedMessages.tableHeaderErrorText}
                </td>
              </tr>
            </thead>
            <tbody>{this.generateTableBody()}</tbody>
          </Table>
          {/*<Paginator itemCount={this.props.list.length} changeEvent = {this.changeEvent}/>*/}
        </div>
        <div className="visible-device">
          <table id="failed" width="100%" cellSpacing="0">
            <thead>
              <tr>
                <th className="medium textLeft tableTop"></th>
              </tr>
            </thead>
            <tbody>{this.generateMobileTableBody()}</tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}
