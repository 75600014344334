/*
  List.js - List Component for Users

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';

// COMPONENTS
import ListItem from './ListItem';
import Paginator from '../../../../components/Paginator';

// I18N
import formattedMessages from './FormattedMessages';

// CONFIG
import config from '../../../../Config';

export default class ListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      error: null,
      displayLetters: [],
      pageLength: config.defaultPL,
    };
  }

  componentDidMount() {
    // Sets parent component's local function to this component's local
    // resetFunction which in turn is the paginator's local changePage function.
    if (this.props.changePageFunction)
      this.props.changePageFunction(this.resetFunction);
  }

  /*
    Handles when a ListItem is clicked on
  */
  returnListItem = (listItemId) => {
    this.props.callBack(listItemId);
  };

  /*
    Handles the resolving of cost center name from id
  */
  returnCostCentreName = (costCentreId) => {
    for (let i = 0; i < this.props.costCentres.length; i++) {
      if (this.props.costCentres[i].cost_centre_id === costCentreId) {
        return this.props.costCentres[i].cost_centre_name;
      }
    }
    return null;
  };

  /* 
    Changes the amount of entries per pagination page.
  */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState({ pageLength: value });
      this.props.changeEvent(1);
    }
  };

  /*
    Generates rows of User objects (in JSX)
  */
  generateList = (displayList) => {
    let fullList = [];
    for (let i = 0; i < displayList.length; i++) {
      let element = displayList[i];

      let status = 'Active';
      if (element.user_status !== 'A') status = 'Disabled';
      fullList.push(
        <ListItem
          key={element.user_email + i}
          onClick={() => this.props.callBack(element)}
          name={element.user_name}
          email={element.user_email}
          number={element.user_tn}
          status={status}
          msgmax={element.max_sms_per_day}
          centre={
            this.returnCostCentreName(element.cost_centre_id) || 'Not Assigned'
          }
        />
      );
    }

    return fullList;
  };

  /**
   * handles the list before it is used to generate the JSX list.
   * @returns list of items as a list which will later be used to generate JSX as well
   * as the total number of filtered items for pagination handling.
   */
  handleList = () => {
    let { list, pageNumber } = this.props;
    let { pageLength } = this.state;

    if (list && typeof list !== 'undefined' && Array.isArray(list)) {
      let filteredList = [];
      let displayList = [];
      if (this.props.filterLetter) {
        list.forEach((user) => {
          if (
            this.props.filterLetter.toLowerCase() ===
            user.user_name.charAt(0).toLowerCase()
          ) {
            filteredList.push(user);
          }
        });
        displayList = filteredList.slice(
          (pageNumber - 1) * pageLength,
          pageNumber * pageLength - 1 + 1
        );
      } else {
        displayList = list.slice(
          (pageNumber - 1) * pageLength,
          pageNumber * pageLength - 1 + 1
        );
      }
      return {
        displayList: displayList,
        count: this.props.filterLetter ? filteredList.length : list.length,
      };
    }
  };

  render() {
    let { displayList, count } = this.handleList();
    return (
      <Fragment>
        {this.props.generateFirstLetterFilter()}
        <Table className="user-table">
          <thead>
            <tr key="head">
              <th className="medium textLeft tableTop">
                {formattedMessages.name}
              </th>
              <th className="medium textLeft tableTop">
                {formattedMessages.email}
              </th>
              <th className="medium textLeft tableTop">
                {formattedMessages.cell}
              </th>
              <th className="medium textLeft tableTop">
                {formattedMessages.status}
              </th>
              <th className="medium textLeft tableTop">
                {formattedMessages.maxDailyMessage}
              </th>
              <th className="medium textLeft tableTop">
                {formattedMessages.costCenter}
              </th>
              <th className="icon tableTop"></th>
            </tr>
          </thead>
          <tbody>{this.generateList(displayList)}</tbody>
        </Table>
        <Paginator
          itemCount={count}
          changeEvent={this.props.changeEvent}
          changePageFunction={(ref) => (this.resetFunction = ref)}
          pageLength={this.state.pageLength}
          editFunction={this.changePageLength}
        />
      </Fragment>
    );
  }
}
