/*
  UserReportRequests.js - Handles Search for User Report Data

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';
import config from '../../../Config';

// INSTANTIATE
const handleRequest = new RequestRabbit();

export default class SearchRequest {
  /*
    Get Report Data for Date Range
  */
  getReportData = async (searchObject, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/reports/users/summary`;
      const headers = { 'Content-Type': 'application/json' };

      const options = {
        method: 'POST',
        body: JSON.stringify(searchObject) || null,
        headers: headers,
      };
      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      // Response
      let responseJSON = await response.json();
      console.log(responseJSON);
      return responseJSON;
    } catch (e) {
      console.error('error while retrieving data:', e);
      errorHandler(e.message);
      return [];
    }
  };

  /*
    Get Report Data for a Single User
  */
  userReport = async (id, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/reports/users/${id}`;
      const header = {
        'Content-Type': 'application/json',
      };
      let options = {
        method: 'GET',
        headers: header,
      };
      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Gets all Sent Messages for a given User and type.  Type being Direct,
    Standard and Replies
  */
  getMessagesForId = async (userId, type, fromDate, toDate, errorHandler) => {
    try {
      let url = `${config.baseServerURL}/sentsms/?user_id=${userId}&type=${type}`;
      if (fromDate) url += `&fromDate=${fromDate}`;
      if (toDate) url += `&toDate=${toDate}`;
      const options = { method: 'GET' };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Gets SMS tracing data for a given timeframe and User
  */
  getUserReportDetails = async (
    customerUserId,
    fromDate,
    toDate,
    errorHandler
  ) => {
    try {
      let url = `${config.baseServerURL}/reports/users/${customerUserId}/details`;
      if (fromDate) url += `?fromDate=${fromDate}`;
      if (fromDate && toDate) url += `&toDate=${toDate}`;
      const options = {
        method: 'GET',
      };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }

      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };

  /*
    Cancels all messages for a given message type (Direct, Standard or Pending Reminder)
    and for a given Customer User.
  */
  cancelAllPendingMessages = async (customerUserId, smsType, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sms/cancel/pending`;
      const headers = { 'Content-Type': 'application/json' };
      const body = JSON.stringify({
        customerUserId: customerUserId,
        smsType: smsType,
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };
      // Request
      await handleRequest.request(url, options, errorHandler);
    } catch (e) {
      errorHandler(e.message);
    }
  };

  cancelSinglePendingMessage = async (
    customerUserId,
    schedId,
    errorHandler
  ) => {
    try {
      const url = `${config.baseServerURL}/sms/cancel`;
      const headers = { 'Content-Type': 'application/json' };
      const body = JSON.stringify({
        customerUserId: customerUserId,
        sched_id: schedId,
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };
      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
