// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

// COMPONENTS
import BackButton from '../../../../../components/CustomButtons/BackButton';

// NETWORK
import DeviceRequest from '../DeviceRequest';

// STYLING
import '../../../Admin.css';
import '../Device.css';

// INIT
const deviceRequest = new DeviceRequest();

export default class NumberSingle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edits: {},
    };
  }

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;
    edits[name] = value;

    console.log('Edits is:', edits);

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let updateData = this.state.edits;

      await deviceRequest.updateNumber(
        this.props.device.device_id,
        updateData,
        this.props.errorHandler
      );
      window.location.href = '/admin/device'; // Force reload - TODO: redo properly
    } catch (e) {
      console.log('Got Device update err:', e);
    }
  };

  generateEditFormGroup = (controlId, label, defaultValue) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name={controlId}
            type="text"
            defaultValue={defaultValue}
          />
        </Col>
      </Form.Group>
    );
  };

  generateFormGroup = (controlId, label, displayValue) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateTwilioCallForwardForm = () => {
    const { edits } = this.state;

    return (
      <Fragment>
        <Form.Group as={Row} controlId={'transferMessage'}>
          <Form.Label column sm={2} className="info-label">
            {'Transfer Message:'}
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              type="checkbox"
              defaultChecked={edits.transferMessage}
              name="transferMessage"
            ></Form.Check>
          </Col>
        </Form.Group>
        {this.generateEditFormGroup('voice_url', 'Voice URL:', edits.voiceUrl)}
        {this.generateEditFormGroup(
          'forward_to_no',
          'Forward To Number:',
          edits.forwardToNo
        )}
        {this.generateEditFormGroup(
          'status_callback_url',
          'Status CallBack URL::',
          edits.statusCallbackUrl
        )}
      </Fragment>
    );
  };

  generateForm = () => {
    const { device } = this.props;
    const { edits } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
        {this.generateFormGroup('device_id', 'Device Id:', device.device_id)}
        {this.generateFormGroup(
          'dedicated_user_id',
          'Dedicated User Id:',
          device.dedicated_user_id || 'N/A'
        )}
        {this.generateFormGroup(
          'dedicated_user_email',
          'Dedicated User Email:',
          device.dedicated_user_email || 'N/A'
        )}
        {this.generateFormGroup(
          'device_name',
          'Twilio Description Name:',
          device.device_name
        )}
        {this.generateFormGroup('sms_url', 'SMS URL:', device.sms_url)}
        {this.generateFormGroup(
          'sms_fallback_url',
          'SMS Fallback URL:',
          device.sms_fallback_url
        )}
        <Form.Group as={Row} controlId={'callForwardingOn'}>
          <Form.Label column sm={2} className="info-label">
            {'Turn Call Forwarding On:'}
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              type="checkbox"
              defaultChecked={edits.callForwardingOn}
              name="callForwardingOn"
            ></Form.Check>
          </Col>
        </Form.Group>
        {edits.callForwardingOn && this.generateTwilioCallForwardForm()}
        <Button
          className="createProviderButton"
          variant="primary"
          type="submit"
          disabled
        >
          Save
        </Button>
      </Form>
    );
  };

  render() {
    const { device } = this.props;
    return (
      <div className="Home buy-config">
        <BackButton onClick={this.props.backButtonClick}></BackButton>
        <h3>Manage Number</h3>
        <h5>{device.reply_tn || 'N/A'}</h5>
        <br />
        {this.generateForm()}
      </div>
    );
  }
}
