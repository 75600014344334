/*
  ProviderRequest.js - Provider Networking File
  
  Contains networking functionality for Administration - Provider Components

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';

// CONFIGURATION
import config from '../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class ProviderRequest {
  /*
    Returns all Providers in the system
  */
  getAllProviders = async (errorHandler) => {
    try {
      // Params
      const url = `${config.baseServerURL}/admin/provider/`;
      const options = {
        method: 'GET',
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Returns a single Provider
  */
  getSingleProvider = async (providerId, errorHandler) => {
    try {
      // Params
      const url = `${config.baseServerURL}/admin/provider/${providerId}`;
      const options = {
        method: 'GET',
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        throw new Error(responseJSON.error);
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      throw e;
    }
  };

  /*
    Takes data from a form and creates a new Provider for the
    Red Oxygen system.
  */
  createProvider = async (newProvider, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/provider/`;
      const body = JSON.stringify(newProvider);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Used to retrieve Tariff Plans solely for Provider use, for the given provider_id
  */
  getTariffPlans = async (providerId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/tariff/plan?provider_id=${providerId}`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      // TODO: why does this and the other request above throw????
      throw e;
    }
  };
}
