import React from 'react';

const ListItem = ({
  id,
  beforePrefix = '',
  afterPrefix = '',
  length = '',
  onClickBeforePrefix = null,
  prefix = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="column-right" name="before_prefix">
      <span className="blue" onClick={() => onClickBeforePrefix(prefix)}>
        {beforePrefix}
      </span>
    </td>
    <td className="column-right" name="after_prefix">
      <span>{afterPrefix}</span>
    </td>
    <td className="column-right" name="length">
      <span>{length}</span>
    </td>
  </tr>
);

export default ListItem;
