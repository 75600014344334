// NPM MODULES
import React, { Component } from 'react';
import CardDeck from 'react-bootstrap/CardDeck';

// OUR COMPONENTS
import HomeCard from '../../components/HomeCard';

// STYLING
import './Home.css';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';
import WebSMSTabs from '../Websms';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  userCard = () => {
    return (
      <CardDeck>
        <div className="visible-desktop">
          <HomeCard
            title={formattedMessages.CompleteSMSTitleText}
            text={formattedMessages.webSMSText}
            iconClass=""
            link=""
            interaction={formattedMessages.manageText}
            page="/websms/address"
          />
          <HomeCard
            title={formattedMessages.templatesTitleText}
            text={formattedMessages.templatesText}
            iconClass=""
            link=""
            interaction={formattedMessages.viewText}
            page="/websms/template"
          />
          <HomeCard
            title={formattedMessages.composeTitleText}
            text={formattedMessages.composeText}
            iconClass=""
            link=""
            interaction={formattedMessages.viewText}
            page="/websms/compose"
          />
        </div>
      </CardDeck>
    );
  };

  adminCard = () => {
    return (
      <CardDeck>
        <div className="visible-desktop">
          <HomeCard
            title={formattedMessages.CompleteSMSTitleText}
            text={formattedMessages.webSMSText}
            iconClass=""
            link=""
            interaction={formattedMessages.manageText}
            page="/websms/address"
          />
          <HomeCard
            title={formattedMessages.reportsTitleText}
            text={formattedMessages.reportsText}
            iconClass=""
            link=""
            interaction={formattedMessages.viewText}
            page="/reports/cost-center"
          />
          <HomeCard
            title={formattedMessages.accountTitleText}
            text={formattedMessages.accountText}
            iconClass=""
            link=""
            interaction={formattedMessages.viewText}
            page="/billing/invoices"
          />
        </div>
        <div className="visible-device">
          <HomeCard
            title={formattedMessages.CompleteSMSTitleText}
            text={formattedMessages.webSMSText}
            iconClass=""
            link=""
            interaction={formattedMessages.manageText}
            page="/websms/address"
          />
        </div>
      </CardDeck>
    );
  };

  render() {
    let { accessLevel, accessType } = this.props;

    return (
      <WebSMSTabs
        tab="home"
        mobileMenuOpen={this.props.mobileMenuOpen}
        toggleMobileMenu={this.props.toggleMobileMenu}
        navigate={this.props.navigate}
      >
        <div className="Home">
          <div style={{ margin: '3rem' }}>
            <div className="lander">
              <h1>{formattedMessages.welcomeText}</h1>
              <br />
            </div>
            <div className="cards">
              {accessLevel <= '0' || accessType === '0'
                ? this.userCard()
                : this.adminCard()}
            </div>
          </div>
        </div>
      </WebSMSTabs>
    );
  }
}
