import React from 'react';

const ListItem = ({
  id,
  name = '',
  active = '',
  type = '',
  customerClass = '',
  userCount = 0,
  Direct = { sent: 0, parts: 0, onClick: null },
  Standard = { sent: 0, parts: 0, onClick: null },
  Replies = { sent: 0, parts: 0, onClick: null },
  Sent = { sent: 0, parts: 0, onClick: null },
  Pending = { sent: 0, parts: 0, onClick: null },
  onClickName = null,
}) => (
  <tr key={'list' + id} id={id} className="">
    <td className="medium textLeft wrap" name="name">
      <span className="blue" onClick={onClickName}>
        {name}
      </span>
    </td>
    <td className="medium textLeft" name="type">
      <span>{type}</span>
    </td>
    <td className="medium textLeft" name="customerClass">
      <span>{customerClass} </span>
    </td>
    <td className="medium textLeft" name="active">
      <span>{active}</span>
    </td>
    <td className="medium" name="userCount">
      <span>{userCount}</span>
    </td>
    <td className="medium column-left" name="Direct">
      <span onClick={Direct.onClick}>{Direct.sent + ` (${Direct.parts})`}</span>
    </td>
    <td className="medium" name="Standard">
      <span onClick={Standard.onClick}>
        {Standard.sent + ` (${Standard.parts})`}
      </span>
    </td>
    <td className="medium column-right" name="Replies">
      <span onClick={Replies.onClick}>
        {Replies.sent + ` (${Replies.parts})`}
      </span>
    </td>
    <td className="medium" name="Sent">
      <span onClick={Sent.onClick}>{Sent.sent + ` (${Sent.parts})`}</span>
    </td>
    <td className="medium" name="Pending">
      <span onClick={Pending.onClick}>
        {Pending.sent + ` (${Pending.parts})`}
      </span>
    </td>
  </tr>
);

export default ListItem;
