/*
  SingleView.js - Admin Carrier Single View Main Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
// import {Form, Table, Button, Col, Row} from 'react-bootstrap';
import { Table, Button } from 'react-bootstrap';

// COMPONENTS
import ListItem from './ListItem';

// I18N
//import formattedMessages from './FormattedMessages';

// STYLES
//import '../Admin.css';

// NETWORK
import CarrierRequest from '../CarrierRequest';

// INSTANTIATION
const carrierRequest = new CarrierRequest();

export default class ServiceCarrierPrefixList extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - carrier: A single carrier object (optional)
        - country: Country to create or edit a Carrier against
        - backButtonClick: Function to handle Back Button click
        - addNewCarrierPrefix: Function to handle Add New Button click
        - showSingleCarrierPrefix: Function to handle viewing a single Carrier Prefix
        - errorHandler: A function to pass errors to
    */

    this.state = {
      prefixes: [],
      edits: {},
    };
  }

  async componentDidMount() {
    let { country, carrier, errorHandler } = this.props;

    let prefixes = await carrierRequest.getCarrierPrefixes(
      country.country_code_id,
      carrier.carrier_id,
      errorHandler
    );
    console.log('Got prefixes:', prefixes);
    return this.setState({
      prefixes: prefixes,
    });
  }

  generateTable() {
    let htmlRows = [];
    let { prefixes } = this.state;

    if (prefixes.length === 0) {
      return <div>No Carrier Prefixes</div>;
    }

    for (let i = 0; i < prefixes.length; i++) {
      htmlRows.push(
        <ListItem
          key={prefixes[i].carrier_prefix_id}
          id={prefixes[i].carrier_id}
          prefixName={prefixes[i].prefix}
          prefix={prefixes[i]}
          onClickName={(e) => this.props.showSingleCarrierPrefix(e)}
        ></ListItem>
      );
    }

    return (
      <Table>
        <thead>
          <tr>
            <td className="medium tableTop column-left">Prefix</td>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  }

  render() {
    return (
      <div key="CarrierPrefixList" className="carrierprefix table">
        <h4>List of Carrier Prefixes</h4>
        <Button
          className="btn add-new-template"
          onClick={this.props.addNewCarrierPrefix}
        >
          Add New
        </Button>
        {this.generateTable()}
      </div>
    );
  }
}
