import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  costCentreButton: (
    <FormattedMessage
      id="ReportsIndex.CostCentreButton"
      defaultMessage="Cost Centre Reports"
    />
  ),
  userButton: (
    <FormattedMessage
      id="ReportsIndex.UserButton"
      defaultMessage="User Reports"
    />
  ),
  messageButton: (
    <FormattedMessage
      id="ReportsIndex.MessageButton"
      defaultMessage="Message Reports"
    />
  ),
  customerButton: (
    <FormattedMessage
      id="ReportsIndex.CustomerButton"
      defaultMessage="Customer Reports"
    />
  ),
  incomingButton: (
    <FormattedMessage
      id="ReportsIndex.IncomingButton"
      defaultMessage="Incoming Reports"
    />
  ),
  outgoingButton: (
    <FormattedMessage
      id="ReportsIndex.OutgoingButton"
      defaultMessage="Outgoing Reports"
    />
  ),
  providerButton: (
    <FormattedMessage
      id="ReportsIndex.ProviderButton"
      defaultMessage="Provider Reports"
    />
  ),
  topUserButton: (
    <FormattedMessage
      id="ReportsIndex.TopUserButton"
      defaultMessage="Top Customer Reports"
    />
  ),
  usersText: <FormattedMessage id="ReportsList.Users" defaultMessage="Users" />,
  loadingText: (
    <FormattedMessage
      id="App.LoadingText"
      defaultMessage="Loading"
    ></FormattedMessage>
  ),
  backText: (
    <FormattedMessage
      id="App.BackButton"
      defaultMessage="Back"
    ></FormattedMessage>
  ),
  messagesText: (
    <FormattedMessage
      id="ReportsList.Messages"
      defaultMessage="Messages (Parts)"
    ></FormattedMessage>
  ),
  remindersText: (
    <FormattedMessage
      id="ReportsList.Reminders"
      defaultMessage="Reminders (Parts)"
    ></FormattedMessage>
  ),
  directText: (
    <FormattedMessage
      id="ReportsList.Direct"
      defaultMessage="Direct"
    ></FormattedMessage>
  ),
  repliesText: (
    <FormattedMessage
      id="ReportsList.Replies"
      defaultMessage="Replies"
    ></FormattedMessage>
  ),
  sentText: (
    <FormattedMessage
      id="ReportsList.Sent"
      defaultMessage="Sent"
    ></FormattedMessage>
  ),
  pendingText: (
    <FormattedMessage
      id="ReportsList.Pending"
      defaultMessage="Pending"
    ></FormattedMessage>
  ),
  standardText: (
    <FormattedMessage
      id="ReportsList.Standard"
      defaultMessage="Standard"
    ></FormattedMessage>
  ),
  nameText: (
    <FormattedMessage
      id="ReportsList.Name"
      defaultMessage="Name"
    ></FormattedMessage>
  ),
  statusText: (
    <FormattedMessage
      id="ReportsList.Status"
      defaultMessage="Status"
    ></FormattedMessage>
  ),
  customerUserText: (
    <FormattedMessage
      id="ReportsIndex.customerUserText"
      defaultMessage="Customer User"
    />
  ),
  totalText: (
    <FormattedMessage
      id="ReportsList.Total"
      defaultMessage="Total"
    ></FormattedMessage>
  ),
  noResultsFound: (
    <FormattedMessage
      id="Global.noResultsFound"
      defaultMessage="No Results Found"
    />
  ),
  typeClassStatusText: (
    <FormattedMessage
      id="Global.TypeClassStatus"
      defaultMessage="Type - Class - Status"
    ></FormattedMessage>
  ),
  customerId: (
    <FormattedMessage
      id="Global.customerId"
      defaultMessage="Customer Id"
    ></FormattedMessage>
  ),
  customerName: (
    <FormattedMessage
      id="Global.customerName"
      defaultMessage="Customer Name"
    ></FormattedMessage>
  ),
  providerName: (
    <FormattedMessage
      id="Global.providerName"
      defaultMessage="Provider Name"
    ></FormattedMessage>
  ),
  activeUsers: (
    <FormattedMessage
      id="Global.activeUsers"
      defaultMessage="Active Users"
    ></FormattedMessage>
  ),
  provider: (
    <FormattedMessage
      id="ReportsList.Provider"
      defaultMessage="Provider"
    ></FormattedMessage>
  ),
  status: (
    <FormattedMessage
      id="ReportsList.Status"
      defaultMessage="Status"
    ></FormattedMessage>
  ),
  customers: (
    <FormattedMessage
      id="ReportsList.Customers"
      defaultMessage="Customers"
    ></FormattedMessage>
  ),
  costCentreText: (
    <FormattedMessage
      id="ReportsIndex.CostCentreText"
      defaultMessage="Cost Centre"
    />
  ),
  customerText: (
    <FormattedMessage
      id="ReportsIndex.customerText"
      defaultMessage="Customer"
    />
  ),
  dateText: (
    <FormattedMessage id="ReportsIndex.dateText" defaultMessage="Date" />
  ),
  typeText: (
    <FormattedMessage id="ReportsIndex.typeText" defaultMessage="Type" />
  ),
  userText: (
    <FormattedMessage id="ReportsIndex.userText" defaultMessage="User" />
  ),
  fromText: (
    <FormattedMessage id="ReportsIndex.fromText" defaultMessage="From" />
  ),
  toText: <FormattedMessage id="ReportsIndex.toText" defaultMessage="To" />,
  errorText: (
    <FormattedMessage id="ReportsIndex.errorText" defaultMessage="Error" />
  ),
};

export default FormattedMessages;
