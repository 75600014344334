/*
  All.js - Admin Carrier Devices - All Devices list

  Used for selecting a Device to attach to new Carrier Device

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
// import {Form, Table, Button, Col, Row} from 'react-bootstrap';
import { Form, Table, Row } from 'react-bootstrap';

// COMPONENTS
import AllListItem from './AllListItem';

// NETWORK
import CarrierRequest from '../CarrierRequest';

// INSTANTIATION
const carrierRequest = new CarrierRequest();

export default class ServiceCarrierAllDevicesList extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - carrier: A single carrier object (optional)
        - country: Country to create or edit a Carrier against
        - backButtonClick: Function to handle Back Button click
        - insertSelectedDevice: Function to handle Device row click
        - errorHandler: A function to pass errors to
    */

    this.state = {
      devices: [],
      apply_provider: 'this',
    };
  }

  async componentDidMount() {
    let { country, carrier, errorHandler } = this.props;
    let devices = await carrierRequest.getSelectableDevices(
      country.country_code_id,
      carrier.carrier_id,
      errorHandler
    );
    console.log('Got selectable devices:', devices);
    return this.setState({
      devices: devices,
    });
  }

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    console.log('handle change value:', value);

    return this.setState({
      apply_provider: value,
    });
  };

  insertSelectedDevice = (device) => {
    this.props.insertSelectedDevice(device, this.state.apply_provider);
  };

  generateSelect() {
    return (
      <Form onChange={this.handleFormChange}>
        <Form.Group as={Row} controlId="apply_provider">
          <Form.Label column sm={2} className="info-label">
            Apply this to current Provider or ALL Providers?
          </Form.Label>
          <Form.Check
            type="radio"
            name="apply_provider"
            value="this"
            defaultChecked={this.state.apply_provider === 'this'}
            label="This Provider"
          ></Form.Check>
          <Form.Check
            type="radio"
            name="apply_provider"
            value="all"
            defaultChecked={this.state.apply_provider === 'all'}
            label="All Providers"
          ></Form.Check>
        </Form.Group>
      </Form>
    );
  }

  sortListForTable() {
    let { devices } = this.state;

    let sortedDevices = devices.sort(function (a, b) {
      if (a.device_name < b.device_name) {
        return -1;
      }
      if (a.device_name > b.device_name) {
        return 1;
      }
      return 0;
    });

    return this.generateTable(sortedDevices);
  }

  generateTable() {
    let htmlRows = [];
    let { devices } = this.state;

    if (devices.length === 0) {
      return <div>No Carrier Devices</div>;
    }

    for (let i = 0; i < devices.length; i++) {
      htmlRows.push(
        <AllListItem
          key={devices[i].device_id}
          id={devices[i].device_id}
          deviceName={devices[i].device_name}
          device={devices[i]}
          onClickName={(e) => this.insertSelectedDevice(e)}
        ></AllListItem>
      );
    }

    return (
      <Table className="table-new">
        <thead>
          <tr>
            <td className="medium tableTop column-left">Device</td>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  }

  render() {
    return (
      <div key="AllDevicesList" className="all-devices table">
        <h4>Add new Carrier Device</h4>
        {this.generateSelect()}
        {this.generateTable()}
      </div>
    );
  }
}
