/*
  index.js - Main File To Serve

  Author: Elle Dunbar (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

// OUR COMPONENTS
import App from './App';

// UTILS
import * as serviceWorker from './serviceWorker';

// STYLES
import './index.css';

const root = createRoot(document.getElementById('root'));

root.render(
  <Router>
    <App />
  </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
