/*
  FormattedMessages.js - FormattedMessages for users/costcentres component

  Author: Julius Klaebe (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  addNew: <FormattedMessage id="Global.AddNew" defaultMessage="Add New" />,
  costCenters: (
    <FormattedMessage id="Global.CostCenters" defaultMessage="Cost Centers" />
  ),
  save: <FormattedMessage id="Global.Save" defaultMessage="Save" />,
  cancel: <FormattedMessage id="Global.Cancel" defaultMessage="Cancel" />,
  backToList: (
    <FormattedMessage
      id="Global.BackToList"
      defaultMessage="Back to Cost Center List"
    />
  ),
  addNewCostCenter: (
    <FormattedMessage
      id="Global.AddNewCostCenter"
      defaultMessage="Add New Cost Center"
    />
  ),
  none: <FormattedMessage id="Global.None" defaultMessage="none" />,
  name: <FormattedMessage id="Global.Name" defaultMessage="Name" />,
  contactName: (
    <FormattedMessage id="Global.ContactName" defaultMessage="Contact Name" />
  ),
  editCostCenter: (
    <FormattedMessage
      id="Global.EditCostCenter"
      defaultMessage="Edit Cost Center"
    />
  ),
  delete: <FormattedMessage id="Global.Delete" defaultMessage="Delete" />,
  loading: <FormattedMessage id="Global.Loading" defaultMessage="Loading..." />,
};

export default FormattedMessages;
