// NPM MODULES
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { DateTime } from 'luxon';

// NETWORKING
import UserReportRequest from '../UserReportRequests';

// FORMATTED MESSAGES
import formattedMessages from '../FormattedMessages';

// INSTANTIATE
const userReportRequest = new UserReportRequest();

export default class SingleViewSent extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props
        - user: A User object to be used for searching

      Optional Props
        - fromDate: The start of the date range to search in
        - toDate: The end of the date range to search in
    */

    this.state = {
      messages: [],
    };
  }

  async componentDidMount() {
    try {
      if (this.props.user) {
        let messages = await userReportRequest.getMessagesForId(
          this.props.user.customer_user_id,
          'REMSEN',
          this.props.fromDate,
          this.props.toDate,
          this.props.errorHandler
        );
        return this.setState({
          messages: messages,
        });
      }
      // Generate error if no props
      return this.setState({
        hasError: true,
        errorMessage: 'Expected User in Props',
      });
    } catch (e) {
      return this.setState({
        hasError: true,
        errorMessage: 'Network Request Failed.',
      });
    }
  }

  generateTable = () => {
    return (
      <Table>
        <thead>
          <tr>
            <td className="medium tableTop column-right" colSpan="3">
              {formattedMessages.whenText}
            </td>
            <td className="medium tableTop" colSpan="7">
              {formattedMessages.dataText}
            </td>
          </tr>
          <tr>
            <td className="medium tableBottom">
              <span>{formattedMessages.dayText}</span>
            </td>
            <td className="medium tableBottom">
              <span>{formattedMessages.dateText}</span>
            </td>
            <td className="medium tableBottom column-right">
              <span>{formattedMessages.timeText}</span>
            </td>
            <td className="medium tableBottom">
              <span>{formattedMessages.typeText}</span>
            </td>
            <td className="medium tableBottom">
              <span>{formattedMessages.phoneNumberText}</span>
            </td>
            <td className="medium tableBottom">
              <span>{formattedMessages.countryText}</span>
            </td>
            <td className="medium tableBottom">
              <span>{formattedMessages.localFlagText}</span>
            </td>
            <td className="medium tableBottom">
              <span>{formattedMessages.quantityText}</span>
            </td>
            {/* <td className="medium tableBottom">
              <span>{formattedMessages.creditsUsedText}</span>
            </td> */}
            <td className="medium tableBottom">
              <span>{formattedMessages.statusText}</span>
            </td>
          </tr>
        </thead>
        <tbody>{this.generateTableBody()}</tbody>
      </Table>
    );
  };

  /*
    Adding the time range text fromDate to toDate
  */
  generateTimeRangeText = () => {
    let fromDate = moment(this.props.fromDate).format('DD MMM YYYY h:mm:ss A');
    let toDate = moment(this.props.toDate).format('DD MMM YYYY h:mm:ss A');

    return (
      <div>
        <p>
          {formattedMessages.from} {fromDate} {formattedMessages.to} {toDate}
        </p>
      </div>
    );
  };

  generateTableBody = () => {
    if (!this.state.messages || this.state.messages.length === 0) return null;
    let rows = [];

    // For each Direct Messages
    for (let i = 0; i < this.state.messages.length; i++) {
      let msg = this.state.messages[i];
      let sentDate = DateTime.fromISO(msg.sms_sent);
      let rezoned = sentDate.setZone(localStorage.getItem('timeShort'), {
        keepLocalTime: false,
      });
      let day = rezoned.toFormat('EEEE');
      let date = rezoned.toFormat('LLL dd, yyyy');
      let time = rezoned.toFormat('h:mm:ss a');

      rows.push(
        <tr key={'list' + msg.sched_sms_id} id={msg.sched_sms_id}>
          <td className="medium" name="day">
            {day}
          </td>
          <td className="medium" name="date">
            {date}
          </td>
          <td className="medium" name="time">
            {time}
          </td>
          <td className="medium" name="type">
            {msg.recurring === 'RR' ? 'Recurring' : 'Non Recurring'}
          </td>
          <td className="medium" name="dest_tn">
            {msg.dest_tn}
          </td>
          <td className="medium" name="country">
            {msg.country_code_name}
          </td>
          <td className="medium" name="local_flag">
            {msg.local_flag}
          </td>
          <td className="medium" name="quantity">
            {msg.quantity}
          </td>
          {/* <td className="medium" name="credit_used">
            {msg.credit_used}
          </td> */}
          <td className="medium" name="status">
            {msg.status}
          </td>
        </tr>
      );
    }

    return rows;
  };

  render() {
    return (
      <div className="Home">
        <div className="lander">
          <p onClick={this.props.backButton} className="backLink">
            <i className="material-icons">keyboard_arrow_left</i>
            <span>{formattedMessages.backText}</span>
          </p>
          <div>
            <h3>{formattedMessages.sentReplies}</h3>
          </div>
        </div>
        {this.generateTimeRangeText()}
        <div className="marginTop">{this.generateTable()}</div>
      </div>
    );
  }
}
