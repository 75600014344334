/*
  ExpiredCardsSummaryRequest.js - Handles Search for Expired Cards Data

  Author: Elle (2019)
  Company: VA
*/

// OUR MODULES
import RequestRabbit from '../../../RequestRabbit';
import config from '../../../../Config';

// INSTANTIATE
const handleRequest = new RequestRabbit();

export default class SearchRequest {
  /*
    Get Data for Date Range
  */
  getData = async (fromDate, toDate, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/billing/report/unpaid`;
      const options = { method: 'GET' };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };
}
