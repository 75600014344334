/*
  Add.js - Displays form for adding a new Customer User

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

// COMPONENTS
import CountryPicker from '../../../components/CountryPicker';
import LanguagePicker from '../../../components/LanguagePicker';
import ErrorText from '../../../components/ErrorText';

// I18N
import formattedMessages from './FormattedMessages';

// NETWORK
import UserRequest from './UserRequest';
import {
  validatePhoneNumber,
  validatePassword,
  validateEmail,
  validateSms,
  validateName,
} from '../../../utils/validate';
const userRequest = new UserRequest();

export default class UserAdd extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - errorHandler: A function to call when handling errors
    */

    this.state = {
      error: 'test',
      hasError: false,
      centres: [],
      edits: {},
      isSaving: false,
      password: '',
      password_check: '',
      passwordError: 'Sorry, those passwords dont match.',
    };
  }

  async componentDidMount() {
    let centres = await userRequest.getCentres();
    let { edits } = this.state;
    edits.cost_centre_id = null;
    edits.language_id = null;

    return this.setState({
      centres: centres,
      edits: edits,
    });
  }

  /*
    Receives back the default select language_id
  */
  handleDefaultLanguage = (languageId) => {
    let { edits } = this.state;
    edits.language_id = languageId;
    return this.setState({ edits: edits });
  };

  /*
    Renders Error Text Component
  */
  generateErrorText = () => {
    return (
      <ErrorText hasError={this.state.hasError} errorText={this.state.error} />
    );
  };

  /*
    Handles any changes from the form
  */
  handleChange = (e) => {
    let { edits } = this.state;
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if (name === 'formCountry') name = 'country_code_id';
    edits[name] = value;

    return this.setState({
      edits: edits,
    });
  };

  /*
    Validates form.  Returns whether form is valid and, if not valid,
    returns an error text as well.
  */
  validateForm = async () => {
    let isValidForm = false;
    let errText = '';
    let { edits, centres } = this.state;

    if (!validateName(edits.user_name))
      errText = formattedMessages.errTextUserName;
    else {
      if (
        edits.user_tn &&
        (edits.user_tn.trim() === '' || !validatePhoneNumber(edits.user_tn))
      ) {
        errText = formattedMessages.errCheckPhoneFormat;
      } else if (!edits.user_email) errText = formattedMessages.errTextEmail;
      else if (!validateEmail(edits.user_email))
        errText = formattedMessages.errTextEmailCheck;
      else if (!edits.country_code_id)
        errText = formattedMessages.errTextCountry;
      else if (!edits.language_id) errText = formattedMessages.errTextLanguage;
      else if (!edits.cost_centre_id) {
        let notAssigned = centres.filter((centre) => {
          return centre.cost_centre_name === 'NotAssigned';
        });
        if (notAssigned.length === 1)
          edits.cost_centre_id = notAssigned[0].cost_centre_id;
        else errText = formattedMessages.errTextCostCenter;
      }
      if (!validatePassword(edits.password)) {
        errText = formattedMessages.errCheckPasswordStrong;
      } else if (!edits.password || !edits.password_check)
        errText = formattedMessages.errCheckPassword;
      else if (!(edits.password === edits.password_check))
        errText = formattedMessages.errCheckPasswordMatch;
      if (!edits.password) errText = formattedMessages.errTextPassword;
    }
    if (edits.max_sms_per_day && !validateSms(edits.max_sms_per_day)) {
      errText = formattedMessages.errTextMaxSms;
    }
    if (!edits.max_sms_per_day && edits.max_sms_per_day === '') {
      errText = formattedMessages.errBlankMaxSms;
    }
    // If no error text, form is valid
    if (!errText) isValidForm = true;

    return {
      isValidForm: isValidForm,
      formError: errText,
    };
  };

  /*
    Handles submitting the form
  */
  async handleSubmit() {
    this.setState({
      isSaving: true,
      hasError: false,
      validated: true,
    });

    let validation = await this.validateForm();

    if (validation.isValidForm) {
      let newUserObject = await userRequest.createUser(
        this.state.edits,
        this.props.errorHandler
      );
      if (typeof newUserObject.error === 'undefined') this.props.callBack(true);
      else this.toggleSaving();
    } else {
      await this.setState({
        error: validation.formError,
        hasError: true,
      });
      this.toggleSaving();
    }
  }

  toggleSaving() {
    let toggle = !this.state.isSaving;
    return this.setState({
      isSaving: toggle,
    });
  }

  /*
    Generates the Lander section
  */
  generateLander = () => {
    return (
      <Fragment>
        <p onClick={this.props.callBack} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backToUser}</span>
        </p>
        <h4>{formattedMessages.addNewUser}</h4>
      </Fragment>
    );
  };

  /*
    Generates the Save Button for the form based on the state
  */
  generateSaveButton = () => {
    // If in Save State
    if (this.state.isSaving) return <p>Saving...</p>;
    if (this.state.password === this.state.password_check) {
      // Allow submit
      return (
        <Button
          type="submit"
          className="createProviderButton"
          variant="primary"
          onClick={() => this.handleSubmit()}
        >
          Save
        </Button>
      );
    } else {
      //disable save button and show warning
      return (
        <Fragment>
          <Button
            type="submit"
            className="createProviderButton"
            variant="primary"
            disabled
          >
            Save
          </Button>
          <br />
          <div className="text-danger error textLeft">
            {this.state.passwordError}
          </div>
        </Fragment>
      );
    }
  };

  /*
    Generates the Cost Centre Select Options
  */
  generateCostCentreOptions = () => {
    let options = [];
    let { centres } = this.state;

    // For each Cost Centre, add option
    for (let i = 0; i < centres.length; i++) {
      if (centres[i].cost_centre_name === 'NotAssigned') {
        options.push(
          <option
            selected
            key={centres[i].cost_centre_id}
            value={centres[i].cost_centre_id}
          >
            {'No Cost Centre Assigned'}
          </option>
        );
      } else {
        options.push(
          <option
            key={centres[i].cost_centre_id}
            value={centres[i].cost_centre_id}
          >
            {centres[i].cost_centre_name}
          </option>
        );
      }
    }

    return (
      <Form.Group as={Row} controlId="cost_centre_id">
        <Form.Label column sm={2} className="info-label">
          {formattedMessages.costCentersTitle}:
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="select"
            name="cost_centre_id"
            value={this.state.edits.cost_centre_id}
          >
            {options}
          </Form.Control>
        </Col>
      </Form.Group>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateTextFormGroup = (label, controlId, isRequired, setType) => {
    let type = 'text';
    let defaultValue = false;
    if (setType) type = setType;
    if (controlId === 'max_sms_per_day') defaultValue = 25;
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            required={isRequired || false}
            name={controlId}
            autoComplete="new-password"
            type={type}
            defaultValue={defaultValue ? defaultValue : null}
          />
        </Col>
      </Form.Group>
    );
  };

  generateForm = () => {
    return (
      <Form onSubmit={this.handleSubmit} onChange={this.handleChange}>
        {this.generateTextFormGroup('* User Name', 'user_name', true)}
        {this.generateTextFormGroup(' User Mobile', 'user_tn', true)}
        {this.generateTextFormGroup('* Password', 'password', true, 'password')}
        {this.generateTextFormGroup(
          '* Confirm Password',
          'password_check',
          true,
          'password'
        )}
        {this.generateTextFormGroup('* User Email Address', 'user_email', true)}
        <Form.Group as={Row} controlId="country_code_id">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.country}
          </Form.Label>
          <Col sm={8}>
            <CountryPicker
              className="form-control"
              defaultValue={null}
              selectDefault={true}
              onChange={this.handleChange}
            ></CountryPicker>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="language_id">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.language}
          </Form.Label>
          <Col sm={8}>
            <LanguagePicker
              className="form-control"
              selectedLanguageId={null}
              returnDefaultLanguageId={this.handleDefaultLanguage}
              selectDefault={true}
            ></LanguagePicker>
          </Col>
        </Form.Group>
        {this.generateCostCentreOptions()}
        {this.generateTextFormGroup(
          '* Maximum SMS Per Day',
          'max_sms_per_day',
          false
        )}
        {this.generateErrorText()}
        {this.generateSaveButton()}
      </Form>
    );
  };

  render() {
    return (
      <Fragment>
        {this.generateLander()}
        <br />
        {this.generateForm()}
      </Fragment>
    );
  }
}
