import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';

import formattedMessages from '../FormattedMessages';
import ErrorText from '../../../../components/ErrorText';

// UTIL
import {
  getCharacterCount,
  getMessageParts,
  getMessageMaxLength,
} from '../../../../utils/characterCount';

class SingleView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templateName: this.props.selected
        ? this.props.selected.template_name
        : '',
      templateContent: this.props.selected
        ? this.props.selected.template_text
        : '',
      error: '',
      saveDisabled: props.saveDisabled,
      saving: false,
    };
  }

  /*
    Handles state changes from the Form
  */
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    return this.setState({
      [name]: value,
    });
  };

  handleAccessLevel = () => {
    let { accessLevel, accessType } = this.props;

    return (accessLevel > '0' && accessType !== '0' && this.props.selected) || // if not Customer User
      (this.props.selected && this.props.tab === 'Personal') || // if Customer User edits Personal Template
      !this.props.selected // if Customer User add New Template (Global && Personal), allow fully access; else, show ReadOnly view
      ? false
      : true;
  };

  handleSaveClick = async () => {
    if (!this.state.saving) {
      if (this.state.saveDisabled) {
        // do nothing
      } else {
        if (
          !this.props.selected &&
          (!this.state.templateName || !this.state.templateContent)
        ) {
          return this.setState({
            error: 'Must fill out both fields',
          });
        }

        await this.setState({
          saving: true,
        });

        // Editing Template
        if (this.props.selected) {
          await this.props.handleEdit(
            this.state.templateName,
            this.state.templateContent,
            this.props.errorHandler
          );
        }
        // Creating new Template
        else {
          await this.props.handleSave(
            this.state.templateName,
            this.state.templateContent,
            this.props.errorHandler
          );
        }
        return this.props.callBack(); // Go back to the list
      }
    }
  };

  handleDeleteClick = async () => {
    let id = this.props.selected.template_sms_id;
    await this.props.handleDelete(id);
    return this.props.callBack(); // Go back to the list
  };

  handleCharacterLimitError = (characterCount, messageMaxLength) => {
    if (characterCount > messageMaxLength) {
      return (
        <ErrorText
          hasError={true}
          errorText="Warning, maximum message size exceeded, please confirm message content."
        />
      );
    }
  };

  generateDeleteButton = () => {
    let { accessLevel, accessType } = this.props;

    // AccessLevel: base user = -1, Sales Agent = 0, Help desk rep = 1, Accounts & reports = 2, admin = 3
    // AccessType: base user = 0, customer admin = 1, provider admin = 2, god admin = 3

    if (
      (accessLevel > '0' && accessType !== '0' && this.props.selected) ||
      (this.props.selected && this.props.tab === 'Personal')
    ) {
      return (
        <Button
          onClick={this.handleDeleteClick}
          className="cancelUserType btn-red btn btn-danger"
        >
          {formattedMessages.deleteText}
        </Button>
      );
    }
    return null;
  };

  generateSaveAndCancelButton = (characterCount, messageMaxLength) => {
    let { accessLevel, accessType } = this.props;

    if (
      (accessLevel > '0' && accessType !== '0' && this.props.selected) ||
      (this.props.selected && this.props.tab === 'Personal') ||
      !this.props.selected
    ) {
      return (
        <span>
          <Button
            onClick={this.handleSaveClick}
            className="maxHeight pad-button"
            disabled={
              this.state.saveDisabled || characterCount > messageMaxLength
            }
          >
            {this.state.saving
              ? formattedMessages.saving
              : formattedMessages.saveText}
          </Button>
          <Button
            onClick={this.props.callBack}
            className="maxHeight pad-button"
          >
            {formattedMessages.cancelText}
          </Button>
        </span>
      );
    }

    return null;
  };

  generateError = () => {
    if (!this.state.error) return null;

    return <div className="text-danger error">{this.state.error}</div>;
  };

  render() {
    let { templateContent } = this.state;

    let {
      standardCharacterOnly,
      unicodeCharacterOnly,
      characterCount,
      isUnicodeMessage,
    } = getCharacterCount(templateContent);

    let messagePartCount = getMessageParts(templateContent);
    let messageMaxLength = getMessageMaxLength(isUnicodeMessage);

    return (
      <div>
        <p onClick={this.props.callBack} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backButton}</span>
        </p>
        <h4>
          {this.props.tab} {formattedMessages.templateDetailsTitle}
        </h4>

        <br />
        <Form>
          <Form.Label>{formattedMessages.nameText}</Form.Label>
          <Form.Control
            onChange={this.handleChange}
            name="templateName"
            type="text"
            defaultValue={
              this.props.selected ? this.props.selected.template_name : ''
            }
            readOnly={this.handleAccessLevel()}
          />
          <Form.Label>{formattedMessages.contentText}</Form.Label>
          <Form.Control
            onChange={this.handleChange}
            name="templateContent"
            as="textarea"
            rows="3"
            defaultValue={
              this.props.selected ? this.props.selected.template_text : ''
            }
            readOnly={this.handleAccessLevel()}
            maxLength={messageMaxLength}
          />
          <p className="remindText">
            <span className="bold">
              {characterCount}/{messageMaxLength} Character(s)
            </span>{' '}
            {standardCharacterOnly > 0 &&
              ' = ' + standardCharacterOnly + ' Standard Character(s)'}
            {unicodeCharacterOnly > 0 &&
              ' + ' + unicodeCharacterOnly + ' Unicode Character(s)'}
            <br />
            <span className="bold"> {messagePartCount} Message Parts</span>{' '}
          </p>
          {this.handleCharacterLimitError(characterCount, messageMaxLength)}
        </Form>
        {this.generateError()}
        {this.generateSaveAndCancelButton(characterCount, messageMaxLength)}
        {this.generateDeleteButton()}
      </div>
    );
  }
}

export default SingleView;
