/*
  CommentEdit.js - Comment Edit Component

  Author: Elle Dunbar(2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
// import {Button, Form, Row, Col, Modal, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';

// I18N
import formattedMessages from './FormattedMessages';
import { FormControl, FormGroup, Modal, Button } from 'react-bootstrap';
import FormattedMessages from './Comments/ListComponent/FormattedMessages';

class CommentEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      commentValue: props.item.customer_comments,
      isEditing: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  /*
    Handles changes in the form
*/
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  showConfirmationModal = () => {
    this.setState({
      showConfirmation: true,
    });
  };

  showConfirmationDialog = () => {
    return (
      <Modal
        show={this.state.showConfirmation}
        onHide={this.handleCloseConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deletion Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you would like to delete the comment?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="maxHeight btn btn-primary"
            onClick={() => this.props.onClickDelete(this.props.item)}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="maxHeight btn btn-primary"
            onClick={this.handleCloseConfirmation}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  onClickEdit() {
    let toggle = this.state.isEditing;
    this.setState({
      isEditing: !toggle,
    });
  }

  saveEdits() {
    this.onClickEdit();
    this.props.updateComment(this.props.item, this.state.commentValue);
  }

  render() {
    if (this.state.isEditing) {
      return (
        <Fragment>
          <td className="medium textLeft" name="comment" colSpan="4">
            <form
              onSubmit={() =>
                this.props.updateComment(
                  this.props.item,
                  this.state.commentValue
                )
              }
            >
              <FormGroup controlId="commentValue">
                <FormControl
                  type="text"
                  value={this.state.commentValue}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </form>

            <span className="">
              <button
                className="maxHeight btn btn-primary"
                onClick={() => this.saveEdits()}
              >
                {formattedMessages.save}
              </button>
              <button
                className="maxHeight btn btn-primary"
                onClick={() => this.onClickEdit()}
              >
                {formattedMessages.cancel}
              </button>
            </span>
          </td>
          <td className="medium textRight" name="controlButtons">
            <button
              className="btn add-new-template margin-right-small"
              onClick={() => this.onClickEdit()}
            >
              {FormattedMessages.edit}
            </button>
            <button
              className="btn add-new-template margin-right-small btn-red"
              onClick={this.showConfirmationModal}
            >
              {FormattedMessages.delete}
            </button>
          </td>
          {this.showConfirmationDialog()}
        </Fragment>
      );
    } else {
      //show text
      return (
        <Fragment>
          <td className="medium textLeft" name="comment" colSpan="4">
            {this.props.comment}
          </td>
          <td className="medium textRight dontHide" name="controlButtons">
            <button
              className="btn add-new-template margin-right-small"
              onClick={() => this.onClickEdit()}
            >
              {FormattedMessages.edit}
            </button>
            <button
              className="btn add-new-template margin-right-small btn-red"
              onClick={this.showConfirmationModal}
            >
              {FormattedMessages.delete}
            </button>
          </td>
          {this.showConfirmationDialog()}
        </Fragment>
      );
    }
  }
}

export default CommentEdit;
