/*
  SingleTemplateComponent/index.js - Generates the WebSMS Distribution List
  Single View Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/
// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';

// OUR COMPONENTS
import ContactImporter from '../../../../components/ContactImporter';

// STYLING
import '../../Websms.css';
import { components } from 'react-select';

// FORMATTED MESSAGES
import formattedMessages from '../FormattedMessages';

// NETWORKING
import AddressBookRequest from '../../AddressBook/AddressBookRequest';
import DistributionRequest from '../DistributionRequest';

// INSTANTIATION
const addressBookRequest = new AddressBookRequest();
const distributionRequest = new DistributionRequest();

// COMPONENT STYLES
const controlStyles = {
  borderRadius: '1px solid black',
  color: 'black',
  width: '100%',
};

const ControlComponent = (props) => (
  <div style={controlStyles}>
    <p>{formattedMessages.searchContactText}</p>
    <components.Control {...props} />
  </div>
);

const OptionComponent = (props) => {
  let option = props.data.label;
  option = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <span>{props.data.label}</span>
      <span style={{ fontStyle: 'italic', color: 'DarkGray' }}>
        {props.data.type}
      </span>
    </div>
  );
  return (
    <div>
      <components.Option {...props}>{option}</components.Option>
    </div>
  );
};
class SingleView extends Component {
  constructor(props) {
    super(props);

    /*
      PROPS LIST
      - errorHandler: Function to be called upon to display errors on the UI
      - addNew: Flag for whether adding a new Distribution List or not
      - tab: Either Global or Personal, defines the Distribution List
      - selected: A Distribution List object to display the information for
      - handleDeleteClick:
      - updateDistributionList: Call this to update the distribution lists
    */

    this.state = {
      deleteClicked: true,
      displayTemplatePopup: false,
      contacts: [],
      contactsSelected: this.props.selected ? this.props.selected.contacts : [],
      contactsToAdd: [],
      contactsToRemove: [],
      listName: this.props.selected ? this.props.selected.list_name : '',
      showContactImport: false,
      error: '',
      saving: false,
    };
  }

  async componentDidMount() {
    try {
      let contacts = await addressBookRequest.getContacts(
        this.props.errorHandler
      );
      let mergedContacts = contacts.global.concat(contacts.personal);

      console.table('old style contacts: ', mergedContacts);

      this.setState({
        contacts: mergedContacts,
      });
    } catch (e) {
      console.error('Got Error Loading Contacts For New Distribution List:', e);
    }
  }

  handleConfirmDeleteClick = (selected) => {
    this.setState({
      deleteClicked: false,
    });
    this.props.handleDeleteClick(selected);
  };

  handleAccessLevel = () => {
    let { accessLevel, accessType } = this.props;

    return (accessLevel > '0' && accessType !== '0' && this.props.selected) || // if not Customer User
      (this.props.selected && this.props.tab === 'Personal') || // if Customer User edits Personal Distribution List
      this.props.addNew // if Customer User add New Distribution List (Global && Personal), allow fully access; else, show ReadOnly view
      ? false
      : true;
  };

  handleDeleteClick = () => {
    this.setState({
      displayTemplatePopup: !this.state.displayTemplatePopup,
    });
  };

  /*
    Convenience function to display Template Modal
  */
  handleModal = () => {
    this.setState({
      displayTemplatePopup: !this.state.displayTemplatePopup,
    });
  };

  /*
    Handles state changes from the Form
  */
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    return this.setState({
      [name]: value,
    });
  };

  /*
    Handles returning back from a child component
  */
  handleBackClick = () => {
    return this.setState({
      showContactImport: false,
    });
  };

  /*
    Handles returning back from a child component
  */
  handleImportClick = (contacts) => {
    if (contacts) {
      let { contactsSelected, contactsToAdd } = this.state;
      contacts.contactsToAdd.map((contact) => {
        contactsToAdd.push({
          value: contact.contact_id,
          label: contact.contact_name,
          number: contact.contact_tn,
        });
      });
      let displayItems = contactsSelected.concat(contacts.contactsToAdd);

      return this.setState({
        showContactImport: false,
        contactsToAdd: contactsToAdd,
        contactsSelected: displayItems,
      });
    }
  };

  /*
    Handles when form Save button is clicked.  Sends request
    to parent Component with Distribution List data to save in backend db.
  */
  handleSaveClick = async () => {
    if (!this.state.saving) {
      let { listName, contactsToAdd, contactsToRemove, contactsSelected } =
        this.state;
      let { selected } = this.props;

      if (!listName) {
        return this.setState({
          error: 'Name is required',
        });
      }

      // Checks if the selected is empty.
      if (
        selected &&
        contactsSelected &&
        contactsToAdd &&
        contactsToRemove &&
        contactsSelected.length +
          contactsToAdd.length -
          contactsToRemove.length <=
          0
      ) {
        return this.setState({
          error: 'Contacts are required',
        });
      }

      await this.setState({
        saving: true,
      });

      if (this.props.addNew) {
        // If adding new, create
        await this.props.handleSaveClick(
          listName,
          contactsToAdd,
          this.props.tab
        );
      } else {
        // Otherwise must be editing
        await this.props.handleEditClick(
          listName,
          contactsToAdd,
          contactsToRemove,
          this.props.tab,
          this.props.selected
        );
      }
    }
  };

  /*
    Handles when the Export is clicked for a given Distribution List
  */
  handleExport = async () => {
    try {
      // Make call to export distribution list
      await distributionRequest.exportDistributionList(
        this.props.selected.distribution_list_id,
        this.props.tab,
        this.props.errorHandler
      );

      return this.setState({
        error: '',
      });
    } catch (e) {
      return this.setState({
        error: e.message,
      });
    }
  };

  /*
    Handles when the Import Contacts button is clicked for a given Distribution List
  */
  showContactImporter = () => {
    return this.setState({
      showContactImport: !this.state.showContactImport,
    });
  };

  /*
    onChange handler for the react-select Select element.
    Simply saves the list of selected Contacts for use later
  */
  onSelectChange = (value, action) => {
    let removedContacts = this.state.contactsToRemove;
    // If removing a Contact that is already part of the list...
    if (action.action === 'remove-value' && action.removedValue.preExist) {
      removedContacts.push(action.removedValue);
      let addContacts = this.state.contactsToAdd.filter((contact) => {
        return action.removedValue.value === contact.contact_id;
      });
      return this.setState({
        contactsToRemove: removedContacts,
        contactsToAdd: addContacts,
      });
    }
    // Else if re-adding a Contact that is already part of the list...
    else if (action.action === 'select-option' && action.option.preExist) {
      let removedContacts = this.state.contactsToRemove.filter((contact) => {
        // If not matching, return it
        return action.option.value !== contact.value;
      });
      return this.setState({
        contactsToRemove: removedContacts,
      });
    }
    // Else if adding or removing a new Contact to Dist List
    else {
      return this.setState({
        contactsToAdd: value,
      });
    }
  };

  /*
    Given a contact, checks if they've already been selected for the
    Distribution List previously
  */
  isContactSelected = (contact) => {
    if (this.props.selected && this.state.contactsSelected) {
      let propContacts = this.state.contactsSelected;
      for (let i = 0; i < propContacts.length; i++) {
        if (contact.addressbook_id === propContacts[i].contact_id) return true;
      }
    }
    return false;
  };

  /*
    Generates the correct title at the top of the page based on
    whether it is Adding a New Distribution List or simply viewing it.
  */
  generateTitle = () => {
    if (this.props.addNew) {
      return <h4>{this.props.tab} - Add New</h4>;
    }
    return (
      <h4>
        {this.props.tab} - {formattedMessages.distListDetailsTitle}
      </h4>
    );
  };

  sortList(data) {
    let newList = data.sort(function (a, b) {
      if (a.contact_name < b.contact_name) {
        return -1;
      }
      if (a.contact_name > b.contact_name) {
        return 1;
      }
      return 0;
    });

    return newList;
  }

  /*
    Generates the Select controls for the form, allowing
    a User to add or remove Contacts from the list
  */
  generateUserSelectControls = () => {
    let { contacts, contactsSelected } = this.state;
    let alreadySelected = [];
    let options = [];

    let sortedContacts = contacts.sort((a, b) => {
      if (a.entry_name.toLowerCase() < b.entry_name.toLowerCase()) {
        return -1;
      }
      if (a.entry_name.toLowerCase() > b.entry_name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    if (sortedContacts && sortedContacts.length > 0) {
      sortedContacts.forEach((element) => {
        if (
          this.props.tab === 'Global' &&
          element.entry_type.toLowerCase() === 'global'
        ) {
          options.push({
            value: element.addressbook_id,
            label: element.entry_name,
            preExist: this.isContactSelected(element),
            number: element.number,
            type: element.entry_type || '',
          });
        }
        if (this.props.tab === 'Personal') {
          options.push({
            value: element.addressbook_id,
            label: element.entry_name,
            preExist: this.isContactSelected(element),
            number: element.number,
            type: element.entry_type || '',
          });
        }
      });
    }

    // If we have a Distribution List selected, set alreadySelected list
    if (contactsSelected) {
      let sortedSelectedContacts = contactsSelected.sort(function (a, b) {
        if (a.contact_name < b.contact_name) {
          return -1;
        }
        if (a.contact_name > b.contact_name) {
          return 1;
        }
        return 0;
      });

      sortedSelectedContacts.forEach((contact) => {
        alreadySelected.push({
          value: contact.contact_id,
          label: contact.contact_name,
          preExist: contact.imported ? false : true,
          number: contact.contact_tn || contact.number || '', // contact_tn is the field they use from props.
          type: contact.type || '',
        });
      });
    }
    if (this.props.tab === 'Global') {
      return (
        <div>
          {formattedMessages.addContactsTitle}
          <Select
            defaultValue={alreadySelected}
            isMulti={true}
            options={options}
            closeMenuOnSelect={false}
            onChange={this.onSelectChange}
            isDisabled={this.handleAccessLevel()}
            components={{ Control: ControlComponent, Option: OptionComponent }}
          />
        </div>
      );
    } else if (this.props.tab === 'Personal') {
      return (
        <div>
          {formattedMessages.addContactsTitle}
          <Select
            defaultValue={alreadySelected}
            isMulti={true}
            options={options}
            closeMenuOnSelect={false}
            onChange={this.onSelectChange}
            isDisabled={this.handleAccessLevel()}
            components={{ Control: ControlComponent, Option: OptionComponent }}
          />
        </div>
      );
    }
  };

  generateSaveButton = () => {
    let { accessLevel, accessType } = this.props;

    if (
      (accessLevel > '0' && accessType !== '0' && this.props.selected) ||
      (this.props.selected && this.props.tab === 'Personal') ||
      this.props.addNew
    ) {
      return (
        <Button onClick={this.handleSaveClick} className="maxHeight">
          {this.state.saving
            ? formattedMessages.saving
            : formattedMessages.saveText}
        </Button>
      );
    }
  };

  /*///*/
  generateSecondaryFormButtons = () => {
    let { accessLevel, accessType } = this.props;

    // Adding a new Distribution List
    if (!this.props.selected && this.props.addNew) {
      return (
        <>
          <Button onClick={this.props.callBack} className="maxHeight">
            {formattedMessages.cancelText}
          </Button>
          <Button
            onClick={this.showContactImporter}
            className="maxHeight pad-button"
          >
            {formattedMessages.importText}
          </Button>
        </>
      );
    }

    // AccessLevel: base user = -1, Sales Agent = 0, Help desk rep = 1, Accounts & reports = 2, admin = 3
    // AccessType: base user = 0, customer admin = 1, provider admin = 2, god admin = 3

    // Viewing a Distribution List
    if (
      (accessLevel > '0' && accessType !== '0' && this.props.selected) ||
      (this.props.selected && this.props.tab === 'Personal') ||
      this.props.addNew
    ) {
      return (
        <Fragment>
          <Button
            onClick={() => this.handleDeleteClick(this.props.selected)}
            className="delete btn-red btn btn-danger"
          >
            {formattedMessages.deleteText}
          </Button>
          <Button onClick={this.handleExport} className="maxHeight">
            {formattedMessages.exportText}
          </Button>
          <Button onClick={this.showContactImporter} className="maxHeight">
            {formattedMessages.importText}
          </Button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Button onClick={this.handleExport} className="maxHeight">
            {formattedMessages.exportText}
          </Button>
        </Fragment>
      );
    }
  };

  generateConfirmFormButtons = () => {
    // Viewing a Distribution List
    return (
      <Fragment>
        <Button onClick={() => this.handleModal()} className="maxHeight">
          {formattedMessages.cancelText}
        </Button>

        <Button
          onClick={() => this.handleConfirmDeleteClick(this.props.selected)}
          className="delete btn-red btn btn-danger"
        >
          {this.state.deleteClicked
            ? formattedMessages.deleteText
            : formattedMessages.deleting}
        </Button>
      </Fragment>
    );
  };

  /*
    Displays an Error Message if there is one.
  */
  generateError = () => {
    let { error } = this.state;
    // No error, nothing to show
    if (!error) return null;

    // Return error
    return <div className="text-danger error">{error}</div>;
  };

  render() {
    let { selected, addNew } = this.props;
    let { showContactImport, listName } = this.state;

    // If importing Contacts for a new dist list or an existing one
    if (showContactImport && (selected || addNew)) {
      return (
        <ContactImporter
          handleBackClick={this.handleBackClick}
          tab={this.props.tab}
          accessLevel={this.props.accessLevel}
          accessType={this.props.accessType}
          update={this.handleImportClick}
          errorHandler={this.props.errorHandler}
          distributionListId={
            selected ? selected.distribution_list_id : undefined
          }
        />
      );
    }
    return (
      <>
        <div>
          <p onClick={this.props.callBack} className="backLink">
            <i className="material-icons">keyboard_arrow_left</i>
            <span>{formattedMessages.backButton}</span>
          </p>
          {this.generateTitle()}
          <br />
          <Form>
            <Form.Label>{formattedMessages.nameText}</Form.Label>
            <br />
            <Form.Control
              name="listName"
              type="text"
              value={listName}
              onChange={this.handleChange}
              readOnly={this.handleAccessLevel()}
            />
            {this.generateUserSelectControls()}
          </Form>
          {this.generateError()}
          <br />
          {this.generateSaveButton()}
          {this.generateSecondaryFormButtons()}
        </div>
        <Modal show={this.state.displayTemplatePopup} onHide={this.handleModal}>
          <Modal.Header closeButton>
            {formattedMessages.confirmDeleteDistributionList}
          </Modal.Header>
          <Modal.Body>
            {formattedMessages.distributionListName}
            {selected ? selected.list_name : ''}
            <br />
            {this.generateConfirmFormButtons()}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default SingleView;
