/*
  App.js - App Main File

  Author: Elle Dunbar (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { IntlProvider } from 'react-intl';
import localeData from './locales/data.json';

// OUR MODULES
import LoginRequest from './containers/Login/LoginRequest';
import HomeRequest from './containers/Home/HomeRequest';
import AdminRequest from './containers/Admin/AdminRequest';

// OUR COMPONENTS
import ErrorModal from './components/ErrorModal';
import NavBar from './components/NavBar';

// ROUTING
import AppRoutes from './Routes';

// STYLES
import CloseSVG from './images/close';
import './App.css';

// UTILS
import config from './Config';

// INSTANTIATE
const loginRequest = new LoginRequest();
const homeRequest = new HomeRequest();
const adminRequest = new AdminRequest();

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessLevel, setAccessLevel] = useState(null);
  const [accessType, setAccessType] = useState(null);
  const [user, setUser] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [acting_names, setActingNames] = useState({
    provider_name: null,
    customer_name: null,
  });
  const [language, setLanguage] = useState('en');
  const [isMobile, setIsMobile] = useState(false);
  const [windowLocation, setWindowLocation] = useState(null);
  const [infoStack, setInfoStack] = useState([]);
  const [errorStack, setErrorStack] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [showError, setShowError] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  /*
    Updates the access_level and access_state state vars
  */
  const updateAccessDetails = (accessLevel, accessType) => {
    setAccessLevel(accessLevel); // base user = -1, Sales Agent = 0, Help desk rep = 1, Accounts & reports = 2, admin = 3
    setAccessType(accessType); // base user = 0, customer admin = 1, provider admin = 2, god admin = 3
  };

  const removeError = () => {
    setShowError(false);
  };

  const removeInfo = () => {
    setShowInfo(false);
  };

  const toggleMenuDisplay = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  const resetWindowLocation = () => {
    setShowError(false);
    setWindowLocation(window.location.pathname);
  };

  const displayInfo = () => {
    // If no errors, return empty array
    if (window.location.pathname !== windowLocation) {
      setShowInfo(false);
      setWindowLocation(window.location.pathname);
    }
    if (infoStack && infoStack.length > 0) {
      console.log('infoStack - here: ', infoStack);
      // Otherwise, print component
      return (
        <div className={showInfo ? 'info-card' : 'info-card hide-error'}>
          <p className="info-message float-left">
            <b>Info: </b>
            {infoStack}
            <a
              href="#0"
              className="close-button float-right"
              onClick={() => removeInfo()}
            >
              <CloseSVG />
            </a>
          </p>
        </div>
      );
    }
  };

  const handleInfo = (info) => {
    console.log('The notification is: ' + info);
    setInfoStack(info);
    setShowInfo(true);
  };

  /**
   * Clears imitations of customer/provider and re-renders the display.
   */
  const handleImitationClear = async () => {
    await updateActingCustomerDetails('default');
  };

  const updateActingProviderDetails = async (providerId) => {
    // TODO: merge this and customer detail function
    let { acting_provider_name, acting_customer_name } =
      await adminRequest.updateActingProvider(providerId, handleError);
    console.log(acting_provider_name, acting_customer_name);
    setActingNames({
      provider_name: acting_provider_name,
      customer_name: acting_customer_name,
    });
  };

  const updateActingCustomerDetails = async (customerId) => {
    let { acting_provider_name, acting_customer_name } =
      await adminRequest.updateActingCustomer(customerId, handleError);

    setActingNames({
      provider_name: acting_provider_name,
      customer_name: acting_customer_name,
    });
  };

  const handleError = (error) => {
    // Handle log out upon hitting web session error.
    if (
      error === 'Web Session Error' ||
      error === 'Web Session has expired' ||
      error === 'No Token Found.'
    ) {
      handleLogout();
      return;
    }
    console.log('The error is: ' + error);
    setErrorStack(error);
    setShowError(true);
  };

  /*
  Convenience function to handle logout.
  */
  const handleLogout = async () => {
    await loginRequest.logout(
      localStorage.getItem('rox_user_email'),
      handleError
    );

    localStorage.removeItem('rox_user_token');
    localStorage.removeItem('rox_user_email');

    // TODO: look into converting this into navigate. Would have to clear state though...
    window.location.href = '/';
    setIsAuthenticated(false);
  };

  /*
  Updates whether a User has or has not authenticated
  */
  const userHasAuthenticated = (authenticated, authDetails) => {
    setIsAuthenticated(authenticated);
    setAccessLevel(authDetails.accessLevel); // base user = -1, Sales Agent = 0, Help desk rep = 1, Accounts & reports = 2, admin = 3
    setAccessType(authDetails.accessType); // base user = 0, customer admin = 1, provider admin = 2, god admin = 3
    setActingNames({
      provider_name: authDetails.acting_provider_name,
      customer_name: authDetails.acting_customer_name,
    });

    if (authenticated) {
      refreshUser();
    }
  };

  const refreshUser = async () => {
    try {
      let userData = await homeRequest.getUser(handleError);

      setCustomer(userData.customer);
      setUser(userData.user);
    } catch (e) {
      handleError(e);
    }
  };

  const commonProps = {
    customer: customer,
    user: user,
    isAuthenticated: isAuthenticated,
    userHasAuthenticated: userHasAuthenticated,
    accessLevel: accessLevel,
    accessType: accessType,
    dateFormats: config.dateFormats,
    errorHandler: handleError,
    infoHandler: handleInfo,
    navigate: navigate,
    location: location,
    isMobile: isMobile,
    acting_names: acting_names,
  };

  const nonWebSmsProps = {
    ...commonProps,
    removeError: removeError,
    updateAccessDetails: updateAccessDetails,
    updateActingProviderDetails: updateActingProviderDetails,
    updateActingCustomerDetails: updateActingCustomerDetails,
  };
  const webSmsProps = {
    ...commonProps,
    loading: loading,
    refreshUser: refreshUser,
    mobileMenuOpen: mobileMenuOpen,
    toggleMobileMenu: toggleMenuDisplay,
  };

  useEffect(() => {
    const _handleError = (error) => {
      // Handle log out upon hitting web session error.
      if (
        error === 'Web Session Error' ||
        error === 'Web Session has expired' ||
        error === 'No Token Found.'
      ) {
        _handleLogout();
        return;
      }
      console.log('The error is: ' + error);
      setErrorStack(error);
      setShowError(true);
    };

    /*
    Convenience function to handle logout.
    */
    const _handleLogout = async () => {
      await loginRequest.logout(
        localStorage.getItem('rox_user_email'),
        _handleError
      );

      localStorage.removeItem('rox_user_token');
      localStorage.removeItem('rox_user_email');

      // TODO: look into converting this into navigate. Would have to clear state though...
      window.location.href = '/';
      setIsAuthenticated(false);
    };

    async function getData() {
      console.log('getData called');

      let token = await localStorage.getItem('rox_user_token');
      setWindowLocation(window.location.pathname);
      handleWindowSizeChange();
      window.addEventListener('resize', handleWindowSizeChange);

      // have token?
      if (token) {
        let userData, customer, user, sessionData;

        try {
          //auth state
          sessionData = await homeRequest.getSessionData(_handleError);
          userData = await homeRequest.getUser(_handleError);
        } catch (error) {
          // Handle offline errors here
          console.log('hit: ', error);
          // navigate to offline page

          localStorage.removeItem('rox_user_token');
          localStorage.removeItem('rox_user_email');

          // Head back to the login page
          window.location.href = '/';
        }

        //trigger logout
        if (typeof userData === 'undefined') {
          customer = null;
          user = null;
        } else {
          customer = userData.customer;
          user = userData.user;
        }

        if (sessionData?.redox_cookie) {
          try {
            let redox_cookie = JSON.parse(sessionData.redox_cookie);
            setActingNames({
              provider_name: redox_cookie.acting_provider_name,
              customer_name: redox_cookie.acting_customer_name,
            });
          } catch (e) {
            console.error();
          }
        }

        setAccessLevel(sessionData?.access_level || -1); // base user = -1, Sales Agent = 0, Help desk rep = 1, Accounts & reports = 2, admin = 3
        setAccessType(sessionData?.access_type || 0); // base user = 0, customer admin = 1, provider admin = 2, god admin = 3
        setUser(user);
        setCustomer(customer);
        setLanguage('en'); // TODO: I believe language is to be implemented later on.
        setMobileMenuOpen(false);
      }
      setLoading(false);
    }

    getData();
  }, []);

  console.log('AppRender called');

  // Try full locale, try locale without region code, fallback to 'en'
  const messages = localeData[language];

  // If loading, render spinner
  if (loading) {
    return (
      <div className="centre-screen">
        {<Spinner animation="border" role="status" />}
      </div>
    );

    // Else load pages such as login or register that does not need the navbar.
  } else if (!customer) {
    return (
      <IntlProvider locale={language} messages={messages}>
        <div className="app container">
          <AppRoutes
            nonWebSmsProps={nonWebSmsProps}
            webSmsProps={webSmsProps}
            errorHandler={() => handleError.bind(this)}
            infoHandler={() => handleInfo.bind(this)}
          />
        </div>
      </IntlProvider>
    );
  } else {
    if (isMobile) {
      return (
        <IntlProvider locale={language} messages={messages}>
          <Fragment>
            <div className="visible-device">
              <NavBar mobileView={true} mobileMenuOpen={mobileMenuOpen} />
              <Fragment>
                <ErrorModal
                  errorStack={errorStack}
                  windowLocation={windowLocation}
                  showError={showError}
                  removeError={removeError}
                  resetWindowLocation={resetWindowLocation}
                />
                <AppRoutes
                  nonWebSmsProps={nonWebSmsProps}
                  webSmsProps={webSmsProps}
                  errorHandler={() => handleError.bind(this)}
                  infoHandler={() => handleInfo.bind(this)}
                />
              </Fragment>
            </div>
          </Fragment>
        </IntlProvider>
      );
    } else {
      return (
        <div className="no-scroll">
          <IntlProvider locale={language} messages={messages}>
            <Fragment>
              <div className="visible-desktop">
                <div className="app container">
                  <NavBar
                    accessLevel={accessLevel}
                    accessType={accessType}
                    navBarInfo={{
                      userEmail: user?.user_email,
                      customerName: customer?.customer_name,
                      customerAccountNo: customer?.customer_account_no,
                    }}
                    acting_names={acting_names}
                    handleImitationClear={handleImitationClear}
                    handleLogout={handleLogout}
                  />
                  {displayInfo()}
                  <ErrorModal
                    errorStack={errorStack}
                    windowLocation={windowLocation}
                    showError={showError}
                    removeError={removeError}
                    resetWindowLocation={resetWindowLocation}
                  />
                  <AppRoutes
                    nonWebSmsProps={nonWebSmsProps}
                    webSmsProps={webSmsProps}
                    errorHandler={() => handleError.bind(this)}
                    infoHandler={() => handleInfo.bind(this)}
                  />
                </div>
              </div>
            </Fragment>
          </IntlProvider>
        </div>
      );
    }
  }
}

export default App;

/*
  Old work for footer/language. It used depreacted react-intl work that can 
  be detailed here https://formatjs.io/docs/react-intl/upgrade-guide-3x/#migrate-to-using-native-intl-apis.

  displaySelectedLanguage() {
    let lang = this.state.language;

    switch (lang) {
      case 'en':
        return (<div> <p className='footer-sml'>
          <span onClick={() => this.toggleLanguage('en')}><b>English</b></span> |
          <span onClick={() => this.toggleLanguage('pl')}>Polish</span></p></div>);
      case 'pl':
        return (<div> <p className='footer-sml'>
          <span onClick={() => this.toggleLanguage('en')}>English</span> |
          <span onClick={() => this.toggleLanguage('pl')}><b>Polish</b></span></p></div>);
      default:
        return (<div> <p className='footer-sml'>
          <span onClick={() => this.toggleLanguage('en')}><b>English</b></span> |
          <span onClick={() => this.toggleLanguage('pl')}>Polish</span></p></div>);
    }
  }

  // Handles the Language toggling
  const toggleLanguage = (target) => {
    let language = 'en';
    if (target === 'en' || target === 'pl')
      language = target;
    setLanguage(language);
  };

    // Split locales with a region code (located in componentdidmount)
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
  */
