/*
  FormattedMessages.js - FormattedMessages for Users component

  Author: Julius Klaebe (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  loading: <FormattedMessage id="Global.Loading" defaultMessage="Loading..." />,
  admin: <FormattedMessage id="Global.Admin" defaultMessage="Administrators" />,
  costCenters: (
    <FormattedMessage id="Global.CostCenters" defaultMessage="Cost Centers" />
  ),
  users: <FormattedMessage id="Global.Users" defaultMessage="Users" />,
  findUser: (
    <FormattedMessage id="Global.FindUser" defaultMessage="Find User" />
  ),
};

export default FormattedMessages;
