/*
  FormattedMessages.js - FormattedMessages for Failed Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React from 'react';
import { FormattedMessage } from 'react-intl';

const FormattedMessages = {
  backText: (
    <FormattedMessage
      id="Failed.backText"
      defaultMessage="Back to Failed List"
    />
  ),
  queuedHeader: (
    <FormattedMessage id="Failed.queuedHeader" defaultMessage="Queued" />
  ),
  failedHeader: (
    <FormattedMessage id="Failed.failedHeader" defaultMessage="Failed" />
  ),
  toHeader: <FormattedMessage id="Failed.toHeader" defaultMessage="To" />,
  fromHeader: <FormattedMessage id="Failed.fromHeader" defaultMessage="From" />,
  nameHeader: <FormattedMessage id="Failed.nameHeader" defaultMessage="Name" />,
  statusHeader: (
    <FormattedMessage id="Failed.statusHeader" defaultMessage="Status" />
  ),
  errorCodeHeader: (
    <FormattedMessage id="Failed.errorCodeHeader" defaultMessage="Error Code" />
  ),
  errorTextHeader: (
    <FormattedMessage id="Failed.errorTextHeader" defaultMessage="Error Text" />
  ),
  encodingHeader: (
    <FormattedMessage id="Failed.encodingHeader" defaultMessage="Encoding" />
  ),
  messageHeader: (
    <FormattedMessage id="Failed.messageHeader" defaultMessage="Message" />
  ),
  errorsTitle: (
    <FormattedMessage id="Websms.ErrorsTitle" defaultMessage="Errors" />
  ),
  errorText: <FormattedMessage id="Global.ErrorText" defaultMessage="Error:" />,
  forText: <FormattedMessage id="Global.ForText" defaultMessage="For" />,
  errorActivityLog: (
    <FormattedMessage
      id="Websms.ErrorActivityLog"
      defaultMessage="Error Activity Log"
    />
  ),
  loadingText: (
    <FormattedMessage id="App.LoadingText" defaultMessage="Loading" />
  ),
  tableHeaderUser: (
    <FormattedMessage id="Websms.ErrorsTableHeaderUser" defaultMessage="User" />
  ),
  tableHeaderReason: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderReason"
      defaultMessage="Reason"
    />
  ),
  tableHeaderCustomer: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderCustomer"
      defaultMessage="Customer"
    />
  ),
  tableHeaderErrorNumber: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderErrorNumber"
      defaultMessage="Error Number"
    />
  ),
  tableHeaderDescription: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderDescription"
      defaultMessage="Description"
    />
  ),
  tableHeaderProcess: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderProcess"
      defaultMessage="Process"
    />
  ),
  tableHeaderTo: (
    <FormattedMessage id="Websms.ErrorsTableHeaderTo" defaultMessage="To" />
  ),
  tableHeaderMessageText: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderMessageText"
      defaultMessage="Message"
    />
  ),
  tableHeaderDestTn: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderDestTn"
      defaultMessage="Destination Number"
    />
  ),
  tableHeaderErrorStatus: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderErrorMessage"
      defaultMessage="Error Status"
    />
  ),
  tableHeaderErrorCode: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderErrorCode"
      defaultMessage="Error Code"
    />
  ),
  tableHeaderErrorText: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderErrorText"
      defaultMessage="Error Text"
    />
  ),
  tableHeaderDateTime: (
    <FormattedMessage
      id="Websms.ErrorsTableHeaderDateTime"
      defaultMessage="Date/Time"
    />
  ),
};

export default FormattedMessages;
