import React from 'react';

const ListItem = ({
  id,
  createdDate,
  date,
  customerID,
  customerName,
  customerType,
  customerLocation,
  agent,
  minCommitment,
  alreadyBilled,
}) => (
  <tr key={id}>
    <td className="left medium">
      <span>{createdDate}</span>
    </td>
    <td className="medium">
      <span>{date}</span>
    </td>
    <td className="medium">
      <span>{customerID}</span>
    </td>
    <td className="medium">
      <span>{customerName}</span>
    </td>
    <td className="medium">
      <span>{customerType}</span>
    </td>
    <td className="medium">
      <span>{alreadyBilled ? 'Yes' : 'No'}</span>
    </td>
    <td className="medium">
      <span>{customerLocation}</span>
    </td>
    <td className="medium ">
      <span>{agent || 'No agent'}</span>
    </td>
    <td className="medium column-left">
      <span>{minCommitment}</span>
    </td>
  </tr>
);

export default ListItem;
