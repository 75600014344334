import React from 'react';

const ListItem = ({
  id,
  countryName = '',
  countryCode = '',
  // deliveredByName='',
  onClickName = null,
  country = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="textLeft">
      <span className="blue" onClick={() => onClickName(country)}>
        {countryName}
      </span>
    </td>
    <td className="medium textLeft">
      <span>{countryCode}</span>
    </td>
    {/* <td className='medium textLeft'><span>{deliveredByName}</span></td> */}
  </tr>
);

export default ListItem;
