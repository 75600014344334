// NPM
import React, { Component, Fragment } from 'react';
import { Container, Col, Row, ButtonGroup, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// I18N
import formattedMessages from './FormattedMessages';

// STYLING
import './Websms.css';

// NETWORKING
import LoginRequest from '../Login/LoginRequest';
const loginRequest = new LoginRequest();

/*
  Export functions globally to be called when requested
  all functions return array objects from promise api call
*/
export default class WebSMSTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      default: 0,
      loading: true,
      composeDataRecipient: '',
      composeDataMessage: '',
      composeAutoFill: '',
      content: null,
      tempPersonal: null,
      tempGlobal: null,
      addressPersonal: null,
      addressGlobal: null,
      settings: null,
      tab: props.tab,
    };
  }

  /*
  Convenience function to handle logout.
*/
  handleLogout = async () => {
    await loginRequest.logout(localStorage.getItem('rox_user_email'));
    localStorage.removeItem('rox_user_token');
    localStorage.removeItem('rox_user_email');
    this.setState({
      isAuthenticated: false,
    });
    this.props.navigate('/');
    this.props.navigate(0);
  };

  /*
    Generates the sidebar menu items for use
  */
  generateUserMenu() {
    let tab = this.state.tab;
    return (
      <ButtonGroup vertical defaultValue={this.state.default}>
        <Link to="../compose">
          <Button
            id="compose"
            variant="light"
            className={tab === 'compose' ? 'active' : ''}
            onClick={() => this.props.handlePageReset()}
          >
            {formattedMessages.composeTitle}
          </Button>
        </Link>
        <Link to="../bulk-send">
          <Button
            id="bulk-send"
            variant="light"
            className={tab === 'bulk-send' ? 'active' : ''}
            onClick={() => this.props.handlePageReset()}
          >
            {formattedMessages.bulkSendTitle}
          </Button>
        </Link>
        <Link to="../inbox">
          <Button
            id="inbox"
            variant="light"
            className={tab === 'inbox' ? 'active' : ''}
            onClick={() => this.props.handlePageReset()}
          >
            {formattedMessages.inboxTitle}
          </Button>
        </Link>
        <Link to="../sent">
          <Button
            id="sent"
            variant="light"
            className={tab === 'sent' ? 'active' : ''}
            onClick={() => this.props.handlePageReset()}
          >
            {formattedMessages.sentTitle}
          </Button>
        </Link>
        <Link to="../errors">
          <Button
            id="errors"
            variant="light"
            className={tab === 'errors' ? 'active' : ''}
            onClick={() => this.props.handlePageReset()}
          >
            {formattedMessages.errorsTitle}
          </Button>
        </Link>
        <Link to="../template">
          <Button
            id="template"
            variant="light"
            className={tab === 'template' ? 'active' : ''}
            onClick={() => this.props.handlePageReset()}
          >
            {formattedMessages.templateTitle}
          </Button>
        </Link>
        <Link to="../address">
          <Button
            id="address"
            variant="light"
            className={tab === 'address' ? 'active' : ''}
            onClick={() => this.props.handlePageReset()}
          >
            {formattedMessages.addressBookTitle}
          </Button>
        </Link>
        <Link to="../distribution">
          <Button
            id="distribution"
            variant="light"
            className={tab === 'distribution' ? 'active' : ''}
            onClick={() => this.props.handlePageReset()}
          >
            {formattedMessages.distListTitle}
          </Button>
        </Link>
        <Link to="../settings">
          <Button
            id="settings"
            variant="light"
            className={tab === 'settings' ? 'active' : ''}
            onClick={() => this.props.handlePageReset()}
          >
            {formattedMessages.settingsTitle}
          </Button>
        </Link>
      </ButtonGroup>
    );
  }

  /*
   Generates the sidebar menu items for use
 */
  generateMobileUserMenu() {
    let tab = this.state.tab;
    return (
      //TODO: handle page reset for mobile versions. use case may vary here.
      <ButtonGroup vertical defaultValue={this.state.default}>
        <Link to="../compose">
          <Button
            id="compose"
            variant="light"
            className={tab === 'compose' ? 'active' : ''}
            onClick={() => this.props.toggleMobileMenu()}
          >
            {formattedMessages.composeTitle}
          </Button>
        </Link>
        <Link to="../bulk-send">
          <Button
            id="bulk-send"
            variant="light"
            className={tab === 'bulk-send' ? 'active' : ''}
            onClick={() => this.props.toggleMobileMenu()}
          >
            {formattedMessages.bulkSendTitle}
          </Button>
        </Link>
        <Link to="../inbox">
          <Button
            id="inbox"
            variant="light"
            className={tab === 'inbox' ? 'active' : ''}
            onClick={() => this.props.toggleMobileMenu()}
          >
            {formattedMessages.inboxTitle}
          </Button>
        </Link>
        <Link to="../sent">
          <Button
            id="sent"
            variant="light"
            className={tab === 'sent' ? 'active' : ''}
            onClick={() => this.props.toggleMobileMenu()}
          >
            {formattedMessages.sentTitle}
          </Button>
        </Link>
        <Link to="../errors">
          <Button
            id="errors"
            variant="light"
            className={tab === 'errors' ? 'active' : ''}
            onClick={() => this.props.toggleMobileMenu()}
          >
            {formattedMessages.errorsTitle}
          </Button>
        </Link>
        <Link to="../template">
          <Button
            id="template"
            variant="light"
            className={tab === 'template' ? 'active' : ''}
            onClick={() => this.props.toggleMobileMenu()}
          >
            {formattedMessages.templateTitle}
          </Button>
        </Link>
        <Link to="../address">
          <Button
            id="address"
            variant="light"
            className={tab === 'address' ? 'active' : ''}
            onClick={() => this.props.toggleMobileMenu()}
          >
            {formattedMessages.addressBookTitle}
          </Button>
        </Link>
        <Link to="../distribution">
          <Button
            id="distribution"
            variant="light"
            className={tab === 'distribution' ? 'active' : ''}
            onClick={() => this.props.toggleMobileMenu()}
          >
            {formattedMessages.distListTitle}
          </Button>
        </Link>
        <Link to="../settings">
          <Button
            id="settings"
            variant="light"
            className={tab === 'settings' ? 'active' : ''}
            onClick={() => this.props.toggleMobileMenu()}
          >
            {formattedMessages.settingsTitle}
          </Button>
        </Link>
        <Button
          id="logout"
          variant="light"
          className={tab === 'logout' ? 'active' : ''}
          onClick={() => this.handleLogout()}
        >
          {formattedMessages.logout}
        </Button>
      </ButtonGroup>
    );
  }

  render() {
    const { tab, isMobile } = this.props;

    if (tab === 'home') {
      return (
        <Container>
          {!isMobile && (
            <div className="visible-desktop-sideNav">
              <Row>
                <Col className="Home" sm={12}>
                  {this.props.children}
                </Col>
              </Row>
            </div>
          )}
          {isMobile && (
            <div className="visible-device">
              {this.props.mobileMenuOpen ? (
                <Fragment>
                  <Row>
                    <Col id="mobileNav" className="mobile-nav" sm={12}>
                      {this.generateMobileUserMenu()}
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                <Fragment>
                  <Row>
                    <Col className="Home" sm={12}>
                      {this.props.children}
                    </Col>
                  </Row>
                </Fragment>
              )}
            </div>
          )}
        </Container>
      );
    } else {
      return (
        <Container>
          {!isMobile && (
            <div className="visible-desktop-sideNav">
              <Row>
                <Col sm={3} id="sideNav" className="side-nav">
                  {this.generateUserMenu()}
                </Col>
                <Col className="WebSMS" sm={9}>
                  {this.props.children}
                </Col>
              </Row>
            </div>
          )}
          {isMobile && (
            <div className="visible-device">
              {this.props.mobileMenuOpen ? (
                <Fragment>
                  <Row>
                    <Col id="mobileNav" className="mobile-nav" sm={12}>
                      {this.generateMobileUserMenu()}
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                <Fragment>
                  <Row>
                    <Col
                      className="WebSMS no-padding marginLeft marginRight"
                      sm={12}
                    >
                      {this.props.children}
                    </Col>
                  </Row>
                </Fragment>
              )}
            </div>
          )}
        </Container>
      );
    }
  }
}
