import React from 'react';

const Admin = ({
  id,
  onClickCustomer,
  onClickContact,
  providerName,
  customerName,
  /*type,
                    customerClass,
                    status,*/
  contactName,
  contactEmail,
  contactPhone,
  contactDepartment,
  accessLevel,
  ...props
}) => (
  <tr key={id} id={id}>
    <td className="medium textLeft">{providerName}</td>
    <td className="medium underline blue textLeft" onClick={onClickCustomer}>
      {customerName}
    </td>
    {/*   <td className="medium textLeft">{type}</td>
        <td className="medium textLeft">{customerClass}</td>
        <td className="medium textLeft">{status}</td>*/}
    <td className="medium underline blue textLeft" onClick={onClickContact}>
      {contactName}
    </td>
    <td className="medium textLeft">{contactEmail}</td>
    <td className="medium textLeft">{contactPhone}</td>
    <td className="medium textLeft">{contactDepartment}</td>
    <td className="medium textLeft">{accessLevel}</td>
  </tr>
);

export default Admin;
