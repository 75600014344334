/*
  FormattedMessages.js - FormattedMessages for the System Monitor's Recent Component.

  Author: Adarsh Anup (2022)
  Company: Red Oxygen
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  recentTitle: (
    <FormattedMessage
      id="Global.recentTitle"
      defaultMessage="Recent Messages"
    />
  ),
  filterText: (
    <FormattedMessage id="Global.filterText" defaultMessage="Filter:" />
  ),
  clear: (
    <FormattedMessage id="Websms.ClearFilters" defaultMessage="Clear Filters" />
  ),
};

export default FormattedMessages;
