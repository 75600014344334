/*
  CustomerRequest.js - CustomerRequest Networking File
  
  Contains networking functionality for Customer Components

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';

// CONFIGURATION
import config from '../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class TariffRequest {
  /*
    Used to validate the unique url param of the Customer Verify
    page.
  */
  getTariffSchemes = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/tariff/scheme`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  getSchemeStatusTypes = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/tariff/scheme/extra`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  createNewTariffScheme = async (newTariffScheme, errorHandler) => {
    try {
      let url = `${config.baseServerURL}/admin/tariff/scheme`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(newTariffScheme),
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  updateTariffScheme = async (tariffSchemeId, updates, errorHandler) => {
    try {
      let url = `${config.baseServerURL}/admin/tariff/scheme/${tariffSchemeId}`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(updates),
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  deleteTariffSchemeById = async (tariffSchemeId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/tariff/scheme/${tariffSchemeId}`;
      const options = {
        method: 'DELETE',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      if (response.status > 399 && response.status < 600)
        errorHandler('Failed to delete Tariff Scheme.');
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Gets a list of Tariff Plans for the associated Id.
  */
  getTariffPlans = async (tariffSchemeId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/tariff/scheme/${tariffSchemeId}/plan`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  getPlanExtras = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/tariff/scheme/plan/extra`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Creates a new Tariff Plan for the given Tariff Scheme Id
  */
  createNewTariffPlan = async (tariffSchemeId, newTariffPlan, errorHandler) => {
    try {
      let url = `${config.baseServerURL}/admin/tariff/scheme/${tariffSchemeId}/plan`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(newTariffPlan),
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Updates a Tariff Plan
  */
  updateTariffPlan = async (
    tariffSchemeId,
    tariffPlanId,
    updates,
    errorHandler
  ) => {
    try {
      let url = `${config.baseServerURL}/admin/tariff/scheme/${tariffSchemeId}/plan/${tariffPlanId}`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(updates),
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Deletes a Tariff Plan
  */
  deleteTariffPlan = async (tariffSchemeId, tariffPlanId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/tariff/scheme/${tariffSchemeId}/plan/${tariffPlanId}`;
      const options = {
        method: 'DELETE',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      if (response.status > 399 && response.status < 600)
        errorHandler('Failed to delete Tariff Scheme.');
    } catch (e) {
      errorHandler(e);
    }
  };

  getLineExtras = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/tariff/scheme/plan/line/extra`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Gets a list of Tariff Lines for a give Tariff Scheme
  */
  getTariffLines = async (tariffSchemeId, tariffPlanId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/tariff/scheme/${tariffSchemeId}/plan/${tariffPlanId}/line`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Creates a new Tariff Line for the given Tariff Plan Id
  */
  createNewTariffLine = async (
    tariffSchemeId,
    tariffPlanId,
    newTariffLine,
    errorHandler
  ) => {
    try {
      let url = `${config.baseServerURL}/admin/tariff/scheme/${tariffSchemeId}/plan/${tariffPlanId}/line`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(newTariffLine),
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  updateTariffLine = async (
    tariffSchemeId,
    tariffPlanId,
    tariffLineId,
    updates,
    errorHandler
  ) => {
    try {
      let url = `${config.baseServerURL}/admin/tariff/scheme/${tariffSchemeId}/plan/${tariffPlanId}/line/${tariffLineId}`;
      console.log('url:', url);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(updates),
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  deleteTariffLine = async (
    tariffSchemeId,
    tariffPlanId,
    tariffLineId,
    errorHandler
  ) => {
    try {
      const url = `${config.baseServerURL}/admin/tariff/scheme/${tariffSchemeId}/plan/${tariffPlanId}/line/${tariffLineId}`;
      const options = {
        method: 'DELETE',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      if (response.status > 399 && response.status < 600)
        errorHandler('Failed to delete Tariff Scheme.');
    } catch (e) {
      errorHandler(e);
    }
  };
}
