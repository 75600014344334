import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  nameText: (
    <FormattedMessage
      id="ReportsList.Name"
      defaultMessage="Name"
    ></FormattedMessage>
  ),
  statusText: (
    <FormattedMessage
      id="ReportsList.Status"
      defaultMessage="Status"
    ></FormattedMessage>
  ),
  messagesText: (
    <FormattedMessage
      id="ReportsList.Messages"
      defaultMessage="Messages"
    ></FormattedMessage>
  ),
  remindersText: (
    <FormattedMessage
      id="ReportsList.Reminders"
      defaultMessage="Reminders"
    ></FormattedMessage>
  ),
  standardText: (
    <FormattedMessage
      id="ReportsList.Standard"
      defaultMessage="Standard"
    ></FormattedMessage>
  ),
  directText: (
    <FormattedMessage
      id="ReportsList.Direct"
      defaultMessage="Direct"
    ></FormattedMessage>
  ),
  repliesText: (
    <FormattedMessage
      id="ReportsList.Replies"
      defaultMessage="Replies"
    ></FormattedMessage>
  ),
  sentText: (
    <FormattedMessage
      id="ReportsList.Sent"
      defaultMessage="Sent"
    ></FormattedMessage>
  ),
  pendingText: (
    <FormattedMessage
      id="ReportsList.Pending"
      defaultMessage="Pending"
    ></FormattedMessage>
  ),
  totalText: (
    <FormattedMessage
      id="ReportsList.Total"
      defaultMessage="Total"
    ></FormattedMessage>
  ),
  loadingText: (
    <FormattedMessage
      id="App.LoadingText"
      defaultMessage="Loading"
    ></FormattedMessage>
  ),
  typeClassStatusText: (
    <FormattedMessage
      id="Global.TypeClassStatus"
      defaultMessage="Type - Class - Status"
    ></FormattedMessage>
  ),
  deviceId: (
    <FormattedMessage
      id="Global.DeviceId"
      defaultMessage="Device ID"
    ></FormattedMessage>
  ),
  deviceName: (
    <FormattedMessage
      id="Global.deviceName"
      defaultMessage="Device Name"
    ></FormattedMessage>
  ),
  deviceType: (
    <FormattedMessage
      id="Global.deviceType"
      defaultMessage="Type"
    ></FormattedMessage>
  ),
  replyTn: (
    <FormattedMessage
      id="Global.replyTn"
      defaultMessage="Reply Tn"
    ></FormattedMessage>
  ),
  outTotal: (
    <FormattedMessage
      id="Global.outTotal"
      defaultMessage="OUT"
    ></FormattedMessage>
  ),
  inTotal: (
    <FormattedMessage
      id="Global.inTotal"
      defaultMessage="IN"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
