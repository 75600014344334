import React from 'react';
import { Link } from 'react-router-dom';

const ListItem = ({
  id,
  account,
  name,
  email,
  phone,
  nameOnCard,
  cardType,
  cardNumber,
  expiryDate,
  ...props
}) => (
  <tr key={id}>
    <td className="medium left">
      <span
        className="blue"
        onClick={() =>
          window.history.pushState(
            id,
            'Customer:' + id,
            '/admin/customer/' + id
          )
        }
      >
        <Link to={'/admin/customer/' + id}>{account}</Link>
      </span>
    </td>
    <td className="medium">{name}</td>
    <td className="medium">{email}</td>
    <td className="medium">{phone}</td>
    <td className="medium">{nameOnCard}</td>
    <td className="medium">{cardType}</td>
    <td className="medium">{cardNumber}</td>
    <td className="medium">{expiryDate}</td>
  </tr>
);

export default ListItem;
