import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  recoveryButtonText: (
    <FormattedMessage
      id="PasswordRecoveryIndex.EmailLabel"
      defaultMessage="Email me a recovery link"
    />
  ),
  loading: (
    <FormattedMessage
      id="PasswordRecoveryIndex.SignupButton"
      defaultMessage="Loading..."
    />
  ),
  successText: (
    <FormattedMessage
      id="PasswordRecoveryIndex.SignupLoadingText"
      defaultMessage="Success"
    />
  ),
  emailSent: (
    <FormattedMessage
      id="PasswordRecoveryIndex.SuccessLabel"
      defaultMessage="An email has been sent to"
    />
  ),
  followInstructions: (
    <FormattedMessage
      id="PasswordRecoveryIndex.FollowInstructions"
      defaultMessage="Please follow the instructions included in the email to reset your password."
    />
  ),
  backToLogin: (
    <FormattedMessage
      id="PasswordRecoveryIndex.SuccessLabel"
      defaultMessage="Back To Login"
    />
  ),
  recoverPasswordTitle: (
    <FormattedMessage
      id="PasswordRecoveryIndex.RecoverPasswordTitle"
      defaultMessage="Email"
    />
  ),
  newPassword: (
    <FormattedMessage
      id="PasswordRecoveryIndex.NewPassword"
      defaultMessage="New Password"
    />
  ),
  confirmNewPassword: (
    <FormattedMessage
      id="PasswordRecoveryIndex.ConfirmNewPassword"
      defaultMessage="Confirm New Password"
    />
  ),
  showPassword: (
    <FormattedMessage
      id="PasswordRecoveryIndex.showPassword"
      defaultMessage="Show Password"
    />
  ),
  successfulResetLogin: (
    <FormattedMessage
      id="PasswordRecoveryIndex.SuccessfulResetLogin"
      defaultMessage="Successfully reset password. Please wait..."
    />
  ),
  errCheckPasswordStrong: (
    <FormattedMessage
      id="Global.ErrCheckPasswordStrong"
      defaultMessage="Password is not strong enough. Please ensure there is at 
        least 1 capital letter, number and symbol, and at least 8 Characters."
    />
  ),
  errCheckPassword: (
    <FormattedMessage
      id="Global.ErrCheckPassword"
      defaultMessage="Requires a password in both password fields."
    />
  ),
  errCheckPasswordMatch: (
    <FormattedMessage
      id="Global.ErrCheckPasswordTwo"
      defaultMessage="Passwords don't match."
    />
  ),
};

export default FormattedMessages;
