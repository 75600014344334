/*
  CostCentresComponent.js - Cost Centres Container

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';

// COMPONENTS
import List from './List';
import Add from './Add';
import Edit from './Edit';
import ContactEdit from '../Administrators/Edit';
import UsersTabs from '../index';

// I18N
import formattedMessages from './FormattedMessages';

// NETWORK
import CostCentreRequest from './CostCentreRequest';
const costCentreRequest = new CostCentreRequest();

export default class CentresContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      centres: [],
      addNew: false,
      contactId: null,
      loaded: false,
    };
  }

  // Get Cost Centres
  async componentDidMount() {
    if (this.props.customer && this.props.customer !== null) {
      let centres = await costCentreRequest.getCentres(this.props.errorHandler);
      return this.setState({
        centres: centres,
        loaded: true,
      });
    }
  }

  /*
    Handles when the Add New button is clicked
  */
  handleAddNew = () => {
    return this.setState({ addNew: true });
  };

  /*
    Passed to List Component to handle when the Cost Centre
    Name is clicked
  */
  handleCostCentreClick = (centre) => {
    return this.setState({ selected: centre });
  };

  /*
    Passed to List Component to handle when the Contact
    Name is clicked
  */
  handleContactClick = (centre) => {
    return this.setState({ contactId: centre.customer_contact_id });
  };

  /*
    Handles back button clicks from child Components
  */
  handleBackClick = async (refreshFlag) => {
    let state = {
      selected: null,
      addNew: false,
      contactId: null,
    };

    // Refresh Cost Centre list if flag is present
    if (refreshFlag === true)
      state.centres = await costCentreRequest.getCentres(
        this.props.errorHandler
      );

    return this.setState(state);
  };

  /*
    Generates the Single or List views depending on context
  */
  generateBody = () => {
    const { addNew, selected, contactId } = this.state;

    // If a Contact Name was clicked
    if (contactId) {
      return (
        <ContactEdit
          contactId={contactId}
          handleBackClick={this.handleBackClick}
          errorHandler={this.props.errorHandler}
          noTab={true}
        />
      );
    } else if (addNew) {
      // If Add New was clicked
      return (
        <Add
          handleBackClick={this.handleBackClick}
          errorHandler={this.props.errorHandler}
        />
      );
    } else if (selected) {
      // If a Cost Centre is clicked
      return (
        <Edit
          handleBackClick={this.handleBackClick}
          centre={selected}
          errorHandler={this.props.errorHandler}
        />
      );
    } else {
      return (
        <Fragment>
          <div className="lander flex">
            <h3>{formattedMessages.costCenters}</h3>
            {this.generateAddNewButton()}
          </div>
          <span className="main">
            <List
              list={this.state.centres}
              admins={this.props.admins}
              handleCostCentreClick={this.handleCostCentreClick}
              handleContactClick={this.handleContactClick}
            />
          </span>
        </Fragment>
      );
    }
  };

  /*
    Creates the Add New Button
  */
  generateAddNewButton = () => {
    let { addNew, contactId, selected } = this.state;

    // Return nothing if not on List page
    if (addNew || contactId || selected) return null;

    // Generate Add New Button
    return (
      <Button
        className="d-inline flexRight btn add-new-template"
        onClick={() => this.handleAddNew()}
      >
        {formattedMessages.addNew}
      </Button>
    );
  };

  render() {
    let { accessLevel, accessType } = this.props;

    if (accessLevel <= '0' || accessType === '0') {
      return null;
    }
    if (this.state.loaded) {
      return (
        <UsersTabs tab="costCenters">
          <div className="Home">{this.generateBody()}</div>
        </UsersTabs>
      );
    }

    return (
      <UsersTabs tab="costCenters">
        <div className="Home">{formattedMessages.loading}</div>
      </UsersTabs>
    );
  }
}
