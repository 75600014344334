/*
  FormattedMessages.js - FormattedMessages for Sent Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React from 'react';
import { FormattedMessage } from 'react-intl';

const FormattedMessages = {
  backText: (
    <FormattedMessage id="Sent.backText" defaultMessage="Back to Sent List" />
  ),
  cancelPendingMessage: (
    <FormattedMessage
      id="Sent.cancelPendingMessage"
      defaultMessage="Cancel Pending Message"
    />
  ),
  queuedHeader: (
    <FormattedMessage id="Sent.queuedHeader" defaultMessage="Queued" />
  ),
  sentHeader: <FormattedMessage id="Sent.sentHeader" defaultMessage="Sent" />,
  toHeader: <FormattedMessage id="Sent.toHeader" defaultMessage="To" />,
  statusHeader: (
    <FormattedMessage id="Sent.statusHeader" defaultMessage="Status" />
  ),
  errorCodeHeader: (
    <FormattedMessage id="Sent.errorCodeHeader" defaultMessage="Error Code" />
  ),
  errorTextHeader: (
    <FormattedMessage id="Sent.errorTextHeader" defaultMessage="Error Text" />
  ),
  encodingHeader: (
    <FormattedMessage id="Sent.encodingHeader" defaultMessage="Encoding" />
  ),
  messageHeader: (
    <FormattedMessage id="Sent.messageHeader" defaultMessage="Message" />
  ),
};

export default FormattedMessages;
