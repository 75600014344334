/*
  FormattedMessages.js - FormattedMessages for Inbox Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React from 'react';
import { FormattedMessage } from 'react-intl';

const FormattedMessages = {
  originalTime: (
    <FormattedMessage id="Websms.originalTime" defaultMessage="Date" />
  ),

  tableHeaderUserID: (
    <FormattedMessage id="Websms.tableHeaderUserID" defaultMessage="User ID" />
  ),

  tableFrom: <FormattedMessage id="Websms.tableFrom" defaultMessage="From" />,
  originalMessage: (
    <FormattedMessage id="Websms.originalMessage" defaultMessage="Message" />
  ),

  originalSender: (
    <FormattedMessage id="Websms.originalSender" defaultMessage="Sent Number" />
  ),
  tableHeaderFrom: (
    <FormattedMessage
      id="Websms.tableHeaderFrom"
      defaultMessage="From Number"
    />
  ),
  tableHeaderMessage: (
    <FormattedMessage id="Websms.tableHeaderMessage" defaultMessage="Message" />
  ),

  tableHeaderDate: (
    <FormattedMessage id="Websms.tableHeaderDate" defaultMessage="Date" />
  ),
  tableHeaderParentID: (
    <FormattedMessage
      id="Websms.tableHeaderParentID"
      defaultMessage="Parent SMS ID"
    />
  ),
  tableHeaderReplyID: (
    <FormattedMessage
      id="Websms.tableHeaderReplyID"
      defaultMessage="Reply ID"
    />
  ),
  tableHeaderUpdatedAt: (
    <FormattedMessage
      id="Websms.tableHeaderUpdatedAt"
      defaultMessage="Updated At"
    />
  ),
  backText: (
    <FormattedMessage id="Global.BackText" defaultMessage="Back to Inbox" />
  ),
  searchingText: (
    <FormattedMessage id="App.SearchingText" defaultMessage="Searching" />
  ),
  inboxTitle: (
    <FormattedMessage id="Websms.InboxTitle" defaultMessage="Inbox" />
  ),
  replyButton: (
    <FormattedMessage id="Websms.ReplyButton" defaultMessage="Reply" />
  ),
  forwardButton: (
    <FormattedMessage id="Websms.ForwardButton" defaultMessage="Forward" />
  ),
  forwardEmailButton: (
    <FormattedMessage
      id="Websms.ForwardEmailButton"
      defaultMessage="Forward as Email"
    />
  ),
  smsInformation: (
    <FormattedMessage
      id="Websms.smsInformation"
      defaultMessage="SMS Information"
    />
  ),
  replyDetails: (
    <FormattedMessage id="Websms.replyDetails" defaultMessage="Reply Details" />
  ),
  originalMessageDetails: (
    <FormattedMessage
      id="Websms.originalMessageDetails"
      defaultMessage="Original Message Details"
    />
  ),
  clear: (
    <FormattedMessage id="Websms.clearFilters" defaultMessage="Clear Filters" />
  ),
  filterNoteInbox: (
    <FormattedMessage
      id="Websms.filterNoteError"
      defaultMessage="NOTE: Numbers should be entered as only digits, no spaces or
    +."
    />
  ),
};

export default FormattedMessages;
