import React from 'react';

const ListItem = ({
  id,
  onClick,
  name = '',
  email = '',
  number = '',
  status = '',
  msgmax = '',
  centre = '',
}) => (
  <tr key={id} id={id} className="table-click" onClick={onClick}>
    <td className="medium textLeft">{name}</td>
    <td className="medium textLeft">{email}</td>
    <td className="medium textLeft">{number}</td>
    <td className="medium textLeft">{status}</td>
    <td className="medium textLeft">{msgmax}</td>
    <td className="medium textLeft">{centre}</td>
    <td className="icon">
      <i id={id + 'icon'} className="material-icons floatRight">
        keyboard_arrow_right
      </i>
    </td>
  </tr>
);

export default ListItem;
