/*
TopUserReports.js - Index Component for Customer Reports

Author: Kyle Combeer (2019)
Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';

// OUR COMPONENTS
import ListComponent from './ListComponent';
import ListItem from './ListItem';
import DateRangeSelector from '../../../components/DateRangeSelector';
import ReportTabs from '../index';

// NETWORKING
import TopUserReportRequests from './TopUserReportRequests';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

// INSTANTIATE
const topUserReportRequests = new TopUserReportRequests();

export default class TopUser extends Component {
  constructor(props) {
    super(props);
    /*
      Props List
        - customerData: Array of Customers with report data
    */

    this.state = {
      smsList: [],
      showList: false,
      data: [],
      dataExists: false,
      totals: {
        Direct: 0,
        Standard: 0,
        Replies: 0,
        Sent: 0,
        Pending: 0,
      },
      selectedData: null,
      activePage: 'list',
      fromDate: '',
      toDate: '',
    };
  }

  componentDidMount = async () => {
    if (this.props.customer && this.props.customer !== null) {
      if (this.props.topUserData && this.props.topUserData.length !== 0) {
        // console.log('Have top user data:', this.props.topUserData);
        await this.setState(
          {
            data: this.props.topUserData,
            dataExists: true,
          },
          this.formatData
        );
      }
    }
  };

  /*
    Convenience function to handle page navigation.
    TODO - Use React History to handle this
  */
  handleNavigation(target) {
    window.location.href = '/reports/' + target;
  }

  /*
    Formats the data in a manner required for the Table
    to properly display the data
  */
  formatData = async () => {
    console.log('compare: ', this.state.data);
    try {
      if (this.state.data.length === 0) {
        return this.setState({
          smsList: [],
          dataExists: true,
        });
      } else {
        let data = await this.state.data.map((el) => (
          <ListItem
            key={el.customer_name}
            id={el.customer_account_no}
            name={el.customer_name}
            provider={el.provider_name}
            activeUsers={el.userCount}
            Direct={{
              sent: el.direct_sent,
              parts: el.direct_parts,
            }}
            Standard={{
              sent: el.messages_sent,
              parts: el.messages_parts,
            }}
            Replies={{
              sent: el.replies_sent,
              parts: el.replies_parts,
            }}
            Sent={{
              sent: el.reminders_sent,
              parts: el.reminders_parts,
            }}
            onClickName={() => this.changePage('svCustomer', el)}
          />
        ));
        return this.setState({
          smsList: data,
          dataExists: true,
          showList: true,
        });
      }
    } catch (e) {
      console.log('data error:', e);
    }
  };

  changePage = async (page, value) => {
    if (page === 'svCustomer') {
      let customerId = value.customer_id;
      let providerId = value.provider_id;
      window.history.pushState(
        customerId,
        `Customer:${customerId}`,
        `/admin/customer/${customerId}`
      );
      await this.props.updateActingProviderDetails(providerId);
      await this.props.updateActingCustomerDetails(customerId);
      this.props.navigate('/admin/customer/' + customerId);
    }
  };

  backButton = () => {
    return this.setState({
      activePage: 'list',
    });
  };

  /*
    Handles searching User Data between two dates
  */
  handleTopUserDataSearch = async (fromDate, toDate, timeRange) => {
    //loader
    this.setState({
      dataExists: false,
      showList: false,
    });

    let searchParams = {};
    searchParams.fromDate = fromDate;
    searchParams.toDate = toDate;
    searchParams.timeRange = timeRange;
    searchParams.timeRangeStart = fromDate;
    searchParams.timeRangeEnd = toDate;
    let result = await topUserReportRequests.getReportData(
      searchParams,
      this.props.errorHandler
    );

    this.setState(
      {
        dataExists: true,
        data: result,
        fromDate: fromDate,
        toDate: toDate,
      },
      this.formatData
    );

    return result;
  };

  /*
    Used when clicking on a Top User Customer Id or Customer's Name.  Takes
    you back to the Cost Centre Standard Report
  */
  showCostCentreReport = (value) => {
    console.log('customer id: ', value);
    this.handleNavigation('centers');
  };

  generateLander = () => {
    return (
      <div className="lander">
        <h3>{formattedMessages.topUserReportsTitle}</h3>
      </div>
    );
  };

  /*
    Generates the Search Tools for querying the data
  */
  generateSearchTools = () => {
    return (
      <Fragment>
        <DateRangeSelector
          handleDateSearch={(fromDate, toDate, timeRange) =>
            this.handleTopUserDataSearch(fromDate, toDate, timeRange)
          }
        />
        <br />
        <br />
      </Fragment>
    );
  };

  reloadPage = () => {
    window.location.reload(1);
  };

  render() {
    let { accessLevel, accessType } = this.props;

    if (accessLevel === '3' && accessType === '3') {
      return (
        <ReportTabs
          tab="top-user"
          accessLevel={accessLevel}
          accessType={accessType}
        >
          <div className="Home">
            {this.generateLander()}
            {this.generateSearchTools()}
            <ListComponent
              totals={this.state.totals}
              list={this.state.smsList}
              loading={!this.state.dataExists}
            />
          </div>{' '}
        </ReportTabs>
      );
    }
    return null;
  }
}
