/*
  UserReport.js - Index Component for User Reports

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap';

// COMPONENTS
import ListComponent from './ListComponent';
import ListItem from './ListItem';
import SingleViewUser from './SingleViewUser';
import SingleViewSent from './SingleViewSent';
import SingleViewStandard from './SingleViewStandard';
import SingleViewReplies from './SingleViewReplies';
import SingleViewDirect from './SingleViewDirect';
import SingleViewPending from './SingleViewPending';
import DateRangeSelector from '../../../components/DateRangeSelector';
import ReportTabs from '../index';

// NETWORKING
import UserReportRequest from './UserReportRequests';

// FORMATTED MESSAGES
import FormattedMessages from './FormattedMessages';

// INSTANTIATE
const userReportRequest = new UserReportRequest();

export default class Users extends Component {
  constructor(props) {
    super(props);

    console.log('Got UserReport props:', props);

    this.state = {
      smsList: null,
      data: [],
      activeUsers: [],
      allUsers: [],
      dataExists: false,
      totals: {
        Direct: 0,
        Standard: 0,
        Replies: 0,
        Sent: 0,
        Pending: 0,
      },
      selectedData: null,
      activePage: 'list',
      fromDate: '',
      toDate: '',
      displayLetters: [],
      filterLetter: '',
      loading: true,
      costCentreId: null,
      filterUserId: null,
      filterUserName: null,
      showAll: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.customer && this.props.customer !== null) {
      this.fetchData();
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.customer &&
      this.props.customer !== null &&
      prevProps.location?.state?.selected !==
        this.props.location?.state?.selected
    ) {
      this.fetchData();
    }
  };

  /*
    Fetch the data for the call of initial render
  */
  fetchData = async () => {
    let { userData } = this.props.location?.state || this.props;
    let costCentreId = this.props.location?.state?.selected;
    let userId = undefined;
    if (userData) userId = userData.customer_user_id;

    this.setState({
      costCentreId: costCentreId,
      filterUserId: userId,
      filterUserName: userData ? userData.user_name : null,
    });
  };

  /*
    Formats the data in a manner required for the Table
    to properly display the data
  */
  formatData = async () => {
    try {
      let { accessLevel, accessType } = this.props;
      let total = {
        Direct: 0,
        Standard: 0,
        Replies: 0,
        Sent: 0,
        Pending: 0,
        DirectParts: 0,
        StandardParts: 0,
        RepliesParts: 0,
        SentParts: 0,
        PendingParts: 0,
      };

      let letterMap = {};
      let letters = [];
      let { filterLetter, data } = this.state;
      // Setup HTML table data
      let CleanedData = data
        .filter((el) => {
          if (
            this.state.displayLetters.length === 0 &&
            !letterMap[el.user_email.toUpperCase().charAt(0)]
          ) {
            letterMap[el.user_email.toUpperCase().charAt(0)] = true;
            letters.push(`${el.user_email.toUpperCase().charAt(0)}`);
          }

          if (
            filterLetter === '' ||
            filterLetter.toLowerCase() === el.user_email.charAt(0).toLowerCase()
          ) {
            return true;
          }
        })
        .map((el, index) => {
          // Check if first letter of name is in array

          return (
            <ListItem
              key={`key_${index}`}
              id={index}
              name={el.user_email}
              active={el.user_status === 'A' ? 'Active' : 'Disabled'}
              Direct={{
                sent: el.direct_sent,
                parts: el.direct_parts,
                onClick: () => this.changePage('svDir', el),
              }}
              Standard={{
                sent: el.messages_sent,
                parts: el.messages_parts,
                onClick: () => this.changePage('svStand', el),
              }}
              Replies={{
                sent: el.replies_sent,
                parts: el.replies_parts,
                onClick: () => this.changePage('svRep', el),
              }}
              Sent={{
                sent: el.reminders_sent,
                parts: el.reminders_parts,
                onClick: () => this.changePage('svSent', el),
              }}
              Pending={{
                sent: el.reminders_pending,
                parts: el.reminders_pending_parts,
                onClick: () => this.changePage('svPend', el),
              }}
              isGodAdmin={accessLevel === '3' && accessType === '3'}
              onClickName={() =>
                this.props.navigate('/users/list', {
                  state: { stv: true, selected: el.customer_user_id },
                })
              }
              onClickStatus={() =>
                this.props.navigate('/users/list', {
                  state: { stv: true, selected: el.customer_user_id },
                })
              }
            />
          );
        });
      // Calculate totals for bottom of table
      this.state.data.forEach((el) => {
        if (
          filterLetter === '' ||
          filterLetter.toLowerCase() === el.user_email.charAt(0).toLowerCase()
        ) {
          total.Direct = total.Direct + parseInt(el.direct_sent);
          total.Standard = total.Standard + parseInt(el.messages_sent);
          total.Replies = total.Replies + parseInt(el.replies_sent);
          total.Sent = total.Sent + parseInt(el.reminders_sent);
          total.Pending = total.Pending + parseInt(el.reminders_pending);
          total.DirectParts = total.DirectParts + parseInt(el.direct_parts);
          total.StandardParts =
            total.StandardParts + parseInt(el.messages_parts);
          total.RepliesParts = total.RepliesParts + parseInt(el.replies_parts);
          total.SentParts = total.SentParts + parseInt(el.reminders_parts);
          total.PendingParts =
            total.PendingParts + parseInt(el.reminders_pending_parts);
        }
      });

      let updatedState = {
        smsList: CleanedData,
        totals: total,
      };
      if (letters.length > 0) {
        letters.sort();
        updatedState.displayLetters = letters;
      }
      return this.setState(updatedState);
    } catch (e) {
      console.error('data error:', e);
    }
  };

  changePage = async (page, value) => {
    // Update activePage
    return this.setState({
      activePage: page,
      selectedData: value,
    });
  };

  backButton = async (refreshList) => {
    let state = { activePage: 'list' };
    if (refreshList) {
      state.dataExists = false;
      state.data = [];
    }
    await this.setState(state); // Change page here first

    if (refreshList) {
      // else if no data, fetch initial set
      let { filterUserId } = this.state;
      let now = new Date();
      let weekAgo = new Date();
      let sevenDays = now.getDate() - 7;
      weekAgo.setDate(sevenDays);
      let toDate = now.getTime();
      let fromDate = weekAgo.getTime();
      let { costCentreId } = this.state;
      // Make Request
      let { data } = await userReportRequest.getReportData(
        fromDate,
        toDate,
        null,
        costCentreId,
        filterUserId,
        this.props.errorHandler
      );
      console.log('data', data);
      state.data = data;
      state.filterLetter = '';
      state.dataExists = true;
      state.loaded = true;
    }
    await this.setState(state);
    if (refreshList) this.formatData();
  };

  /*
    Handles click from the 'letter filter' component.
  */
  handleLetterFilter = async (letter) => {
    await this.setState(
      {
        filterLetter: letter,
      },
      this.formatData
    );
  };

  /*
    Gets the object of the selected User from the data list
  */
  getSelectedUser = async (userId) => {
    try {
      let users = this.state.data;
      for (let i = 0; i < users.length; i++) {
        if (userId === users[i].userId) {
          return users[i].user;
        }
      }
    } catch (e) {
      // TODO - Handle Error
    }
  };

  /*
    Handles searching User Data between two dates
  */
  handleUserDataSearch = (fromDate, toDate, timeRange) => {
    try {
      this.setState(
        {
          dataExists: false,
          loading: true,
          data: [],
          activeUsers: [],
          allUsers: [],
        },
        async () => {
          let { costCentreId, filterUserId } = this.state;
          let searchParams = {};
          searchParams.fromDate = fromDate;
          searchParams.toDate = toDate;
          searchParams.timeRange = timeRange;
          searchParams.timeRangeStart = fromDate;
          searchParams.timeRangeEnd = toDate;
          searchParams.costCentreId = costCentreId;
          searchParams.filterLetter = null;
          searchParams.userId = filterUserId;
          let { data } = await userReportRequest.getReportData(
            searchParams,
            this.props.errorHandler
          );
          let displayedList = [];
          let activeUsers = data.filter((e) => {
            return e.user_status === 'A';
          });
          if (this.state.showAll) {
            displayedList = data;
          } else {
            displayedList = activeUsers;
          }

          this.setState(
            {
              dataExists: true,
              data: displayedList,
              activeUsers: activeUsers,
              allUsers: data,
              fromDate: fromDate,
              toDate: toDate,
              filterLetter: '', // reset filter
              displayLetters: [], // reset filter
              loading: false,
            },
            this.formatData
          );
        }
      );
    } catch (e) {
      // TODO - handle error
    }
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    let { filterUserId, filterUserName } = this.state;
    // Display cost centre name if filtering by cost centre
    if (this.props.location?.state?.selected)
      return (
        <div className="lander">
          <h3>{FormattedMessages.topUserReportsTitle}</h3>
          <p>
            {FormattedMessages.filterTitle}{' '}
            {this.props.location.state.selectedName}{' '}
          </p>
        </div>
      );

    // Display user name if filtering by cost centre
    if (filterUserId)
      return (
        <div className="lander">
          <h3>{FormattedMessages.topUserReportsTitle}</h3>
          <p>
            {FormattedMessages.filterUserTitle} {filterUserName}{' '}
          </p>
        </div>
      );

    return (
      <div className="lander">
        <h3>{FormattedMessages.topUserReportsTitle}</h3>
      </div>
    );
  };

  /*
    Generates the Search Tools for querying the data
  */
  generateSearchTools = () => {
    return (
      <Fragment>
        <DateRangeSelector
          handleDateSearch={(fromDate, toDate, timeRange) =>
            this.handleUserDataSearch(fromDate, toDate, timeRange)
          }
        ></DateRangeSelector>
        <br />
        <br />
      </Fragment>
    );
  };

  reloadPage = () => {
    window.location.reload(1);
  };

  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    console.log('handleInputChange event name:', name);
    console.log('handleInputChange event value:', value);

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === 'showAll' && this.state.dataExists) {
          if (value === false) {
            console.log('activeCustomers', this.state.activeUsers);
            this.setState({ data: this.state.activeUsers }, this.formatData);
          } else {
            this.setState({ data: this.state.allUsers }, this.formatData);
          }
        }
      }
    );
  }

  /*
    Generates a 'letter filter' component that allows a User to filter other Users who have the same first letter
    as the one they select.
  */
  generateFirstLetterFilter = () => {
    if (this.props.userData) return null;
    // NOTE: Extra space in end </span> is required to space letters out on display
    if (
      this.state.displayLetters !== undefined &&
      this.state.displayLetters !== 'undefined'
    ) {
      let letterHtml = this.state.displayLetters.map((el) => {
        return (
          <span key={el + '1'}>
            <span
              key={el}
              className="click-object"
              onClick={() => this.handleLetterFilter(el)}
            >
              {el}
            </span>{' '}
          </span>
        );
      });
      return (
        <div>
          <table width="100%" className="no-table-row-background">
            <tbody>
              <tr valign="middle">
                <td>{letterHtml}</td>
                <td align="right" style={{ width: '200px' }}>
                  <Form>
                    <Form.Check
                      type="checkbox"
                      name="showAll"
                      label={'Show All'}
                      defaultChecked={this.state.showAll}
                      onChange={(e) => this.handleInputChange(e)}
                    ></Form.Check>
                  </Form>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };

  render() {
    let { accessLevel, accessType } = this.props;
    if (accessLevel <= '0' || accessType === '0') {
      return null;
    }
    switch (this.state.activePage) {
      case 'svPend':
        return (
          <ReportTabs
            tab="user"
            accessLevel={accessLevel}
            accessType={accessType}
          >
            <SingleViewPending
              user={this.state.selectedData}
              backButton={this.backButton}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              errorHandler={this.props.errorHandler}
            />
          </ReportTabs>
        );
      case 'svDir':
        return (
          <ReportTabs
            tab="user"
            accessLevel={accessLevel}
            accessType={accessType}
          >
            <SingleViewDirect
              user={this.state.selectedData}
              backButton={this.backButton}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              errorHandler={this.props.errorHandler}
            />
          </ReportTabs>
        );
      case 'svRep':
        return (
          <ReportTabs
            tab="user"
            accessLevel={accessLevel}
            accessType={accessType}
          >
            <SingleViewReplies
              user={this.state.selectedData}
              backButton={this.backButton}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              errorHandler={this.props.errorHandler}
            />
          </ReportTabs>
        );
      case 'svSent':
        return (
          <ReportTabs
            tab="user"
            accessLevel={accessLevel}
            accessType={accessType}
          >
            <SingleViewSent
              user={this.state.selectedData}
              backButton={this.backButton}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              errorHandler={this.props.errorHandler}
            />
          </ReportTabs>
        );
      case 'svStand':
        return (
          <ReportTabs
            tab="user"
            accessLevel={accessLevel}
            accessType={accessType}
          >
            <SingleViewStandard
              user={this.state.selectedData}
              backButton={this.backButton}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              errorHandler={this.props.errorHandler}
            />
          </ReportTabs>
        );
      case 'svUser':
        return (
          <ReportTabs
            tab="user"
            accessLevel={accessLevel}
            accessType={accessType}
          >
            <SingleViewUser
              selectedData={this.state.selectedData}
              backButton={this.backButton}
              errorHandler={this.props.errorHandler}
            />
          </ReportTabs>
        );
      case 'svDetail':
        return (
          <ReportTabs
            tab="user"
            accessLevel={accessLevel}
            accessType={accessType}
          >
            <div className="Home">{FormattedMessages.loading}</div>
          </ReportTabs>
        );
      default:
        return (
          <ReportTabs
            tab="user"
            accessLevel={accessLevel}
            accessType={accessType}
          >
            <div className="Home">
              {this.generateLander()}
              {this.generateSearchTools()}
              <ListComponent
                changePage={this.changePage}
                totals={this.state.totals}
                list={this.state.smsList}
                loading={!this.state.dataExists}
                filter={this.generateFirstLetterFilter}
              />
            </div>
          </ReportTabs>
        );
    }
  }
}
