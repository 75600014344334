import React from 'react';
import FormattedMessages from '../ListComponent/FormattedMessages';

const ListItem = ({
  customer_id,
  customer_attachment_id,
  created_at = '',
  customer_contact_name = '',
  attachment_name = '',
  status_flag = '',
  updated_at = '',
  onClickDelete = null,
  onClickOpen = null,
  formatDate = null,
}) => (
  <tr
    key={'list' + customer_attachment_id}
    id={customer_attachment_id}
    className=""
  >
    <td className="medium textLeft" name="date">
      <span>{formatDate(created_at)}</span>
    </td>
    <td className="medium textLeft" name="name">
      <span>{customer_contact_name}</span>
    </td>
    <td colSpan={2} className="medium textLeft" name="file">
      <span>{attachment_name}</span>
    </td>
    <td className="medium textLeft" name="status">
      <span>{status_flag}</span>
    </td>
    <td className="medium textLeft" name="statusDate">
      <span>{formatDate(updated_at)}</span>
    </td>
    <td className="medium textRight dontHide" name="controlButtons" colSpan="2">
      <button
        className="btn add-new-template margin-right-small"
        onClick={() =>
          onClickOpen(customer_id, customer_attachment_id, attachment_name)
        }
      >
        {FormattedMessages.open}
      </button>
      <button
        className="btn add-new-template margin-right-small btn-red"
        onClick={() => onClickDelete(customer_attachment_id)}
      >
        {FormattedMessages.delete}
      </button>
    </td>
  </tr>
);

export default ListItem;
