import React, { Component } from 'react';
import FormattedMessage from './FormattedMessages';
import ListComponent from './ListComponent';
import ListItem from './ListItem';
import BillingTabs from '../../index';

// NETWORKING
import ExpiredCardsSummaryRequest from './ExpiredCardsSummaryRequest';

// INSTANTIATE
const expiredCardsSummaryRequest = new ExpiredCardsSummaryRequest();

class ExpiredCardsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: null,
      totals: null,
      loading: true,
      pageCount: 0,
    };
  }

  componentDidMount = async () => {
    if (this.props.customer && this.props.customer !== null) {
      if (this.props.incomingData && this.props.incomingData.length !== 0) {
        console.log('Have incoming data:', this.props.incomingData);
        await this.setState({
          // data: this.props.incomingData.CHANGETHISVARIABLE,
          // dataExists: true
        });
        return this.formatData();
      } else {
        // else if no data, fetch initial set
        let now = new Date();
        let weekAgo = new Date();
        let sevenDays = now.getDate() - 7;
        weekAgo.setDate(sevenDays);
        let toDate = now.getTime();
        let fromDate = weekAgo.getTime();

        let result = await expiredCardsSummaryRequest.getData(
          fromDate,
          toDate,
          this.props.errorHandler
        );
        //console.log('got traffic in result:', result);
        if (result) {
          let sortedResults = this.sortList(result);

          await this.setState(
            {
              data: sortedResults,
              dataExists: true,
            },
            this.formatData
          );
        }

        // Save to index for instant retrieval
        // this.props.saveSessionData(result, 'trafficInData');
      }
    }
  };

  sortList(data) {
    let newList = data.sort(function (a, b) {
      if (a.customer_name < b.customer_name) {
        return -1;
      }
      if (a.customer_name > b.customer_name) {
        return 1;
      }
      return 0;
    });

    return newList;
  }

  /*
  Formats the data in a manner required for the Table
  to properly display the data
*/
  formatData = async () => {
    try {
      let data = await this.state.data.map((el) => {
        return (
          <ListItem
            key={el.customer_id}
            id={el.customer_id}
            account={el.customer_name}
            name={el.cc_name}
            email={el.contact_email}
            phone={el.contact_tn}
            nameOnCard={el.cc_name}
            cardType={el.cc_type}
            cardNumber={el.cc_last_four}
            expiryDate={el.cc_expiry}
          />
        );
      });
      this.setState({
        list: data,
        loading: false,
      });
      // return this.setState(updatedState);
    } catch (e) {
      console.log('data error:', e);
    }
  };

  changeEvent = (o) => {
    console.log(o);
  };
  handlePagination = (page) => {};
  handleFilter = (startDate, endDate, costFrom, costTo, agent) => {
    console.log(startDate, endDate, costFrom, costTo, agent);
  };

  render() {
    let { accessLevel, accessType } = this.props;

    if (accessLevel === '3' && accessType === '3') {
      if (this.state.loading) {
        return (
          <BillingTabs
            tab="expiredcards"
            accessLevel={accessLevel}
            accessType={accessType}
          >
            {FormattedMessage.loading}
          </BillingTabs>
        );
      } else {
        return (
          <BillingTabs
            tab="expiredcards"
            accessLevel={accessLevel}
            accessType={accessType}
          >
            {/*<p onClick={this.props.callBack} className="backLink"><i className="material-icons">keyboard_arrow_left</i><span>Back</span>*/}
            {/*</p>*/}

            <div className="Home">
              <div className="lander">
                <br />
                <h3>{FormattedMessage.expiredCardsTitle}</h3>
                <br />

                {/*<div className='filter-container'><FilterComponent handleFilter={this.handleFilter} handleToggle={this.handleToggle}/></div>*/}
                <div className="list-container">
                  <ListComponent
                    changePage={this.changePage}
                    list={this.state.list}
                    handlePagination={this.handlePagination}
                    pageCount={this.state.pageCount}
                  />
                </div>
              </div>
            </div>
          </BillingTabs>
        );
      }
    }
    return null;
  }
}

export default ExpiredCardsReport;
