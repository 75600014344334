/*
  FormattedMessages.js - FormattedMessages for WebSMS component

  Author: Julius Klaebe (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  loadingText: (
    <FormattedMessage
      id="App.LoadingText"
      defaultMessage="Loading"
    ></FormattedMessage>
  ),
  templateTitle: (
    <FormattedMessage
      id="Websms.TemplateTitle"
      defaultMessage="Templates"
    ></FormattedMessage>
  ),
  nameText: (
    <FormattedMessage
      id="Websms.NameText"
      defaultMessage="Name"
    ></FormattedMessage>
  ),
  templateDetailsTitle: (
    <FormattedMessage
      id="Websms.TemplateDetailsTitle"
      defaultMessage="Template Details"
    ></FormattedMessage>
  ),
  contentText: (
    <FormattedMessage
      id="Websms.ContentText"
      defaultMessage="Content"
    ></FormattedMessage>
  ),
  backButton: (
    <FormattedMessage
      id="App.BackButton"
      defaultMessage="Back"
    ></FormattedMessage>
  ),
  cancelText: (
    <FormattedMessage
      id="Websms.CancelText"
      defaultMessage="Cancel"
    ></FormattedMessage>
  ),
  saveText: (
    <FormattedMessage id="Websms.Save" defaultMessage="Save"></FormattedMessage>
  ),
  saving: (
    <FormattedMessage
      id="Websms.Saving"
      defaultMessage="Saving..."
    ></FormattedMessage>
  ),
  deleteText: (
    <FormattedMessage
      id="Websms.DeleteText"
      defaultMessage="Delete"
    ></FormattedMessage>
  ),
  searchByName: (
    <FormattedMessage
      id="Websms.SearchByName"
      defaultMessage="Search By Name"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
