/*
  LoginRequest.js - LoginRequest Index File

  Contains networking functionality for Login Components

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../RequestRabbit';

// CONFIGURATION
import config from '../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class LoginRequest {
  /*
    Handles when a User is attempting to log into the Red Oxygen application.
    If successful, returns the auth token on response. Else, returns error message.
  */
  login = async (email, password, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/auth/login`;
      const body = JSON.stringify({
        email: email,
        password: password,
      });
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };
      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      if (response.status === 200) {
        let responseJSON = await response.json();
        // Return token
        return responseJSON;
      }
      return response.status;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Handles when a User is attempting to log out of the Red Oxygen application.
    If successful, returns success status code (204). Else, returns error message.
  */
  logout = async (email, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/auth/signout`;
      const body = JSON.stringify({
        email: email,
      });
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      return response;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
