// NPM
import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
// CONFIG
import config from '../../../../Config';

class ListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.pageLength = config.defaultPL;
  }

  generateMobileTableBody = (data) => {
    let { pageNumber } = this.props;
    let list = data;
    let rows = [];
    let displayList = list.slice(
      (pageNumber - 1) * this.pageLength,
      pageNumber * this.pageLength - 1 + 1
    );
    // TODO: This mobile function should probably call the same as the regular since its missing useful parts such as decoding.
    for (let i = 0; i < displayList.length; i++) {
      let element = displayList[i];
      let createdDate = DateTime.fromISO(element.created_at);
      let rezoned = createdDate
        .setZone(localStorage.getItem('timeShort'), {
          keepLocalTime: false,
        })
        .toFormat('dd-LLL-yyyy h:mm a');

      rows.push(
        <tr
          key={i}
          id={'inbox_table_' + i}
          valign="top"
          onClick={() => this.props.selectMessage(element)}
        >
          <td>
            <b>{rezoned}</b>
            <br />
            {element.dest_tn}
            <br />
            {element.dest_name || element.dest_tn || ''}
            <br />
            {element.message}
            <br />
          </td>
        </tr>
      );
    }
    return rows;
  };

  render() {
    let DateTimeText = (
      <FormattedMessage id="Websms.DateTimeText" defaultMessage="Date/Time" />
    );
    let FromText = (
      <FormattedMessage id="Websms.FromText" defaultMessage="From" />
    );
    let MessageText = (
      <FormattedMessage id="Websms.MessageText" defaultMessage="Message" />
    );
    let NameText = (
      <FormattedMessage id="Websms.NameText" defaultMessage="Name" />
    );
    const { list, data } = this.props;

    if (typeof data.error === 'undefined') {
      return (
        <Fragment>
          <div className="visible-desktop">
            <Table id="inbox">
              <thead>
                <tr key="head">
                  <th className="medium textLeft tableTop">{DateTimeText}</th>
                  <th className="medium textLeft tableTop">{FromText}</th>
                  <th className="medium textLeft tableTop">{NameText}</th>
                  <th className="wide textLeft tableTop">{MessageText}</th>
                </tr>
              </thead>
              <tbody>{list}</tbody>
            </Table>
          </div>
          <div className="visible-device">
            <table id="sent" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th className="medium textLeft tableTop"></th>
                </tr>
              </thead>
              <tbody>{this.generateMobileTableBody(data)}</tbody>
            </table>
          </div>
        </Fragment>
      );
    }

    return null;
  }
}

export default ListComponent;
