// OUR MODULES
import RequestRabbit from '../../containers/RequestRabbit';

// CONFIGURATION
import config from '../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class TimezoneRequest {
  /*
      Gets the current user's timezone and offset.
      This should only happen on login and when saving settings in WebSMS
      (That's where user's can change their timezones.)
    */
  getTimezoneOffset = async (errorHandler) => {
    let url = `${config.baseServerURL}/timezone/offset`;
    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    if (response.status > 399 && response.status < 600) return [];

    let responseJSON = await response.json();
    return responseJSON;
  };
}
