/*
  FormattedMessages.js - FormattedMessages for editComponent within Single View Customer of topuser reports Component

  Author: Julius Klaebe (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  name: (
    <FormattedMessage id="Global.Name" defaultMessage="Name"></FormattedMessage>
  ),
  department: (
    <FormattedMessage
      id="Global.Department"
      defaultMessage="Department"
    ></FormattedMessage>
  ),
  accessLevel: (
    <FormattedMessage
      id="Global.AccessLevel"
      defaultMessage="Access Level"
    ></FormattedMessage>
  ),
  contactType: (
    <FormattedMessage
      id="Global.ContactType"
      defaultMessage="Contact Type"
    ></FormattedMessage>
  ),
  addNew: (
    <FormattedMessage
      id="Global.AddNew"
      defaultMessage="Add New"
    ></FormattedMessage>
  ),
  fullAdminAccess: (
    <FormattedMessage
      id="Global.FullAdminAccess"
      defaultMessage="Full Administration Access"
    ></FormattedMessage>
  ),
  accountAndReport: (
    <FormattedMessage
      id="Global.AccountAndReport"
      defaultMessage="Accounts and Reports"
    ></FormattedMessage>
  ),
  helpDesk: (
    <FormattedMessage
      id="Global.HelpDesk"
      defaultMessage="Help desk"
    ></FormattedMessage>
  ),
  noAccessLevel: (
    <FormattedMessage
      id="Global.NoAccessLevel"
      defaultMessage="No access Level"
    ></FormattedMessage>
  ),
  primaryTech: (
    <FormattedMessage
      id="Global.PrimaryTech"
      defaultMessage="Primary Technical"
    ></FormattedMessage>
  ),
  secondTech: (
    <FormattedMessage
      id="Global.SecondTech"
      defaultMessage="Secondary Technical"
    ></FormattedMessage>
  ),
  primaryBill: (
    <FormattedMessage
      id="Global.PrimaryBill"
      defaultMessage="Primary Billing"
    ></FormattedMessage>
  ),
  secondBill: (
    <FormattedMessage
      id="Global.SecondBill"
      defaultMessage="Secondary Billing"
    ></FormattedMessage>
  ),
  primarySales: (
    <FormattedMessage
      id="Global.PrimarySales"
      defaultMessage="Primary Sales"
    ></FormattedMessage>
  ),
  secondSales: (
    <FormattedMessage
      id="Global.SecondSales"
      defaultMessage="Secondary Sales"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
