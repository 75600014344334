import React from 'react';

const ListItem = ({
  date = '',
  routeId = '',
  destTn = '',
  originTn = '',
  smsId = '',
  totalMessageProcessing = '',
  ...props
}) => (
  <tr key={`list${routeId}`} id={routeId}>
    <td className="" name="date">
      {date}
    </td>
    <td className="" name="routeId">
      {routeId}
    </td>
    <td className="" name="destTn">
      {destTn}
    </td>
    <td className="" name="originTn">
      {originTn}
    </td>
    <td className="" name="smsId">
      {smsId}
    </td>
    <td className="" name="totalMessageProcessing">
      {totalMessageProcessing}
    </td>
  </tr>
);

export default ListItem;
