/*
  Admin.js - Admin Main Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';

// COMPONENTS
import List from './List';
import SingleView from './Single';

// NETWORK
import TariffRequest from './TariffRequest';
import AdminTabs from '../index';

const tariffRequest = new TariffRequest();

export default class Tariff extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tariffSchemes: [],
      selectedScheme: null,
      selectedPlanId: null,
      addNewTariffScheme: false,
    };
  }

  async componentDidMount() {
    if (this.props.customer && this.props.customer !== null) {
      const tariffSchemes = await this.getTariffSchemes();

      this.setState({
        tariffSchemes: tariffSchemes,
      });

      if (
        this.props.location?.state?.tariffPlanId &&
        this.props.location?.state?.tariffSchemeId
      ) {
        const { tariffPlanId, tariffSchemeId } = this.props.location.state;
        const selectedScheme = tariffSchemes.find(
          (e) => e.tariff_scheme_id === tariffSchemeId
        );
        if (selectedScheme)
          this.setState({ selectedScheme, selectedPlanId: tariffPlanId });
      }
    }
  }

  /*
    Handles when a back button is clicked
  */
  handleBackClick = async (refreshFlag) => {
    let defaultState = {
      selectedScheme: null,
      selectedPlanId: null,
      addNewTariffScheme: false,
    };

    if (refreshFlag) {
      let tariffSchemes = await tariffRequest.getTariffSchemes(
        this.props.errorHandler
      );
      defaultState.tariffSchemes = tariffSchemes;
    }
    return this.setState(defaultState);
  };

  addNewTariffScheme = () => {
    return this.setState({
      addNewTariffScheme: true,
    });
  };

  /*
    Used to fetch list of Providers to display.  These can be viewed by ROX God Admin only
  */
  getTariffSchemes = async () => {
    let tariffSchemes = await tariffRequest.getTariffSchemes(
      this.props.errorHandler
    );
    console.log('tariffSchemes are:', tariffSchemes);

    return tariffSchemes;
  };

  /*
    Handles when a Tariff Scheme is selected
  */
  showSingleScheme = (scheme) => {
    return this.setState({
      selectedScheme: scheme,
    });
  };

  generateHeader = () => {
    return <div>Tariff Scheme</div>;
  };

  sortListForTable() {
    let { tariffSchemes } = this.state;

    let sortedTariffs = tariffSchemes.sort(function (a, b) {
      if (a.tariff_scheme_name < b.tariff_scheme_name) {
        return -1;
      }
      if (a.tariff_scheme_name > b.tariff_scheme_name) {
        return 1;
      }
      return 0;
    });

    return sortedTariffs;
  }

  /*
    Convenience method that works out which Component to display
  */
  generateContent = () => {
    // If a tariff scheme has been selected, Show the Single View for it
    if (this.state.selectedScheme || this.state.addNewTariffScheme) {
      return (
        <SingleView
          tariffScheme={this.state.selectedScheme}
          selectedPlanId={this.state.selectedPlanId}
          isNew={this.state.addNewTariffScheme}
          backButtonClick={this.handleBackClick}
          errorHandler={this.props.errorHandler}
        />
      );
    }
    return (
      <List
        tariffSchemes={this.sortListForTable()}
        addNewTariffScheme={this.addNewTariffScheme}
        showSingleTariffScheme={this.showSingleScheme}
      />
    );
  };

  render() {
    let { accessLevel, accessType } = this.props;

    if (accessLevel === '3' && accessType === '3') {
      return (
        <AdminTabs
          tab="tariff"
          accessLevel={accessLevel}
          accessType={accessType}
        >
          {this.generateContent()}
        </AdminTabs>
      );
    }
    return null;
  }
}
