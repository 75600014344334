/*
  FormattedMessages.js - FormattedMessages for user/costcentercomponent/singleview component

  Author: Julius Klaebe (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  name: (
    <FormattedMessage id="Global.Name" defaultMessage="Name"></FormattedMessage>
  ),
  contact: (
    <FormattedMessage
      id="Global.Contact"
      defaultMessage="Contact"
    ></FormattedMessage>
  ),
  numberOfUsers: (
    <FormattedMessage
      id="Global.NumberofUsers"
      defaultMessage="Number of Users"
    ></FormattedMessage>
  ),
  addNew: (
    <FormattedMessage
      id="Global.AddNew"
      defaultMessage="Add New"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
