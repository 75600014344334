import React from 'react';

const ListItem = ({
  id,
  deviceName = '',
  customer = {},
  user = {},
  userEmail = '',
  phoneNumber = '',
  className = '',
  onClickDeviceName = null,
  onClickCustomerName = null,
  onClickEmail = null,
  onClickNumber = null,
  device = null,
}) => {
  let userDisplay;
  // Handles cases where device has dedicated user id but the user id does not exist in customer user table
  if (user === null) userDisplay = 'User missing for given device';
  else userDisplay = user.user_email || 'N/A';

  return (
    <tr key={`list${id}`} id={id} className={className}>
      <td className="textLeft" width="10%">
        <span>{id}</span>
      </td>
      <td className="">
        <span className="blue" onClick={() => onClickDeviceName(device)}>
          {deviceName}
        </span>
      </td>
      <td className="">
        <span
          className={customer.customer_name ? 'blue' : ''}
          onClick={() => onClickCustomerName(customer)}
        >
          {customer.customer_name || 'N/A'}
        </span>
      </td>
      <td className="">
        <span>{customer.customer_status || 'N/A'}</span>
      </td>
      <td className="">
        <span
          className={userEmail ? 'blue' : ''}
          onClick={() => onClickEmail(user)}
        >
          {userDisplay}
        </span>
      </td>
      <td className="">
        <span
          className={phoneNumber ? 'blue' : ''}
          onClick={() => onClickNumber(device)}
        >
          {phoneNumber}
        </span>
      </td>
    </tr>
  );
};

export default ListItem;
