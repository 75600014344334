/*
  RecoverPasswordRequest.js - Password Recovery Networking File

  Author: Elle Dunbar
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../RequestRabbit';

// CONFIGURATION
import config from '../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class RecoverPasswordRequest {
  /*
    Creates the initial request to the Customer started off registering.
    Returns null if success, otherwise returns a JSON object containing
  */
  recoverPassword = async (email, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/auth/recovery`;
      const body = JSON.stringify({
        email: email,
      });
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      if (response.status === 204) return;
      let errorJson = await response.json();

      return errorJson;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
  Used to validate the unique url param of the Customer Verify
  page.
*/
  verifyURL = async (uniqueLink, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/auth/verify/url`;
      const body = JSON.stringify({
        uniqueLink: uniqueLink,
      });
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      console.log('rsp:', responseJSON);
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  setNewPassword = async (email, password, newPassword, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/auth/password`;
      const body = JSON.stringify({
        email: email,
        password: password,
        newPassword: newPassword,
      });
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
