import React from 'react';

const BlackListItem = ({
  id,
  list,
  onClick,
  entryId = '',
  number = '',
  date = '',
  ...props
}) => (
  <tr key={list + id} id={id} className="table-click" onClick={onClick}>
    <td>{entryId}</td>
    <td>{number}</td>
    <td>{date}</td>
    <td>
      <i id={id + 'icon'} className="material-icons floatRight">
        keyboard_arrow_right
      </i>
    </td>
  </tr>
);

export default BlackListItem;
