/*
  index.js - Find Users Main Component

  Author: Elle Dunbar (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';

// COMPONENTS
import ListView from './ListComponent';
import Paginator from '../../../components/Paginator';
import AdminSearch from './AdminSearch';
import AdminTabs from '../index';

// REPORTS
import Users from '../../Reports/UserReport';
import Centres from '../../Reports/CostCentre';

// NETWORK
import UserRequest from '../../Users/UserRequests';

// I18N
import formattedMessages from './FormattedMessages';

// STYLING
import '../../Users/Users.css';

const userRequest = new UserRequest();

export default class AdminFindCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      phone: '',
      showResults: false,

      hideSearchForm: false,
      stv: false,
      selected: null,
      selectedIndex: null,
      searchTitle: '',
      adminFormsPersistedState: null,
      errorState: false,
      pageNumber: 1,
      paginatedResults: [],
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'email') {
      value = value.toLowerCase();
    }

    return this.setState({
      [name]: value,
    });
  };

  persistAdminState(state) {
    this.setState({
      adminFormsPersistedState: state,
    });
  }

  submitSearch = async (type, ...params) => {
    this.setState({
      showResults: false,
    });

    // params.concat(this.props.errorHandler);
    params.push(this.props.errorHandler);
    console.log('here: ', params);

    let results = [];
    //todo: connect search endpoints here
    console.log('type: ', type);
    console.log('params: ', params);

    // Perform a regular User search
    if (type === 'SEARCH - USER') {
      // 0 - name, 1 - email, 2 - mob
      results = await userRequest.searchForUser(
        params[0],
        params[1],
        params[2]
      );
    } else if (type === 'ADMIN SEARCH - CUSTOMER') {
      // if (type === 'ADMIN SEARCH - CUSTOMER') {
      console.log('Got searchForAdminCustomer');
      // 0 - customer_id, 1 - customer_name, 2 - customer_domain
      // 3 - customer_auth, 4 - customer_ip_address
      results = await userRequest.searchForAdminCustomer(
        params[0],
        params[1],
        params[2],
        params[3],
        params[4]
      );
      this.setState({
        hideSearchForm: true,
        searchTitle: type,
      });
    } else if (type === 'ADMIN SEARCH - USER') {
      // 0 - user_id, 1 - user_name, 2 - user_email, 3 - user_phone
      results = await userRequest.searchForAdminUser(
        params[0],
        params[1],
        params[2],
        params[3]
      );
      this.setState({
        hideSearchForm: true,
        searchTitle: type,
      });
    } else if (type === 'ADMIN SEARCH - ADMIN') {
      // 0 - admin_name, 1 - admin_email
      results = await userRequest.searchForAdminUserAdmin(params[0], params[1]);
      this.setState({
        hideSearchForm: true,
        searchTitle: type,
      });
    } else if (type === 'ADMIN SEARCH - EMAIL ERROR') {
      // 1 - error_email
      results = await userRequest.searchForAdminErrorEmail(params[1]);
      this.setState({
        hideSearchForm: true,
        searchTitle: type,
      });
    } else if (type === 'ADMIN SEARCH - MESSAGES VIA DESTINATION') {
      // 0 - destination_number
      results = await userRequest.searchForAdminDestTn(params[0]);
      this.setState({
        hideSearchForm: true,
        searchTitle: type,
      });
    }

    console.log('Got search results:', results);

    if (!results.error) {
      //on success
      this.setState({
        showResults: true,
        results: results,
      });
      this.changeEvent(0, results);
    } else {
      this.setState({
        errorState: true,
      });
    }
  };

  /*
    Handles receiving data back from ListView - ListItem click
  */
  selectUser = async (id, type, providerId, customerId) => {
    if (type === 'customer_user') {
      console.log('Clicked on a customer user');
      // handles undefined
      if (typeof id === 'undefined') {
        return this.setState({
          stv: false,
          selected: null,
          type: type,
        });
      }

      window.history.pushState(id, `User:${id}`, `/Users/list`);

      // imitates provider and customer and links to the users drilldown.
      await this.props.updateActingProviderDetails(providerId);
      await this.props.updateActingCustomerDetails(customerId);
      this.props.navigate('/users/list', {
        state: {
          stv: true,
          selected: id,
        },
      });
    } else if (type === 'user_report') {
      if (typeof id === 'undefined') {
        return this.setState({
          stv: false,
          selected: null,
          type: type,
        });
      }

      let selectedCustomerUser;
      let i;
      // Find selected User from passed in ID
      for (i = 0; i < this.state.results.length; i++) {
        if (this.state.results[i].customer_user_id === id) {
          selectedCustomerUser = this.state.results[i];
          break;
        }
      }

      // imitates provider and customer and links to the users drilldown.
      await this.props.updateActingProviderDetails(providerId);
      await this.props.updateActingCustomerDetails(customerId);
      this.props.navigate('/reports/user', {
        state: { userData: selectedCustomerUser },
      });
      console.log('Selected Customer User:', selectedCustomerUser);

      return this.setState({
        stv: true,
        selected: selectedCustomerUser,
        type: type,
        selectedIndex: i,
      });
    } else if (type === 'admin_contact') {
      // window.location.href = '/reports/cost-center';
      if (typeof id === 'undefined') {
        return this.setState({
          stv: false,
          selected: null,
          type: type,
        });
      }
      console.log('Clicked on an admin');
      window.history.pushState(id, `Admin:${id}`, `/users/administrators`);
      // imitates provider and customer and links to the admin drilldown.
      await this.props.updateActingProviderDetails(providerId);
      await this.props.updateActingCustomerDetails(customerId);
      this.props.navigate('/users/administrators', {
        state: { selected: id, isHidingList: true },
      });
    } else if (type === 'customer') {
      // imitates provider and customer and links to the customer drilldown.
      console.log('Clicked on a customer');
      window.history.pushState(id, `Customer:${id}`, `/admin/customer/${id}`);
      await this.props.updateActingProviderDetails(providerId);
      await this.props.updateActingCustomerDetails(id);
      this.props.navigate('/admin/customer/' + id);
    } else if (type === 'provider') {
      // imitates provider and links to the provider drilldown.
      console.log('Clicked on a provider');
      await this.props.updateActingProviderDetails(id);
      this.props.navigate('/admin/provider', {
        state: { showSingleProvider: true, selectedProvider: id },
      });
    } else if (type === 'email_recipient') {
      console.log('Clicked on a email recipient');
      // window.location.href = '/reports/cost-center';
      if (typeof id === 'undefined') {
        return this.setState({
          stv: false,
          selected: null,
          type: type,
        });
      }

      let selectedEmailRecipient;

      let i;
      // Find selected User from passed in ID
      for (i = 0; i < this.state.results.length; i++) {
        if (this.state.results[i].email_id === id) {
          selectedEmailRecipient = this.state.results[i];
          break;
        }
      }
      this.props.navigate('/reports/cost-center', {
        state: { userData: selectedEmailRecipient },
      });
      console.log('Selected Customer User:', selectedEmailRecipient);

      return this.setState({
        stv: true,
        selected: selectedEmailRecipient,
        type: type,
        // selectedIndex: i
      });
    } else if (type === 'destination_number') {
      console.log('Clicked on a destination number');
    }
  };

  changeEvent = (i, resultsInitial) => {
    let { results } = this.state;
    if (!results) results = resultsInitial;
    let paginatedResults = results.slice(0 + i * 10, 10 + i * 10);
    return this.setState({
      pageNumber: i + 1,
      paginatedResults: paginatedResults,
    });
  };

  stvCallback = () => {
    this.setState({
      stv: false,
    });
  };

  showResults() {
    if (typeof this.state.results === 'undefined') {
      return (
        <span className="main">{formattedMessages.errorReturningResults}</span>
      );
    } else {
      if (this.state.results.length === 0) {
        return <span className="main">{formattedMessages.noResultsFound}</span>;
      }
    }
    console.log(this.state.paginatedResults);
    return (
      <Fragment>
        <span className="main">
          <ListView
            list={this.state.paginatedResults}
            callBack={this.selectUser}
            type={this.state.searchTitle}
          />
          <Paginator
            itemCount={this.state.results.length}
            changeEvent={this.changeEvent}
            changePageFunction={(ref) => (this.resetFunction = ref)}
            pageLength={10}
          />
        </span>
      </Fragment>
    );
  }

  adminBack() {
    this.setState({
      showResults: false,
      hideSearchForm: false,
      stv: false,
      selected: null,
      selectedIndex: null,
      searchTitle: '',
      adminFormsPersistedState: {
        user_name: '',
        user_email: '',
        user_phone: '',
        user_id: '',
        customer_id: '',
        customer_name: '',
        customer_domain: '',
        customer_auth: '',
        customer_ip_address: '',
        admin_name: '',
        admin_email: '',
        error_name: '',
        error_email: '',
        destination_number: '',
        displayResults: false,
        searchType: null,
      },
      errorState: false,
      pageNumber: 1,
      paginatedResults: [],
      results: null,
    });
  }

  generateBackButton = () => {
    return (
      <p onClick={() => this.adminBack()} className="backLink">
        <i className="material-icons">keyboard_arrow_left</i>
        <span>Back</span>
      </p>
    );
  };

  /**
   *  Resets the page's info when the tab is clicked.
   */
  handlePageReset = () => {
    this.adminBack();
  };

  render() {
    let { accessLevel, accessType } = this.props;
    if (accessLevel !== '3' || accessType !== '3') {
      return null;
    }
    //show admin search forms
    if (this.state.errorState) {
      return (
        <AdminTabs
          tab="findCustomer"
          accessLevel={accessLevel}
          accessType={accessType}
          handlePageReset={this.handlePageReset}
        >
          Error
        </AdminTabs>
      );
    } else {
      if (accessLevel === '3' && accessType === '3') {
        console.log('testing', this.state);
        if (this.state.type === 'customer_user') {
          return (
            <div>
              {this.state.stv ? ( //check if single view if true...
                <span className="singleView">
                  <Users userData={this.state.selected} />
                </span>
              ) : (
                // if not single view...
                <AdminTabs
                  tab="findCustomer"
                  accessLevel={accessLevel}
                  accessType={accessType}
                  handlePageReset={this.handlePageReset}
                >
                  {this.state.hideSearchForm ? (
                    ''
                  ) : (
                    <AdminSearch
                      searchHandler={this.submitSearch}
                      state={this.state.adminFormsPersistedState}
                      stateHandler={(state) => this.persistAdminState(state)}
                    />
                  )}
                  {this.state.searchTitle === '' ? (
                    ''
                  ) : (
                    <Fragment>
                      {this.generateBackButton()}
                      <h3>{this.state.searchTitle}</h3>
                    </Fragment>
                  )}
                  <br />
                  <div className="find-users-results">
                    {this.state.showResults ? this.showResults() : null}
                  </div>
                </AdminTabs>
              )}
            </div>
          );
        } else if (
          this.state.type === 'admin_contact' ||
          this.state.type === 'customer' ||
          this.state.type === 'email_recipient'
        ) {
          return (
            <div>
              {this.state.stv ? ( //check if single view if true...
                <span className="singleView">
                  <Centres costCentre={this.state.selected} />
                </span>
              ) : (
                // if not single view...
                <AdminTabs
                  tab="findCustomer"
                  accessLevel={accessLevel}
                  accessType={accessType}
                  handlePageReset={this.handlePageReset}
                >
                  {this.state.hideSearchForm ? (
                    ''
                  ) : (
                    <AdminSearch
                      searchHandler={this.submitSearch}
                      state={this.state.adminFormsPersistedState}
                      stateHandler={(state) => this.persistAdminState(state)}
                    />
                  )}
                  {this.state.searchTitle === '' ? (
                    ''
                  ) : (
                    <Fragment>
                      {this.generateBackButton()}
                      <h3>{this.state.searchTitle}</h3>
                    </Fragment>
                  )}
                  <br />
                  <div className="find-users-results">
                    {this.state.showResults ? this.showResults() : null}
                  </div>
                </AdminTabs>
              )}
            </div>
          );
        } else {
          return (
            <div>
              {this.state.stv ? ( //check if single view if true...
                <span className="singleView">
                  <Centres costCentre={this.state.selected} />
                </span>
              ) : (
                // if not single view...
                <AdminTabs
                  tab="findCustomer"
                  accessLevel={accessLevel}
                  accessType={accessType}
                  handlePageReset={this.handlePageReset}
                >
                  {this.state.hideSearchForm ? (
                    ''
                  ) : (
                    <AdminSearch
                      searchHandler={this.submitSearch}
                      state={this.state.adminFormsPersistedState}
                      stateHandler={(state) => this.persistAdminState(state)}
                    />
                  )}
                  {this.state.searchTitle === '' ? (
                    ''
                  ) : (
                    <Fragment>
                      {this.generateBackButton()}
                      <h3>{this.state.searchTitle}</h3>
                    </Fragment>
                  )}
                  <br />
                  <div className="find-users-results">
                    {this.state.showResults ? this.showResults() : null}
                  </div>
                </AdminTabs>
              )}
            </div>
          );
        }
      } else {
        if (accessLevel !== '3' || accessType !== '3') {
          // show regular search forms
          return (
            <AdminTabs
              tab="findCustomer"
              accessLevel={accessLevel}
              accessType={accessType}
              handlePageReset={this.handlePageReset}
            >
              <div className="Home find-users">
                {this.state.stv ? (
                  <span className="singleView">
                    {
                      //<SingleView
                      // callBack={this.stvCallback}
                      // selected={this.state.selected}
                      // centres={this.props.centres}
                      // edit={this.editExisting}
                      // delete={this.deleteExisting}
                      // add={this.addNew}
                      // />
                    }
                    <Users userData={this.state.selected} />
                  </span>
                ) : (
                  <Fragment>
                    <h3>{formattedMessages.searchForUser}</h3>
                    <table
                      border="0"
                      cellPadding="5px"
                      style={{ marginLeft: '50px' }}
                      width="80%"
                      className="no-table-row-background"
                    >
                      <tbody>
                        <tr valign="top">
                          <td width="30%">{formattedMessages.name}</td>
                          <td>
                            <input
                              className="find-users input-name"
                              type="text"
                              name="name"
                              value={this.state.name}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </td>
                        </tr>
                        <tr valign="top">
                          <td width="30%">{formattedMessages.email}</td>
                          <td>
                            <input
                              className="find-users input-email"
                              type="text"
                              name="email"
                              value={this.state.email}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </td>
                        </tr>
                        <tr valign="top">
                          <td width="30%">{formattedMessages.cell}</td>
                          <td>
                            <input
                              className="find-users input-phone"
                              type="text"
                              name="phone"
                              value={this.state.phone}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" align="right">
                            <Button
                              className="btn btn-primary maxHeight"
                              onClick={() =>
                                this.submitSearch(
                                  'SEARCH - USER',
                                  this.state.name,
                                  this.state.email,
                                  this.state.phone
                                )
                              }
                            >
                              {formattedMessages.find}
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <div className="find-users-results">
                      {this.state.showResults ? this.showResults() : null}
                    </div>
                  </Fragment>
                )}
              </div>
            </AdminTabs>
          );
        }
      }
    }
  }
}
