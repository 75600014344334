import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  loadingText: (
    <FormattedMessage
      id="App.LoadingText"
      defaultMessage="Loading"
    ></FormattedMessage>
  ),
  settingsTitle: (
    <FormattedMessage
      id="Websms.SettingsTitle"
      defaultMessage="Settings"
    ></FormattedMessage>
  ),
  deliveryReceiptsText: (
    <FormattedMessage
      id="Websms.deliveryReceiptsText"
      defaultMessage="Delivery Receipts"
    ></FormattedMessage>
  ),
  deliveryReceiptsTip: (
    <FormattedMessage
      id="Websms.deliveryReceiptsTip"
      defaultMessage="Will send an email with confirmation of message delivery."
    ></FormattedMessage>
  ),
  mobileText: (
    <FormattedMessage
      id="Websms.MobileText"
      defaultMessage="Phone Number"
    ></FormattedMessage>
  ),
  originatorText: (
    <FormattedMessage
      id="Websms.OriginatorText"
      defaultMessage="Message Originator"
    ></FormattedMessage>
  ),
  languageText: (
    <FormattedMessage
      id="Websms.LanguageText"
      defaultMessage="Language"
    ></FormattedMessage>
  ),
  timeOffsetText: (
    <FormattedMessage
      id="Websms.TimeOffsetText"
      defaultMessage="Time Offset"
    ></FormattedMessage>
  ),
  password: (
    <FormattedMessage
      id="Websms.Password"
      defaultMessage="Change Password"
    ></FormattedMessage>
  ),
  confirmPassword: (
    <FormattedMessage
      id="Websms.ConfirmPassword"
      defaultMessage="Confirm New Password"
    ></FormattedMessage>
  ),
  inboxTitle: (
    <FormattedMessage
      id="Websms.InboxTitle"
      defaultMessage="Inbox"
    ></FormattedMessage>
  ),
  passwordMisMatch: (
    <FormattedMessage
      id="Websms.PasswordMisMatch"
      defaultMessage="Passwords don't match."
    ></FormattedMessage>
  ),
  passwordCheck: (
    <FormattedMessage
      id="Websms.PasswordCheck"
      defaultMessage="Requires a password in both password fields."
    />
  ),
  passwordNotSecure: (
    <FormattedMessage
      id="Websms.PasswordNotSecure"
      defaultMessage="Password is not strong enough. Please ensure it is 8 or more characters long and contains at least one uppercase and one lowercase letter, a number and a symbol."
    ></FormattedMessage>
  ),
  messageDefaultText: (
    <FormattedMessage
      id="Websms.MessageDefaultText"
      defaultMessage="Show message replies for the past"
    ></FormattedMessage>
  ),
  signatureText: (
    <FormattedMessage
      id="Websms.SignatureText"
      defaultMessage="Your Signature"
    ></FormattedMessage>
  ),
  dayPluralText: (
    <FormattedMessage
      id="Websms.DayPluralText"
      defaultMessage="day(s)"
    ></FormattedMessage>
  ),
  saveText: (
    <FormattedMessage
      id="Websms.SaveText"
      defaultMessage="Save"
    ></FormattedMessage>
  ),
  cancelText: (
    <FormattedMessage
      id="Websms.CancelText"
      defaultMessage="Cancel"
    ></FormattedMessage>
  ),
  invalidPhoneText: (
    <FormattedMessage
      id="Websms.InvalidPhoneText"
      defaultMessage="Incorrect user mobile format, should only contain numbers and '+', eg: +0123456789/0123456789"
    ></FormattedMessage>
  ),
  missingPhoneText: (
    <FormattedMessage
      id="Websms.MissingPhoneText"
      defaultMessage="Please enter a phone number in order to forward replies to your mobile phone"
    ></FormattedMessage>
  ),
  forwardReplies: (
    <FormattedMessage
      id="Websms.forwardReplies"
      defaultMessage="Forward replies to :"
    />
  ),
  forwardEmailText: (
    <FormattedMessage
      id="Websms.ForwardEmailText"
      defaultMessage="My email"
    ></FormattedMessage>
  ),
  forwardMobileText: (
    <FormattedMessage
      id="Websms.ForwardMobileText"
      defaultMessage="My mobile phone"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
