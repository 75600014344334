// NPM MODULES
import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap';
// import { FormattedMessage } from 'react-intl';

// OUR COMPONENTS
// import Customers from './Customers';
// import Centres from './CostCentre';
// import Users from './UserReport';
// import Incoming from './Incoming';
// import Outgoing from './Outgoing';
// import Provider from './Provider';
// import TopUser from './TopUser';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

// STYLING
import './Reports.css';
import { Link } from 'react-router-dom';

export default class ReportTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: props.tab,
    };
  }

  /*
  Generates the sidebar menu items for use
*/
  generateUserMenu() {
    let isGodAdmin =
      this.props.accessLevel === '3' && this.props.accessType === '3';

    let tab = this.state.tab;
    return (
      <ButtonGroup vertical defaultValue={this.state.default}>
        {isGodAdmin ? (
          <Link to="../customer">
            <Button
              id="customer"
              variant="light"
              className={tab === 'customer' ? 'active' : ''}
            >
              {formattedMessages.customerButton}
            </Button>
          </Link>
        ) : null}
        <Link to="../cost-center">
          <Button
            id="costCenter"
            variant="light"
            className={tab === 'costCenter' ? 'active' : ''}
          >
            {formattedMessages.costCentreButton}
          </Button>
        </Link>
        <Link to="../user">
          <Button
            id="user"
            variant="light"
            className={tab === 'user' ? 'active' : ''}
          >
            {formattedMessages.userButton}
          </Button>
        </Link>
        <Link to="../message">
          <Button
            id="message"
            variant="light"
            className={tab === 'message' ? 'active' : ''}
          >
            {formattedMessages.messageButton}
          </Button>
        </Link>
        {isGodAdmin ? (
          <Link to="../incoming">
            <Button
              id="incoming"
              variant="light"
              className={tab === 'incoming' ? 'active' : ''}
            >
              {formattedMessages.incomingButton}
            </Button>
          </Link>
        ) : null}
        {isGodAdmin ? (
          <Link to="../outgoing">
            <Button
              id="outgoing"
              variant="light"
              className={tab === 'outgoing' ? 'active' : ''}
            >
              {formattedMessages.outgoingButton}
            </Button>
          </Link>
        ) : null}
        {isGodAdmin ? (
          <Link to="../provider">
            <Button
              id="provider"
              variant="light"
              className={tab === 'provider' ? 'active' : ''}
            >
              {formattedMessages.providerButton}
            </Button>
          </Link>
        ) : null}
        {isGodAdmin ? (
          <Link to="../top-user">
            <Button
              id="top-user"
              variant="light"
              className={tab === 'top-user' ? 'active' : ''}
            >
              {formattedMessages.topUserButton}
            </Button>
          </Link>
        ) : null}
      </ButtonGroup>
    );
  }

  render() {
    let { accessLevel, accessType } = this.props;

    if (accessLevel <= '0' || accessType === '0') {
      return null;
    }

    return (
      <Container>
        <div className="visible-desktop-sideNav">
          <Row>
            <Col sm={3} id="sideNav" className="side-nav">
              {this.generateUserMenu()}
            </Col>
            <Col className="Reports" sm={9}>
              {this.props.children}
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
