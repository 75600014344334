/*
  CostCentreRequest.js - Networking for Cost Centres

  Author: Kyle Combeer (2020)
  Company: Kyle Combeer
*/

// CONFIG
import config from '../../../Config';

// NETWORK
import RequestRabbit from '../../RequestRabbit';
const handleRequest = new RequestRabbit();

export default class CostCentreRequest {
  /*
    Fetches all Cost Centres for a given Customer's id
  */
  getCentres = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/centre/`;
      let options = {
        method: 'GET',
      };
      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Creates a new Cost Center
  */
  createCostCentre = async (data, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/centre/`;
      const header = {
        'Content-Type': 'application/json',
      };
      let options = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(data),
      };
      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Updates a given Cost Centre
  */
  editCostCentre = async (costCentreId, edits, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/centre/${costCentreId}`;
      const headers = {
        'Content-Type': 'application/json',
      };
      let options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(edits),
      };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Deletes a given Cost Centre
  */
  deleteCostCentre = async (costCentreId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/centre/${costCentreId}`;
      let options = {
        method: 'DELETE',
      };

      // Request
      await handleRequest.request(url, options, errorHandler);
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Gets Users for a given Cost Centre
  */
  getUsersForCostCentre = async (costCentreId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/centre/${costCentreId}/users`;
      let options = {
        method: 'GET',
      };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
