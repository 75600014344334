import React, { Component, Fragment } from 'react';
import ListItem from './ListItem';
import InlineListItem from './InlineListItem';
import SubInlineListItem from './SubInlineListItem';
import formattedMessages from './../FormattedMessages';

class UserUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      activePage: 0,
      pages: [],
      pagination: null,
      pagModal: false,
      typedPage: 0,
    };
  }

  componentDidMount = () => {
    this.props.getDataFunction();
  };

  changeEvent = (o) => {
    console.log(o);
  };

  generateTable = () => {
    const { userUsageData } = this.props;

    let content;
    // If do not have user usage data or users list, return message noting this
    if (!userUsageData || userUsageData.length === 0) {
      content = formattedMessages.NoDetailsFound;

      // Else if prepaid, return message noting this
    } else if (userUsageData === 'prepaid') {
      content = formattedMessages.NoDetailsPrepaid;
    } else {
      let data = [];

      // Iterate over data for each cost centre
      for (let i = 0; i < userUsageData.length; i++) {
        const { users, cost_centre_id, cost_centre_name } = userUsageData[i];
        let userArray = [];

        // Iterate over data for each user in the cost centre
        for (let j = 0; j < users.length; j++) {
          const user = users[j];
          let dateArray = [];

          // Iterate over data for each date entry for the user
          for (let k = 0; k < user.dates.length; k++) {
            let date = user.dates[k];
            dateArray.push(
              <Fragment key={k}>
                {
                  <SubInlineListItem
                    key={k}
                    revenue_date={date.revenue_date}
                    direct_sent={date.direct_sent}
                    messages_sent={date.messages_sent}
                    reminders_sent={date.reminders_sent}
                    replies_sent={date.replies_sent}
                    binary_sent={date.binary_sent}
                    total={date.total}
                    premium_sent={date.premium_sent}
                  />
                }
              </Fragment>
            );
          }
          userArray.push(
            <Fragment key={j}>
              <InlineListItem
                key={j}
                user_name={user.user_name}
                total_messages={user.total_messages}
              />
              {dateArray}
            </Fragment>
          );
        }
        data.push(
          <Fragment key={cost_centre_id}>
            <ListItem
              id={cost_centre_id}
              key={i}
              costCenter={cost_centre_name}
            />
            {userArray}
          </Fragment>
        );
      }
      content = (
        <table className="invoice-table wide-table user-usage">
          <tbody>
            <tr>
              <td className="medium tableTop">
                {formattedMessages.costCenter}
              </td>
              <td className="medium tableTop">{formattedMessages.userName}</td>
              <td className="medium tableTop">{formattedMessages.date}</td>
              <td className="medium tableTop">{formattedMessages.direct}</td>
              <td className="medium tableTop">{formattedMessages.standard}</td>
              <td className="medium tableTop">{formattedMessages.reminders}</td>
              <td className="medium tableTop">{formattedMessages.replies}</td>
              <td className="medium tableTop">
                {formattedMessages.international}
              </td>
              <td className="medium tableTop">{formattedMessages.others}</td>
              <td className="medium tableTop">{formattedMessages.total}</td>
            </tr>
            {data}
          </tbody>
        </table>
      );
    }

    return content;
  };

  render() {
    if (this.props.userUsageData) {
      return (
        <Fragment>
          <h2>{formattedMessages.userUsageDetails}</h2>
          <div>
            <p>
              <b>
                {this.props.billDates.start_date} {formattedMessages.to}{' '}
                {this.props.billDates.end_date}
              </b>
            </p>
            {this.generateTable()}
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div>{formattedMessages.loading}</div>
        </Fragment>
      );
    }
  }
}

export default UserUsage;
