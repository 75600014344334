/*
  Billing.js - Account Main Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// STYLING
import './Billing.css';

// I18N
import formattedMessages from './FormattedMessages';

export default class BillingTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: props.tab,
      loading: true,
      content: null,
      selected: null,
      customerType: props.customerType,
    };
  }

  /*
   Generates the sidebar menu items for use
 */
  generateUserMenu() {
    let isGodAdmin =
      this.props.accessLevel === '3' && this.props.accessType === '3';
    let customerType = this.state.customerType;
    let tab = this.state.tab;
    return (
      <ButtonGroup vertical defaultValue={this.state.default}>
        <Link to="../invoices">
          <Button
            id="invoices"
            variant="light"
            className={tab === 'invoices' ? 'active' : ''}
          >
            {formattedMessages.invoicesText}
          </Button>
        </Link>
        <Link to="../payment-method">
          <Button
            id="paymentmethod"
            variant="light"
            className={tab === 'paymentmethod' ? 'active' : ''}
          >
            {formattedMessages.paymentMethod}
          </Button>
        </Link>
        {isGodAdmin ? (
          <Link to="../summary">
            <Button
              id="billing"
              variant="light"
              className={tab === 'summary' ? 'active' : ''}
            >
              {formattedMessages.billingsSummary}
            </Button>
          </Link>
        ) : null}
        {isGodAdmin ? (
          <Link to="../new-customer-report">
            <Button
              id="new-customer-report"
              variant="light"
              className={tab === 'new-customer-report' ? 'active' : ''}
            >
              {formattedMessages.newCustomerReport}
            </Button>
          </Link>
        ) : null}
        {isGodAdmin ? (
          <Link to="../commission-report">
            <Button
              id="commission"
              variant="light"
              className={tab === 'commission' ? 'active' : ''}
            >
              {formattedMessages.commissionReport}
            </Button>
          </Link>
        ) : null}
        {isGodAdmin ? (
          <Link to="../expired-cards">
            <Button
              id="expiredcards"
              variant="light"
              className={tab === 'expiredcards' ? 'active' : ''}
            >
              {formattedMessages.expiredCardsReport}
            </Button>
          </Link>
        ) : null}
        {Number(this.props.accessLevel) >= 1 &&
        Number(this.props.accessType) >= 1 &&
        customerType === 'B' ? (
          <Link to="../purchase">
            <Button
              id="puchasemessages"
              variant="light"
              className={tab === 'purchase' ? 'active' : ''}
            >
              {formattedMessages.purchaseMessages}
            </Button>
          </Link>
        ) : null}
      </ButtonGroup>
    );
  }

  render() {
    let { accessLevel, accessType } = this.props;
    if (accessLevel <= '0' || accessType === '0') return null;

    return (
      <Container>
        <div className="visible-desktop-sideNav">
          <Row>
            <Col sm={3} id="sideNav" className="side-nav">
              {this.generateUserMenu()}
            </Col>
            <Col className="Billing" sm={9}>
              {this.props.children}
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
