/*
  AddressBookRequest.js - Networking for the Address Book content

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';
// CONFIG
import config from '../../../Config';
// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class AddressBookRequest {
  /*
    Makes a request to fetch all Contacts relating to the User.  This includes
    the Global Contacts list for the Customer, as well as the User's own Personal Contacts list.
    Inputs:
      - None
    Outputs:
      - An object containing two keys: 'global' and 'personal', each containing an array of
      Contact Objects.
  */
  getContacts = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/contacts`;
      const options = {
        method: 'GET',
      };

      // Get Contacts
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (err) {
      errorHandler(err);
    }
  };

  /*
    Takes a Contact Object and sends it as a POST request to the back-end in order to
    insert a new Contact.
    Inputs:
      - contact: Requires first_name, last_name, email, number and entry_type.
        Optionals are middle_name
    Outputs:
      - Newly inserted Contact Object
  */
  insertContact = async (contact, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/contacts`;
      const body = JSON.stringify(contact);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        headers: headers,
        body: body,
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Takes a Contact Object and sends it as a POST request to the back-end in order
    to update a Contact.
    Inputs:
      - contact: Requires entry_type and addressbook_id.
        Optionals are first_name, middle_name, last_name, email and number
    Outputs:
      - Updated Contact Object
  */
  updateContact = async (contact, errorHandler) => {
    const url = `${config.baseServerURL}/addressbook/contacts/${contact.addressbook_id}`;
    const body = JSON.stringify(contact);
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: body,
    };

    try {
      // Update Contact
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Takes a Contact ID and deletes it from the back-end.
    Inputs:
      - contactId: Address Book Contact Id
      - entryType: (Query) Either 'personal' or 'global'
    Outputs:
      - Success or Failure Status Code
  */
  deleteContact = async (contactId, entryType, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/contacts/${contactId}?entry_type=${entryType}`;
      const options = {
        method: 'DELETE',
      };

      // Request
      await requestRabbit.request(url, options, errorHandler);
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Used to count the number of Contacts (Global and Personal) for the
    calling Customer User
  */
  countContacts = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/contacts/count`;
      const options = {
        method: 'GET',
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Deletes ALL Personal Contacts for the calling Customer User
  */
  deleteAllContacts = async (selected, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/contacts/delete/${selected}`;
      const options = {
        method: 'DELETE',
      };

      // Update Contact
      let response = await requestRabbit.request(url, options, errorHandler);
      if (response.status === 204) return true;
      errorHandler('Failed to delete all Contacts of type ' + selected);
    } catch (err) {
      errorHandler(err);
    }
  };

  exportContacts = async (data, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/export`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        // method: 'GET'
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      if (response.status === 200) {
        let downloadUrl = `${config.baseServerURL}/addressbook/download/${responseJSON.filename}`;
        window.open(downloadUrl);
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Takes a blacklist Object and sends it as a POST request to the back-end in order to
    insert a new global blacklist record.
    @param blacklistId: customer blacklist Id
    @param data: 'number':'610123456789','entry_type':('global'/'personal')
    @return
      - Newly inserted Blacklist Object
  */
  insertBlacklistGlobal = async (data, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/blacklist/`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);

      // Response
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  getBlacklistItems = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/blacklist/`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };
  /*
  Update global blacklist
  */
  updateBlacklistGlobal = async (data, errorHandler) => {
    const url = `${config.baseServerURL}/addressbook/blacklist/${data.blacklistId}`;
    const body = JSON.stringify(data);
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: body,
    };

    try {
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Delete blacklist item
  */
  deleteBlackList = async (data, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/blacklist/${data.blacklistId}`;
      const body = JSON.stringify(data);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'DELETE',
        headers: headers,
        body: body,
      };

      // Request
      await requestRabbit.request(url, options, errorHandler);
    } catch (e) {
      console.log(e.message);
    }
  };
}
