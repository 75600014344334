// NPM MODULES
import React, { Component } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { DateTime } from 'luxon';

// NETWORKING
import UserReportRequest from '../UserReportRequests';

// FORMATTED MESSAGES
import formattedMessages from '../FormattedMessages';

// INSTANTIATE
const userReportRequest = new UserReportRequest();

export default class SingleViewPending extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props
        - user: A User object to be used for searching

      Optional Props
        - fromDate: The start of the date range to search in
        - toDate: The end of the date range to search in
    */

    this.state = {
      messages: [],
      showConfirmation: false,
    };
  }

  async componentDidMount() {
    try {
      if (this.props.user) {
        let messages = await userReportRequest.getMessagesForId(
          this.props.user.customer_user_id,
          'REMPEN',
          this.props.fromDate,
          this.props.toDate,
          this.props.errorHandler
        );
        return this.setState({
          messages: messages,
          fromDate: this.props.fromDate,
          toDate: this.props.toDate,
        });
      }
      // Generate error if no props
      return this.setState({
        hasError: true,
        errorMessage: 'Expected User in Props',
      });
    } catch (e) {
      return this.setState({
        hasError: true,
        errorMessage: 'Network Request Failed.',
      });
    }
  }

  /*
    Handles the click for "Cancel All Pending" button.
  */
  handleCancelPendingConfirmation = () => {
    return this.setState({
      showConfirmation: true,
    });
  };

  handleCancelModal = async (shouldCancel) => {
    return this.setState({
      showConfirmation: shouldCancel,
    });
  };

  /*
    Handles the click for "Cancel All Pending" button.
  */
  handleCancelPending = async () => {
    try {
      let { user } = this.props;
      await userReportRequest.cancelAllPendingMessages(
        user.customer_user_id,
        'REMPEN',
        this.props.errorHandler
      );

      // Refresh the page to update status of any pending items
      let messages = await userReportRequest.getMessagesForId(
        this.props.user.customer_user_id,
        'REMPEN',
        this.props.fromDate,
        this.props.toDate
      );
      return this.setState({
        messages: messages,
        showConfirmation: false,
      });
    } catch (e) {
      return this.setState({
        hasError: true,
        errorMessage: e.message,
        showConfirmation: false,
      });
    }
  };

  /*
    Cancels a single Pending Reminder
  */
  onClickCancelSingleReminder = async (schedId) => {
    try {
      let { user } = this.props;
      await userReportRequest.cancelSinglePendingMessage(
        user.customer_user_id,
        schedId,
        this.props.errorHandler
      );

      // Refresh the page to update status of any pending items
      let messages = await userReportRequest.getMessagesForId(
        this.props.user.customer_user_id,
        'REMPEN',
        this.props.fromDate,
        this.props.toDate
      );
      return this.setState({
        messages: messages,
        showConfirmation: false,
      });
    } catch (e) {
      return this.setState({
        hasError: true,
        errorMessage: e.message,
        showConfirmation: false,
      });
    }
  };

  /*
    Adding the time range text fromDate to toDate
  */
  generateTimeRangeText = () => {
    let fromDate = moment(this.props.fromDate).format('DD MMM YYYY h:mm:ss A');
    let toDate = moment(this.props.toDate).format('DD MMM YYYY h:mm:ss A');

    return (
      <div>
        <p>
          {formattedMessages.from} {fromDate} {formattedMessages.to} {toDate}
        </p>
      </div>
    );
  };

  generateTable = () => {
    return (
      <Table>
        <thead>
          <tr>
            <td className="medium tableTop" colSpan="12">
              {formattedMessages.messagesTitle}
            </td>
          </tr>
          <tr>
            <td className="medium tableTop column-right" rowSpan="2">
              {formattedMessages.typeText}
            </td>
            <td className="medium tableTop column-right" rowSpan="2">
              {formattedMessages.phoneNumberText}
            </td>
            <td className="medium tableTop column-right" rowSpan="2">
              {formattedMessages.countryText}
            </td>
            <td className="medium tableTop column-right" rowSpan="2">
              {formattedMessages.localFlagText}
            </td>
            <td className="medium tableTop column-right" colSpan="3">
              Due
            </td>
            <td className="medium tableTop column-right" colSpan="3">
              Received
            </td>
          </tr>
          <tr>
            <td className="medium tableTop column-right">
              {formattedMessages.dayText}
            </td>
            <td className="medium tableTop column-right">
              {formattedMessages.dateText}
            </td>
            <td className="medium tableTop column-right">
              {formattedMessages.timeText}
            </td>
            <td className="medium tableTop column-right">
              {formattedMessages.dayText}
            </td>
            <td className="medium tableTop column-right">
              {formattedMessages.dateText}
            </td>
            <td className="medium tableTop column-right">
              {formattedMessages.timeText}
            </td>
          </tr>
        </thead>
        <tbody>{this.generateTableBody()}</tbody>
      </Table>
    );
  };

  generateTableBody = () => {
    if (!this.state.messages || this.state.messages.length === 0) return null;
    let rows = [];

    // For each Direct Messages
    for (let i = 0; i < this.state.messages.length; i++) {
      let msg = this.state.messages[i];
      let sentDate = DateTime.fromISO(msg.sms_sent);
      let rezoned = sentDate.setZone(localStorage.getItem('timeShort'), {
        keepLocalTime: false,
      });
      let day = rezoned.toFormat('EEEE');
      let date = rezoned.toFormat('LLL dd, yyyy');
      let time = rezoned.toFormat('h:mm:ss a');
      let nextSend = DateTime.fromISO(msg.next_send);
      let rezonedTwo = nextSend.setZone(localStorage.getItem('timeShort'), {
        keepLocalTime: false,
      });
      let nextSendDay = rezonedTwo.toFormat('EEEE');
      let nextSendDate = rezonedTwo.toFormat('LLL dd, yyyy');
      let nextSendTime = rezonedTwo.toFormat('h:mm:ss a');

      rows.push(
        <tr key={'list' + msg.sched_id} id={msg.sched_id}>
          <td className="medium" name="type">
            <span
              className="blue"
              onClick={() => this.onClickCancelSingleReminder(msg.sched_id)}
            >
              {msg.recurring === 'RR' ? 'Recurring' : 'Non Recurring'}
            </span>
          </td>
          <td className="medium" name="dest_tn">
            {msg.dest_tn}
          </td>
          <td className="medium" name="country">
            {msg.country_code_name}
          </td>
          <td className="medium" name="local_flag">
            {msg.local_flag}
          </td>
          <td className="medium" name="day">
            {nextSendDay}
          </td>
          <td className="medium" name="date">
            {nextSendDate}
          </td>
          <td className="medium" name="time">
            {nextSendTime}
          </td>
          <td className="medium" name="day">
            {day}
          </td>
          <td className="medium" name="date">
            {date}
          </td>
          <td className="medium" name="time">
            {time}
          </td>
        </tr>
      );
    }

    return rows;
  };

  /*
    Generates a Clear Pending Messages confirmation Modal
  */
  generateDeletePendingConfirmationModal = () => {
    return (
      <Modal
        show={this.state.showConfirmation}
        onHide={() => this.handleCancelModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Really Cancel All Pending Messages?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            onClick={() => this.handleCancelPending()}
            className="maxHeight pad-button"
          >
            {formattedMessages.yes}
          </Button>
          <Button
            onClick={() => this.handleCancelModal(false)}
            className="maxHeight pad-button"
          >
            {formattedMessages.no}
          </Button>
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    return (
      <div className="Home">
        <div className="lander">
          <p onClick={this.props.backButton} className="backLink">
            <i className="material-icons">keyboard_arrow_left</i>
            <span>{formattedMessages.backText}</span>
          </p>
          <div>
            <h3>{formattedMessages.pendingReminders}</h3>
          </div>
        </div>
        {this.generateTimeRangeText()}
        <div className="marginTop">{this.generateTable()}</div>
        {this.generateDeletePendingConfirmationModal()}
      </div>
    );
  }
}
