import React from 'react';

const ListItem = ({
  muxId,
  routeName = '',
  routeId = '',
  daemonStatus = '',
  protocol = '',
  txOk = '',
  txErr = '',
  rx = '',
  today = '',
  maxPerDay = '',
  H = '',
  M = '',
  L = '',
  age = '',
  onClickRouteName = null,
  onClickRouteId = null,
  ...props
}) => (
  <tr key={`list${routeName}`} id={routeName}>
    <td className="" name="muxId">
      {muxId}
    </td>
    <td className="" name="routeName">
      <span className="blue" onClick={() => onClickRouteName(routeName)}>
        {routeName}
      </span>
    </td>
    <td className="" name="routeId">
      <span className="blue" onClick={() => onClickRouteId(routeId)}>
        {routeId}
      </span>
    </td>
    <td className="" name="daemonStatus">
      {daemonStatus}
    </td>
    <td className="" name="protocol">
      {protocol}
    </td>
    <td className="" name="txOk">
      {txOk}
    </td>
    <td className="" name="txErr">
      {txErr}
    </td>
    <td className="" name="rx">
      {rx}
    </td>
    <td className="" name="today">
      {today}/{maxPerDay}
    </td>
    <td className="" name="H">
      {H}
    </td>
    <td className="" name="M">
      {M}
    </td>
    <td className="" name="L">
      {L}
    </td>
    <td className="" name="age">
      {age}
    </td>
  </tr>
);

export default ListItem;
