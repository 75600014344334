import React from 'react';

const ListItem = ({
  id,
  createdDate = '',
  from = '',
  to = '',
  name = '',
  errorText = '',
  code = '',
  status = '',
  onClickStatus = null,
  failedSms = null,
  ...props
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="medium textLeft" name="createdDate">
      {createdDate}
    </td>
    <td className="medium textLeft" name="from">
      <span>{from}</span>{' '}
    </td>
    <td className="medium textLeft" name="to">
      <span>{to}</span>
    </td>
    <td className="medium textLeft" name="name">
      <span>{name}</span>{' '}
    </td>
    <td className="medium textLeft" name="status">
      <span className="blue" onClick={() => onClickStatus(failedSms)}>
        {status}
      </span>
    </td>
    <td className="medium textLeft" name="code">
      <span>{code}</span>
    </td>
    <td className="medium textLeft" name="errorText">
      <span>{errorText}</span>
    </td>
  </tr>
);

export default ListItem;
