// NPM MODULES
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class ErrorModal extends Component {
  constructor(props) {
    super(props);

    /*
          Expected Props:
           - errorStack: The actual error message (should be string but may come in as object).
           - showError: The boolean which determines if the modal should be shown.
           - removeError: The function which sets showError to false.
           - windowLocation: (as I understand it, this value holds the window location. not too sure on its usage beyond that.)
           - resetWindowLocation: The function which sets showError to false and resets windowLocation.
        */

    this.state = {};
  }

  render() {
    let errorStack = this.props.errorStack;
    if (window.location.pathname !== this.props.windowLocation) {
      this.props.resetWindowLocation();
      return null;
    }
    if (errorStack !== undefined) {
      // handles error stack
      if (errorStack.length === 0) return null;
      if (typeof errorStack !== 'string')
        errorStack =
          errorStack.message ||
          'FAILURE: Pass string to error handler, not object.';

      // returns modal
      return (
        <Modal
          show={this.props.showError}
          onHide={() => this.props.removeError()}
          backdrop="static"
        >
          <Modal.Header className="error-card" closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorStack}
            <br />
            <Button
              className="delete btn-red btn btn-danger float-right"
              onClick={() => this.props.removeError()}
            >
              Close
            </Button>
          </Modal.Body>
        </Modal>
      );
    } else {
      console.log('error not being caught properly');
      return null;
    }
  }
}

export default ErrorModal;
