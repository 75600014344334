import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  modalWarning: (
    <FormattedMessage
      id="Websms.modalWarning"
      defaultMessage="Warning"
    ></FormattedMessage>
  ),
  addressBookTitle: (
    <FormattedMessage
      id="Websms.AddressBookTitle"
      defaultMessage="Address Book"
    ></FormattedMessage>
  ),
  backText: (
    <FormattedMessage
      id="Global.BackText"
      defaultMessage="Back"
    ></FormattedMessage>
  ),
  cancelText: (
    <FormattedMessage
      id="Websms.CancelText"
      defaultMessage="Cancel"
    ></FormattedMessage>
  ),
  emailText: (
    <FormattedMessage
      id="Websms.EmailText"
      defaultMessage="Email Address"
    ></FormattedMessage>
  ),
  firstNameText: (
    <FormattedMessage
      id="Websms.FirstNameText"
      defaultMessage="First Name"
    ></FormattedMessage>
  ),
  lastNameText: (
    <FormattedMessage
      id="Websms.LastNameText"
      defaultMessage="Last Name"
    ></FormattedMessage>
  ),
  loadingText: (
    <FormattedMessage
      id="App.LoadingText"
      defaultMessage="Loading..."
    ></FormattedMessage>
  ),
  manageAddressButton: (
    <FormattedMessage
      id="Websms.ManageAddressButton"
      defaultMessage="Manage Address Books"
    ></FormattedMessage>
  ),
  manageDistListButton: (
    <FormattedMessage
      id="Websms.ManageDistListButton"
      defaultMessage="Manage Distribution Lists"
    ></FormattedMessage>
  ),
  middleNameText: (
    <FormattedMessage
      id="Websms.MiddleNameText"
      defaultMessage="Middle Name"
    ></FormattedMessage>
  ),
  mobileText: (
    <FormattedMessage
      id="Websms.MobileText"
      defaultMessage="Phone Number"
    ></FormattedMessage>
  ),
  searchContacts: (
    <FormattedMessage
      id="Websms.SearchContacts"
      defaultMessage="Search Contacts"
    ></FormattedMessage>
  ),
  importText: (
    <FormattedMessage
      id="Websms.ImportContactsText"
      defaultMessage="Import Contacts"
    ></FormattedMessage>
  ),
  deleteContactText: (
    <FormattedMessage
      id="Websms.DeleteContactText"
      defaultMessage="Delete Contact"
    ></FormattedMessage>
  ),
  exportText: (
    <FormattedMessage
      id="Websms.ExportContactsText"
      defaultMessage="Export Contacts"
    ></FormattedMessage>
  ),
  statistics: (
    <FormattedMessage
      id="Websms.StatText"
      defaultMessage="Statistics"
    ></FormattedMessage>
  ),
  save: (
    <FormattedMessage id="Websms.Save" defaultMessage="Save"></FormattedMessage>
  ),
  saving: (
    <FormattedMessage
      id="Websms.Saving"
      defaultMessage="Saving"
    ></FormattedMessage>
  ),
  global: (
    <FormattedMessage
      id="Websms.Global"
      defaultMessage="Global"
    ></FormattedMessage>
  ),
  personal: (
    <FormattedMessage
      id="Websms.Personal"
      defaultMessage="Personal"
    ></FormattedMessage>
  ),
  deleteText: (
    <FormattedMessage
      id="Websms.DeleteText"
      defaultMessage="Delete"
    ></FormattedMessage>
  ),
  globalListText: (
    <FormattedMessage
      id="Websms.GlobalListText"
      defaultMessage="Global List"
    ></FormattedMessage>
  ),
  personalListText: (
    <FormattedMessage
      id="Websms.PersonalListText"
      defaultMessage="Personal List"
    ></FormattedMessage>
  ),
  totalContactText: (
    <FormattedMessage
      id="Websms.TotalContactText"
      defaultMessage="Total Contacts"
    ></FormattedMessage>
  ),
  deleteGlobalContactsHeader: (
    <FormattedMessage
      id="WebsmsDelete.AllContactsHeader"
      defaultMessage="Delete Global Contacts"
    ></FormattedMessage>
  ),
  deletePersonalContactsHeader: (
    <FormattedMessage
      id="WebsmsDelete.PersonalContactsHeader"
      defaultMessage="Delete Personal Contacts"
    ></FormattedMessage>
  ),
  deleteGlobalContactsBody: (
    <FormattedMessage
      id="WebsmsDelete.GlobalContactsBody"
      defaultMessage="You are about to permanently delete all Global Contacts, are you sure you want to do this?"
    ></FormattedMessage>
  ),
  deletePersonalContactsBody: (
    <FormattedMessage
      id="WebsmsDelete.PersonalContactsBody"
      defaultMessage="You are about to permanently delete all Personal Contacts, are you sure you want to do this?"
    ></FormattedMessage>
  ),
  deleteContactsBody: (
    <FormattedMessage
      id="WebsmsDelete.ContactsBody"
      defaultMessage="You are about to permanently delete this Contact, are you sure you want to do this?"
    ></FormattedMessage>
  ),
  noData: (
    <FormattedMessage
      id="Error.NoData"
      defaultMessage="Cannot access data"
    ></FormattedMessage>
  ),
  selectImportContact: (
    <FormattedMessage
      id="WebsmsDelete.SelectImportContact"
      defaultMessage="1) Select which list to import the contacts into"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
