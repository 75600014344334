import React, { Fragment } from 'react';
import { Collapse } from 'react-bootstrap';

const ListItem = ({
  id,
  date = '',
  type = '',
  emailID = '',
  email = '',
  onClickName = null,
  onClickResult = null,
  accountNo = '',
  reqBody = '',
  collapse,
  showArrow,
  total,
  failed,
}) => {
  let message = JSON.stringify(reqBody, null, 4);
  message = message.replace(/(\\r\\n)/g, `\n\t`);
  let onClickFunction = () => (reqBody ? onClickResult(id) : null);
  return (
    <Fragment>
      <tr key={`list${id}`} id={id}>
        <td
          className="column-right column-left"
          name="date"
          onClick={onClickFunction}
        >
          <div className="icon-inline">
            {showArrow && (
              <i className="material-icons table-click">
                {collapse ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
              </i>
            )}
            <span className="display-interactable"> &nbsp; {date}</span>
          </div>
        </td>
        <td className="" name="emailID">
          <span className="blue" onClick={() => onClickName(emailID)}>
            {emailID || 'missing'}
          </span>
        </td>
        <td className="" name="accountId" onClick={onClickFunction}>
          <span>{accountNo}</span>
        </td>
        <td className="" name="email" onClick={onClickFunction}>
          <span>{email}</span>
        </td>
        <td className="" name="type" onClick={onClickFunction}>
          <span>{type}</span>
        </td>
        <td className="" name="total" onClick={onClickFunction}>
          <span>{total}</span>
        </td>
        <td className="" name="failures" onClick={onClickFunction}>
          <span>{failed}</span>
        </td>
      </tr>
      <tr></tr>
      <tr
        className={
          'collapsible-table column-right column-left' +
          (collapse ? ' column-bottom' : ' column-bottom-hidden')
        }
      >
        <td colSpan="8">
          <Collapse in={collapse}>
            <div>
              <div>{message}</div>
            </div>
          </Collapse>
        </td>
      </tr>
    </Fragment>
  );
};

export default ListItem;
