/*
  Add.js - Add New Provider Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

// COMPONENTS
import CountryPicker from '../../../components/CountryPicker';
import LanguagePicker from '../../../components/LanguagePicker';

// I18N
import formattedMessages from './FormattedMessages';

// STYLES
import '../Admin.css';

// NETWORKS
import AdminRequest from '../AdminRequest';
import ProviderRequest from './ProviderRequest';
import { validateName } from '../../../utils/validate';
const adminRequest = new AdminRequest();
const providerRequest = new ProviderRequest();

export default class CreateProviderView extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - backButtonClick: Function to handle Back Button click
    */

    this.state = {
      edits: {},
      statuses: [],
      xero_accounts: [],
      isSaving: false,
      error: null,
    };
  }

  async componentDidMount() {
    let extras = await adminRequest.getProviderExtra(this.props.errorHandler);
    return this.setState({
      statuses: extras.provider_statuses,
      xero_accounts: extras.xero_accounts,
    });
  }

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;

    if (name === 'formCountry') {
      edits.country_code_id = value;
    } else {
      edits[name] = value;
    }

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles form submission
  */
  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState(
      {
        isSaving: true,
      },
      async () => {
        let { edits, xero_accounts } = this.state;

        if (!validateName(edits.provider_name)) {
          return this.setState({
            error: 'Provider Name is required',
            isSaving: false,
          });
        }

        // Add defaults
        if (!edits.xero_accounts_id && xero_accounts.length > 0) {
          edits.xero_accounts_id = xero_accounts[0].xero_accounts_id;
        }

        try {
          // Create Provider
          await providerRequest.createProvider(edits, this.props.errorHandler);
          window.location.href = '/admin/provider'; // Force reload - TODO: redo properly
          // this.props.backButtonClick('refresh');
        } catch (e) {
          return this.setState({
            error: e.message,
            isSaving: false,
          });
        }
      }
    );
  };

  /*
    Receives back the default select language_id
  */
  handleDefaultLanguage = (languageId) => {
    let { edits } = this.state;
    edits.language_id = languageId;
    return this.setState({ edits: edits });
  };

  /*
    Handles setting defaults for Select elements (element 0 if exists)
  */
  returnDefault = async (label, id) => {
    let { edits } = this.state;
    edits[label] = id;

    return this.setState({
      edits: edits,
    });
  };

  /*
    Generates the Select control for Provider Statuses
  */
  generateStatusSelect = () => {
    let { statuses } = this.state;
    let statusOptions = null;
    // Set to data fetched from API
    if (statuses && statuses.length > 0) statusOptions = statuses;
    // If no data from API, set default list
    if (!statusOptions || typeof statusOptions === 'undefined') {
      statusOptions = [
        { meaning: 'Trial' /*formattedMessages.trial*/, code: 'T' },
        { meaning: 'Active' /*formattedMessages.active*/, code: 'A' },
        { meaning: 'Cancelled' /*formattedMessages.cancelled*/, code: 'C' },
        { meaning: 'Suspended' /*formattedMessages.suspended*/, code: 'S' },
      ];
    }

    let statusList = [];
    for (let i = 0; i < statusOptions.length; i++) {
      statusList.push(
        <option key={i} value={statusOptions[i].code}>
          {statusOptions[i].meaning}
        </option>
      );
    }

    return (
      <Form.Control
        as="select"
        name="provider_status"
        defaultValue={statusList.length > 0 ? statusList[0] : ''}
      >
        {statusList}
      </Form.Control>
    );
  };

  /*
    Generates the Select control for Provider Xero Accounts
  */
  generateXeroSelect = () => {
    let { xero_accounts } = this.state;
    let xeroAccountsList = [];
    for (let i = 0; i < xero_accounts.length; i++) {
      xeroAccountsList.push(
        <option
          key={xero_accounts[i].xero_accounts_id}
          value={xero_accounts[i].xero_accounts_id}
        >
          {xero_accounts[i].account_name}
        </option>
      );
    }

    return (
      <Form.Control
        as="select"
        name="xero_accounts_id"
        defaultValue={
          xero_accounts.length > 0 ? xero_accounts[0].xero_accounts_id : ''
        }
      >
        {xeroAccountsList}
      </Form.Control>
    );
  };

  /*
  Generates the form submission button
*/
  generateButton = () => {
    if (this.state.isSaving) return <p>Creating...</p>;
    return (
      <Button className="createProviderButton" variant="primary" type="submit">
        Create Provider
      </Button>
    );
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="ProviderCreate" className="lander">
        <p
          onClick={() => this.props.backButtonClick(false)}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
        <h3>{formattedMessages.createProvider}</h3>
      </div>
    );
  };

  /*
    Generate Error Text
  */
  generateError = () => {
    if (!this.state.error) return null;

    return <div className="text-danger">{this.state.error}</div>;
  };

  /*
    Generates Main Body content
  */
  generateBody = () => {
    let { edits } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
        <Form.Group as={Row} controlId="provider_name">
          <Form.Label column sm={2} className="info-label">
            * Name:
          </Form.Label>
          <Col sm={8}>
            <Form.Control required name="provider_name" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="copy_dlvry_from">
          <Form.Label column sm={2} className="info-label">
            Copy From:
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="copy_dlvry_from" type="text" />
            <span className="hintText">
              (Root Provider: 16f5fd20-8911-11e9-8735-6772c8e323ae)
            </span>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="provider_status">
          <Form.Label column sm={2} className="info-label">
            Status:
          </Form.Label>
          <Col sm={8}>{this.generateStatusSelect()}</Col>
        </Form.Group>
        <Form.Group as={Row} controlId="country_code_id">
          <Form.Label column sm={2} className="info-label">
            Country:
          </Form.Label>
          <Col sm={8}>
            <CountryPicker
              className="form-control"
              returnDefaultCountryCode={(id) =>
                this.returnDefault('country_code_id', id)
              }
              defaultValue={edits.country_code_id}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="language_id">
          <Form.Label column sm={2} className="info-label">
            Language on Web Pages:
          </Form.Label>
          <Col sm={8}>
            <LanguagePicker
              className="form-control"
              optionsName="language_id"
              returnDefaultLanguageId={this.handleDefaultLanguage}
              selectedLanguageId={this.state.edits.language_id}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="active_creation">
          <Form.Label column sm={2} className="info-label">
            Active Creation:
          </Form.Label>
          <Col sm={8}>
            <Form.Check type="checkbox" name="active_creation"></Form.Check>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="xero_accounts_id">
          <Form.Label column sm={2} className="info-label">
            Xero Account:
          </Form.Label>
          <Col sm={8}>{this.generateXeroSelect()}</Col>
        </Form.Group>
        <span className="hintText">* Indicates a required field</span>
        {this.generateError()}
        <br />
        {this.generateButton()}
      </Form>
    );
  };

  render() {
    return (
      <div className="Home">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
