/*
  CustomerRequest.js - CustomerRequest Networking File
  
  Contains networking functionality for Customer Components

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../../RequestRabbit';

// CONFIGURATION
import config from '../../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class AdminRequest {
  getSingleCustomer = async (customerId, errorHandler) => {
    try {
      let url = `${config.baseServerURL}/admin/customer/${customerId}`;
      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  updateCustomer = async (customerId, updateData, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/customer/${customerId}`;
      console.log('url is:', url);
      console.log('update is:', updateData);
      const body = JSON.stringify(updateData);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      console.log('response: ', responseJSON);

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };
}
