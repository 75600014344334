/*
  SingleView.js - Admin Country Single View Main Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

// COMPONENTS
import DeviceCarrierAll from './Carrier/All';
import CarrierDeviceList from '../Country/Carrier/Device/List';
import CarrierDeviceSingle from '../Country/Carrier/Device/Single';
import BackButton from '../../../../components/CustomButtons/BackButton';
import ErrorText from '../../../../components/ErrorText';

// NETWORK
import DeviceRequest from './DeviceRequest';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

// UTILS
import { validateName, validatePhoneNumber } from '../../../../utils/validate';

// INIT
const deviceRequest = new DeviceRequest();

export default class DeviceSingleView extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - device: A single Device object
        - isNew: (Optional) Boolean indicating if creating a new object or not
        - backButtonClick: Function to handle Back Button click
        - errorHandler: A function to pass errors to
    */

    console.log('Have Device prop:', this.props.device);

    this.state = {
      isEditing: false,
      edits: {},
      selectedDevice: null,
      addNewCarrierDevice: false,
      types: [],
      classes: [],
      statuses: [],
      hasError: false,
      errorMessage: '',
      showConfirmation: false,
      isSaving: false,
    };
  }

  async componentDidMount() {
    let { types, statuses, classes } =
      await deviceRequest.getStatusTypeClassData();
    return this.setState({
      types: types,
      statuses: statuses,
      classes: classes,
    });
  }

  backButtonClick = () => {
    return this.setState({
      selectedDevice: null,
      addNewCarrierDevice: false,
    });
  };

  /*
    Handles Edit Mode on/off
  */
  toggleEdit = () => {
    return this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  addNewCarrierDevice = () => {
    return this.setState({
      addNewCarrierDevice: true,
    });
  };

  /*
    Handles when a Carrier Device is clicked in the list
  */
  showSingleCarrierDevice = (carrierDevice) => {
    console.log('Got carrierDevice:', carrierDevice);
    return this.setState({
      selectedDevice: carrierDevice,
    });
  };

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;
    edits[name] = value;

    console.log('Edits is:', edits);

    return this.setState({
      edits: edits,
    });
  };

  /*
    Renders Error Text Component
  */
  generateErrorText = () => {
    return (
      <ErrorText hasError={this.state.hasError} errorText={this.state.error} />
    );
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    e.preventDefault();
    let updateData = this.state.edits;
    let result;
    // Handle create new Device
    if (this.props.isNew) {
      if (!validateName(updateData.device_name)) {
        return this.setState({
          hasError: true,
          error: formattedMessages.errTextDeviceName,
        });
      }
      if (!updateData.device_type) {
        return this.setState({
          hasError: true,
          error: formattedMessages.errTextDeviceType,
        });
      }
      if (!updateData.device_class) {
        return this.setState({
          hasError: true,
          error: formattedMessages.errTextDeviceClass,
        });
      }
      if (!updateData.device_status) {
        return this.setState({
          hasError: true,
          error: formattedMessages.errTextDeviceStatus,
        });
      }
      if (updateData.priority === '') {
        return this.setState({
          hasError: true,
          error: formattedMessages.errTextPriority,
        });
      }
      if (isNaN(updateData.priority)) {
        return this.setState({
          hasError: true,
          error: formattedMessages.errTextPriorityNumber,
        });
      }
      if (!updateData.primary_device_id) {
        return this.setState({
          hasError: true,
          error: formattedMessages.errTextPrimaryDeviceId,
        });
      }
      if (!updateData.virtual_device_id) {
        return this.setState({
          hasError: true,
          error: formattedMessages.errTextVirtualDeviceId,
        });
      }
      if (
        updateData.reply_tn &&
        (updateData.reply_tn.trim() === '' ||
          !validatePhoneNumber(updateData.reply_tn))
      ) {
        return this.setState({
          hasError: true,
          error: formattedMessages.errTextCheckReplyTn,
        });
      }

      this.setState({ isSaving: true });
      result = await deviceRequest.createDevice(
        updateData,
        this.props.errorHandler
      );
    }
    // Handle update Device
    else {
      this.setState({ isSaving: true });
      result = await deviceRequest.updateDevice(
        this.props.device.device_id,
        updateData,
        this.props.errorHandler
      );
    }
    if (result && !result.error) this.props.backButtonClick();
    else this.setState({ isSaving: false });
  };

  handleDelete = async () => {
    this.handleCloseConfirmation();
    await deviceRequest.deleteDevice(
      this.props.device.device_id,
      this.props.errorHandler
    );
    window.location.href = '/admin/device'; // Force reload - TODO: redo properly
    return this.setState({
      showConfirmation: false,
    });
  };

  /*
    TODO - Add a react-router Link Component here when Customer User Report takes a customerUserId
  */
  generateCustomerUserLink = (userEmail) => {
    if (!userEmail || typeof userEmail === 'undefined') return null;

    return <div>Link to Customer User</div>;
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateEditFormGroup = (controlId, label, defaultValue) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name={controlId}
            type="text"
            defaultValue={defaultValue}
          />
        </Col>
      </Form.Group>
    );
  };

  generateEditFormCheckbox = (controlId, label, defaultChecked) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}:
        </Form.Label>
        <Col sm={8}>
          <Form.Check
            type="checkbox"
            defaultChecked={defaultChecked}
            name={controlId}
          ></Form.Check>
        </Col>
      </Form.Group>
    );
  };

  generateLander = () => {
    let { selectedDevice, addNewCarrierDevice } = this.state;
    if (selectedDevice || addNewCarrierDevice) return null;
    return (
      <div key="DeviceShow" className="lander">
        <BackButton onClick={() => this.props.backButtonClick()}></BackButton>
        <h3>Device Information</h3>
      </div>
    );
  };

  generateSelect = (
    options,
    listKey,
    optionCode,
    value,
    label,
    defaultValue
  ) => {
    let list = [];

    list.push(
      <option hidden key={''} value={''}>
        {'Select an option'}
      </option>
    );

    for (let i = 0; i < options.length; i++) {
      let option = options[i];
      list.push(
        <option key={option[optionCode]} value={option[optionCode]}>
          {option[value]}
        </option>
      );
    }

    return (
      <Form.Group as={Row} controlId={listKey}>
        <Form.Label column sm={2} className="info-label">
          {label}:
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="select"
            name={listKey}
            value={this.state.edits[listKey] || defaultValue || ''}
            onChange={(e) => {
              if (this.props.onChange) this.props.onChange(e);
            }}
          >
            {list}
          </Form.Control>
        </Col>
      </Form.Group>
    );
  };

  generateCarrierDeviceList = () => {
    let { device, errorHandler, isNew } = this.props;
    let { selectedDevice, addNewCarrierDevice } = this.state;

    // If drilling down into a device, adding new device or adding new carrier device
    if (isNew || selectedDevice || addNewCarrierDevice) return null;

    return (
      <CarrierDeviceList
        deviceId={device.device_id}
        showSingleCarrierDevice={this.showSingleCarrierDevice}
        addNewCarrierDevice={this.addNewCarrierDevice}
        backButtonClick={this.backButtonClick}
        errorHandler={errorHandler}
      />
    );
  };

  generateBody = () => {
    const { device, errorHandler, isNew } = this.props;
    const { isEditing, selectedDevice, addNewCarrierDevice, isSaving } =
      this.state;
    const buttonText = isEditing ? 'Cancel' : 'Edit';

    // If a Device is selected
    if (selectedDevice) {
      return (
        <CarrierDeviceSingle
          carrierDevice={selectedDevice}
          deviceId={device.device_id}
          isNew={addNewCarrierDevice}
          backButtonClick={this.backButtonClick}
          errorHandler={errorHandler}
        />
      );
    }

    if (addNewCarrierDevice) {
      return (
        <DeviceCarrierAll
          device={device}
          backButtonClick={this.backButtonClick}
          errorHandler={errorHandler}
        />
      );
    }

    // Creating new Device
    if (isNew) {
      return (
        <div>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            {this.generateEditFormGroup('device_name', '* Device Name:', null)}
            {this.generateEditFormGroup(
              'copy_from_device_id',
              'Copy From Device:',
              null
            )}
            {this.generateSelect(
              this.state.types,
              'device_type',
              'code',
              'meaning',
              '* Device Type',
              null
            )}
            {this.generateSelect(
              this.state.classes,
              'device_class',
              'code',
              'meaning',
              '* Device Class',
              null
            )}
            {this.generateSelect(
              this.state.statuses,
              'device_status',
              'code',
              'meaning',
              '* Device Status',
              null
            )}
            {this.generateEditFormGroup('priority', '* Priority:', null)}
            {this.generateEditFormGroup('reply_tn', 'Reply TN:', null)}
            {this.generateEditFormGroup(
              'primary_device_id',
              '* Primary Device Id:',
              null
            )}
            {this.generateEditFormGroup(
              'virtual_device_id',
              '* Virtual Device Id:',
              null
            )}
            {this.generateEditFormCheckbox(
              'accepts_alphas',
              'Accepts Alphas',
              true
            )}
            {this.generateEditFormGroup(
              'top_up_credits',
              'Top Up Credits:',
              null
            )}
            {this.generateEditFormGroup(
              'last_topped_up',
              'Last Topped Up:',
              null
            )}
            {this.generateEditFormGroup(
              'device_sms_count',
              'Current SMS Count:',
              null
            )}
            {this.generateEditFormGroup(
              'dedicated_user_id',
              'Dedicated User Id:',
              null
            )}
            {this.generateEditFormGroup(
              'dedicated_cust_id',
              'Dedicated Customer Id:',
              null
            )}
            {this.generateErrorText()}
            {isSaving && 'Saving...'}
            {!isSaving && (
              <Button className="maxHeight" variant="primary" type="submit">
                Save
              </Button>
            )}
          </Form>
        </div>
      );
    }

    // Have Device and not editing
    if (device && !isEditing) {
      return (
        <div>
          <Form>
            {this.generateFormGroup(
              'Device ID:',
              device.device_id,
              'device_id'
            )}
            {this.generateFormGroup(
              'Device Name:',
              device.device_name,
              'device_name'
            )}
            {this.generateFormGroup(
              'Device Type:',
              device.device_type_meaning,
              'device_type'
            )}
            {this.generateFormGroup(
              'Device Class:',
              device.device_class_meaning,
              'device_class'
            )}
            {this.generateFormGroup(
              'Device Status:',
              device.device_status_meaning,
              'device_status'
            )}
            {this.generateFormGroup(
              'Device Old Status:',
              device.old_status_meaning,
              'old_status'
            )}
            {this.generateFormGroup('Priority:', device.priority, 'priority')}
            {this.generateFormGroup('Reply TN:', device.reply_tn, 'reply_tn')}
            {this.generateFormGroup(
              'Primary Device Id:',
              device.primary_device_id,
              'primary_device_id'
            )}
            {this.generateFormGroup(
              'Virtual Device Id:',
              device.virtual_device_id,
              'virtual_device_id'
            )}
            {this.generateFormGroup(
              'Accepts Alphas:',
              device.accepts_alphas,
              'accepts_alphas'
            )}
            {this.generateFormGroup(
              'Top Up Credits:',
              device.top_up_credits,
              'top_up_credits'
            )}
            {this.generateFormGroup(
              'Last Topped Up:',
              device.last_topped_up,
              'last_topped_up'
            )}
            {this.generateFormGroup(
              'Current SMS Count:',
              device.device_sms_count,
              'device_sms_count'
            )}
            {this.generateFormGroup(
              'Dedicated User Id:',
              device.dedicated_user_id || 'N/A',
              'dedicated_user_id'
            )}
            {this.generateFormGroup(
              'Dedicated Customer Id:',
              device.dedicated_cust_id || 'N/A',
              'dedicated_cust_id'
            )}
          </Form>
          <Button className="maxHeight" onClick={this.toggleEdit}>
            {buttonText}
          </Button>
        </div>
      );
    } else if (device && isEditing) {
      return (
        <div>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            {this.generateEditFormGroup(
              'device_name',
              '* Device Name:',
              device.device_name
            )}
            {this.generateSelect(
              this.state.types,
              'device_type',
              'code',
              'meaning',
              '* Device Type',
              device.device_type
            )}
            {this.generateSelect(
              this.state.classes,
              'device_class',
              'code',
              'meaning',
              '* Device Class',
              device.device_class
            )}
            {this.generateSelect(
              this.state.statuses,
              'device_status',
              'code',
              'meaning',
              '* Device Status',
              device.device_status
            )}
            {this.generateEditFormGroup(
              'priority',
              '* Priority:',
              device.priority
            )}
            {this.generateEditFormGroup(
              'reply_tn',
              'Reply TN:',
              device.reply_tn
            )}
            {this.generateEditFormGroup(
              'primary_device_id',
              '* Primary Device Id:',
              device.primary_device_id
            )}
            {this.generateEditFormGroup(
              'virtual_device_id',
              '* Virtual Device Id:',
              device.virtual_device_id
            )}
            {this.generateEditFormCheckbox(
              'accepts_alphas',
              'Accepts Alphas',
              device.accepts_alphas === 'Y'
            )}
            {this.generateEditFormGroup(
              'top_up_credits',
              'Top Up Credits:',
              device.top_up_credits
            )}
            {this.generateEditFormGroup(
              'last_topped_up',
              'Last Topped Up:',
              device.last_topped_up
            )}
            {this.generateEditFormGroup(
              'device_sms_count',
              'Current SMS Count:',
              device.device_sms_count
            )}
            {this.generateEditFormGroup(
              'dedicated_user_id',
              'Dedicated User Id:',
              device.dedicated_user_id
            )}
            {this.generateCustomerUserLink(
              device.dedicated_user_email,
              device.dedicated_user_id
            )}
            {this.generateEditFormGroup(
              'dedicated_cust_id',
              'Dedicated Customer Id:',
              device.dedicated_cust_id
            )}
            {this.generateErrorText()}
            {isSaving && 'Saving...'}
            {!isSaving && (
              <Button className="maxHeight" variant="primary" type="submit">
                Save
              </Button>
            )}
          </Form>
        </div>
      );
    }

    return (
      <p>
        <span>(Device) No Data</span>
      </p>
    );
  };

  render() {
    return (
      <div className="Home">
        {this.generateLander()}
        {this.generateBody()}
        {this.generateCarrierDeviceList()}
      </div>
    );
  }
}
