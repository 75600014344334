/*
  CustomerReportRequests.js - Handles Search for Customer Report Data

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';
import config from '../../../Config';

// INSTANTIATE
const handleRequest = new RequestRabbit();

export default class SearchRequest {
  /*
    Get Report Data for Date Range
  */
  getReportData = async (searchObject, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/report/provider`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(searchObject) || null,
        headers: headers,
      };
      // Request data
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();
      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };
}
