// NPM
import React, { Component, Fragment } from 'react';
import formattedMessages from './../FormattedMessages';

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    console.log('Invoice Details props:', props);

    /*
      PROPS LIST:
        - invoiceDetailsData: Array of data for Cost Centres
        - invoice: Object containing Invoice details
    */

    this.state = {
      usersList: null,
    };
  }

  changeEvent = (o) => {
    console.log(o);
  };

  componentDidMount = () => {
    this.props.getDataFunction();
  };

  generateAdjustmentChargeTable = () => {
    const { invoice } = this.props;
    const { credit_card_surcharge, bill_adjustments } = invoice;

    let charges = [];
    if (credit_card_surcharge) {
      charges.push({
        translation: credit_card_surcharge.translation,
        revenue: credit_card_surcharge.revenue,
        rate: '2.5',
      });
    }

    if (bill_adjustments) {
      bill_adjustments.forEach((element) => {
        charges.push({
          revenue: element.revenue,
          rate: element.rate,
          translation: element.translation + ' - ' + element.bill_desc,
        });
      });
    }

    const entries = charges.map((element, index) => {
      return (
        <tr key={index}>
          <td align="right">{element.translation}</td>
          <td align="right">{element.rate}</td>
          <td align="right">{element.revenue}</td>
        </tr>
      );
    });

    return (
      <table width="100%" className="invoice-details">
        <tbody>
          <tr>
            <td colSpan="4" className="tableTop">
              Charges
            </td>
          </tr>
          <tr>
            <td>
              <b>Charge Description</b>
            </td>
            <td>
              <b>Rate</b>
            </td>
            <td>
              <b>Revenue ({invoice.currency_type})</b>
            </td>
          </tr>
          {entries}
        </tbody>
      </table>
    );
  };

  generateCostCentreRows = () => {
    let { invoiceDetailsData } = this.props;

    if (!invoiceDetailsData || !invoiceDetailsData.costCentres) return;

    let data = [];
    for (let i = 0; i < invoiceDetailsData.costCentres.length; i++) {
      let costCentre = invoiceDetailsData.costCentres[i];
      data.push(
        <tr key={i}>
          <td colSpan="4" align="left">
            {costCentre.cost_centre_name}
          </td>
          <td align="center">{costCentre.cost_centre_quantity}</td>
          <td align="right">{costCentre.cost_centre_revenue}</td>
        </tr>
      );
    }
    return data;
  };

  generateUserRows = () => {
    let { invoiceDetailsData } = this.props;
    let rows = [];

    for (let i = 0; i < invoiceDetailsData.costCentres.length; i++) {
      let costCentre = invoiceDetailsData.costCentres[i];
      // First generate the Cost Centre Name row
      rows.push(
        <tr key={costCentre.cost_centre_id}>
          <td align="left" colSpan="7">
            <b>{costCentre.cost_centre_name}</b>
          </td>
        </tr>
      );

      // Now handle each row for each User of the Cost Centre
      for (let j = 0; j < costCentre.cost_centre_users.length; j++) {
        let user = costCentre.cost_centre_users[j];
        rows.push(
          <tr key={user.customer_user_id}>
            <td align="center" colSpan="4">
              {user.customer_user_name}
            </td>
            <td align="center">{user.quantity}</td>
            <td align="right">{user.revenue}</td>
          </tr>
        );
      }
    }
    return rows;
  };

  generateLicenseTable() {
    const { invoice } = this.props;

    if (!invoice.software_license_fee) return;

    return (
      <table width="100%" className="invoice-details">
        <tbody>
          <tr>
            <td colSpan="4" className="tableTop">
              {formattedMessages.licenseFees}
            </td>
          </tr>
          <tr>
            <td>
              <b>No. Licenses</b>
            </td>
            <td>
              <b>Type</b>
            </td>
            <td>
              <b>Revenue ({invoice.currency_type})</b>
            </td>
          </tr>
          <tr>
            <td align="right">{invoice.software_license_fee.quantity}</td>
            <td align="right">{invoice.software_license_fee.type}</td>
            <td align="right">{invoice.software_license_fee.revenue}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    const { invoice, invoiceDetailsData } = this.props;

    if (!invoiceDetailsData || !invoice) {
      return (
        <Fragment>
          <p>{formattedMessages.loading}</p>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <h2>{formattedMessages.invoiceDetails}</h2>
        <h5>
          {formattedMessages.invoiceAnalysisFor}:{' '}
          {invoice.address_to.customer_name}
        </h5>
        <br />
        <p>
          <b>
            {formattedMessages.licenseAndMessageCharges} {this.props.startDate}{' '}
            {formattedMessages.to} {this.props.endDate}
          </b>
        </p>

        {this.generateLicenseTable()}
        <br />
        {this.generateAdjustmentChargeTable()}
        <br />
        <table width="100%" className="invoice-details">
          <tbody>
            <tr>
              <td colSpan="7" className="tableTop">
                {formattedMessages.costCenterChargesSummary}
              </td>
            </tr>
            <tr>
              <td colSpan="4" align="left">
                <b>{formattedMessages.costCenter}</b>
              </td>
              <td align="center">
                <b>{formattedMessages.messages}</b>
              </td>
              <td align="right">
                <b>{invoice.currency_type}</b>
              </td>
            </tr>
            {this.generateCostCentreRows()}
          </tbody>
        </table>
        <br />
        <table width="100%" className="invoice-details">
          <tbody>
            <tr>
              <td colSpan="7" align="left" className="tableTop">
                {formattedMessages.chargeBreakdownbyUserForCostCenter}
              </td>
            </tr>
            <tr>
              <td colSpan="4" align="middle">
                <b>User&apos;s Name</b>
              </td>
              <td align="middle">
                <b>Messages</b>
              </td>
              <td align="right">
                <b>{invoice.currency_type}</b>
              </td>
            </tr>
            {this.generateUserRows()}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

export default InvoiceDetails;
