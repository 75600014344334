// NPM MODULES
import React, { Component } from 'react';
import FormattedMessages from './FormattedMessages';
import { DateTime } from 'luxon';
export default class TraceLogSingle extends Component {
  constructor(props) {
    super(props);
    /*
      Expected Props:
        - traceLog: Contains the trace log info to display
        - backButtonClick: Function to go back to List view
    */
    this.state = {};
  }
  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="ProviderShow">
        <p
          onClick={() => this.props.backButtonClick(false)}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{FormattedMessages.back}</span>
        </p>
      </div>
    );
  };

  generateBody = () => {
    let { traceLog } = this.props;
    // Display sms information
    if (traceLog) {
      let failedDate = DateTime.fromISO(traceLog.created_at);
      let rezoned = failedDate.setZone(localStorage.getItem('timeShort'), {
        keepLocalTime: false,
      });

      // Creates shallow copy of trace log object to remove the json_object
      let traceLogView = { ...traceLog };
      delete traceLogView.json_object;
      traceLogView = JSON.stringify(traceLogView, null, 2);

      return (
        <div>
          <h2>Trace Logs Information</h2>
          <br />
          <b>{FormattedMessages.tableHeaderLogTime}</b>
          <br />
          <span>{rezoned.toFormat('dd-LLL-yyyy h:mm a')}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderLogLevel}</b>
          <br />
          <span>{traceLog.log_level}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderLogGroupId}</b>
          <br />
          <span>{traceLog.log_group_id || 'N/A'}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderProcessName}</b>
          <br />
          <span>{traceLog.process_name}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderProgramName}</b>
          <br />
          <span>{traceLog.program_name}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderLogMessage}</b>
          <br />
          <span className="json-view">{traceLog.log_message}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderTag}</b>
          <br />
          <span>{traceLog.tag || 'N/A'}</span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderJson}</b>
          <br />
          <span className="json-view">
            {JSON.stringify(JSON.parse(traceLog.json_object), null, 2)}
          </span>
          <br />
          <br />
          <b>{FormattedMessages.tableHeaderAllFields}</b>
          <br />
          <span className="json-view">{traceLogView}</span>
        </div>
      );
    }
    return <span> No Data </span>;
  };
  render() {
    return (
      <div className="Home">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
