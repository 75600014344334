// NPM MODULES
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import formattedMessages from './FormattedMessages';
import { DateTime } from 'luxon';
import InboxRequest from './InboxRequest';

const inboxRequest = new InboxRequest();

const inboxSingleDrilldown = ({ message, clickedReply, backButtonClick }) => {
  const [replyData, setReplyData] = useState(null);

  useEffect(() => {
    async function getData() {
      const replyId = message.isEmailReply
        ? message.email_reply_id
        : message.sms_reply_id;
      const isEmailReply = message.isEmailReply || false;

      const replyData = await inboxRequest.getInboxReply(replyId, isEmailReply);
      setReplyData(replyData);
    }

    getData();
  }, []);

  let childSmsText;
  try {
    childSmsText = decodeURIComponent(replyData.child_sms.sms_text);
  } catch (err) {
    childSmsText = 'ERROR: MALFORMED TEXT';
  }
  let parentSmsText;
  try {
    parentSmsText = decodeURIComponent(replyData.message);
  } catch (err) {
    parentSmsText = 'ERROR: MALFORMED TEXT';
  }

  const createdDate = DateTime.fromISO(message.created_date)
    .setZone(localStorage.getItem('timeShort'), {
      keepLocalTime: false,
    })
    .toFormat('dd-LLL-yyyy h:mm:ss a');

  if (!replyData) return 'Loading...';

  return (
    <div className="Home">
      <div className="lander">
        <div className="inboxWrapper">
          <div key="ProviderShow" className="lander provider">
            <p onClick={() => backButtonClick()} className="backLink">
              <i className="material-icons">keyboard_arrow_left</i>
              <span>{formattedMessages.backText}</span>
            </p>
            <h3>{formattedMessages.smsInformation}</h3>
            <h4>{formattedMessages.replyDetails}</h4>
            <b>{formattedMessages.tableHeaderDate}</b>
            <br />
            <span>{createdDate}</span>
            <br />
            <br />
            <b>{formattedMessages.tableFrom}</b>
            <br />
            <span>{message.dest_tn}</span>
            <br />
            <br />
            <b>{formattedMessages.tableHeaderMessage}</b>
            <br />
            <span>{childSmsText}</span>
            <br />
            <br />
            <hr />
            <h4>{formattedMessages.originalMessageDetails}</h4>
            <b>{formattedMessages.originalTime}</b>
            <br />
            <span>
              {DateTime.fromISO(message.sms_sent)
                .setZone(localStorage.getItem('timeShort'), {
                  keepLocalTime: false,
                })
                .toFormat('dd-LLL-yyyy h:mm:ss a')}
            </span>
            <br />
            <br />
            <b>{formattedMessages.originalSender}</b>
            <br />
            <span>{replyData.from}</span>
            <br />
            <br />
            <b>{formattedMessages.originalMessage}</b>
            <br />
            <span>{parentSmsText}</span>
            <br />
            <br />
            <Button
              className="marginRight btn add-new-template"
              onClick={() => clickedReply(message)}
            >
              {formattedMessages.replyButton}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inboxSingleDrilldown;
