/*
ProviderReports.js - Index Component for Customer Reports

Author: Kyle Combeer (2019)
Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap';

// OUR COMPONENTS
import ListComponent from './ListComponent';
import ListItem from './ListItem';
import SingleViewCustomer from './SingleViewCustomer';
import DateRangeSelector from '../../../components/DateRangeSelector';
import ReportTabs from '../index';

// NETWORKING
import ProviderReportRequests from './ProviderReportRequests';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

// INSTANTIATE
const providerReportRequests = new ProviderReportRequests();

export default class Provider extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - customerData: Array of Customers with report data
    */

    this.state = {
      smsList: null,
      data: [],
      activeProviders: [],
      allProviders: [],
      dataExists: false,
      totals: {
        Direct: 0,
        Standard: 0,
        Replies: 0,
        Sent: 0,
        Pending: 0,
        Users: 0,
        Customers: 0,
      },
      selectedData: null,
      searching: false,
      activePage: 'list',
      fromDate: '',
      toDate: '',
      showAll: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.customer && this.props.customer !== null) {
      if (this.props.providerData && this.props.providerData.length !== 0) {
        console.log('Have provider data:', this.props.providerData);
        await this.setState(
          {
            data: this.props.providerData.smslist,
            dataExists: true,
          },
          this.formatData
        );
      }
    }
  };

  sortList(data) {
    let newList = [].slice.call(data).sort(function (a, b) {
      if (a.provider_name < b.provider_name) {
        return -1;
      }
      if (a.provider_name > b.provider_name) {
        return 1;
      }
      return 0;
    });

    return newList;
  }

  /*
    Formats the data in a manner required for the Table
    to properly display the data
  */
  formatData = async () => {
    try {
      let total = {
        Direct: 0,
        Standard: 0,
        Replies: 0,
        Sent: 0,
        Pending: 0,
        DirectParts: 0,
        StandardParts: 0,
        RepliesParts: 0,
        SentParts: 0,
        PendingParts: 0,
        Users: 0,
        Customers: 0,
      };

      let { data } = this.state;
      data = data.map((el) => {
        total.Direct += parseInt(el.direct_sent);
        total.Standard += parseInt(el.messages_sent);
        total.Replies += parseInt(el.replies_sent);
        total.Sent += parseInt(el.reminders_sent);
        total.Pending += parseInt(el.reminders_pending);
        total.DirectParts += parseInt(el.direct_parts);
        total.StandardParts += parseInt(el.messages_parts);
        total.RepliesParts += parseInt(el.replies_parts);
        total.SentParts += parseInt(el.reminders_parts);
        total.PendingParts += parseInt(el.reminders_pending_parts);
        total.Users += parseInt(el.totals.users);
        total.Customers += parseInt(el.totals.customers);
        // if (el.provider_status === 'A') statusName = 'Active';

        return (
          <ListItem
            key={this.state.data.indexOf(el)}
            id={0}
            provider={el.provider_name}
            status={el.provider_status_name}
            customers={el.totals.customers}
            users={el.totals.users}
            Direct={{
              sent: el.direct_sent,
              parts: el.direct_parts,
            }}
            Standard={{
              sent: el.messages_sent,
              parts: el.messages_parts,
            }}
            Replies={{
              sent: el.replies_sent,
              parts: el.replies_parts,
            }}
            Sent={{
              sent: el.reminders_sent,
              parts: el.reminders_parts,
            }}
          />
        );
      });

      return this.setState({
        smsList: data,
        totals: total,
      });
    } catch (e) {
      console.log('data error:', e);
    }
  };

  backButton = () => {
    return this.setState({
      activePage: 'list',
    });
  };

  /*
    Handles searching User Data between two dates
  */
  handleProviderDataSearch = async (fromDate, toDate, timeRange) => {
    this.setState(
      {
        searching: true,
        dataExists: false,
        data: [],
        activeProviders: [],
        allProviders: [],
      },
      async () => {
        let searchParams = {};
        searchParams.fromDate = fromDate;
        searchParams.toDate = toDate;
        searchParams.timeRange = timeRange;
        searchParams.timeRangeStart = fromDate;
        searchParams.timeRangeEnd = toDate;

        let result = await providerReportRequests.getReportData(
          searchParams,
          this.props.errorHandler
        );
        let data = [];
        let activeProviders = result.filter((e) => {
          return e.provider_status === 'A';
        });
        if (this.state.showAll) {
          data = result;
        } else {
          data = activeProviders;
        }

        console.log('provider', result);

        this.setState(
          {
            dataExists: true,
            data: data,
            activeProviders: activeProviders,
            allProviders: result,
            fromDate: fromDate,
            toDate: toDate,
            searching: false,
          },
          this.formatData
        );
      }
    );
  };

  // HANDLERS START
  changePage = async (page, value) => {
    // Update activePage
    return this.setState({
      activePage: page,
      selectedData: value,
    });
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div className="lander">
        <h3>{formattedMessages.providerReportsTitle}</h3>
      </div>
    );
  };

  /*
    Generates the Search Tools for querying the data
  */
  generateSearchTools = () => {
    return (
      <Fragment>
        <DateRangeSelector
          handleDateSearch={(fromDate, toDate, timeRange) =>
            this.handleProviderDataSearch(fromDate, toDate, timeRange)
          }
        ></DateRangeSelector>
        <br />
        <br />
      </Fragment>
    );
  };
  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    console.log('handleInputChange event name:', name);
    console.log('handleInputChange event value:', value);

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === 'showAll' && this.state.dataExists) {
          if (value === false) {
            console.log('activeCustomers', this.state.activeProviders);
            this.setState(
              { data: this.state.activeProviders },
              this.formatData
            );
          } else {
            this.setState({ data: this.state.allProviders }, this.formatData);
          }
        }
      }
    );
  }

  handleShowAllCheckBox = () => {
    return (
      <div className="textRight">
        <Form>
          <Form.Check
            reverse
            type="checkbox"
            name="showAll"
            label={'Show All'}
            defaultChecked={this.state.showAll}
            onChange={(e) => this.handleInputChange(e)}
          ></Form.Check>
        </Form>
      </div>
    );
  };

  render() {
    let { accessLevel, accessType } = this.props;

    if (accessLevel === '3' && accessType === '3') {
      let renderContent = null;
      switch (this.state.activePage) {
        // TODO: There was previously a bunch of copied commented code for various single views which may be a future requirement.
        case 'svCustomer':
          renderContent = (
            <SingleViewCustomer
              customerId={this.state.selectedData}
              backButton={this.backButton}
            />
          );
          break;
        default:
          renderContent = (
            <div className="Home">
              {this.generateLander()}
              {this.generateSearchTools()}
              <ListComponent
                changePage={this.changePage}
                totals={this.state.totals}
                list={this.state.smsList}
                loading={!this.state.dataExists}
                checkBox={this.handleShowAllCheckBox}
              />
            </div>
          );
      }

      if (this.state.searching)
        renderContent = (
          <div className="Home">
            {this.generateLander()}
            {this.generateSearchTools()}
            Searching...
          </div>
        );

      return (
        <ReportTabs
          tab="provider"
          accessLevel={accessLevel}
          accessType={accessType}
        >
          {renderContent}
        </ReportTabs>
      );
    }
    return null;
  }
}
