/*
  SingleView.js - Admin Carrier Device Single View

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

// NETWORK
import CarrierRequest from '../CarrierRequest';
import formattedMessages from '../../../../Provider/FormattedMessages';

// INSTANTIATION
const carrierRequest = new CarrierRequest();

export default class CarrierDeviceSingleView extends Component {
  constructor(props) {
    super(props);

    /*
      Props List:
        - carrierDevice: A single Carrier Device
        - deviceId: (Optional) If present, do not display list of Carriers
        - backButtonClick: Function to handle Back Button click
        - errorHandler: A function to pass errors to
    */

    console.log('Have carrier device props:', this.props);

    this.state = {
      isEditing: false,
      edits: {},
      selectableDevices: [],
      apply_provider: 'this',
      isDeleting: false,
    };
  }

  async componentDidMount() {
    let { carrierDevice, errorHandler, deviceId, country } = this.props;

    if (!deviceId) {
      // Get Carrier Devices for Carrier
      let selectableDevices = await carrierRequest.getSelectableDevices(
        country.country_code_id,
        carrierDevice.carrier_id,
        errorHandler
      );
      console.log('selectableDevices:', selectableDevices);
      return this.setState({
        selectableDevices: selectableDevices,
      });
    }
  }

  /*
    Handles Edit Mode on/off
  */
  toggleEdit = () => {
    return this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;
    edits[name] = value;

    console.log('Edits is:', edits);

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let data = this.state.edits;
      let { carrierDevice, errorHandler } = this.props;

      // Update Carrier Device
      await carrierRequest.updateCarrierDevice(
        carrierDevice.country_code_id,
        carrierDevice.carrier_id,
        carrierDevice.carrier_device_id,
        data,
        errorHandler
      );
      // Force reload
      window.location.href = '/admin/serviceconfig-country'; // TODO: redo properly
    } catch (e) {
      console.log('Have err:', e);
    }
  };

  /*
    Handles Delete of Carrier Device
  */
  handleDelete = async () => {
    let { carrierDevice, errorHandler } = this.props;
    this.setState({ isDeleting: true }, async () => {
      await carrierRequest.deleteCarrierDeviceById(
        carrierDevice.country_code_id,
        carrierDevice.carrier_id,
        carrierDevice.carrier_device_id,
        carrierDevice.provider_id,
        errorHandler
      );
      this.props.backButtonClick('refresh');
    });
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="CountryShow" className="lander carrier-device">
        <p
          onClick={() => this.props.backButtonClick('list')}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
        <h3>Carrier Device Information</h3>
      </div>
    );
  };

  generateDeviceSelect = () => {
    let list = [];
    let { selectableDevices } = this.state;

    for (let i = 0; i < selectableDevices.length; i++) {
      list.push(
        <option
          key={selectableDevices[i].device_id}
          value={selectableDevices[i].device_id}
        >
          {selectableDevices[i].device_name}
        </option>
      );
    }

    return (
      <select
        name="device_id"
        defaultValue={this.props.carrierDevice.device_id}
        className={this.props.className}
        onChange={(e) => {
          if (this.props.onChange) this.props.onChange(e);
        }}
      >
        {list}
      </select>
    );
  };

  generateProviderSelect = () => {
    if (!this.state.edits.device_id) return null;

    return (
      <Form.Group as={Row} controlId="apply_provider">
        <Form.Label column sm={2} className="info-label">
          Apply this to current Provider or ALL Providers?
        </Form.Label>
        <Col sm={8}>
          <Form.Check
            type="radio"
            name="apply_provider"
            value="this"
            defaultChecked={this.state.apply_provider === 'this'}
            label="This Provider"
          ></Form.Check>
          <Form.Check
            type="radio"
            name="apply_provider"
            value="all"
            defaultChecked={this.state.apply_provider === 'all'}
            label="All Providers"
          ></Form.Check>
        </Col>
      </Form.Group>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateEditButton = () => {
    let { isEditing } = this.state;
    let buttonText = isEditing ? 'Cancel' : 'Edit';

    if (
      !this.props.isNew &&
      typeof this.props.isNew === 'undefined' &&
      !this.props.deviceId
    ) {
      return (
        <Button className="maxHeight" onClick={this.toggleEdit}>
          {buttonText}
        </Button>
      );
    }
    return null;
  };

  generateBody = () => {
    let { carrierDevice } = this.props;
    let { isEditing, isDeleting } = this.state;

    // Not editing, display data
    if (carrierDevice && !isEditing) {
      return (
        <Form>
          {this.generateFormGroup(
            '* Carrier:',
            carrierDevice.carrier_name,
            'carrier_name'
          )}
          {this.generateFormGroup(
            '* Device:',
            carrierDevice.device_name,
            'device_name'
          )}
          {this.generateFormGroup(
            '* Priority:',
            carrierDevice.priority,
            'priority'
          )}
          {this.generateEditButton()}
          {!isDeleting && (
            <Button
              className="delete btn-red btn btn-danger"
              onClick={this.handleDelete}
            >
              Delete
            </Button>
          )}
          {isDeleting && 'Deleting...'}
        </Form>
      );
    }
    // Is editing
    else if (carrierDevice && isEditing) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            {this.generateFormGroup(
              '* Carrier:',
              carrierDevice.carrier_name,
              'carrier_name'
            )}
            <Form.Group as={Row} controlId="device_name">
              <Form.Label column sm={2} className="info-label">
                * Device:
              </Form.Label>
              <Col sm={8}>{this.generateDeviceSelect()}</Col>
            </Form.Group>
            {this.generateProviderSelect()}
            <Form.Group as={Row} controlId="priority">
              <Form.Label column sm={2} className="info-label">
                * Priority:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="priority"
                  type="text"
                  defaultValue={carrierDevice.priority}
                />
              </Col>
            </Form.Group>
            <Button className="maxHeight" variant="primary" type="submit">
              Save
            </Button>
          </Form>
          {this.generateEditButton()}
        </Fragment>
      );
    }

    return <span>(Carrier Device) No Data</span>;
  };

  render() {
    return (
      <div className="Home carrier-device">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
