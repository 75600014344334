import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  backText: <FormattedMessage id="Global.BackText" defaultMessage="Back" />,
  messagesText: (
    <FormattedMessage id="ReportsList.Messages" defaultMessage="Messages" />
  ),
  whenText: <FormattedMessage id="ReportsList.When" defaultMessage="When" />,
  dayText: <FormattedMessage id="ReportsList.Day" defaultMessage="Day" />,
  dateText: <FormattedMessage id="ReportsList.Date" defaultMessage="Date" />,
  timeText: <FormattedMessage id="ReportsList.Time" defaultMessage="Time" />,
  statusText: (
    <FormattedMessage id="ReportsList.Status" defaultMessage="Status" />
  ),
  to: (
    <FormattedMessage
      id="ReportsList.to"
      defaultMessage="to"
    ></FormattedMessage>
  ),
  from: (
    <FormattedMessage
      id="ReportsList.from"
      defaultMessage="From"
    ></FormattedMessage>
  ),
  phoneNumberText: (
    <FormattedMessage
      id="ReportsList.PhoneNumber"
      defaultMessage="Phone Number"
    />
  ),
  countryText: (
    <FormattedMessage id="ReportsList.Country" defaultMessage="Country" />
  ),
  localFlagText: (
    <FormattedMessage id="ReportsList.LocalFlag" defaultMessage="Local Flag" />
  ),
  quantityText: (
    <FormattedMessage id="ReportsList.Quantity" defaultMessage="Quantity" />
  ),
  creditsUsedText: (
    <FormattedMessage
      id="ReportsList.CreditsUsed"
      defaultMessage="Credits Used"
    />
  ),
  dataText: <FormattedMessage id="ReportsList.Data" defaultMessage="Data" />,
  typeText: <FormattedMessage id="ReportsList.Type" defaultMessage="Type" />,
  pendingReminders: (
    <FormattedMessage
      id="ReportsList.PendingReminders"
      defaultMessage="Pending Reminders"
    />
  ),
  userReplies: (
    <FormattedMessage
      id="ReportsList.UserReplies"
      defaultMessage="User Replies"
    />
  ),
  sentReplies: (
    <FormattedMessage
      id="ReportsList.SentReplies"
      defaultMessage="Sent Reminders"
    />
  ),
  standardMessages: (
    <FormattedMessage
      id="ReportsList.StandardMessages"
      defaultMessage="Standard Messages"
    />
  ),
  name: <FormattedMessage id="Global.Name" defaultMessage="Name" />,
  number: <FormattedMessage id="Global.Number" defaultMessage="Number" />,
  email: <FormattedMessage id="Global.Email" defaultMessage="Email" />,
  country: <FormattedMessage id="Global.Country" defaultMessage="Country" />,
  language: <FormattedMessage id="Global.Language" defaultMessage="Language" />,
  maxdailymessages: (
    <FormattedMessage
      id="Global.Maxdailymessages"
      defaultMessage="Maximum Daily Messages"
    />
  ),
  smsInCred: (
    <FormattedMessage id="Global.SmsInCred" defaultMessage="SMS in credit:" />
  ),
  smsInExcess: (
    <FormattedMessage id="Global.smsInExcess" defaultMessage="SMS in excess:" />
  ),
  typeClassStatus: (
    <FormattedMessage
      id="Global.typeClassStatus"
      defaultMessage="Type-Class-Status"
    />
  ),
  active: <FormattedMessage id="Websms.Active" defaultMessage="Active" />,
  inactive: <FormattedMessage id="Websms.Inactive" defaultMessage="Inactive" />,
  editThisUser: (
    <FormattedMessage
      id="Websms.EditThisUser"
      defaultMessage="Edit this user"
    />
  ),
  cancelAllPending: (
    <FormattedMessage
      id="ReportsList.CancelAllPending"
      defaultMessage="Cancel All Pending"
    />
  ),
  reallyCancelAllPending: (
    <FormattedMessage
      id="ReportsList.ReallyCancelAllPending"
      defaultMessage="Really Cancel All Pending Messages?"
    />
  ),
  yes: <FormattedMessage id="Global.Yes" defaultMessage="Yes" />,
  no: <FormattedMessage id="Global.No" defaultMessage="No" />,
  directMessages: (
    <FormattedMessage
      id="ReportsList.DirectMessages"
      defaultMessage="Direct Messages"
    />
  ),
  reportUserDetail: (
    <FormattedMessage
      id="ReportsList.ReportUserDetail"
      defaultMessage="User Details"
    ></FormattedMessage>
  ),
  messagesTitle: (
    <FormattedMessage id="ReportsList.Messages" defaultMessage="Messages" />
  ),
  loading: (
    <FormattedMessage id="ReportsList.Loading" defaultMessage="Loading..." />
  ),
  topUserReportsTitle: (
    <FormattedMessage
      id="ReportsList.TopUserReportsTitle"
      defaultMessage="User Reports"
    />
  ),
  filterTitle: (
    <FormattedMessage
      id="ReportsList.FilterTitle"
      defaultMessage="Filtered by Cost Centre :"
    ></FormattedMessage>
  ),
  filterUserTitle: (
    <FormattedMessage
      id="ReportsList.FilterTitle"
      defaultMessage="Filtered by User :"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
