import React, { Component } from 'react';
import FormattedMessage from './FormattedMessages';
import ListComponent from './ListComponent';
import ListItem from './ListItem';
import CancelledCustomerListItem from './ListItem/cancelledCustomer';
import { Form } from 'react-bootstrap';
import BillingTabs from '../../index';

// NETWORKING
import CommissionSummaryRequest from './CommissionSummaryRequest';

// INSTANTIATE
const commissionSummaryRequest = new CommissionSummaryRequest();

// NPM MODULES
const { DateTime } = require('luxon');

class CommissionReport extends Component {
  constructor(props) {
    super(props);

    const currentDate = new Date();
    const monthToQuery = currentDate.getMonth() + 1;
    const yearToQuery = currentDate.getYear() + 1900;

    this.state = {
      listNewCustomers: null,
      listCancelledCustomers: null,
      dataNewCustomer: null,
      dataCancelledCustomer: null,
      totals: null,
      loading: true,
      pageCount: 0,
      selectedMonth: monthToQuery,
      selectedYear: yearToQuery,
    };
  }

  componentDidMount = async () => {
    if (this.props.customer && this.props.customer !== null)
      this.getReportData(this.state.selectedMonth);
  };

  getReportData = async () => {
    this.setState({
      loading: true,
    });
    let { selectedMonth, selectedYear } = this.state;

    const commissionData =
      await commissionSummaryRequest.getCommissionReportData(
        selectedMonth,
        selectedYear,
        this.props.errorHandler
      );
    this.setState(
      {
        dataNewCustomer: commissionData.new,
        dataCancelledCustomers: commissionData.cancelled,
        dataExists: true,
      },
      this.formatData
    );
  };

  /**
   * Sorts list of customers by created_date
   * @param {Array} data Array of customers (either new or cancelled)
   * @returns Sorted list
   */
  sortList(data) {
    return data.sort(function (a, b) {
      if (a.created_date < b.created_date) {
        return -1;
      }
      if (a.created_date > b.created_date) {
        return 1;
      }
      return 0;
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      this.getReportData
    );
  }

  /*
    Formats the data in a manner required for the Table
    to properly display the data
  */
  formatData = async () => {
    const { dataNewCustomer, dataCancelledCustomers } = this.state;

    let totals = {
      Total: 0,
      Count: dataNewCustomer?.length,
      Cancelled: dataCancelledCustomers?.length,
      totalUsers: 0,
    };

    const newCustomerList = dataNewCustomer.map((el) => {
      const formatDate = DateTime.fromISO(el.created_date).toFormat(
        'dd-MMM-yy'
      );
      return (
        <ListItem
          key={el.customer_id}
          id={el.customer_id}
          createdDate={formatDate}
          date={el.status_date}
          customerID={el.customer_friendly_id}
          customerName={el.customer_name}
          customerType={el.customer_type}
          customerLocation={el.country_name}
          agent={el.sales_agent_name}
          minCommitment={el.minimum_commitment}
          alreadyBilled={el.alreadyBilled}
        />
      );
    });

    dataNewCustomer.forEach((el) => {
      totals.Total = totals.Total + parseInt(el.minimum_commitment);
    });

    const cancelledCustomerList = await dataCancelledCustomers.map((el) => {
      const formatDate = DateTime.fromISO(el.created_date).toFormat(
        'dd-MMM-yy'
      );
      totals.totalUsers += el.numberOfUsers;
      return (
        <CancelledCustomerListItem
          key={el.customer_id}
          id={el.customer_id}
          createdDate={formatDate}
          date={el.status_date}
          customerID={el.customer_friendly_id}
          customerName={el.customer_name}
          customerType={el.customer_type}
          customerLocation={el.country_name}
          agent={el.sales_agent_name}
          numberOfUsers={el.numberOfUsers}
        />
      );
    });

    return this.setState({
      listNewCustomers: newCustomerList,
      listCancelledCustomers: cancelledCustomerList,
      totals: totals,
      loading: false,
    });
  };

  generateDateSelector = () => {
    const yearList = [];
    const date = new Date();
    const year = date.getFullYear();

    for (let i = 0; i <= 10; i++) {
      const selectedYear = year - i;
      yearList.push(<option value={selectedYear}>{selectedYear}</option>);
    }

    const yearDropdown = (
      <div className="filter-item">
        <td>
          <Form.Label className="no-margin">Year:&nbsp;&nbsp;</Form.Label>
        </td>
        <td>
          <div>
            <Form.Control
              as="select"
              name="selectedYear"
              value={this.state.selectedYear}
              onChange={(e) => this.handleInputChange(e)}
            >
              {yearList}
            </Form.Control>
          </div>
        </td>
      </div>
    );

    return (
      <div className="floatRight">
        <Form id="filter-form">
          <table className="no-table-row-background">
            <tbody className="filter-table">
              <div className="filter">
                <div className="filter-item">
                  <td>
                    <Form.Label className="no-margin">
                      Month:&nbsp;&nbsp;
                    </Form.Label>
                  </td>
                  <td>
                    <div>
                      <Form.Control
                        as="select"
                        name="selectedMonth"
                        value={this.state.selectedMonth}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="1">{FormattedMessage.January}</option>
                        <option value="2">{FormattedMessage.February}</option>
                        <option value="3">{FormattedMessage.March}</option>
                        <option value="4">{FormattedMessage.April}</option>
                        <option value="5">{FormattedMessage.May}</option>
                        <option value="6">{FormattedMessage.June}</option>
                        <option value="7">{FormattedMessage.July}</option>
                        <option value="8">{FormattedMessage.August}</option>
                        <option value="9">{FormattedMessage.September}</option>
                        <option value="10">{FormattedMessage.October}</option>
                        <option value="11">{FormattedMessage.November}</option>
                        <option value="12">{FormattedMessage.December}</option>
                      </Form.Control>
                    </div>
                  </td>
                </div>
                {yearDropdown}
              </div>
            </tbody>
          </table>
        </Form>
      </div>
    );
  };

  render() {
    let { accessLevel, accessType } = this.props;

    if (accessLevel <= '0' || accessType === '0') {
      return null;
    }
    if (this.state.loading) {
      return (
        <BillingTabs
          tab="new-customer-report"
          accessLevel={accessLevel}
          accessType={accessType}
        >
          {FormattedMessage.loading}
        </BillingTabs>
      );
    } else {
      return (
        <BillingTabs
          tab="new-customer-report"
          accessLevel={accessLevel}
          accessType={accessType}
        >
          <div className="Home">
            <div className="lander">
              {this.generateDateSelector()}
              <h3>{FormattedMessage.newCustomerReport}</h3>
              <br></br>
              <div className="list-container">
                <div className="list-container">
                  <ListComponent
                    changePage={this.changePage}
                    totals={this.state.totals}
                    listNewCustomers={this.state.listNewCustomers}
                    listCancelledCustomers={this.state.listCancelledCustomers}
                    handlePagination={this.handlePagination}
                    pageCount={this.state.pageCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </BillingTabs>
      );
    }
  }
}

export default CommissionReport;
