// NPM MODULES
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

// OUR COMPONENTS
import ListItem from '../ListItem';
import formattedMessages from './FormattedMessages';

// NETWORKING
// import UserRequests from '../../UserRequests';

// INSTANTIATE
// const userRequests = new UserRequests();

class ListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
    };
  }

  returnCostCentreName = (costCentreId) => {
    for (let i = 0; i < this.props.costCentres.length; i++) {
      if (this.props.costCentres[i].cost_centre_id === costCentreId) {
        return this.props.costCentres[i].cost_centre_name;
      }
    }
    return null;
  };

  /*
    Handles when a ListItem is clicked on
  */
  returnListItem = (listItemId) => {
    console.log('List Item click:', listItemId);
    this.props.callBack(listItemId);
  };

  render() {
    let { list } = this.props;
    console.log(list);

    let fullList = list.map((element) => {
      return (
        <ListItem
          key={element.customer_user_id}
          onClick={() => this.returnListItem(element.customer_user_id)}
          name={element.user_name}
          email={element.user_email}
          number={element.user_tn}
          status={element.user_status}
          msgmax={element.max_sms_per_day}
          //version={element.sw_release}
          centre={
            this.returnCostCentreName(element.cost_centre_id) || 'Not Assigned'
          }
        />
      );
    });

    return (
      <Table className="user-table">
        <thead>
          <tr key="head">
            <th className="medium tableTop textLeft">
              {formattedMessages.name}
            </th>
            <th className="medium tableTop textLeft">
              {formattedMessages.email}
            </th>
            <th className="medium tableTop textLeft">
              {formattedMessages.cell}
            </th>
            <th className="medium tableTop textLeft">
              {formattedMessages.status}
            </th>
            <th className="medium tableTop textLeft">
              {formattedMessages.maxDailyMessage}
            </th>
            {/* <th className='medium'>Red Oxygen software release</th> */}
            <th className="medium tableTop textLeft">
              {formattedMessages.costCenter}
            </th>
            <th className="icon tableTop"></th>
          </tr>
        </thead>
        <tbody>{fullList}</tbody>
      </Table>
    );
  }
}

export default ListView;
