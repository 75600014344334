/*
  App.js - Login Root Component

  Author: Elle Dunbar (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { FormGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// OUR MODULES
import LoaderButton from '../../components/LoaderButton';

// NETWORKING
import LoginRequest from './LoginRequest';

// I18N Strings
import FormattedMessages from './FormattedMessages';

// STYLES
import './Login.css';

// IMGS
import roxLogo from '../../images/Logo-RO4.gif';

// TIMEZONES
import TimezoneRequest from '../../components/requests/TimezoneRequest';

// INSTANTIATE
const timezoneRequest = new TimezoneRequest();

const loginRequest = new LoginRequest();

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      windowLocation: null,
      isLoading: false,
      email: '',
      password: '',
      hasError: false,
      errorText: '',
    };
  }

  async componentDidMount() {
    console.log(this.props);
    if (this.props.isAuthenticated) {
      this.setState({
        isLoading: false,
        hasError: false,
        errorText: '',
      });
      this.props.navigate('/home');
    }
  }

  /*
      Ensures we have some values in the form before posting
  */
  validateForm = () => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  };

  /*
      Handles changes in the form
  */
  handleChange = (event) => {
    let value = event.target.value;
    if (event.target.id === 'email') {
      value = value.toLowerCase();
    }
    this.setState({
      [event.target.id]: value,
    });
  };

  /*
    Handles the actual submission of the form
  */
  handleSubmit = async (event, platform) => {
    event.preventDefault();

    // Set loading state
    this.setState({
      isLoading: true,
      hasError: false,
      errorText: '',
    });

    try {
      // check if both fields are populated
      if (!this.validateForm()) {
        return this.setState({
          isLoading: false,
          hasError: true,
          errorText: 'Please fill out both fields',
        });
      }

      // Make network Req for Login
      let result = await loginRequest.login(
        this.state.email,
        this.state.password,
        this.props.errorHandler
      );

      if (result === 403) {
        return this.setState({
          isLoading: false,
          hasError: true,
          errorText: 'Contact your Administrator',
        });
      }
      // Handle login failure
      if (result === 401) {
        console.log('Incorrect Login Details');
        return this.setState({
          isLoading: false,
          hasError: true,
          errorText: 'Incorrect Login Details',
        });
      }

      if (!result || typeof result === 'undefined') {
        throw new Error('Network Error');
      }

      await localStorage.setItem('rox_user_token', 'exists');
      await localStorage.setItem('rox_user_email', this.state.email);
      // send authenticated state back to App.js
      this.props.userHasAuthenticated(true, result);
      this.props.removeError();
      let time = await timezoneRequest.getTimezoneOffset();

      // Long string for timezone, used for display
      localStorage.setItem('timeString', time.timezone_name);
      // Short string for timezone, used for luxon calculations
      localStorage.setItem('timeShort', time.time_short);
      // Short code-like string for timezone, used for unique ID for the timezone
      localStorage.setItem('timezone', time.timezone_offset);
      // Holds user's time range when its not a set date range but rather a descriptor. Defaults to week upon login.
      localStorage.setItem('timeRange', '7');
      // Holds user's time range for the start of a date range.
      localStorage.setItem('timeRangeStart', null);
      // Holds user's time range for the end of a date range.
      localStorage.setItem('timeRangeEnd', null);

      // change page depending on platform
      this.props.navigate(platform === 'desktop' ? '/home' : '/websms/compose');
    } catch (e) {
      console.error(e);
      return this.setState({
        isLoading: false,
        hasError: true,
        errorText: 'Network Error',
      });
    }
  };

  generateError = () => {
    // No error, nothing to show
    if (!this.state.hasError) return null;
    // Return error
    return <div className="text-danger error">{this.state.errorText}</div>;
  };

  render() {
    return (
      <div className="Login visible-desktop-login-block ">
        <div className="visible-desktop-login">
          <div className="customerVerify">
            <br />
            <img src={roxLogo} alt={`Red Oxygen Logo ${process.env.REACT_APP_COLOUR}`} />
            <br />
            <br />
            <form onSubmit={(e) => this.handleSubmit(e, 'desktop')}>
              <FormGroup controlId="email" bssize="large">
                <b>{FormattedMessages.emailText}</b>
                <FormControl
                  autoFocus
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup controlId="password" bssize="large">
                <p>
                  <span className="float-left">
                    {' '}
                    <b>{FormattedMessages.passwordText}</b>
                  </span>
                  <span className="float-right">
                    <a href="/forgotpassword">
                      {FormattedMessages.forgotPassword}
                    </a>
                  </span>
                </p>
                <FormControl
                  value={this.state.password}
                  onChange={this.handleChange}
                  type="password"
                />
              </FormGroup>
              {this.generateError()}
              <LoaderButton
                block
                bssize="large"
                disabled={false}
                type="submit"
                className="full-width"
                isLoading={this.state.isLoading}
                text={FormattedMessages.loginText}
                loadingText={FormattedMessages.loginLoadingText}
              />
            </form>
          </div>
          <div className="register">
            <span>
              {FormattedMessages.signUpText}
              <Link className="register-button" to="/register">
                {FormattedMessages.registerText}
              </Link>
            </span>
          </div>
        </div>

        <div className="visible-device">
          <div className="customerVerify">
            <br />
            <img
              src={roxLogo}
              alt={`Red Oxygen Logo Mobile ${process.env.REACT_APP_COLOUR}`}
              style={{ marginLeft: 'calc(50% - 85px)' }}
            />
            <br />
            <br />
            <form onSubmit={(e) => this.handleSubmit(e, 'mobile')}>
              <FormGroup controlId="email" bssize="large">
                <b>{FormattedMessages.emailText}</b>
                <FormControl
                  autoFocus
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup controlId="password" bssize="large">
                <p>
                  <span className="float-left">
                    <b>{FormattedMessages.passwordText}</b>
                  </span>
                  <span className="float-right">
                    <a href="/forgotpassword">
                      {FormattedMessages.forgotPassword}
                    </a>
                  </span>
                </p>
                <FormControl
                  value={this.state.password}
                  onChange={this.handleChange}
                  type="password"
                />
              </FormGroup>
              {this.generateError()}
              <LoaderButton
                block
                bssize="large"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text={FormattedMessages.loginText}
                loadingText={FormattedMessages.loginLoadingText}
              />
            </form>
          </div>
          <br />
          <div className="register">
            <span>
              {FormattedMessages.signUpText}
              <Link className="register-button" to="/register">
                {FormattedMessages.registerText}
              </Link>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
