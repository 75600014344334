import React from 'react';

const ListItem = ({
  muxId,
  status = '',
  protocol = '',
  txOk = '',
  txErr = '',
  rx = '',
  today = '',
  maxPerDay = '',
  H = '',
  M = '',
  L = '',
  age = '',
  isTwilio = false,
  onClickName = null,
  ...props
}) => (
  <tr key={`list${muxId}`} id={muxId}>
    <td className="" name="muxId">
      <span className="blue" onClick={() => onClickName(muxId, isTwilio)}>
        {muxId}
      </span>
    </td>
    <td className="" name="status">
      {status}
    </td>
    <td className="" name="protocol">
      {protocol}
    </td>
    <td className="" name="txOk">
      {txOk}
    </td>
    <td className="" name="txErr">
      {txErr}
    </td>
    <td className="" name="rx">
      {rx}
    </td>
    <td className="" name="today">
      {today}/{maxPerDay}
    </td>
    <td className="" name="H">
      {H}
    </td>
    <td className="" name="M">
      {M}
    </td>
    <td className="" name="L">
      {L}
    </td>
    <td className="" name="age">
      {age}
    </td>
  </tr>
);

export default ListItem;
