/*
  CountryRequest.js - Service Config - Country Networking File

  Contains networking functionality for Service Config - Country Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../../RequestRabbit';

// CONFIGURATION
import config from '../../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class CountryRequest {
  /*
    Gets a list of Countries
  */
  getCountries = async (errorHandler) => {
    let url = `${config.baseServerURL}/admin/country`;
    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Given a Country Code ID and an object containing K:V pairs of updates, send to
    the server to process.
  */
  updateCountryById = async (countryCodeId, updates, errorHandler) => {
    const url = `${config.baseServerURL}/admin/country/${countryCodeId}`;
    const body = JSON.stringify(updates);
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      body: body,
      headers: headers,
    };

    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Returns a list of Carriers for the given Country Code.
  */
  getCarriersByCountry = async (countryCodeId, errorHandler) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier`;
    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Returns a list of Prefix Maps for the given Country Code
  */
  getPrefixMapsByCountry = async (countryCodeId, errorHandler) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/prefix`;
    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  createNewPrefixMap = async (countryCodeId, newPrefixMap, errorHandler) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/prefix`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(newPrefixMap),
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Updates a Prefix Map by its ID.
  */
  updatePrefixMap = async (
    countryCodeId,
    countryPrefixMapId,
    updates,
    errorHandler
  ) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/prefix/${countryPrefixMapId}`;

    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(updates),
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Deletes a Prefix Map by its ID.
  */
  deletePrefixMapById = async (
    countryCodeId,
    countryPrefixMapId,
    errorHandler
  ) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/prefix/${countryPrefixMapId}`;
    const options = {
      method: 'DELETE',
    };
    await requestRabbit.request(url, options, errorHandler);
  };
}
