import React from 'react';

const Customer = ({
  id,
  onClick,
  onClickProvider,
  providerId,
  providerName = '',
  customerName = '',
  type = '',
  customerClass = '',
  status = '',
  authCode = '',
  domain = '',
  ipAddress = '',
  ...props
}) => (
  <tr key={id} id={id}>
    <td
      className="medium underline blue textLeft"
      onClick={() => onClickProvider()}
    >
      {providerName}
    </td>
    {/* add faking the customer that is selected to the navigation */}
    {/* <td className="medium textLeft"><a href="/Reports/cost-center" className='red'>{customerName}</a></td> */}
    <td className="medium underline blue textLeft" onClick={() => onClick()}>
      {customerName}
    </td>
    <td className="medium textLeft">{type}</td>
    <td className="medium textLeft">{customerClass}</td>
    <td className="medium textLeft">{status}</td>
    <td className="medium textLeft">{authCode}</td>
    <td className="medium textLeft">{domain}</td>
    <td className="medium textLeft">{ipAddress}</td>
    {/*<td className="icon"><i id={id + "icon"} className="material-icons floatRight">keyboard_arrow_right</i></td>*/}
  </tr>
);

export default Customer;
