/*
  index.js - Find Users Main Component

  Author: Elle Dunbar (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

// COMPONENTS
import AdminTabs from '../index';
import RecentComponent from './RecentComponent';
import SearchComponent from './SearchComponent';
import StatisticsComponent from './StatisticsComponent';
import CollapsedComponent from './CollapsedComponent';

// NETWORK
import AdminRequest from '../AdminRequest';

// I18N
import formattedMessages from './FormattedMessages';

// STYLING
import '../../Users/Users.css';

const adminRequest = new AdminRequest();

export default class systemMonitor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'Recent',
      collapsedValues: null,
      searchValues: [],
      totalStats: [],
      totalTwilioStats: [],
      primaryQueueStats: [],
      statsPageReady: false,
      errorState: false,
      firstRefresh: true,
      routeNameResults: [],
      selectedRouteName: null,
    };
  }

  async componentDidMount() {
    if (this.props.customer && this.props.customer !== null)
      await this.getStats();
  }

  setEmailFilter = () => null;

  goToRecent = (email) => {
    this.setState(
      {
        tab: 'Recent',
      },
      () => this.setEmailFilter(email)
    );
  };

  /**
   * gets log messages for a specified email ID
   * @param {uuid | String} emailID
   * @returns
   */
  getDrilldownMessages = async (emailID) => {
    try {
      let values = await adminRequest.getEmailDrilldown(
        emailID,
        this.props.errorHandler
      );
      return values;
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * gets log messages for a specified sms ID
   * @param {uuid | String} smsID
   * @returns
   */
  getSmsIdDrilldownMessages = async (smsID) => {
    try {
      let values = await adminRequest.getSmsIdDrilldown(
        smsID,
        this.props.errorHandler
      );
      return values;
    } catch (e) {
      console.log(e);
    }
  };

  getMuxIdDrilldown = async (muxId, isTwilio) => {
    try {
      let muxIdDrilldown = await adminRequest.getMuxIdDrilldown(
        muxId,
        isTwilio,
        this.props.errorHandler
      );
      return muxIdDrilldown;
    } catch (e) {
      console.log(e);
    }
  };

  getStats = async () => {
    try {
      let primaryQueueStats = await adminRequest.getPrimaryQueueStats(
        this.props.errorHandler
      );
      let totalStats = await adminRequest.getTotalStatsPerMuxId(
        this.props.errorHandler
      );
      let totalTwilioStats = await adminRequest.getTotalTwilioStatsPerMuxId(
        this.props.errorHandler
      );
      this.setState({
        totalStats: totalStats,
        totalTwilioStats: totalTwilioStats,
        primaryQueueStats: primaryQueueStats,
        statsPageReady: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Gets messages based on a search query. Used in SearchComponent, in submitHandler().
   * @param {String} searchQuery
   * @param {Number} searchType
   * @param {Date} startDate
   * @param {Date} endDate
   */
  getSearchMessages = async (searchQuery, searchType, startDate, endDate) => {
    try {
      console.log('searching for', searchQuery);
      let searchValues = await adminRequest.getSearchLogMessages(
        searchQuery,
        searchType,
        startDate,
        endDate,
        this.props.errorHandler
      );
      this.setState({ searchValues: searchValues });
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Generates the tabs for the system monitor: 'Statistics', 'Recent' and 'Search'
   * @returns
   */
  generateTabs = () => {
    let { tab } = this.state;
    return (
      <Fragment>
        <div className="visible-desktop">
          <div className="d-inline floatRight">
            Environment: {process.env.REACT_APP_ENV || 'local'}
          </div>
          <h3>{formattedMessages.systemMonitorTitle}</h3>
          <br />
          <span className="main">
            <Tabs
              activeKey={tab}
              onSelect={(key) => {
                this.setState({
                  tab: key,
                });
              }}
            >
              <Tab eventKey="Recent" title="Recent">
                <RecentComponent
                  getDrilldownMessages={this.getDrilldownMessages}
                  getSmsIdDrilldownMessages={this.getSmsIdDrilldownMessages}
                  setEmailFilter={(ref) => (this.setEmailFilter = ref)}
                />
              </Tab>
              <Tab eventKey="Collapsed" title="Collapsed">
                <CollapsedComponent
                  values={this.state.collapsedValues}
                  goToRecent={this.goToRecent}
                />
              </Tab>
              <Tab eventKey="Search" title="Search">
                <SearchComponent
                  values={this.state.searchValues}
                  getDrilldownMessages={this.getDrilldownMessages}
                  getSmsIdDrilldownMessages={this.getSmsIdDrilldownMessages}
                  getSearchMessages={this.getSearchMessages}
                />
              </Tab>
              <Tab eventKey="Statistics" title="Statistics">
                <StatisticsComponent
                  primaryQueueStats={this.state.primaryQueueStats}
                  totalStats={this.state.totalStats}
                  totalTwilioStats={this.state.totalTwilioStats}
                  statsPageReady={this.state.statsPageReady}
                  getMuxIdDrilldown={this.getMuxIdDrilldown}
                />
              </Tab>
            </Tabs>
          </span>
          <br />
        </div>
      </Fragment>
    );
  };

  /**
   * Generates teh system monitor tab and all of its content, if user is God Admin.
   * @returns
   */
  render() {
    let { accessLevel, accessType } = this.props;

    //show admin search forms
    if (accessLevel === '3' && accessType === '3') {
      if (this.state.errorState) {
        return (
          <AdminTabs
            tab="systemMonitor"
            accessLevel={accessLevel}
            accessType={accessType}
          >
            Error
          </AdminTabs>
        );
      } else {
        return (
          <AdminTabs
            tab="systemMonitor"
            accessLevel={accessLevel}
            accessType={accessType}
          >
            {this.generateTabs()}
          </AdminTabs>
        );
      }
    }
    return null;
  }
}
