// NPM
import React, { Component, Fragment } from 'react';
import { FormControl } from 'react-bootstrap';
// COMPONENTS
import ListItem from '../ListItem';
import Paginator from '../../../../components/Paginator';

// I18N
import formattedMessages from './FormattedMessages';

// CONFIG
import config from '../../../../Config';

class GlobalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      error: null,
      searchValue: '',
    };
    this.pageLength = config.defaultPL;
  }

  /*
    Handles the change event when clicking a pagination number
  */
  changeEvent = (i) => {
    console.log('Paginator change event:', i);
    return this.setState({
      pageNumber: i + 1,
    });
  };

  /*
    Handles changes in the form
  */
  handleSearchChange = async (event) => {
    this.setState({
      searchValue: event.target.value,
      pageNumber: 1,
    });
    this.resetFunction();
  };

  generateSearch = () => {
    return (
      <Fragment>
        <div className="main">
          <h5>{formattedMessages.searchByName}</h5>
          <FormControl
            id="contactSearch"
            autoFocus
            type="text"
            defaultValue=""
            onChange={this.handleSearchChange}
          />
          <br />
          <br />
        </div>
      </Fragment>
    );
  };

  /*
    Handles global template search
  */
  handleSearchFilter = () => {
    let list = this.props.list;
    if (this.state.searchValue) {
      list = this.props.list.filter((contact) => {
        return `${contact.template_name}`
          .toLowerCase()
          .includes(this.state.searchValue.toLowerCase());
      });
    }
    return list;
  };

  generateList = (list) => {
    let fullList = [];
    let { pageNumber } = this.state;
    if (list && typeof list !== 'undefined' && Array.isArray(list)) {
      let displayList = list.slice(
        (pageNumber - 1) * this.pageLength,
        pageNumber * this.pageLength - 1 + 1
      );
      for (let i = 0; i < displayList.length; i++) {
        let element = displayList[i];
        fullList.push(
          <div
            onClick={() => this.props.handleSelect(element)}
            key={element.template_sms_id}
          >
            <ListItem
              key={element.template_sms_id}
              id={element.template_sms_id}
              title={element.template_name}
            />
          </div>
        );
      }
    }
    return fullList;
  };

  render() {
    let list = this.handleSearchFilter();
    let listLen = list ? list.length : 0;
    return (
      <Fragment>
        <div>
          <br />
          <div className="d-inline">
            {this.generateSearch()}
            <h5 className="sub-header">
              {formattedMessages.globalTemplateTitle}: {list ? list.length : 0}
            </h5>
          </div>
          <div className="d-inline floatRight">
            <button
              className="btn add-new-template"
              onClick={this.props.callBack}
            >
              {formattedMessages.createNewButton}
            </button>
          </div>
          <br />
          <br />
          <ul className="wembsms-list">{this.generateList(list)}</ul>
        </div>
        <Paginator
          itemCount={listLen}
          changePageFunction={(ref) => (this.resetFunction = ref)}
          changeEvent={this.changeEvent}
        />
      </Fragment>
    );
  }
}

export default GlobalList;
