/*
  ComposeComponent.js - Generates the WebSMS Compose Component

  Author: Elle Dunbar (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment, createRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

// COMPONENTS
import Templates from '../TemplateList';
import ComposeRequest from './ComposeRequest';
import ErrorText from '../../../components/ErrorText';
import WebSMSTabs from '../index';

// UTIL
import {
  getCharacterCount,
  getMessageParts,
  getMessageMaxLength,
  withinCharacterLimit,
} from '../../../utils/characterCount';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';
import CustomTooltip from '../../../components/CustomTooltip';
import { validatePhoneNumber } from '../../../utils/validate';

// STYLING
import '../Websms.css';

// NETWORK
const composeRequest = new ComposeRequest();

// COMPONENT STYLES
const controlStyles = {
  borderRadius: '1px solid black',
  color: 'black',
  width: '100%',
};

const ControlComponent = (props) => (
  <div style={controlStyles}>
    <p>{formattedMessages.searchContactText}</p>
    <components.Control {...props} />
  </div>
);

const OptionComponent = (props) => {
  let option = props.data.label;
  option = (
    <div className="flex-between">
      <span>{props.data.label}</span>
      <span className="italic-grey ">{props.data.type}</span>
    </div>
  );
  return (
    <div>
      <components.Option {...props}>{option}</components.Option>
    </div>
  );
};

/*
  Creates the Compose React Component
*/
export default class Compose extends Component {
  constructor(props) {
    super(props);

    //localize date/time picker
    moment.locale('en');
    momentLocalizer();
    let dateNow = new Date();

    let customer_data = props.customer;

    this.state = {
      displayTemplatePopup: false,
      noOfSmsSent: 0,
      tempPersonal: null,
      tempGlobal: null,
      template: null,
      messageContent: '',
      contacts: [],
      selectedOption: [],
      countries: [],
      isLoading: false,
      value: null,
      hasError: false,
      errorText: '',
      isCreatingSms: false,
      isSending: false,
      scheduledTime: dateNow,
      allowScheduling: false,
      allowSignature: false,
      retainContent: false,
      creditsTotal: null,
      messagesTotal: null,
      showConfirmation: false,
      user: null,
      countryCode: '',
      customer: customer_data,
    };
    // TODO: replace createRef with useRef if this component gets converted from class component to functional component
    this.messageContentRef = createRef();
  }

  async componentDidMount() {
    if (this.props.customer && this.props.customer !== null) {
      // Handles redirect from inbox page.
      const selectedOption = this.props.location.state?.selectedOption || [];
      this.setState({
        selectedOption: selectedOption,
      });

      // Auto focuses the message content component if successful redirect.
      if (selectedOption.length > 0 && this.messageContentRef.current)
        this.messageContentRef.current.focus();

      let data = await composeRequest.getContacts(this.props.errorHandler);
      let { count, creditCount, messageCount } =
        await composeRequest.getNoSmsSentForUser(this.props.errorHandler);
      let user = await composeRequest.getUser(this.props.errorHandler);
      this.setState({
        user: user.user,
        countryCode: user.countryCode.country_code,
      });

      if (isNaN(count)) count = 0; // error condition

      // Get list of personal contacts
      const personalContacts = data.contacts.personal.map((contact) => {
        return {
          label: `${contact.entry_name || ''}`,
          value: contact.number,
          type: 'Personal Contact',
        };
      });

      // Get list of global contacts
      const globalContacts = data.contacts.global.map((contact) => {
        return {
          label: `${contact.entry_name || ''}`,
          value: contact.number,
          type: 'Global Contact',
        };
      });

      // Get list of personal distribution lists
      const personalDistLists = data.distribution_lists.personal.map(
        (contact) => {
          return {
            label: `${contact.list_name}`,
            value: contact.contacts.map((distListContact) => {
              return distListContact.contact_tn;
            }),
            fullValue: contact.contacts.map((distListContact) => {
              return {
                label: distListContact.contact_name,
                value: distListContact.contact_tn,
              };
            }),
            type: 'Personal Distribution List',
          };
        }
      );

      // Get list of global distribution lists
      const globalDistLists = data.distribution_lists.global.map((contact) => {
        return {
          label: `${contact.list_name}`,
          value: contact.contacts.map((distListContact) => {
            return distListContact.contact_tn;
          }),
          fullValue: contact.contacts.map((distListContact) => {
            return {
              label: distListContact.contact_name,
              value: distListContact.contact_tn,
            };
          }),
          type: 'Global Distribution List',
        };
      });

      // Concatenate contacts together
      const contacts = globalContacts.concat(personalContacts);
      const distLists = globalDistLists.concat(personalDistLists);
      let selectableContacts = contacts.concat(distLists);

      selectableContacts = selectableContacts.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (b.label.toLowerCase() < a.label.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      let customerUserSettings = await composeRequest.getSettings(
        this.props.errorHandler
      );
      let signature = '';
      if (customerUserSettings && customerUserSettings.signature) {
        signature = customerUserSettings.signature;
      }

      return this.setState({
        contacts: selectableContacts,
        noOfSmsSent: count,
        creditsTotal: creditCount,
        messagesTotal: messageCount,
        signature: signature,
        countryCode: user.countryCode.country_code,
      });
    }
  }

  /*
    Convenience function to display Template Modal
  */
  handleModal = () => {
    this.setState({
      displayTemplatePopup: !this.state.displayTemplatePopup,
    });
  };

  changeScheduledDateTime(date) {
    this.setState({
      scheduledTime: date,
    });
  }

  /*
    Convenience function to handle CreateSelectable Component
    onSelect.
  */
  handleChange = (selectedOption) => {
    return this.setState({ selectedOption });
  };

  handleCloseConfirmation = () => {
    this.setState(
      {
        showConfirmation: false,
      },
      async () => {
        if (!this.state.retainContent) {
          this.clearContent();
        } else {
          this.retainContent();
        }
      }
    );
  };

  handleInputBlur = (event) => {
    let inputTn = event.target.value.trim(); // Remove start and end blank

    if (inputTn) {
      this.handleTextEntry(inputTn);
    }
  };

  showConfirmationDialog = () => {
    let { selectedOption } = this.state;

    let allRecipients = selectedOption.map((option) => option.value).flat();
    let noOfRecipients = allRecipients.length;

    return (
      <Modal
        show={this.state.showConfirmation}
        onHide={this.handleCloseConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formattedMessages.messageSend}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {noOfRecipients}/{noOfRecipients} {formattedMessages.messageQueued}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <p>
            <input
              type="checkbox"
              checked={this.state.retainContent}
              value={this.state.retainContent}
              name="retainContent"
              onChange={(e) => this.handleInputChange(e)}
            />
            &nbsp;Retain Message Content
          </p>
          <Button
            className="maxHeight"
            variant="primary"
            onClick={this.handleCloseConfirmation}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  showConfirmationAfterSent = () => {
    this.setState({
      showConfirmation: true,
    });
  };

  clearContent = () => {
    this.setState({
      retainContent: false,
      tempPersonal: null,
      tempGlobal: null,
      template: null,
      showConfirmation: false,
      messageContent: '',
      selectedOption: [],
      allowSignature: false,
      allowScheduling: false,
    });
  };

  retainContent = () => {
    this.setState({
      retainContent: true,
      selectedOption: [],
    });
  };
  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSelect = (element) => {
    this.setState({
      template: element,
      messageContent: element.template_text,
      displayTemplatePopup: false,
    });
  };

  /*
    Creates a composeRequest to the POST SMS data endpoint to create a new SMS
  */
  sendMessage = () => {
    // If no mobile numbers selected
    if (!this.state.selectedOption || this.state.selectedOption.length < 1) {
      return this.setState({
        hasError: true,
        errorText: 'Selected Contact(s) is empty',
        isCreatingSms: false,
        isSending: false,
      });
    } else {
      let invalidNumber = this.getInvalidNumbers(this.state.selectedOption);
      if (invalidNumber) {
        return this.setState({
          hasError: true,
          errorText:
            'Invalid Contact number for- ' +
            invalidNumber.label +
            ': ' +
            invalidNumber.value,
          isCreatingSms: false,
          isSending: false,
        });
      }
    }
    // if no message content has been added...
    if (!this.state.messageContent) {
      return this.setState({
        hasError: true,
        errorText: 'Message Content is empty',
        isCreatingSms: false,
        isSending: false,
      });
    }

    //If the scheduled date and time is in the past
    if (this.state.allowScheduling && this.state.scheduledTime < Date.now()) {
      return this.setState({
        hasError: true,
        errorText: 'Date/Time invalid, please select a future Date/Time.',
        isCreatingSms: false,
        isSending: false,
      });
    }

    this.setState(
      {
        hasError: false,
        isCreatingSms: true,
        isSending: true,
      },
      async () => {
        try {
          // For each selected option in the contacts selector...
          let message = this.state.messageContent;
          if (this.state.allowSignature) {
            message += this.state.signature ? '\n' + this.state.signature : '';
          }

          // Body data
          let data = {
            contacts: this.state.selectedOption,
            message: message,
          };

          if (this.state.allowScheduling) {
            data.scheduled_datetime = this.state.scheduledTime;
          }

          // Make request
          await composeRequest.createSMS(data, this.props.errorHandler);

          // Update this component and then request to update the User as well
          this.setState(
            {
              hasError: false,
              isCreatingSms: false,
              isSending: false,
            },
            () => this.props.refreshUser()
          );
          this.showConfirmationAfterSent();
        } catch (e) {
          return this.setState({
            hasError: true,
            errorText: e.message,
            isCreatingSms: false,
            isSending: false,
          });
        }
      }
    );
  };

  /**
   * validate recipients numbers formats
   * @param {objects} numbers of number objects
   * @returns {object} number returns the first invalid number object if exists.
   */
  getInvalidNumbers = (contact) => {
    for (let i = 0; i < contact.length; i++) {
      let number = contact[i].value;
      if (Array.isArray(number)) {
        // handle distribution lists
        for (let j = 0; j < number.length; j++) {
          if (!validatePhoneNumber(number[j])) {
            return {
              label: contact[i].label,
              value: number[j],
            };
          }
        }
      } else if (!validatePhoneNumber(number)) {
        return {
          label: contact[i].label,
          value: contact[i].value,
        };
      }
    }
    return null;
  };

  /*
    Adds a straight text mobile number option to list of dest_tns
  */
  handleTextEntry = (inputTn) => {
    let { contacts } = this.state;
    let selectedTn = this.state.selectedOption;

    let inputTn_firstFew = inputTn.slice(0, inputTn.length);
    let removeSelectedOption = contacts.filter(
      (item) => !selectedTn.includes(item)
    ); // Remove selected option from Contacts list
    let foundContacts = removeSelectedOption.find(
      (item) =>
        item.label.slice(0, inputTn.length).toLowerCase() ===
        inputTn_firstFew.toLowerCase()
    ); // Check if entered name is already in the Conatcts List

    // If input value exists, push the first item found
    if (foundContacts) {
      selectedTn.push(foundContacts);
      return this.setState({ selectedOption: selectedTn });
    } else {
      // Else, create new
      if (inputTn.includes(',')) {
        let splitTns = inputTn.split(',');

        for (let i = 0; i < splitTns.length; i++) {
          selectedTn.push({
            label: splitTns[i],
            value: splitTns[i],
          });
        }
        return this.setState({
          selectedOption: selectedTn,
        });
      }

      selectedTn.push({
        label: inputTn,
        value: inputTn,
      });
      return this.setState({
        selectedOption: selectedTn,
      });
    }
  };

  /*
    Generates buttons for the form
  */
  generateButtonsAndErrors = (
    creditsCost,
    messageCost,
    messageWithinLength
  ) => {
    let { messagesTotal, isSending, creditsTotal } = this.state;

    // Returns no button but just text 'Sending...' if currently sending
    if (isSending) return <p>Sending...</p>;

    let messageDifference = messagesTotal - messageCost;
    let creditsDifference = creditsTotal - creditsCost;

    let errorTextMessage;
    let errorTextComponent = <br></br>;
    let disabledSending = false;

    // If an error has been found and set to state, display below. These errors were not disabling sends before, so i've kept it that way.
    if (this.state.hasError) {
      errorTextMessage = this.state.errorText;
    }
    // If a pre-paid account and the cost of the send would go below the customer's current credits.
    else if (creditsTotal !== 'N/A' && creditsTotal < creditsCost) {
      disabledSending = true;
      errorTextMessage =
        'You have reached your message credit limit and, therefore, this message will not be sent. Please purchase more credits via the Web Administrator.';
    }
    // Else if the user's messages remaining for the day is less than the amount of messages they are attempting to send.
    else if (messagesTotal < messageCost) {
      disabledSending = true;
      errorTextMessage =
        'You have reached your daily message limit and, therefore, this message will not be sent. Please contact an Administrator to increase your daily messages.';
    }
    // Else if the message is not within the length limits.
    else if (!messageWithinLength) {
      disabledSending = true;
      errorTextMessage = `You’ve reached the message character count limit, this message cannot be sent.`;
    }
    // Else if there are 5 credits left, give a warning
    else if (creditsTotal && creditsTotal !== 'N/A' && creditsDifference <= 5) {
      errorTextMessage =
        'Warning: Your account has five credits or fewer remaining.';
    }
    // Else if there are 5 messages left, give a warning
    else if (messagesTotal && messageDifference <= 5) {
      errorTextMessage =
        'Warning: Your account has five messages or fewer remaining.';
    }

    // If an error (or warning) to display, display it
    if (errorTextMessage)
      errorTextComponent = (
        <ErrorText hasError={true} errorText={errorTextMessage} />
      );

    return (
      <div>
        {errorTextComponent}
        <Button
          className="maxHeight"
          onClick={this.sendMessage}
          disabled={disabledSending}
        >
          {formattedMessages.sendButton}
        </Button>
      </div>
    );
  };

  /*
    Resets the page's info when the tab is clicked.
  */
  handlePageReset = () => {
    this.setState({
      messageContent: '',
      selectedOption: [],
      allowScheduling: false,
      allowSignature: false,
    });
  };

  /*
    Calculate International and Local Credit Costs
  */
  getRecipientsLocation = () => {
    let { selectedOption, countryCode } = this.state;

    let allRecipients = selectedOption.map((option) => option.value).flat();

    let firstFew = allRecipients.map((w) => w.slice(0, countryCode.length + 1)); // Split first few number based on local country code

    // A send locally costs 1 credit
    // A send internationally costs 2 credits
    let internationalRecipientsTotal = firstFew.filter(
      (x) => x !== '+' + countryCode && x[0] === '+'
    ).length;
    let localRecipientsTotal =
      allRecipients.length - internationalRecipientsTotal;

    return {
      localRecipients: localRecipientsTotal,
      internationalRecipients: internationalRecipientsTotal,
      allRecipients: allRecipients,
    };
  };

  /*
    Calculate Message Credits Cost
  */
  calculateCreditsAndRecipients = (msgValue) => {
    let { localRecipients, internationalRecipients, allRecipients } =
      this.getRecipientsLocation();
    let messagePartCount = getMessageParts(msgValue);

    let creditsCost =
      messagePartCount * (localRecipients + internationalRecipients * 2);

    return {
      messagePartCount: messagePartCount,
      creditsCost: creditsCost,
      localRecipients: localRecipients,
      internationalRecipients: internationalRecipients,
      allRecipients: allRecipients,
    };
  };

  render() {
    let {
      messageContent,
      allowSignature,
      signature,
      creditsTotal,
      messagesTotal,
      isLoading,
      tempPersonal,
      tempGlobal,
      allowScheduling,
      scheduledTime,
      selectedOption,
      contacts,
      displayTemplatePopup,
    } = this.state;

    let messageWithSignature =
      messageContent + (allowSignature ? ' ' + signature : '');

    // Counts character for message (and signature if 'allowSignature' is true)
    let {
      standardCharacterOnly,
      unicodeCharacterOnly,
      characterCount,
      isUnicodeMessage,
    } = getCharacterCount(messageWithSignature);

    let {
      messagePartCount,
      creditsCost,
      localRecipients,
      internationalRecipients,
      allRecipients,
    } = this.calculateCreditsAndRecipients(messageWithSignature);

    let messageWithinLength = withinCharacterLimit(
      messageWithSignature,
      isUnicodeMessage
    );
    let messageMaxLength = getMessageMaxLength(isUnicodeMessage);

    let messageCount = allRecipients ? allRecipients.length : 0;

    // Formats the credits total and message total into strings if they weren't already.
    if (!creditsTotal) creditsTotal = 'Loading...';
    else if (typeof creditsTotal === 'number')
      creditsTotal = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 1,
      }).format(creditsTotal);

    if (!messagesTotal) messagesTotal = 'Loading...';
    else if (typeof messageTotal === 'number')
      messagesTotal = new Intl.NumberFormat('en-US').format(messagesTotal);

    return (
      <Fragment>
        <WebSMSTabs
          tab="compose"
          mobileMenuOpen={this.props.mobileMenuOpen}
          navigate={this.props.navigate}
          toggleMobileMenu={this.props.toggleMobileMenu}
          handlePageReset={this.handlePageReset}
        >
          <div className="Home">
            <div className="lander">
              <h3>{formattedMessages.composeTitle}</h3>
              <br />
              <h5>{formattedMessages.recipientsTitle}</h5>
              <div style={{ width: '80%' }}>
                <CreatableSelect
                  isClearable
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  onChange={this.handleChange}
                  onCreateOption={this.handleTextEntry}
                  onBlur={this.handleInputBlur}
                  isMulti={true}
                  isSearchable={true}
                  openOnFocus={true}
                  autoFocus={true}
                  components={{
                    Control: ControlComponent,
                    Option: OptionComponent,
                  }}
                  value={selectedOption}
                  options={contacts}
                />
              </div>

              <br />
              <br />
              <Form.Group controlId="textarea">
                <Form.Label>
                  <h5>{formattedMessages.messageTitle}</h5>
                  <p>
                    {formattedMessages.messageText}
                    <Button
                      className="maxHeight floatRight"
                      onClick={this.handleModal}
                    >
                      {formattedMessages.templateButton}
                    </Button>
                  </p>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={messageContent || ''}
                  name="messageContent"
                  onChange={(e) => this.handleInputChange(e)}
                  ref={this.messageContentRef}
                  maxLength={(
                    messageMaxLength -
                    (allowSignature && signature ? signature.length + 1 : 0)
                  ).toString()}
                />
                <p>
                  <input
                    type="checkbox"
                    checked={allowSignature}
                    value={allowSignature}
                    name="allowSignature"
                    onChange={(e) => this.handleInputChange(e)}
                  />{' '}
                  Signature: {signature ? signature : ''}
                </p>
                <p className="remindText">
                  {/* &nbsp; = space, &lowast; = multiplication sign */}
                  <span className={!messageWithinLength ? 'red bold' : ' bold'}>
                    {characterCount}/{messageMaxLength} Character(s)
                  </span>{' '}
                  =&nbsp;
                  {standardCharacterOnly} Standard Character(s)
                  {unicodeCharacterOnly > 0 &&
                    ' + ' + unicodeCharacterOnly + ' Unicode Character(s)'}
                  <CustomTooltip
                    tooltipText={
                      '1 message = 160 characters or less (including spaces and signature character count).\n\n' +
                      'If 160 characters is exceeded, the message will be broken into parts, consisting of 153 characters.\n\n' +
                      'We can support max. of 765(plain)/335(unicode) characters = 5 parts.\n\n' +
                      '1 unicode SMS = 70 unicode characters, any message with 1 or more unicode characters is considered a unicode message and will be counted as such. Emojis are counted as unicode characters'
                    }
                    margin="5px"
                    className="tooltip-standard-unicode"
                  />
                  <br />
                  <span className="bold">
                    {creditsCost} Credit(s) Cost
                  </span>{' '}
                  =&nbsp;
                  {messagePartCount} Message Parts &lowast; ({localRecipients}{' '}
                  Local Number{localRecipients === 1 ? '' : 's'} +&nbsp;
                  {internationalRecipients} International Number
                  {internationalRecipients === 1 ? '' : 's'})
                  {
                    <CustomTooltip
                      tooltipText={
                        'Counting credits:\n' +
                        'For local messages (within your country code)\n' +
                        '1 part message = 1 credit\n' +
                        '2 part message = 2 credits\n' +
                        '3 part message = 3 credits and so on\n' +
                        'International messages are 2 credits per part'
                      }
                      margin="5px"
                      className="tooltip-credits-count"
                    />
                  }
                  <br />
                  <span className="bold">{messageCount} Message(s)</span>
                  <br />
                  <br />
                </p>
              </Form.Group>
              <div className="creditComponent">
                <div className="CCouter">
                  <div className="CCinner">{creditsTotal}</div>
                  <div className="CCinner">
                    {formattedMessages.creditsRemaining}
                    <CustomTooltip
                      tooltipText="Credit is the cost of a message. 
                    The destination (country) and the length of the message both impact the credit cost of a message."
                      margin="5px"
                    />
                  </div>
                </div>
                <div className="CCouter">
                  <div className="CCinner">{messagesTotal}</div>
                  <div className="CCinner">
                    {formattedMessages.messageRemaining}
                    {
                      <CustomTooltip
                        tooltipText="A message is any one SMS sent to any phone number. 
                    The length of the message does not matter."
                        margin="5px"
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div>
              <input
                type="checkbox"
                checked={allowScheduling}
                value={allowScheduling}
                name="allowScheduling"
                onChange={(e) => this.handleInputChange(e)}
              />{' '}
              {formattedMessages.laterDateText} <br />
              <DateTimePicker
                format="DD/MMM/YYYY h:mm A"
                initialValue={!allowScheduling ? null : scheduledTime}
                value={!allowScheduling ? null : scheduledTime}
                onChange={(value) => this.changeScheduledDateTime(value)}
                // max={new Date()}
                min={new Date()}
                disabled={!allowScheduling}
              />
              <br />
              {/*<input type='checkbox' /> {formattedMessages.forwardRepliesText} <br />*/}
              {this.generateButtonsAndErrors(
                creditsCost,
                messageCount,
                messageWithinLength
              )}
            </div>
          </div>
        </WebSMSTabs>
        <Modal show={displayTemplatePopup} onHide={this.handleModal}>
          <Modal.Header closeButton>
            <Modal.Title>{formattedMessages.chooseTemplateText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Templates
              loading={this.state.isLoading}
              dateFormats={this.props.dateFormats}
              tempPersonal={tempPersonal}
              tempGlobal={tempGlobal}
              selectOnly
              handleSelect={this.handleSelect.bind(this)}
              hideLeftMenu={true}
              customer={this.props.customer}
            />
          </Modal.Body>
        </Modal>
        {this.showConfirmationDialog()}
      </Fragment>
    );
  }
}
