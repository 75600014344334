import React from 'react';

const ListItem = ({
  id,
  onClick,
  name = '',
  department = '',
  accessLevel = '',
  contactType = '',
}) => (
  <tr key={id} id={id} className="table-click" onClick={onClick}>
    <td className="medium textLeft">{name}</td>
    <td className="medium textLeft">{department}</td>
    <td className="medium textLeft">{accessLevel}</td>
    <td className="medium textLeft">{contactType}</td>
    <td className="narrow">
      <i id={id + 'icon'} className="material-icons floatRight">
        keyboard_arrow_right
      </i>
    </td>
  </tr>
);

export default ListItem;
