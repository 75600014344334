/*
  InvoiceShow.js - Invoice Detailed View Component

  Author: Elle Dunbar (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Fragment, useState, useEffect } from 'react';
import {
  Modal,
  Container,
  Col,
  Row,
  Form,
  Dropdown,
  Button,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// COMPONENTS
import SingleInvoice from './SingleInvoice';
import UserUsage from './UserUsage';
import FullUsage from './FullUsage';
import InvoiceDetails from './InvoiceDetails';
import StagedModal from '../../../components/StagedModal';

// STYLING
import formattedMessages from './FormattedMessages';
import '../Billing.css';

// NETWORKING
import InvoiceRequest from '../Invoices/InvoiceRequest';
const invoiceRequests = new InvoiceRequest();

const InvoiceShow = (props) => {
  console.log(props);

  const { customer, tab } = useParams();

  let dataLoaded = false;
  const [stage, setStage] = useState(0);
  const [showStaged, setShowStaged] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalParameters, setModalParams] = useState({});
  const [printCheck, setPrintCheck] = useState(false);

  const [details, setDetails] = useState('1');
  const [invoice, setInvoice] = useState(null); // Single Invoice object
  //const [invoiceFormatted, setInvoiceFormatted] = useState(null); // Formatted Single Invoice object
  const [XeroInfo, setXeroInfo] = useState(null);
  const [fullUsageData, setFullUsageData] = useState(null); // Full Usage View
  const [userUsageData, setUserUsageData] = useState(null); // User Usage View
  const [invoiceDetailsData, setInvoiceDetailsData] = useState(null); // Invoice Details View

  //const [loadingSingleInvoice, setLoadingSingleInvoice] = useState(true); // Is Loading Single Invoice Data
  //const [loadingInvoiceDetails, setLoadingInvoiceDetails] = useState(true); // Is Loading Invoice Details
  const [loadingUser, setLoadingUser] = useState(true); // Is Loading User Usage Details
  const [loadingFull, setLoadingFull] = useState(true); // Is Loading Full Details

  // useEffect hook for initial mounting.
  useEffect(() => {
    async function getData() {
      // Have params in url
      if (customer && tab) {
        let singleInvoiceData = await invoiceRequests.getSingleInvoice(
          customer,
          tab,
          props.errorHandler
        );

        const urlParams = new URLSearchParams(window.location.search);

        setPrintCheck(urlParams.get('print'));
        setInvoice(singleInvoiceData);
        setLoadingUser(false);
        setLoadingFull(false);
      }
    }

    getData();
  }, [customer, tab, props.errorHandler]);

  // useEffect hook for when updating printing variables.
  useEffect(() => {
    if (printCheck && dataLoaded && !loadingUser && !loadingFull) {
      setPrintCheck(false);
      window.setTimeout(printAfterRender, 1000);
    }
  }, [setPrintCheck, printCheck, dataLoaded, loadingUser, loadingFull]);

  // Invoice Details Function to split loading of data
  const getInvoiceDetails = async () => {
    let invoiceDetailsData = await invoiceRequests.getInvoiceDetails(
      customer,
      tab,
      props.errorHandler
    );
    setInvoiceDetailsData(invoiceDetailsData);
  };

  // User Usage Function to split loading of data
  const getUserUsageDetails = async () => {
    let userInvoiceData = await invoiceRequests.getUserInvoiceDetails(
      customer,
      tab,
      props.errorHandler
    );
    setUserUsageData(userInvoiceData);
  };

  // Full Usage Function to split loading of data
  const getFullUsageDetails = async () => {
    let detailedInvoiceData = await invoiceRequests.getFullInvoiceDetails(
      customer,
      tab,
      props.errorHandler
    );
    setFullUsageData(detailedInvoiceData);
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'details') setDetails(value);
  };

  const performAction = async (customerId, providerId, billId) => {
    console.log('performAction');
    switch (modalParameters.actionType) {
      case 'processPendingInvoice':
        console.log('processPendingInvoice');
        await processPendingInvoice(billId, customerId);
        break;
      case 'transitionPendingToInvoiced':
        console.log('transitionPendingToInvoiced');
        await transitionPendingToInvoiced(customerId, providerId, billId);
        break;
      case 'transitionBillToFailed':
        console.log('transitionBillToFailed');
        await transitionBillToFailed(customerId, providerId, billId);
        break;
      case 'cancelPendingTransaction':
        console.log('cancelPendingTransaction');
        await cancelPendingTransaction(customerId, providerId, billId);
        break;
      case 'voidXeroInvoice':
        console.log('voidXeroInvoice');
        await voidXeroInvoice(customerId, providerId, billId);
        break;
      default:
        console.error('Invoice attempted to perform an unknown action');
        break;
    }
  };

  const processPendingInvoice = async (billId, customerId) => {
    if (!showStaged) {
      setStage(0);
      setShowStaged(true);
      setModalParams({
        actionType: 'processPendingInvoice',
        billId,
        customerId,
        stageTitle: 'Invoice Bill',
        stageConfirmation:
          'Are you sure you would like to process payment on this invoice?',
        stagePending: 'Processing transaction...',
        stageSuccess: 'Invoice paid successfully!',
        stageFailure: 'Invoice payment failed!',
      });
    } else if (stage === 0) {
      setStage(1);
      let results = await invoiceRequests.processInvoiceTransaction(
        modalParameters?.customerId,
        modalParameters?.billId,
        props.errorHandler
      );
      if (results.message === 'Successful Payment') {
        setStage(2);
        setInvoice({
          ...invoice,
          billingStatus: 'Paid',
          successfulPayment: results.successfulPayment,
        });
      } else {
        setStage(3);
        if (results.failedPayments)
          setInvoice({ ...invoice, failedPayments: results.failedPayments });
        setModalParams({
          stageTitle: 'Invoice Bill',
          errorMsg: results.message,
          stageFailure: 'Invoice payment failed!',
        });
      }
      console.log(results);
    }
  };

  const transitionBillToFailed = async (billId, customerId, providerId) => {
    if (!showStaged) {
      setStage(0);
      setShowStaged(true);
      setModalParams({
        actionType: 'transitionBillToFailed',
        billId,
        customerId,
        providerId,
        stageTitle: 'Fail Transaction',
        stageConfirmation: 'Are you sure you want to fail this invoice?',
        stagePending: 'Failing Invoice...',
        stageSuccess: 'Invoice successfully transitioned to "Failed"!',
        stageFailure: 'Invoice failed to transition to "Failed"!',
      });
    } else if (stage === 0) {
      setStage(1);
      let result = await invoiceRequests.updateStatus(
        modalParameters?.providerId,
        modalParameters?.customerId,
        modalParameters?.billId,
        'F', //Failed
        props.errorHandler
      );
      console.log('got results updating invoice:', result);

      if (result.status === 'Failed') {
        setStage(2);
        if (result.status)
          setInvoice({ ...invoice, billingStatus: result.status });
      } else setStage(3);
    }
  };

  const cancelPendingTransaction = async (billId, customerId, providerId) => {
    if (!showStaged) {
      setStage(0);
      setShowStaged(true);
      setModalParams({
        actionType: 'cancelPendingTransaction',
        billId,
        customerId,
        providerId,
        stageTitle: 'Cancel Transaction',
        stageConfirmation: 'Are you sure you want to cancel this invoice?',
        stagePending: 'Canceling Invoice...',
        stageSuccess: 'Invoice Cancelled successfully!',
        stageFailure: 'Invoice failed to be cancelled!',
      });
    } else if (stage === 0) {
      setStage(1);
      let result = await invoiceRequests.updateStatus(
        modalParameters?.providerId,
        modalParameters?.customerId,
        modalParameters?.billId,
        'C', //Cancelled
        props.errorHandler
      );
      console.log('got results updating invoice:', result);

      if (result.status === 'Cancelled') {
        setStage(2);
        if (result.status)
          setInvoice({ ...invoice, billingStatus: result.status });
      } else setStage(3);
    }
  };

  const checkXeroInvoice = async (invoiceNo) => {
    setXeroInfo(null);
    setShowModal(true);
    let result = await invoiceRequests.getXeroInformation(
      invoiceNo,
      props.errorHandler
    );
    console.log('Xero Info', result);
    if (!result || result.error) setXeroInfo('missing');
    else setXeroInfo(result);
  };

  const xeroInformationModal = () => {
    let message = 'Loading...';
    if (XeroInfo && XeroInfo !== 'missing') {
      message = JSON.stringify(XeroInfo, null, 4);
      message = message.replace(/(\\r\\n)/g, `\n\t`);
    } else if (XeroInfo === 'missing')
      message =
        'There was no invoice or credit note found that corresponds with this invoice.';

    const { billingStatus } = invoice;
    // Conditions to enable the void button. To be able to void, must be a cancelled or failed bill
    // that exists in xero but isn't already voided.
    const voidButtonEnabled =
      (billingStatus !== 'Cancelled' && billingStatus !== 'Failed') ||
      !XeroInfo ||
      XeroInfo === 'missing' ||
      XeroInfo?.status?.toLowerCase() === 'voided';

    return (
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Xero Invoice Information</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-xero">{message}</Modal.Body>
        <Modal.Footer>
          <Button
            className="maxHeight btn btn-primary"
            disabled={voidButtonEnabled}
            variant="secondary"
            onClick={voidXeroInvoice}
          >
            Void Xero Invoice
          </Button>
          <Button
            className="maxHeight btn btn-primary"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const voidXeroInvoice = async () => {
    setShowModal(false);
    if (!showStaged) {
      setStage(0);
      setShowStaged(true);
      setModalParams({
        actionType: 'voidXeroInvoice',
        stageTitle: 'Void Xero Invoice',
        stageConfirmation: 'Are you sure you want to void this invoice?',
        stagePending: 'Voiding Invoice...',
        stageSuccess: 'Invoice Voided successfully!',
        stageFailure: 'Invoice failed to be voided!',
      });
    } else if (stage === 0) {
      setStage(1);
      //TODO: call the backend to void the Xero Invoice
      let result = await invoiceRequests.voidXeroInvoice(
        tab,
        props.errorHandler
      );
      console.log('void result', result);

      if (!result?.error) {
        setStage(2);
      } else setStage(3);
    }
  };

  const transitionPendingToInvoiced = async (
    billId,
    customerId,
    providerId
  ) => {
    if (!showStaged) {
      setStage(0);
      setShowStaged(true);
      setModalParams({
        actionType: 'transitionPendingToInvoiced',
        billId,
        customerId,
        providerId,
        stageTitle: 'Invoice Bill',
        stageConfirmation:
          'Are you sure you would like convert this pending bill to invoiced?',
        stagePending: 'invoicing bill...',
        stageSuccess: 'Invoice successfully transitioned to "Invoiced"!',
        stageFailure: 'Invoice failed to transition to "Invoiced"!',
      });
    } else if (stage === 0) {
      setStage(1);
      let result = await invoiceRequests.updateStatus(
        modalParameters?.providerId,
        modalParameters?.customerId,
        modalParameters?.billId,
        'I', //Invoiced
        props.errorHandler
      );
      console.log('got results updating invoice:', result);

      if (result.status === 'Invoiced') {
        setStage(2);
        if (result.status)
          setInvoice({ ...invoice, billingStatus: result.status });
      } else setStage(3);
    }
  };

  /** Sends user back to the billing page */
  const backToBilling = () => {
    props.navigate('/billing/invoices/');
  };

  const getDisplayedContent = () => {
    // Get current selected state
    console.log('Viewing invoice show page:', details);

    switch (details) {
      case '1':
        return (
          <SingleInvoice
            errorHandler={props.errorHandler}
            invoice={invoice}
            backToBilling={backToBilling}
          />
        );
      case '2':
        return (
          <div>
            <UserUsage
              errorHandler={props.errorHandler}
              userUsageData={userUsageData}
              getDataFunction={getUserUsageDetails}
              billDates={invoice.bill_dates}
            />
          </div>
        );
      case '3':
        return (
          <div>
            <FullUsage
              errorHandler={props.errorHandler}
              fullUsageData={fullUsageData}
              getDataFunction={getFullUsageDetails}
              billDates={invoice.bill_dates}
            />
          </div>
        );
      case '4':
        return (
          <div>
            <InvoiceDetails
              errorHandler={props.errorHandler}
              invoice={invoice}
              invoiceDetailsData={invoiceDetailsData}
              startDate={invoice.bill_dates.start_date}
              endDate={invoice.bill_dates.end_date}
              getDataFunction={getInvoiceDetails}
            />
          </div>
        );
      default:
        return <div>page selection error</div>;
    }
  };

  const printAfterRender = () => {
    window.print();
  };

  /*
    Generates a Loading Fragment in JSX
  */
  const generateLoadingFragment = () => {
    return (
      <Fragment>
        <p>{formattedMessages.loading}</p>
      </Fragment>
    );
  };

  const generateInvoiceDropdown = () => {
    let dropdown = <i>&nbsp; Loading...</i>;
    const { accessLevel, accessType } = props;
    const isGodAdmin = accessLevel === '3' && accessType === '3';
    if (invoice) {
      const { billingStatus, providerId, customerType } = invoice;
      let cancelEntry;
      if (customerType === 'B')
        cancelEntry = (
          <Dropdown.Item
            href="#"
            onClick={() => cancelPendingTransaction(tab, customer, providerId)}
          >
            {formattedMessages.cancelTransaction}
          </Dropdown.Item>
        );
      if (billingStatus === 'Pending') {
        dropdown = (
          <Dropdown style={{ marginLeft: '15px' }}>
            <Dropdown.Toggle variant={'success'} id="dropdown-basic">
              {invoice.billingStatus}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                href="#"
                onClick={() => processPendingInvoice(tab, customer)}
              >
                {formattedMessages.processTransaction}
              </Dropdown.Item>
              {cancelEntry}
              {isGodAdmin && (
                <Fragment>
                  <Dropdown.Item
                    href="#"
                    onClick={() =>
                      transitionPendingToInvoiced(tab, customer, providerId)
                    }
                  >
                    {formattedMessages.transitionToInvoiced}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() =>
                      transitionBillToFailed(tab, customer, providerId)
                    }
                  >
                    {formattedMessages.failTransaction}
                  </Dropdown.Item>
                </Fragment>
              )}
            </Dropdown.Menu>
          </Dropdown>
        );
      } else if (billingStatus === 'Invoiced' && isGodAdmin) {
        dropdown = (
          <Dropdown style={{ marginLeft: '15px' }}>
            <Dropdown.Toggle variant={'success'} id="dropdown-basic">
              {invoice.billingStatus}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                href="#"
                onClick={() =>
                  transitionBillToFailed(tab, customer, providerId)
                }
              >
                {formattedMessages.failTransaction}
              </Dropdown.Item>
              {cancelEntry}
            </Dropdown.Menu>
          </Dropdown>
        );
      } else {
        dropdown = <i>&nbsp; {billingStatus}</i>;
      }
    }

    return (
      <div>
        <div className="flex align-item-centre">
          <p className="no-margin">
            {formattedMessages.selectLevelDetailsDisplay}:
          </p>
          <Form.Control
            onChange={(e) => handleInputChange(e)}
            as="select"
            id="details"
            name="details"
            className="tableSearch"
            value={details}
            style={{ marginLeft: '15px' }}
          >
            <option defaultValue value="1">
              Show Invoice
            </option>
            <option value="4">Invoice Details</option>
            <option value="2">User Usage Per Day</option>
            <option value="3">Full Usage Details</option>
          </Form.Control>

          <p style={{ marginLeft: '15px' }} className="no-margin">
            Bill Status:
          </p>
          {dropdown}
          {generateXeroButton()}
        </div>
      </div>
    );
  };

  const generateXeroButton = () => {
    console.log('invoice', invoice);
    const { accessLevel, accessType } = props;
    if (accessLevel === '3' && accessType === '3') {
      return (
        <Button
          className="btn-green flexRight"
          onClick={() => checkXeroInvoice(invoice.invoice_no)}
        >
          Check Xero Invoice
        </Button>
      );
    }
  };

  /**
   * Generates back button to go back to the Billing page.
   * @returns button component (as a p tag)
   */
  const generateBackButton = () => {
    return (
      <p onClick={backToBilling} className="backLink">
        <i className="material-icons">keyboard_arrow_left</i>
        <span>Back</span>
      </p>
    );
  };

  // If still loading User or Full Data
  if (loadingUser || loadingFull) {
    return generateLoadingFragment();
  }

  dataLoaded = true;
  return (
    <Container className="y-scroll">
      <Row>
        <Col sm={12}>
          <div className="pad-medium">
            <div className="pad-medium">
              {generateBackButton()}
              {generateInvoiceDropdown()}
              <br />
              <hr />
              <br />
              {getDisplayedContent()}
            </div>
            <div></div>
          </div>
        </Col>
      </Row>
      {xeroInformationModal()}
      <StagedModal
        showStaged={showStaged}
        removeStaged={() => {
          setShowStaged(false), setModalParams({});
        }}
        removeStagedSlow={() => setStage(0)}
        stage={stage}
        performAction={performAction}
        stageTitle={modalParameters?.stageTitle}
        stageConfirmation={modalParameters?.stageConfirmation}
        stagePending={modalParameters?.stagePending}
        stageSuccess={modalParameters?.stageSuccess}
        stageFailure={
          modalParameters?.stageFailure +
          (modalParameters?.errorMsg ? ': ' + modalParameters.errorMsg : '')
        }
      />
    </Container>
  );
};

export default InvoiceShow;
