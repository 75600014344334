import React from 'react';
// import { FormattedMessage } from 'react-intl';

// let currency = (
//   <FormattedMessage
//     id='global.currency'
//     defaultMessage='$'
//   ></FormattedMessage>
// );
const InlineListItem = ({
  // key,
  user_name,
  total_messages,
  ...props
}) => (
  <tr>
    <td className="left"></td>
    <td>{user_name}</td>
    <td colSpan={7}></td>
    <td>{total_messages}</td>
  </tr>
);

export default InlineListItem;
