/*
  List.js - Admin Tariff Scheme List

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Table, Button } from 'react-bootstrap';
// import {Form, Table, Button, Col, Row} from 'react-bootstrap';

// COMPONENTS
import ListItem from './ListItem.js';

export default class TariffPlanList extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - tariffPlans: Array of Tariff Plans
        - addNewTariffPlan: Function to handle Add New Button click
        - showSingleTariffPlan: Function to handle viewing a single Tariff Plan
        - errorHandler: A function to pass errors to
    */

    this.state = {
      tariffPlans: [],
    };
  }

  /*
    Generates a Table for Tariff Schemes
  */
  generateTable() {
    let htmlRows = [];
    let { tariffPlans, isLoading } = this.props;
    if (isLoading) {
      return <span>Loading...</span>;
    }

    for (let i = 0; i < tariffPlans.length; i++) {
      htmlRows.push(
        <ListItem
          key={tariffPlans[i].tariff_plan_id}
          id={tariffPlans[i].tariff_plan_id}
          planName={tariffPlans[i].tariff_plan_name}
          monthlyLicCom={tariffPlans[i].commitment_recur}
          monthlyUsageCom={tariffPlans[i].commitment_usage}
          includedMessages={tariffPlans[i].included_messages}
          maxUsers={tariffPlans[i].max_users}
          plan={tariffPlans[i]}
          onClickName={(e) => this.props.showSingleTariffPlan(e)}
        ></ListItem>
      );
    }

    return (
      <Table>
        <thead>
          <tr>
            <td className="medium left-wrap tableTop column-left">
              Tariff Plan Name
            </td>
            <td className="medium left-wrap tableTop column-left">
              Monthly Licencing Commitment
            </td>
            <td className="medium left-wrap tableTop column-left">
              Monthly Usage Commitment
            </td>
            <td className="medium left-wrap tableTop column-left">
              Included Messages
            </td>
            <td className="medium left-wrap tableTop column-left column-right">
              Included Numbers
            </td>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  }

  render() {
    return (
      <div key="TariffPlanList" className="table">
        <h4>List of Tariff Plans</h4>
        <Button
          className="btn add-new-template"
          onClick={this.props.addNewTariffPlan}
        >
          Add New
        </Button>
        <br />
        <br />
        {this.generateTable()}
      </div>
    );
  }
}
