/*
  SingleView.js - Admin Country Single View Main Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Form, Table, Button, Col, Row } from 'react-bootstrap';

// COMPONENTS
import PrefixMapSingleView from './PrefixMap/SingleView';
import CarrierSingleView from './Carrier/SingleView';
import CarrierDeviceSingleView from './Carrier/Device/Single';

import PrefixMapListItem from './PrefixMap/ListItem';
import CarrierListItem from './Carrier/ListItem';

import LanguagePicker from '../../../../components/LanguagePicker';

// I18N
//import formattedMessages from './FormattedMessages';

// STYLES
//import '../Admin.css';

// NETWORK
import CountryRequest from './CountryRequest';
import formattedMessages from '../../Provider/FormattedMessages';

// INSTANTIATION
const countryRequest = new CountryRequest();

export default class ServiceCountrySingleView extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - country: A single Country object
        - backButtonClick: Function to handle Back Button click
        - errorHandler: A function to pass errors to
    */

    console.log('Have Single Country props:', this.props);

    this.state = {
      carriers: null,
      prefixMaps: null,
      isEditing: false,
      edits: {},
      selectedPrefixMap: null,
      selectedCarrier: null,
      selectedCarrierDevice: null,
      addNewCarrier: false,
      addNewPrefixMap: false,
    };
  }

  async componentDidMount() {
    if (this.props.country) {
      let countryCodeId = this.props.country.country_code_id;
      let carriers = await countryRequest.getCarriersByCountry(
        countryCodeId,
        this.props.errorHandler
      );
      let prefixMaps = await countryRequest.getPrefixMapsByCountry(
        countryCodeId,
        this.props.errorHandler
      );
      return this.setState({
        carriers: carriers,
        prefixMaps: prefixMaps,
      });
    }
  }

  /*
    Handles Edit Mode on/off
  */
  toggleEdit = () => {
    return this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  backButtonClick = () => {
    return this.setState({
      selectedPrefixMap: null,
      selectedCarrier: null,
      selectedCarrierDevice: null,
      addNewCarrier: false,
      addNewPrefixMap: false,
    });
  };

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;
    edits[name] = value;

    console.log('Edits is:', edits);

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    try {
      console.log('Handle submit called');
      e.preventDefault();
      let updateData = this.state.edits;

      await countryRequest.updateCountryById(
        this.props.country.country_code_id,
        updateData,
        this.props.errorHandler
      );
      window.location.href = '/admin/serviceconfig-country'; // Force reload - TODO: redo properly
    } catch (e) {
      console.log('Got Country update err:', e);
    }
  };

  /*
    Handles when a Prefix Map link is clicked in the table.
  */
  showSinglePrefixMap = async (e) => {
    return this.setState({
      selectedPrefixMap: e,
    });
  };

  /*
    Handles when a Carrier link is clicked in the table.
  */
  showSingleCarrier = async (e) => {
    return this.setState({
      selectedCarrier: e,
    });
  };

  /*
    Handles when add new Prefix Map is clicked
  */
  addNewPrefixMap = async () => {
    return this.setState({
      addNewPrefixMap: true,
    });
  };

  /*
    Handles when add new Carrier is clicked
  */
  addNewCarrier = async () => {
    return this.setState({
      addNewCarrier: true,
    });
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    let title = 'Country Code Information';

    if (this.state.addNewPrefixMap) {
      title = 'Add New Prefix Map';
    } else if (this.state.addNewCarrier) {
      title = 'Add New Carrier';
    }

    return (
      <div key="CountryShow" className="lander country">
        <p
          onClick={() => this.props.backButtonClick('list')}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
        <h3>{title}</h3>
      </div>
    );
  };

  /*
    Generates the table data and structure for the Carrier table information
  */
  generateCarrierTable() {
    let htmlRows = [];
    const { carriers } = this.state;

    if (!carriers) return 'Loading...';
    if (carriers.length === 0) return 'No Carriers Found.';

    for (let i = 0; i < carriers.length; i++) {
      htmlRows.push(
        <CarrierListItem
          key={carriers[i].carrier_id}
          id={carriers[i].carrier_id}
          countryName={carriers[i].carrier_name}
          countryCode={carriers[i].carrier_code}
          carrier={carriers[i]}
          onClickName={(e) => this.showSingleCarrier(e)}
        ></CarrierListItem>
      );
    }

    return (
      <Table className="table-new">
        <thead>
          <tr>
            <td className="medium tableTop column-left">Carrier Name</td>
            <td className="medium tableTop column-left">Carrier Code</td>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  }

  generatePrefixMapTable() {
    let htmlRows = [];
    const { prefixMaps } = this.state;

    if (!prefixMaps) return 'Loading...';
    if (prefixMaps.length === 0) return 'No Prefix Maps Found.';

    for (let i = 0; i < prefixMaps.length; i++) {
      htmlRows.push(
        <PrefixMapListItem
          key={prefixMaps[i].country_prefix_map_id}
          id={prefixMaps[i].country_prefix_map_id}
          beforePrefix={prefixMaps[i].before_prefix}
          afterPrefix={prefixMaps[i].after_prefix}
          length={prefixMaps[i].tn_length}
          prefix={prefixMaps[i]}
          onClickBeforePrefix={(e) => this.showSinglePrefixMap(e)}
        ></PrefixMapListItem>
      );
    }

    return (
      <Table className="table-new">
        <thead>
          <tr>
            <td className="medium tableTop column-left">Before Prefix</td>
            <td className="medium tableTop column-left">After Prefix</td>
            <td className="medium tableTop column-left">Length</td>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  }

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateBody = () => {
    let { country } = this.props;
    let {
      isEditing,
      selectedPrefixMap,
      selectedCarrier,
      selectedCarrierDevice,
      addNewPrefixMap,
      addNewCarrier,
    } = this.state;
    let buttonText = isEditing ? 'Cancel' : 'Edit';

    if (selectedPrefixMap) {
      return (
        <PrefixMapSingleView
          country={country}
          prefixMap={selectedPrefixMap}
          backButtonClick={this.backButtonClick}
          errorHandler={this.props.errorHandler}
        ></PrefixMapSingleView>
      );
    } else if (selectedCarrierDevice) {
      return (
        <CarrierDeviceSingleView
          carrierDevice={selectedCarrierDevice}
          country={country}
          backButtonClick={this.backButtonClick}
          errorHandler={this.props.errorHandler}
        ></CarrierDeviceSingleView>
      );
    } else if (selectedCarrier) {
      return (
        <CarrierSingleView
          carrier={selectedCarrier}
          country={country}
          backButtonClick={this.backButtonClick}
          errorHandler={this.props.errorHandler}
        ></CarrierSingleView>
      );
    } else if (addNewCarrier) {
      return (
        <CarrierSingleView
          country={country}
          isNew={true}
          backButtonClick={this.backButtonClick}
          errorHandler={this.props.errorHandler}
        ></CarrierSingleView>
      );
    } else if (addNewPrefixMap) {
      return (
        <PrefixMapSingleView
          prefixMap={null}
          country={country}
          isNew={true}
          backButtonClick={this.backButtonClick}
          errorHandler={this.props.errorHandler}
        ></PrefixMapSingleView>
      );
    }
    // Display data if not editing
    else if (country && !this.state.isEditing) {
      return (
        <div>
          {this.generateLander()}
          <Form>
            {this.generateFormGroup(
              '* Country Name:',
              country.country_name,
              'country_name'
            )}
            {this.generateFormGroup(
              '* Country Code:',
              country.country_code,
              'country_code'
            )}
            {this.generateFormGroup(
              '* Customers Located Here:',
              country.country_status === 'Y' ? 'Yes' : 'No',
              'country_status'
            )}
            {this.generateFormGroup(
              '* Premium Charged For Messages:',
              country.premium_status === 'Y' ? 'Yes' : 'No',
              'premium_status'
            )}
            {this.generateFormGroup(
              '* Primary Language For This Country:',
              country.language_name || 'English',
              'language_id'
            )}
          </Form>
          <Button className="maxHeight" onClick={this.toggleEdit}>
            {buttonText}
          </Button>
          <br />
          <div key="CarrierList" className="carrier table">
            <h4>List of Carriers</h4>
            <Button
              className="btn add-new-template"
              onClick={this.addNewCarrier}
            >
              Add New
            </Button>
            <br />
            {this.generateCarrierTable()}
          </div>
          <br />
          <div key="PrefixMapList" className="carrier table">
            <h4>List of Prefix Maps</h4>
            <Button
              className="btn add-new-template"
              onClick={this.addNewPrefixMap}
            >
              Add New
            </Button>
            <br />
            {this.generatePrefixMapTable()}
          </div>
        </div>
      );
    } else if (country && this.state.isEditing) {
      return (
        <div>
          {this.generateLander()}
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="country_name">
              <Form.Label column sm={2} className="info-label">
                * Country Name:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="country_name"
                  type="text"
                  defaultValue={country.country_name}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="country_code">
              <Form.Label column sm={2} className="info-label">
                * Country Code:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="country_code"
                  type="text"
                  defaultValue={country.country_code}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="country_status">
              <Form.Label column sm={2} className="info-label">
                * Customers Located Here:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  as="select"
                  name="country_status"
                  defaultValue={country.country_status}
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="premium_status">
              <Form.Label column sm={2} className="info-label">
                * Premium Charged For Messages:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  as="select"
                  name="premium_status"
                  defaultValue={country.premium_status}
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="dflt_language_id">
              <Form.Label column sm={2} className="info-label">
                * Primary Language For This Country:
              </Form.Label>
              <Col sm={8}>
                <LanguagePicker
                  selectName={'dflt_language_id'}
                  onChange={this.handleFormChange}
                  selectedLanguageId={country.dflt_language_id}
                ></LanguagePicker>
              </Col>
            </Form.Group>
            <Button className="maxHeight" variant="primary" type="submit">
              Save
            </Button>
          </Form>
          <Button className="maxHeight" onClick={this.toggleEdit}>
            {buttonText}
          </Button>
          <br />
          <div key="CarrierList" className="carrier table">
            <h4>List of Carriers</h4>
            <Button
              className="btn add-new-template"
              onClick={this.addNewCarrier}
            >
              Add New
            </Button>
            <br />
            {this.generateCarrierTable()}
          </div>
          <br />
          <div key="PrefixMapList" className="carrier table">
            <h4>List of Prefix Maps</h4>
            <Button
              className="btn add-new-template"
              onClick={this.addNewPrefixMap}
            >
              Add New
            </Button>
            <br />
            {this.generatePrefixMapTable()}
          </div>
        </div>
      );
    }

    return (
      <p>
        <span>(Country) No Data</span>
      </p>
    );
  };

  render() {
    return <div className="Home country">{this.generateBody()}</div>;
  }
}
