import React, { Fragment } from 'react';
import { Collapse } from 'react-bootstrap';
import { divide } from '../../../../../utils/maths';

const ListItem = ({
  id,
  date = '',
  dateRelative = '',
  logLevel = '',
  result = '',
  onClickResult = null,
  collapse = false,
  jsonObject = {},
}) => {
  let message = JSON.stringify(jsonObject, null, 4);
  const dateRelativeInSeconds = divide(dateRelative, 1000);

  return (
    <Fragment>
      <tr key={`list${id}`} id={id} onClick={() => onClickResult(id)}>
        <td className="column-right column-left" name="date">
          <div className="icon-inline">
            <i className="material-icons">
              {collapse ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
            </i>
            <span> &nbsp; {date} </span>
          </div>
        </td>
        <td className="column-right" name="result">
          <span>{dateRelativeInSeconds + ' secs'}</span>
        </td>
        <td className="column-right" name="result">
          <span className={logLevel === 'Error' ? 'red' : null}>{result}</span>
        </td>
      </tr>
      <tr></tr>
      <tr
        className={
          'collapsible-table column-right column-left' +
          (collapse ? ' column-bottom' : ' column-bottom-hidden')
        }
      >
        <td colSpan="6">
          <Collapse in={collapse}>
            <div>
              <div>{message}</div>
            </div>
          </Collapse>
        </td>
      </tr>
    </Fragment>
  );
};

export default ListItem;
