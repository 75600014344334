// NPM
import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';

// FORMATTED MESSAGES
import formattedMessages from '../FormattedMessages';

import Paginator from '../../../../components/Paginator';
// COMPONENTS
import ListItem from './ListItem';
import CustomTooltip from '../../../../components/CustomTooltip';

// CONFIG
import config from '../../../../Config';

export default class ListComponent extends Component {
  constructor(props) {
    super(props);

    /*
      PROPS LIST
        - list: List of entries to be displayed
        - templateMessage: Text for the template if template is selected
        - numberColumn: Column (as a numeric index) for the number
        - nameColumn: Column (as a numeric index) for the name
        - messageColumn: Column (as a numeric index) for the message
        - pageNumber: Number for the paginator
        - changeEvent: Change event for the paginator
        - selectRowsToBeDeleted: function to toggle checkboxes and enable / disable delete button accordingly.
        - changePageFunction: See below... 
    */

    this.state = {
      pageLength: config.defaultPL,
      pageNumber: 1,
      error: null,
      displayList: [],
      list: [],
    };
  }

  componentDidMount() {
    // Sets parent component's (top level bulksms) local function to this component's local
    // resetFunction which in turn is the paginator's local changePage function. Yes, this
    // was directed by Christopher Nolan.
    if (this.props.changePageFunction)
      this.props.changePageFunction(this.resetFunction);
  }

  /* 
   Changes the amount of entries per pagination page.
 */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState({ pageLength: value });
      this.props.changeEvent(1);
    }
  };

  /**
   * @returns list of items as a list which will later be used to generate JSX as well
   * as the total number of filtered items for pagination handling.
   */
  handleList = () => {
    let { list, pageNumber } = this.props;
    let { pageLength } = this.state;

    if (list && typeof list !== 'undefined' && Array.isArray(list)) {
      let filteredList = [];
      let displayList = [];

      displayList = list.slice(
        (pageNumber - 1) * pageLength,
        pageNumber * pageLength - 1 + 1
      );
      return {
        displayList: displayList,
        count: this.state.rows ? filteredList.length : list.length,
      };
    }
  };

  generateTableBody = (displayList) => {
    let { numberColumn, nameColumn, messageColumn } = this.props;
    let rows = [];

    for (let i = 0; i < displayList?.length; i++) {
      let element = displayList[i];
      let smsText = element.templateMessage || element[messageColumn] || '';

      // regex to check if there is a unicode character, therefore making it a unicode message
      let unicodeMessage = smsText.match(
        // eslint-disable-next-line no-control-regex
        /[^\u000A\u0020-\u007E]/g && /[^\x00-\x7F]/g
      );

      let messagePartCount = 1;
      if (unicodeMessage) {
        if (smsText.length > 70)
          messagePartCount = Math.ceil(smsText.length / 67);
      } else if (smsText.length > 160)
        messagePartCount = Math.ceil(smsText.length / 153);

      let textClass = null;
      if (element.valid === false) textClass = 'red';

      rows.push(
        <ListItem
          key={`list-${i}`}
          id={`list-${i}`}
          order={element[0]}
          number={element[numberColumn]}
          name={element[nameColumn]}
          messageText={smsText}
          messageCount={messagePartCount}
          textClass={textClass}
          selectRowsToBeDeleted={this.props.selectRowsToBeDeleted}
        />
      );
    }

    return rows;
  };

  render() {
    let { displayList, count } = this.handleList();

    return (
      <Fragment>
        <div className="visible-desktop">
          <Table id="inbox">
            <thead>
              <tr key="head">
                <th className="medium textLeft tableTop">
                  {formattedMessages.hashColumn}
                </th>
                <th className="medium textLeft tableTop">
                  {formattedMessages.recipientColumn}
                  <CustomTooltip
                    tooltipText={
                      'Recipient number should only contain numbers and +\n' +
                      'eg: +0123456789/0123456789'
                    }
                    margin="5px"
                    className="tooltip-standard-unicode"
                  />
                </th>
                <th className="medium textLeft tableTop">
                  {formattedMessages.nameColumn}
                </th>
                <th className="wide textLeft tableTop">
                  {formattedMessages.messageColumn}
                  <CustomTooltip
                    tooltipText={
                      '1 message = 160 characters or less (including spaces and signature character count).\n' +
                      'If 160 characters is exceeded, the message will be broken into parts, consisting of 153 characters.\n' +
                      'We can support max. of 765(plain)/335(unicode) characters = 5 parts.\n' +
                      '1 unicode SMS = 70 unicode characters, any message with 1 or more unicode characters is considered a unicode message and will be counted as such. Emojis are counted as unicode characters'
                    }
                    margin="5px"
                    className="tooltip-standard-unicode"
                  />
                </th>
                <th className="medium textLeft tableTop">
                  {formattedMessages.countColumn}
                </th>
                <th className="medium textLeft tableTop">
                  {formattedMessages.deleteRow}
                </th>
              </tr>
            </thead>
            <tbody>{this.generateTableBody(displayList)}</tbody>
          </Table>
          <Paginator
            itemCount={count}
            changeEvent={this.props.changeEvent}
            changePageFunction={(ref) => (this.resetFunction = ref)}
            pageLength={this.state.pageLength}
            editFunction={this.changePageLength}
          />
        </div>
        <div className="visible-device">
          <table id="sent" width="100%" cellSpacing="0">
            <thead>
              <tr>
                <th className="medium textLeft tableTop"></th>
              </tr>
            </thead>
            <tbody>{/* {this.generateMobileTableBody()} */}</tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}
