import React from 'react';

const ListItem = ({
  id, //={el.device_id}
  name, //={el.device_name}
  type, //={el.device_type}
  replyTn, //={el.reply_tn}
  outTotal, //={el.outTotal}
  inTotal, //={el.inTotal}
  ...props
}) => (
  <tr key={'list' + id} id={id} className="">
    <td className="medium textLeft" name="id">
      <span>{id}</span>
    </td>
    <td className="medium textLeft" name="name">
      <span>{name}</span>
    </td>
    <td className="medium textLeft" name="type">
      <span>{type}</span>
    </td>
    <td className="medium textLeft" name="replyTn">
      <span>{replyTn}</span>
    </td>
    <td className="medium textLeft" name="outTotal">
      <span>{outTotal}</span>
    </td>
    <td className="medium textLeft" name="inTotal">
      <span>{inTotal}</span>
    </td>
  </tr>
);

export default ListItem;
