import React from 'react';
// import { FormattedMessage } from 'react-intl';

// let currency = (
//   <FormattedMessage
//     id='global.currency'
//     defaultMessage='$'
//   ></FormattedMessage>
// );
const ListItem = ({
  id,
  // key,
  costCenter,
  user,
  date,
  direct,
  standard,
  reminders,
  replies,
  premium,
  others,
  total,
  ...props
}) => (
  <tr>
    <td className="left">{costCenter}</td>
    <td colSpan={8}></td>
    <td></td>
  </tr>
);

export default ListItem;
