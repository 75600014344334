import React, { Component } from 'react';
import ListItem from './ListItem';
import formattedMessages from './FormattedMessages';
// STYLING
import '../../../Users/Users.css';

class ListView extends Component {
  /*
        expects props:
            - logs: an object with the relevant data to display in the table.
            - drilldownEmail: a function that sets the parent component's drilldown email value.
            - selectedResult: the selected result to display req.body.
            - setSelectedResult: sets the parent components selected result in its state.
            - showArrow: a boolean that shows the dropdown arrow when true.
    */

  /**
   * Creates the Table to display all the log data
   * @returns
   */
  generateTable = () => {
    if (this.props.logs?.length > 0) {
      return (
        <div key="LogList">
          <table width="100%" className="table-new">
            <thead>
              <tr>
                <td
                  className="tableTop"
                  style={{ width: '200px', marginLeft: '5px' }}
                >
                  {formattedMessages.dateText}
                </td>
                <td className="tableTop" style={{ marginLeft: '5px' }}>
                  {formattedMessages.emailIDText}
                </td>
                <td className="tableTop" style={{ marginLeft: '5px' }}>
                  {'Account ID'}
                </td>
                <td className="tableTop" style={{ marginLeft: '5px' }}>
                  {formattedMessages.emailText}
                </td>
                <td className="tableTop" style={{ marginLeft: '5px' }}>
                  {formattedMessages.typeText}
                </td>

                <td className="tableTop" style={{ marginLeft: '5px' }}>
                  {'Total'}
                </td>
                <td className="tableTop" style={{ marginLeft: '5px' }}>
                  {'Failures'}
                </td>
              </tr>
            </thead>
            <tbody>{this.generateTableBody()}</tbody>
          </table>
        </div>
      );
    } else {
      return null;
    }
  };

  /**
   * Generates the body of the Table to display all the log data
   * @returns
   */
  generateTableBody = () => {
    let rows = [];
    let { logs, drilldownEmail, setSelectedResult, selectedResult, showArrow } =
      this.props;

    for (let i = 0; i < logs?.length; i++) {
      // pushes list items into array.
      rows.push(
        <ListItem
          key={logs[i].log_id}
          id={logs[i].log_id}
          date={logs[i].date}
          type={logs[i].type}
          failed={logs[i].failed}
          total={logs[i].total}
          emailID={logs[i].emailID}
          email={logs[i].email}
          accountNo={logs[i].accountNo}
          onClickName={(e) => drilldownEmail(e)}
          reqBody={logs[i].req_body}
          collapse={selectedResult && logs[i].log_id === selectedResult}
          onClickResult={(e) => setSelectedResult(e)}
          showArrow={showArrow}
        />
      );
    }
    return rows;
  };

  /** Returns the table of the list component. */
  render() {
    return this.generateTable();
  }
}

export default ListView;
