/*
  index.js - Find Users Main Component

  Author: Elle Dunbar (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';

// COMPONENTS
import ListView from './ListComponent';
import Paginator from '../../../components/Paginator';
import Users from '../../Reports/UserReport';
import UsersTabs from '../index';
import CostCentreRequest from '../CostCentres/CostCentreRequest';
// NETWORK
import UserRequest from '../UserRequests';

// I18N
import formattedMessages from './FormattedMessages';

// CONFIG
import config from '../../../Config';

// STYLING
import '../Users.css';

const userRequest = new UserRequest();
const costCentreRequest = new CostCentreRequest();

export default class FindUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      phone: '',
      showResults: false,
      results: [],
      paginatedResults: [],
      pageNumber: 1,
      pageLength: config.defaultPL,
      hideSearchForm: false,
      stv: false,
      selected: null,
      selectedIndex: null,
      searchTitle: '',
      errorState: false,
    };
  }
  async componentDidMount() {
    console.log('mounted once');
    let costCentres = await costCentreRequest.getCentres(
      this.props.errorHandler
    );

    await this.setState({
      costCentres: costCentres,
    });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    return this.setState({
      [name]: value,
    });
  };

  submitSearch = async (type, ...params) => {
    this.setState({
      showResults: false,
    });

    console.log('type: ', type);
    console.log('params: ', params);

    // Perform a regular User search
    // 0 - name, 1 - email, 2 - mob
    params.push(this.props.errorHandler);
    let rawData = await userRequest.searchForUser(
      params[0],
      params[1],
      params[2]
    );

    console.log('Got search rawData:', rawData);
    let results = this.sortList(rawData);

    if (!rawData.error) {
      //on success
      this.setState(
        {
          showResults: true,
          results: results,
          pageNumber: 1,
          pageLength: 10,
        },
        this.setPaginatedResults
      );
    } else {
      this.setState({
        errorState: true,
      });
    }
  };

  sortList(data) {
    let newList = data.sort(function (a, b) {
      if (a.user_name.toLowerCase() < b.user_name.toLowerCase()) {
        return -1;
      }
      if (a.user_name.toLowerCase() > b.user_name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    return this.sortByStatus(newList);
  }

  sortByStatus(data) {
    let newList = [];
    // Ordering purposes
    for (let i = 0; i < data.length; i++) {
      if (data[i].user_status === 'Active') newList.push(data[i]);
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].user_status === 'Suspended') newList.push(data[i]);
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].user_status === 'Cancelled') newList.push(data[i]);
    }
    // These are the possible user_statuses
    // "A": "Active",
    // "B": "Blacklisted",
    // "C": "Cancelled",
    // "D": "Delenquent",
    // "I": "Install",
    // "P": "Pending Cancel",
    // "S": "Suspended",
    // "U": "UnInstalled"

    return newList;
  }
  /*
    Handles receiving data back from ListView - ListItem click
  */
  selectUser = async (id) => {
    if (typeof id === 'undefined') {
      return this.setState({
        stv: false,
        selected: null,
      });
    }

    let selectedUser;

    let index;
    // Find selected User from passed in ID
    for (index = 0; index < this.state.results.length; index++) {
      if (this.state.results[index].customer_user_id === id) {
        selectedUser = this.state.results[index];
        break; //catches desired index value
      }
    }

    this.props.navigate('/reports/user', { state: { userData: selectedUser } });

    return this.setState({
      stv: true,
      selected: selectedUser,
      selectedIndex: index,
    });
  };

  changeEvent = async (i) => {
    this.setState(
      {
        pageNumber: i + 1,
      },
      this.setPaginatedResults
    );
  };

  /* 
  Changes the amount of entries per pagination page. 
  */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState({ pageLength: value, pageNumber: 1 });
    }
    this.setPaginatedResults;
  };

  setPaginatedResults = async () => {
    let { results, pageNumber, pageLength } = this.state;
    console.log('pageNumber:', pageNumber);
    this.setState({
      paginatedResults: results.slice(
        (pageNumber - 1) * pageLength,
        pageNumber * pageLength
      ),
    });
  };

  showResults() {
    //this.state.results
    if (typeof this.state.results === 'undefined') {
      return (
        <span className="main">{formattedMessages.errorReturningResults}</span>
      );
    } else {
      if (this.state.results.length === 0) {
        return <span className="main">{formattedMessages.noResultsFound}</span>;
      }
    }
    let { paginatedResults } = this.state;
    console.log('paginatedResults', paginatedResults);
    return (
      <Fragment>
        <span className="main">
          <ListView
            list={paginatedResults}
            callBack={this.selectUser}
            type={this.state.searchTitle}
            costCentres={this.state.costCentres}
          />
          <Paginator
            itemCount={this.state.results.length}
            changeEvent={this.changeEvent}
            pageLength={this.state.pageLength}
            editFunction={this.changePageLength}
          />
        </span>
      </Fragment>
    );
  }

  render() {
    let { accessLevel, accessType } = this.props;
    let { paginatedResults } = this.state;
    console.log('paginatedResults:', paginatedResults);
    if (accessLevel <= '0' || accessType === '0') {
      return null;
    }
    //show admin search forms
    if (this.state.errorState) {
      return <UsersTabs tab="findUser">Error</UsersTabs>;
    } else {
      // show regular search forms
      return (
        <div className="no-scroll">
          {this.state.stv ? ( //check if single view if true...
            <span className="singleView">
              <Users userData={this.state.selected} />
            </span>
          ) : (
            // if not single view...
            <UsersTabs tab="findUser">
              <Fragment>
                <h3>{formattedMessages.searchForUser}</h3>
                <table
                  border="0"
                  cellPadding="5px"
                  style={{ marginLeft: '50px' }}
                  width="80%"
                  className="no-table-row-background"
                >
                  <tbody>
                    <tr valign="top">
                      <td width="30%">{formattedMessages.name}</td>
                      <td>
                        <input
                          className="find-users input-name"
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </td>
                    </tr>
                    <tr valign="top">
                      <td width="30%">{formattedMessages.email}</td>
                      <td>
                        <input
                          className="find-users input-email"
                          type="text"
                          name="email"
                          value={this.state.email}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </td>
                    </tr>
                    <tr valign="top">
                      <td width="30%">{formattedMessages.cell}</td>
                      <td>
                        <input
                          className="find-users input-phone"
                          type="text"
                          name="phone"
                          value={this.state.phone}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" align="right">
                        <Button
                          className="btn btn-primary maxHeight"
                          onClick={() =>
                            this.submitSearch(
                              'SEARCH - USER',
                              this.state.name,
                              this.state.email,
                              this.state.phone
                            )
                          }
                        >
                          {formattedMessages.find}
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <div className="find-users-results">
                  {this.state.showResults ? this.showResults() : null}
                </div>
              </Fragment>
            </UsersTabs>
          )}
        </div>
      );
    }
  }
}
