// NPM
import React, { Component, Fragment } from 'react';

// COMPONENTS
import BillingTabs from '../index';
import ErrorList from '../../../components/ErrorList';
import StagedModal from '../../../components/StagedModal';

// I18N
import formattedMessages from './FormattedMessages';

// NETWORK
import PurchaseRequest from './PurchaseRequest';

import ListComponent from './List';

const purchaseRequest = new PurchaseRequest();

export default class PurchaseMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      last_four: '',
      expiry: '',
      edits: {},
      isSaving: false,
      loading: true,
      cardUpdate: false,
      data: null,
      list: [],
      showStaged: false,
      stage: 0,
      selected: null,
      stripeRate: null,
      modalError: '',
      modalBillId: null,
    };
  }

  componentDidMount = async () => {
    if (this.props.customer && this.props.customer !== null) {
      await this.getTariffs();
      this.setState({
        loading: false,
      });
    }
  };

  getTariffs = async () => {
    let results = await purchaseRequest.getTariffLines();
    let tariffs = [];
    for (let i = 0; i < results.tariffLines.length; i++) {
      tariffs.push(results.tariffLines[i]);
    }
    let stripeRate = results.stripeRate * 100;
    console.log('tariffs: ', tariffs);
    this.setState({
      data: results.results,
      list: tariffs,
      stripeRate: stripeRate,
    });

    return tariffs;
  };

  showStaged = async (element) => {
    this.setState({ showStaged: true, selected: element });
  };

  purchase = async () => {
    let { selected } = this.state;
    if (!selected) {
      this.setState({
        showStaged: false,
        stage: 0,
      });
      return;
    }
    const tariffPlanId = selected.tariff_plan_id;
    const tariffLineId = selected.tariff_line_id;
    const customerId = this.props.customer.customer_id;
    const customerUserId = this.props.user.customer_user_id;
    this.setState({
      stage: 1,
    });
    let results = await purchaseRequest.purchaseMessages(
      tariffPlanId,
      tariffLineId,
      customerId,
      customerUserId,
      this.props.errorHandler
    );

    // if the results exist and have no errors
    if (results && !results.expectedError && !results.error)
      this.setState({ stage: 2 });
    // else if there was an error when purchasing messages
    else {
      let errorMsg = 'Could not retrieve results.';
      let billId;
      // TODO: should we errorhandler unexpected errors?
      if (results) {
        console.log('purchase results:', results);
        errorMsg = results.expectedError || results.error || errorMsg;
        if (results.billId) {
          billId = results.billId;
          errorMsg += '. Do you want to go to the created bill?';
        } else if (results.conflictBillId) {
          billId = results.conflictBillId;
          errorMsg += '. Do you want to go to the conflicting bill?';
        }
      }
      this.setState({
        stage: 3,
        modalError: errorMsg,
        modalBillId: billId,
      });
    }

    console.log('purchase results: ', results);
  };

  generateBody = () => {
    // display list of results.
    let { list } = this.state;
    return (
      <div>
        <ListComponent list={list} purchase={this.showStaged} />
      </div>
    );
  };

  /*
    Renders Error Text Component
  */
  generateErrorList = () => {
    return (
      <ErrorList hasError={this.state.hasError} errors={this.state.errors} />
    );
  };

  render() {
    let { accessLevel, accessType, customer, navigate } = this.props;
    let { showStaged, modalError, modalBillId } = this.state;

    if (!this.state.loading) {
      const redirectUrl = `/billing/invoiceshow/${customer.customer_id}/${modalBillId}`;
      return (
        <BillingTabs
          tab="purchase"
          accessLevel={accessLevel}
          accessType={accessType}
          customerType={customer?.customer_type}
        >
          <Fragment>
            <h3>{formattedMessages.purchaseMessages}</h3>
            <p className="surcharge-warning">
              * All purchases will incur an additional {this.state.stripeRate}%
              processing fee.
            </p>
            {this.generateBody()}
            <StagedModal
              showStaged={showStaged}
              removeStaged={() =>
                this.setState({
                  showStaged: false,
                })
              }
              removeStagedSlow={() =>
                this.setState({ stage: 0, modalError: '', modalBillId: null })
              }
              performAction={this.purchase}
              performErrorAction={
                modalBillId && customer
                  ? () => navigate(redirectUrl)
                  : undefined
              }
              stage={this.state.stage}
              stageTitle="Purchase Message Confirmation"
              stageConfirmation="Are you sure you would like to purchase these messages?"
              stagePending="Message purchase pending..."
              stageSuccess="Message purchase successful!"
              stageFailure={modalError || 'Message purchase failed!'}
            />
          </Fragment>
        </BillingTabs>
      );
    } else
      return (
        <BillingTabs
          tab="purchase"
          accessLevel={accessLevel}
          accessType={accessType}
          customerType={customer?.customer_type}
        >
          <Fragment>
            <h3>{formattedMessages.purchaseMessages}</h3>
            <h4>Loading...</h4>
          </Fragment>
        </BillingTabs>
      );
  }
}
