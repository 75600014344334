import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  listOfProviders: (
    <FormattedMessage
      id="AdminProvider.ListOfProviders"
      defaultMessage="List Of Providers"
    />
  ),
  nameText: <FormattedMessage id="ReportsList.Name" defaultMessage="Name" />,
  statusText: (
    <FormattedMessage id="ReportsList.Status" defaultMessage="Status" />
  ),
  addNewText: (
    <FormattedMessage id="Global.AddNewText" defaultMessage="Add New" />
  ),
  backText: <FormattedMessage id="Global.BackText" defaultMessage="Back" />,
  providerInformation: (
    <FormattedMessage
      id="AdminProvider.ProviderInformation"
      defaultMessage="Provider Information"
    />
  ),
  createProvider: (
    <FormattedMessage
      id="AdminProvider.CreateProvider"
      defaultMessage="Create Provider"
    />
  ),
  trial: <FormattedMessage id="AdminProvider.Trial" defaultMessage="Trial" />,
  active: (
    <FormattedMessage id="AdminProvider.Active" defaultMessage="Active" />
  ),
  cancelled: (
    <FormattedMessage id="AdminProvider.Cancelled" defaultMessage="Cancelled" />
  ),
  suspended: (
    <FormattedMessage id="AdminProvider.Suspended" defaultMessage="Suspended" />
  ),
};

export default FormattedMessages;
