/*
  Single.js - Sent SMS Single Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Form, Col, Row, Button, Modal } from 'react-bootstrap';
import formattedMessages from './FormattedMessages';
import { DateTime } from 'luxon';

// UTIL
import { errorCodes } from '../../../utils/errorCodes';

// FORMATTED MESSAGES
// import formattedMessages from '../FormattedMessages';

// NETWORK
import SentRequest from './SentRequest';
const sentRequest = new SentRequest();

export default class SentSmsSingle extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - sms: The Sent SMS to display information for
        - backButtonClick: Function to go back to List view
    */

    this.state = { justCancelled: false };
  }

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="ProviderShow">
        <p
          onClick={() => this.props.backButtonClick(false)}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
      </div>
    );
  };

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  showConfirmationModal = () => {
    this.setState({
      showConfirmation: true,
    });
  };

  showConfirmationDialog = () => {
    return (
      <Modal
        show={this.state.showConfirmation}
        onHide={this.handleCloseConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancellation Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you would like to cancel single pending messages?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="maxHeight btn btn-primary"
            onClick={this.handleCancelPendingMessage}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="maxHeight btn btn-primary"
            onClick={this.handleCloseConfirmation}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  /*
    Handles the click for "Cancel Pending Message" button.
  */
  handleCancelPendingMessage = () => {
    let { sms } = this.props;
    this.setState(
      {
        isSaving: true,
      },
      async () => {
        const result = await sentRequest.cancelPendingMessage(
          sms?.sms_id,
          this.props.errorHandler
        );

        this.handleCloseConfirmation();
        if (result && result.length > 0) this.setState({ justCancelled: true });
      }
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateBody = () => {
    const { sms } = this.props;
    const { justCancelled } = this.state;
    // Display sms information
    if (sms) {
      let deliverDate = sms.sms_delivered
        ? DateTime.fromISO(sms.sms_delivered)
        : 'Pending Delivery';
      let sentDate = DateTime.fromISO(sms.sms_sent);
      let rezoned = sentDate.setZone(localStorage.getItem('timeShort'), {
        keepLocalTime: false,
      });
      let smsText;
      try {
        smsText = decodeURIComponent(sms.sms_text);
      } catch (err) {
        smsText = 'ERROR: MALFORMED TEXT';
      }

      return (
        <div>
          <h2>SMS Information</h2>
          <b>{formattedMessages.queuedHeader}</b>
          <br />
          <span>
            {rezoned.toFormat('dd-LLL-yyyy h:mm a') +
              ' ' +
              localStorage.getItem('timeString')}
          </span>
          <br />
          <br />
          <b>{formattedMessages.sentHeader}</b>
          <br />
          <span>
            {sms.sms_delivered
              ? deliverDate
                  .setZone(localStorage.getItem('timeShort'), {
                    keepLocalTime: false,
                  })
                  .toFormat('dd-LLL-yyyy h:mm a')
              : 'Pending Delivery'}
          </span>
          <br />
          <br />
          <b>{formattedMessages.toHeader}</b>
          <br />
          <span>{sms.dest_name || sms.dest_tn || sms.dest_email}</span>
          <br />
          <br />
          <b>{formattedMessages.statusHeader}</b>
          <br />
          <span>{justCancelled ? 'Cancelled' : sms.sent_flag_name}</span>
          <br />
          <br />
          {this.generateErrorComponent(sms.error_code_text)}
          <b>{formattedMessages.encodingHeader}</b>
          <br />
          <span>{sms.smsc_params}</span>
          <br />
          <br />
          <b>{formattedMessages.messageHeader}</b>
          <br />
          <span>{smsText}</span>
          <br />
          <br />
          {sms.sent_flag_name === 'Pending' && !justCancelled && (
            <Button
              className="maxHeight btn btn-primary"
              onClick={this.showConfirmationModal}
            >
              <span>{formattedMessages.cancelPendingMessage}</span>
            </Button>
          )}
        </div>
      );
    }

    return <span> No Data </span>;
  };

  generateErrorComponent = (errorCode) => {
    if (!errorCode || typeof errorCode === 'undefined') return;
    let code = errorCodes(errorCode);
    return (
      <>
        <b>{formattedMessages.errorCodeHeader}</b>
        <br />
        <span>{code.roxCode}</span>
        <br />
        <br />
        <b>{formattedMessages.errorTextHeader}</b>
        <br />
        <span>{code.meaning}</span>
        <br />
        <br />
      </>
    );
  };

  render() {
    return (
      <div className="Home">
        {this.generateLander()}
        {this.showConfirmationDialog()}
        {this.generateBody()}
      </div>
    );
  }
}
