/*
  FormattedMessages.js - FormattedMessages for the System Monitor's Search Component.

  Author: Adarsh Anup (2022)
  Company: Red Oxygen
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  searchTitle: (
    <FormattedMessage
      id="Global.searchTitle"
      defaultMessage="Search All Log Messages"
    />
  ),
  searchType: (
    <FormattedMessage id="Global.searchType" defaultMessage="Search Type:" />
  ),
  searchValue: (
    <FormattedMessage id="Global.searchValue" defaultMessage="Search Value:" />
  ),
  dateRange: (
    <FormattedMessage id="Global.dateRange" defaultMessage="Data Range:" />
  ),
  searchButton: (
    <FormattedMessage id="Global.searchButton" defaultMessage="Search" />
  ),
  noResultsFound: (
    <FormattedMessage
      id="Global.noResultsFound"
      defaultMessage="No Results Found"
    />
  ),
};

export default FormattedMessages;
