import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  smsDelivered: (
    <FormattedMessage id="Global.SmsDelivered" defaultMessage="SMS Delivered" />
  ),
  smsQueued: (
    <FormattedMessage id="Global.SmsQueued" defaultMessage="SMS Queued" />
  ),
  delay: <FormattedMessage id="Global.Delay" defaultMessage="Delay" />,
  destination: (
    <FormattedMessage id="Global.Destination" defaultMessage="Destination" />
  ),
  originator: (
    <FormattedMessage id="Global.Originator" defaultMessage="Originator" />
  ),
  campaignId: (
    <FormattedMessage id="Global.CampaignId" defaultMessage="Campaign ID" />
  ),
  encoding: <FormattedMessage id="Global.Encoding" defaultMessage="Encoding" />,
  messageParts: (
    <FormattedMessage id="Global.MessageParts" defaultMessage="Message Parts" />
  ),
  backText: <FormattedMessage id="Global.BackText" defaultMessage="Back" />,
  messageReportsTitle: (
    <FormattedMessage
      id="ReportsList.MessageReportsTitle"
      defaultMessage="Message Reports"
    />
  ),
  searchingText: (
    <FormattedMessage
      id="ReportsList.SearchingText"
      defaultMessage="Searching..."
    ></FormattedMessage>
  ),
  dateText: (
    <FormattedMessage id="ReportsIndex.dateText" defaultMessage="Date" />
  ),
  typeText: (
    <FormattedMessage
      id="ReportsList.Type"
      defaultMessage="Type"
    ></FormattedMessage>
  ),
  userText: (
    <FormattedMessage
      id="ReportsList.User"
      defaultMessage="User"
    ></FormattedMessage>
  ),
  fromText: (
    <FormattedMessage
      id="ReportsList.From"
      defaultMessage="From"
    ></FormattedMessage>
  ),
  toText: (
    <FormattedMessage
      id="ReportsList.To"
      defaultMessage="To"
    ></FormattedMessage>
  ),
  countryText: (
    <FormattedMessage
      id="ReportsList.CountryText"
      defaultMessage="Country"
    ></FormattedMessage>
  ),
  statusText: (
    <FormattedMessage
      id="ReportsList.Status"
      defaultMessage="Status"
    ></FormattedMessage>
  ),
  errorCode: (
    <FormattedMessage
      id="ReportsList.ErrorCode"
      defaultMessage="Error Code"
    ></FormattedMessage>
  ),
  errorText: (
    <FormattedMessage
      id="ReportsList.Error"
      defaultMessage="Error"
    ></FormattedMessage>
  ),
  clear: (
    <FormattedMessage id="ReportsList.Clear" defaultMessage="Clear Filters" />
  ),
};

export default FormattedMessages;
