import { DateTime } from 'luxon';

export function getZoneOffsettedDate(date) {
  const createdDate = DateTime.fromISO(date);
  return createdDate
    .setZone(localStorage.getItem('timeShort'), {
      keepLocalTime: false,
    })
    .toFormat('dd-LLL-yyyy h:mm a');
}
