// NPM MODULES
import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class StagedModal extends Component {
  constructor(props) {
    super(props);

    /*
          Expected Props:
           - message: The actual message
           - showStaged: The boolean which determines if the modal should be shown.
           - removeStaged: The function which sets showStaged to false.
           - performAction: The function that will perform the action required
           - performErrorAction: The function that performs if an error happens.
           - stage: the variable controlling the stage of the modal
           - stageTitle: The title of the stage modal
           - stageConfirmation: The confirmation message
           - stagePending: The pending message
           - stageSuccess: The success message
           - stageFailure: The failure message

          Optional Prop:
           - removeStagedSlow: The function which is called after the modal finishes transitioning out.
        */

    this.state = {};
  }

  render() {
    let {
      showStaged,
      removeStaged,
      removeStagedSlow,
      performAction,
      stageTitle,
      stageConfirmation,
      stagePending,
      stageSuccess,
      stageFailure,
      stage,
      performErrorAction,
    } = this.props;
    // Confirmation 0, pending 1
    if (this.props.stage <= 1) {
      return (
        <Modal show={showStaged} onHide={() => removeStaged()}>
          <Modal.Header closeButton>
            <Modal.Title>{stageTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {stage === 0 ? stageConfirmation : stagePending}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="maxHeight btn btn-primary"
              disabled={stage === 0 ? false : true}
              variant="primary"
              onClick={() => performAction()}
            >
              Yes
            </Button>
            <Button
              className="maxHeight btn btn-primary"
              disabled={stage === 0 ? false : true}
              variant="secondary"
              onClick={() => removeStaged()}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    // Success 2, failure 3
    else if (stage >= 2) {
      let button = (
        <Modal.Footer>
          <Button
            className="maxHeight"
            variant="primary"
            onClick={() => removeStaged()}
          >
            Ok
          </Button>
        </Modal.Footer>
      );

      if (performErrorAction && stage === 3)
        button = (
          <Modal.Footer>
            <Button
              className="maxHeight"
              variant="primary"
              onClick={() => performErrorAction()}
            >
              Yes
            </Button>
            <Button
              className="maxHeight btn btn-primary"
              variant="secondary"
              onClick={() => removeStaged()}
            >
              No
            </Button>
          </Modal.Footer>
        );

      return (
        <Modal
          show={showStaged}
          onHide={() => removeStaged()}
          onExited={removeStagedSlow ? () => removeStagedSlow() : null}
        >
          <Modal.Header closeButton>
            <Modal.Title>{stageTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{stage === 2 ? stageSuccess : stageFailure}</Modal.Body>
          {button}
        </Modal>
      );
    }
  }
}

export default StagedModal;
