import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  costCentreReportsTitle: (
    <FormattedMessage
      id="Reports.CostCentreReportsTitle"
      defaultMessage="Cost Centre Reports"
    ></FormattedMessage>
  ),
  accountText: (
    <FormattedMessage
      id="Global.AccountText"
      defaultMessage="Account:"
    ></FormattedMessage>
  ),
  back: (
    <FormattedMessage id="Global.back" defaultMessage="Back"></FormattedMessage>
  ),
  usageLogTitle: (
    <FormattedMessage
      id="Reports.UsageLogTitle"
      defaultMessage="System Usage Log"
    ></FormattedMessage>
  ),
  searchByUserEmail: (
    <FormattedMessage
      id="Reports.SearchByUserEmail"
      defaultMessage="Search by User Email:"
    ></FormattedMessage>
  ),
  dateRange: (
    <FormattedMessage
      id="Reports.DateRange"
      defaultMessage="Date Range:"
    ></FormattedMessage>
  ),
  showReport: (
    <FormattedMessage
      id="Reports.ShowReport"
      defaultMessage="Show Report"
    ></FormattedMessage>
  ),
  enterPassword: (
    <FormattedMessage
      id="Reports.EnterPassword"
      defaultMessage="Enter a Document Password:"
    ></FormattedMessage>
  ),
  emailPDFFile: (
    <FormattedMessage
      id="Reports.EmailPDFFile"
      defaultMessage="Email PDF File"
    ></FormattedMessage>
  ),
  searching: (
    <FormattedMessage
      id="Reports.Searching"
      defaultMessage="Searching..."
    ></FormattedMessage>
  ),
  noResult: (
    <FormattedMessage
      id="Reports.NoResult"
      defaultMessage="No results found."
    ></FormattedMessage>
  ),
  key: (
    <FormattedMessage
      id="ReportsList.Key"
      defaultMessage="Key"
    ></FormattedMessage>
  ),
  costCentre: (
    <FormattedMessage
      id="ReportsList.CostCentre"
      defaultMessage="Cost Centre"
    ></FormattedMessage>
  ),
  userName: (
    <FormattedMessage
      id="ReportsList.UserName"
      defaultMessage="User's Name"
    ></FormattedMessage>
  ),
  date: (
    <FormattedMessage
      id="ReportsList.Date"
      defaultMessage="Date"
    ></FormattedMessage>
  ),
  time: (
    <FormattedMessage
      id="ReportsList.Time"
      defaultMessage="Time"
    ></FormattedMessage>
  ),
  phone: (
    <FormattedMessage
      id="ReportsList.Phone"
      defaultMessage="Phone"
    ></FormattedMessage>
  ),
  type: (
    <FormattedMessage
      id="ReportsList.Type"
      defaultMessage="Type"
    ></FormattedMessage>
  ),
  quantity: (
    <FormattedMessage
      id="ReportsList.Quantity"
      defaultMessage="Quantity"
    ></FormattedMessage>
  ),
  text: (
    <FormattedMessage
      id="ReportsList.Text"
      defaultMessage="Text"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
