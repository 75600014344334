// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { validatePhoneNumber } from '../../../../utils/validate';

// I18N
import formattedMessages from '../FormattedMessages';

export default class BlacklistSingleView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      delete: false,
      oldNumber: '',
      number: '',
      blacklistId: '',
      listToggle: '',
      dataPresent: false, // if true - have Contact data in props.selected
      saving: false,
      error: '',
    };
  }

  componentDidMount() {
    const { selected } = this.props;
    // If have selected, a Contact was clicked
    if (selected && typeof selected !== 'undefined') {
      this.setState({
        oldNumber: selected.number || '',
        number: selected.number || '',
        blacklistId: selected.blacklist_id || '',
        dataPresent: true,
      });
    }
  }

  /*
    Sets additional buttons on the screen based on whether or not
  */
  getButton() {
    let { accessLevel, accessType, tab } = this.props;

    if (
      (accessLevel > '0' && accessType !== '0' && this.state.dataPresent) ||
      (this.state.dataPresent && tab === 'Personal')
    ) {
      return (
        <Button
          onClick={this.handleModal}
          className="delete btn-red btn btn-danger"
        >
          {formattedMessages.deleteText}
        </Button>
      );
    }
    return null;
  }

  /*
    Handles displaying of Delete modal
  */
  handleModal = () => {
    return this.setState({
      delete: !this.state.delete,
    });
  };

  /*
    Handles state changes from the Form
  */
  handleChangeEvent = (e) => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'email') {
      value = value.toLowerCase();
    }

    return this.setState({
      [name]: value,
    });
  };

  /*
    Handles the change action for a toggle
  */
  handleToggle = (event) => {
    return this.setState({
      listToggle: event,
    });
  };

  /*
    Handles when the Form is submitted
  */
  handleSubmit = async () => {
    // If not already saving...
    if (!this.state.saving) {
      let { number, oldNumber, blacklistId } = this.state;

      // Needs number
      if (!number) {
        return this.setState({
          error: 'Mobile Number is required',
        });
      }
      // If number isn't only digits
      if (!validatePhoneNumber(number)) {
        return this.setState({
          error:
            'A Mobile Number must contain only digits, dashes, brackets or spaces.  It may also contain a + sign at the start',
        });
      }

      await this.setState({
        saving: true,
      });

      // If Editing...
      if (this.state.dataPresent) {
        await this.props.edit(blacklistId, number, oldNumber);
      }
      // Create
      else {
        await this.props.add(number);
      }
      return;
    }
  };

  handleNewContact = () => {
    let { number } = this.state;
    let data = [number];

    return (
      <Fragment>
        <div className="col-sm-2">&nbsp;</div>
        <div className="col-sm-10">
          <Button
            className="maxHeight"
            onClick={() => this.props.handleSelect(true, data)}
          >
            {formattedMessages.save}
          </Button>
        </div>
      </Fragment>
    );
  };

  handleButtonGroup = () => {
    return (
      <Fragment>
        <div className="col-sm-2">&nbsp;</div>
        <div className="col-sm-10">
          <Button className="maxHeight" onClick={this.handleSubmit}>
            {this.state.saving
              ? formattedMessages.saving
              : formattedMessages.save}
          </Button>
          {this.getButton()}
        </div>
      </Fragment>
    );
  };

  /*
    Handles any errors generated
  */
  generateError = () => {
    if (this.state.error) {
      return (
        <Fragment>
          <div className="col-sm-2">&nbsp;</div>
          <div className="col-sm-10 errorText textLeft">{this.state.error}</div>
        </Fragment>
      );
    }
    // Return no error if none present
    return null;
  };

  render() {
    let { dataPresent } = this.state;
    return (
      <Fragment>
        <div>
          <p onClick={this.props.callBack} className="backLink">
            <i className="material-icons">keyboard_arrow_left</i>
            <span>{formattedMessages.backText}</span>
          </p>
          <h4>{dataPresent ? 'Global Blacklist Details' : 'New Blacklist'}</h4>
          <br />
          <Form onChange={this.handleChangeEvent}>
            <Form.Group as={Row} controlId="number">
              <Form.Label column sm={2} className="detail-label">
                {formattedMessages.mobileText}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="number"
                  type="text"
                  defaultValue={!dataPresent ? '' : this.props.selected.number}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>{this.generateError()}</Form.Group>
            <Form.Group as={Row} controlId="buttons">
              {this.props.selectOnly
                ? this.handleNewContact()
                : this.handleButtonGroup()}
            </Form.Group>
          </Form>
        </div>
        <Modal show={this.state.delete} onHide={this.handleModal}>
          <Modal.Header closeButton>
            <Modal.Title>{formattedMessages.deleteContactText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{formattedMessages.deleteContactsBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleModal}>
              {formattedMessages.cancelText}
            </Button>
            <Button variant="danger" onClick={this.props.delete}>
              {formattedMessages.deleteContactText}
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}
