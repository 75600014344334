/*
  RegisterRequest.js - Register Networking File
  
  Contains networking functionality for the Register Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../RequestRabbit';

// CONFIGURATION
import config from '../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class RegisterRequest {
  /*
    Creates the initial request to the Customer started off registering.
    Returns null if success, otherwise returns a JSON object containing
  */
  registerNewCustomer = async (params, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/auth/register`;
      const body = JSON.stringify(params);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };

      let response = await requestRabbit.request(url, options, errorHandler);

      if (response.status === 204) return;

      let errorJson = await response.json();
      return errorJson;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  verifyPhoneNumberCode = async (state, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/auth/verify/code`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: JSON.stringify(state),
        headers: headers,
      };

      let response = await requestRabbit.request(url, options);

      if (response.status === 200) return;

      let errorJson = await response.json();
      return errorJson;
    } catch (e) {
      errorHandler(e.message);
    }
  };
  generateResendCode = async (state, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/auth/verify/resend`;
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        body: JSON.stringify(state),
        headers: headers,
      };

      let response = await requestRabbit.request(url, options);

      if (response.status === 200) return;

      let errorJson = await response.json();
      return errorJson;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
