/*
  Admin.js - Admin Main Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import formattedMessages from './FormattedMessages';

export default class AdminTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: props.tab,
      selectedCustomerId: null,
      action: null,
    };
  }

  /*
   Generates the sidebar menu items for use
 */
  generateUserMenu() {
    let { tab } = this.state;

    return (
      <ButtonGroup vertical defaultValue={this.state.default}>
        <Link to="../provider">
          <Button
            id="provider"
            variant="light"
            className={tab === 'provider' ? 'active' : ''}
          >
            {formattedMessages.provider}
          </Button>
        </Link>
        <Link to="../customers">
          <Button
            id="customers"
            variant="light"
            className={tab === 'customers' ? 'active' : ''}
          >
            {formattedMessages.customers}
          </Button>
        </Link>
        <Link to="../errorlog">
          <Button
            id="errorlog"
            variant="light"
            className={tab === 'errorlog' ? 'active' : ''}
          >
            {formattedMessages.errorLog}
          </Button>
        </Link>
        <Link to="../tracelog">
          <Button
            id="tracelog"
            variant="light"
            className={tab === 'tracelog' ? 'active' : ''}
          >
            {formattedMessages.traceLog}
          </Button>
        </Link>
        <Link to="../service-config">
          <Button
            id="device"
            variant="light"
            className={tab === 'device' ? 'active' : ''}
          >
            {formattedMessages.serviceConfig}
          </Button>
        </Link>
        <Link to="../tariffs">
          <Button
            id="tariff"
            variant="light"
            className={tab === 'tariff' ? 'active' : ''}
          >
            {formattedMessages.tariffing}
          </Button>
        </Link>
        <Link to="../find-customer">
          <Button
            id="findCustomer"
            variant="light"
            className={tab === 'findCustomer' ? 'active' : ''}
            onClick={
              this.props.handlePageReset
                ? () => this.props.handlePageReset()
                : undefined
            }
          >
            {formattedMessages.findCustomer}
          </Button>
        </Link>
        <Link to="../system-monitor">
          <Button
            id="systemMonitor"
            variant="light"
            className={tab === 'systemMonitor' ? 'active' : ''}
          >
            {formattedMessages.systemMonitor}
          </Button>
        </Link>
      </ButtonGroup>
    );
  }

  render() {
    let { accessLevel, accessType } = this.props;
    if (accessLevel !== '3' || accessType !== '3')
      return <p>{formattedMessages.accessDenied}</p>;

    return (
      <Container>
        <div className="visible-desktop-sideNav">
          <Row>
            <Col sm={3} id="sideNav" className="side-nav">
              {this.generateUserMenu()}
            </Col>
            <Col className="Admin" sm={9}>
              {this.props.children}
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
