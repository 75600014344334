/*
  UserReport/SingleViewUser.js - Creates a Single User view that allows Editing as well

  Author: Elle Dunbar (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import Edit from '../../../Users/Users/Edit';

// FORMATTED MESSAGES
// import formattedMessages from '../FormattedMessages';

class SingleViewUser extends Component {
  constructor(props) {
    super(props);
    /*
      Expected Props:
        - selectedData: Data for the selected User
    */

    this.state = {};
  }

  render() {
    // console.log('reports/user: ', this.state);
    // console.log('reports/user: ', this.props.selectedData);
    // console.log('reports/user: ', this.props);

    return (
      <Fragment>
        <Edit
          callBack={this.props.backButton}
          user={this.props.selectedData}
          errorHandler={this.props.errorHandler}
          backMessage="Back to User Reports"
        />
      </Fragment>
    );
  }
}

export default SingleViewUser;
