import React from 'react';

const ListItem = ({
  id,
  deviceName = '',
  priority = '',
  onClickName = null,
  device = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="column-right" name="name">
      <span className="blue" onClick={() => onClickName(device)}>
        {deviceName}
      </span>
    </td>
    <td className="column-right" name="name">
      <span>{priority}</span>
    </td>
  </tr>
);

export default ListItem;
