/*
  RequestRabbit.js - RequestRabbit Index File
  
  Abstract networking class using Fetch API

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

export default class RequestRabbit {
  /*
    Makes a request on behalf of the User, based on passed in parameters.
    Inputs: 
      - url: String containing the URL to send the request to
      - body: Any fields to add to the body of the request.  Should be an object
      - headers: Any headers to add to the body.  Should be an object
  */
  request = async (url, options, errorHandler) => {
    try {
      if (!options || typeof options === 'undefined')
        throw new Error('Requires valid Options object');
      // Set Allow Credentials
      options.credentials = 'include';
      // Fetch
      var response = await fetch(url, options);
      // If we received an error and it is to do with the web session
      if (response.error && response.message.includes('Web Session')) {
        // Deletes localStorage
        localStorage.removeItem('rox_user_token');
        localStorage.removeItem('rox_user_email');

        // Head back to the home page
        window.location.href = '/';
      }

      // Throws error if status code is invalid.
      if (response.status > 399 && response.status < 600) {
        let responseJSON = await response.clone().json();
        throw new Error(responseJSON.error);
      }

      // Else if regular response, return as an Error
      else if (response.error) throw new Error(response.error);
      return response;
    } catch (err) {
      console.error('req rabbit err:', err, url, options);
      if (errorHandler) errorHandler(err.message);
      else console.error('errorHandler missing for:', url);
      return response;
    }
  };
}
