import React from 'react';

const ListItem = ({
  id,
  schemeName = '',
  schemeType = '',
  schemeStatus = '',
  onClickName = null,
  scheme = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="textLeft">
      <span className="blue" onClick={() => onClickName(scheme)}>
        {schemeName}
      </span>
    </td>
    <td className="textLeft">
      <span>{schemeType}</span>
    </td>
    <td className="textLeft">
      <span>{schemeStatus}</span>
    </td>
  </tr>
);

export default ListItem;
