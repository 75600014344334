import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import Paginator from '../../../../components/Paginator';
import config from '../../../../Config';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

class ListComponent extends Component {
  constructor(props) {
    super(props);

    /*
      PROPS LIST:
        - loading: Boolean on whether data is loading or not
    */

    this.state = {
      pageLength: config.defaultPL,
      pageNumber: 1,
      error: null,
    };
  }

  /* 
   Changes the amount of entries per pagination page.
 */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState({ pageLength: value, pageNumber: 1 });
      this.changeEvent(1);
    }
  };

  changeEvent = (i) => {
    return this.setState({
      pageNumber: i + 1,
    });
  };

  handleList = () => {
    let { list } = this.props;
    let { pageLength, pageNumber } = this.state;

    if (list && typeof list !== 'undefined' && Array.isArray(list)) {
      let filteredList = [];
      let displayList = [];

      displayList = list.slice(
        (pageNumber - 1) * pageLength,
        pageNumber * pageLength
      );
      return {
        displayList: displayList,
        count: this.state.rows ? filteredList.length : list.length,
      };
    }
    return { count: 0 };
  };

  render() {
    let { count, displayList } = this.handleList();
    let { loading } = this.props;

    if (loading) {
      return <div>{formattedMessages.loadingText}...</div>;
    } else
      return (
        <Fragment>
          <Table>
            <thead>
              <tr>
                <td className="medium tableTop textLeft">
                  {formattedMessages.deviceId}
                </td>
                <td className="medium tableTop textLeft">
                  {formattedMessages.deviceName}
                </td>
                <td className="medium tableTop textLeft">
                  {formattedMessages.deviceType}
                </td>
                <td className="medium tableTop textLeft">
                  {formattedMessages.replyTn}
                </td>
                <td className="medium tableTop textLeft">
                  {formattedMessages.outTotal}
                </td>
                <td className="medium tableTop textLeft">
                  {formattedMessages.inTotal}
                </td>
              </tr>
            </thead>
            <tbody>{displayList}</tbody>
          </Table>
          <Paginator
            itemCount={count}
            changeEvent={this.changeEvent}
            changePageFunction={(ref) => (this.resetFunction = ref)}
            pageLength={this.state.pageLength}
            editFunction={this.changePageLength}
          />
        </Fragment>
      );
  }
}

export default ListComponent;
