/*
  AdministratorRequest.js - Networking File

  Contains networking functionality for Customer_Contact (Adminstrator) actions.

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';

// CONFIGURATION
import config from '../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class AdminRequest {
  /*
    Fetch Customer_Contact list for Customer
  */
  getAdministrators = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/contact`;
      let options = {
        method: 'GET',
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return await responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Used to fetch a single Customer Contact
  */
  getSingleAdministrator = async (contactId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/contact/${contactId}`;
      let options = {
        method: 'GET',
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return await responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Creates a new Customer_Contact for the given details.
  */
  createAdministrator = async (data, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/contact`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const body = JSON.stringify(data);
      const options = {
        method: 'POST',
        headers: headers,
        body: body,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Edits a Customer_Contact with the given details.
  */
  editAdministrator = async (customerContactId, data, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/contact/${customerContactId}`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const body = JSON.stringify(data);
      const options = {
        method: 'POST',
        headers: headers,
        body: body,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Deletes a Customer_Contact with the given Id.
  */
  deleteAdministrator = async (customerContactId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/contact/${customerContactId}`;
      const options = {
        method: 'DELETE',
      };

      // Request
      await requestRabbit.request(url, options, errorHandler);
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Gets extra data for Select elements etc
  */
  getExtras = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/contact/extras`;
      let options = {
        method: 'GET',
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Gets user data by email address
  */

  getUserByEmail = async (email, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/contact/user`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const body = JSON.stringify({ contact_email: email });
      const options = {
        method: 'POST',
        headers: headers,
        body: body,
      };
      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
