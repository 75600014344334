import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HomeCard = ({
  title,
  text,
  iconClass = '',
  link = '',
  interaction = '',
  page = '',
  ...props
}) => (
  <Card style={{ width: '18rem', height: '18rem' }}>
    <Card.Body>
      <Card.Img variant="top" src={link} />
      <Card.Title>{title}</Card.Title>
      <Card.Subtitle className="mb-2 text-muted">{text}</Card.Subtitle>
      <Link to={page}>{interaction}</Link>
    </Card.Body>
  </Card>
);

export default HomeCard;
