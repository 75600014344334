import React, { Component } from 'react';
import ListItem from './ListItem';

// STYLING
import '../../../../../Users/Users.css';

class RouteIdDrilldownComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recentMessages: [],
    };
  }

  componentDidMount() {
    this.getRouteIdDrilldown();
  }

  getRouteIdDrilldown = async () => {
    let recentMessages = await this.props.getRouteIdDrilldown(
      this.props.selectedRouteId
    );
    this.setState({ recentMessages: recentMessages });
  };

  generateContent = () => {
    return (
      <div className="visible-desktop">
        {this.generateBackButton()}
        <h5>{this.props.selectedRouteId}</h5>
        {this.generateTable()}
      </div>
    );
  };

  generateTable = () => {
    let recentMessages = this.state.recentMessages;
    if (recentMessages && recentMessages.length > 0) {
      return (
        <div
          key="recentMessagesByRouteId"
          className="recentMessagesByRouteId table"
        >
          <table width="100%">
            <thead>
              <tr className="tableTop column-right">
                <th>Time</th>
                <th>Link</th>
                <th>TO</th>
                <th>FROM</th>
                <th>SMSID</th>
                <th>Result (ms)</th>
              </tr>
            </thead>
            <tbody>{this.generateTableBody()}</tbody>
          </table>
        </div>
      );
    } else {
      return <p>Loading...</p>;
    }
  };

  /*
   Generates the body of the Table/List to display all Provider data
 */
  generateTableBody = () => {
    let rows = [];
    let { recentMessages } = this.state;
    for (let i = 0; i < recentMessages.length; i++) {
      rows.push(
        <ListItem
          key={recentMessages[i].routeId}
          id={recentMessages[i].routeId}
          date={recentMessages[i].date}
          routeId={recentMessages[i].routeId}
          destTn={recentMessages[i].destTn}
          originTn={recentMessages[i].originTn}
          smsId={recentMessages[i].smsId}
          totalMessageProcessing={recentMessages[i].totalMessageProcessing}
        />
      );
    }
    return rows;
  };

  /**
   * Generates back button to go back to the Stats page.
   * @returns button component (as a p tag)
   */
  generateBackButton = () => {
    return (
      <p onClick={() => this.props.backToMuxIdDrilldown()} className="backLink">
        <i className="material-icons">keyboard_arrow_left</i>
        <span>Back</span>
      </p>
    );
  };

  /* renders the component */
  render() {
    return this.generateContent();
  }
}

export default RouteIdDrilldownComponent;
