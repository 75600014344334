/*
TraceComponent.js - Trace Log Component
*/

// NPM MODULES
import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Device from './Device';
import Country from './Country';

// FORMATTED MESSAGES
import AdminTabs from '../index';

export default class ServiceConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'device',
    };
  }

  generateTabs() {
    const { tab } = this.state;

    return (
      <Tabs
        activeKey={tab}
        onSelect={(key) => {
          this.setState({
            tab: key,
          });
        }}
      >
        <Tab eventKey="device" title="Device">
          <br></br>
          <Device {...this.props} />
        </Tab>
        <Tab eventKey="country" title="Country">
          <br></br>
          <Country {...this.props} />
        </Tab>
      </Tabs>
    );
  }

  render() {
    const { accessLevel, accessType } = this.props;
    if (accessLevel === '3' && accessType === '3') {
      return (
        <AdminTabs accessLevel={accessLevel} accessType={accessType}>
          <h3>Service Config</h3>
          <br></br>
          {this.generateTabs()}
        </AdminTabs>
      );
    }
  }
}
