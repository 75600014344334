import React from 'react';

const ListItem = ({
  id,
  onClick,
  name = '',
  email = '',
  number = '',
  status = '',
  msgmax = '',
  version = '',
  centre = '',
  ...props
}) => (
  <tr key={id} id={id} className="table">
    <td className="medium textLeft">{name}</td>
    <td className="medium textLeft">{email}</td>
    <td className="medium textLeft">{number}</td>
    <td className="medium textLeft">{status}</td>
    <td className="medium textLeft">{msgmax}</td>
    {/* <td className='medium'>{version}</td> */}
  </tr>
);

export default ListItem;
