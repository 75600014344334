import React, { Component } from 'react';
import ListItem from './ListItem';
import formattedMessages from './FormattedMessages';
// STYLING
import '../../../../Users/Users.css';

class SmsDrilldownComponent extends Component {
  constructor(props) {
    super(props);

    /*
        expects props:
        - selectedEmailID: The selected email ID for the drilldown
        - backToRecent: A function that sets the selectedEmailID for parent back to null
    */

    this.state = {
      trueDate: false,
      values: [],
      selectedSmsId: null,
      selectedResult: null,
    };
  }

  componentDidMount() {
    this.getSMSValues();
  }

  /**
   * Handles the input for the checkbox regarding the dates format (true or relative).
   * @param {Event} event
   */
  handleInputChange(event) {
    this.setState({ trueDate: event.target.checked });
  }

  /** Gets values for the selected email ID. */
  getSMSValues = async () => {
    let values = await this.props.getSmsIdDrilldownMessages(
      this.props.selectedSmsId
    );
    console.log(values);
    this.setState({ values: values });
  };

  /**
   * Generates content for drilldown component.
   * @returns
   */
  generateContent = () => {
    return (
      <div className="visible-desktop">
        <br />
        {this.generateBackButton()}
        <h5>SMS ID: {this.props.selectedSmsId}</h5>
        {this.generateTable()}
      </div>
    );
  };

  /**
   * Generates the table that displays the logs for the drilldown
   * @returns
   */
  generateTable = () => {
    if (this.state.values.length > 0) {
      return (
        <div key="LogList" className="table">
          <h6>{this.state.values[0].customer_user_email}</h6>
          <br />
          <table width="100%" className="table-middle">
            <thead>
              <tr>
                <td
                  className="tableTop column-left column-right"
                  style={{
                    width: '200px',
                    marginLeft: '5px',
                  }}
                >
                  {formattedMessages.dateText}
                </td>
                <td
                  className="tableTop column-left column-right"
                  style={{ marginLeft: '5px' }}
                >
                  {formattedMessages.relativeTimeText}
                </td>
                <td
                  className="tableTop column-left column-right"
                  style={{ marginLeft: '5px' }}
                >
                  {formattedMessages.resultText}
                </td>
              </tr>
            </thead>
            <tbody>{this.generateTableBody()}</tbody>
          </table>
        </div>
      );
    } else {
      return <p>Loading...</p>;
    }
  };

  setSelectedResult(id) {
    console.log(id);
    if (this.state.selectedResult === id) {
      return this.setState({
        selectedResult: null,
      });
    }
    return this.setState({
      selectedResult: id,
    });
  }

  /**
   * Generates the body of the table to display all drilldown data
   * @returns array of ListItem
   */
  generateTableBody = () => {
    let rows = [];
    let { values, trueDate, selectedResult } = this.state;
    let startDate = null;
    for (let i = values.length - 1; i >= 0; i--) {
      if (!startDate) startDate = new Date(values[values.length - 1].log_time);
      rows.push(
        <ListItem
          trueDate={trueDate}
          key={values[i].trace_log_id}
          id={values[i].trace_log_id}
          date={values[i].date}
          dateRelative={new Date(values[i].log_time) - startDate}
          logLevel={values[i].log_level}
          routeName={values[i].route_name}
          type={values[i].program_name}
          logFunction={values[i].process_name}
          result={values[i].log_message}
          collapse={values[i].trace_log_id === selectedResult}
          jsonObject={values[i]}
          onClickResult={(e) => this.setSelectedResult(e)}
        />
      );
    }
    return rows;
  };

  /**
   * add selected sms id to state
   * @returns button component (as a p tag)
   */
  drilldownSmsId = async (e) => {
    await this.setState({
      selectedSmsId: e,
    });
    this.getSMSValues();
  };

  /**
   * Generates back button to go back to the Recent logs page.
   * @returns button component (as a p tag)
   */
  generateBackButton = () => {
    return (
      <p onClick={() => this.props.backToEmailDrilldown()} className="backLink">
        <i className="material-icons">keyboard_arrow_left</i>
        <span>Back</span>
      </p>
    );
  };

  /** renders the component */
  render() {
    return this.generateContent();
  }
}

export default SmsDrilldownComponent;
