// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

// FORMATTED MESSAGES
import formattedMessages from '../FormattedMessages';

class Manage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delete: false,
      list: 'personal',
      deleteResult: '',
    };
  }

  handleModal = (selected) => {
    const open = this.state.delete ? false : true;
    this.setState({
      list: selected,
      delete: open,
    });
    return;
  };

  /*
    Handles when the Delete All button is clicked for either the Personal
    or Global Contacts list.
  */
  handleDeleteAll = async () => {
    let result = await this.props.handleDeleteAll(this.state.list);

    return this.setState({
      deleteResult: result ? 'Delete Successful' : 'Delete Failed',
      delete: false,
    });
  };

  /*
    Displays a success message after deleting all Contacts from a given list type.
  */
  displayDeleteResult = (list) => {
    if (this.state.deleteResult && list === this.state.list) {
      return <span>{this.state.deleteResult}</span>;
    }
    return null;
  };

  render() {
    let { accessLevel, accessType } = this.props;
    // If user is customer user, make sure there's no "Delete Global Contact" button
    // AccessLevel: base user = -1, Sales Agent = 0, Help desk rep = 1, Accounts & reports = 2, admin = 3
    // AccessType: base user = 0, customer admin = 1, provider admin = 2, god admin = 3
    if (accessLevel <= '0' || accessType === '0') {
      return (
        <Fragment
          tab="commission"
          accessLevel={accessLevel}
          accessType={accessType}
        >
          {/*<p onClick={this.props.callBack} className='backLink' >*/}
          {/*  <i className='material-icons'>keyboard_arrow_left</i>*/}
          {/*  <span>Back</span>*/}
          {/*</p>*/}
          <h4>{formattedMessages.statistics}</h4>
          <br />
          <Table className="tg table">
            <tbody>
              <tr>
                <th className="tg-0lax">{formattedMessages.statistics}</th>
                <th className="tg-baqh">{formattedMessages.globalListText}</th>
                <th className="tg-baqh">
                  {formattedMessages.personalListText}
                </th>
                <th className="tg-baqh">
                  {formattedMessages.globalBlacklistText}
                </th>
              </tr>
              <tr>
                <td className="tg-0lax">
                  {formattedMessages.totalContactText}
                </td>
                <td className="tg-baqh">
                  {this.props.globalList
                    ? this.props.globalList.length
                    : formattedMessages.noData}
                </td>
                <td className="tg-baqh">
                  {this.props.personalList
                    ? this.props.personalList.length
                    : formattedMessages.noData}
                </td>
                <td className="tg-baqh">
                  {this.props.globalBlacklist
                    ? this.props.globalBlacklist.length
                    : formattedMessages.noData}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="delete-section">
            <h5>{formattedMessages.personalListText}</h5>
            {formattedMessages.deletePersonalContactsHeader}:
            {/* <Button onClick={() => this.handleModal('personal')} variant='danger' className='marginLeft marginRight'>{formattedMessages.deleteText}</Button> */}
            <Button
              onClick={() => this.handleModal('personal')}
              className="delete btn-red btn btn-danger"
            >
              {formattedMessages.deleteText}
            </Button>
            {this.displayDeleteResult('personal')}
          </div>
          <Modal show={this.state.delete} onHide={this.handleModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.list === 'personal'
                  ? formattedMessages.deletePersonalContactsHeader
                  : formattedMessages.deleteGlobalContactsHeader}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.list === 'personal'
                ? formattedMessages.deletePersonalContactsBody
                : formattedMessages.deleteGlobalContactsBody}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleModal}>
                {formattedMessages.cancelText}
              </Button>
              <Button variant="danger" onClick={this.handleDeleteAll}>
                {formattedMessages.deleteContactText}
              </Button>
            </Modal.Footer>
          </Modal>
        </Fragment>
      );
    }
    // If user is not customer user, give them access to delete Global Contact
    else {
      return (
        <Fragment>
          {/*<p onClick={this.props.callBack} className='backLink' >*/}
          {/*  <i className='material-icons'>keyboard_arrow_left</i>*/}
          {/*  <span>Back</span>*/}
          {/*</p>*/}
          <h4>{formattedMessages.statistics}</h4>
          <br />
          <Table className="tg table">
            <tbody>
              <tr>
                <th className="tg-0lax">{formattedMessages.statistics}</th>
                <th className="tg-baqh">{formattedMessages.globalListText}</th>
                <th className="tg-baqh">
                  {formattedMessages.personalListText}
                </th>
                <th className="tg-baqh">
                  {formattedMessages.globalBlacklistText}
                </th>
              </tr>
              <tr>
                <td className="tg-0lax">
                  {formattedMessages.totalContactText}
                </td>
                <td className="tg-baqh">
                  {this.props.globalList
                    ? this.props.globalList.length
                    : formattedMessages.noData}
                </td>
                <td className="tg-baqh">
                  {this.props.personalList
                    ? this.props.personalList.length
                    : formattedMessages.noData}
                </td>
                <td className="tg-baqh">
                  {this.props.globalBlacklist
                    ? this.props.globalBlacklist.length
                    : formattedMessages.noData}
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="delete-section">
            <h5>{formattedMessages.globalListText}</h5>
            {formattedMessages.deleteGlobalContactsHeader}:
            {/* <Button onClick={() => this.handleModal('global')} variant='danger' className='marginLeft marginRight'>{formattedMessages.deleteText}</Button> */}
            <Button
              onClick={() => this.handleModal('global')}
              className="delete btn-red btn btn-danger"
            >
              {formattedMessages.deleteText}
            </Button>
            {this.displayDeleteResult('global')}
          </div>
          <div className="delete-section">
            <h5>{formattedMessages.personalListText}</h5>
            {formattedMessages.deletePersonalContactsHeader}:
            {/* <Button onClick={() => this.handleModal('personal')} variant='danger' className='marginLeft marginRight'>{formattedMessages.deleteText}</Button> */}
            <Button
              onClick={() => this.handleModal('personal')}
              className="delete btn-red btn btn-danger"
            >
              {formattedMessages.deleteText}
            </Button>
            {this.displayDeleteResult('personal')}
          </div>
          <Modal show={this.state.delete} onHide={this.handleModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.list === 'personal'
                  ? formattedMessages.deletePersonalContactsHeader
                  : formattedMessages.deleteGlobalContactsHeader}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.list === 'personal'
                ? formattedMessages.deletePersonalContactsBody
                : formattedMessages.deleteGlobalContactsBody}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleModal}>
                {formattedMessages.cancelText}
              </Button>
              <Button variant="danger" onClick={this.handleDeleteAll}>
                {formattedMessages.deleteContactText}
              </Button>
            </Modal.Footer>
          </Modal>
        </Fragment>
      );
    }
  }
}

export default Manage;
