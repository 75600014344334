/*
UserReports.js - Index Component for Customer Reports

Author: Kyle Combeer (2019)
Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';

// OUR COMPONENTS
import ListComponent from './ListComponent';
import ListItem from './ListItem';
import SingleViewCustomer from './SingleViewCustomer';
import DateRangeSelector from '../../../components/DateRangeSelector';
import ReportTabs from '../index';

// NETWORKING
import OutgoingReportRequest from './OutgoingReportRequests';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

// INSTANTIATE
const outgoingReportRequest = new OutgoingReportRequest();

export default class Outgoing extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - trafficOutData: Array of Traffic Out objects with report data
    */

    this.state = {
      reportsList: null,
      data: [],
      dataExists: false,
      totals: {
        Direct: 0,
        Standard: 0,
        Replies: 0,
        Sent: 0,
        Pending: 0,
      },
      selectedData: null,
      activePage: 'list',
      fromDate: '',
      toDate: '',
    };
  }

  componentDidMount = async () => {
    if (this.props.customer && this.props.customer !== null) {
      if (this.props.outgoingData && this.props.outgoingData.length !== 0) {
        console.log('Have outgoing data:', this.props.outgoingData);
        await this.setState(
          {
            data: this.props.outgoingData.smslist,
            dataExists: true,
          },
          this.formatData
        );
      }
    }
  };

  sortList(data) {
    let newList = [].slice.call(data).sort(function (a, b) {
      if (a.device_name < b.device_name) {
        return -1;
      }
      if (a.device_name > b.device_name) {
        return 1;
      }
      return 0;
    });

    return newList;
  }

  /*
    Formats the data in a manner required for the Table
    to properly display the data
  */
  formatData = async () => {
    try {
      let data = await this.state.data.map((el) => (
        <ListItem
          key={el.device_id}
          id={el.device_id}
          deviceName={el.device_name}
          type={el.device_type}
          replyTn={el.reply_tn}
          txt={el.totals.messages_sent}
          dir={el.totals.direct_sent}
          rem={el.totals.reminders_sent}
          //  onClickName = {() => this.changePage('svCustomer', el.customer_id)}
          //  onClickDirect = {() => this.changePage('svDir', el.customer_id)}
          //  onClickStandard = {() => this.changePage('svStand', el.customer_id)}
          //  onClickReplies = {() => this.changePage('svRep', el.customer_id)}
          //  onClickSent = {() => this.changePage('svSent', el.customer_id)}
          //  onClickPending = {() => this.changePage('svPend', el.customer_id)}
        />
      ));

      return this.setState({
        reportsList: data,
      });
    } catch (e) {
      console.log('data error:', e);
    }
  };

  backButton = () => {
    return this.setState({
      activePage: 'list',
    });
  };

  /*saveSessionData = (data) => {
    console.log('step 1',data)
    this.props.saveSessionData(data)
  }*/

  /*
    Gets the object of the selected User from the data list
  */
  /*getSelectedUser = async (userId) => {
    try {
      let users = this.state.data;
      for (let i = 0; i < users.length; i++) {
        if (userId === users[i].userId) {
          return users[i].user;
        }
      }
    }
    catch(e) {
      // TODO - Handle Error
    }
  }*/

  /*
    Handles searching Outgoing Data between two dates
  */
  handleOutgoingDataSearch = async (fromDate, toDate) => {
    try {
      let result = await outgoingReportRequest.getReportData(
        fromDate,
        toDate,
        this.props.errorHandler
      );

      this.setState(
        {
          dataExists: true,
          data: result,
          fromDate: fromDate,
          toDate: toDate,
        },
        this.formatData
      );
    } catch (e) {
      // TODO - handle error
    }
  };

  // HANDLERS START
  changePage = async (page, value) => {
    // Update activePage
    return this.setState({
      activePage: page,
      selectedData: value,
    });
  };

  // HANDLERS END

  // RENDER START
  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div className="lander">
        <h3>{formattedMessages.outgoingReportsTitle}</h3>
      </div>
    );
  };

  /*
    Generates the Search Tools for querying the data
  */
  generateSearchTools = () => {
    return (
      <Fragment>
        <DateRangeSelector
          handleDateSearch={(fromDate, toDate) =>
            this.handleOutgoingDataSearch(fromDate, toDate)
          }
        ></DateRangeSelector>
        <br />
        <br />
      </Fragment>
    );
  };

  reloadPage = () => {
    window.location.reload(1);
  };

  render() {
    let { accessLevel, accessType } = this.props;

    if (accessLevel === '3' && accessType === '3') {
      switch (this.state.activePage) {
        /*case 'svPend':return (
              <SingleViewPending selectedData={this.state.selectedData} backButton={this.backButton}/>
        );
        case 'svDir':return (
              <SingleViewDirect
                user={this.state.selectedData}
                backButton={this.backButton}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
              ></SingleViewDirect>
        );
        case 'svRep':return (
              <SingleViewReplies
                user={this.state.selectedData}
                backButton={this.backButton}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
              ></SingleViewReplies>
        );
        case 'svSent':return (
              <SingleViewSent
                user={this.state.selectedData}
                backButton={this.backButton}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
              ></SingleViewSent>
        );
        case 'svStand':return (
              <SingleViewStandard
                user={this.state.selectedData}
                backButton={this.backButton}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
              ></SingleViewStandard>
        );*/
        case 'svCustomer':
          return (
            <ReportTabs
              tab="outgoing"
              accessLevel={accessLevel}
              accessType={accessType}
            >
              <SingleViewCustomer
                customerId={this.state.selectedData}
                backButton={this.backButton}
              />
            </ReportTabs>
          );
        default:
          return (
            <ReportTabs
              tab="outgoing"
              accessLevel={accessLevel}
              accessType={accessType}
            >
              <div className="Home">
                {this.generateLander()}
                {this.generateSearchTools()}
                <ListComponent
                  changePage={this.changePage}
                  list={this.state.reportsList}
                  loading={!this.state.dataExists}
                />
              </div>
            </ReportTabs>
          );
      }
    }
    return null;
  }
}
