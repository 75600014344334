import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  date: (
    <FormattedMessage
      id="attachments.Date"
      defaultMessage="Date"
    ></FormattedMessage>
  ),
  contactName: (
    <FormattedMessage
      id="attachments.ContactName"
      defaultMessage="Contact Name"
    ></FormattedMessage>
  ),
  file: (
    <FormattedMessage
      id="attachments.File"
      defaultMessage="File"
    ></FormattedMessage>
  ),
  commands: (
    <FormattedMessage
      id="attachments.Commands"
      defaultMessage="Commands"
    ></FormattedMessage>
  ),
  status: (
    <FormattedMessage
      id="attachments.Status"
      defaultMessage="Status"
    ></FormattedMessage>
  ),
  type: (
    <FormattedMessage
      id="attachment.type"
      defaultMessage="Type"
    ></FormattedMessage>
  ),
  statusDate: (
    <FormattedMessage
      id="attachments.StatusDate"
      defaultMessage="Status Date"
    ></FormattedMessage>
  ),
  delete: (
    <FormattedMessage
      id="attachments.Delete"
      defaultMessage="Delete"
    ></FormattedMessage>
  ),
  open: (
    <FormattedMessage
      id="attachments.Open"
      defaultMessage="Open"
    ></FormattedMessage>
  ),
  loadingText: (
    <FormattedMessage
      id="App.LoadingText"
      defaultMessage="Loading"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
