import React from 'react';

const ListItem = ({
  id,
  date,
  invoiceNo,
  name,
  COO,
  paidDate,
  paymentType,
  suffix,
  setupFees,
  licenceFees,
  messages,
  replies,
  messageFees,
  totalFees,
  gst,
  surcharge,
  total,
}) => (
  <tr key={id}>
    <td className="left medium">
      <span>{date}</span>
    </td>
    <td className="medium">
      <span>{invoiceNo}</span>
    </td>
    <td className="medium">
      <span>{name}</span>
    </td>
    <td className="medium">
      <span>{COO}</span>
    </td>
    <td className="medium">
      <span>{paidDate}</span>
    </td>
    <td className="medium">
      <span>{paymentType}</span>
    </td>
    <td className="medium">
      <span>{suffix}</span>
    </td>
    <td className="column-left medium">
      <span>{setupFees}</span>
    </td>
    <td className="column-left medium">
      <span>{licenceFees}</span>
    </td>
    <td className="column-left medium">
      <span>{messages}</span>
    </td>
    <td className="column-left medium">
      <span>{replies}</span>
    </td>
    <td className="column-left medium">
      <span>{messageFees}</span>
    </td>
    <td className="column-left medium">
      <span>{totalFees}</span>
    </td>
    <td className="column-left medium">
      <span>{gst}</span>
    </td>
    <td className="column-left medium">
      <span>{surcharge}</span>
    </td>
    <td className="column-left medium">
      <span>{total}</span>
    </td>
  </tr>
);

export default ListItem;
