import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  accountText: (
    <FormattedMessage
      id="AccountIndex.AccountLink"
      defaultMessage="Account"
    ></FormattedMessage>
  ),
  invoicesText: (
    <FormattedMessage
      id="ReportsIndex.InvoicesLink"
      defaultMessage="Invoices"
    ></FormattedMessage>
  ),
  planText: (
    <FormattedMessage
      id="ReportsIndex.PlanLink"
      defaultMessage="Plan"
    ></FormattedMessage>
  ),
  billingsSummary: (
    <FormattedMessage
      id="ReportsIndex.BillingsSummary"
      defaultMessage="Billing Summary"
    ></FormattedMessage>
  ),
  commissionReport: (
    <FormattedMessage
      id="ReportsIndex.CommissionReport"
      defaultMessage="Commission Report"
    ></FormattedMessage>
  ),
  newCustomerReport: (
    <FormattedMessage
      id="ReportsIndex.NewCustomerReport"
      defaultMessage="New Customer Report"
    ></FormattedMessage>
  ),
  expiredCardsReport: (
    <FormattedMessage
      id="ReportsIndex.ExpiredCardsReport"
      defaultMessage="Expired Cards Report"
    ></FormattedMessage>
  ),
  unpaidAccounts: (
    <FormattedMessage
      id="ReportsIndex.UnpaidAccounts"
      defaultMessage="Unpaid Accounts"
    ></FormattedMessage>
  ),
  loading: (
    <FormattedMessage
      id="Global.Loading"
      defaultMessage="Loading..."
    ></FormattedMessage>
  ),
  showFilter: (
    <FormattedMessage
      id="ReportsIndex.ShowFilter"
      defaultMessage="Show Filter"
    ></FormattedMessage>
  ),
  noResultsFound: (
    <FormattedMessage
      id="Global.NoResultsFound"
      defaultMessage="No Results Found."
    ></FormattedMessage>
  ),
  paymentMethod: (
    <FormattedMessage
      id="Global.PaymentMethod"
      defaultMessage="Payment Method"
    ></FormattedMessage>
  ),
  purchaseMessages: (
    <FormattedMessage
      id="Global.PurchaseMessages"
      defaultMessage="Purchase Messages"
    ></FormattedMessage>
  ),
  thisInvoice: (
    <FormattedMessage id="Billing.ThisInvoice" defaultMessage="Invoice Now" />
  ),
  nextInvoice: (
    <FormattedMessage id="Billing.NextInvoice" defaultMessage="Next Invoice" />
  ),
  charge: (
    <FormattedMessage
      id="Billing.Charge"
      defaultMessage="Charge Description :"
    />
  ),
  clear: (
    <FormattedMessage id="Websms.ClearFilters" defaultMessage="Clear Filters" />
  ),
  quantity: (
    <FormattedMessage id="Billing.Quantity" defaultMessage="Quantity :" />
  ),
  rate: <FormattedMessage id="Billing.Rate" defaultMessage="Rate :" />,
  revenue: <FormattedMessage id="Billing.Revenue" defaultMessage="Revenue :" />,
  backText: (
    <FormattedMessage
      id="Billing.BackButton"
      defaultMessage="Back to Invoices"
    />
  ),
  createInvoiceAdjustment: (
    <FormattedMessage
      id="Billing.CreateInvoiceAdjustment"
      defaultMessage="Create Invoice Adjustment"
    />
  ),
  customerName: (
    <FormattedMessage
      id="Billing.CustomerName"
      defaultMessage="Customer Name :"
    />
  ),
  customerAccountNo: (
    <FormattedMessage
      id="Billing.CustomerAccountNo"
      defaultMessage="Customer Account No :"
    />
  ),
  invoiceNumber: (
    <FormattedMessage
      id="Billing.InvoiceNumber"
      defaultMessage="Invoice Number :"
    />
  ),
  invoiceDueDate: (
    <FormattedMessage
      id="Billing.InvoiceDueDate"
      defaultMessage="Invoice Due Date :"
    />
  ),
  tax: <FormattedMessage id="Billing.Tax" defaultMessage="Tax :" />,
  total: <FormattedMessage id="Billing.Total" defaultMessage="Total" />,
  save: <FormattedMessage id="Billing.Save" defaultMessage="Save" />,
  cancel: <FormattedMessage id="Billing.Cancel" defaultMessage="Cancel" />,
  saving: <FormattedMessage id="Billing.Saving" defaultMessage="Saving..." />,
  refundNow: (
    <FormattedMessage id="Billing.RefundNow" defaultMessage="Refund Now" />
  ),
  refundTotal: (
    <FormattedMessage
      id="Billing.RefundTotal"
      defaultMessage="Currently Refunded :"
    />
  ),
  invoiceTotal: (
    <FormattedMessage
      id="Billing.InvoiceTotal"
      defaultMessage="Invoice Total :"
    />
  ),
};

export default FormattedMessages;
