import React from 'react';

const ListItem = ({
  id,
  name = '',
  active = '',
  isGodAdmin = false,
  onClickName = null,
  Direct = { sent: 0, parts: 0, onClick: null },
  Standard = { sent: 0, parts: 0, onClick: null },
  Replies = { sent: 0, parts: 0, onClick: null },
  Sent = { sent: 0, parts: 0, onClick: null },
  Pending = { sent: 0, parts: 0, onClick: null },
  onClickStatus = null,
}) => (
  <tr key={'list' + id} id={id} className="">
    <td className="medium textLeft" name="name">
      <span className="blue" onClick={onClickName}>
        {name}
      </span>
    </td>
    {_generateStatusItem(isGodAdmin, onClickStatus, active)}
    <td className="medium" name="Direct">
      <span className="blue" onClick={Direct.onClick}>
        {Direct.sent + ` (${Direct.parts})`}
      </span>
    </td>
    <td className="medium" name="Standard">
      <span className="blue" onClick={Standard.onClick}>
        {Standard.sent + ` (${Standard.parts})`}
      </span>
    </td>
    <td className="medium column-right" name="Replies">
      <span className="blue" onClick={Replies.onClick}>
        {Replies.sent + ` (${Replies.parts})`}
      </span>
    </td>
    <td className="medium" name="Sent">
      <span className="blue" onClick={Sent.onClick}>
        {Sent.sent + ` (${Sent.parts})`}
      </span>
    </td>
    <td className="medium" name="Pending">
      <span className="blue" onClick={Pending.onClick}>
        {Pending.sent + ` (${Pending.parts})`}
      </span>
    </td>
  </tr>
);

const _generateStatusItem = (isGodAdmin, onClickStatus, active) => {
  if (!isGodAdmin) {
    return (
      <td className="medium column-right" name="active">
        {active}
      </td>
    );
  }

  return (
    <td className="medium column-right" name="active">
      <span className="blue" onClick={onClickStatus}>
        {active}
      </span>
    </td>
  );
};

export default ListItem;
