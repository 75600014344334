/*
  UserRequest.js - Handles WebSMS Users

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NETWORK
import RequestRabbit from '../../RequestRabbit';

// CONFIG
import config from '../../../Config';

// INSTANTIATE
const handleRequest = new RequestRabbit();

export default class UserRequest {
  /*
    Fetch all Customer Users for the calling Customer
  */
  getUsers = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/users/`;
      const header = {
        'Content-Type': 'application/json',
      };
      let options = {
        method: 'GET',
        headers: header,
      };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      //Error catching
      errorHandler(e.message);
    }
  };

  /*
    Get user info
  */
  getUser = async (data, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/users/user`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'GET',
        headers: headers,
      };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
   Get Dedicated Numbers
   */
  getDedicatedNumbers = async (customerUserId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/users/number/${customerUserId}`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'GET',
        headers: headers,
      };

      console.log('getDedicatedNumbers called');
      console.log(customerUserId);

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Fetches campaign data for the customer.
  */
  getCustomerCampaignData = async (customerId, errorHandler) => {
    try {
      const url =
        `${config.baseServerURL}/admin/customer/campaigns/` + customerId;
      const options = {
        method: 'GET',
      };

      // Make Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      return errorHandler(e);
    }
  };

  /*
    Creates a new Customer User for the Customer
  */
  createUser = async (data, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/users`;
      const header = {
        'Content-Type': 'application/json',
      };
      const body = JSON.stringify(data);
      const options = {
        method: 'POST',
        headers: header,
        body: body,
      };
      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Creates a request to update a specific User.

    Inputs:
      - data: A JSON object containing updated details of the Customer User
      - errorHandler: A function to be called if an error occurs
  */
  updateUser = async (customerUserId, data, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/users/${customerUserId}`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Cancels the specified Customer User
  */
  cancelUser = async (userId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/users/${userId}`;
      const options = {
        method: 'DELETE',
      };

      // Request
      await handleRequest.request(url, options, errorHandler);
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Fetches all Cost Centres for a given Customer's id
  */
  getCentres = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/centre/`;
      let options = { method: 'GET' };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Calls TWILIO api to request 20 possible numbers for a given Country Code and Prefix
  */

  searchAvailableNumbers = async (data, errorHandler) => {
    try {
      ///v1/users/number/search?countryCode=US&areaCode=856
      let url = `${config.baseServerURL}/users/number/search?countryCode=${data.country}`;

      if (data.prefix !== undefined) {
        url += `&areaCode=${data.prefix}`;
      }
      if (data.prefix !== undefined) {
        url += `&gateway=${data.gateway}`;
      }
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'GET',
        headers: headers,
      };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  provisionNumbers = async (data, errorHandler) => {
    try {
      //let {numbers, templateDeviceId, customerUserId} = data;
      console.log('provisionTwilioNumbers called');
      let url = `${config.baseServerURL}/users/number/provision`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      console.log('options:', options);

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      console.log('responseJSON:', responseJSON);
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  releaseTwilioNumbers = async (data, errorHandler) => {
    try {
      //let {numbers, templateDeviceId, customerUserId} = data;
      console.log('releaseTwilioNumbers called');
      let url = `${config.baseServerURL}/users/number/release`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      console.log('JSON.stringify(data):', JSON.stringify(data));
      console.log('options:', options);

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      console.log('response', response);
      return response;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  changeCampaignForNumbers = async (data, customerId, errorHandler) => {
    try {
      console.log('changeCampaignForNumbers called');
      let url = `${config.baseServerURL}/users/number/change`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ numbers: data, customerId: customerId }),
      };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      console.log('response', response);
      return response;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
