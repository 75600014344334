/*
UserReports.js - Index Component for Customer Reports

Author: Kyle Combeer (2019)
Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap';

// COMPONENTS
import ListComponent from './ListComponent';
import ListItem from './ListItem';
import SingleViewCustomer from './SingleViewCustomer';
import DateRangeSelector from '../../../components/DateRangeSelector';
import ReportTabs from '../index';

// NETWORKING
import CustomerReportRequest from './CustomerReportRequests';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

// INSTANTIATE
const customerReportRequest = new CustomerReportRequest();

// export default class Users extends Component {
export default class Users extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - customerData: Array of Customers with report data
    */

    this.state = {
      smsList: null,
      data: [],
      activeCustomers: [],
      allCustomers: [],
      dataExists: false,
      totals: {
        Direct: 0,
        Standard: 0,
        Replies: 0,
        Sent: 0,
        Pending: 0,
      },
      selectedData: null,
      activePage: 'list',
      fromDate: '',
      toDate: '',
      timeRange: null,
      displayLetters: [],
      filterLetter: '',
      showAll: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.customer && this.props.customer !== null) {
      console.log('Have props customerData?', this.props.customerData);
      if (this.props.customerData && this.props.customerData.length > 0) {
        console.log('Should not be here');
        await this.setState({
          data: this.props.customerData,
          dataExists: true,
        });
        return this.formatData();
      }
    }
  };

  sortList(data) {
    let newList = [].slice.call(data).sort(function (a, b) {
      if (a.customer_name < b.customer_name) {
        return -1;
      }
      if (a.customer_name > b.customer_name) {
        return 1;
      }
      return 0;
    });

    return newList;
  }

  /*
    Formats the data in a manner required for the Table
    to properly display the data
  */
  formatData = async () => {
    try {
      let total = {
        Direct: 0,
        Standard: 0,
        Replies: 0,
        Sent: 0,
        Pending: 0,
        DirectParts: 0,
        StandardParts: 0,
        RepliesParts: 0,
        SentParts: 0,
        PendingParts: 0,
        Users: 0,
      };
      let letterMap = {};
      let letters = [];
      let { data } = this.state;

      let CleanedData = data
        .filter((el) => {
          if (
            this.state.displayLetters.length === 0 &&
            !letterMap[el.customer_name.charAt(0)]
          ) {
            letterMap[el.customer_name.charAt(0)] = true;
            letters.push(`${el.customer_name.charAt(0)}`);
          }

          if (
            this.state.filterLetter === '' ||
            this.state.filterLetter.toLowerCase() ===
              el.customer_name.charAt(0).toLowerCase()
          ) {
            return true;
          }
        })
        .map((el) => {
          return (
            <ListItem
              key={'key_' + 0}
              id={0}
              name={el.customer_name}
              active={el.customer_status_name}
              type={el.customer_type_name}
              customerClass={el.customer_class_name}
              userCount={el.userCount}
              Direct={{
                sent: el.direct_sent,
                parts: el.direct_parts,
                onClick: () => this.changePage('svDir', el.customer_id),
              }}
              Standard={{
                sent: el.messages_sent,
                parts: el.messages_parts,
                onClick: () => this.changePage('svStand', el.customer_id),
              }}
              Replies={{
                sent: el.replies_sent,
                parts: el.replies_parts,
                onClick: () => this.changePage('svRep', el.customer_id),
              }}
              Sent={{
                sent: el.reminders_sent,
                parts: el.reminders_parts,
                onClick: () => this.changePage('svSent', el.customer_id),
              }}
              Pending={{
                sent: el.reminders_pending,
                parts: el.reminders_pending_parts,
                onClick: () => this.changePage('svPend', el.customer_id),
              }}
              onClickName={() => this.changePage('svCustomer', el.customer_id)}
            />
          );
        });

      this.state.data.forEach((el) => {
        if (
          this.state.filterLetter === '' ||
          this.state.filterLetter.toLowerCase() ===
            el.customer_name.charAt(0).toLowerCase()
        ) {
          total.Direct = total.Direct + parseInt(el.direct_sent);
          total.Standard = total.Standard + parseInt(el.messages_sent);
          total.Replies = total.Replies + parseInt(el.replies_sent);
          total.Sent = total.Sent + parseInt(el.reminders_sent);
          total.Pending = total.Pending + parseInt(el.reminders_pending);
          total.DirectParts = total.DirectParts + parseInt(el.direct_parts);
          total.StandardParts =
            total.StandardParts + parseInt(el.messages_parts);
          total.RepliesParts = total.RepliesParts + parseInt(el.replies_parts);
          total.SentParts = total.SentParts + parseInt(el.reminders_parts);
          total.PendingParts =
            total.PendingParts + parseInt(el.reminders_pending_parts);
          total.Users = total.Users + parseInt(el.userCount);
        }
      });

      let updatedState = {
        smsList: CleanedData,
        totals: total,
      };
      if (letters.length > 0) {
        letters.sort();
        updatedState.displayLetters = letters;
      }
      return this.setState(updatedState);
    } catch (e) {
      console.log('data error:', e);
    }
  };

  backButton = () => {
    return this.setState({
      activePage: 'list',
    });
  };

  handleLetterFilter = async (letter) => {
    console.log('filter click with letter:', letter);
    await this.setState(
      {
        filterLetter: letter,
      },
      this.formatData
    );
  };

  /*
    Handles searching User Data between two dates
  */
  handleUserDataSearch = async (fromDate, toDate, timeRange) => {
    this.setState(
      {
        dataExists: false,
        loading: true,
        data: [],
        activeCustomers: [],
        allCustomers: [],
      },
      async () => {
        let searchParams = {};
        searchParams.fromDate = fromDate;
        searchParams.toDate = toDate;
        searchParams.timeRangeStart = fromDate;
        searchParams.timeRangeEnd = toDate;
        searchParams.timeRange = timeRange;
        console.log('searchParams', searchParams);

        let result = await customerReportRequest.getReportData(
          searchParams,
          this.props.errorHandler
        );
        let data;
        if (this.state.showAll) {
          data = result.finalResults;
        } else {
          data = result.activeResults;
        }

        this.setState(
          {
            dataExists: true,
            data: data,
            activeCustomers: result.activeResults,
            allCustomers: result.finalResults,
            fromDate: fromDate,
            toDate: toDate,
            timeRange: timeRange,
            filterLetter: '', // reset filter
            displayLetters: [], // reset filter
          },
          this.formatData
        );
      }
    );
  };

  // HANDLERS START
  changePage = async (page, value) => {
    // Update activePage
    return this.setState({
      activePage: page,
      selectedData: value,
    });
  };

  // HANDLERS END

  // RENDER START
  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div className="lander">
        <h3>{formattedMessages.customerReportsTitle}</h3>
      </div>
    );
  };

  /*
    Generates the Search Tools for querying the data
  */
  generateSearchTools = () => {
    return (
      <Fragment>
        <DateRangeSelector
          handleDateSearch={(fromDate, toDate, timeRange) =>
            this.handleUserDataSearch(fromDate, toDate, timeRange)
          }
        ></DateRangeSelector>
        <br />
        <br />
      </Fragment>
    );
  };
  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    console.log('handleInputChange event name:', name);
    console.log('handleInputChange event value:', value);

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === 'showAll' && this.state.dataExists) {
          if (value === false) {
            console.log('activeCustomers', this.state.activeCustomers);
            this.setState(
              { data: this.state.activeCustomers },
              this.formatData
            );
          } else {
            this.setState({ data: this.state.allCustomers }, this.formatData);
          }
        }
      }
    );
  }

  generateFirstLetterFilter = () => {
    if (this.props.userData) return null;
    if (
      this.state.displayLetters !== undefined &&
      this.state.displayLetters !== 'undefined'
    ) {
      let letterHtml = this.state.displayLetters.map((el) => {
        return (
          <span key={el + '1'}>
            <span
              key={el}
              className="click-object"
              onClick={() => this.handleLetterFilter(el)}
            >
              {el}
            </span>{' '}
          </span>
        );
      });
      return (
        <div>
          <table width="100%" className="no-table-row-background">
            <tbody>
              <tr valign="middle">
                <td>{letterHtml}</td>
                <td align="right" style={{ width: '200px' }}>
                  <Form>
                    <Form.Check
                      type="checkbox"
                      name="showAll"
                      label={'Show All'}
                      defaultChecked={this.state.showAll}
                      onChange={(e) => this.handleInputChange(e)}
                    ></Form.Check>
                  </Form>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };

  reloadPage = () => {
    window.location.reload(1);
  };

  render() {
    let { accessLevel, accessType } = this.props;

    if (accessLevel === '3' && accessType === '3') {
      switch (this.state.activePage) {
        /*case 'svPend':return (
              <SingleViewPending selectedData={this.state.selectedData} backButton={this.backButton}/>
        );
        case 'svDir':return (
              <SingleViewDirect
                user={this.state.selectedData}
                backButton={this.backButton}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
              ></SingleViewDirect>
        );
        case 'svRep':return (
              <SingleViewReplies
                user={this.state.selectedData}
                backButton={this.backButton}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
              ></SingleViewReplies>
        );
        case 'svSent':return (
              <SingleViewSent
                user={this.state.selectedData}
                backButton={this.backButton}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
              ></SingleViewSent>
        );
        case 'svStand':return (
              <SingleViewStandard
                user={this.state.selectedData}
                backButton={this.backButton}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
              ></SingleViewStandard>
        );*/
        case 'svCustomer':
          return (
            <ReportTabs
              tab="customer"
              accessLevel={accessLevel}
              accessType={accessType}
            >
              <SingleViewCustomer
                accessLevel={accessLevel}
                accessType={accessType}
                customerId={this.state.selectedData}
                backButtonClick={this.backButton}
                errorHandler={this.props.errorHandler}
              />
            </ReportTabs>
          );
        default:
          return (
            <ReportTabs
              tab="customer"
              accessLevel={accessLevel}
              accessType={accessType}
            >
              <div className="Home">
                {this.generateLander()}
                {this.generateSearchTools()}
                <ListComponent
                  changePage={this.changePage}
                  totals={this.state.totals}
                  list={this.state.smsList}
                  loading={!this.state.dataExists}
                  filter={this.generateFirstLetterFilter}
                />
              </div>
            </ReportTabs>
          );
      }
    }
    return null;
  }
}
