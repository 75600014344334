import React from 'react';

const EmailError = ({
  id,
  onClick,
  providerName,
  customerName,
  type,
  customerClass,
  status,
  destEmail,
  body,
  ...props
}) => (
  <tr key={id} id={id} onClick={onClick}>
    <td className="medium textLeft">{providerName}</td>
    <td className="medium textLeft">{customerName}</td>
    <td className="medium textLeft">{type}</td>
    <td className="medium textLeft">{customerClass}</td>
    <td className="medium textLeft">{status}</td>
    <td className="medium textLeft">{destEmail}</td>
    <td className="medium textLeft">{body}</td>
    {/*<td className="icon"><i id={id + "icon"} className="material-icons floatRight">keyboard_arrow_right</i></td>*/}
  </tr>
);

export default EmailError;
