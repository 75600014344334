/*
  List.js - Admin Carrier Devices List

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
// import {Form, Table, Button, Col, Row} from 'react-bootstrap';
import { Table, Button } from 'react-bootstrap';

// COMPONENTS
import ListItem from './ListItem';
import DeviceListItem from './DeviceListItem';

// I18N
//import formattedMessages from './FormattedMessages';

// STYLES
//import '../Admin.css';

// NETWORK
import CarrierRequest from '../CarrierRequest';

// INSTANTIATION
const carrierRequest = new CarrierRequest();

export default class ServiceCarrierDeviceList extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - carrier: A single carrier object (optional)
        - country: Country to create or edit a Carrier against
        - deviceId: (Optional) If this is passed in, returns a list of Carrier Devices associated
        with that deviceId.  This is used for generating a list in Device Single.js view
        - backButtonClick: Function to handle Back Button click
        - addNewCarrierDevice: Function to handle Add New Button click
        - showSingleCarrierDevice: Function to handle viewing a single Carrier Device
        - errorHandler: A function to pass errors to
    */

    this.state = {
      isEditing: false,
      devices: [],
      edits: {},
      carrierDevices: null,
    };
  }

  async componentDidMount() {
    let { country, carrier, errorHandler, deviceId } = this.props;

    // Get Carrier Devices for Device
    if (deviceId && typeof deviceId !== 'undefined') {
      const carrierDevices = await carrierRequest.getDeviceCarrierDevices(
        deviceId,
        errorHandler
      );
      console.log('carrierDevices:', carrierDevices);
      return this.setState({
        carrierDevices: carrierDevices,
      });
    }

    if (country && carrier) {
      let devices = await carrierRequest.getCarrierDevices(
        country.country_code_id,
        carrier.carrier_id,
        errorHandler
      );
      console.log('Got devices:', devices);
      return this.setState({
        devices: devices,
      });
    }
  }

  generateTable() {
    let htmlRows = [];
    let { deviceId } = this.props;
    let { devices } = this.state;

    if (deviceId) {
      return this.generateDeviceCarrierDeviceTable();
    }

    if (devices.length === 0) {
      return <div>No Carrier Devices</div>;
    }

    for (let i = 0; i < devices.length; i++) {
      htmlRows.push(
        <ListItem
          key={devices[i].carrier_device_id}
          id={devices[i].carrier_device_id}
          deviceName={devices[i].device_name}
          priority={devices[i].priority}
          device={devices[i]}
          onClickName={(e) => this.props.showSingleCarrierDevice(e)}
        ></ListItem>
      );
    }

    return (
      <Table className="table-new">
        <thead>
          <tr>
            <td className="medium tableTop column-left">Device</td>
            <td className="medium tableTop column-left">Priority</td>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  }

  generateDeviceCarrierDeviceTable = () => {
    let { carrierDevices } = this.state;
    let htmlRows = [];

    if (!carrierDevices) return 'Loading...';

    if (carrierDevices.length === 0) return 'No Carrier Devices found.';

    for (let i = 0; i < carrierDevices.length; i++) {
      htmlRows.push(
        <DeviceListItem
          key={carrierDevices[i].carrier_device_id}
          id={carrierDevices[i].carrier_device_id}
          carrierName={carrierDevices[i].carrier_name}
          device={carrierDevices[i]}
          onClickName={(e) => this.props.showSingleCarrierDevice(e)}
        ></DeviceListItem>
      );
    }
    return (
      <Table className="table-new">
        <thead>
          <tr>
            <td className="medium tableTop column-left textLeft">
              Provider ID
            </td>
            <td className="medium tableTop column-left textLeft">Carrier</td>
            <td className="medium tableTop column-left textLeft">Priority</td>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  };

  render() {
    return (
      <div key="CarrierDeviceList" className="table">
        <br />
        <h4 className="float-left">List of Carrier Devices</h4>
        <Button
          className="btn add-new-template float-right"
          onClick={this.props.addNewCarrierDevice}
        >
          Add New
        </Button>
        <br />
        <br />
        {this.generateTable()}
      </div>
    );
  }
}
