import React from 'react';

const DeviceListItem = ({
  id,
  carrierName = '',
  onClickName = null,
  device = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td>
      <span>{device.provider_id}</span>
    </td>
    <td className="column-right column-left" name="name">
      <span className="blue" onClick={() => onClickName(device)}>
        {carrierName}
      </span>
    </td>
    <td className="column-right">
      <span>{device.priority}</span>
    </td>
  </tr>
);

export default DeviceListItem;
