import React from 'react';
import { Link } from 'react-router-dom';

const ListItem = ({
  id,
  invoiceNo,
  accountName,
  status,
  failureReason,
  ...props
}) => (
  <tr key={id}>
    <td className="left medium">
      <span
        className="blue"
        onClick={() =>
          window.history.pushState(
            invoiceNo,
            'Invoice:' + invoiceNo,
            '/billing/invoiceshow/' + invoiceNo
          )
        }
      >
        <Link to={'/billing/invoiceshow/' + invoiceNo}>{invoiceNo}</Link>
      </span>
    </td>
    <td className="medium">
      <span
        className="blue"
        onClick={() =>
          window.history.pushState(
            id,
            'Customer:' + id,
            '/admin/customer/' + id
          )
        }
      >
        <Link
          to={{
            pathname: '/admin/customer/' + id,
            state: { fromUnpaid: true, id: id },
          }}
        >
          {accountName}
        </Link>
      </span>
    </td>
    <td className="medium">
      <span>{status}</span>
    </td>
    <td className="medium">
      <span>{failureReason}</span>
    </td>
  </tr>
);

export default ListItem;
