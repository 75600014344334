import React, { Component } from 'react';
import ListItem from './ListItem';
//import formattedMessages from './FormattedMessages'; // TODO: formatted messages for this page?
import RouteNameDrilldownComponent from './RouteNameDrilldownComponent';
import RouteIdDrilldownComponent from './RouteIdDrilldownComponent';
// NETWORK
import AdminRequest from '../../../AdminRequest';
// STYLING
import '../../../../Users/Users.css';
const adminRequest = new AdminRequest();

class MuxIdDrilldownComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statsByMuxId: [],
    };
  }

  componentDidMount() {
    this.getValues();
  }

  getValues = async () => {
    let statsByMuxId = await this.props.getMuxIdDrilldown(
      this.props.selectedMuxID,
      this.props.isTwilio
    );
    this.setState({ statsByMuxId: statsByMuxId });
  };

  getRouteNameDrilldown = async (routeName) => {
    try {
      let daemonConfig = await adminRequest.getDaemonConfigByRouteName(
        routeName,
        this.props.errorHandler
      );
      return daemonConfig;
    } catch (e) {
      console.log(e);
    }
  };

  getRouteIdDrilldown = async (routeId) => {
    try {
      let recentMessages = await adminRequest.getRecentMessagesByRouteId(
        routeId,
        this.props.errorHandler
      );
      return recentMessages;
    } catch (e) {
      console.log(e);
    }
  };

  drilldownRouteName = (e) => {
    this.setState({
      selectedRouteName: e,
    });
  };
  drilldownRouteId = (e) => {
    this.setState({
      selectedRouteId: e,
    });
  };

  backToMuxIdDrilldown = () => {
    this.setState({
      selectedRouteName: null,
      selectedRouteId: null,
    });
  };

  generateContent = () => {
    return (
      <div className="visible-desktop">
        <br />
        <h5>{this.props.selectedMuxId}</h5>
        {this.generateTable()}
      </div>
    );
  };

  generateTable = () => {
    if (this.state.selectedRouteName) {
      return (
        <div>
          <RouteNameDrilldownComponent
            selectedRouteName={this.state.selectedRouteName}
            backToMuxIdDrilldown={this.backToMuxIdDrilldown}
            getRouteNameDrilldown={this.getRouteNameDrilldown}
          />
        </div>
      );
    } else if (this.state.selectedRouteId) {
      return (
        <div>
          <RouteIdDrilldownComponent
            selectedRouteId={this.state.selectedRouteId}
            backToMuxIdDrilldown={this.backToMuxIdDrilldown}
            getRouteIdDrilldown={this.getRouteIdDrilldown}
          />
        </div>
      );
    } else {
      if (this.state.statsByMuxId && this.state.statsByMuxId.length > 0) {
        return (
          <div>
            <p onClick={() => this.props.backToStats()} className="backLink">
              <i className="material-icons">keyboard_arrow_left</i>
              <span>Back</span>
            </p>
            <div key="statsListByMuxId" className="statsListByMuxId table">
              <h5>
                Detailed Stats -{' '}
                {this.state.statsByMuxId[0]
                  ? this.state.statsByMuxId[0].mux_id.S
                  : ''}
                {this.props.isTwilio ? '(Twilio)' : ''}
              </h5>
              <br />
              <table width="100%">
                <thead>
                  <tr className="tableTop column-right">
                    <th>MuxId</th>
                    <th>RouteName</th>
                    <th>RouteID</th>
                    <th>Status</th>
                    <th>Protocol</th>
                    <th>TX_OK</th>
                    <th>TX_ERR</th>
                    <th>RX</th>
                    <th>Today</th>
                    <th>H</th>
                    <th>M</th>
                    <th>L</th>
                    <th>Age (s)</th>
                  </tr>
                </thead>
                <tbody>{this.generateTableBody()}</tbody>
              </table>
            </div>
          </div>
        );
      } else {
        return <p>Loading...</p>;
      }
    }
  };

  /*
    Generates the body of the Table/List to display all Provider data
  */
  generateTableBody = () => {
    let rows = [];
    let { statsByMuxId } = this.state;

    for (let i = 0; i < statsByMuxId.length; i++) {
      rows.push(
        <ListItem
          key={statsByMuxId[i].route_name.S}
          id={statsByMuxId[i].route_name.S}
          muxId={statsByMuxId[i].mux_id.S}
          routeName={statsByMuxId[i].route_name.S}
          routeId={statsByMuxId[i].route_id.S}
          daemonStatus={statsByMuxId[i].daemon_status.S}
          protocol={statsByMuxId[i].protocol.S}
          txOk={statsByMuxId[i].transmitted_ok.N}
          txErr={statsByMuxId[i].transmitted_error.N}
          rx={statsByMuxId[i].received.N}
          today={statsByMuxId[i].sms_today.N}
          maxPerDay={statsByMuxId[i].max_sms_per_day.N}
          H={statsByMuxId[i].messages_count_H.N}
          M={statsByMuxId[i].messages_count_M.N}
          L={statsByMuxId[i].messages_count_L.N}
          age={statsByMuxId[i].updated_at.S}
          onClickRouteName={(e) => this.drilldownRouteName(e)}
          onClickRouteId={(e) => this.drilldownRouteId(e)}
        />
      );
    }
    return rows;
  };

  /* renders the component */
  render() {
    return this.generateContent();
  }
}

export default MuxIdDrilldownComponent;
