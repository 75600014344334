import React from 'react';
import { Form } from 'react-bootstrap';

const ListItem = ({
  id,
  createdDate = '',
  from = '',
  to = '',
  name = '',
  messageText = '',
  status = '',
  onClickMessage = null,
  sentSms = null,
  handleCancelList = () => {},
  checked = false,
}) => {
  const handleInputChange = (event, id) => {
    const target = event.target;
    // const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    handleCancelList(id, !value);
  };

  return (
    <tr key={`list${id}`} id={id}>
      <td className="medium textLeft" name="createdDate">
        {createdDate}
      </td>
      <td className="medium textLeft" name="from">
        <span>{from}</span>{' '}
      </td>
      <td className="medium textLeft" name="to">
        <span>{to}</span>
      </td>
      <td className="medium textLeft" name="name">
        <span>{name}</span>{' '}
      </td>
      <td className="medium textLeft" name="messageText">
        <span className="blue" onClick={() => onClickMessage(sentSms)}>
          {messageText}
        </span>
      </td>
      <td className="medium textLeft" name="status">
        <span>{status}</span>
      </td>
      <td>
        <Form.Check
          type="checkbox"
          name={'check'}
          onChange={(e) => handleInputChange(e, id)}
          checked={checked}
          disabled={status !== 'Pending'}
        ></Form.Check>
      </td>
    </tr>
  );
};

export default ListItem;
