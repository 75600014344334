/*
  FormattedMessages.js - FormattedMessages for the Monitor page's List Component.

  Author: Adarsh Anup (2022)
  Company: Red Oxygen
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  dateText: (
    <FormattedMessage id="Global.dateText" defaultMessage="Date (UTC)" />
  ),
  logLevelText: (
    <FormattedMessage id="Global.logLevelText" defaultMessage="Level" />
  ),
  countText: <FormattedMessage id="Global.countText" defaultMessage="Count" />,
  typeText: <FormattedMessage id="Global.typeText" defaultMessage="Type" />,
  emailIDText: (
    <FormattedMessage id="Global.emailIDText" defaultMessage="EmailID" />
  ),
  emailText: (
    <FormattedMessage id="Global.emailText" defaultMessage="User Email" />
  ),
  functionText: (
    <FormattedMessage id="Global.functionText" defaultMessage="Function" />
  ),
  resultText: (
    <FormattedMessage id="Global.resultText" defaultMessage="Result" />
  ),
};

export default FormattedMessages;
