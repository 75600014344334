/*
  ComposeRequest.js - Handles composing of request for SMS Send

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../RequestRabbit';
import config from '../../Config';

// INSTANTIATE
const handleRequest = new RequestRabbit();

export default class UserRequests {
  // Get user access level
  getLevel(a, errorHandler) {
    switch (a) {
      case 1:
        return 'H';
      case 2:
        return 'R';
      case 3:
        return 'A';
      default:
        return 'S';
    }
  }

  deleteCentre = async (id, errorHandler) => {
    const url = `${config.baseServerURL}/costcentre/delete`;
    const header = {
      'Content-Type': 'application/json',
    };
    let body = {
      centreId: id,
    };
    body = JSON.stringify(body);
    let options = {
      method: 'POST',
      headers: header,
      body: body,
    };
    try {
      console.log('Received options:', options);
      // Request data
      let response = await handleRequest.request(url, options, errorHandler);
      console.log('user reports rsp:', response);
      let responseJSON = await response.json();
      return responseJSON;
    } catch (e) {
      //Error catching
      errorHandler(e);
    }
  };
  updateCentre = async (id, name, contact, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/costcentre/update`;
      const header = { 'Content-Type': 'application/json' };
      let body = JSON.stringify({
        centreId: id,
        centreName: name,
        contact_id: contact,
      });
      let options = {
        method: 'POST',
        headers: header,
        body: body,
      };
      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      //Error catching
      errorHandler(e);
    }
  };

  /*
    Gets data about the selected User
  */
  selectedUser = async (user, type, page, errorHandler) => {
    let level = type;
    if (page === 'report') {
      level = this.getLevel(type);
    }
    console.log(user, level);
    const url = `${config.baseServerURL}/admin/users/${level}/${user}`;
    const header = {
      'Content-Type': 'application/json',
    };
    let options = {
      method: 'GET',
      headers: header,
    };

    try {
      console.log('Received options:', options);
      // Request data
      let response = await handleRequest.request(url, options, errorHandler);
      console.log('user reports rsp:', response);
      let responseJSON = await response.json();
      return responseJSON;
    } catch (e) {
      //Error catching
      errorHandler(e);
    }
  };

  /*
    Find User Search

    // params: 0 - name, 1 - email, 2 - mob, 3-error handler
  */
  searchForUser = async (name, email, mob, errorHandler) => {
    try {
      let url = `${config.baseServerURL}/users/search`;

      let body = {};
      if (name) body.name = name;
      if (email) body.email = email;
      if (mob) body.phone = mob;

      const header = {
        'Content-Type': 'application/json',
      };
      let options = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(body),
      };

      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Find Customer (Administrators only)

    // 0 - customer_id, 1 - customer_name, 2 - customer_domain
    // 3 - customer_auth, 4 - customer_ip_address, , 5 - error handler
  */
  searchForAdminCustomer = async (
    customerId,
    customerName,
    customerDomain,
    customerAuth,
    customerIpAddress,
    errorHandler
  ) => {
    //param[5] is the error handler function
    try {
      let url = `${config.baseServerURL}/admin/search/customer`;

      let body = {};
      if (customerId) body.accountId = customerId;
      if (customerName) body.name = customerName;
      if (customerDomain) body.domain = customerDomain;
      if (customerAuth) body.authorisationCode = customerAuth;
      if (customerIpAddress) body.ipAddress = customerIpAddress;

      const header = {
        'Content-Type': 'application/json',
      };
      let options = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(body),
      };

      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      console.log('Admin Search JSON:', responseJSON);
      console.log('response.status:', response.status);

      if (response.status > 399 && response.status < 600) {
        return [];
      }
      return responseJSON;
    } catch (e) {
      console.log('Got err:', e.message);
      errorHandler(e.message);
      return [];
    }
  };

  /*
    Find User (Administrators only)

    // 0 - user_id, 1 - user_name, 2 - user_email, 3 - user_phone, 3 - error handler
  */
  searchForAdminUser = async (
    userId,
    userName,
    userEmail,
    userPhone,
    errorHandler
  ) => {
    try {
      let url = `${config.baseServerURL}/admin/search/user`;

      let body = {};
      if (userId) body.userId = userId;
      if (userName) body.name = userName;
      if (userEmail) body.emailAddress = userEmail;
      if (userPhone) body.mobileNumber = userPhone;

      const header = {
        'Content-Type': 'application/json',
      };
      let options = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(body),
      };

      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      if (response.status > 399 && response.status < 600) {
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };

  /*
    Find User Admin (Administrators only)

    // 0 - admin_name, 1 - admin_email, 2 -error handler
  */
  searchForAdminUserAdmin = async (adminName, adminEmail, errorHandler) => {
    //params 2 is the error handler function
    try {
      let url = `${config.baseServerURL}/admin/search/admin`;

      let body = {};
      if (adminName) body.name = adminName;
      if (adminEmail) body.emailAddress = adminEmail;

      const header = {
        'Content-Type': 'application/json',
      };
      let options = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(body),
      };
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Find Errors by Destination Email (Administrators only)

    // 0 - error_email, 1 -error handler
  */
  searchForAdminErrorEmail = async (errorEmail, errorHandler) => {
    //param 1 is the error function
    try {
      let url = `${config.baseServerURL}/admin/search/erroremail`;
      let body = {};
      if (errorEmail) body.emailAddress = errorEmail;

      const header = {
        'Content-Type': 'application/json',
      };
      let options = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(body),
      };

      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Search by Destination Mobile Number (Administrators only)

    // 0 - destination_number, 1 - error handler
  */
  searchForAdminDestTn = async (destinationNumber, errorHandler) => {
    // params[1] is the errorhandler function
    try {
      let url = `${config.baseServerURL}/admin/search/message`;

      let body = {};
      if (destinationNumber) body.destinationNumber = destinationNumber;

      const header = {
        'Content-Type': 'application/json',
      };
      let options = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(body),
      };

      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Gets all applicable Cost Centres for a given Customer Id
  */
  getCostCentres = async (customerId, errorHandler) => {
    const url = `${config.baseServerURL}/customer/${customerId}/centre`;
    const header = {
      'Content-Type': 'application/json',
    };
    let options = {
      method: 'GET',
      headers: header,
    };
    // Request
    let response = await handleRequest.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };
}
