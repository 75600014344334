import React from 'react';

const User = ({
  id,
  onClick,
  onClickProvider,
  onClickCustomer,
  onClickDetails,
  providerName = '',
  customerName = '',
  userName = '',
  email = '',
  phone = '',
  type = '',
  userClass = '',
  status = '',
  softwareRelease = '',
  ...props
}) => (
  <tr key={id} id={id} className="table-click">
    <td
      className="medium underline blue textLeft"
      onClick={() => onClickProvider()}
    >
      {providerName}
    </td>
    <td
      className="medium underline blue textLeft"
      onClick={() => onClickCustomer()}
    >
      {customerName}
    </td>
    {/* add that user as the visible user on the reports page */}
    {/* <td className="medium textLeft"><a href="/Reports/user" className='blue'>{userName}</a></td> */}
    <td className="medium underline blue textLeft" onClick={() => onClick()}>
      {userName}
    </td>
    <td>
      <a href="#0" onClick={onClickDetails}>
        <i
          id={id + 'icon_detail'}
          className="material-icons small-icon textLeft"
        >
          list_alt
        </i>
      </a>
    </td>
    <td className="medium textLeft">{email}</td>
    <td className="medium textLeft">{phone}</td>
    <td className="medium textLeft">{type}</td>
    <td className="medium textLeft">{userClass}</td>
    <td className="medium textLeft">{status}</td>
    <td
      className={
        softwareRelease === 'Data Error'
          ? 'medium red textLeft'
          : 'medium textLeft'
      }
    >
      {softwareRelease}
    </td>
  </tr>
);

export default User;
