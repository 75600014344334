/*
  Country/index.js - Service Config - Countries Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import Paginator from '../../../../components/Paginator';
import config from '../../../../Config';

// COMPONENTS
import ListItem from './ListItem';
import SingleView from './SingleView';

// NETWORKS
import CountryRequest from './CountryRequest';

// STYLES
import '../../Admin.css';

// INSTANTIATION
const countryRequest = new CountryRequest();

export default class ServiceCountry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countries: [],
      selectedCountry: null,
      paginatedCountries: [],
      pageNumber: 1,
      pageLength: config.defaultPL,
      filterLetter: '',
      letters: [],
      count: 0,
    };
  }

  async componentDidMount() {
    if (this.props.customer && this.props.customer !== null) {
      // Fetch All Countries
      let countries = await countryRequest.getCountries(
        this.props.errorHandler
      );
      let sortedCountries = this.sortList(countries);
      let letters = await this.sortLetters(sortedCountries);

      return this.setState(
        {
          countries: sortedCountries,
          letters: letters,
          pageNumber: 1,
        },
        this.setPaginatedResults
      );
    }
  }

  sortList(data) {
    let sortedList = data.sort(function (a, b) {
      if (a.country_name.toLowerCase() < b.country_name.toLowerCase()) {
        return -1;
      }
      if (a.country_name.toLowerCase() > b.country_name.toLowerCase()) {
        return 1;
      }
    });
    return sortedList;
  }

  sortLetters = async (countries) => {
    let letters = [];
    countries.forEach((country) => {
      if (!letters.includes(country.country_name.toUpperCase().charAt(0))) {
        letters.push(country.country_name.toUpperCase().charAt(0));
      }
    });
    return letters.sort();
  };

  generateFirstLetterFilter = () => {
    let letterHtml = this.state.letters.map((el) => {
      return (
        <span key={el}>
          <span
            key={el}
            className={
              this.state.filterLetter === el ? 'clicked-object' : 'click-object'
            }
            onClick={() => this.handleLetterFilter(el)}
          >
            {el}
          </span>{' '}
        </span>
      );
    });
    return <div>{letterHtml}</div>;
  };

  /*
    Handles click from the 'letter filter' component.
  */
  handleLetterFilter = async (letter) => {
    if (letter === this.state.filterLetter) {
      this.setState(
        {
          filterLetter: '',
          pageNumber: 1,
        },
        this.setPaginatedResults
      );
    } else {
      this.setState(
        {
          filterLetter: letter,
          pageNumber: 1,
        },
        this.setPaginatedResults
      );
    }
    this.changeFunction();
  };

  /* Changes the amount of entries per pagination page. */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState(
        { pageLength: value, pageNumber: 1 },
        this.setPaginatedResults
      );
    }
  };

  setPaginatedResults = async () => {
    let { countries, pageNumber, pageLength } = this.state;
    if (
      countries &&
      typeof countries !== 'undefined' &&
      Array.isArray(countries)
    ) {
      let newList = [];
      let paginatedCountries = [];
      if (this.state.filterLetter) {
        countries.forEach((country) => {
          if (
            this.state.filterLetter.toLowerCase() ===
            country.country_name.charAt(0).toLowerCase()
          ) {
            newList.push(country);
          }
        });
        paginatedCountries = newList.slice(
          (pageNumber - 1) * pageLength,
          pageNumber * pageLength
        );
      } else {
        paginatedCountries = countries.slice(
          (pageNumber - 1) * pageLength,
          pageNumber * pageLength
        );
      }
      this.setState({
        paginatedCountries: paginatedCountries,
        count: this.state.filterLetter ? newList.length : countries.length,
      });
    }
  };

  /**
   * Handles the change event when clicking a pagination number
   */
  changeEvent = async (i) => {
    this.setState(
      {
        pageNumber: i + 1,
      },
      this.setPaginatedResults
    );
  };

  /*
    Toggles whether or not to display a single Country.
  */
  showSingleCountry = (country) => {
    return this.setState({
      selectedCountry: country,
    });
  };

  /*
    Handles when a back button is clicked
  */
  handleBackClick = () => {
    return this.setState(
      {
        selectedCountry: null,
      },
      () => this.changeFunction(this.state.pageNumber - 1)
    );
  };

  // GENERATORS //

  /*
    Convenience method that works out which Component to display
  */
  generateContent = () => {
    // If a country has been selected, Show the Single View for it
    if (this.state.selectedCountry) {
      return (
        <SingleView
          country={this.state.selectedCountry}
          backButtonClick={this.handleBackClick}
          errorHandler={this.props.errorHandler}
        />
      );
    }

    return this.generateTable();
  };

  generateTable() {
    let htmlRows = [];
    let { paginatedCountries, count } = this.state;

    for (let i = 0; i < paginatedCountries.length; i++) {
      htmlRows.push(
        <ListItem
          key={paginatedCountries[i].country_code_id}
          id={paginatedCountries[i].country_code_id}
          countryName={paginatedCountries[i].country_name}
          countryCode={paginatedCountries[i].country_code}
          // deliveredByName={countries[i].device_name}
          country={paginatedCountries[i]}
          onClickName={(e) => this.showSingleCountry(e)}
        />
      );
    }

    return (
      <div key="CountryList" className="country table">
        <h5>Countries</h5>
        {this.generateFirstLetterFilter()}
        <br />
        <Table className="table-new">
          <thead>
            <tr>
              <td className="medium tableTop textLeft">Country Name</td>
              <td className="medium tableTop textLeft">Country Code</td>
              {/* <td className='medium tableTop textLeft'>Delivered By</td> */}
            </tr>
          </thead>
          <tbody>{htmlRows}</tbody>
        </Table>
        <Paginator
          itemCount={count}
          changeEvent={this.changeEvent}
          pageLength={this.state.pageLength}
          editFunction={this.changePageLength}
          changePageFunction={(ref) => (this.changeFunction = ref)}
        />
      </div>
    );
  }

  render() {
    return <div className="Home">{this.generateContent()}</div>;
  }
}
