import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  topUserReportsTitle: (
    <FormattedMessage
      id="Reports.TopUserTitle"
      defaultMessage="Top Customer Reports"
      tagName="div"
    ></FormattedMessage>
  ),
  accountText: (
    <FormattedMessage
      id="Global.AccountText"
      defaultMessage="Account:"
    ></FormattedMessage>
  ),
  backText: (
    <FormattedMessage
      id="Global.BackText"
      defaultMessage="Back"
    ></FormattedMessage>
  ),
  messagesText: (
    <FormattedMessage
      id="ReportsList.Messages"
      defaultMessage="Messages"
    ></FormattedMessage>
  ),
  whenText: (
    <FormattedMessage
      id="ReportsList.When"
      defaultMessage="When"
    ></FormattedMessage>
  ),
  dayText: (
    <FormattedMessage
      id="ReportsList.Day"
      defaultMessage="Day"
    ></FormattedMessage>
  ),
  dateText: (
    <FormattedMessage
      id="ReportsList.Date"
      defaultMessage="Date"
    ></FormattedMessage>
  ),
  timeText: (
    <FormattedMessage
      id="ReportsList.Time"
      defaultMessage="Time"
    ></FormattedMessage>
  ),
  statusText: (
    <FormattedMessage
      id="ReportsList.Status"
      defaultMessage="Status"
    ></FormattedMessage>
  ),
  phoneNumberText: (
    <FormattedMessage
      id="ReportsList.PhoneNumber"
      defaultMessage="Phone Number"
    ></FormattedMessage>
  ),
  countryText: (
    <FormattedMessage
      id="ReportsList.Country"
      defaultMessage="Country"
    ></FormattedMessage>
  ),
  localFlagText: (
    <FormattedMessage
      id="ReportsList.LocalFlag"
      defaultMessage="Local Flag"
    ></FormattedMessage>
  ),
  quantityText: (
    <FormattedMessage
      id="ReportsList.Quantity"
      defaultMessage="Quantity"
    ></FormattedMessage>
  ),
  creditsUsedText: (
    <FormattedMessage
      id="ReportsList.CreditsUsed"
      defaultMessage="Credits Used"
    ></FormattedMessage>
  ),
  dataText: (
    <FormattedMessage
      id="ReportsList.Data"
      defaultMessage="Data"
    ></FormattedMessage>
  ),
  typeText: (
    <FormattedMessage
      id="ReportsList.Type"
      defaultMessage="Type"
    ></FormattedMessage>
  ),
  customerRef: (
    <FormattedMessage
      id="ReportsList.CustomerRef"
      defaultMessage="Customer Reference No"
    ></FormattedMessage>
  ),
  defaulOriginator: (
    <FormattedMessage
      id="ReportsList.DefaulOriginator"
      defaultMessage="Default Originator"
    ></FormattedMessage>
  ),
  typeClass: (
    <FormattedMessage
      id="ReportsList.TypeClass"
      defaultMessage="Type - Class"
    ></FormattedMessage>
  ),
  status: (
    <FormattedMessage
      id="ReportsList.Status"
      defaultMessage="Status"
    ></FormattedMessage>
  ),
  active: (
    <FormattedMessage
      id="ReportsList.Active"
      defaultMessage="Active"
    ></FormattedMessage>
  ),
  cancelled: (
    <FormattedMessage
      id="ReportsList.Cancelled"
      defaultMessage="Cancelled"
    ></FormattedMessage>
  ),
  pendingCancelled: (
    <FormattedMessage
      id="ReportsList.PendingCancelled"
      defaultMessage="Pending Cancelled"
    ></FormattedMessage>
  ),
  suspended: (
    <FormattedMessage
      id="ReportsList.Suspended"
      defaultMessage="Suspended"
    ></FormattedMessage>
  ),
  salesAgent: (
    <FormattedMessage
      id="ReportsList.SalesAgent"
      defaultMessage="Sales Agent"
    ></FormattedMessage>
  ),
  tariffPlan: (
    <FormattedMessage
      id="ReportsList.TariffPlan"
      defaultMessage="Tariff Plan"
    ></FormattedMessage>
  ),
  country: (
    <FormattedMessage
      id="ReportsList.Country"
      defaultMessage="Country"
    ></FormattedMessage>
  ),
  contractId: (
    <FormattedMessage
      id="ReportsList.ContractId"
      defaultMessage="Contract Form Id"
    ></FormattedMessage>
  ),
  language: (
    <FormattedMessage
      id="ReportsList.Language"
      defaultMessage="Language on Web Pages"
    ></FormattedMessage>
  ),
  activeCreation: (
    <FormattedMessage
      id="ReportsList.ActiveCreation"
      defaultMessage="Active Creation"
    ></FormattedMessage>
  ),
  redAlertEnabled: (
    <FormattedMessage
      id="ReportsList.RedAlertEnabled"
      defaultMessage="Red Alert Enabled"
    ></FormattedMessage>
  ),
  gSTApplicable: (
    <FormattedMessage
      id="ReportsList.GSTApplicable"
      defaultMessage="GST Applicable"
    ></FormattedMessage>
  ),
  oMSFlag: (
    <FormattedMessage
      id="ReportsList.OMSFlag"
      defaultMessage="Outlook Mobile Service Flag"
    ></FormattedMessage>
  ),
  receiptFlag: (
    <FormattedMessage
      id="ReportsList.ReceiptFlag"
      defaultMessage="Receipt Flag"
    ></FormattedMessage>
  ),
  employeeTrustFlag: (
    <FormattedMessage
      id="ReportsList.EmployeeTrustFlag"
      defaultMessage="Employee Trust Flag"
    ></FormattedMessage>
  ),
  messagePriority: (
    <FormattedMessage
      id="ReportsList.MessagePriority"
      defaultMessage="Message Priority"
    ></FormattedMessage>
  ),
  low: (
    <FormattedMessage
      id="ReportsList.Low"
      defaultMessage="Low"
    ></FormattedMessage>
  ),
  med: (
    <FormattedMessage
      id="ReportsList.Medium"
      defaultMessage="Medium"
    ></FormattedMessage>
  ),
  high: (
    <FormattedMessage
      id="ReportsList.High"
      defaultMessage="High"
    ></FormattedMessage>
  ),
  smsInCred: (
    <FormattedMessage
      id="ReportsList.SmsInCred"
      defaultMessage="SMS In Credit"
    ></FormattedMessage>
  ),
  smsInExcess: (
    <FormattedMessage
      id="ReportsList.SmsInExcess"
      defaultMessage="SMS In Excess"
    ></FormattedMessage>
  ),
  httpReplyTempl: (
    <FormattedMessage
      id="ReportsList.HttpReplyTempl"
      defaultMessage="HTTP Reply Template"
    ></FormattedMessage>
  ),
  none: (
    <FormattedMessage
      id="ReportsList.None"
      defaultMessage="None"
    ></FormattedMessage>
  ),
  httpReplyUrl: (
    <FormattedMessage
      id="ReportsList.HttpReplyUrl"
      defaultMessage="HTTP Reply URL"
    ></FormattedMessage>
  ),
  save: (
    <FormattedMessage
      id="ReportsList.Save"
      defaultMessage="Save"
    ></FormattedMessage>
  ),
  loading: (
    <FormattedMessage
      id="ReportsList.Loading"
      defaultMessage="Loading..."
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
