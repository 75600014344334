import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { stripNonNumerical } from '../../../../utils/validate';

const ListItem = ({
  id,
  order,
  number,
  name,
  messageText,
  messageCount,
  textClass,
  selectRowsToBeDeleted,
}) => (
  <tr key={`list${id}`} id={id} className={textClass}>
    <td className="medium textLeft" name="order">
      {order}
    </td>
    <td className="medium textLeft" name="number">
      <span>{stripNonNumerical(number)}</span>
    </td>
    <td className="medium textLeft" name="name">
      <span>{name}</span>
    </td>
    <td className="medium textLeft" name="messageText">
      <span>{messageText}</span>
    </td>
    <td className="medium textLeft" name="messageCount">
      <span>{messageCount}</span>
    </td>
    <td className="medium textLeft">
      <Col sm={2} className="margin-auto">
        <Form.Check
          id={order}
          type="checkbox"
          name="delete"
          onChange={selectRowsToBeDeleted}
        />
      </Col>
    </td>
  </tr>
);

export default ListItem;
