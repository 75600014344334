import React from 'react';

const Messages = ({
  id,
  // onClick,
  providerName,
  customerName,
  userName,
  email,
  phone,
  ...props
}) => (
  <tr key={id} id={id}>
    <td className="medium textLeft">{providerName}</td>
    <td className="medium textLeft">{customerName}</td>
    <td className="medium textLeft">{userName}</td>
    <td className="medium textLeft">{email}</td>
    <td className="medium textLeft">{phone}</td>
    {/*<td className="icon"><i id={id + "icon"} className="material-icons floatRight">keyboard_arrow_right</i></td>*/}
  </tr>
);

export default Messages;
