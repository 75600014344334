import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  billingsSummaryTitle: (
    <FormattedMessage
      id="BillingsSummary.Title"
      defaultMessage="Billing Summary Report"
    />
  ),
  activityReport: (
    <FormattedMessage
      id="ReportsIndex.ActivityReport"
      defaultMessage="Activity Report"
    />
  ),
  showReport: (
    <FormattedMessage
      id="ReportsIndex.ShowReport"
      defaultMessage="Show Report"
    />
  ),
  Date: <FormattedMessage id="ReportsIndex.Date" defaultMessage="Date" />,
  invoiceNo: (
    <FormattedMessage id="ReportsIndex.InvoiceNo" defaultMessage="Invoice No" />
  ),
  name: <FormattedMessage id="ReportsIndex.Name" defaultMessage="Name" />,
  coo: <FormattedMessage id="ReportsIndex.COO" defaultMessage="COO" />,
  paidDate: (
    <FormattedMessage id="ReportsIndex.PaidDate" defaultMessage="Paid Date" />
  ),
  paymentType: (
    <FormattedMessage
      id="ReportsIndex.PaymentType"
      defaultMessage="PaymentType"
    />
  ),
  suffix: <FormattedMessage id="ReportsIndex.suffix" defaultMessage="Suffix" />,
  setupFees: (
    <FormattedMessage id="ReportsIndex.SetupFees" defaultMessage="SetupFees" />
  ),
  licenseFees: (
    <FormattedMessage
      id="ReportsIndex.LicenseFees"
      defaultMessage="License Fees"
    />
  ),
  messages: (
    <FormattedMessage id="ReportsIndex.messages" defaultMessage="Messages" />
  ),
  replies: (
    <FormattedMessage id="ReportsIndex.Replies" defaultMessage="Replies" />
  ),
  messageFees: (
    <FormattedMessage
      id="ReportsIndex.MessageFees"
      defaultMessage="Message Fees"
    />
  ),
  totalFees: (
    <FormattedMessage id="ReportsIndex.totalFees" defaultMessage="TotalFees" />
  ),
  gst: <FormattedMessage id="ReportsIndex.GST" defaultMessage="GST" />,
  CCsurcharge: (
    <FormattedMessage
      id="ReportsIndex.CCsurcharge"
      defaultMessage="Credit Card Surcharge"
    />
  ),

  total: <FormattedMessage id="ReportsIndex.Total" defaultMessage="Total" />,
  back: <FormattedMessage id="ReportsIndex.BackText" defaultMessage="back" />,
  January: (
    <FormattedMessage id="ReportsIndex.January" defaultMessage="January" />
  ),
  February: (
    <FormattedMessage id="ReportsIndex.February" defaultMessage="February" />
  ),
  March: <FormattedMessage id="ReportsIndex.March" defaultMessage="March" />,
  April: <FormattedMessage id="ReportsIndex.April" defaultMessage="April" />,
  May: <FormattedMessage id="ReportsIndex.May" defaultMessage="May" />,
  June: <FormattedMessage id="ReportsIndex.June" defaultMessage="June" />,
  July: <FormattedMessage id="ReportsIndex.July" defaultMessage="July" />,
  August: <FormattedMessage id="ReportsIndex.August" defaultMessage="August" />,
  September: (
    <FormattedMessage id="ReportsIndex.September" defaultMessage="September" />
  ),
  October: (
    <FormattedMessage id="ReportsIndex.October" defaultMessage="October" />
  ),
  November: (
    <FormattedMessage id="ReportsIndex.November" defaultMessage="November" />
  ),
  December: (
    <FormattedMessage id="ReportsIndex.December" defaultMessage="December" />
  ),
  loading: <FormattedMessage id="Global.Loading" defaultMessage="Loading..." />,
};

export default FormattedMessages;
