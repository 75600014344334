/*
  CostCenterReportRequests.js
  Handles Search for Cost Center Report Data

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';
import config from '../../../Config';

// INSTANTIATE
const handleRequest = new RequestRabbit();

export default class CostCenterReportRequests {
  /*
    Get Report Data for Date Range
  */
  getReportData = async (searchObject, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/reports/costcentre`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(searchObject) || null,
        headers: headers,
      };

      // Request data
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();
      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Requests the backend generates a Usage Log Report PDF file and
    emails it to a Customer Contact of the requested Usage Log.
    Inputs:
      - date: Date to generate a report for
      - password: A password for the PDF File
      - customerUserId: (Optional) The ID for a Customer User
      - errorHandler: A function to handle any generated errors
  */
  generateUsageLog = async (
    fromDate,
    toDate,
    password,
    userEmail,
    errorHandler
  ) => {
    try {
      const url = `${config.baseServerURL}/admin/report/costcentre/usage/pdf`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const body = JSON.stringify({
        fromDate: fromDate,
        toDate: toDate,
        password: password,
        user_email: userEmail || null,
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };
      // Request data
      let response = await handleRequest.request(url, options, errorHandler);
      return response;
    } catch (e) {
      errorHandler(e.message);
      // throw e;
    }
  };

  /*
    Fetches System Usage Report Log data
  */
  fetchUsageLogData = async (fromDate, toDate, userEmail, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/report/costcentre/usage`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const body = JSON.stringify({
        fromDate: fromDate,
        toDate: toDate,
        user_email: userEmail,
      });
      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Fail State
      if (response.status > 399 && response.status < 600) {
        return [];
      }

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
      // throw e;
    }
  };

  /*
  Used to fetch a single Customer Contact
*/

  getAdministrator = async (contactId, isLoginUser, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/contact/${contactId}/${isLoginUser}`;
      let options = {
        method: 'GET',
      };

      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return await responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
