import React from 'react';

const ListItem = ({
  id, //{el.device_id}
  deviceName, //{el.device_name}
  type, //{el.device_type}
  replyTn, //{el.reply_tn}
  txt, //{el.totals.messages_sent}
  dir, // {el.totals.direct_sent}
  rem, //{el.totals.reminders_sent}
  ...props
}) => (
  <tr key={'list' + id} id={id} className="">
    <td className="medium textLeft">
      <span>{deviceName}</span>
    </td>
    <td className="medium textLeft">
      <span>{type}</span>
    </td>
    <td className="medium textLeft">
      <span>{replyTn}</span>
    </td>
    <td className="medium textLeft">
      <span>{txt}</span>
    </td>
    <td className="medium textLeft">
      <span>{dir}</span>
    </td>
    <td className="medium textLeft">
      <span>{rem}</span>
    </td>
  </tr>
);

export default ListItem;
