import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import MessagePartTooltip from '../../../../components/Miscellaneous/MessagePartTooltip';

// FORMATTED MESSAGES
import formattedMessages from '../../FormattedMessages';

class ListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
    };
  }

  render() {
    let { loading, list, totals } = this.props;

    if (loading) {
      return <div>{formattedMessages.loadingText}...</div>;
    } else
      return (
        <Fragment>
          {this.props.checkBox()}
          <Table className="table-new">
            <thead>
              <tr>
                <td
                  className="medium tableTop centre"
                  style={{ width: '50%' }}
                  colSpan="5"
                >
                  {formattedMessages.provider}
                </td>
                <td className="medium tableTop column-left"></td>
                <td className="medium tableTop centre">
                  {formattedMessages.messagesText}
                  <MessagePartTooltip />
                </td>
                <td className="medium tableTop column-right"></td>
                <td className="medium tableTop centre" colSpan="2">
                  {formattedMessages.remindersText}
                  <MessagePartTooltip />
                </td>
              </tr>
              <tr>
                <td className="medium tableBottom left" colSpan={2}>
                  {formattedMessages.nameText}
                </td>
                <td className="medium tableBottom ">
                  {formattedMessages.status}
                </td>
                <td className="medium tableBottom ">
                  {formattedMessages.customers}
                </td>
                <td className="medium tableBottom ">
                  {formattedMessages.usersText}
                </td>
                <td className="medium tableBottom column-left">
                  {formattedMessages.directText}
                </td>
                <td className="medium tableBottom">
                  {formattedMessages.standardText}
                </td>
                <td className="medium tableBottom column-right">
                  {formattedMessages.repliesText}
                </td>
                <td className="medium tableBottom">
                  {formattedMessages.sentText}
                </td>
              </tr>
            </thead>
            <tbody>
              {list}{' '}
              <tr>
                <td className="medium left">{formattedMessages.totalText}</td>
                <td className="medium"></td>
                <td className="medium"></td>
                <td className="medium">{totals.Customers}</td>
                <td className="medium">{totals.Users}</td>
                <td className="medium column-left">{`${totals.Direct} (${totals.DirectParts})`}</td>
                <td className="medium">{`${totals.Standard} (${totals.StandardParts})`}</td>
                <td className="medium column-right">{`${totals.Replies} (${totals.RepliesParts})`}</td>
                <td className="medium">{`${totals.Sent} (${totals.SentParts})`}</td>
              </tr>
            </tbody>
          </Table>
        </Fragment>
      );
  }
}

export default ListComponent;
