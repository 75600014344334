/**
  AddressBookComponent - AddressBook Container Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

// OUR COMPONENTS
import WebSMSTabs from '../index';
import SingleView from './SingleTemplateComponent';
import BlacklistSingleView from './BlacklistSingleTemplateComponent';
import GlobalList from './GlobalListComponent';
import PersonalList from './PersonalListComponent';
import GlobalBlacklist from './GlobalBlacklistComponent';
import Manage from './ManageComponent';
import ContactImporter from '../../../components/ContactImporter';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

// STYLING
import './AddressBookComponent.css';

// NETWORKING
import AddressBookRequest from './AddressBookRequest';

let addressBookRequest = new AddressBookRequest();

export default class AddressBook extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - errorHandler: A function used to display errors in a pop-up fashion
        - edit: A function used to handle edits to Contacts
        - add:  A function to handle adding a new Contact
        - delete: A function to handle deleting a Contact
    */
    this.state = {
      isLoading: true,
      stv: false,
      selected: null,
      tab: 'Global',
      manage: false,
      addressBookPersonal: [],
      addressBookGlobal: [],
      showContactImport: false,
      blacklistGlobal: [],
    };
  }

  componentDidMount = async () => {
    if (this.props.customer && this.props.customer !== null) {
      try {
        // Load in contacts data now
        // Returns {global[], personal[]} Contacts
        let data = await addressBookRequest.getContacts(
          this.props.errorHandler
        );

        let sortedGlobal = this.sortList(data.global);
        let sortedPersonal = this.sortList(data.personal);

        let blacklistGlobal = await addressBookRequest.getBlacklistItems(
          this.props.errorHandler
        );

        return this.setState({
          isLoading: false,
          addressBookGlobal: sortedGlobal,
          addressBookPersonal: sortedPersonal,
          blacklistGlobal: blacklistGlobal.list,
        });
      } catch (e) {
        return this.setState({
          isLoading: false,
          hasError: true,
          errorText: 'Network Error',
        });
      }
    }
  };

  sortList(data) {
    //sorts alphabetically
    let sortedList = data.sort(function (a, b) {
      if (a.entry_name.toLowerCase() < b.entry_name.toLowerCase()) {
        return -1;
      }
      if (a.entry_name.toLowerCase() > b.entry_name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    //removed blacklist
    let filtered = [];
    for (let i = 0; i < sortedList.length; i++) {
      if (
        sortedList[i].entry_type !== 'B' ||
        sortedList[i].entry_type !== 'b'
      ) {
        filtered.push(sortedList[i]);
      }
    }

    return filtered;
  }

  /*
    Edits an existing Contact
  */
  editExisting = async (id, entryName, email, number, tab, errorHandler) => {
    try {
      let contactToUpdate = {
        addressbook_id: id,
        entry_name: entryName,
        email: email,
        number: number,
        entry_type: tab,
      };
      // Update Contact
      await addressBookRequest.updateContact(
        contactToUpdate,
        this.props.errorHandler
      );

      // Load in contacts data now
      // Returns {global[], personal[]} Contacts
      let data = await addressBookRequest.getContacts(this.props.errorHandler);

      let sortedGlobal = this.sortList(data.global);
      let sortedPersonal = this.sortList(data.personal);

      this.setState({
        isLoading: false,
        stv: false,
        addressBookGlobal: sortedGlobal,
        addressBookPersonal: sortedPersonal,
      });
      return true;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
   Edits an existing Contact
 */
  editExistingBlacklist = async (blacklistId, number, oldNumber) => {
    try {
      let updateData = {
        blacklistId: blacklistId,
        number: number,
        oldNumber: oldNumber,
      };
      // Update
      await addressBookRequest.updateBlacklistGlobal(updateData);

      // Load in blacklist data now
      let data = await addressBookRequest.getBlacklistItems(
        this.props.errorHandler
      );

      this.setState({
        isLoading: false,
        stv: false,
        blacklistGlobal: data.list,
        saving: false,
      });
      return true;
    } catch (e) {
      this.props.errorHandler(e.message);
    }
  };

  /*
    Deletes an existing Contact
  */
  deleteExisting = async () => {
    let { tab, selectedIndex } = this.state;
    let list =
      tab.toLowerCase() === 'personal'
        ? this.state.addressBookPersonal
        : this.state.addressBookGlobal;
    let contactToDelete = list[selectedIndex];

    await addressBookRequest.deleteContact(
      contactToDelete.addressbook_id,
      tab,
      this.props.errorHandler
    );

    // Load in contacts data now
    // Returns {global[], personal[]} Contacts
    let data = await addressBookRequest.getContacts(this.props.errorHandler);

    let sortedGlobal = this.sortList(data.global);
    let sortedPersonal = this.sortList(data.personal);

    this.setState({
      isLoading: false,
      stv: false,
      addressBookGlobal: sortedGlobal,
      addressBookPersonal: sortedPersonal,
    });
    return true;
  };

  /*
    Handle blacklist delete
  */
  deleteExistingBlacklist = async () => {
    let { selectedIndex } = this.state;
    let itemToDelete = this.state.blacklistGlobal[selectedIndex];
    let deleteData = {
      blacklistId: itemToDelete.blacklist_id,
      number: itemToDelete.number,
    };
    await addressBookRequest.deleteBlackList(deleteData);

    // Refresh data
    let data = await addressBookRequest.getBlacklistItems(
      this.props.errorHandler
    );
    return this.setState({
      isLoading: false,
      stv: false,
      blacklistGlobal: data.list,
    });
  };

  /*
    Creates a new Contact of type Global or Personal
  */
  addNew = async (entryName, email, number, tab) => {
    // Data to send
    let contact = {
      entry_name: entryName,
      email: email,
      number: number,
      entry_type: tab,
    };
    await addressBookRequest.insertContact(contact, this.props.errorHandler);

    // Load in contacts data now
    // Returns {global[], personal[]} Contacts
    let data = await addressBookRequest.getContacts(this.props.errorHandler);

    let sortedGlobal = this.sortList(data.global);
    let sortedPersonal = this.sortList(data.personal);

    this.setState({
      isLoading: false,
      stv: false,
      addressBookGlobal: sortedGlobal,
      addressBookPersonal: sortedPersonal,
    });
    return true;
  };

  /*
    Creates a new global blacklist
  */
  addNewBlacklistGlobal = async (number) => {
    // Data to send
    let data = { number: number };
    await addressBookRequest.insertBlacklistGlobal(
      data,
      this.props.errorHandler
    );

    // Load in blacklistData
    let blacklists = await addressBookRequest.getBlacklistItems(
      this.props.errorHandler
    );
    let blacklistGlobal = blacklists.list;

    this.setState({
      isLoading: false,
      stv: false,
      blacklistGlobal: blacklistGlobal,
      saving: false,
    });
    return true;
  };

  /*
    Handles when a List Item is clicked under the Global Address Book tab
  */
  listItemCallback = (element) => {
    let list =
      this.state.tab === 'Global'
        ? this.state.addressBookGlobal
        : this.state.addressBookPersonal;
    // If have Contact to Edit or Delete
    if (element && typeof element !== 'undefined' && element !== 'null') {
      // let list = (listType.toLowerCase() === 'personal') ? this.state.addressBookPersonal : this.state.addressBookGlobal;
      return this.setState({
        selected: element,
        selectedIndex: list.indexOf(element),
        stv: true,
      });
    } else {
      // Else creating new Contact
      return this.setState({
        selected: null,
        selectedIndex: null,
        stv: true,
      });
    }
  };

  /*
   Handles when a List Item is clicked under the Global blacklist Address Book tab
 */
  BlacklistItemCallback = (element) => {
    let list =
      this.state.tab === 'GlobalBlacklist'
        ? this.state.blacklistGlobal
        : this.state.blacklistPersonal; //currently only have global(15/08/2021)
    // If have Contact to Edit or Delete
    if (element && typeof element !== 'undefined' && element !== 'null') {
      // let list = (listType.toLowerCase() === 'personal') ? this.state.addressBookPersonal : this.state.addressBookGlobal;
      return this.setState({
        selected: element,
        selectedIndex: list.indexOf(element),
        stv: true,
      });
    } else {
      // Else creating new Contact
      return this.setState({
        selected: null,
        selectedIndex: null,
        stv: true,
      });
    }
  };

  /*
    Handles the "Create New" button being clicked for
    the Personal Contacts page, and the "Edit" button
    being clicked for a Personal Contact in the list.
  */
  personalCallback = (id) => {
    // If we have an id, find that User
    if (id) {
      let list = this.state.addressBookPersonal;
      let person;
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
          person = list[i];
          break;
        }
      }

      this.setState({
        selected: person,
        stv: true,
      });
    } else {
      this.setState({
        selected: null,
        stv: true,
      });
    }
  };

  /*
    Handles whether or not the Manage Address Books
    button has been clicked
  */
  manage = () => {
    return this.setState({
      manage: true,
    });
  };

  /*
    Sets which tab is currently being displayed
  */
  setTab = (event) => {
    return this.setState({
      tab: event,
      contactSearch: '',
    });
  };

  /*
    Handles returning back from a child component
  */
  handleBackClick = () => {
    return this.setState({
      showContactImport: false,
      manage: false,
      stv: false,
    });
  };

  /**
   *  Resets the page's info when the tab is clicked.
   */
  handlePageReset = () => {
    this.setState({
      stv: false,
      selected: null,
      showContactImport: false,
      tab: 'Global',
    });
  };

  /*
    Given an address book list type ('personal' or 'global'),
    deletes ALL of the Contacts for that list.
  */
  handleDeleteAll = async (selected) => {
    let result = await addressBookRequest.deleteAllContacts(
      selected,
      this.props.errorHandler
    );
    if (result) {
      if (selected.toLowerCase() === 'personal')
        this.setState({ addressBookPersonal: [] });
      else if (selected.toLowerCase() === 'global')
        this.setState({ addressBookGlobal: [] });
    }
    return true;
  };

  showContactImporter = async () => {
    if (this.state.showContactImport) {
      let data = await addressBookRequest.getContacts(this.props.errorHandler);

      this.setState({
        addressBookGlobal: data.global,
        addressBookPersonal: data.personal,
      });
    }
    return this.setState({
      showContactImport: !this.state.showContactImport,
    });
  };

  exportContacts = async () => {
    let { tab } = this.state;
    await addressBookRequest.exportContacts(
      { entry_type: tab },
      this.props.errorHandler
    );
  };

  generateMainContent = () => {
    let { stv, tab, selected, showContactImport, isLoading } = this.state;
    let { selectOnly, error, accessLevel, accessType } = this.props;

    // Display Single View
    if (selectOnly && (tab === 'Global' || tab === 'Personal')) {
      return (
        <span className="singleView">
          <SingleView
            tab={tab}
            accessLevel={accessLevel}
            accessType={accessType}
            callBack={this.handleBackClick}
            edit={this.editExisting}
            selectOnly={true}
            handleSelect={this.props.handleSelect.bind(this)}
            delete={this.deleteExisting}
            add={this.addNew}
            selected={selected}
          />
        </span>
      );
    }
    // Display error
    else if (error) {
      return <div>Error: {error.message}</div>;
    }
    // Handle Loading State for App
    else if (isLoading) {
      return <div> {formattedMessages.loadingText}... </div>;
    } else if (stv && tab === 'GlobalBlacklist') {
      return (
        <span className="singleView">
          <BlacklistSingleView
            accessLevel={accessLevel}
            accessType={accessType}
            tab={tab}
            callBack={this.handleBackClick}
            edit={this.editExistingBlacklist}
            delete={this.deleteExistingBlacklist}
            add={this.addNewBlacklistGlobal}
            selected={selected}
          />
        </span>
      );
    } else if (stv) {
      return (
        <span className="singleView">
          <SingleView
            tab={tab}
            accessLevel={accessLevel}
            accessType={accessType}
            callBack={this.handleBackClick}
            edit={this.editExisting}
            delete={this.deleteExisting}
            add={this.addNew}
            selected={selected}
          />
        </span>
      );
    } else if (showContactImport) {
      return (
        <ContactImporter
          handleBackClick={this.handleBackClick}
          errorHandler={this.props.errorHandler}
          showContactImporter={this.showContactImporter}
          tab={this.props.tab}
        />
      );
    }

    return this.generateContactTabs();
  };

  generateListContent = () => {
    return (
      <Fragment>
        <div className="bigFlex">
          <h3>{formattedMessages.addressBookTitle}</h3>
        </div>
      </Fragment>
    );
  };

  /*
    Generates Tab Area Component
  */
  generateContactTabs = () => {
    let { accessLevel, accessType } = this.props;

    let globalList = this.state.addressBookGlobal;
    let personalList = this.state.addressBookPersonal;
    let globalBlacklist = this.state.blacklistGlobal;
    let { tab } = this.state;

    return (
      <Fragment>
        <div className="visible-desktop">
          <Tabs
            activeKey={tab}
            onSelect={this.setTab}
            className="tabs-container"
            id="address-book"
          >
            <Tab eventKey="Global" title="Global Contacts" className="tab">
              {this.generateListContent()}
              <GlobalList
                list={globalList}
                callBack={this.listItemCallback}
                showContactImporter={this.showContactImporter}
                exportContacts={this.exportContacts}
                accessLevel={accessLevel}
                accessType={accessType}
              />
            </Tab>
            <Tab eventKey="Personal" title="Personal Contacts" className="tab">
              {this.generateListContent()}
              <PersonalList
                list={personalList}
                callBack={this.listItemCallback}
                showContactImporter={this.showContactImporter}
                exportContacts={this.exportContacts}
              />
            </Tab>
            {accessLevel > '0' && accessType !== '0' && (
              <Tab
                eventKey="GlobalBlacklist"
                title="Global Blacklist"
                className="tab"
              >
                {this.generateListContent()}
                <GlobalBlacklist
                  list={globalBlacklist}
                  callBack={this.BlacklistItemCallback}
                  accessLevel={accessLevel}
                  accessType={accessType}
                />
              </Tab>
            )}
            <Tab
              eventKey="Manage"
              title="Manage"
              onClick={this.manage}
              className="tab"
            >
              <Manage
                accessLevel={accessLevel}
                accessType={accessType}
                tab={tab}
                personalList={this.state.addressBookPersonal}
                globalList={this.state.addressBookGlobal}
                globalBlacklist={this.state.blacklistGlobal}
                callBack={this.handleBackClick}
                handleDeleteAll={this.handleDeleteAll}
              />
            </Tab>
          </Tabs>
        </div>
        <div className="visible-device">
          <Tabs
            activeKey={tab}
            onSelect={this.setTab}
            className="tabs-container col-sm-4"
          >
            <Tab eventKey="Global" title="Global" className="tab">
              {this.generateListContent()}
              <GlobalList
                list={globalList}
                callBack={this.listItemCallback}
                showContactImporter={this.showContactImporter}
                exportContacts={this.exportContacts}
                accessLevel={accessLevel}
                accessType={accessType}
              />
            </Tab>
            <Tab eventKey="Personal" title="Personal" className="tab">
              {this.generateListContent()}
              <PersonalList
                list={personalList}
                callBack={this.listItemCallback}
                showContactImporter={this.showContactImporter}
                exportContacts={this.exportContacts}
              />
            </Tab>
            {accessLevel > '0' && accessType !== '0' && (
              <Tab
                eventKey="GlobalBlacklist"
                title="Global Blacklist"
                className="tab"
              >
                {this.generateListContent()}
                <GlobalBlacklist
                  list={globalBlacklist}
                  callBack={this.BlacklistItemCallback}
                  accessLevel={accessLevel}
                  accessType={accessType}
                />
              </Tab>
            )}
            <Tab
              eventKey="Manage"
              title="Manage"
              onClick={this.manage}
              className="tab"
            >
              <Manage
                accessLevel={accessLevel}
                accessType={accessType}
                tab={tab}
                personalList={this.state.addressBookPersonal}
                globalList={this.state.addressBookGlobal}
                globalBlacklist={this.state.blacklistGlobal}
                callBack={this.handleBackClick}
                handleDeleteAll={this.handleDeleteAll}
              />
            </Tab>
          </Tabs>
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      <WebSMSTabs
        tab="address"
        mobileMenuOpen={this.props.mobileMenuOpen}
        toggleMobileMenu={this.props.toggleMobileMenu}
        navigate={this.props.navigate}
        handlePageReset={this.handlePageReset}
      >
        {this.generateMainContent()}
      </WebSMSTabs>
    );
  }
}
