import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  unpaidAccountsTitle: (
    <FormattedMessage
      id="UnpaidAccounts.Title"
      defaultMessage="List of Unpaid Accounts"
    />
  ),
  invoiceNo: (
    <FormattedMessage
      id="UnpaidAccounts.InvoiceNo"
      defaultMessage="Invoice No"
    />
  ),
  accountName: (
    <FormattedMessage
      id="UnpaidAccounts.AccountName"
      defaultMessage="Account Name"
    />
  ),
  status: (
    <FormattedMessage id="UnpaidAccounts.Status" defaultMessage="Status" />
  ),
  failureReason: (
    <FormattedMessage
      id="UnpaidAccounts.FailureReason"
      defaultMessage="Failure Reason"
    />
  ),
  back: (
    <FormattedMessage
      id="Global.BackText"
      defaultMessage="back"
    ></FormattedMessage>
  ),
  loading: (
    <FormattedMessage
      id="UnpaidAccounts.loading"
      defaultMessage="Loading...."
    />
  ),
};

export default FormattedMessages;
