import React from 'react';

const SubInlineListItem = ({
  revenue_date,
  direct_sent,
  messages_sent,
  reminders_sent,
  replies_sent,
  binary_sent,
  premium_sent,
  total,
}) => (
  <tr>
    <td className="left"></td>
    <td></td>
    <td>{revenue_date}</td>
    <td>{direct_sent}</td>
    <td>{messages_sent}</td>
    <td>{reminders_sent}</td>
    <td>{replies_sent}</td>
    <td>{premium_sent}</td>
    <td>{binary_sent}</td>
    <td>{total}</td>
  </tr>
);

export default SubInlineListItem;
