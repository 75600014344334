import React from 'react';

const ListItem = ({
  id,
  countryName = '',
  countryCode = '',
  onClickName = null,
  carrier = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="column-right" name="name">
      <span className="blue" onClick={() => onClickName(carrier)}>
        {countryName}
      </span>
    </td>
    <td className="medium column-right" name="active">
      <span>{countryCode}</span>
    </td>
  </tr>
);

export default ListItem;
