// NPM
import { Table } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';

// Components
import formattedMessage from '../ListItem/FormattedMessages';
import Paginator from '../../../../components/Paginator';

import config from '../../../../Config';

class ListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      list: [],
      activePage: 0,
      pages: [],
      pagination: true,
      pagModal: false,
      typedPage: 0,
      pageLength: config.defaultPL,
      pageNumber: 1,
    };
  }

  /**
   * Function passed through to the paginator that saves the paginator's selected page to the state of this component.
   * @param {Int} o Is the current page number (starting from 0)
   */
  changeEvent = async (i) => {
    this.setState(
      {
        pageNumber: i + 1,
      },
      this.paginatedList
    );
  };

  /* 
    Changes the amount of entries per pagination page.
  */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState({ pageLength: value, pageNumber: 1 }, this.paginatedList);
    }
  };

  /**
   * Handles the list of data depending on whether pagination is enabled or not.
   * @returns if pagination is set to true, returns the correct data depending on the page, else it returns all of the data
   */
  paginatedList = () => {
    let { pageNumber, pageLength } = this.state;
    if (this.state.pagination)
      return this.props.list.slice(
        (pageNumber - 1) * pageLength,
        pageNumber * pageLength - 1 + 1
      );
    else return this.props.list;
  };

  render() {
    return (
      <Fragment>
        <Table className="invoice-table" id="invoice-table">
          <thead>
            <tr>
              {this.props.isGodAdmin === true ? (
                <th className="medium textLeft tableTop">
                  {formattedMessage.provider}
                </th>
              ) : null}
              <th className="medium textLeft tableTop">
                {formattedMessage.invoiceNo}
              </th>
              {this.props.isGodAdmin === true ? (
                <th className="medium textLeft tableTop">
                  {formattedMessage.customer}
                </th>
              ) : null}
              <th className="medium textLeft tableTop th pad-left">
                {formattedMessage.invoiced}
              </th>
              <th className="medium textLeft tableTop th pad-left">
                {formattedMessage.paid}
              </th>
              <th className="medium textLeft tableTop th pad-left">
                {formattedMessage.status}
              </th>
              <th className="medium textRight tableTop">
                {formattedMessage.amount}
              </th>
              <th className="medium textLeft tableTop th pad-left">
                {formattedMessage.currency}
              </th>
              {this.props.isGodAdmin === true ? (
                <th className="medium textLeft tableTop th pad-left">Type</th>
              ) : null}
              <th className="icon tableTop th pad-right"></th>
            </tr>
          </thead>
          <tbody>{this.paginatedList()}</tbody>
        </Table>
        <Paginator
          itemCount={this.props.pageCount}
          changeEvent={this.changeEvent}
          pageLength={this.state.pageLength}
          editFunction={this.changePageLength}
        />
      </Fragment>
    );
  }
}

export default ListComponent;
