/*
  DistributionListComponent/index.js - Generates the WebSMS Distribution List Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

// OUR COMPONENTS
import WebSMSTabs from '../index';
import SingleView from './SingleTemplateComponent';
import GlobalList from './GlobalListComponent';
import PersonalList from './PersonalListComponent';

// NETWORKING
import DistributionRequest from './DistributionRequest';

// INSTANTIATION
const distributionRequest = new DistributionRequest();

export default class DistributionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      stv: false,
      tab: 'Global',
      entryType: 'Global',
      addNew: false,
      personal: [],
      global: [],
      loading: true,
      error: false,
      errorMessage: '',
    };
  }

  async componentDidMount() {
    if (this.props.customer && this.props.customer !== null) {
      // Fetches current sets of Dist Lists
      let lists = await this.getDistributionLists();

      // Got results, set them
      if (lists) {
        return this.setState({
          stv: false,
          personal: lists.personal,
          global: lists.global,
          loading: false,
        });
      }

      // Had error fetching lists
      return this.setState({
        loading: false,
        error: true,
        errorMessage: 'Failed to fetch Lists',
      });
    }
  }

  /*
    Fetches and returns distribution lists for current User
  */
  getDistributionLists = async () => {
    // Fetch Personal and Global Distribution Lists
    let distLists = await distributionRequest.getDistributionLists(
      this.props.errorHandler
    );
    return distLists;
  };

  /*
    Handles which view to currently display to the User
  */
  viewListCallback = (list, entryType) => {
    return this.setState({
      stv: true,
      entryType: entryType,
      selected: list,
    });
  };

  /*
    Handles when clicking the 'Add New' button
  */
  singleViewList = (entryType) => {
    this.setState({
      stv: true,
      addNew: true,
      selected: null,
      entryType: entryType,
    });
  };

  handleSelect = (selectedList) => {
    this.setState({
      stv: true,
      addNew: false,
      selected: selectedList,
    });
  };

  /*
    Handles back button click from single view
  */
  stvCallback = () => {
    this.setState({
      stv: false,
    });
  };

  importCallback = async () => {
    let lists = await this.getDistributionLists();
    if (lists) {
      return this.setState({
        stv: false,
        personal: lists.personal,
        global: lists.global,
        loading: false,
      });
    }
  };

  /*
    Given a name and an array of Contacts, sends a request to the backend
    to create a new Distribution List.
  */
  createNewDistributionList = async (listName, listContacts, entryType) => {
    try {
      let newDistList = await distributionRequest.createNewDistributionList(
        listName,
        listContacts,
        entryType,
        this.props.errorHandler
      );
      let newState = {
        stv: false,
        selected: null,
        addNew: false,
      };
      let list = [];

      if (entryType.toLowerCase() === 'global') {
        list = this.state.global;
        list.push(newDistList);
        newState.global = list;
      } else {
        list = this.state.personal;
        list.push(newDistList);
        newState.personal = list;
      }
      this.setState(newState);
    } catch (e) {
      console.error('Got error creating new Dist List:', e);
      this.props.errorHandler(e);
    }
  };

  /*
    Handles the editing of a Distribution List
  */
  editDistributionList = async (
    listName,
    listContacts,
    removedContacts,
    entryType,
    currentList
  ) => {
    try {
      let edits = {
        entry_type: entryType,
      };
      // If we have a name and it's not the current name, let's update it
      if (listName && currentList.list_name !== listName)
        edits.list_name = listName;
      // If we have some new contacts to add, let's add them
      if (listContacts && listContacts.length > 0)
        edits.contacts = listContacts;
      if (removedContacts && removedContacts.length > 0)
        edits.removed_contacts = removedContacts;

      let editedList = await distributionRequest.editDistributionList(
        currentList.distribution_list_id,
        edits,
        entryType,
        this.props.errorHandler
      );
      console.log('Got edited list:', editedList);
      this.updateEditedList(editedList, entryType);
      // TODO - Find Dist List current and update

      this.setState({
        stv: false,
        selected: null,
      });
    } catch (e) {
      console.error('Got error editing Dist List:', e);
      this.props.errorHandler(e);
    }
  };

  /*
    Deletes the specified Distribution List
  */
  deleteDistributionList = async (list) => {
    await distributionRequest.deleteDistributionList(
      list.distribution_list_id,
      list.entry_type,
      this.props.errorHandler
    );
    this.removeList(list);

    return this.setState({
      stv: false,
      selected: null,
    });
  };

  updateEditedList = (updatedDistList, entryType) => {
    try {
      let list;
      let state = {};
      if (entryType !== undefined || entryType !== 'undefined') {
        if (entryType.toLowerCase() === 'global') {
          list = this.state.global;
          for (let i = 0; i < list.length; i++) {
            if (
              list[i].distribution_list_id ===
              updatedDistList.distribution_list_id
            ) {
              if (!updatedDistList.contacts)
                updatedDistList.contacts = list[i].contacts;
              if (!updatedDistList.list_name)
                updatedDistList.list_name = list[i].list_name;
              list[i] = updatedDistList;
              break;
            }
          }
          state.global = list;
        } else {
          list = this.state.personal;
          for (let i = 0; i < list.length; i++) {
            if (
              list[i].distribution_list_id ===
              updatedDistList.distribution_list_id
            ) {
              if (!updatedDistList.contacts)
                updatedDistList.contacts = list[i].contacts;
              if (!updatedDistList.list_name)
                updatedDistList.list_name = list[i].list_name;
              list[i] = updatedDistList;
              break;
            }
          }
          state.personal = list;
        }
      }
      console.log('Updated Edited List:', state);
      this.setState(state);
    } catch (e) {
      console.log('Error updating edited list:', e);
    }
  };

  removeList = (deletedList) => {
    try {
      let list;
      let entryType = deletedList.entry_type;
      let state = {};
      if (entryType.toLowerCase() === 'global') {
        list = this.state.global;
        list = list.filter((distList) => {
          console.log('dist list id:', distList.distribution_list_id);
          console.log(
            'deleted dist list id:',
            deletedList.distribution_list_id
          );
          return (
            distList.distribution_list_id !== deletedList.distribution_list_id
          );
        });
        state.global = list;
      } else {
        list = this.state.personal;
        list = list.filter((distList) => {
          return (
            distList.distribution_list_id !== deletedList.distribution_list_id
          );
        });
        state.personal = list;
      }
      this.setState(state);
    } catch (e) {
      console.log('Error updating deleted list:', e);
    }
  };

  /**
   *  Resets the page's info when the tab is clicked.
   */
  handlePageReset = () => {
    this.setState({
      stv: false,
      selected: null,
      addNew: false,
      tab: 'Global',
    });
  };

  setTab = (event) => {
    this.setState({
      tab: event,
    });
  };

  /*
    Selects correct Components to render to User
  */
  renderMainContent = () => {
    if (this.state.stv) {
      return this.renderSingleView();
    }
    return this.renderList();
  };

  renderSingleView() {
    let { tab, selected } = this.state;
    let { accessLevel, accessType } = this.props;

    return (
      <span className="singleView">
        <SingleView
          accessLevel={accessLevel}
          accessType={accessType}
          tab={tab}
          selected={selected}
          callBack={this.stvCallback}
          errorHandler={this.props.errorHandler}
          addNew={this.state.addNew}
          handleSaveClick={this.createNewDistributionList}
          handleEditClick={this.editDistributionList}
          handleDeleteClick={this.deleteDistributionList}
          updateDistributionList={this.importCallback}
        />
      </span>
    );
  }

  renderList() {
    let distListTitle = (
      <FormattedMessage
        id="Websms.distListTitle"
        defaultMessage="Distribution List"
      />
    );
    let globalDistText = (
      <FormattedMessage
        id="Websms.globalDistText"
        defaultMessage="Global Distribution Lists"
      />
    );
    let personalDistText = (
      <FormattedMessage
        id="Websms.personalDistText"
        defaultMessage="Personal Distribution Lists"
      />
    );
    let { tab } = this.state;
    let { accessLevel, accessType } = this.props;
    let globalDistribution = this.state.global;
    let personalDistribution = this.state.personal;

    return (
      <Fragment>
        <div className="visible-desktop">
          <h3>{distListTitle}</h3>
          <span className="main">
            <Tabs activeKey={tab} onSelect={this.setTab}>
              <Tab eventKey="Global" title={globalDistText}>
                <GlobalList
                  accessLevel={accessLevel}
                  accessType={accessType}
                  list={globalDistribution}
                  tab={tab}
                  callBack={this.viewListCallback}
                  addNewCallback={this.singleViewList}
                  handleSelect={this.handleSelect}
                  errorHandler={this.props.errorHandler}
                />
              </Tab>
              <Tab eventKey="Personal" title={personalDistText}>
                <PersonalList
                  accessLevel={accessLevel}
                  accessType={accessType}
                  list={personalDistribution}
                  tab={tab}
                  callBack={this.viewListCallback}
                  addNewCallback={this.singleViewList}
                  handleSelect={this.handleSelect}
                  errorHandler={this.props.errorHandler}
                />
              </Tab>
            </Tabs>
          </span>
        </div>
        <div className="visible-device">
          <h3>{distListTitle}</h3>
          <span className="main">
            <Tabs activeKey={tab} onSelect={this.setTab}>
              <Tab eventKey="Global" title="Global">
                <GlobalList
                  accessLevel={accessLevel}
                  accessType={accessType}
                  list={globalDistribution}
                  tab={tab}
                  callBack={this.viewListCallback}
                  addNewCallback={this.singleViewList}
                  handleSelect={this.handleSelect}
                  errorHandler={this.props.errorHandler}
                />
              </Tab>
              <Tab eventKey="Personal" title="Personal">
                <PersonalList
                  accessLevel={accessLevel}
                  accessType={accessType}
                  list={personalDistribution}
                  tab={tab}
                  callBack={this.viewListCallback}
                  addNewCallback={this.singleViewList}
                  handleSelect={this.handleSelect}
                  errorHandler={this.props.errorHandler}
                />
              </Tab>
            </Tabs>
          </span>
        </div>
      </Fragment>
    );
  }

  render() {
    let LoadingText = (
      <FormattedMessage id="App.LoadingText" defaultMessage="Loading" />
    );

    if (this.state.error) return <div>Error: {this.state.errorMessage}</div>;
    else if (this.state.loading) {
      return (
        <WebSMSTabs
          tab="distribution"
          mobileMenuOpen={this.props.mobileMenuOpen}
          toggleMobileMenu={this.props.toggleMobileMenu}
          navigate={this.props.navigate}
          handlePageReset={this.handlePageReset}
        >
          <div className="Home">
            <div>{LoadingText}...</div>
          </div>
        </WebSMSTabs>
      );
    } else {
      return (
        <WebSMSTabs
          tab="distribution"
          mobileMenuOpen={this.props.mobileMenuOpen}
          toggleMobileMenu={this.props.toggleMobileMenu}
          navigate={this.props.navigate}
          handlePageReset={this.handlePageReset}
        >
          <div className="Home">
            <div className="lander">{this.renderMainContent()}</div>
          </div>
        </WebSMSTabs>
      );
    }
  }
}
