/*
  PurchaseRequest.js - Purchase Request class

  Author: David Mackenzie (2022)
  Company: Red Oxygen
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';

// CONFIGURATION
import config from '../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class PurchaseRequest {
  getTariffLines = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/billing/tariffs`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'GET',
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      console.log('response:', response);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (err) {
      errorHandler(err.message);
    }
  };

  purchaseMessages = async (
    tariffPlanId,
    tariffLineId,
    customerId,
    customerUserId,
    errorHandler
  ) => {
    // TODO: include tariff stuff
    try {
      const url = `${config.baseServerURL}/billing/purchase`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const body = {
        tariffPlanId: tariffPlanId,
        tariffLineId: tariffLineId,
        customerId: customerId,
        customerUserId: customerUserId,
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      console.log('response:', response);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (err) {
      errorHandler(err.message);
    }
  };
}
