/*
  FormattedMessages.js - FormattedMessages for Monitor page.

  Author: Adarsh Anup (2022)
  Company: Red Oxygen
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  statsTitle: (
    <FormattedMessage
      id="AdminSystemMonitor.statsTitle"
      defaultMessage="SMSC Service"
    />
  ),
  totalStatsTitle: (
    <FormattedMessage
      id="AdminSystemMonitor.totalStatsTitle"
      defaultMessage="Total Stats"
    />
  ),
  totalTwilioStatsTitle: (
    <FormattedMessage
      id="AdminSystemMonitor.totalTwilioStatsTitle"
      defaultMessage="Total Twilio Stats"
    />
  ),
};

export default FormattedMessages;
