/*
  List.js - Cost Centres List View Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

// COMPONENTS
import ListItem from './ListItem';

// I18N
import formattedMessages from './FormattedMessages';

class ListView extends Component {
  constructor(props) {
    super(props);

    /*
      - list: An array of Cost Centre objects
      - handleCostCentreClick: A function to handle when a User clicks the Cost
      Centre Name
      - handleContactClick: A function to handle when a User clicks the Customer
      Contact Name
    */

    this.state = {
      list: props.list,
    };
  }

  sortList(data) {
    let newList = data.sort(function (a, b) {
      if (a.cost_centre_name < b.cost_centre_name) return -1;
      if (a.cost_centre_name > b.cost_centre_name) return 1;
      return 0;
    });

    return newList;
  }

  /*
    Generates the Table View for Cost Centres
  */
  generateTable = () => {
    // let {list} = this.props;
    let list = this.sortList(this.props.list);
    let rows = [];

    for (let i = 0; i < list.length; i++) {
      rows.push(
        <ListItem
          key={i}
          id={list[i].cost_centre_id}
          name={list[i].cost_centre_name}
          contactName={list[i].customer_contact_name}
          users={list[i].user_count}
          onCostCentreClick={this.props.handleCostCentreClick}
          onContactClick={this.props.handleContactClick}
          element={list[i]}
        />
      );
    }

    return (
      <Table id="CostCentreList">
        <thead>
          <tr key="head">
            <th className="medium textLeft tableTop">
              {formattedMessages.name}
            </th>
            <th className="medium textLeft tableTop">
              {formattedMessages.contact}
            </th>
            <th className="medium textLeft tableTop">
              {formattedMessages.numberOfUsers}
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  };

  render() {
    if (this.state.list.length === 0) {
      return (
        <div>
          <span>No results found.</span>
        </div>
      );
    }

    return this.generateTable();
  }
}

export default ListView;
