/*
  Edit.js - Edit Administrator Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';

// COMPONENTS
import ErrorText from '../../../components/ErrorText';
import UsersTabs from '../index';

// I18N
import formattedMessages from './FormattedMessages';

// NETWORKS
import AdministratorRequest from './AdministratorRequest';
import CountryPicker from '../../../components/CountryPicker';
import { validatePhoneNumber } from '../../../utils/validate';
const administratorRequest = new AdministratorRequest();

class AdministratorEdit extends Component {
  constructor(props) {
    super(props);

    /*
      PROPS LIST
        - selected: The selected Customer Contact (Administrator) to view
        - contactId: If this is passed in, this will cause the Customer Contact
        to be fetched and used in place of props.selected
        - handleBackClick: Function to handle going back
        - errorHandler: Function to handle generated errors
        - noTab: Hides the LHS nav system (like if calling from another Component)
    */

    this.state = {
      access_levels: [],
      edits: {},
      error: '',
      isSaving: false,
      selected: null,
    };
  }

  async componentDidMount() {
    try {
      let { contactId, errorHandler } = this.props;
      let extras = await administratorRequest.getExtras(errorHandler);
      if (contactId) {
        extras.selected = await administratorRequest.getSingleAdministrator(
          contactId,
          errorHandler
        );
      }

      return this.setState(extras);
    } catch (e) {
      return this.setState({
        error: e.message,
      });
    }
  }

  /*
    Holds state changes from the form
  */
  handleFormChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    let edits = this.state.edits;
    if (name === 'formCountry') edits.contact_country = value;
    else edits[name] = value;

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles delete button click
  */
  handleDeleteAdmin = async () => {
    this.setState(
      {
        isSaving: true,
      },
      async () => {
        try {
          let { selected, errorHandler } = this.props;

          await administratorRequest.deleteAdministrator(
            selected.customer_contact_id,
            errorHandler
          );

          this.props.handleBackClick('refresh');
        } catch (e) {
          return this.setState({
            error: e.message,
            isSaving: false,
          });
        }
      }
    );
  };

  /*
    Handles form submission
  */
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        isSaving: true,
      },
      async () => {
        try {
          let { edits } = this.state;
          let { selected, errorHandler } = this.props;
          let validated = true;

          //Check the admin name not to be empty.
          if (
            edits.contact_name === '' ||
            this.props.selected.contact_name === ''
          ) {
            return this.setState({
              hasError: true,
              error: 'Requires a name.',
              isSaving: false,
            });
          }

          if (
            edits.contact_tn === '' ||
            this.props.selected.contact_tn === ''
          ) {
            return this.setState({
              hasError: true,
              error: 'Requires a Contact Phone Number.',
              isSaving: false,
            });
          }

          // Check contact phone number format.
          if (edits.contact_tn) {
            let validated = validatePhoneNumber(edits.contact_tn);
            if (!validated || edits.contact_tn.trim() === '') {
              return this.setState({
                hasError: true,
                error: formattedMessages.errFormatPhoneNumber,
                isSaving: false,
              });
            }
          }

          // Check mobile Phone number format.
          if (edits.contact_mobile && edits.contact_mobile !== '')
            validated = validatePhoneNumber(edits.contact_mobile);

          if (!validated) {
            return this.setState({
              hasError: true,
              error: formattedMessages.errFormatMobileNumber,
              isSaving: false,
            });
          }

          // Edit Administrator
          await administratorRequest.editAdministrator(
            selected.customer_contact_id,
            edits,
            errorHandler
          );

          this.props.handleBackClick('refresh');
        } catch (e) {
          return this.setState({
            error: e.message,
            isSaving: false,
          });
        }
      }
    );
  };

  /*
    Renders Error Component
  */
  generateErrorText = () => {
    if (!this.state.error) return null;
    return <ErrorText hasError={true} errorText={this.state.error} />;
  };

  /*
    Generates the Access Levels Select component
  */
  generateAccessLevelsSelect = () => {
    let { selected, access_levels, edits } = this.state;
    if (!selected || !selected.customer_contact_id) {
      selected = this.props.selected; // Set to props if no state
    }
    if (!selected) selected = {};
    let accessLevelList = [];

    for (let i = 0; i < access_levels.length; i++) {
      accessLevelList.push(
        <option key={access_levels[i].code} value={access_levels[i].code}>
          {access_levels[i].meaning}
        </option>
      );
    }
    return (
      <Form.Control
        onChange={this.handleFormChange}
        as="select"
        name="access_level"
        value={edits.access_level || selected.access_level}
      >
        {accessLevelList}
      </Form.Control>
    );
  };

  /*
   Generates the Country Select component
 */
  generateCountry = () => {
    let { selected, edits } = this.state;
    if (!selected || !selected.customer_contact_id) {
      selected = this.props.selected; // Set to props if no state
    }
    if (!selected) selected = {};
    console.log('country:' + selected.contact_country);
    return (
      <CountryPicker
        className="form-control"
        onChange={this.handleFormChange}
        returnDefaultCountryCode={undefined}
        defaultValue={
          edits.contact_country ||
          selected.contact_country ||
          '203aadda-8867-4425-b92b-a73e94fd2c66'
        }
      />
    );
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    let { selected } = this.state; // Try state first
    if (!selected || !selected.customer_contact_id) {
      selected = this.props.selected; // Set to props if no state
    }

    if (!selected) selected = {};

    return (
      <div key="AddAdmin" className="lander">
        <p
          onClick={() => this.props.handleBackClick('refresh')}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backToAdmin}</span>
        </p>
        <h3>{formattedMessages.adminInfo}</h3>
        <h5>{selected.contact_email}</h5>
        <br />
      </div>
    );
  };

  generateFormGroup = (controlId, label, selected, type) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="detail-label">
          {label}:
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            name={controlId}
            defaultValue={selected}
            type={type || 'text'}
          />
        </Col>
      </Form.Group>
    );
  };

  /*
    Handles whether or not to display the submit button
  */
  generateSaveButton = () => {
    if (this.state.isSaving) return <p>Processing...</p>;
    return (
      <Button className="maxHeight" onClick={this.handleSubmit}>
        {formattedMessages.save}
      </Button>
    );
  };

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  showConfirmationModal = () => {
    this.setState({
      showConfirmation: true,
    });
  };

  showConfirmationDialog = () => {
    return (
      <Modal
        show={this.state.showConfirmation}
        onHide={this.handleCloseConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deletion Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you would like to delete this administrator?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="maxHeight btn btn-primary"
            onClick={this.handleDeleteAdmin}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="maxHeight btn btn-primary"
            onClick={this.handleCloseConfirmation}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  /*
    Handles whether or not to display the submit button
  */
  generateDeleteButton = () => {
    if (this.state.isSaving) return null;
    return (
      <Button
        className="cancelUserType btn-red btn btn-danger"
        onClick={this.showConfirmationModal}
      >
        {formattedMessages.delete}
      </Button>
    );
  };

  checkValue = (val, type) => {
    if (val === (this.state.edits?.[type] || this.props.selected?.[type]))
      return true;
    return false;
  };

  generateForm = () => {
    let { selected } = this.state; // Try state first
    if (!selected || !selected.customer_contact_id) {
      selected = this.props.selected; // Set to props if no state
    }

    if (!selected) selected = {}; // Empty object so it won't crash at least

    return (
      <Form onChange={this.handleFormChange}>
        {this.generateFormGroup(
          'contact_name',
          '* Name',
          selected.contact_name
        )}
        {this.generateFormGroup(
          'contact_posn',
          'Position',
          selected.contact_posn
        )}
        {this.generateFormGroup(
          'contact_dept',
          'Department',
          selected.contact_dept
        )}
        {this.generateFormGroup(
          'contact_tn',
          '* Contact Phone',
          selected.contact_tn
        )}
        {this.generateFormGroup(
          'contact_mobile',
          'Mobile Phone',
          selected.contact_mobile
        )}
        {this.generateFormGroup(
          'contact_address1',
          'Street Address',
          selected.contact_address1
        )}
        {this.generateFormGroup(
          'contact_address2',
          'Continued...',
          selected.contact_address2
        )}
        {this.generateFormGroup('contact_city', 'City', selected.contact_city)}
        {this.generateFormGroup(
          'contact_state',
          'State',
          selected.contact_state
        )}
        {this.generateFormGroup(
          'contact_postcode',
          'Postcode',
          selected.contact_postcode
        )}

        <Form.Group as={Row} controlId="contact_country">
          <Form.Label column sm={2} className="detail-label">
            {'* Country'}:
          </Form.Label>
          <Col sm={8}>{this.generateCountry()}</Col>
        </Form.Group>

        <Form.Group as={Row} controlId="access_level">
          <Form.Label column sm={2} className="detail-label">
            {formattedMessages.accessLevel}:
          </Form.Label>
          <Col sm={8}>{this.generateAccessLevelsSelect()}</Col>
        </Form.Group>
        {/*this.generateFormGroup('linked_user_email', 'Linked User Email')*/}
        <Form.Group as={Row} controlId="billing_contact">
          <Form.Label column sm={2} className="detail-label">
            {formattedMessages.billingContactType}:
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              inline
              checked={this.checkValue('P', 'billing_contact')}
              label={formattedMessages.primary}
              name="billing_contact"
              type="radio"
              value="P"
              id={'billing_contact_primary'}
            />
            <Form.Check
              inline
              checked={this.checkValue('S', 'billing_contact')}
              label={formattedMessages.secondary}
              name="billing_contact"
              type="radio"
              value="S"
              id={'billing_contact_secondary'}
            />
            <Form.Check
              inline
              checked={this.checkValue('N', 'billing_contact')}
              label={formattedMessages.na}
              name="billing_contact"
              type="radio"
              value="N"
              id={'billing_contact_na'}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="sales_contact">
          <Form.Label column sm={2} className="detail-label">
            {formattedMessages.salesContactType}:
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              inline
              checked={this.checkValue('P', 'sales_contact')}
              label={formattedMessages.primary}
              name="sales_contact"
              type="radio"
              value="P"
              id={'sales_contact_primary'}
            />
            <Form.Check
              inline
              checked={this.checkValue('S', 'sales_contact')}
              label={formattedMessages.secondary}
              name="sales_contact"
              type="radio"
              value="S"
              id={'sales_contact_secondary'}
            />
            <Form.Check
              inline
              checked={this.checkValue('N', 'sales_contact')}
              label={formattedMessages.na}
              name="sales_contact"
              type="radio"
              value="N"
              id={'sales_contact_na'}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="technical_contact">
          <Form.Label column sm={2} className="detail-label">
            {formattedMessages.technicalContactType}:
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              inline
              checked={this.checkValue('P', 'technical_contact')}
              label={formattedMessages.primary}
              name="technical_contact"
              type="radio"
              value="P"
              id={'technical_contact_primary'}
            />
            <Form.Check
              inline
              checked={this.checkValue('S', 'technical_contact')}
              label={formattedMessages.secondary}
              name="technical_contact"
              type="radio"
              value="S"
              id={'technical_contact_secondary'}
            />
            <Form.Check
              inline
              checked={this.checkValue('N', 'technical_contact')}
              label={formattedMessages.na}
              name="technical_contact"
              type="radio"
              value="N"
              id={'technical_contact_na'}
            />
          </Col>
        </Form.Group>
        {this.generateErrorText()}
      </Form>
    );
  };

  generateMain() {
    let content = (
      <div className="Home">
        <div className="lander">
          {this.generateLander()}
          {this.generateForm()}
          <br />
          {this.generateSaveButton()}
          {this.showConfirmationDialog()}
          {this.generateDeleteButton()}
        </div>
      </div>
    );

    if (!this.props.noTab) {
      let newContent = <UsersTabs tab="admin">{content}</UsersTabs>;

      content = newContent;
    }

    return content;
  }

  render() {
    return this.generateMain();
  }
}

export default AdministratorEdit;
