import React from 'react';

const ListItem = ({
  id,
  title = '',
  content = '',
  onClick = null,
  ...props
}) => (
  <li key={id} id={id} onClick={onClick}>
    {title}
    <i id={id + 'icon'} className="material-icons floatRight">
      keyboard_arrow_right
    </i>
  </li>
);

export default ListItem;
