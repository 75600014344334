import React, { Component, Fragment } from 'react';
import { Form, Button } from 'react-bootstrap';
import ListComponent from '../ListComponent';
import DrilldownComponent from '../DrilldownComponent';
import FormattedMessages from './FormattedMessages';
import DateRangeSelector from '../../../../components/DateRangeSelector';
import AdminRequest from '../../AdminRequest';
const adminRequest = new AdminRequest();
export default class RecentComponent extends Component {
  constructor(props) {
    super(props);

    /*
            expected props:
                - getDrilldownMessages: Calls to the backend to retrieve drill down results.
                - getSmsIdDrilldownMessages: Calls to the backend to retrieve sms drill down results.
                - routeNameResults: Values to populate the dropdown for routeNameResults.
                - setRouteName: Sets the selected route name for the filter.
                - selectedRouteName: Passed in to populate the dropdown for routeNameResults.
        */

    this.state = {
      selectedEmailID: null,
      emailFilter: undefined,
      selectedResult: null,
      showFilterDetails: false,
      searchParams: null,
      timeRange: null,
      currentlySearching: {},
      open: false,
      selectorKeyRefresh: false,
    };
    this.setFilter = this.setFilter.bind(this);
  }

  componentDidMount() {
    // If prop exists, sets the parent component's local function (e.g. resetFuncion) to the paginator's changePage function
    if (this.props.setEmailFilter) this.props.setEmailFilter(this.setFilter);
  }

  setFilter(email) {
    this.setState({
      emailFilter: email,
      selectorKeyRefresh: !this.state.selectorKeyRefresh,
    });
  }

  /**
   * Drilldown into an email after being clicked.
   * @param {String | uuid} id
   */
  drilldownEmail = (id) => {
    this.setState({ selectedEmailID: id });
  };

  /** Returns from drilldown back to recent by deleting 'selectedEmailID'. */
  backToRecent = () => {
    this.setState({ selectedEmailID: null });
  };

  setSelectedResult = (id) => {
    if (this.state.selectedResult === id) {
      return this.setState({
        selectedResult: null,
      });
    }
    return this.setState({
      selectedResult: id,
    });
  };

  /**
   * Generates the content of the 'Recent' tab, which could either be a list of recent messages or drilldown into an email ID.
   * @returns
   */
  generateRecentPage() {
    let content;

    if (this.state.selectedEmailID) {
      return (
        <div>
          <DrilldownComponent
            selectedEmailID={this.state.selectedEmailID}
            backToRecent={this.backToRecent}
            getDrilldownMessages={this.props.getDrilldownMessages}
            getSmsIdDrilldownMessages={this.props.getSmsIdDrilldownMessages}
          />
        </div>
      );
    } else if (!this.state.data) {
      content = 'Loading...';
    } else if (this.state.data.length === 0) {
      content = 'No results found.';
    } else {
      content = (
        <div className="table table-middle">
          <ListComponent
            logs={this.state.data}
            drilldownEmail={this.drilldownEmail}
            selectedResult={this.state.selectedResult}
            setSelectedResult={this.setSelectedResult}
            showArrow
          />
        </div>
      );
    }

    return (
      <div>
        <br />
        <Button
          className="maxHeight float-right"
          onClick={() =>
            this.setState({
              selectorKeyRefresh: !this.state.selectorKeyRefresh,
            })
          }
        >
          {'Refresh'}
        </Button>
        <h5>{FormattedMessages.recentTitle}</h5>
        <br />
        {this.generateSearchTools()}
        {content}
      </div>
    );
  }

  /*
    Handles searching User Data between two dates
  */
  handleUserDataSearch = async (fromDate, toDate, timeRange) => {
    try {
      this.setState({ data: undefined });
      const searchParams = {
        timeRange: timeRange,
        timeRangeStart: fromDate,
        timeRangeEnd: toDate,
        accountFilter: this.state.accountFilter,
        emailFilter: this.state.emailFilter,
      };
      let recentLogData = await adminRequest.getRecentLogMessages(
        this.props.errorHandler,
        searchParams
      );
      console.log('data: ', recentLogData.length);
      this.setState({
        data: recentLogData,
        pageCount: recentLogData.length,
        timeRange: timeRange,
        pageLoaded: true,
        currentlySearching: {
          accountFilter: searchParams.accountFilter,
          emailFilter: searchParams.emailFilter,
        },
      });
    } catch (e) {
      console.error(e);
      return this.setState({
        error: e.message,
      });
    }
  };

  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'text' && target.value === '') delete target.value;

    console.log('handleInputChange event name:', name);
    console.log('handleInputChange event value:', value);

    this.setState({
      [name]: value,
    });
  }

  clearFilters = () => {
    this.setState({
      accountFilter: null,
      emailFilter: null,
    });
    document.getElementById('filter-form').reset();
  };
  toggleMenu = () => this.setState(({ open }) => ({ open: !open }));

  /* Generates the Search Tools for querying data */
  generateSearchTools = () => {
    // Have selected and invoice, hide search
    if (this.state.isSelected) return null;
    const { open, currentlySearching, selectorKeyRefresh } = this.state;

    // Destructures which filters the table is currently displaying fields on.
    let { accountFilter, emailFilter } = currentlySearching || {};

    let filterText = [];
    let filterArray = [accountFilter, emailFilter];
    let values = ['Account:', 'Email:'];

    // Pushes spans to an array for rendering. Iterates to avoid code duplication.
    filterArray.map((filter, i) => {
      if (filter)
        filterText.push(
          <span>
            {filterText.length === 0 ? '' : '; '} <b>{values[i]}</b> {filter}
          </span>
        );
    });

    return (
      <Fragment>
        <DateRangeSelector
          key={selectorKeyRefresh ? 1 : 0} // Changing the key remounts it
          handleDateSearch={(fromDate, toDate, timeRange) =>
            this.handleUserDataSearch(fromDate, toDate, timeRange)
          }
        ></DateRangeSelector>

        <div className="Home">
          <div className="lander">
            <br />
            <span className="showHideLink-left">
              <span className="advanced-filters" onClick={this.toggleMenu}>
                {open ? (
                  <i className="material-icons">keyboard_arrow_down</i>
                ) : (
                  <i className="material-icons">keyboard_arrow_right</i>
                )}
                Advanced Filters
              </span>

              <div className="clear-filters" onClick={this.clearFilters}>
                {FormattedMessages.clear}
              </div>
            </span>

            {open && (
              <div>
                <Form id="filter-form">
                  <table className="no-table-row-background">
                    <tbody className="filter-table">
                      <div className="filter">
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Account:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="accountFilter"
                                id="accountFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Account"
                              />
                            </div>
                          </td>
                        </div>
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Email:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="emailFilter"
                                id="emailFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Email"
                              />
                            </div>
                          </td>
                        </div>
                      </div>
                    </tbody>
                  </table>
                </Form>
                <br></br>
              </div>
            )}
            <div className="flex-between">
              {filterText.length > 0 && <div>{filterText}</div>}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  /**
   * Renders recent page.
   * @returns
   */
  render() {
    return this.generateRecentPage();
  }
}
