/*
  Add.js - Cost Centres Add Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

// COMPONENTS
import ErrorText from '../../../components/ErrorText';

// I18N
import formattedMessages from './FormattedMessages';

// NETWORK
import CostCentreRequest from './CostCentreRequest';
import AdministratorRequest from '../Administrators/AdministratorRequest';
const costCentreRequest = new CostCentreRequest();
const administratorRequest = new AdministratorRequest();

export default class CostCentreAdd extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - errorHandler: A function to handle error messages
        - handleBackClick: A function to handle back clicks from this Component
    */

    this.state = {
      contacts: [],
      edits: {},
      isSaving: false,
      error: null,
    };
  }

  async componentDidMount() {
    let contacts = await administratorRequest.getAdministrators(
      this.props.errorHandler
    );
    contacts = this.sortContacts(contacts);
    return this.setState({
      contacts: contacts,
    });
  }

  /*
   Sort contacts alphabetically by contact_name
  */
  sortContacts(contacts) {
    let sortedContacts = contacts.sort(function (a, b) {
      if (a.contact_name.toLowerCase() < b.contact_name.toLowerCase()) {
        return -1;
      }
      if (a.contact_name.toLowerCase() > b.contact_name.toLowerCase()) {
        return 1;
      }
      return 0;
      //sorts alphabetically
    });
    return sortedContacts;
  }

  /*
    Holds state changes from the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let { edits } = this.state;

    // Delete if User selecting None for Cost Centre Contact
    if (name === 'customer_contact_id' && value === 'None') {
      delete edits.customer_contact_id;
    } else {
      edits[name] = value;
    }

    // console.log('Edits:', edits);

    return this.setState({
      edits: edits,
    });
  };

  /*
    Validates form.  Returns whether form is valid and, if not valid,
    returns an error text as well.
  */
  validateForm = () => {
    let isValidForm = false;
    let errText = '';
    let { edits } = this.state;

    // Validation checks
    if (!edits.cost_centre_name) errText = 'Requires a Name.';

    // If no error text, form is valid
    if (!errText) isValidForm = true;

    return {
      isValid: isValidForm,
      error: errText,
    };
  };

  /*
    Handles form submission
  */
  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState(
      {
        isSaving: true,
      },
      async () => {
        let { edits } = this.state;
        let { isValid, error } = this.validateForm();

        // If have invalid form
        if (!isValid) {
          return this.setState({
            error: error,
            isSaving: false,
          });
        }

        // Add New Cost Centre
        await costCentreRequest.createCostCentre(
          edits,
          this.props.errorHandler
        );

        this.props.handleBackClick(true);
      }
    );
  };

  /*
    Renders Error Component
  */
  generateErrorText = () => {
    if (!this.state.error) return null;
    return <ErrorText hasError={true} errorText={this.state.error} />;
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="AddCostCentre" className="lander">
        <p onClick={() => this.props.handleBackClick()} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backToList}</span>
        </p>
        <h3>{formattedMessages.addNewCostCenter}</h3>
      </div>
    );
  };

  /*
    Generates a Select element with options containing
    Customer Contacts
  */
  generateContactSelect = () => {
    let { contacts } = this.state;
    let contactOptions = [];

    contactOptions.push(
      <option selected disabled hidden key={null} value={null}>
        {'Select One'}
      </option>
    );

    for (let i = 0; i < contacts.length; i++) {
      contactOptions.push(
        <option
          key={contacts[i].customer_contact_id}
          value={contacts[i].customer_contact_id}
        >
          {contacts[i].contact_name}
        </option>
      );
    }
    return (
      <Form.Control as="select" name="customer_contact_id">
        {contactOptions}
      </Form.Control>
    );
  };

  /*
    Generates the correct button state
  */
  generateButtons = () => {
    if (this.state.isSaving) return <p>Saving...</p>;
    return (
      <Form.Group controlId="buttons">
        <Button onClick={this.handleSubmit} className="maxHeight">
          {formattedMessages.save}
        </Button>
      </Form.Group>
    );
  };

  /*
    Generates the Cost Centre Add form
  */
  generateForm = () => {
    return (
      <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
        <Form.Group as={Row} controlId="cost_centre_name">
          <Form.Label column sm={2} className="detail-label">
            * {formattedMessages.name}:
          </Form.Label>
          <Col sm={8}>
            <Form.Control required name="cost_centre_name" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="customer_contact_id">
          <Form.Label column sm={2} className="detail-label">
            {formattedMessages.contactName}:
          </Form.Label>
          <Col sm={8}>{this.generateContactSelect()}</Col>
        </Form.Group>
        {this.generateErrorText()}
        {this.generateButtons()}
      </Form>
    );
  };

  render() {
    return (
      <Fragment>
        {this.generateLander()}
        {this.generateForm()}
      </Fragment>
    );
  }
}
