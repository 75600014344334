/*
  LanguageRequest.js - Service Config - Languages Networking File
  
  Contains networking functionality for fetching Languages

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../containers/RequestRabbit';

// CONFIG
import config from '../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class LanguageRequest {
  getLanguages = async (errorHandler) => {
    let url = `${config.baseServerURL}/language`;
    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    if (response.status > 399 && response.status < 600)
      errorHandler(response.body);

    let responseJSON = await response.json();
    return responseJSON;
  };
}
