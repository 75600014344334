import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  composeTitle: (
    <FormattedMessage
      id="Websms.ComposeTitle"
      defaultMessage="Compose"
    ></FormattedMessage>
  ),
  recipientsTitle: (
    <FormattedMessage
      id="Websms.RecipientsTitle"
      defaultMessage="Recipients"
    ></FormattedMessage>
  ),
  messageSend: (
    <FormattedMessage
      id="Websms.messageSend"
      defaultMessage="Message Send Confirmation"
    ></FormattedMessage>
  ),
  messageQueued: (
    <FormattedMessage
      id="Websms.messageQueued"
      defaultMessage="Message(s) are on the way!"
    ></FormattedMessage>
  ),
  messageTitle: (
    <FormattedMessage
      id="Websms.MessageTitle"
      defaultMessage="Message"
    ></FormattedMessage>
  ),
  messageText: (
    <FormattedMessage
      id="Websms.ComposeText"
      defaultMessage="Type your message or insert a template"
    ></FormattedMessage>
  ),
  templateButton: (
    <FormattedMessage
      id="Websms.TemplateButton"
      defaultMessage="Insert template"
    ></FormattedMessage>
  ),
  laterDateText: (
    <FormattedMessage
      id="Websms.LaterDateText"
      defaultMessage="Send this message at a later date and time"
    ></FormattedMessage>
  ),
  forwardRepliesText: (
    <FormattedMessage
      id="Websms.ForwardRepliesText"
      defaultMessage="Forward replies to my mobile phone"
    ></FormattedMessage>
  ),
  searchContactText: (
    <FormattedMessage
      id="Websms.SearchContactText"
      defaultMessage="Search and Add Contacts"
    ></FormattedMessage>
  ),
  sendButton: (
    <FormattedMessage
      id="Websms.SendButton"
      defaultMessage="Send"
    ></FormattedMessage>
  ),
  chooseTemplateText: (
    <FormattedMessage
      id="Websms.ChooseTemplateText"
      defaultMessage="Choose a Template"
    ></FormattedMessage>
  ),
  messageRemaining: (
    <FormattedMessage
      id="Websms.MessageRemaining"
      defaultMessage="User Messages Remaining"
    ></FormattedMessage>
  ),
  creditsRemaining: (
    <FormattedMessage
      id="Websms.CreditsRemaining"
      defaultMessage="Account Credits Remaining"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
