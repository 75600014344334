/*
  FormattedMessages.js - FormattedMessages for the top-level System Monitor Component

  Author: Adarsh Anup (2022)
  Company: Red Oxygen

*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  systemMonitorTitle: (
    <FormattedMessage
      id="Global.systemMonitorTitle"
      defaultMessage="System Monitor"
    />
  ),
  restartButtonTitle: (
    <FormattedMessage
      id="Global.restartButtonTitle"
      defaultMessage="In Case of Emergency, Break Glass:"
    />
  ),
  restartMuxButton: (
    <FormattedMessage
      id="Global.restartMuxButton"
      defaultMessage="Restart Mux (Outbound)"
    />
  ),
  restartMuxModalTitle: (
    <FormattedMessage
      id="Global.restartMuxModalTitle"
      defaultMessage="Mux Restart Confirmation"
    />
  ),
  restartMuxModalBody: (
    <FormattedMessage
      id="Global.restartMuxModalBody"
      defaultMessage="Are you sure you would like to restart the MUX?"
    />
  ),
  restartApiButton: (
    <FormattedMessage
      id="Global.restartApiButton"
      defaultMessage="Restart Api (Inbound)"
    />
  ),
  restartApiModalTitle: (
    <FormattedMessage
      id="Global.restartApiModalTitle"
      defaultMessage="Api Restart Confirmation"
    />
  ),
  restartApiModalBody: (
    <FormattedMessage
      id="Global.restartApiModalBody"
      defaultMessage="Are you sure you would like to restart the API? NOTE: You may see errors while the API is reconnecting to the database"
    />
  ),
};

export default FormattedMessages;
