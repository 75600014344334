/*
  Device/Search.js - Main Device Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Paginator from '../../../../components/Paginator';
import config from '../../../../Config';

// OUR COMPONENTS
import List from './List';

// NETWORK
import DeviceRequest from './DeviceRequest';

// STYLING
import '../../Admin.css';

// INIT
const deviceRequest = new DeviceRequest();

export default class DeviceSearch extends Component {
  constructor(props) {
    super(props);

    /*
      PROPS LIST
      - viewAllDevices: Function to handle viewing All Devices
      - showSingleDevice: Function to handle when a Device Name is clicked in Search Results
      - showSingleCustomer: Function to handle when a Customer Name is clicked in Search Results
      - showSingleUserReport: Function to handle when a Customer User Name is clicked in Search Results
      - errorHandler: Function to handle displaying errors
    */

    this.state = {
      devices: null,
      edits: {},
      hasSearched: false,
      paginatedDevices: null,
      pageNumber: 1,
      pageLength: config.defaultPL,
    };
  }

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;
    edits[name] = value;

    console.log('Edits is:', edits);

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles search form submission
  */
  handleSubmit = async (e) => {
    this.setState({ hasSearched: true, devices: null, paginatedDevices: null });

    console.log('Handle submit called');
    e.preventDefault();
    let data = this.state.edits;

    let devices = await deviceRequest.searchDevices(
      data,
      this.props.errorHandler
    );
    this.resetFunction();
    this.setState(
      { devices: devices, pageNumber: 1 },
      this.setPaginatedResults
    );
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="DeviceSearch" className="lander device">
        <h5 className="float-left">Device Search</h5>
        {this.generateViewAllDevicesButton()}
        <br></br>
      </div>
    );
  };

  /* Changes the amount of entries per pagination page. */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState(
        { pageLength: value, pageNumber: 1 },
        this.setPaginatedResults
      );
    }
  };

  setPaginatedResults = async () => {
    let { devices, pageNumber, pageLength } = this.state;
    const newList = devices.slice(
      (pageNumber - 1) * pageLength,
      pageNumber * pageLength
    );
    this.setState({
      paginatedDevices: newList,
    });
  };

  /**
   * Handles the change event when clicking a pagination number
   */
  changeEvent = async (i) => {
    this.setState(
      {
        pageNumber: i + 1,
      },
      this.setPaginatedResults
    );
  };

  generateFormGroup = (controlId, label) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <Form.Control name={controlId} type="text" />
        </Col>
      </Form.Group>
    );
  };

  generateViewAllDevicesButton = () => {
    return (
      <Form onSubmit={this.props.viewAllDevices}>
        <Button
          className="createProviderButton float-right"
          variant="primary"
          type="submit"
        >
          View ALL Devices
        </Button>
      </Form>
    );
  };

  generateRedOxSearchForm = () => {
    return (
      <div>
        <br></br>
        <br></br>
        <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
          {this.generateFormGroup('customer_id', 'Customer Id:')}
          {this.generateFormGroup('customer_user_id', 'User Id:')}
          {this.generateFormGroup('device_id', 'Device Id:')}
          {this.generateFormGroup('device_name', 'Device Name:')}
          <Button
            className="createProviderButton"
            variant="primary"
            type="submit"
          >
            Search
          </Button>
        </Form>
      </div>
    );
  };

  generateResultsTable = () => {
    const { paginatedDevices, hasSearched, devices } = this.state;

    if (!hasSearched) return;

    return (
      <div>
        <List
          devices={paginatedDevices}
          showSingleDevice={this.props.showSingleDevice}
          showSingleCustomer={this.props.showSingleCustomer}
          showSingleUserReport={this.props.showSingleUserReport}
          showSinglePhoneNumber={this.props.showSinglePhoneNumber}
        ></List>
        <Paginator
          itemCount={devices?.length}
          changeEvent={this.changeEvent}
          pageLength={this.state.pageLength}
          editFunction={this.changePageLength}
          changePageFunction={(ref) => (this.resetFunction = ref)}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="Home device-search">
        {this.generateLander()}
        {this.generateRedOxSearchForm()}
        <br />
        {this.generateResultsTable()}
      </div>
    );
  }
}
