import React, { Component } from 'react';
import ListComponent from './ListComponent';
import { Button, Modal } from 'react-bootstrap';

import MuxIdDrilldownComponent from './MuxIdDrilldownComponent';
import formattedMessages from '../FormattedMessages';
import AdminRequest from '../../AdminRequest';

const adminRequest = new AdminRequest();

class StatisticsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMuxID: null,
      muxRestarting: 0,
      apiRestarting: 0,
      showMuxRestartConfirmation: false,
      showApiRestartConfirmation: false,
    };
  }
  muxIdDrilldown = (e, isTwilio) => {
    this.setState({
      selectedMuxID: e,
      isTwilio: isTwilio,
    });
  };

  backToStatsPage = () => {
    console.log('back');
    this.setState({ selectedMuxID: null });
  };

  /*
    Convenience function to display Mux Restart Modal
  */
  handleMuxModal = () => {
    this.setState(
      {
        showMuxRestartConfirmation: !this.state.showMuxRestartConfirmation,
      },
      () => {
        this.setState({
          muxRestarting: 0,
        });
      }
    );
  };

  restartMux = async () => {
    this.setState({
      muxRestarting: 1,
    });
    let result = await adminRequest.sendMuxShutdown(this.props.errorHandler);
    // Failure
    if (result.length === 0) {
      this.setState({
        muxRestarting: 3,
      });
    }
    // Success
    else {
      this.setState({
        muxRestarting: 2,
      });
    }
  };

  /*
    Convenience function to display Api Restart Modal
  */
  handleApiModal = () => {
    this.setState(
      {
        showApiRestartConfirmation: !this.state.showApiRestartConfirmation,
      },
      () => {
        this.setState({
          apiRestarting: 0,
        });
      }
    );
  };

  restartApi = async () => {
    this.setState({
      apiRestarting: 1,
    });
    let result = await adminRequest.sendApiShutdown(this.props.errorHandler);
    // Failure
    if (result.length === 0) {
      this.setState({
        apiRestarting: 3,
      });
    }
    // Success
    else {
      this.setState({
        apiRestarting: 2,
      });
    }
  };

  showMuxConfirmationDialog = () => {
    let { showMuxRestartConfirmation, muxRestarting } = this.state;
    // Mux Restart Confirmation
    if (muxRestarting <= 1) {
      return (
        <Modal show={showMuxRestartConfirmation} onHide={this.handleMuxModal}>
          <Modal.Header closeButton>
            <Modal.Title>{formattedMessages.restartMuxModalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {muxRestarting === 0
              ? formattedMessages.restartMuxModalBody
              : 'Mux Restarting...'}
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={muxRestarting === 0 ? false : true}
              variant="primary"
              className="maxHeight btn btn-primary"
              onClick={this.restartMux}
            >
              Yes
            </Button>
            <Button
              disabled={muxRestarting === 0 ? false : true}
              variant="secondary"
              className="maxHeight btn btn-primary"
              onClick={this.handleMuxModal}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    // Mux Restart Completed
    else if (muxRestarting >= 2) {
      return (
        <Modal show={showMuxRestartConfirmation} onHide={this.handleMuxModal}>
          <Modal.Header closeButton>
            <Modal.Title>{formattedMessages.restartMuxModalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {muxRestarting === 2
              ? 'Mux Restart Succeeded'
              : 'Mux Restart Failed'}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleMuxModal}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  };

  showApiConfirmationDialog = () => {
    let { showApiRestartConfirmation, apiRestarting } = this.state;
    // Api Restart Confirmation
    if (apiRestarting <= 1) {
      return (
        <Modal show={showApiRestartConfirmation} onHide={this.handleApiModal}>
          <Modal.Header closeButton>
            <Modal.Title>{formattedMessages.restartApiModalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {apiRestarting === 0
              ? formattedMessages.restartApiModalBody
              : 'Api Restarting...'}
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={apiRestarting === 0 ? false : true}
              variant="primary"
              className="maxHeight btn btn-primary"
              onClick={this.restartApi}
            >
              Yes
            </Button>
            <Button
              disabled={apiRestarting === 0 ? false : true}
              variant="secondary"
              className="maxHeight btn btn-primary"
              onClick={this.handleApiModal}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    // Api Restart Completed
    else if (apiRestarting >= 2) {
      return (
        <Modal show={showApiRestartConfirmation} onHide={this.handleApiModal}>
          <Modal.Header closeButton>
            <Modal.Title>{formattedMessages.restartApiModalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {apiRestarting === 2
              ? 'Api Restart Succeeded'
              : 'Api Restart Failed'}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleApiModal}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  };

  generateStatsPage() {
    if (this.state.selectedMuxID) {
      return (
        <div>
          <MuxIdDrilldownComponent
            selectedMuxID={this.state.selectedMuxID}
            isTwilio={this.state.isTwilio}
            backToStats={this.backToStatsPage}
            getMuxIdDrilldown={this.props.getMuxIdDrilldown}
          />
        </div>
      );
    } else {
      return (
        <div className="visible-desktop">
          <ListComponent
            primaryQueueStats={this.props.primaryQueueStats}
            totalStats={this.props.totalStats}
            totalTwilioStats={this.props.totalTwilioStats}
            statsPageReady={this.props.statsPageReady}
            muxIdDrilldown={this.muxIdDrilldown}
          />
          <br />
          <h4>
            {formattedMessages.restartButtonTitle}
            <Button className="maxHeight" onClick={this.handleApiModal}>
              {formattedMessages.restartApiButton}
            </Button>
            <Button className="maxHeight" onClick={this.handleMuxModal}>
              {formattedMessages.restartMuxButton}
            </Button>
          </h4>
          {this.showMuxConfirmationDialog()}
          {this.showApiConfirmationDialog()}
        </div>
      );
    }
  }
  render() {
    return this.generateStatsPage();
  }
}

export default StatisticsComponent;
