import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import Paginator from '../../../../components/Paginator';
import config from '../../../../Config';
import MessagePartTooltip from '../../../../components/Miscellaneous/MessagePartTooltip';

// FORMATTED MESSAGES
import formattedMessages from '../../FormattedMessages';

class ListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLength: config.defaultPL,
      pageNumber: 1,
      error: null,
    };
  }

  /* 
   Changes the amount of entries per pagination page.
 */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState({ pageLength: value, pageNumber: 1 });
      this.changeEvent(1);
    }
  };

  changeEvent = (i) => {
    return this.setState({
      pageNumber: i + 1,
    });
  };

  handleList = () => {
    let { list } = this.props;
    let { pageLength, pageNumber } = this.state;

    if (list && typeof list !== 'undefined' && Array.isArray(list)) {
      let filteredList = [];
      let displayList = [];

      displayList = list.slice(
        (pageNumber - 1) * pageLength,
        pageNumber * pageLength
      );
      return {
        displayList: displayList,
        count: this.state.rows ? filteredList.length : list.length,
      };
    }
    return { count: 0 };
  };

  render() {
    let { count, displayList } = this.handleList();
    let { loading } = this.props;

    if (loading) {
      return <div>{formattedMessages.loadingText}...</div>;
    } else if (displayList.length === 0) {
      return (
        <div>
          <span>{formattedMessages.noResultsFound}</span>
        </div>
      );
    } else
      return (
        <Fragment>
          <Table className="table-new">
            <thead>
              <tr>
                <td className="medium tableTop centre" colSpan="4">
                  {formattedMessages.customerText}
                </td>
                <td className="medium tableTop column-left"></td>
                <td className="medium tableTop centre">
                  {formattedMessages.messagesText}
                  <MessagePartTooltip />
                </td>
                <td className="medium tableTop column-right"></td>
                <td className="medium tableTop centre">
                  {formattedMessages.remindersText}
                  <MessagePartTooltip />
                </td>
              </tr>
              <tr>
                <td className="medium tableBottom left">
                  {formattedMessages.customerId}
                </td>
                <td className="medium tableBottom textLeft">
                  {formattedMessages.customerName}
                </td>
                <td className="medium tableBottom textLeft">
                  {formattedMessages.providerName}
                </td>
                <td className="medium tableBottom">
                  {formattedMessages.activeUsers}
                </td>
                <td className="medium tableBottom column-left">
                  {formattedMessages.standardText}
                </td>
                <td className="medium tableBottom">
                  {formattedMessages.directText}
                </td>
                <td className="medium tableBottom column-right">
                  {formattedMessages.repliesText}
                </td>
                <td className="medium tableBottom">
                  {formattedMessages.sentText}
                </td>
              </tr>
            </thead>
            <tbody>{displayList}</tbody>
          </Table>
          <Paginator
            itemCount={count}
            changeEvent={this.changeEvent}
            changePageFunction={(ref) => (this.resetFunction = ref)}
            pageLength={this.state.pageLength}
            editFunction={this.changePageLength}
          />
        </Fragment>
      );
  }
}

export default ListComponent;
