import React from 'react';

const cancelledCustomer = ({
  id,
  createdDate,
  date,
  customerID,
  customerName,
  customerType,
  customerLocation,
  agent,
  numberOfUsers,
}) => (
  <tr key={id}>
    <td className="medium left">{createdDate}</td>
    <td className="medium">{date}</td>
    <td className="medium">{customerID}</td>
    <td className="medium">{customerName}</td>
    <td className="medium">{customerType}</td>
    <td className="medium">{customerLocation}</td>
    <td className="medium">{agent || 'No agent'}</td>
    <td className="medium column-left">{numberOfUsers || 0}</td>
  </tr>
);

export default cancelledCustomer;
