/**
 * Cost Centre Reports
 *
 * Returns Query with date range, recieves valid data from that range
 */

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';

// OUR COMPONENTS
import ListComponent from './ListComponent';
import ListItem from './ListItem';
import DateRangeSelector from '../../../components/DateRangeSelector';
import ReportTabs from '../index';
import UsageLog from './UsageLog';

// NETWORKING
import CostCentreReportRequest from './CostCentreReportRequests';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

// INSTANTIATE
const costCentreReportRequest = new CostCentreReportRequest();

export default class Centres extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props
        - customer: Customer Object
    */
    this.state = {
      smsList: null,
      loaded: false,
      showUsageLog: false,
      selectedData: null,
      activePage: 'list',
      fromDate: '',
      toDate: '',
      usageLogAccess: null,
    };
  }

  componentDidMount = async () => {
    if (this.props.customer && this.props.customer !== null) {
      let { user, errorHandler } = this.props;
      let admin = await costCentreReportRequest.getAdministrator(
        user.customer_contact_id,
        true,
        errorHandler
      );
      this.setState({ usageLogAccess: admin.usage_log_access_flag });
    }
  };

  /*
    Handles displaying the Usage Log component or not
  */
  showUsageLog = () => {
    return this.setState({
      showUsageLog: !this.state.showUsageLog,
    });
  };

  /*
    Handles when the search button is clicked for the report
  */
  handleCostCentreSearch = async (fromDate, toDate, timeRange) => {
    try {
      this.setState({
        // loaded: false
        dataExists: false,
        loading: true,
        data: [],
      });
      let searchParams = {};
      searchParams.fromDate = fromDate;
      searchParams.toDate = toDate;
      searchParams.timeRange = timeRange;
      searchParams.timeRangeStart = fromDate;
      searchParams.timeRangeEnd = toDate;
      let result = await costCentreReportRequest.getReportData(
        searchParams,
        this.props.errorHandler
      );

      let sortedResults = this.sortList(result);

      console.log(`sorted Data: ${JSON.stringify(sortedResults, null, 2)}`);
      this.setState(
        {
          dataExists: true,
          data: sortedResults,
          fromDate: fromDate,
          toDate: toDate,
          loading: false,
        },
        this.formatData
      );
    } catch (e) {
      // Handle Search error
    }
  };

  sortList(data) {
    let newList = data.sort(function (a, b) {
      if (a.cost_centre_name < b.cost_centre_name) {
        return -1;
      }
      if (a.cost_centre_name > b.cost_centre_name) {
        return 1;
      }
      return 0;
    });

    return newList;
  }

  /*
    Formats the data in a manner required for the Table
    to properly display the data
  */
  formatData = async () => {
    try {
      let total = {
        Direct: 0,
        Standard: 0,
        Replies: 0,
        Sent: 0,
        Pending: 0,
        DirectParts: 0,
        StandardParts: 0,
        RepliesParts: 0,
        SentParts: 0,
        PendingParts: 0,
        Users: 0,
      };

      let data = await this.state.data.map((el, index) => (
        <ListItem
          key={'key_' + index}
          id={0}
          name={el.cost_centre_name}
          userCount={el.userCount}
          Direct={{
            sent: el.direct_sent,
            parts: el.direct_parts,
          }}
          Standard={{
            sent: el.messages_sent,
            parts: el.messages_parts,
          }}
          Replies={{
            sent: el.replies_sent,
            parts: el.replies_parts,
          }}
          Sent={{
            sent: el.reminders_sent,
            parts: el.reminders_parts,
          }}
          Pending={{
            sent: el.reminders_pending,
            parts: el.reminders_pending_parts,
          }}
          onClickName={() =>
            this.props.navigate('/reports/user', {
              state: {
                stv: true,
                selectedName: el.cost_centre_name,
                selected: el.cost_centre_id,
                filtered: true,
              },
            })
          }
        />
      ));
      this.state.data.forEach((el) => {
        total.Direct = total.Direct + parseInt(el.direct_sent);
        total.Standard = total.Standard + parseInt(el.messages_sent);
        total.Replies = total.Replies + parseInt(el.replies_sent);
        total.Sent = total.Sent + parseInt(el.reminders_sent);
        total.Pending = total.Pending + parseInt(el.reminders_pending);
        total.Users = total.Users + parseInt(el.userCount);
        total.DirectParts = total.DirectParts + parseInt(el.direct_parts);
        total.StandardParts = total.StandardParts + parseInt(el.messages_parts);
        total.RepliesParts = total.RepliesParts + parseInt(el.replies_parts);
        total.SentParts = total.SentParts + parseInt(el.reminders_parts);
        total.PendingParts =
          total.PendingParts + parseInt(el.reminders_pending_parts);
      });

      return this.setState({
        smsList: data,
        totals: total,
        loaded: true,
      });
    } catch (e) {
      console.log('data error:', e);
    }
  };

  /*
    Convenience function to handle page navigation.
    TODO - Use React History to handle this
  */
  handleNavigation(target) {
    // window.location.href = '/reports/' + target;
    window.location.href = '/reports/user?id=' + target;
  }

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div className="lander">
        <h3>{formattedMessages.costCentreReportsTitle}</h3>
        <p>
          {formattedMessages.accountText}{' '}
          {this.props.customer ? this.props.customer.customer_name : ''}
        </p>
      </div>
    );
  };

  /*
    Generates the Search Tools for querying the data
  */
  generateSearchTools = () => {
    return (
      <Fragment>
        <DateRangeSelector
          handleDateSearch={(fromDate, toDate, timeRange) =>
            this.handleCostCentreSearch(fromDate, toDate, timeRange)
          }
        ></DateRangeSelector>
        <br />
        <br />
      </Fragment>
    );
  };

  // HANDLERS START
  changePage = async (page, value) => {
    // Update activePage
    return this.setState({
      activePage: page,
      selectedData: value,
    });
  };

  /*
    Generates the link to go to the User Message Content Report
  */
  generateUserContentLink = () => {
    let { accessLevel, accessType } = this.props;
    if (accessLevel < '1' || accessType < '1') return null;

    if (this.state.usageLogAccess === 'Y') {
      return (
        <Button className="searchRange" onClick={this.showUsageLog}>
          User Message Content Report
        </Button>
      );
    }
  };

  reloadPage = () => {
    window.location.reload(1);
  };

  render() {
    let { accessLevel, accessType } = this.props;

    if (accessLevel <= '0' || accessType === '0') {
      return null;
    }
    // Still Loading
    if (!this.state.loaded) {
      return (
        <ReportTabs
          tab="costCenter"
          accessLevel={accessLevel}
          accessType={accessType}
        >
          <div className="Home">
            <div className="lander">
              {this.generateLander()}
              {this.generateSearchTools()}
              <p>Loading...</p>
            </div>
          </div>
        </ReportTabs>
      );
    }

    // Showing Usage Log
    if (this.state.showUsageLog) {
      return (
        <UsageLog
          customer={this.props.customer}
          backClick={this.showUsageLog}
          errorHandler={this.props.errorHandler}
          accessLevel={accessLevel}
          accessType={accessType}
        />
      );
    }

    // Regular View
    return (
      <ReportTabs
        tab="costCenter"
        accessLevel={accessLevel}
        accessType={accessType}
      >
        <div className="Home">
          <div className="lander">{this.generateLander()}</div>
          {this.generateSearchTools()}
          {this.generateUserContentLink()}
          <br />
          <br />
          <ListComponent
            changePage={this.changePage}
            totals={this.state.totals}
            list={this.state.smsList}
            loading={!this.state.dataExists}
          />
        </div>
      </ReportTabs>
    );
  }
}
