// NETWORK
import RequestRabbit from '../../RequestRabbit';

// CONFIG
import config from '../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class BulkSendRequest {
  /*
    Creates a network request to upload a CSV file containing Contacts
  */
  uploadCSV = async (csvFile, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sms/bulk/csv`;

      let formData = new FormData();
      formData.set('file', csvFile);

      const options = {
        method: 'POST',
        body: formData,
      };

      // Send request with file attachment
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Handle error response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
      }

      // Return response for success
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  update = async (customerId, userId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sms/bulk/update`;
      const body = JSON.stringify({ customerId: customerId, userId: userId });
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const options = {
        method: 'POST',
        body: body,
        headers: headers,
      };

      // Send request with file attachment
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Handle error response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
      }

      // Return response for success
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Makes a request to create a new SMS object
  */
  sendBulkSMS = async (data, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sms/bulk/send`;
      const header = {
        'Content-Type': 'application/json',
      };
      console.log('createBulkSMS data:', data);

      // Convert to string if still an object
      if (typeof data === 'object') data = JSON.stringify(data);

      const options = {
        method: 'POST',
        body: data,
        headers: header,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      console.log('Send Bulk sms rsp:', responseJSON);
      return responseJSON;
    } catch (e) {
      console.log('Got createSMSBulk catch');
      errorHandler(e.message);
    }
  };

  /*
    Fetches Customer and CustomerUser data
  */
  getUser = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/users/user`;
      const options = {
        method: 'GET',
      };
      // Request
      const response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
