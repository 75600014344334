import React from 'react';

const ListItem = ({
  id,
  lineName = '',
  lineType = '',
  lineUnit = '',
  lineRate = '',
  lineQuantity = '',
  lineBatches = '',
  onClickName = null,
  line = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="column-left column-right" name="name">
      <span className="blue" onClick={() => onClickName(line)}>
        {lineName}
      </span>
    </td>
    <td className="medium left-wrap column-right" name="active">
      <span>{lineType}</span>
    </td>
    <td className="medium left-wrap column-right" name="active">
      <span>{lineUnit}</span>
    </td>
    <td className="medium column-right" name="active">
      <span>{lineRate}</span>
    </td>
    <td className="medium column-right" name="active">
      <span>{lineQuantity}</span>
    </td>
    <td className="medium column-right" name="active">
      <span>{lineBatches}</span>
    </td>
  </tr>
);

export default ListItem;
