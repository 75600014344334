// NPM MODULES
import React, { Component, Fragment } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Modal,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';

// I18N
import formattedMessages from '../FormattedMessages';
import { validatePhoneNumber } from '../../../../utils/validate';
export default class SingleView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      delete: false,
      entry_name: '',
      email: '',
      number: '',
      listToggle: 'Personal',
      dataPresent: false, // if true - have Contact data in props.selected
      saving: false,
      error: '',
    };
  }

  componentDidMount() {
    const { selected, tab } = this.props;
    // If have selected, a Contact was clicked
    if (selected && typeof selected !== 'undefined') {
      this.setState({
        entry_name: selected.entry_name || '',
        email: selected.email || '',
        number: selected.number || '',
        dataPresent: true,
      });
    }

    this.setState({
      listToggle: tab ? tab : 'Personal',
    });
  }

  /*
    Sets additional buttons on the screen based on whether or not
  */
  getDeleteButton() {
    let { accessLevel, accessType } = this.props;

    if (
      (accessLevel > '0' && accessType !== '0' && this.state.dataPresent) ||
      (this.state.dataPresent && this.props.tab === 'Personal')
    ) {
      return (
        <Button
          onClick={this.handleModal}
          className="delete btn-red btn btn-danger"
        >
          {formattedMessages.deleteText}
        </Button>
      );
    }
    return null;
  }
  /**
   * Convenience function that renders toggle buttons.
   * @returns
   */
  getToggleButton() {
    if (!this.state.dataPresent) {
      return (
        <Form.Group name="listToggle" as={Row} controlId="global_personal">
          <Form.Check
            inline
            onChange={this.handleToggle}
            checked={this.state.listToggle === 'Global'}
            label={formattedMessages.global}
            name="global_contact_list"
            type="radio"
            value="Global"
            id={'G'}
          />
          <Form.Check
            inline
            onChange={this.handleToggle}
            checked={this.state.listToggle === 'Personal'}
            label={formattedMessages.personal}
            name="personal_contact_list"
            type="radio"
            value="Personal"
            id={'P'}
          />
        </Form.Group>
      );
    }
    return null;
  }

  /*
    Handles displaying of Delete modal
  */
  handleModal = () => {
    return this.setState({
      delete: !this.state.delete,
    });
  };

  /*
    Handles state changes from the Form
  */
  handleChangeEvent = (e) => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'email') {
      value = value.toLowerCase();
    }

    return this.setState({
      [name]: value,
    });
  };

  /*
    Handles access level & type for read only view
  */
  handleAccessLevel = () => {
    let { accessLevel, accessType } = this.props;
    let { dataPresent } = this.state;

    return (accessLevel > '0' && accessType !== '0' && this.props.selected) || // if not customer user
      (this.props.selected && this.props.tab === 'Personal') || // if customer user is editing personal address book
      !dataPresent
      ? false
      : true; // if Customer User add New address book (Global && Personal), allow fully access; else, show ReadOnly view
  };

  /*
    Handles the change action for a toggle
  */
  handleToggle = (event) => {
    const { target } = event;
    return this.setState({
      listToggle: target.value,
    });
  };

  /*
    Handles when the Form is submitted
  */
  handleSubmit = async () => {
    // If not already saving...
    if (!this.state.saving) {
      let { entry_name, email, number } = this.state;

      // Needs entry_name
      if (!entry_name) {
        return this.setState({
          error: 'Entry Name is required',
        });
      }
      // Needs number
      if (!number) {
        return this.setState({
          error: 'Mobile Number is required',
        });
      }
      // If number isn't only digits

      if (!validatePhoneNumber(number)) {
        return this.setState({
          error:
            'A Mobile Number must contain only digits, dashes, brackets or spaces.  It may also contain a + sign at the start',
        });
      }
      await this.setState({
        saving: true,
      });

      // If Editing...
      if (this.state.dataPresent) {
        await this.props.edit(
          this.props.selected.addressbook_id,
          entry_name,
          email,
          number,
          this.state.listToggle || this.props.tab
        );
      }
      // Create
      else {
        await this.props.add(
          entry_name,
          email,
          number,
          this.state.listToggle || this.props.tab
        );
      }
      return;
    }
  };

  handleNewContact = () => {
    let { firstName, lastName, number } = this.state;
    let data = [`${firstName} ${lastName}`, number];

    return (
      <Fragment>
        <div className="col-sm-2">&nbsp;</div>
        <div className="col-sm-10">
          <Button
            className="maxHeight"
            onClick={() => this.props.handleSelect(true, data)}
          >
            {formattedMessages.save}
          </Button>
          <ToggleButtonGroup
            type="radio"
            name="listToggle"
            onChange={this.handleToggle}
            defaultValue="Personal"
          >
            <ToggleButton value="Global">
              {formattedMessages.global}
            </ToggleButton>
            <ToggleButton value="Personal">
              {formattedMessages.personal}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Fragment>
    );
  };

  handleButtonGroup = () => {
    let { accessLevel, accessType } = this.props;

    if (
      (accessLevel > '0' && accessType !== '0' && this.props.selected) || // if not customer user
      (this.props.selected && this.props.tab === 'Personal') || // if customer user && personal tab
      !this.state.dataPresent
    ) {
      // if customer user is add new contacts
      // if above three, show SAVE and CANCEL button
      return (
        <Fragment>
          <div className="col-sm-2">&nbsp;</div>
          <div className="col-sm-10">
            <Button className="maxHeight" onClick={this.handleSubmit}>
              {this.state.saving
                ? formattedMessages.saving
                : formattedMessages.save}
            </Button>
            <Button className="maxHeight" onClick={this.props.callBack}>
              {formattedMessages.cancelText}
            </Button>
            {this.getDeleteButton()}
          </div>
        </Fragment>
      );
    }
  };

  /*
    Handles any errors generated
  */
  generateError = () => {
    if (this.state.error) {
      return (
        <Fragment>
          <div className="col-sm-2">&nbsp;</div>
          <div className="col-sm-10 errorText textLeft">{this.state.error}</div>
        </Fragment>
      );
    }
    // Return no error if none present
    return null;
  };

  render() {
    let { dataPresent } = this.state;

    return (
      <Fragment>
        <div>
          <p onClick={this.props.callBack} className="backLink">
            <i className="material-icons">keyboard_arrow_left</i>
            <span>{formattedMessages.backText}</span>
          </p>
          <h4>
            {dataPresent ? this.props.tab + ' Contact Details' : 'New Contact'}
          </h4>
          <br />
          <Form onChange={this.handleChangeEvent}>
            {this.getToggleButton()}
            <br />
            <Form.Group as={Row} controlId="entry_name">
              <Form.Label column sm={2} className="detail-label">
                {formattedMessages.entryNameText}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="entry_name"
                  type="text"
                  defaultValue={
                    !dataPresent ? '' : this.props.selected.entry_name
                  }
                  readOnly={this.handleAccessLevel()}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="email">
              <Form.Label column sm={2} className="detail-label">
                {formattedMessages.emailText}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="email"
                  type="text"
                  defaultValue={!dataPresent ? '' : this.props.selected.email}
                  readOnly={this.handleAccessLevel()}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="number">
              <Form.Label column sm={2} className="detail-label">
                {formattedMessages.mobileText}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="number"
                  type="text"
                  defaultValue={!dataPresent ? '' : this.props.selected.number}
                  readOnly={this.handleAccessLevel()}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>{this.generateError()}</Form.Group>
            <Form.Group as={Row} controlId="buttons">
              {this.props.selectOnly
                ? this.handleNewContact()
                : this.handleButtonGroup()}
            </Form.Group>
          </Form>
        </div>
        <Modal show={this.state.delete} onHide={this.handleModal}>
          <Modal.Header closeButton>
            <Modal.Title>{formattedMessages.deleteContactText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{formattedMessages.deleteContactsBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleModal}>
              {formattedMessages.cancelText}
            </Button>
            <Button variant="danger" onClick={this.props.delete}>
              {formattedMessages.deleteContactText}
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}
