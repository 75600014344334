/*
  FormattedMessages.js - FormattedMessages for TableSearcher Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NOM MODULES
import React from 'react';
import { FormattedMessage } from 'react-intl';

const FormattedMessages = {
  quickReport: (
    <FormattedMessage
      id="Global.ErrorsTableSearcher"
      defaultMessage="Quick Report:"
    ></FormattedMessage>
  ),
  activityBetween: (
    <FormattedMessage
      id="Global.ErrorsActivityBetween"
      defaultMessage="Activity Between:"
    ></FormattedMessage>
  ),
  todayText: (
    <FormattedMessage id="Global.TodayText" defaultMessage="For Today Only">
      {(txt) => <option value="1">{txt}</option>}
    </FormattedMessage>
  ),
  yesterdayText: (
    <FormattedMessage
      id="Global.YesterdayText"
      defaultMessage="Since Yesterday"
    >
      {(txt) => <option value="2">{txt}</option>}
    </FormattedMessage>
  ),
  hoursText: (
    <FormattedMessage id="Global.HoursText" defaultMessage="Last 24 hours">
      {(txt) => <option value="24 hrs">{txt}</option>}
    </FormattedMessage>
  ),
  weekText: (
    <FormattedMessage id="Global.WeekText" defaultMessage="For the Last Week">
      {(txt) => <option value="7">{txt}</option>}
    </FormattedMessage>
  ),
  fortnightText: (
    <FormattedMessage
      id="Global.FortnightText"
      defaultMessage="For the Last Fortnight"
    >
      {(txt) => <option value="14">{txt}</option>}
    </FormattedMessage>
  ),
  monthText: (
    <FormattedMessage
      id="Global.MonthText"
      defaultMessage="For the Last Month (30 Days)"
    >
      {(txt) => <option value="30">{txt}</option>}
    </FormattedMessage>
  ),
  yearText: (
    <FormattedMessage
      id="Global.YearText"
      defaultMessage="For the Last Year (365 Days)"
    >
      {(txt) => <option value="365">{txt}</option>}
    </FormattedMessage>
  ),
  customText: (
    <FormattedMessage id="Global.customText" defaultMessage="Custom Range">
      {(txt) => (
        <option hidden value="custom">
          {txt}
        </option>
      )}
    </FormattedMessage>
  ),
  searchButton: (
    <FormattedMessage
      id="Global.SearchButton"
      defaultMessage="Search"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
