/**
 * Attempts to accurately divide to potential decimals.
 * @param {Number} a the first decimal number
 * @param {Number} b the second decimal number
 * @returns the quotient of both numbers, properly divided.
 */
exports.divide = function (a, b) {
  var commonMultiplier = 1000000;

  a *= commonMultiplier;
  b *= commonMultiplier;

  return a / b;
};

/**
 * Accurately multiplies to potential decimals.
 * @param {Number} a the first decimal number
 * @param {Number} b the second decimal number
 * @returns the product of both numbers, properly multiplied.
 */
exports.multiply = function (a, b) {
  var commonMultiplier = 1000000;

  a *= commonMultiplier;
  b *= commonMultiplier;

  return (a * b) / (commonMultiplier * commonMultiplier);
};
