// NPM MODULES
import React, { Component } from 'react';

class ErrorList extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - errors: Array of strings containing the error text
        - hasError: Boolean for whether or not to display the error component
    */

    this.state = {};
  }

  render() {
    // No error, nothing to show
    if (!this.props.hasError) return null;
    // Return errors
    return (
      <ul className="list-unstyled alert alert-danger">
        {this.props.errors.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    );
  }
}

export default ErrorList;
