/*
  InvoiceRequest.js - Invoice Request class

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';

// CONFIGURATION
import config from '../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class InvoiceRequest {
  /*
    Gets Invoices based on search criteria (if provided)
  */
  searchInvoices = async (searchObject, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/billing/`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify(searchObject) || null,
        headers: headers,
      };
      console.log('searchObject');
      console.log(searchObject);

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      console.log(responseJSON);
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };

  /*
    Kicks off the process in the back-end to refresh the current Invoice
  */
  refreshInvoice = async (billId, customerId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/billing/invoice/refresh`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify({ customerId: customerId, billId: billId }),
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      if (response.status === 200) {
        let responseJSON = await response.json();
        return responseJSON;
      } else return false;
    } catch (e) {
      errorHandler(e);
      return false;
    }
  };

  /*
      Kicks off the process in the back-end to email the current Invoice to the
      relevant Billing Contact
    */
  emailInvoice = async (providerId, customerId, billId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/billing/invoice/email`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify({
          providerId: providerId,
          customerId: customerId,
          billId: billId,
        }),
        headers: headers,
      };
      console.log('options: ', options.body);
      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      if (response.status === 200) {
        let responseJSON = await response.json();
        return responseJSON;
      } else return false;
    } catch (e) {
      errorHandler(e);
      return false;
    }
  };

  /*
    Given an ID for the Bill, retrieve all data for it
  */
  getSingleInvoice = async (customerId, billId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/billing/invoice`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify({ customerId: customerId, billId: billId }),
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return;
    }
  };

  /*
    Gets an overall summary of the Bill Lines and totals for the given
    Bill Id.
  */
  getInvoiceDetails = async (customerId, billId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/billing/invoice/details`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify({ customerId: customerId, billId: billId }),
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };

  /*
    Gets summarised User totals for the given Bill Id.
  */
  getUserInvoiceDetails = async (customerId, billId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/billing/invoice/user`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify({ customerId: customerId, billId: billId }),
        headers: headers,
      };
      console.log('options.body', options.body);

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      console.log('responseJSON', responseJSON);
      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };

  /*
    Gets full usage details for the given Bill Id.
  */
  getFullInvoiceDetails = async (customerId, billId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/billing/invoice/full`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify({ customerId: customerId, billId: billId }),
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };

  /**
   * Post request to process specific invoice
   * @param {*} customerId the id of the customer logged in
   * @param {*} billId id for bill (different to invoice id)
   * @param {*} errorHandler error handler
   * @returns result to update page results for invoice
   */
  processInvoiceTransaction = async (customerId, billId, errorHandler) => {
    try {
      console.log('processing invoice');
      const url = `${config.baseServerURL}/billing/invoice/pay`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify({
          customerId: customerId,
          billId: billId,
        }),
        headers: headers,
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      console.log(response);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };

  /*
    Create invoice adjustment for the given customer Id and bill Id.
  */
  createInvoiceAdjustment = async (
    customerId,
    billId,
    update,
    errorHandler
  ) => {
    try {
      const url = `${config.baseServerURL}/billing/invoice/adjust`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify({
          customerId: customerId,
          billId: billId,
          update: update,
        }),
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };
  /*
    Fetch extra fields for the selected Bill.
  */
  getExtraData = async (customerId, billId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/billing/invoice/adjust/${customerId}/${billId}`;
      const options = {
        method: 'GET',
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  updateStatus = async (
    providerId,
    customerId,
    billId,
    status,
    errorHandler
  ) => {
    try {
      const url = `${config.baseServerURL}/billing/invoice/updateflag`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify({
          customerId: customerId,
          billId: billId,
          providerId: providerId,
          status: status,
        }),
        headers: headers,
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      console.log(`response: ${JSON.stringify(response)}`);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };

  getXeroInformation = async (invoiceNo, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/xero`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify({
          invoiceNo: invoiceNo,
        }),
        headers: headers,
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      console.log(`response: ${JSON.stringify(response)}`);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return;
    }
  };

  voidXeroInvoice = async (billId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/xero/void`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify({
          billId: billId,
        }),
        headers: headers,
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      console.log(`response: ${JSON.stringify(response)}`);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return;
    }
  };
}
