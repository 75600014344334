// NPM
import React, { Component, Fragment } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { FilePicker } from 'react-file-picker';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

// COMPONENTS
import BulkSendRequest from './BulkSendRequest';
import WebSMSTabs from '../index';
import ListComponent from './List';
import Templates from '../TemplateList';
import StagedModal from '../../../components/StagedModal';
import ErrorText from '../../../components/ErrorText';

// UTIL
import {
  withinCharacterLimit,
  getMessageParts,
} from '../../../utils/characterCount';

// STYLING
import '../Websms.css';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';
import {
  validatePhoneNumber,
  stripNonNumerical,
} from '../../../utils/validate';

// NETWORK
const bulkSendRequest = new BulkSendRequest();

/*
  Creates the Compose React Component
*/
export default class BulkSend extends Component {
  constructor(props) {
    super(props);
    let dateNow = new Date();

    //localize date/time picker
    moment.locale('en');
    momentLocalizer();

    this.state = {
      allowScheduling: false,
      scheduledTime: dateNow,
      stage: 0,
      sendModalDisplay: false,
      templateModalDisplay: false,
      tempPersonal: null,
      tempGlobal: null,
      messageIn: 'C',
      template: { template_text: '' },
      csvFileObject: { name: 'Select a file to begin...' },
      fileSelected: false,
      showErrors: false,
      errorMessage: '',
      validRows: [],
      invalidRows: [],
      showInvalidRows: false,
      showRows: 'all',
      totalRows: [],
      totalRowsCount: 0,
      numberColumn: 1,
      nameColumn: 2,
      messageColumn: 3,
      creditRemaining: null,
      messagesRemaining: null,
      accountStatus: 'Loading...',
      removeHead: false,
      isDeleting: false,
      headerRow: null,
      pageNumber: 1,
      countryCode: '',
      hasError: true, // Set initially to true to disable the send until credit check complete
      errorText: '',
      rowsToBeDeleted: [],
    };
  }

  async componentDidMount() {
    let user = await bulkSendRequest.getUser(this.props.errorHandler);
    this.setState({
      countryCode: user.countryCode.country_code,
    });
    this.updateAccountStatus();
  }

  clearCheckboxes(name) {
    var elements = document.getElementsByClassName(
      'form-check-input position-static'
    );
    for (var i = 0, len = elements.length; i < len; i++) {
      if ((elements[i] ? elements[i].name : null) === name)
        elements[i].checked = false;
    }
  }

  selectRowsToBeDeleted = (event) => {
    const target = event.target;
    let { rowsToBeDeleted } = this.state;

    if (target.checked) {
      rowsToBeDeleted.push(event.target.id);
      return this.setState({
        rowsToBeDeleted: rowsToBeDeleted,
        isDeleting: true,
      });
    } else {
      rowsToBeDeleted = rowsToBeDeleted.filter((row) => {
        if (row !== event.target.id) return true;
      });
      if (rowsToBeDeleted.length !== 0) {
        return this.setState({
          rowsToBeDeleted: rowsToBeDeleted,
        });
      }
      if (rowsToBeDeleted.length === 0) {
        return this.setState({
          rowsToBeDeleted: rowsToBeDeleted,
          isDeleting: false,
        });
      }
    }
  };

  /*
    Handles delete button click
  */

  handleDeleteRow = () => {
    const { totalRows, rowsToBeDeleted } = this.state;
    let result = totalRows.filter((currentRow) => {
      return rowsToBeDeleted.includes(currentRow[0].toString()) ? false : true;
    });

    let { invalidRows, validRows } = this.getValidatedRows(result);
    this.clearCheckboxes('delete');
    this.resetPaginator();
    return this.setState({
      rowsToBeDeleted: [],
      totalRows: result,
      totalRowsCount: result.length,
      validRows: validRows,
      invalidRows: invalidRows,
      isDeleting: false,
      pageNumber: 1,
    });
  };

  changeScheduledDateTime(date) {
    this.setState({
      scheduledTime: date,
      hasError: date > Date.now() ? false : true,
      errorText:
        date > Date.now()
          ? ''
          : 'Date/Time invalid, please select a future Date/Time.',
    });
  }

  /*
   Convenience function to toggle Modal boolean
 */
  handleModal = async (modalName) => {
    if (this.state.allowScheduling && this.state.scheduledTime < Date.now()) {
      if (modalName === 'sendModalDisplay') {
        return this.setState({
          hasError: true,
          errorText: 'Date/Time invalid, please select a future Date/Time.',
        });
      }
    }
    this.setState({
      [modalName]: !this.state[modalName],
    });
  };

  captureFile = async (file) => {
    let result = await bulkSendRequest.uploadCSV(file, this.props.errorHandler);
    let headerRow = result.find((row) => {
      return row[0] === 1;
    });

    // Resets the state vars before validating rows, and then changing state again after that.
    this.setState(
      {
        numberColumn: 1,
        nameColumn: 2,
        messageColumn: 3,
        messageIn: 'C',
        template: { template_text: '' },
      },
      () => {
        let { invalidRows, validRows } = this.getValidatedRows(result);

        this.setState({
          csvFileObject: file,
          fileSelected: true,
          errorMessage: '',
          headerRow: headerRow,
          totalRows: result || 'No data found',
          totalRowsCount: result?.length || 0,
          invalidRows: invalidRows,
          validRows: validRows,
          pageNumber: 1,
          removeHead: false,
          showInvalidRows: false,
        });
      }
    );
  };

  updateAccountStatus = async () => {
    if (this.props.user) {
      let {
        user: { customer_id, customer_user_id },
        errorHandler,
      } = this.props;
      let { period, limit, messagesRemaining, creditRemaining } =
        (await bulkSendRequest.update(
          customer_id,
          customer_user_id,
          errorHandler
        )) || {};
      this.setState({
        creditRemaining: creditRemaining,
        messagesRemaining: messagesRemaining,
        accountStatus: `${period}/ ${limit}/ ${messagesRemaining}/ ${creditRemaining}`,
      });
      return {
        creditRemaining: creditRemaining,
        messagesRemaining: messagesRemaining,
      };
    }
    return {
      creditRemaining: 0,
      messagesRemaining: 0,
    };
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // console.log('Handling input change:', name, value);
    let inputChange = {};
    if (target.type === 'radio') {
      inputChange.pageNumber = 1;
      this.resetPaginator();
      this.clearCheckboxes('delete');
      this.setState({ rowsToBeDeleted: [], isDeleting: false });
    }

    this.setState(
      {
        ...inputChange,
        [name]: value,
      },
      () => {
        if (
          name === 'allowScheduling' &&
          value === false &&
          this.state.errorText ===
            'Date/Time invalid, please select a future Date/Time.'
        ) {
          this.setState({ hasError: false });
        }
        //Rerender invalid and valid rows when toggle the recipient number selector
        if (
          name === 'numberColumn' ||
          name === 'messageIn' ||
          name === 'messageColumn' ||
          name === 'nameColumn'
        ) {
          let { invalidRows, validRows } = this.getValidatedRows();
          this.setState({
            validRows: validRows,
            invalidRows: invalidRows,
          });
        }
      }
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handleSelect = (element) => {
    this.setState(
      {
        template: element,
        templateModalDisplay: false,
        messageIn: 'T',
      },
      () => {
        let { invalidRows, validRows } = this.getValidatedRows();
        this.setState({
          validRows: validRows,
          invalidRows: invalidRows,
        });
      }
    );
  };

  /*
    Calculate Message Credits Cost
  */
  calculateTotalCredits = (validRows) => {
    let { messageColumn, numberColumn, messageIn, template } = this.state;
    let creditsCost = 0;
    for (let i = 0; i < validRows.length; i++) {
      let element = validRows[i];
      let { countryCode } = this.state;

      let smsText = element[messageColumn] || '';

      // If Template check length for the template's text.
      if (messageIn === 'T') smsText = template?.template_text || '';

      let messagePartCount = getMessageParts(smsText);

      let internationalRecipient = false;
      if (
        element[numberColumn].slice(0, countryCode.length + 1) !==
          '+' + countryCode &&
        element[numberColumn][0] === '+'
      )
        internationalRecipient = true;

      creditsCost += messagePartCount * (internationalRecipient ? 2 : 1);
    }
    return creditsCost;
  };

  checkCreditsAndMessages = async () => {
    let { creditRemaining, messagesRemaining } =
      await this.updateAccountStatus();
    let { validRows } = this.state;
    if (
      (messagesRemaining || messagesRemaining === 0) &&
      messagesRemaining - validRows.length < 0
    ) {
      this.setState({
        hasError: true,
        errorText: 'Not enough messages remaining for this send.',
      });
      return true;
    } else if (
      (creditRemaining || creditRemaining === 0) &&
      creditRemaining - this.calculateTotalCredits(validRows) < 0
    ) {
      this.setState({
        hasError: true,
        errorText: 'Not enough credits remaining for this send.',
      });
      return true;
    }
    this.setState({
      hasError: false,
      errorText: '',
    });
    return false;
  };

  sendAction = async () => {
    if (this.state.stage === 0) {
      if ((await this.checkCreditsAndMessages()) === true)
        return this.setState({ stage: 3 });
      this.setState(
        {
          stage: 1,
          hasError: false,
          errorText: '',
        },
        async () => {
          let {
            messageColumn,
            numberColumn,
            nameColumn,
            allowScheduling,
            scheduledTime,
            stage,
            validRows,
          } = this.state;

          if (stage === 1) {
            const batchSize = 500;
            let result = true;

            let messagesToSend = [];
            // Replaces message with template if exists.
            for (let i = 0; i < validRows.length; i++) {
              messagesToSend.push({
                name: validRows[i][nameColumn],
                number: stripNonNumerical(validRows[i][numberColumn]),
                message: validRows[i].templateMessage
                  ? validRows[i].templateMessage
                  : validRows[i][messageColumn],
              });
            }

            // Batches requests to the backend in batches of 500.
            for (let i = 0; i < Math.ceil(validRows.length / batchSize); i++) {
              let data = {
                messages: messagesToSend.slice(
                  i * batchSize,
                  i * batchSize + batchSize
                ),
              };

              if (allowScheduling) {
                data.scheduled_time = scheduledTime;
              }

              // Make request
              let singleResult = await bulkSendRequest.sendBulkSMS(
                data,
                this.props.errorHandler
              );

              if (result === true) result = singleResult;
            }

            console.log(result);
            // TODO: replace current check with check for error.
            if (result === true) stage = 2;
            else stage = 3;
          }

          this.setState({
            stage: stage,
          });
        }
      );
    }
  };

  parseMessage(j, smsText, element) {
    let regText = `[${j}]`;
    smsText = smsText.replace(regText, element[j]);
    return smsText;
  }

  /**
   * Validate messages
   * @returns {boolean} return true if messages have empty content.
   */
  checkForEmptyMessages = () => {
    // TODO: this function can be phased out since this check is done in getValidated rows now
    let { validRows, messageColumn, template, messageIn } = this.state;
    let templateDisplayText = '';

    if (validRows.length === 0) return true;

    //check if the template is empty
    if (messageIn === 'T') {
      templateDisplayText = template?.template_text;
      if (!templateDisplayText) {
        return true;
      }
    } else {
      //check if the individual message is empty
      for (let i = 0; i < validRows.length; i++) {
        let singleMessage = validRows[i][messageColumn];
        if (!singleMessage || typeof singleMessage === 'undefined') return true;
      }
    }
  };

  /**
   * validate recipients numbers formats
   * @returns {object} count returns the total count of valid and invalid recipient number.
   */
  getValidatedRows = (result) => {
    let { totalRows, numberColumn, messageColumn, messageIn, template } =
      this.state;

    let incrementInvalidRow = [];
    let incrementValidRow = [];

    if (result) totalRows = result;

    for (let i = 0; i < totalRows.length; i++) {
      let singleRowNumber = totalRows[i][numberColumn];

      let newRow = [...totalRows[i]]; // This avoids nested copy issue

      // Validates phone number format, if within sms character limits and message is empty when not using templates
      if (
        !validatePhoneNumber(stripNonNumerical(singleRowNumber)) ||
        (!totalRows[i][messageColumn] &&
          (messageIn !== 'T' || !template?.template_text)) ||
        !withinCharacterLimit(totalRows[i][messageColumn])
      ) {
        newRow.valid = false;
        incrementInvalidRow.push(newRow);
      } else {
        newRow.valid = true;
        incrementValidRow.push(newRow);
      }
    }

    // Handles the replacement of [1] etc. with the appropriate column.
    for (let i = 0; i < incrementValidRow?.length; i++) {
      let element = incrementValidRow[i];
      let smsText = element[messageColumn] || '';

      // If the Template radio button is selected, set valid rows text to template text
      if (messageIn === 'T') smsText = template?.template_text || '';

      for (let j = 0; j < element.length; j++) {
        // ignore the message column unless we're using a template.
        if (j !== parseInt(messageColumn) || messageIn === 'T') {
          smsText = this.parseMessage(j, smsText, element);
        }
      }
      if (messageIn === 'T') {
        incrementValidRow[i].templateMessage = smsText;
      } else incrementValidRow[i][messageColumn] = smsText;
    }

    // Checks credit and messages remaining for the red error text.
    this.checkCreditsAndMessages();

    return {
      invalidRows: incrementInvalidRow,
      validRows: incrementValidRow,
    };
  };

  getFirstMessageValue = () => {
    let { messageColumn, validRows } = this.state;
    if (validRows.length > 0) {
      // Gets the first message's value to display.
      let msgValue =
        validRows[0].templateMessage || validRows[0][messageColumn]; // Print Message Content

      return msgValue;
    }
  };

  generateButton = () => {
    let {
      allowScheduling,
      scheduledTime,
      showInvalidRows,
      totalRowsCount,
      validRows,
      invalidRows,
      hasError,
      fileSelected,
      showRows,
    } = this.state;

    return (
      <Form.Group>
        <table width="100%" className="no-table-row-background">
          <tbody>
            <tr valign="top">
              <td>
                <Button
                  className="maxHeight"
                  onClick={() => this.handleModal('sendModalDisplay')}
                  disabled={
                    !fileSelected ||
                    hasError ||
                    this.checkForEmptyMessages() ||
                    showInvalidRows
                  }
                >
                  {formattedMessages.startButton}
                </Button>
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={allowScheduling}
                  value={allowScheduling}
                  name="allowScheduling"
                  onChange={(e) => this.handleInputChange(e)}
                />{' '}
                {formattedMessages.sendLater}
                <br />
              </td>
              <td>
                <DateTimePicker
                  format="DD/MMM/YYYY h:mm A"
                  initialValue={!allowScheduling ? null : scheduledTime}
                  value={!allowScheduling ? null : scheduledTime}
                  onChange={(value) => this.changeScheduledDateTime(value)}
                  min={new Date()}
                  disabled={!allowScheduling}
                />
              </td>
              <td align="right">
                <Form.Check
                  inline
                  onChange={(e) => this.handleInputChange(e)}
                  checked={showRows === 'all'}
                  label={`All Rows: ${totalRowsCount}`}
                  name="showRows"
                  type="radio"
                  value="all"
                  id={'showRows_all'}
                />
                <br />
                <Form.Check
                  inline
                  onChange={(e) => this.handleInputChange(e)}
                  checked={showRows === 'valid'}
                  label={`Valid Rows: ${validRows.length}`}
                  name="showRows"
                  type="radio"
                  value="valid"
                  id={'showRows_valid'}
                />
                <br />
                <Form.Check
                  inline
                  className={invalidRows.length > 0 ? 'red' : null}
                  onChange={(e) => this.handleInputChange(e)}
                  checked={showRows === 'invalid'}
                  label={`Invalid Rows: ${invalidRows.length}`}
                  name="showRows"
                  type="radio"
                  value="invalid"
                  id={'showRows_invalid'}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Form.Group>
    );
  };

  generateSendModal = () => {
    let { validRows, invalidRows, hasError, errorText, stage } = this.state;
    let msgValue = this.getFirstMessageValue();

    let modalSendConfirmation = `Are you sure you want to send this message to ${validRows.length} recipients?`;

    if (invalidRows.length > 0) {
      modalSendConfirmation = `${invalidRows.length} recipient's numbers are incorrect or empty.
      Messages can not be delivered to these recipients.\n
      Do you want to continue sending?
      If you answer 'Yes' recipients with incorrect numbers will be ignored.`;
    }
    let stageConfirmation = (
      <div>
        <b>{modalSendConfirmation}</b> <br />
        <br />
        Message Preview: <br /> {msgValue}
      </div>
    );
    return (
      <StagedModal
        showStaged={this.state.sendModalDisplay}
        stageTitle={
          stage === 3
            ? formattedMessages.modalSendFailureTitle
            : formattedMessages.modalSendTitle
        }
        stageConfirmation={stageConfirmation}
        stagePending={formattedMessages.modalSendPending}
        stage={stage}
        stageSuccess={`${validRows.length}/${validRows.length} Message(s) are on the way!`}
        stageFailure={hasError ? errorText : formattedMessages.modalSendFailure}
        removeStaged={() => {
          this.setState({ sendModalDisplay: false, hasError: false });
        }}
        removeStagedSlow={() => {
          this.setState({ stage: 0 });
        }}
        performAction={this.sendAction}
      />
    );
  };

  generateBody = () => {
    if (this.state.fileSelected) {
      let {
        validRows,
        invalidRows,
        numberColumn,
        nameColumn,
        messageColumn,
        pageNumber,
        showRows,
      } = this.state;
      let displayList = validRows;
      if (showRows === 'invalid') displayList = invalidRows;
      else if (showRows === 'all') {
        let combinedList = validRows.concat(invalidRows);
        displayList = combinedList.sort((a, b) => {
          return a[0] - b[0];
        });
      }
      return (
        <div>
          {this.generateDeleteButton()}
          <ListComponent
            list={displayList}
            numberColumn={numberColumn}
            nameColumn={nameColumn}
            messageColumn={messageColumn}
            pageNumber={pageNumber}
            changeEvent={(i) => {
              this.setState({
                pageNumber: i + 1,
                isDeleting: false,
                rowsToBeDeleted: [],
              });
              this.clearCheckboxes('delete');
            }}
            selectRowsToBeDeleted={this.selectRowsToBeDeleted}
            changePageFunction={(ref) => (this.resetPaginator = ref)}
          />
        </div>
      );
    }
  };

  generateDeleteButton = () => {
    return (
      <button
        disabled={!this.state.isDeleting}
        className="delete btn-red btn btn-danger"
        onClick={() => this.handleDeleteRow()}
      >
        {formattedMessages.deleteSelectedRow}
      </button>
    );
  };

  /**
   * Convenience render function due to the messageIn section requiring some computation before render.
   * @returns a Form Group with the messageIn section
   */
  generateRecipientInSection = () => {
    let { numberColumn, nameColumn } = this.state;
    let options = [
      <option key="empty" hidden value="empty"></option>,
      <option key="no_numbers" hidden value="no_numbers">
        select
      </option>,
    ];

    let { headerRow, fileSelected } = this.state;

    if (headerRow) {
      for (let i = 1; i < headerRow.length; i++)
        options.push(
          <option key={`option-${i}`} value={i}>
            {headerRow[i] + ` (Column ${i})`}
          </option>
        );
    }

    return (
      <Form.Group>
        <table width="100%" className="no-table-row-background">
          <tbody>
            <tr valign="left">
              <td style={{ width: '250px' }}>
                <Form.Label className="no-table-row-background">
                  {formattedMessages.mobileNumbers}
                </Form.Label>
              </td>
              <td colSpan={2}>
                <Form.Control
                  type="text"
                  as="select"
                  value={numberColumn}
                  onChange={this.handleInputChange}
                  name="numberColumn"
                  disabled={!fileSelected}
                >
                  {options}
                </Form.Control>
              </td>
            </tr>
            <tr>
              <td>
                <Form.Label className="no-table-row-background">
                  {formattedMessages.recipientNames}
                </Form.Label>
              </td>
              <td colSpan={2}>
                <Form.Control
                  type="text"
                  as="select"
                  value={nameColumn}
                  onChange={this.handleInputChange}
                  name="nameColumn"
                  disabled={!fileSelected}
                >
                  {options}
                </Form.Control>
              </td>
            </tr>
          </tbody>
        </table>
      </Form.Group>
    );
  };

  generateMessageInSection = () => {
    let { messageIn, template, messageColumn } = this.state;
    let templateDisplayText = '';
    let templateDisplayed = false;
    let csvColumnDisplay = messageColumn;
    let options = [
      <option key="empty" hidden value="empty"></option>,
      <option key="no_csv" hidden value="no_csv">
        Please upload a CSV
      </option>,
    ];

    // If the customer wants to send a template for the message text.
    if (messageIn === 'T') {
      templateDisplayText = template?.template_text
        ? `${template.template_name} (${template.template_text})`
        : 'Please select a template';
      templateDisplayed = true;
      csvColumnDisplay = 'empty';
    }
    // Else if the customer wants to just send message text supplied in the uploaded csv.
    else {
      let { headerRow } = this.state;

      // For each entry in the first row (excluding the first entry in the array as that is just the row number), create an options in
      // the select for the customer to select which column contains the message text.
      if (headerRow) {
        for (let i = 1; i < headerRow.length; i++)
          options.push(
            <option key={`option-${i}`} value={i}>
              {headerRow[i] + ` (Column ${i})`}
            </option>
          );
      }
      // If the first row is not found, then display a message to the customer showing that the CSV needs to be uploaded first.
      else if (!headerRow) {
        csvColumnDisplay = 'no_csv';
      }
    }

    return (
      <Form.Group>
        <table width="100%" className="no-table-row-background">
          <tbody>
            <tr valign="left">
              <td style={{ width: '250px' }}>
                <Form.Label>{formattedMessages.messageIn}</Form.Label>
              </td>
            </tr>
            <tr>
              <td>
                <Form.Label className="info-label-radio">
                  <Form.Check
                    type="radio"
                    name="messageIn"
                    value="C"
                    onChange={this.handleInputChange}
                    checked={!templateDisplayed}
                    id="csv_message_column"
                  />
                  {formattedMessages.csvFileColumn}
                </Form.Label>
              </td>
              <td colSpan={2}>
                <Form.Control
                  type="text"
                  as="select"
                  value={csvColumnDisplay}
                  disabled={templateDisplayed || csvColumnDisplay === 'no_csv'}
                  onChange={this.handleInputChange}
                  name="messageColumn"
                >
                  {options}
                </Form.Control>
              </td>
            </tr>
            <tr>
              <td>
                <Form.Label className="info-label-radio">
                  <Form.Check
                    type="radio"
                    name="messageIn"
                    value="T"
                    onChange={this.handleInputChange}
                    checked={templateDisplayed}
                    id="template_message_column"
                  />
                  {formattedMessages.templateRadio}
                </Form.Label>
              </td>
              <td>
                <Form.Control
                  className="default-cursor"
                  type="text"
                  value={templateDisplayed ? templateDisplayText : ''}
                  disabled
                />
              </td>
              <td align="right" style={{ width: '150px' }}>
                <Button
                  className="maxHeight"
                  onClick={() => this.handleModal('templateModalDisplay')}
                >
                  {formattedMessages.templateButton}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </Form.Group>
    );
  };

  generateForm = () => {
    let { accountStatus } = this.state;
    return (
      <Fragment>
        {this.generateRecipientInSection()}
        {this.generateMessageInSection()}
        <Form.Group>
          <table width="100%" className="no-table-row-background">
            <tbody>
              <tr valign="middle">
                <td style={{ width: '250px' }}>
                  <Form.Label>{formattedMessages.accountStatus}</Form.Label>
                </td>
                <td>
                  <Form.Control
                    type="text"
                    disabled
                    value={accountStatus}
                    name="accountStatus"
                  />
                </td>
                <td align="right" style={{ width: '100px' }}>
                  <Button
                    className="maxHeight"
                    onClick={() => this.checkCreditsAndMessages()}
                  >
                    {formattedMessages.updateButton}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Form.Group>
        <br />
        {this.generateButton()}
        {this.generateErrorText()}
        <Form.Group>{this.generateBody()}</Form.Group>
      </Fragment>
    );
  };

  generateLander = () => {
    return (
      <Fragment>
        <div>
          <br />
          <strong>{formattedMessages.landingText1}</strong>
          <ul>
            <li>{formattedMessages.landingText2}</li>
            <li>{formattedMessages.landingText3}</li>
            <li>{formattedMessages.landingText4}</li>
          </ul>
          <strong>
            {formattedMessages.landingText5}
            <a
              className="inline"
              href="/static/example_bulk_import.csv"
              download
            >
              {formattedMessages.landingText6}
            </a>
          </strong>
          <br />
          {formattedMessages.landingText7}
          <br />
          <p className="indentSpaced">{formattedMessages.landingText8}</p>
          <a>
            <img src={'/static/sample_csv_image.png'} />
          </a>
        </div>
      </Fragment>
    );
  };

  generateErrorText = () => {
    return (
      <ErrorText
        hasError={this.state.hasError}
        errorText={this.state.errorText}
      />
    );
  };

  render() {
    let { tempPersonal, tempGlobal, templateModalDisplay } = this.state;

    return (
      <Fragment>
        <WebSMSTabs
          tab="bulk-send"
          mobileMenuOpen={this.props.mobileMenuOpen}
          history={this.props.history}
          toggleMobileMenu={this.props.toggleMobileMenu}
          handlePageReset={() => {}}
          isMobile={this.props.isMobile}
        >
          <div className="Home">
            <div className="lander">
              <h3>{formattedMessages.bulkSendTitle}</h3>
              <br />
            </div>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <table width="100%" className="no-table-row-background">
                  <tbody>
                    <tr valign="middle">
                      <td style={{ width: '250px' }}>
                        <Form.Label>{formattedMessages.csvFile}</Form.Label>
                      </td>
                      <td>
                        <Form.Control
                          className="default-cursor"
                          type="text"
                          placeholder={this.state.csvFileObject.name}
                          disabled
                        />
                      </td>
                      <td align="right" style={{ width: '100px' }}>
                        <FilePicker
                          extensions={['csv']}
                          className="marginBottomMedium"
                          onChange={(fileObject) =>
                            this.captureFile(fileObject)
                          }
                          onError={(errMsg) =>
                            this.setState({ errorMessage: errMsg })
                          }
                        >
                          <button className="maxHeight btn btn-primary">
                            {formattedMessages.browseButton}
                          </button>
                        </FilePicker>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form.Group>
              {!this.state.fileSelected && this.generateLander()}
              {this.state.fileSelected && this.generateForm()}
            </Form>
          </div>
        </WebSMSTabs>
        <Modal
          show={templateModalDisplay}
          onHide={() => this.handleModal('templateModalDisplay')}
        >
          <Modal.Header closeButton>
            <Modal.Title>{formattedMessages.chooseTemplateText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Templates
              loading={this.state.isLoading}
              dateFormats={this.props.dateFormats}
              tempPersonal={tempPersonal}
              tempGlobal={tempGlobal}
              selectOnly
              handleSelect={this.handleSelect.bind(this)}
              hideLeftMenu={true}
              customer={this.props.customer}
            />
          </Modal.Body>
        </Modal>
        {this.generateSendModal()}
      </Fragment>
    );
  }
}
