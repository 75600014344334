import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import Paginator from '../../../../components/Paginator';
import config from '../../../../Config';
import MessagePartTooltip from '../../../../components/Miscellaneous/MessagePartTooltip';

// FORMATTED MESSAGES
import formattedMessages from '../../FormattedMessages';

class ListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLength: config.defaultPL,
      pageNumber: 1,
      error: null,
    };
  }

  /* 
   Changes the amount of entries per pagination page.
 */
  changePageLength = async (e) => {
    if (e.target) {
      const value = e.target.value;
      this.setState({ pageLength: value, pageNumber: 1 });
      this.changeEvent(1);
    }
  };

  changeEvent = (i) => {
    return this.setState({
      pageNumber: i + 1,
    });
  };

  handleList = () => {
    let { list } = this.props;
    let { pageLength, pageNumber } = this.state;

    if (list && typeof list !== 'undefined' && Array.isArray(list)) {
      let filteredList = [];
      let displayList = [];

      displayList = list.slice(
        (pageNumber - 1) * pageLength,
        pageNumber * pageLength
      );
      return {
        displayList: displayList,
        count: this.state.rows ? filteredList.length : list.length,
      };
    }
    return { count: 0 };
  };

  render() {
    let { count, displayList } = this.handleList();
    let { totals, loading } = this.props;

    if (loading) {
      return <div>{formattedMessages.loadingText}...</div>;
    } else
      return (
        <Fragment>
          {this.props.filter()}
          <Table className="table-new">
            <thead>
              <tr>
                <td
                  className="medium tableTop centre"
                  style={{ width: '50%' }}
                  colSpan="5"
                >
                  {formattedMessages.customerText}
                </td>
                <td className="medium tableTop column-left"></td>
                <td className="medium tableTop centre">
                  {formattedMessages.messagesText}
                  <MessagePartTooltip />
                </td>
                <td className="medium tableTop column-right"></td>
                <td className="medium tableTop centre" colSpan="2">
                  {formattedMessages.remindersText}
                  <MessagePartTooltip />
                </td>
              </tr>
              <tr>
                <td className="medium tableBottom left">
                  {formattedMessages.nameText}
                </td>
                <td className="medium tableBottom left">Type</td>
                <td className="medium tableBottom left">Class</td>
                <td className="medium tableBottom left">Status</td>
                <td className="medium tableBottom">
                  {formattedMessages.usersText}
                </td>
                <td className="medium tableBottom column-left">
                  {formattedMessages.directText}
                </td>
                <td className="medium tableBottom">
                  {formattedMessages.standardText}
                </td>
                <td className="medium tableBottom column-right">
                  {formattedMessages.repliesText}
                </td>
                <td className="medium tableBottom">
                  {formattedMessages.sentText}
                </td>
                <td className="medium tableBottom">
                  {formattedMessages.pendingText}
                </td>
              </tr>
            </thead>
            <tbody>
              {displayList}
              <tr>
                <td className="medium left">{formattedMessages.totalText}</td>
                <td className="medium"></td>
                <td className="medium"></td>
                <td className="medium"></td>
                <td className="medium">{totals.Users}</td>
                <td className="medium column-left">{`${totals.Direct} (${totals.DirectParts})`}</td>
                <td className="medium">{`${totals.Standard} (${totals.StandardParts})`}</td>
                <td className="medium column-right">{`${totals.Replies} (${totals.RepliesParts})`}</td>
                <td className="medium">{`${totals.Sent} (${totals.SentParts})`}</td>
                <td className="medium">{`${totals.Pending} (${totals.PendingParts})`}</td>
              </tr>
            </tbody>
          </Table>
          <Paginator
            itemCount={count}
            changeEvent={this.changeEvent}
            changePageFunction={(ref) => (this.resetFunction = ref)}
            pageLength={this.state.pageLength}
            editFunction={this.changePageLength}
          />
        </Fragment>
      );
  }
}

export default ListComponent;
