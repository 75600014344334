// NPM MODULES
import React, { Component } from 'react';
import { DateTime } from 'luxon';

// NETWORKING
import MessageReportRequest from './MessageReportRequest';

// FORMATTED MESSAGES
import FormattedMessages from './FormattedMessages';

// INSTANTIATE
const messageReportRequest = new MessageReportRequest();

export default class MessageReportSingle extends Component {
  constructor(props) {
    super(props);
    /*
      Expected Props:
        - backButtonClick: Function to go back to List view
        - selected: The sms id selected for the drilldown view
        - errorHandler: A prop passed down from the top level for handling errors
    */
    this.state = {
      smsData: {},
      loading: true,
    };
  }

  componentDidMount = async () => {
    let smsData = await messageReportRequest.getSingleMessageData(
      this.props.selected.smsId,
      this.props.selected.isEmailReply,
      this.props.errorHandler
    );

    this.setState({ smsData, loading: false });
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div>
        <p
          onClick={() => this.props.backButtonClick(false)}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{FormattedMessages.backText}</span>
        </p>
        <div className="lander">
          <h3>{'Message Details'}</h3>
        </div>
        <br></br>
      </div>
    );
  };

  generateContentRow = (header, value) => {
    return (
      <div>
        <b>{header}</b>
        <br />
        <span>{value}</span>
        <br />
        <br />
      </div>
    );
  };

  generateBody = () => {
    const { smsData } = this.state;

    const deliverDate = smsData.sms_delivered
      ? DateTime.fromISO(smsData.sms_delivered)
          .setZone(localStorage.getItem('timeShort'), {
            keepLocalTime: false,
          })
          .toFormat('dd-LLL-yyyy h:mm a')
      : 'Pending Delivery';

    const sentDate = DateTime.fromISO(smsData.sms_sent)
      .setZone(localStorage.getItem('timeShort'), {
        keepLocalTime: false,
      })
      .toFormat('dd-LLL-yyyy h:mm a');

    let delay = 'N/A';

    if (smsData.sms_delivered && smsData.sms_sent)
      delay =
        new Date(smsData.sms_delivered).getTime() -
        new Date(smsData.sms_sent).getTime() +
        ' (ms)';

    if (smsData) {
      return (
        <div>
          {this.generateContentRow(FormattedMessages.smsDelivered, deliverDate)}
          {this.generateContentRow(FormattedMessages.smsQueued, sentDate)}
          {this.generateContentRow(FormattedMessages.delay, delay)}
          {this.generateContentRow(
            FormattedMessages.destination,
            smsData.dest_tn
          )}
          {this.generateContentRow(
            FormattedMessages.countryText,
            smsData.country_code_name
          )}
          {this.generateContentRow(
            FormattedMessages.originator,
            smsData.originator
          )}
          {this.generateContentRow(FormattedMessages.typeText, smsData.smsType)}
          {this.generateContentRow(
            FormattedMessages.statusText,
            smsData.status
          )}
          {this.generateContentRow(
            FormattedMessages.userText,
            smsData.userEmail
          )}
          {this.generateContentRow(
            FormattedMessages.campaignId,
            smsData.campaignId
          )}
          {this.generateContentRow(
            FormattedMessages.encoding,
            smsData.smsc_params
          )}
          {this.generateContentRow(
            FormattedMessages.messageParts,
            smsData.no_of_sms_sent
          )}
          {this.generateContentRow(
            FormattedMessages.errorText,
            smsData.errorCode
          )}
        </div>
      );
    }

    return <span> No Data </span>;
  };
  render() {
    if (this.state.loading) return <span> Loading... </span>;

    return (
      <div className="Home">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
