/*
  Users index.js - User Main Component File

  Author:   Kyle Combeer (2019)
            Elle Dunbar (2020)
  Company:  Virtual Ark
*/
// NPM
import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap';

//I18N
import formattedMessages from './FormattedMessages';

// STYLING
import './Users.css';
import { Link } from 'react-router-dom';

export default class UsersTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: props.tab,
    };
  }

  /*
    Generates the sidebar menu items for use
  */
  generateUserMenu() {
    let tab = this.state.tab;
    return (
      <ButtonGroup vertical defaultValue={this.state.default}>
        <Link to="../administrators">
          <Button
            id="admin"
            variant="light"
            className={tab === 'admin' ? 'active' : ''}
          >
            {formattedMessages.admin}
          </Button>
        </Link>
        <Link to="../cost-centers">
          <Button
            id="costCenters"
            variant="light"
            className={tab === 'costCenters' ? 'active' : ''}
          >
            {formattedMessages.costCenters}
          </Button>
        </Link>
        <Link to="../list">
          <Button
            id="list"
            variant="light"
            className={tab === 'list' ? 'active' : ''}
          >
            {formattedMessages.users}
          </Button>
        </Link>
        <Link to="../find">
          <Button
            id="findUser"
            variant="light"
            className={tab === 'findUser' ? 'active' : ''}
          >
            {formattedMessages.findUser}
          </Button>
        </Link>
      </ButtonGroup>
    );
  }

  render() {
    return (
      <Container>
        <div className="visible-desktop-sideNav">
          <Row>
            <Col sm={3} id="sideNav" className="side-nav">
              {this.generateUserMenu()}
            </Col>
            <Col className="Users" sm={9}>
              {this.props.children}
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
