/*
  ComposeRequest.js - Handles composing of request for SMS Send

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NETWORK
import RequestRabbit from '../../RequestRabbit';

// CONFIG
import config from '../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class ComposeRequest {
  /*

    Makes a request to fetch all Contacts relating to the User.  This includes
    the Global Contacts list for the Customer, as well as the User's own Personal Contacts list.

    Inputs:
      - None

    Outputs:
      - An object containing two keys: 'global' and 'personal', each containing an array of
      Contact Objects.
  */

  getContacts = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/contacts/websms`;
      const options = {
        method: 'GET',
      };

      // Get Contacts
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      console.log(response, responseJSON);
      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Makes a request to create a new SMS object for the given destination numbers.  This
    includes a message body as well containing the SMS text to send.

    Inputs:
      - data: Object containing a 'message' text field and a 'user_tn' array containing
      one or more mobile phone numbers

    Outputs:
      - An object containing response details for the SMS send
  */
  createSMS = async (data, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sms/send`;
      const header = {
        'Content-Type': 'application/json',
      };

      // Convert to string if still an object
      if (typeof data === 'object') data = JSON.stringify(data);

      const options = {
        method: 'POST',
        body: data,
        headers: header,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      console.log('Send sms rsp:', responseJSON);
      return responseJSON;
    } catch (e) {
      console.log('Got createSMS catch');
      errorHandler(e.message);
    }
  };

  /*
    Fetches a count of the number of SMS the User has sent over the last 24 hours.
  */
  getNoSmsSentForUser = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sms/count`;
      const options = {
        method: 'GET',
      };

      // Get Count
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json(); //Get JSON verson of response

      console.log('responseJSON', responseJSON);
      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Creates a request to fetch a User's WebSMS settings
  */
  getSettings = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/users/settings`;
      const options = { method: 'GET' };
      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Fetches Customer and CustomerUser data
  */
  getUser = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/users/user`;
      const options = {
        method: 'GET',
      };
      // Request
      const response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
