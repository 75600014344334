exports.errorCodes = (code) => {
  switch (code) {
    case '2004':
      return {
        roxCode: '2004',
        meaning: 'Insufficient trial credits',
      };
    case '2015':
      return {
        roxCode: '2015',
        meaning: 'Invalid TN',
      };
    case '2018':
      return {
        roxCode: '2018',
        meaning: 'Insufficient credits',
      };
    case '2019':
      return {
        roxCode: '2019',
        meaning: 'Exceeded Max SMS',
      };
    case '2061':
      return {
        roxCode: '2061',
        meaning: 'Blacklisted number',
      };
    case '2062':
      return {
        roxCode: '2062',
        meaning: 'Number not whitelisted',
      };
    case '2110':
      return {
        roxCode: '2110',
        meaning: 'Expired scheduled message',
      };
    case '2123':
      return {
        roxCode: '2123',
        meaning: 'Routing error',
      };
    case '30003':
    // falls through
    case '3003':
      return {
        roxCode: '3003',
        meaning: 'Out of range',
      };
    case '30004':
    // falls through
    case '3004':
      return {
        roxCode: '3004',
        meaning: 'Carrier blockage',
      };
    case '30005':
    // falls through
    case '3005':
      return {
        roxCode: '3005',
        meaning: 'Unknown number',
      };
    case '30006':
    // falls through
    case '3006':
      return {
        roxCode: '3006',
        meaning: 'Landline number',
      };
    case '30007':
    // falls through
    case '3007':
      return {
        roxCode: '3007',
        meaning: 'Blocked message',
      };
    case '30024':
    // falls through
    case '3024':
      return {
        roxCode: '3024',
        meaning: 'Carrier issue',
      };
    case '30034':
    // falls through
    case '3034':
      return {
        roxCode: '3034',
        meaning: 'Message from unregistered number',
      };
    default:
      return {
        roxCode: '2999',
        meaning: 'Unknown error',
      };
  }
};
