/*
  FormattedMessages.js - FormattedMessages for the drilldown component for the Monitor page.

  Author: Adarsh Anup (2022)
  Company: Red Oxygen
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  dateText: (
    <FormattedMessage id="Global.dateText" defaultMessage="Date (UTC)" />
  ),
  logLevelText: (
    <FormattedMessage id="Global.logLevelText" defaultMessage="Level" />
  ),
  typeText: <FormattedMessage id="Global.typeText" defaultMessage="Type" />,
  functionText: (
    <FormattedMessage id="Global.functionText" defaultMessage="Function" />
  ),
  resultText: (
    <FormattedMessage id="Global.resultText" defaultMessage="Result" />
  ),
  relativeTimeText: (
    <FormattedMessage
      id="Global.relativeTimeText"
      defaultMessage="Processing Time"
    />
  ),
  relativeToggleText: (
    <FormattedMessage
      id="Global.relativeToggleText"
      defaultMessage="Relative"
    />
  ),
  routeNameText: (
    <FormattedMessage id="Global.routeNameText" defaultMessage="Route Name" />
  ),
};

export default FormattedMessages;
