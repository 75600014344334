import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  loading: (
    <FormattedMessage
      id="Global.Loading"
      defaultMessage="Loading..."
    ></FormattedMessage>
  ),
  key: (
    <FormattedMessage id="Global.key" defaultMessage="Key"></FormattedMessage>
  ),
  usersName: (
    <FormattedMessage
      id="Global.usersName"
      defaultMessage="User's Name"
    ></FormattedMessage>
  ),
  date: (
    <FormattedMessage id="Global.date" defaultMessage="Date"></FormattedMessage>
  ),
  time: (
    <FormattedMessage id="Global.time" defaultMessage="Time"></FormattedMessage>
  ),
  phone: (
    <FormattedMessage
      id="Global.phone"
      defaultMessage="Destination"
    ></FormattedMessage>
  ),
  type: (
    <FormattedMessage id="Global.type" defaultMessage="Type"></FormattedMessage>
  ),
  quantity: (
    <FormattedMessage
      id="Global.quantity"
      defaultMessage="Quantity"
    ></FormattedMessage>
  ),
  revenue: (
    <FormattedMessage
      id="Global.revenue"
      defaultMessage="Revenue"
    ></FormattedMessage>
  ),
  invoiceDetails: (
    <FormattedMessage
      id="Billing.invoiceDetails"
      defaultMessage="Invoice Details"
    ></FormattedMessage>
  ),
  userUsageDetails: (
    <FormattedMessage
      id="Billing.userUsageDetails"
      defaultMessage="User Usage Details"
    ></FormattedMessage>
  ),
  invoiceAnalysisFor: (
    <FormattedMessage
      id="Billing.invoiceAnalysisFor"
      defaultMessage="Invoice Analysis For"
    ></FormattedMessage>
  ),
  licenseAndMessageCharges: (
    <FormattedMessage
      id="Billing.licenseAndMessageCharges"
      defaultMessage="Licence and message charges for period"
    ></FormattedMessage>
  ),
  to: <FormattedMessage id="Billing.to" defaultMessage="to"></FormattedMessage>,
  licenseFees: (
    <FormattedMessage
      id="Billing.licenseFees"
      defaultMessage="License Fees"
    ></FormattedMessage>
  ),
  costCenterChargesSummary: (
    <FormattedMessage
      id="Billing.costCenterChargesSummary"
      defaultMessage="Cost Center Charges Summary"
    ></FormattedMessage>
  ),
  costCenter: (
    <FormattedMessage
      id="Billing.costCenter"
      defaultMessage="Cost Center"
    ></FormattedMessage>
  ),
  userName: (
    <FormattedMessage
      id="Billing.userName"
      defaultMessage="User Name"
    ></FormattedMessage>
  ),
  direct: (
    <FormattedMessage
      id="Billing.direct"
      defaultMessage="Direct"
    ></FormattedMessage>
  ),
  standard: (
    <FormattedMessage
      id="Billing.standard"
      defaultMessage="Standard"
    ></FormattedMessage>
  ),
  reminders: (
    <FormattedMessage
      id="Billing.reminders"
      defaultMessage="Reminders"
    ></FormattedMessage>
  ),
  replies: (
    <FormattedMessage
      id="Billing.replies"
      defaultMessage="Replies"
    ></FormattedMessage>
  ),
  international: (
    <FormattedMessage
      id="Billing.international"
      defaultMessage="International"
    ></FormattedMessage>
  ),
  others: (
    <FormattedMessage
      id="Billing.others"
      defaultMessage="Others"
    ></FormattedMessage>
  ),
  total: (
    <FormattedMessage
      id="Billing.total"
      defaultMessage="Total"
    ></FormattedMessage>
  ),
  messages: (
    <FormattedMessage
      id="Billing.messages"
      defaultMessage="Messages"
    ></FormattedMessage>
  ),
  currency: (
    <FormattedMessage
      id="Billing.currency"
      defaultMessage="A$"
    ></FormattedMessage>
  ),
  chargeBreakdownbyUserForCostCenter: (
    <FormattedMessage
      id="Billing.chargeBreakdownbyUserForCostCenter"
      defaultMessage="Charge breakdown by User for each Cost Center"
    ></FormattedMessage>
  ),
  taxInvoice: (
    <FormattedMessage
      id="Billing.taxInvoice"
      defaultMessage="Tax Invoice"
    ></FormattedMessage>
  ),
  attention: (
    <FormattedMessage
      id="Billing.attention"
      defaultMessage="Attn"
    ></FormattedMessage>
  ),
  invoicedDate: (
    <FormattedMessage
      id="Billing.invoicedDate"
      defaultMessage="Invoiced Date"
    ></FormattedMessage>
  ),
  yourRef: (
    <FormattedMessage
      id="Billing.yourRef"
      defaultMessage="Your ref"
    ></FormattedMessage>
  ),
  invoiceNo: (
    <FormattedMessage
      id="Billing.invoiceNo"
      defaultMessage="Invoice No"
    ></FormattedMessage>
  ),
  licenseAndMessagesFor: (
    <FormattedMessage
      id="Billing.licenseAndMessagesFor"
      defaultMessage="Licence and message charges for period"
    ></FormattedMessage>
  ),
  prepaidBillDate: (
    <FormattedMessage
      id="Billing.prepaidBillDate"
      defaultMessage="Messages purchased on"
    ></FormattedMessage>
  ),
  currencySymbol: (
    <FormattedMessage
      id="Global.currencySymbol"
      defaultMessage="$"
    ></FormattedMessage>
  ),
  softwareLicenseFees: (
    <FormattedMessage
      id="Billing.SoftwareLicenseFees"
      defaultMessage="Fees and Charges"
    ></FormattedMessage>
  ),
  nameOnCC: (
    <FormattedMessage
      id="ExpiredCardsReport.nameOnCard"
      defaultMessage="Name on Credit Card"
    ></FormattedMessage>
  ),
  cCLastFour: (
    <FormattedMessage
      id="Billing.CreditCardNumber"
      defaultMessage="Credit Card Number"
    ></FormattedMessage>
  ),
  youPaid: (
    <FormattedMessage
      id="Billing.YouPaid"
      defaultMessage="You Paid"
    ></FormattedMessage>
  ),
  paymentDueBy: (
    <FormattedMessage
      id="Billing.PaymentDueBy"
      defaultMessage="Payment Due By"
    ></FormattedMessage>
  ),
  totalChargesExcludingTax: (
    <FormattedMessage
      id="Billing.TotalChargesExcludingTax"
      defaultMessage="Total Charges Excluding Tax"
    ></FormattedMessage>
  ),
  gst: (
    <FormattedMessage id="Billing.GST" defaultMessage="GST"></FormattedMessage>
  ),
  tax: (
    <FormattedMessage id="Billing.Tax" defaultMessage="Tax"></FormattedMessage>
  ),
  totalCharges: (
    <FormattedMessage
      id="Billing.TotalChargesIncludingTax"
      defaultMessage="Total Charges Including Tax"
    ></FormattedMessage>
  ),
  creditCardSurcharge: (
    <FormattedMessage
      id="Billing.creditCardSurcharge"
      defaultMessage="Credit Card Surcharge"
    ></FormattedMessage>
  ),
  messageFees: (
    <FormattedMessage
      id="Billing.MessageFees"
      defaultMessage="Message Fees"
    ></FormattedMessage>
  ),
  selectLevelDetailsDisplay: (
    <FormattedMessage
      id="Billing.InvoiceDetailLevel"
      defaultMessage="Select level of details to display"
    ></FormattedMessage>
  ),
  showInvoice: (
    <FormattedMessage
      id="Billing.ShowInvoice"
      defaultMessage="Show Invoice"
    ></FormattedMessage>
  ),
  fullUsage: (
    <FormattedMessage
      id="Billing.fullUsage"
      defaultMessage="Full Usage Details"
    ></FormattedMessage>
  ),
  transitionToInvoiced: (
    <FormattedMessage
      id="Billing.TransitionInvoiced"
      defaultMessage="Transition to Invoiced"
    />
  ),
  processTransaction: (
    <FormattedMessage
      id="Billing.process"
      defaultMessage="Process Transaction"
    />
  ),
  cancelTransaction: (
    <FormattedMessage
      id="Billing.CancelTransaction"
      defaultMessage="Cancel Transaction"
    />
  ),
  failTransaction: (
    <FormattedMessage
      id="Billing.FailTransaction"
      defaultMessage="Fail Transaction"
    />
  ),
  NoDetailsFound: (
    <FormattedMessage
      id="Billing.NoDetailsFound"
      defaultMessage="No user usage details found."
    />
  ),
  NoDetailsPrepaid: (
    <FormattedMessage
      id="Billing.NoDetailsPrepaid"
      defaultMessage="User usage details not currently available for Prepaid Bills."
    />
  ),
};

export default FormattedMessages;
