/*
  Customer.js - Admin Customer Main Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM
import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// COMPONENTS
import ListItem from './ListItem';
import Edit from './Edit';
import Add from './Add';

// I18N
import formattedMessages from './FormattedMessages';

// STYLES
import '../Admin.css';

// NETWORK
import AdminRequest from '../AdminRequest';
import AdminTabs from '../index';
const adminRequest = new AdminRequest();

const CustomerContainer = (props) => {
  /*
    Expected Props:
      - customers: An array of Customer objects
      - customerId: (Optional) If present, load single Customer directly
      - action: An action to take against the Customer. Values include 'payment'
  */

  const { type, id } = useParams();

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedComments, setSelectedComments] = useState([]);
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [selectedCardDetails, setSelectedCardDetails] = useState(null);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showPaymentMethodPage, setShowPaymentMethodPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Load Payment section if action is present in props //TODO: Delete payment section
  if (type === 'payment') {
    setShowPaymentMethodPage(true);
    console.log(showPaymentMethodPage);
  }

  useEffect(() => {
    /*
      Used to display the Single Customer View
    */
    const _showSingleCustomer = async (customer) => {
      window.history.pushState(
        customer.customer_id,
        `Customer:${customer.customer_id}`,
        `/admin/customer/${customer.customer_id}`
      );
      let comments = await adminRequest.getCustomerComments(
        customer.customer_id,
        props.errorHandler
      );
      let attachments = await adminRequest.getCustomerAttachments(
        customer.customer_id,
        props.errorHandler
      );
      let cardDetails = await adminRequest.getCardDetails(
        customer.customer_id,
        props.errorHandler
      );

      setSelectedCustomer(customer);
      setSelectedComments(comments);
      setSelectedAttachments(attachments);
      setSelectedCardDetails(cardDetails);
      setLoading(false);
    };

    async function getData() {
      if (props.customer && props.customer !== null) {
        let customers = await adminRequest.getAllCustomers(props.errorHandler);
        // setup selected customer for window.location.href routes.
        if (!type) {
          for (let i = 0; i < customers.length; i++) {
            if (id === customers[i].customer_id)
              await _showSingleCustomer(customers[i]);
          }
        }

        if (type === 'payment' && id) {
          let navigatedToCustomerWithID = await adminRequest.getSingleCustomer(
            id,
            props.errorHandler
          );
          setShowPaymentMethodPage(true);
          setSelectedCustomer(navigatedToCustomerWithID);
          setLoading(false);
        } else if (customers) {
          if (props.location.state) {
            try {
              let { fromUnpaid, id: unpaidId } = props.location.state;
              if (fromUnpaid && unpaidId) {
                let customer;
                for (let i = 0; i < customers.length; i++) {
                  if (customers[i].customer_id === unpaidId) {
                    customer = customers[i];
                  }
                }
                if (customer) {
                  await _showSingleCustomer(customer);
                }
              }
              setCustomers(customers);
              setLoading(true);
            } catch (e) {
              setCustomers(customers);
              setLoading(false);
            }
          } else {
            setCustomers(customers);
            setLoading(false);
          }
        }
      }
    }

    getData();
  }, [props, type, id]);

  /*
    Used to fetch Customer
  */
  const getCustomers = async () => {
    try {
      let customers = await adminRequest.getAllCustomers(props.errorHandler);
      return customers;
    } catch (e) {
      setError(e.message);
    }
  };

  /*
    Used to display the Single Customer View
  */
  const showSingleCustomer = async (customer) => {
    window.history.pushState(
      customer.customer_id,
      `Customer:${customer.customer_id}`,
      `/admin/customer/${customer.customer_id}`
    );
    let comments = await adminRequest.getCustomerComments(
      customer.customer_id,
      props.errorHandler
    );
    let attachments = await adminRequest.getCustomerAttachments(
      customer.customer_id,
      props.errorHandler
    );
    let cardDetails = await adminRequest.getCardDetails(
      customer.customer_id,
      props.errorHandler
    );

    setSelectedCustomer(customer);
    setSelectedComments(comments);
    setSelectedAttachments(attachments);
    setSelectedCardDetails(cardDetails);
    setLoading(false);
  };

  /*
    Used to display the Add Customer View
  */
  const addNewCustomer = () => {
    setShowAddCustomer(true);
  };

  /*
    Handle back button click from Add/Edit Customer View
    Inputs:
      - to: Where the back button is going back to
  */
  const handleBackButtonClick = async (to) => {
    let { customerId } = props;
    let url = '/admin/customer';

    // Going back to Customer Single View & have Id in url
    if (to && to.toLowerCase() === 'customer' && customerId) {
      url += `/${customerId}`;
      window.history.pushState(
        { tab: 'customer' },
        'Customer: ' + customerId,
        url
      );
    }
    // Else if returning to the Customer List
    else if (!to || to.toLowerCase() === 'list') {
      window.history.pushState({ tab: 'customer' }, 'Customer List', url);
      props.navigate(url);
      setSelectedCustomer(null);
    }
    // Refresh the Customer list (eg. after creating new Customer)
    else if (to && to.toLowerCase() === 'refresh') {
      let customers = await getCustomers();
      setCustomers(customers);
      window.history.pushState({ tab: 'customer' }, 'Customer List', url);
      setSelectedCustomer(null);
    }

    setShowAddCustomer(false);
    setShowPaymentMethodPage(false);
  };

  /*
    Generates the Lander heading and text section
  */
  const generateLander = () => {
    return (
      <div key="CustomerSingleLander" className="lander flex">
        <h3>{formattedMessages.listOfCustomers}</h3>
        <Button
          className="btn add-new-template flexRight"
          onClick={() => addNewCustomer()}
        >
          {formattedMessages.addNewText}
        </Button>
      </div>
    );
  };

  /*
    Main Content generating function
  */
  const generateContent = () => {
    // Handle error state
    if (error) {
      return <span>{error}</span>;
    }

    // Display Single Customer view for editing or deleting
    else if (selectedCustomer) {
      return (
        <Edit
          customer={selectedCustomer}
          comments={selectedComments}
          attachments={selectedAttachments}
          cardDetails={selectedCardDetails}
          backButtonClick={handleBackButtonClick}
          errorHandler={props.errorHandler}
          updateActingCustomerDetails={props.updateActingCustomerDetails}
          navigate={props.navigate}
        />
      );
    }
    // Display Single Customer view for adding a new Customer
    else if (showAddCustomer) {
      return (
        <Add goBack={handleBackButtonClick} errorHandler={props.errorHandler} />
      );
    } else {
      // Default state - List View
      return [generateLander(), generateTable()];
    }
  };

  /*
    Generates whole table for Customers (headers and rows)
  */
  const generateTable = () => {
    return (
      <div key="CustomerList" className="customer table">
        <Table>
          <thead>
            <tr>
              <td
                className="tableTop column-right"
                style={{ width: '200px', marginLeft: '5px' }}
              >
                {formattedMessages.nameText}
              </td>
              <td className="medium tableTop column-left">
                {formattedMessages.typeClassStatus}
              </td>
            </tr>
          </thead>
          <tbody>{sortListForTable()}</tbody>
        </Table>
      </div>
    );
  };

  const sortListForTable = () => {
    let sortedCustomers = [].slice.call(customers).sort(function (a, b) {
      if (a.customer_name < b.customer_name) {
        return -1;
      }
      if (a.customer_name > b.customer_name) {
        return 1;
      }
      return 0;
    });

    return generateTableBody(sortedCustomers);
  };

  /*
    Generate Table Body
  */
  const generateTableBody = (customers) => {
    let rows = [];

    for (let i = 0; i < customers.length; i++) {
      rows.push(
        <ListItem
          key={customers[i].customer_id}
          id={customers[i].customer_id}
          name={customers[i].customer_name}
          type={customers[i].customer_type_name || ''}
          cust_class={customers[i].customer_class_name || ''}
          active={customers[i].customer_status_name || ''}
          customer={customers[i]}
          onClickName={(e) => showSingleCustomer(e)}
        />
      );
    }
    return rows;
  };

  // render return
  let { accessLevel, accessType } = props;

  if (accessLevel === '3' && accessType === '3') {
    if (loading) {
      return (
        <AdminTabs
          tab="customers"
          accessLevel={accessLevel}
          accessType={accessType}
        >
          Loading...
        </AdminTabs>
      );
    }
    return (
      <AdminTabs
        tab="customers"
        accessLevel={accessLevel}
        accessType={accessType}
      >
        {generateContent()}
      </AdminTabs>
    );
  }
};

export default CustomerContainer;
