/*
  FormattedMessages.js - FormattedMessages for editComponent within Single View Customer of topuser reports Component

  Author: Julius Klaebe (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  accountID: (
    <FormattedMessage id="Global.AccountID" defaultMessage="Account ID" />
  ),
  searchForACustomer: (
    <FormattedMessage
      id="Global.searchForACustomer"
      defaultMessage="Search for a Customer"
    />
  ),
  searchForUser: (
    <FormattedMessage
      id="Global.searchForUser"
      defaultMessage="Search for a User"
    />
  ),
  name: <FormattedMessage id="Global.Name" defaultMessage="Name" />,
  domain: <FormattedMessage id="Global.Domain" defaultMessage="Domain" />,
  authCode: (
    <FormattedMessage
      id="Global.AuthenticationCode"
      defaultMessage="Authentication Code"
    />
  ),
  ipAddress: (
    <FormattedMessage id="Global.IPAddress" defaultMessage="IP Address" />
  ),
  find: <FormattedMessage id="Global.Find" defaultMessage="Find" />,
  searchForAUser: (
    <FormattedMessage
      id="Global.SearchForAUser"
      defaultMessage="Search for a User"
    />
  ),
  userID: <FormattedMessage id="Global.UserID" defaultMessage="User ID" />,
  email: <FormattedMessage id="Global.Email" defaultMessage="Email" />,
  cell: (
    <FormattedMessage id="Global.CellNumber" defaultMessage="Cell Number" />
  ),
  searchForAnAdmin: (
    <FormattedMessage
      id="Global.SearchForAnAdministrator"
      defaultMessage="Search for an Administrator"
    />
  ),
  findAnErrorByEmail: (
    <FormattedMessage
      id="Global.FindAnErrorByEmail"
      defaultMessage="Find an Error by email recipient"
    />
  ),
  findMessagesViaDestNum: (
    <FormattedMessage
      id="Global.FindMessagesViaDestNum"
      defaultMessage="Find Messages via the destination number"
    />
  ),
  needToIncludeCountryCodeAndCell: (
    <FormattedMessage
      id="Global.NeedToIncludeCountryCodeAndCell"
      defaultMessage="You need to include the country code followed by the mobile phone number."
    />
  ),
  destNumb: (
    <FormattedMessage
      id="Global.DestNumb"
      defaultMessage="Destination Number"
    />
  ),
  noResultsFound: (
    <FormattedMessage
      id="Global.NoResultsFound"
      defaultMessage="No results found."
    />
  ),
  errorReturningResults: (
    <FormattedMessage
      id="Global.ErrorReturningResults"
      defaultMessage="Error returning results."
    />
  ),
};

export default FormattedMessages;
