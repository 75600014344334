import React, { Component } from 'react';
import ListItem from './ListItem';
import FormattedMessages from './FormattedMessages';
// STYLING
import '../../../../Users/Users.css';

class ListView extends Component {
  /*
      Creates the Table/List to display all log data
  */
  generateTables = () => {
    if (this.props.statsPageReady) {
      return (
        <div key="statsLists" className="statsTables">
          <div key="primaryQueueStatsList" className="primaryQueueStatstable">
            <h5 style={{ marginTop: '20px' }}>
              {FormattedMessages.statsTitle}
            </h5>
            <table width="50%">
              <thead>
                <tr>
                  <th className="tableTop column-left">Queue Name</th>
                  <th className="tableTop column-left">Number of messages</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Primary Inbound</td>
                  <td>{this.props.primaryQueueStats.PrimaryInbound}</td>
                </tr>
                <tr>
                  <td>Primary Outbound High</td>
                  <td>{this.props.primaryQueueStats.PrimaryOutbound_H}</td>
                </tr>
                <tr>
                  <td>Primary Outbound Medium</td>
                  <td>{this.props.primaryQueueStats.PrimaryOutbound_M}</td>
                </tr>
                <tr>
                  <td>Primary Outbound Low</td>
                  <td>{this.props.primaryQueueStats.PrimaryOutbound_L}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div key="totalStatsList" className="totalStatstable">
            <h5 style={{ marginTop: '20px' }}>
              {FormattedMessages.totalStatsTitle}
            </h5>
            <table width="100%">
              <thead>
                <tr>
                  <th className="tableTop column-left">MuxId</th>
                  <th className="tableTop column-left">Status</th>
                  <th className="tableTop column-left">Protocol</th>
                  <th className="tableTop column-left">TX_OK</th>
                  <th className="tableTop column-left">TX_ERR</th>
                  <th className="tableTop column-left">RX</th>
                  <th className="tableTop column-left">Today</th>
                  <th className="tableTop column-left">H</th>
                  <th className="tableTop column-left">M</th>
                  <th className="tableTop column-left">L</th>
                  <th className="tableTop column-left">Age (s)</th>
                </tr>
              </thead>
              <tbody>
                {this.generateTotalStatsTableBody(this.props.totalStats, false)}
              </tbody>
            </table>
          </div>

          <div key="totalTwilioStatsList" className="totalTwilioStatstable">
            <h5 style={{ marginTop: '20px' }}>
              {FormattedMessages.totalTwilioStatsTitle}
            </h5>
            <table width="100%">
              <thead>
                <tr>
                  <th className="tableTop column-left">MuxId</th>
                  <th className="tableTop column-left">Status</th>
                  <th className="tableTop column-left">Protocol</th>
                  <th className="tableTop column-left">TX_OK</th>
                  <th className="tableTop column-left">TX_ERR</th>
                  <th className="tableTop column-left">RX</th>
                  <th className="tableTop column-left">Today</th>
                  <th className="tableTop column-left">H</th>
                  <th className="tableTop column-left">M</th>
                  <th className="tableTop column-left">L</th>
                  <th className="tableTop column-left">Age (s)</th>
                </tr>
              </thead>
              <tbody>
                {this.generateTotalStatsTableBody(
                  this.props.totalTwilioStats,
                  true
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <p>Loading...</p>;
    }
  };

  /*
     Generates the body of the Table/List to display total stats / total twilio stats
   */
  generateTotalStatsTableBody = (stats, isTwilio) => {
    let rows = [];
    let { muxIdDrilldown } = this.props;
    for (let i = 0; i < stats.length; i++) {
      rows.push(
        <ListItem
          key={stats[i].muxId}
          id={stats[i].muxId}
          muxId={stats[i].muxId}
          status={stats[i].status}
          protocol={stats[i].protocol}
          txOk={stats[i].txOk}
          txErr={stats[i].txErr}
          rx={stats[i].rx}
          today={stats[i].today}
          maxPerDay={stats[i].maxPerDay}
          H={stats[i].H}
          M={stats[i].M}
          L={stats[i].L}
          age={stats[i].age}
          isTwilio={isTwilio}
          onClickName={(e) => muxIdDrilldown(e, isTwilio)}
        />
      );
    }
    return rows;
  };

  render() {
    return this.generateTables();
  }
}

export default ListView;
