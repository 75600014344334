// NPM MODULES
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

// OUR COMPONENTS
import ListItem from '../ListItem';
import AdminList from '../ListItem/Admin/Admin';
import CustomerList from '../ListItem/Admin/Customer';
import EmailErrorList from '../ListItem/Admin/EmailError';
import MessageList from '../ListItem/Admin/Messages';
import UserList from '../ListItem/Admin/User';
import formattedMessages from './FormattedMessages';

class ListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
    };
  }

  sortList(data) {
    let newList = data.sort(function (a, b) {
      if (a.user_name < b.user_name) {
        return -1;
      }
      if (a.user_name > b.user_name) {
        return 1;
      }
      return 0;
    });

    return newList;
  }

  sortByStatus(data) {
    let newList = [];
    // Alter this array to change ordering.
    const order = ['A', 'T', 'S', 'C'];

    // Ordering algorithm
    for (let i = 0; i < order.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (data[j].user_status === order[i]) newList.push(data[j]);
      }
    }
    for (let j = 0; j < data.length; j++) {
      if (order.indexOf(data[j].user_status) === -1) newList.push(data[j]);
    }
    return newList;
  }

  /*
    Handles when a ListItem is clicked on
  */
  returnListItem = (listItemId, type, providerId, customerId) => {
    console.log('List Item click:', listItemId);
    this.props.callBack(listItemId, type, providerId, customerId);
  };

  generateList = (type) => {
    let fullList;
    let sortedAlpha = this.sortList(this.props.list);
    // let sortedStatus = this.sortByStatus(sortedAlpha);
    console.log(sortedAlpha);
    let sortedStatus =
      sortedAlpha.length > 0 && sortedAlpha[0].user_status
        ? this.sortByStatus(sortedAlpha)
        : sortedAlpha;
    console.log(sortedStatus);
    if (type === 'ADMIN SEARCH - CUSTOMER') {
      // let sortedStatus = this.sortList();
      fullList = sortedStatus.map((el) => {
        return (
          <CustomerList
            key={sortedStatus.indexOf(el)}
            id={sortedStatus.indexOf(el)}
            onClick={() =>
              this.returnListItem(el.customer_id, 'customer', el.provider_id)
            }
            onClickProvider={() =>
              this.returnListItem(el.provider_id, 'provider')
            }
            providerName={el.provider_name}
            providerId={el.provider_id}
            customerName={el.customer_name}
            customerId={el.customer_id}
            type={el.customer_type}
            customerClass={el.customer_class}
            status={el.customer_status}
            authCode={el.auth_numbers.join()}
            // todo: add these fields to the returned search object
            domain={el.domains.join()}
            ipAddress={el.ip_addresses.join()}
          />
        );
      });
    } else if (type === 'ADMIN SEARCH - USER') {
      // let sortedStatus = this.sortList();
      fullList = sortedStatus.map((el) => {
        return (
          <UserList
            key={sortedStatus.indexOf(el)}
            id={sortedStatus.indexOf(el)}
            onClick={() =>
              this.returnListItem(
                el.customer_user_id,
                'customer_user',
                el.provider_id,
                el.customer_id
              )
            }
            onClickCustomer={() =>
              this.returnListItem(el.customer_id, 'customer', el.provider_id)
            }
            onClickProvider={() =>
              this.returnListItem(el.provider_id, 'provider')
            }
            onClickDetails={() =>
              this.returnListItem(
                el.customer_user_id,
                'user_report',
                el.provider_id,
                el.customer_id
              )
            }
            providerName={el.provider_name}
            customerName={el.customer_name}
            userName={el.user_name}
            email={el.user_email}
            phone={el.user_tn}
            type={el.user_type}
            userClass={el.user_class}
            status={el.user_status}
            softwareRelease={el.sw_release}
          />
        );
      });
    } else if (type === 'ADMIN SEARCH - ADMIN') {
      // let sortedStatus = this.sortList();
      fullList = sortedStatus.map((el) => {
        return (
          <AdminList
            key={sortedStatus.indexOf(el)}
            id={sortedStatus.indexOf(el)}
            onClickCustomer={() =>
              this.returnListItem(el.customer_id, 'customer', el.provider_id)
            }
            onClickContact={() =>
              this.returnListItem(
                el.customer_contact_id,
                'admin_contact',
                el.provider_id,
                el.customer_id
              )
            }
            providerName={el.provider_name}
            customerName={el.customer_name}
            // todo: add these fields to the returned search object
            /* type={el.user_type}
            customerClass={el.user_class}
            status={el.user_status}*/
            contactName={el.contact_name}
            contactEmail={el.contact_email}
            contactPhone={el.contact_tn}
            contactDepartment={el.contact_dept}
            accessLevel={el.access_level}
          />
        );
      });
    } else if (type === 'ADMIN SEARCH - EMAIL ERROR') {
      // let sortedStatus = this.sortList();
      fullList = sortedStatus.map((el) => {
        let bodyText;
        try {
          bodyText = decodeURIComponent(el.email_text);
        } catch (err) {
          bodyText = 'ERROR: MALFORMED TEXT';
        }
        return (
          <EmailErrorList
            key={sortedStatus.indexOf(el)}
            id={sortedStatus.indexOf(el)}
            onClick={() =>
              this.returnListItem(el.customer_user_id, 'email_recipient')
            }
            // todo: add these fields to the returned search object
            providerName={el.provider_name}
            customerName={el.customer_name}
            type={el.customer_type}
            customerClass={el.customer_class}
            status={el.customer_status}
            destEmail={el.dest_email}
            body={bodyText}
          />
        );
      });
    } else if (type === 'ADMIN SEARCH - MESSAGES VIA DESTINATION') {
      // let sortedStatus = this.sortList();
      fullList = sortedStatus.map((el) => {
        return (
          <MessageList
            key={sortedStatus.indexOf(el)}
            id={sortedStatus.indexOf(el)}
            // onClick={() => this.returnListItem(el.customer_user_id, 'destination_number')}
            // todo: add these fields to the returned search object
            providerName={el.provider_name}
            customerName={el.customer_name}
            userName={el.user_name}
            email={el.user_email}
            phone={el.dest_tn}
          />
        );
      });
    } else {
      fullList = this.props.list.map((element) => {
        return (
          <ListItem
            key={element.customer_user_id}
            onClick={() => this.returnListItem(element.customer_user_id)}
            name={element.user_name}
            email={element.user_email}
            number={element.user_tn}
            status={element.user_status}
            msgmax={element.max_sms_per_day}
            //version={element.sw_release}
            centre={element.cost_centre_title}
          />
        );
      });
    }
    return fullList;
  };

  render() {
    let type = this.props.type;
    if (type === 'ADMIN SEARCH - CUSTOMER') {
      return (
        <Table id="inbox">
          <thead>
            <tr key="head">
              <th className="medium tableTop textLeft">
                {formattedMessages.providerName}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.customerName}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.type}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.class}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.status}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.authCode}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.domain}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.ipAddress}
              </th>
              {/*<th className='icon'></th>*/}
            </tr>
          </thead>
          <tbody>{this.generateList(type)}</tbody>
        </Table>
      );
    } else if (type === 'ADMIN SEARCH - USER') {
      return (
        <Table id="inbox">
          <thead>
            <tr key="head">
              <th className="medium tableTop textLeft">
                {formattedMessages.providerName}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.customerName}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.userName}
              </th>
              <th className="small tableTop textLeft"></th>
              <th className="medium tableTop textLeft">
                {formattedMessages.userEmail}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.userPhone}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.type}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.class}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.status}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.softVersion}
              </th>
              {/*<th className='icon'></th>*/}
            </tr>
          </thead>
          <tbody>{this.generateList(type)}</tbody>
        </Table>
      );
    } else if (type === 'ADMIN SEARCH - ADMIN') {
      return (
        <Table id="inbox">
          <thead>
            <tr key="head">
              <th className="medium tableTop textLeft">
                {formattedMessages.providerName}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.customerName}
              </th>
              {/* <th className='medium tableTop textLeft'>{formattedMessages.type}</th>
            <th className='medium tableTop textLeft'>{formattedMessages.class}</th>
            <th className='medium tableTop textLeft'>{formattedMessages.status}</th>*/}
              <th className="medium tableTop textLeft">
                {formattedMessages.contactName}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.contactEmail}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.contactPhone}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.contactDepartment}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.accessLevel}
              </th>
              {/*<th className='icon'></th>*/}
            </tr>
          </thead>
          <tbody>{this.generateList(type)}</tbody>
        </Table>
      );
    } else if (type === 'ADMIN SEARCH - EMAIL ERROR') {
      return (
        <Table id="inbox">
          <thead>
            <tr key="head">
              <th className="medium tableTop textLeft">
                {formattedMessages.providerName}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.customerName}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.type}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.class}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.status}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.destinationEmail}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.body}
              </th>
              {/*<th className='icon'></th>*/}
            </tr>
          </thead>
          <tbody>{this.generateList(type)}</tbody>
        </Table>
      );
    } else if (type === 'ADMIN SEARCH - MESSAGES VIA DESTINATION') {
      return (
        <Table id="inbox">
          <thead>
            <tr key="head">
              <th className="medium tableTop textLeft">
                {formattedMessages.providerName}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.customerName}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.userName}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.email}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.phone}
              </th>
              {/*<th className='icon'></th>*/}
            </tr>
          </thead>
          <tbody>{this.generateList(type)}</tbody>
        </Table>
      );
    } else {
      return (
        <Table id="inbox">
          <thead>
            <tr key="head">
              <th className="medium tableTop textLeft">
                {formattedMessages.name}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.email}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.cell}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.status}
              </th>
              <th className="medium tableTop textLeft">
                {formattedMessages.maxDailyMessage}
              </th>
              {/* <th className='medium'>Red Oxygen software release</th> */}
              <th className="medium tableTop textLeft">
                {formattedMessages.costCenter}
              </th>
              <th className="icon tableTop textLeft"></th>
            </tr>
          </thead>
          <tbody>{this.generateList(type)}</tbody>
        </Table>
      );
    }
  }
}

export default ListView;
