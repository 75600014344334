/*
  SingleView.js - Admin Carrier Single View Main Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

// COMPONENTS
import CarrierPrefixList from './Prefix/List';
import CarrierPrefixSingle from './Prefix/Single';
import CarrierDeviceList from './Device/List';
import CarrierDeviceAll from './Device/All';
import CarrierDeviceSingle from './Device/Single';
import CarrierPortedNumberList from './PortedNumber/List';
import CarrierPortedNumberSingle from './PortedNumber/Single';

// NETWORK
import CarrierRequest from './CarrierRequest';
import formattedMessages from '../../../Provider/FormattedMessages';

// INSTANTIATION
const carrierRequest = new CarrierRequest();

export default class CarrierSingleView extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - carrier: A single carrier object (optional)
        - country: Country to create or edit a Carrier against
        - backButtonClick: Function to handle Back Button click
        - isNew: Whether or not it's handling a new Carrier,
        - errorHandler: A function to pass errors to
    */

    console.log('Have carrier props:', this.props);

    this.state = {
      isEditing: false,
      edits: {},
      addNewCarrierPrefix: false,
      showSingleCarrierPrefix: null,
      selectedCarrierDevice: null,
      addNewCarrierPortedNumber: false,
      showSingleCarrierPortedNumber: null,
    };
  }

  /*
    Handles Edit Mode on/off
  */
  toggleEdit = () => {
    return this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  backButtonClick = () => {
    return this.setState({
      addNewCarrierPrefix: false,
      showSingleCarrierPrefix: null,
      selectedCarrierDevice: null,
      addNewCarrierPortedNumber: false,
      showSingleCarrierPortedNumber: null,
    });
  };

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;
    edits[name] = value;

    console.log('Edits is:', edits);

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    try {
      console.log('Handle submit');
      e.preventDefault();
      let data = this.state.edits;

      // Have new Object, create
      if (this.props.isNew) {
        console.log('Have props isNew, create new carrier');
        await carrierRequest.createNewCarrier(
          this.props.country.country_code_id,
          data,
          this.props.errorHandler
        );
        console.log('Post-create new carrier');
      }
      // Editing object, update
      else {
        await carrierRequest.updateCarrier(
          this.props.country.country_code_id,
          this.props.carrier.carrier_id,
          data,
          this.props.errorHandler
        );
      }

      window.location.href = '/admin/serviceconfig-country'; // Force reload - TODO: redo properly
    } catch (e) {
      console.log('Have err:', e);
      // Handle error
    }
  };

  insertSelectedDevice = async (device, applyProvider) => {
    let { country, carrier, errorHandler } = this.props;
    await carrierRequest.createNewCarrierDevice(
      country.country_code_id,
      carrier.carrier_id,
      device,
      applyProvider,
      errorHandler
    );
    window.location.href = '/admin/serviceconfig-country'; // Force reload - TODO: redo properly
  };

  /*
    Handles Delete of Country Prefix Map
  */
  handleDelete = async (e) => {
    await carrierRequest.deleteCarrierById(
      this.props.country.country_code_id,
      this.props.carrier.carrier_id,
      this.props.errorHandler
    );
    window.location.href = '/admin/serviceconfig-country'; // Force reload - TODO: redo properly
  };

  addNewCarrierPrefix = () => {
    return this.setState({
      addNewCarrierPrefix: true,
    });
  };

  addNewCarrierPortedNumber = () => {
    return this.setState({
      addNewCarrierPortedNumber: true,
    });
  };

  addNewCarrierDevice = () => {
    return this.setState({
      addNewCarrierDevice: true,
    });
  };

  /*
    Handles when a Carrier Device link is clicked in the table.
  */
  showSingleCarrierDevice = async (e) => {
    return this.setState({
      selectedCarrierDevice: e,
    });
  };

  /*
    Triggers the Single view for a Carrier Prefix
  */
  showSingleCarrierPrefix = (carrierPrefix) => {
    return this.setState({
      showSingleCarrierPrefix: carrierPrefix,
    });
  };

  showSingleCarrierPortedNumber = (carrierPortedNumber) => {
    return this.setState({
      showSingleCarrierPortedNumber: carrierPortedNumber,
    });
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    let {
      addNewCarrierPrefix,
      selectedCarrierDevice,
      showSingleCarrierPrefix,
      showSingleCarrierPortedNumber,
      addNewCarrierPortedNumber,
    } = this.state;
    // If showing other pages, return nothing here
    if (
      addNewCarrierPrefix ||
      selectedCarrierDevice ||
      showSingleCarrierPrefix ||
      showSingleCarrierPortedNumber ||
      addNewCarrierPortedNumber
    )
      return null;

    return (
      <div key="CountryShow" className="lander country">
        <p
          onClick={() => this.props.backButtonClick('list')}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
        <h3>Carrier Information</h3>
      </div>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateEditButton = () => {
    let { isEditing } = this.state;
    let buttonText = isEditing ? 'Cancel' : 'Edit';

    if (!this.props.isNew || typeof this.props.isNew === 'undefined') {
      return (
        <Button className="maxHeight" onClick={this.toggleEdit}>
          {buttonText}
        </Button>
      );
    }
    return null;
  };

  generateBody = () => {
    let { country, carrier, isNew } = this.props;
    let {
      isEditing,
      addNewCarrierDevice,
      showSingleCarrierPrefix,
      addNewCarrierPrefix,
      selectedCarrierDevice,
      addNewCarrierPortedNumber,
      showSingleCarrierPortedNumber,
    } = this.state;

    if (isNew) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="carrier_name">
              <Form.Label column sm={2} className="info-label">
                * Carrier Name:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="carrier_name" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="carrier_code">
              <Form.Label column sm={2} className="info-label">
                * Carrier Code:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="carrier_code" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="accepts_alphas">
              <Form.Label column sm={2} className="info-label">
                * Accept Alphas:
              </Form.Label>
              <Col sm={8}>
                <Form.Check name="accepts_alphas" type="checkbox" />
              </Col>
            </Form.Group>
            {/* <Button className='createProviderButton' variant='primary' type='submit'> */}
            <Button className="maxHeight" variant="primary" type="submit">
              Save
            </Button>
          </Form>
          {this.generateEditButton()}
        </Fragment>
      );
    }
    // Display data if not editing
    else if (carrier && !this.state.isEditing) {
      if (addNewCarrierDevice) {
        return (
          <CarrierDeviceAll
            country={country}
            carrier={carrier}
            errorHandler={this.props.errorHandler}
            backButtonClick={this.backButtonClick}
            insertSelectedDevice={this.insertSelectedDevice}
          ></CarrierDeviceAll>
        );
      } else if (selectedCarrierDevice) {
        console.log('Got carrier device single');
        return (
          <CarrierDeviceSingle
            carrier={carrier}
            carrierDevice={selectedCarrierDevice}
            backButtonClick={this.backButtonClick}
            errorHandler={this.props.errorHandler}
          ></CarrierDeviceSingle>
        );
      } else if (showSingleCarrierPrefix || addNewCarrierPrefix) {
        console.log('Got carrier prefix single');
        // Handles Carrier Prefix items
        return (
          <CarrierPrefixSingle
            carrier={carrier}
            carrierPrefix={showSingleCarrierPrefix}
            isNew={addNewCarrierPrefix}
            backButtonClick={this.backButtonClick}
          ></CarrierPrefixSingle>
        );
      } else if (addNewCarrierPortedNumber || showSingleCarrierPortedNumber) {
        return (
          <CarrierPortedNumberSingle
            carrier={carrier}
            portedNumber={showSingleCarrierPortedNumber}
            isNew={addNewCarrierPortedNumber}
            backButtonClick={this.backButtonClick}
          ></CarrierPortedNumberSingle>
        );
      }

      return (
        <Fragment>
          <Form>
            {this.generateFormGroup(
              '* Carrier Name:',
              carrier.carrier_name,
              'carrier_name'
            )}
            {this.generateFormGroup(
              '* Carrier Code:',
              carrier.carrier_code,
              'carrier_code'
            )}
            {this.generateFormGroup(
              '* Accept Alphas:',
              carrier.accepts_alphas === 'Y' ? 'Yes' : 'No',
              'accepts_alphas'
            )}
          </Form>
          {this.generateEditButton()}
          <Button
            className="delete btn-red btn btn-danger"
            onClick={this.handleDelete}
          >
            Delete
          </Button>
          <br />
          <CarrierPrefixList
            country={country}
            carrier={carrier}
            errorHandler={this.props.errorHandler}
            backButtonClick={this.backButtonClick}
            addNewCarrierPrefix={this.addNewCarrierPrefix}
            showSingleCarrierPrefix={this.showSingleCarrierPrefix}
          ></CarrierPrefixList>
          <CarrierDeviceList
            country={country}
            carrier={carrier}
            errorHandler={this.props.errorHandler}
            backButtonClick={this.backButtonClick}
            addNewCarrierDevice={this.addNewCarrierDevice}
            showSingleCarrierDevice={this.showSingleCarrierDevice}
          ></CarrierDeviceList>
          <CarrierPortedNumberList
            country={country}
            carrier={carrier}
            errorHandler={this.props.errorHandler}
            backButtonClick={this.props.backButtonClick}
            addNewCarrierPortedNumber={this.addNewCarrierPortedNumber}
            showSingleCarrierPortedNumber={this.showSingleCarrierPortedNumber}
          ></CarrierPortedNumberList>
        </Fragment>
      );
    } else if (carrier && isEditing) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="carrier_name">
              <Form.Label column sm={2} className="info-label">
                * Carrier Name:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="carrier_name"
                  type="text"
                  defaultValue={carrier.carrier_name}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="carrier_code">
              <Form.Label column sm={2} className="info-label">
                * Carrier Code:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="carrier_code"
                  type="text"
                  defaultValue={carrier.carrier_code}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="accepts_alphas">
              <Form.Label column sm={2} className="info-label">
                * Accepts Alphas:
              </Form.Label>
              <Col sm={8}>
                <Form.Check
                  name="accepts_alphas"
                  type="checkbox"
                  checked={carrier.accepts_alphas === 'Y'}
                />
              </Col>
            </Form.Group>
            {/* <Button className='createProviderButton' variant='primary' type='submit'> */}
            <Button className="maxHeight" variant="primary" type="submit">
              Save
            </Button>
          </Form>
          {this.generateEditButton()}
        </Fragment>
      );
    }

    return (
      <p>
        <span>(Carrier) No Data</span>
      </p>
    );
  };

  render() {
    return (
      <div className="Home carrier">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
