/*
  DistributionListComponent/GlobalListComponent.js - Generates the WebSMS Distribution List
  Global List Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/
// NPM
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormControl } from 'react-bootstrap';

// COMPONENTS
import ListItem from '../ListItem';
import Paginator from '../../../../components/Paginator';

// CONFIG
import config from '../../../../Config';

class GlobalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      error: null,
      searchValue: '',
    };
    this.pageLength = config.defaultPL;
  }

  /*
    Handles the change event when clicking a pagination number
  */
  changeEvent = (i) => {
    return this.setState({
      pageNumber: i + 1,
    });
  };

  /*
    Handles changes in the form
  */
  handleSearchChange = async (event) => {
    this.setState({
      searchValue: event.target.value,
      pageNumber: 1,
    });
    this.resetFunction();
  };

  generateSearch = () => {
    let searchByName = (
      <FormattedMessage
        id="Websms.searchByName"
        defaultMessage="Search Distribution List By Name"
      />
    );
    return (
      <Fragment>
        <div className="main">
          <h5>{searchByName}</h5>
          <FormControl
            id="contactSearch"
            autoFocus
            type="text"
            defaultValue=""
            onChange={this.handleSearchChange}
          />
          <br />
          <br />
        </div>
      </Fragment>
    );
  };

  /*
    Handles global distribution list search
  */
  handleSearchFilter = () => {
    let list = this.props.list;
    if (this.state.searchValue) {
      list = this.props.list.filter((contact) => {
        return `${contact.list_name}`
          .toLowerCase()
          .includes(this.state.searchValue.toLowerCase());
      });
    }
    return list;
  };

  generateAddNewButton = () => {
    let { accessLevel, accessType } = this.props;
    if (accessLevel > '0' && accessType > '0') {
      // If it's global, new distribution list should be able to be added by customer administrators, not regular users
      return (
        <div className="d-inline floatRight">
          <button
            className="btn add-new-template"
            onClick={() => this.props.addNewCallback('global')}
          >
            {'Add New'}
          </button>
        </div>
      );
    }
  };

  generateList = (list) => {
    let fullList = [];
    let { pageNumber } = this.state;
    if (list && typeof list !== 'undefined' && Array.isArray(list)) {
      let displayList = list.slice(
        (pageNumber - 1) * this.pageLength,
        pageNumber * this.pageLength - 1 + 1
      );
      for (let i = 0; i < displayList.length; i++) {
        let element = displayList[i];
        fullList.push(
          <div
            onClick={() => this.props.handleSelect(element)}
            key={element.distribution_list_id}
          >
            <ListItem
              id={element.distribution_list_id}
              key={element.distribution_list_id}
              title={element.list_name}
              onClick={() => this.props.callBack(element)}
            />
          </div>
        );
      }
    }
    return fullList;
  };

  render() {
    let GlobalListText = (
      <FormattedMessage
        id="Websms.GlobalListText"
        defaultMessage="Global List"
      />
    );
    let list = this.handleSearchFilter();
    let listLen = list ? list.length : 0;
    return (
      <Fragment>
        {this.generateSearch()}
        {this.generateAddNewButton()}
        <br />
        <h5>
          {GlobalListText}: {list ? list.length : 0}
        </h5>
        <ul className="wembsms-list">{this.generateList(list)}</ul>
        <Paginator
          itemCount={listLen}
          changeEvent={this.changeEvent}
          changePageFunction={(ref) => (this.resetFunction = ref)}
        />
      </Fragment>
    );
  }
}

export default GlobalList;
