/*
  DistributionRequest.js - Handles networking requests for Distribution Lists

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';
// CONFIG
import config from '../../../Config';
// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class DistributionRequest {
  getDistributionLists = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/distribution`;
      const options = {
        method: 'GET',
      };
      // Get Contacts
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (err) {
      errorHandler(err);
    }
  };

  createNewDistributionList = async (
    listName,
    contactList,
    entryType,
    errorHandler
  ) => {
    try {
      const url = `${config.baseServerURL}/addressbook/distribution`;
      const options = {
        method: 'POST',
        body: JSON.stringify({
          list_name: listName,
          entry_type: entryType,
          contacts: contactList,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      };
      // Get Contacts
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (err) {
      errorHandler(err);
    }
  };

  editDistributionList = async (distributionListId, edits, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/distribution/${distributionListId}`;
      const options = {
        method: 'POST',
        body: JSON.stringify(edits),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      };
      // Update Contact
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  deleteDistributionList = async (
    distributionListId,
    entryType,
    errorHandler
  ) => {
    try {
      const url = `${config.baseServerURL}/addressbook/distribution/${distributionListId}?entry_type=${entryType}`;
      const options = {
        method: 'DELETE',
      };

      // Delete Contact
      await requestRabbit.request(url, options, errorHandler);

      return true;
    } catch (e) {
      errorHandler(e);
    }
  };

  exportDistributionList = async (
    distributionListId,
    entryType,
    errorHandler
  ) => {
    try {
      const url = `${config.baseServerURL}/addressbook/distribution/export`;
      const options = {
        method: 'POST',
        body: JSON.stringify({
          distribution_list_id: distributionListId,
          entry_type: entryType,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);

      // Response
      let responseJSON = await response.json();

      // Get file and download it
      let downloadUrl = `${config.baseServerURL}/addressbook/distribution/download/${responseJSON.filename}`;
      window.open(downloadUrl);
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
