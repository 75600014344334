import React from 'react';

const ListItem = ({
  id,
  name = '',
  active = '',
  type = '',
  cust_class = '',
  onClickName = null,
  customer = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="column-right" name="name">
      <span className="blue" onClick={() => onClickName(customer)}>
        {name}
      </span>
    </td>
    <td className="medium" name="active">
      <span>
        {type} - {cust_class} - {active}
      </span>
    </td>
  </tr>
);

export default ListItem;
