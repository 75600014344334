import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  bulkSendTitle: (
    <FormattedMessage
      id="Websms.bulkSendTitle"
      defaultMessage="Bulk Send"
    ></FormattedMessage>
  ),
  csvFile: (
    <FormattedMessage
      id="Websms.csvFile"
      defaultMessage="Upload CSV file:"
    ></FormattedMessage>
  ),
  landingText1: (
    <FormattedMessage
      id="Websms.landingText1"
      defaultMessage="To send in bulk, you must first upload a .csv file."
    ></FormattedMessage>
  ),
  landingText2: (
    <FormattedMessage
      id="Websms.landingText2"
      defaultMessage="At minimum, your file should list the mobile phone # and name of the recipients."
    ></FormattedMessage>
  ),
  landingText3: (
    <FormattedMessage
      id="Websms.landingText3"
      defaultMessage="The file can also contain other columns of information that you might want to use to customize your message."
    ></FormattedMessage>
  ),
  landingText4: (
    <FormattedMessage
      id="Websms.landingText4"
      defaultMessage="You can add a message in one of the .csv columns or use a template that you've already saved in 'Templates'."
    ></FormattedMessage>
  ),
  landingText5: (
    <FormattedMessage
      id="Websms.landingText5"
      defaultMessage="Here's an example of a "
    ></FormattedMessage>
  ),
  landingText6: (
    <FormattedMessage
      id="Websms.landingText6"
      defaultMessage=".csv file."
    ></FormattedMessage>
  ),
  landingText7: (
    <FormattedMessage
      id="Websms.landingText7"
      defaultMessage="This example message will pull the month [3], the name [2] and quantity [4] into the message:"
    ></FormattedMessage>
  ),
  landingText8: (
    <FormattedMessage
      id="Websms.landingText8"
      defaultMessage="ex: March acct. balance: Adam has 9 tokens remaining."
    ></FormattedMessage>
  ),
  browseButton: (
    <FormattedMessage
      id="Websms.browseButton"
      defaultMessage="Browse"
    ></FormattedMessage>
  ),
  mobileNumbers: (
    <FormattedMessage
      id="Websms.mobileNumbers"
      defaultMessage="Recipient numbers are in:"
    ></FormattedMessage>
  ),
  recipientNames: (
    <FormattedMessage
      id="Websms.recipientNames"
      defaultMessage="Recipient names are in:"
    ></FormattedMessage>
  ),
  messageIn: (
    <FormattedMessage
      id="Websms.messageIn"
      defaultMessage="The message is in:"
    ></FormattedMessage>
  ),
  csvFileColumn: (
    <FormattedMessage
      id="Websms.csvFileColumn"
      defaultMessage="CSV file's column"
    ></FormattedMessage>
  ),
  templateRadio: (
    <FormattedMessage
      id="Websms.templateRadio"
      defaultMessage="Template"
    ></FormattedMessage>
  ),
  templateButton: (
    <FormattedMessage
      id="Websms.templateButton"
      defaultMessage="Templates..."
    ></FormattedMessage>
  ),
  accountStatus: (
    <FormattedMessage
      id="Websms.accountStatus"
      defaultMessage="Account status (period/limit/remaining/credit):"
    ></FormattedMessage>
  ),
  updateButton: (
    <FormattedMessage
      id="Websms.updateButton"
      defaultMessage="Update"
    ></FormattedMessage>
  ),
  startButton: (
    <FormattedMessage
      id="Websms.startButton"
      defaultMessage="Start sending..."
    ></FormattedMessage>
  ),
  sendLater: (
    <FormattedMessage
      id="Websms.sendLater"
      defaultMessage="Send SMS later"
    ></FormattedMessage>
  ),
  totalRecipients: (
    <FormattedMessage
      id="Websms.totalRecipients"
      defaultMessage="Total recipients: "
    ></FormattedMessage>
  ),
  validRecipients: (
    <FormattedMessage
      id="Websms.validRecipientsRecipients"
      defaultMessage="Total valid recipients: "
    ></FormattedMessage>
  ),
  invalidRecipients: (
    <FormattedMessage
      id="Websms.invalidRecipientsRecipients"
      defaultMessage="Total invalid recipients: "
    ></FormattedMessage>
  ),
  hashColumn: (
    <FormattedMessage
      id="Websms.hashColumn"
      defaultMessage="#"
    ></FormattedMessage>
  ),
  recipientColumn: (
    <FormattedMessage
      id="Websms.recipientColumn"
      defaultMessage="Recipient number"
    ></FormattedMessage>
  ),
  nameColumn: (
    <FormattedMessage
      id="Websms.nameColumn"
      defaultMessage="Recipient name"
    ></FormattedMessage>
  ),
  messageColumn: (
    <FormattedMessage
      id="Websms.messageColumn"
      defaultMessage="Message text"
    ></FormattedMessage>
  ),
  countColumn: (
    <FormattedMessage
      id="Websms.countColumn"
      defaultMessage="Message Parts"
    ></FormattedMessage>
  ),
  deleteRow: (
    <FormattedMessage
      id="Websms.deleteRow"
      defaultMessage="Delete Row"
    ></FormattedMessage>
  ),
  deleteSelectedRow: (
    <FormattedMessage
      id="Websms.deleteRow"
      defaultMessage="Delete Selected Rows"
    ></FormattedMessage>
  ),
  modalSendTitle: (
    <FormattedMessage
      id="Websms.modalSendTitle"
      defaultMessage="Message Send Confirmation"
    ></FormattedMessage>
  ),
  modalSendFailureTitle: (
    <FormattedMessage
      id="Websms.modalSendFailureTitle"
      defaultMessage="Cannot Send Message"
    ></FormattedMessage>
  ),
  modalSendConfirmation: (
    <FormattedMessage
      id="Websms.modalSendConfirmation"
      defaultMessage="Do you want to send to all these recipients?"
    ></FormattedMessage>
  ),
  modalSendPending: (
    <FormattedMessage
      id="Websms.modalSendPending"
      defaultMessage="Sending request..."
    ></FormattedMessage>
  ),
  modalSendFailure: (
    <FormattedMessage
      id="Websms.modalSendFailure"
      defaultMessage="An error occurred with the send"
    ></FormattedMessage>
  ),
  modalSendFailureEmptyMessages: (
    <FormattedMessage
      id="Websms.modalSendFailureEmptyMessages"
      defaultMessage="Incorrect data: Message content is empty."
    ></FormattedMessage>
  ),
  removeHeaders: (
    <FormattedMessage
      id="Websms.removeHeaders"
      defaultMessage="Ignore the header row"
    ></FormattedMessage>
  ),
  showInvalidRow: (
    <FormattedMessage
      id="Websms.showInvalidRow"
      defaultMessage="Show invalid rows"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
