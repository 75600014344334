import React from 'react';
//import { FormattedMessage } from 'react-intl'; // TODO: use formatted message?
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import FormattedMessages from './FormattedMessages';

let invShowUrl = '/billing/invoiceshow/';

const listItem = ({
  id,
  provider,
  invoiceId,
  billId,
  customerId,
  name,
  invoiceDate,
  paidDate,
  status,
  currency,
  dollar,
  customerType,
  amount,
  isGodAdmin,
  emailRequestHandler,
  printRequestHandler,
  adjustRequestHandler,
  refreshRequestHandler,
  errorHandler,
  processPendingInvoice,
  transitionPendingToInvoiced,
  transitionBillToFailed,
  cancelPendingTransaction,
  dataChange,
  selectCustomer,
}) => {
  let displayAmount = Math.abs(amount).toFixed(2);
  let amountClass = '';
  if (amount < 0) {
    currency = '-' + (currency || '');
    amountClass = 'red default-cursor';
  }

  let customerTypeText;
  if (isGodAdmin && customerType) {
    if (customerType === 'A') customerTypeText = 'Post';
    else if (customerType === 'B') customerTypeText = 'Pre';
    else customerTypeText = 'Other';
  }

  // Recalculate is disabled for
  let disableRecalculate =
    (status !== 'Pending' && status !== 'Invoiced') || customerType === 'B';

  return (
    <tr key={id} id={id}>
      {_generateProviderName(isGodAdmin, provider)}
      <td className="medium textLeft">
        <span
          className="blue"
          onClick={() =>
            window.history.pushState(
              invoiceId,
              `Invoice:${invoiceId}`,
              `${invShowUrl}${customerId}/${billId}`
            )
          }
        >
          <Link to={`${invShowUrl}${customerId}/${billId}`}>{invoiceId}</Link>
        </span>
      </td>
      {_generateCustomerName(isGodAdmin, id, customerId, name, selectCustomer)}
      <td className="invoice-table-cells td pad-left">{invoiceDate}</td>
      <td
        className="invoice-table-cells td pad-left"
        style={{ minWidth: '60px' }}
      >
        {paidDate}
      </td>
      <td
        className="invoice-table-cells td pad-left"
        style={{ minWidth: '70px' }}
      >
        {_generateStatus(
          isGodAdmin,
          status,
          customerType,
          invoiceId,
          billId,
          customerId,
          id,
          errorHandler,
          dataChange,
          processPendingInvoice,
          transitionPendingToInvoiced,
          transitionBillToFailed,
          cancelPendingTransaction
        )}
      </td>
      <td
        className={amountClass + ' currency-padding'}
        style={{ minWidth: '80px' }}
      >
        {currency}
        {displayAmount}
      </td>
      <td className="medium textLeft td pad-left">{dollar}</td>
      {isGodAdmin && (
        <td className="invoice-table-cells td pad-left">{customerTypeText}</td>
      )}
      {_generateActionIcons(
        isGodAdmin,
        id,
        customerId,
        billId,
        disableRecalculate,
        printRequestHandler,
        emailRequestHandler,
        adjustRequestHandler,
        refreshRequestHandler,
        amount < 0
      )}
    </tr>
  );
};

const _generateStatus = (
  isGodAdmin,
  status,
  customerType,
  invoiceId,
  billId,
  customerId,
  providerId,
  errorHandler,
  dataChange,
  processPendingInvoice,
  transitionPendingToInvoiced,
  transitionBillToFailed,
  cancelPendingTransaction
) => {
  if (!status) return;
  else if (status === 'Pending' && !isGodAdmin) {
    return (
      <Dropdown>
        <Dropdown.Toggle
          className="status-transition"
          variant={'success'}
          id="dropdown-basic"
        >
          {status}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            style={{ fontSize: '0.85rem' }}
            href="#"
            onClick={() => processPendingInvoice(billId, customerId)}
          >
            {FormattedMessages.processTransaction}
          </Dropdown.Item>
          {customerType === 'B' && (
            <Dropdown.Item
              style={{ fontSize: '0.85rem' }}
              href="#"
              onClick={() =>
                cancelPendingTransaction(customerId, providerId, billId)
              }
            >
              {FormattedMessages.cancelTransaction}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    return <div>{status}</div>;
  }
};

const _generateActionIcons = (
  isGodAdmin,
  id,
  customerId,
  billId,
  disableRecalculate,
  printRequestHandler,
  emailRequestHandler,
  adjustRequestHandler,
  refreshRequestHandler,
  disableEdit
) => {
  // The print and email buttons are viewable for all users.
  let regularIconComponent = (
    <Dropdown>
      <Dropdown.Toggle className="invoices-dropdown-icon hide-dropdown-arrow">
        <i id={id + 'icon_menu'} className="material-icons small-icon">
          more_horiz
        </i>
      </Dropdown.Toggle>
      <Dropdown.Menu className="invoices-dropdown-menu">
        <React.Fragment>
          <Dropdown.Item
            href={`${invShowUrl}${customerId}/${billId}?print=true`}
            onClick={printRequestHandler}
            data-toggle="tooltip"
            data-placement="top"
            title="Print"
          >
            <i id={id + 'icon_print'} className="material-icons small-icon">
              print
            </i>
          </Dropdown.Item>
          <Dropdown.Item
            href="#0"
            onClick={emailRequestHandler}
            data-toggle="tooltip"
            data-placement="top"
            title="Email"
          >
            <i id={id + 'icon_email'} className="material-icons small-icon">
              mail_outline
            </i>
          </Dropdown.Item>
        </React.Fragment>
      </Dropdown.Menu>
    </Dropdown>
  );

  // The edit (invoice correction) and recalculate buttons are only viewable for god-admins
  if (isGodAdmin) {
    return (
      <>
        <td style={{ width: '40px' }} className="td pad-right">
          <Dropdown>
            <Dropdown.Toggle className="invoices-dropdown-icon hide-dropdown-arrow">
              <i id={id + 'icon_menu'} className="material-icons small-icon">
                more_horiz
              </i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="invoices-dropdown-menu">
              <Dropdown.Item
                href={`${invShowUrl}${customerId}/${billId}?print=true`}
                onClick={printRequestHandler}
                data-toggle="tooltip"
                data-placement="top"
                title="Print"
              >
                <i id={id + 'icon_print'} className="material-icons small-icon">
                  print
                </i>
              </Dropdown.Item>
              <Dropdown.Item
                href="#0"
                onClick={emailRequestHandler}
                data-toggle="tooltip"
                data-placement="top"
                title="Email"
              >
                <i id={id + 'icon_email'} className="material-icons small-icon">
                  mail_outline
                </i>
              </Dropdown.Item>
              <Dropdown.Item
                href="#0"
                onClick={disableEdit ? null : adjustRequestHandler}
                className={disableEdit ? 'default-cursor' : ''}
                data-toggle="tooltip"
                data-placement="top"
                title="Adjust"
              >
                <i
                  id={id + 'icon_detail'}
                  className={`material-icons small-icon ${
                    disableEdit ? 'disabled' : ''
                  }`}
                >
                  edit
                </i>
              </Dropdown.Item>
              <Dropdown.Item
                href="#0"
                onClick={disableRecalculate ? null : refreshRequestHandler}
                className={disableRecalculate ? 'default-cursor' : ''}
                data-toggle="tooltip"
                data-placement="top"
                title="Refresh"
              >
                <i
                  id={id + 'icon_refresh'}
                  className={`material-icons small-icon ${
                    disableRecalculate ? 'disabled' : ''
                  }`}
                >
                  refresh
                </i>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </>
    );
  }
  return (
    <td style={{ width: '40px' }} className="td pad-right">
      {regularIconComponent}
    </td>
  );
};

const _generateProviderName = (isGodAdmin, provider) => {
  if (!isGodAdmin) return null;
  return (
    <td title={provider} className="truncated-text">
      <span>{provider}</span>
    </td>
  );
};

const _generateCustomerName = (
  isGodAdmin,
  id,
  customerId,
  name,
  selectCustomer
) => {
  // Regular User
  if (!isGodAdmin) return null;

  // God Admin
  return (
    <td title={name} className="medium textLeft">
      <span
        className="blue underline"
        onClick={() => selectCustomer(customerId)}
      >
        <Link>{name}</Link>
      </span>
    </td>
  );
};

export default listItem;
