import React, { Component, Fragment } from 'react';

// Custom Components
import { Table } from 'react-bootstrap';
import FormattedMessage from '../FormattedMessages';

class ListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidMount = () => {
    //TODO - uncomment once backend data is set up
  };
  changeEvent = (o) => {
    console.log(o);
  };

  render() {
    console.log(this.props.totals);
    return (
      <Fragment>
        <Table className="invoice-table table-new">
          <thead>
            <tr>
              <td className="medium tableTop left">{FormattedMessage.Date}</td>
              <td className="medium tableTop">{FormattedMessage.invoiceNo}</td>
              <td className="medium tableTop">{FormattedMessage.name}</td>
              <td className="medium tableTop">{FormattedMessage.coo}</td>
              <td className="medium tableTop">{FormattedMessage.paidDate}</td>
              <td className="medium tableTop">
                {FormattedMessage.paymentType}
              </td>
              <td className="medium tableTop">{FormattedMessage.suffix}</td>
              <td className="medium tableTop column-left">
                {FormattedMessage.setupFees}
              </td>
              <td className="medium tableTop column-left">
                {FormattedMessage.licenseFees}
              </td>
              <td className="medium tableTop column-left">
                {FormattedMessage.messages}
              </td>
              <td className="medium tableTop column-left">
                {FormattedMessage.replies}
              </td>
              <td className="medium tableTop column-left">
                {FormattedMessage.messageFees}
              </td>
              <td className="medium tableTop column-left">
                {FormattedMessage.totalFees}
              </td>
              <td className="medium tableTop column-left">
                {FormattedMessage.gst}
              </td>
              <td className="medium tableTop column-left">
                {FormattedMessage.CCsurcharge}
              </td>
              <td className="medium tableTop column-left">
                {FormattedMessage.total}
              </td>
            </tr>
            <tr>
              <td className="medium tableBottom left"></td>
              <td className="medium tableBottom"></td>
              <td className="medium tableBottom"></td>
              <td className="medium tableBottom"></td>
              <td className="medium tableBottom"></td>
              <td className="medium tableBottom"></td>
              <td className="medium tableBottom"></td>
              <td className="medium tableBottom column-left">$</td>
              <td className="medium tableBottom column-left">$</td>
              <td className="medium tableBottom column-left">#</td>
              <td className="medium tableBottom column-left">#</td>
              <td className="medium tableBottom column-left">$</td>
              <td className="medium tableBottom column-left">$</td>
              <td className="medium tableBottom column-left">$</td>
              <td className="medium tableBottom column-left">$</td>
              <td className="medium tableBottom column-left">$</td>
            </tr>
          </thead>
          <tbody>
            {this.props.list}
            <tr>
              <td className="medium tableBottom left" colSpan="7">
                {FormattedMessage.total}
              </td>
              <td className="medium tableBottom column-left">
                {this.props.totals.SetupFeesDisplay}
              </td>
              <td className="medium tableBottom column-left">
                {this.props.totals.LicenceDisplay}
              </td>
              <td className="medium tableBottom column-left">
                {this.props.totals.Messages}
              </td>
              <td className="medium tableBottom column-left">
                {this.props.totals.Replies}
              </td>
              <td className="medium tableBottom column-left">
                {this.props.totals.MessageFeesDisplay}
              </td>
              <td className="medium tableBottom column-left">
                {this.props.totals.TotalFeesDisplay}
              </td>
              <td className="medium tableBottom column-left">
                {this.props.totals.GSTDisplay}
              </td>
              <td className="medium tableBottom column-left">
                {this.props.totals.CCSurchargeDisplay}
              </td>
              <td className="medium tableBottom column-left">
                {this.props.totals.TotalDisplay}
              </td>
            </tr>
          </tbody>
        </Table>
      </Fragment>
    );
  }
}

export default ListComponent;
