// NPM MODULES
import React, { Component } from 'react';

class ErrorText extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - errorText: String containing the error text
        - hasError: Boolean for whether or not to display the error component
        - className: replacement className
    */

    this.state = {};
  }

  render() {
    // No error, nothing to show
    if (!this.props.hasError) return null;
    // Return error
    return (
      <div
        className={
          this.props.className ? this.props.className : 'text-danger error'
        }
      >
        {this.props.errorText}
      </div>
    );
  }
}

export default ErrorText;
