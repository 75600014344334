import React from 'react';

const AllListItem = ({
  id,
  carrierName = '',
  onClickCarrierName = null,
  carrier = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="column-right">
      <span className="blue" onClick={() => onClickCarrierName(carrier)}>
        {carrierName}
      </span>
    </td>
  </tr>
);

export default AllListItem;
