const { REACT_APP_API_ENDPOINT } = process.env;
const Config = {
  defaultPL: 10, // Number of returned entries for each pagination page
  defaultLanguageId: 'd102dcf7-f0d6-40b7-86ba-1d3bd80c12fe',
  dateFormats: [
    'DD-MM-YYYY',
    'DD/MM/YYYY',
    'DD-MM-YY',
    'DD/MM/YY',
    'D-MM-YYYY',
    'D/MM/YYYY',
    'D-MM-YY',
    'D/MM/YY',
    'DD-M-YYYY',
    'DD/M/YYYY',
    'DD-M-YY',
    'DD/M/YY',
    'D-M-YYYY',
    'D/M/YYYY',
    'D-M-YY',
    'D/M/YY',
    'DD MMM YYYY',
    'DD MMM YY',
    'D MMM YYYY',
    'D MMM YY',
  ],
  baseServerURL: REACT_APP_API_ENDPOINT
  // baseServerURL: 'http://localhost:8080/v1', // Local Env
};

export default Config;
