// UTILS
import RequestRabbit from '../../RequestRabbit';
// CONFIG
import config from '../../../Config';
const handleRequest = new RequestRabbit();

export default class SettingsRequest {
  /*
    Creates a request to fetch a User's WebSMS settings
  */
  getSettings = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/users/settings`;
      const options = { method: 'GET' };
      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();
      console.log(responseJSON);

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Creates a request to update a User's WebSMS settings
  */
  updateSettings = async (updates, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/users/settings`;
      const options = {
        method: 'POST',
        body: JSON.stringify(updates),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      };
      // Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };
}
