import React from 'react';

const ListItem = ({ id, costCenter }) => (
  <tr>
    <td className="left">{id}</td>
    <td>{costCenter}</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
);

export default ListItem;
