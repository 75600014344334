/*
  FormattedMessages.js - FormattedMessages for Users Find Users component

  Author: Julius Klaebe (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  providerName: (
    <FormattedMessage
      id="Global.ProviderName"
      defaultMessage="Provider Name"
    ></FormattedMessage>
  ),
  customerName: (
    <FormattedMessage
      id="Global.CustomerName"
      defaultMessage="Customer Name"
    ></FormattedMessage>
  ),
  type: (
    <FormattedMessage id="Global.Type" defaultMessage="Type"></FormattedMessage>
  ),
  class: (
    <FormattedMessage
      id="Global.Class"
      defaultMessage="Class"
    ></FormattedMessage>
  ),
  status: (
    <FormattedMessage
      id="Global.Status"
      defaultMessage="Status"
    ></FormattedMessage>
  ),
  authCode: (
    <FormattedMessage
      id="Global.AuthCode"
      defaultMessage="Auth Code"
    ></FormattedMessage>
  ),
  domain: (
    <FormattedMessage
      id="Global.Domain"
      defaultMessage="Domain"
    ></FormattedMessage>
  ),
  ipAddress: (
    <FormattedMessage
      id="Global.IPAddress"
      defaultMessage="IP Address"
    ></FormattedMessage>
  ),
  userName: (
    <FormattedMessage
      id="Global.UserName"
      defaultMessage="User Name"
    ></FormattedMessage>
  ),
  userEmail: (
    <FormattedMessage
      id="Global.UserEmail"
      defaultMessage="User Email"
    ></FormattedMessage>
  ),
  userPhone: (
    <FormattedMessage
      id="Global.UserPhone"
      defaultMessage="User Phone"
    ></FormattedMessage>
  ),
  softVersion: (
    <FormattedMessage
      id="Global.SoftwareVersion"
      defaultMessage="Software Version"
    ></FormattedMessage>
  ),
  contactName: (
    <FormattedMessage
      id="Global.ContactName"
      defaultMessage="Contact Name"
    ></FormattedMessage>
  ),
  contactEmail: (
    <FormattedMessage
      id="Global.ContactEmail"
      defaultMessage="Contact Email"
    ></FormattedMessage>
  ),
  accessLevel: (
    <FormattedMessage
      id="Global.AccessLevel"
      defaultMessage="Access Level"
    ></FormattedMessage>
  ),
  destinationEmail: (
    <FormattedMessage
      id="Global.DestinationEmail"
      defaultMessage="Destination Email"
    ></FormattedMessage>
  ),
  body: (
    <FormattedMessage id="Global.Body" defaultMessage="Body"></FormattedMessage>
  ),
  email: (
    <FormattedMessage
      id="Global.Email"
      defaultMessage="Email"
    ></FormattedMessage>
  ),
  phone: (
    <FormattedMessage
      id="Global.Phone"
      defaultMessage="Phone"
    ></FormattedMessage>
  ),
  name: (
    <FormattedMessage id="Global.Name" defaultMessage="Name"></FormattedMessage>
  ),
  cell: (
    <FormattedMessage
      id="Global.Cell"
      defaultMessage="Cell Number"
    ></FormattedMessage>
  ),
  maxDailyMessage: (
    <FormattedMessage
      id="Global.MaxDailyMessage"
      defaultMessage="Max. Msgs Per Day"
    ></FormattedMessage>
  ),
  costCenter: (
    <FormattedMessage
      id="Global.CostCenter"
      defaultMessage="Cost Center"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
