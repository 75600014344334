import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  CompleteSMSTitleText: (
    <FormattedMessage
      id="Home.CompleteSMSTitleText"
      defaultMessage="CompleteSMS"
    ></FormattedMessage>
  ),
  webSMSText: (
    <FormattedMessage
      id="Home.WebSMSText"
      defaultMessage="Manage your list of recipients and distributions."
    ></FormattedMessage>
  ),
  manageText: (
    <FormattedMessage
      id="Home.ManageLink"
      defaultMessage="Manage"
    ></FormattedMessage>
  ),
  templatesTitleText: (
    <FormattedMessage
      id="Home.TemplatesTitleText"
      defaultMessage="Templates"
    ></FormattedMessage>
  ),
  templatesText: (
    <FormattedMessage
      id="Home.TemplatesText"
      defaultMessage="View SMS Templates list"
    ></FormattedMessage>
  ),
  composeTitleText: (
    <FormattedMessage
      id="Home.ComposeTitleText"
      defaultMessage="Compose SMS"
    ></FormattedMessage>
  ),
  composeText: (
    <FormattedMessage
      id="Home.ComposeText"
      defaultMessage="Compose a new SMS"
    ></FormattedMessage>
  ),
  reportsTitleText: (
    <FormattedMessage
      id="Home.ReportsTitleText"
      defaultMessage="Reports"
    ></FormattedMessage>
  ),
  reportsText: (
    <FormattedMessage
      id="Home.ReportsText"
      defaultMessage="View reports for cost centres and users."
    ></FormattedMessage>
  ),
  viewText: (
    <FormattedMessage
      id="Home.ViewLink"
      defaultMessage="View"
    ></FormattedMessage>
  ),
  accountTitleText: (
    <FormattedMessage
      id="Home.AccountTitleText"
      defaultMessage="Account"
    ></FormattedMessage>
  ),
  accountText: (
    <FormattedMessage
      id="Home.AccountText"
      defaultMessage="Update your subscription &amp; plan and view invoices."
    ></FormattedMessage>
  ),
  welcomeText: (
    <FormattedMessage
      id="Home.WelcomeText"
      defaultMessage="Welcome back"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
