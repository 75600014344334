/*
  FormattedMessages.js - FormattedMessages for singleview webSMSUsers component

  Author: Julius Klaebe (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  addNew: <FormattedMessage id="Global.AddNew" defaultMessage="Add New" />,
  backToUser: (
    <FormattedMessage
      id="Global.backToUser"
      defaultMessage="Back to User List"
    />
  ),
  addNewUser: (
    <FormattedMessage id="Global.AddNew" defaultMessage="Add New User" />
  ),
  userInformation: (
    <FormattedMessage
      id="Global.userInformation"
      defaultMessage="User Information"
    />
  ),
  editCountry: (
    <FormattedMessage id="Global.EditCountry" defaultMessage="Country:" />
  ),
  webSMSUsers: (
    <FormattedMessage id="Global.WebSMSUsers" defaultMessage="WebSMS Users" />
  ),
  searchForUsers: (
    <FormattedMessage
      id="Global.SearchForUsers"
      defaultMessage="Search for Users"
    />
  ),
  costCentersTitle: (
    <FormattedMessage
      id="Global.CostCentersTitle"
      defaultMessage="Cost Centers"
    />
  ),
  language: (
    <FormattedMessage id="Global.Language" defaultMessage="* Language" />
  ),
  country: <FormattedMessage id="Global.Country" defaultMessage="* Country" />,
  loading: <FormattedMessage id="Global.Loading" defaultMessage="Loading..." />,
  addDN: (
    <FormattedMessage
      id="Global.AddDn"
      defaultMessage="Manage Dedicated Numbers"
    />
  ),
  errTextUserName: (
    <FormattedMessage
      id="Global.ErrTextUserName"
      defaultMessage="Requires a User Name."
    />
  ),
  errTextEmail: (
    <FormattedMessage
      id="Global.ErrTextUserEmail"
      defaultMessage="Requires an Email."
    />
  ),
  errTextEmailCheck: (
    <FormattedMessage
      id="Global.ErrTextEmailCheck"
      defaultMessage="The email provided is not in a valid format."
    />
  ),
  errTextCountry: (
    <FormattedMessage
      id="Global.ErrTextCountry"
      defaultMessage="Select a Country."
    />
  ),
  errTextLanguage: (
    <FormattedMessage
      id="Global.ErrTextLanguage"
      defaultMessage="Select a Language."
    />
  ),
  errTextCostCenter: (
    <FormattedMessage
      id="Global.ErrTextCostCenter"
      defaultMessage="Select a Cost Center."
    />
  ),
  errCheckPhoneFormat: (
    <FormattedMessage
      id="Global.ErrTextCostCenter"
      defaultMessage="Incorrect user mobile format, should only contain numbers and '+', eg: +0123456789/0123456789"
    />
  ),
  errTextPassword: (
    <FormattedMessage
      id="Global.ErrTextPassword"
      defaultMessage="Requires a Password."
    />
  ),
  errCheckPasswordStrong: (
    <FormattedMessage
      id="Global.ErrCheckPasswordStrong"
      defaultMessage="Password is not strong enough. Please ensure it is 8 or more characters long and contains at least one uppercase and one lowercase letter, a number and a symbol."
    />
  ),
  errTextMaxSms: (
    <FormattedMessage
      id="Global.ErrTextMaxSms"
      defaultMessage="Max SMS Per Day must be a positive number"
    />
  ),
  errBlankMaxSms: (
    <FormattedMessage
      id="Global.ErrBlankMaxSms"
      defaultMessage="Max SMS must not be blank"
    />
  ),
  errCheckPassword: (
    <FormattedMessage
      id="Global.ErrCheckPassword"
      defaultMessage="Requires a password in both password fields."
    />
  ),
  errCheckPasswordMatch: (
    <FormattedMessage
      id="Global.ErrCheckPasswordTwo"
      defaultMessage="Passwords don't match."
    />
  ),
  modalCancel: (
    <FormattedMessage id="Global.modalCancel" defaultMessage="Cancel" />
  ),
  modalYes: <FormattedMessage id="Global.modalYes" defaultMessage="Yes" />,
  modalWarning: (
    <FormattedMessage id="Global.modalWarning" defaultMessage="Warning" />
  ),
  modalBody: (
    <FormattedMessage
      id="Global.modalBody"
      defaultMessage="A number you want to release appears to be a legacy number based on the name. Are you sure you want to release that number?"
    />
  ),
  dedicatedNumber: (
    <FormattedMessage
      id="Global.dedicatedNumber"
      defaultMessage="Dedicated Number(s)"
    />
  ),
  saving: <FormattedMessage id="Global.Saving" defaultMessage="Saving..." />,
  saveChanges: (
    <FormattedMessage id="Global.saveChanges" defaultMessage="Save Changes" />
  ),
  campaign: <FormattedMessage id="Global.Campaign" defaultMessage="Campaign" />,
  deviceName: (
    <FormattedMessage id="Global.DeviceName" defaultMessage="Device Name" />
  ),
  release: <FormattedMessage id="Global.Release" defaultMessage="Release?" />,
  gateway: <FormattedMessage id="Global.Gateway" defaultMessage="Gateway" />,
};

export default FormattedMessages;
