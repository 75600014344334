/*
  SingleView.js - Admin Prefix Map Single View Main Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

// I18N
//import formattedMessages from './FormattedMessages';

// STYLES
//import '../Admin.css';

// NETWORK
import CountryRequest from '../CountryRequest';
import formattedMessages from '../../../Provider/FormattedMessages';

// OUR MODULES
// import RequestRabbit from '../../../../RequestRabbit';

// CONFIGURATION
// import config from '../../../../../Config';

// INSTANTIATION
const countryRequest = new CountryRequest();
// const requestRabbit = new RequestRabbit();

export default class ServiceCountryPrefixMapSingleView extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - prefixMap: A single prefixMap object
        - country: Country to make a prefixMap object for
        - backButtonClick: Function to handle Back Button click
        - isNew: Whether or not it's handling a new Preifx Map,
        - errorHandler: A function to pass errors to
    */

    console.log('Have prefixMap prop:', this.props.prefixMap);

    this.state = {
      isEditing: false,
      edits: {},
    };
  }

  /*
    Handles Edit Mode on/off
  */
  toggleEdit = () => {
    return this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;
    edits[name] = value;

    console.log('Edits is:', edits);

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    try {
      console.log('Handle submit');
      e.preventDefault();
      let updateData = this.state.edits;

      // Have new Object, create
      if (this.props.isNew) {
        console.log('Have props isNew, create new prefix map');
        await countryRequest.createNewPrefixMap(
          this.props.country.country_code_id,
          updateData,
          this.props.errorHandler
        );
        console.log('Post-create new prefix map');
      }
      // Editing object, update
      else {
        await countryRequest.updatePrefixMap(
          this.props.prefixMap.country_code_id,
          this.props.prefixMap.country_prefix_map_id,
          updateData,
          this.props.errorHandler
        );
      }

      window.location.href = '/admin/serviceconfig-country'; // Force reload - TODO: redo properly
    } catch (e) {
      console.log('Have err:', e);
      // Handle error
    }
  };

  /*
    Handles Delete of Country Prefix Map
  */
  handleDelete = async (e) => {
    await countryRequest.deletePrefixMapById(
      this.props.country.country_code_id,
      this.props.prefixMap.country_prefix_map_id,
      this.props.errorHandler
    );
    window.location.href = '/admin/serviceconfig-country'; // Force reload - TODO: redo properly
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="CountryShow" className="lander country">
        <p
          onClick={() => this.props.backButtonClick('list')}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
        <h3>Country Prefix Map Information</h3>
      </div>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateEditButton = () => {
    let { isEditing } = this.state;
    let buttonText = isEditing ? 'Cancel' : 'Edit';

    if (!this.props.isNew || typeof this.props.isNew === 'undefined') {
      return (
        <Button className="maxHeight" onClick={this.toggleEdit}>
          {buttonText}
        </Button>
      );
    }
    return null;
  };

  generateBody = () => {
    let { prefixMap, isNew } = this.props;
    let { isEditing } = this.state;

    if (isNew) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="before_prefix">
              <Form.Label column sm={2} className="info-label">
                * Before Prefix:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="before_prefix" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="after_prefix">
              <Form.Label column sm={2} className="info-label">
                * After Prefix:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="after_prefix" type="text" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="tn_length">
              <Form.Label column sm={2} className="info-label">
                * Length:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="tn_length" type="text" />
              </Col>
            </Form.Group>
            <Button className="maxHeight" variant="primary" type="submit">
              Save
            </Button>
          </Form>
          {this.generateEditButton()}
        </Fragment>
      );
    }
    // Display data if not editing
    else if (prefixMap && !this.state.isEditing) {
      return (
        <Form>
          {this.generateFormGroup(
            '* Before Prefix:',
            prefixMap.before_prefix,
            'before_prefix'
          )}
          {this.generateFormGroup(
            '* After Prefix:',
            prefixMap.after_prefix,
            'after_prefix'
          )}
          {this.generateFormGroup(
            '* Length:',
            prefixMap.tn_length,
            'tn_length'
          )}
          {this.generateEditButton()}
          <Button
            className="delete btn-red btn btn-danger"
            onClick={this.handleDelete}
          >
            Delete
          </Button>
        </Form>
      );
    } else if (prefixMap && isEditing) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="before_prefix">
              <Form.Label column sm={2} className="info-label">
                * Before Prefix:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="before_prefix"
                  type="text"
                  defaultValue={prefixMap.before_prefix}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="after_prefix">
              <Form.Label column sm={2} className="info-label">
                * After Prefix:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="after_prefix"
                  type="text"
                  defaultValue={prefixMap.after_prefix}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="tn_length">
              <Form.Label column sm={2} className="info-label">
                * Length:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="tn_length"
                  type="text"
                  defaultValue={prefixMap.tn_length}
                />
              </Col>
            </Form.Group>
            <Button className="maxHeight" variant="primary" type="submit">
              Save
            </Button>
          </Form>
          {this.generateEditButton()}
        </Fragment>
      );
    }

    return <span>No Data</span>;
  };

  render() {
    return (
      <div className="Home country">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
