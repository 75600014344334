/*
  Device/index.js - Main Device Component

  Author: Elle Dunbar (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
// import {Table} from 'react-bootstrap';
// import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap';

// OUR COMPONENTS
import Search from './Search';
import Single from './Single';
import All from './All';

import CustomerSingle from '../../Customer/Edit';
import UserReport from '../../../Reports/UserReport';
import PhoneNumberSingle from './Number/Single';

// I18N
// import formattedMessages from './FormattedMessages';

// STYLING
import '../../Admin.css';

export default class Device extends Component {
  constructor(props) {
    super(props);

    /*
      PROPS LIST
      - handleViewTwilioNumbers: Function to handle when View Available Twilio
      Numbers button is clicked
    */

    this.state = {
      selectedDevice: null,
      selectedCustomer: null,
      selectedUser: null,
      selectedNumber: null,
      viewAllDevices: false,
      backToAll: null,
    };
  }

  viewAllDevices = () => {
    return this.setState({
      viewAllDevices: true,
    });
  };

  handleBackToAllDevices = () => {
    return this.setState({
      viewAllDevices: true,
      selectedDevice: null,
      selectedCustomer: null,
      selectedUser: null,
      selectedNumber: null,
      addNewDevice: false,
      backToAll: null,
    });
  };

  handleBackClick = () => {
    return this.setState({
      selectedDevice: null,
      selectedCustomer: null,
      selectedUser: null,
      selectedNumber: null,
      viewAllDevices: false,
      addNewDevice: false,
      backToAll: null,
    });
  };

  handleAddNewDevice = (backToAll) => {
    return this.setState({
      viewAllDevices: false,
      addNewDevice: true,
      backToAll: backToAll,
    });
  };

  onClickDeviceName = (device, backToAll) => {
    return this.setState({
      viewAllDevices: false,
      selectedDevice: device,
      backToAll: backToAll,
    });
  };

  onClickCustomerName = (customer) => {
    this.props.navigate('/admin/customer/' + customer.customer_id);
  };

  onClickUserName = (customerUser) => {
    return this.setState({
      selectedUser: customerUser,
    });
  };

  onClickPhoneNumber = (phoneNumber) => {
    return this.setState({
      selectedNumber: phoneNumber,
    });
  };

  /*
    Generate main content
  */
  generateContent() {
    let {
      selectedDevice,
      selectedCustomer,
      selectedUser,
      selectedNumber,
      viewAllDevices,
      addNewDevice,
      backToAll,
    } = this.state;

    if (viewAllDevices) {
      return (
        <All
          errorHandler={this.props.errorHandler}
          backButtonClick={this.handleBackClick}
          showSingleDevice={this.onClickDeviceName}
          addNewDevice={this.handleAddNewDevice}
        />
      );
    }

    // Have a Selected Device
    if (selectedDevice || addNewDevice) {
      return (
        <Single
          device={selectedDevice}
          isNew={addNewDevice}
          backButtonClick={
            backToAll ? this.handleBackToAllDevices : this.handleBackClick
          }
          errorHandler={this.props.errorHandler}
        />
      );
    }

    // Have a Selected Customer
    if (selectedCustomer) {
      return (
        <CustomerSingle
          customer={selectedCustomer}
          backButtonClick={this.handleBackClick}
          errorHandler={this.props.errorHandler}
        />
      );
    }

    if (selectedUser) {
      return <UserReport userData={selectedUser} />;
    }

    if (selectedNumber) {
      return (
        <PhoneNumberSingle
          device={selectedNumber}
          backButtonClick={this.handleBackClick}
        />
      );
    }

    return (
      <Search
        handleViewTwilioNumbers={this.props.handleViewTwilioNumbers}
        viewAllDevices={this.viewAllDevices}
        showSingleDevice={this.onClickDeviceName}
        showSingleCustomer={this.onClickCustomerName}
        showSingleUserReport={this.onClickUserName}
        showSinglePhoneNumber={this.onClickPhoneNumber}
      />
    );
  }

  render() {
    return <div className="Home device">{this.generateContent()}</div>;
  }
}
