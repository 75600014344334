/*
  List.js - Admin Tariff Line List

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Table, Button } from 'react-bootstrap';

// COMPONENTS
import ListItem from './ListItem.js';

export default class TariffLineList extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - tariffLines: Array of Tariff Lines
        - addNewTariffLine: Function to handle Add New Button click
        - showSingleTariffLine: Function to handle viewing a single Tariff Line
        - errorHandler: A function to pass errors to
    */

    this.state = {};
  }

  /*
    Generates a Table for Tariff Lines
  */
  generateTable() {
    let htmlRows = [];
    let { tariffLines, isLoading } = this.props;

    if (isLoading) {
      return <span>Loading...</span>;
    }
    for (let i = 0; i < tariffLines.length; i++) {
      htmlRows.push(
        <ListItem
          key={tariffLines[i].tariff_line_id}
          id={tariffLines[i].tariff_line_id}
          lineName={tariffLines[i].tariff_name}
          lineType={tariffLines[i].tariff_type_name}
          lineUnit={tariffLines[i].unit_name}
          lineRate={tariffLines[i].rate}
          lineQuantity={tariffLines[i].quantity}
          lineBatches={tariffLines[i].batches}
          line={tariffLines[i]}
          onClickName={(e) => this.props.showSingleTariffLine(e)}
        ></ListItem>
      );
    }

    return (
      <Table>
        <thead>
          <tr>
            <td className="medium left-wrap tableTop column-left column-right">
              Tariff
            </td>
            <td className="medium left-wrap tableTop column-right">Type</td>
            <td className="medium left-wrap tableTop column-right">Unit</td>
            <td className="medium left-wrap tableTop column-right">Rate</td>
            <td className="medium left-wrap tableTop column-right">Quantity</td>
            <td className="medium left-wrap tableTop column-right">Batches</td>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  }

  render() {
    return (
      <div key="TariffLineList" className="table">
        <h4>List of Tariff Lines</h4>
        <Button
          className="btn add-new-template marginRight"
          onClick={this.props.addNewTariffLine}
        >
          Add New
        </Button>
        <br />
        <br />
        {this.generateTable()}
      </div>
    );
  }
}
