import React from 'react';

const ListItem = ({
  id,
  onCostCentreClick,
  onContactClick,
  name = '',
  contactName = '',
  users,
  element,
}) => (
  <tr key={id} id={id}>
    <td className="medium textLeft" name="name">
      <span className="blue" onClick={() => onCostCentreClick(element)}>
        {name}
      </span>
    </td>
    <td className="medium textLeft" name="contactName">
      <span
        className={element.customer_contact_id ? "blue" : null }
        onClick={() => (element.customer_contact_id ? onContactClick(element) : null)}
      >
        {contactName}
      </span>
    </td>
    <td className="medium textLeft">{users}</td>
  </tr>
);

export default ListItem;
