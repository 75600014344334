// NPM MODULES
import React from 'react';
import CustomTooltip from '../CustomTooltip';

const MessagePartTooltip = () => {
  return (
    <CustomTooltip
      tooltipText={
        'Messages = Quantity of sends to recipients\n\n' +
        'Parts = Quantity of SMS sent per message\n' +
        '(160 characters = 1 part or 1 SMS, any message exceeding 160 characters is ' +
        'broken into parts made up of 153 characters — Unicode messages are broken into 70 and 67 respectively. ' +
        'All messages have a limit of 5 parts)'
      }
      margin="5px"
      className="tooltip-standard-unicode"
    />
  );
};

export default MessagePartTooltip;
