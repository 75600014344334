import React, { Component } from 'react';
import ListItem from './ListItem';
import formattedMessages from './FormattedMessages';
import SmsDrilldownComponent from './SmsDrilldownComponent';
import { Modal, Button } from 'react-bootstrap';
import { Buffer } from 'buffer';
window.Buffer = Buffer;

import Paginator from '../../../../components/Paginator';

// STYLING
import '../../../Users/Users.css';

const crypto = require('crypto-browserify');
const algorithm = 'aes-256-ctr';

class DrilldownComponent extends Component {
  constructor(props) {
    super(props);

    /*
        expects props:
        - selectedEmailID: The selected email ID for the drilldown
        - backToRecent: A function that sets the selectedEmailID for parent back to null
        - getDrilldownMessages: Calls to the backend to retrieve drill down results.
        - getSmsIdDrilldownMessages: Calls to the backend to retrieve sms drill down results.
    */

    this.state = {
      values: [],
      selectedSmsId: null,
      pageNumber: 1,
      showAuthDetailsModal: false,
      authDetails: null,
      body: null, // decrypted body
      selectedResult: null,
    };
    this.pageLength = 25;
  }

  componentDidMount() {
    this.getValues();
  }

  /** Returns from drilldown back to recent by deleting 'selectedEmailID'. */
  backToEmailDrilldown = () => {
    this.setState({ selectedSmsId: null, values: [] });
    this.getValues();
  };

  /*
   * Handles the change event when clicking a pagination number
   */
  changeEvent = (i) => {
    return this.setState({
      pageNumber: i + 1,
    });
  };

  /** Gets values for the selected email ID. */
  getValues = async () => {
    const { authResult, finalResults } = await this.props.getDrilldownMessages(
      this.props.selectedEmailID
    );
    console.log('Drilldown values:', finalResults, authResult); // helpful for debugging.

    // essentially checks here to see if the auth details is parsable before trying to save it.
    try {
      if (authResult) {
        const jsonObject = JSON.parse(authResult.json_object);

        if (!jsonObject.authDetails)
          throw new Error(
            'The authDetails field is missing from the AUTH_DETAILS log entry'
          );

        return this.setState({
          values: finalResults,
          authDetails: jsonObject.authDetails,
        });
      }
      console.log('Could not find authResult.');
    } catch (e) {
      console.log(
        'Error finding and parsing auth details:',
        authResult.json_object
      );
    }
    this.setState({ values: finalResults });
  };

  /**
   * Generates content for drilldown component.
   * @returns
   */
  generateContent = () => {
    if (this.state.selectedSmsId) {
      return (
        <div>
          <SmsDrilldownComponent
            selectedSmsId={this.state.selectedSmsId}
            backToEmailDrilldown={this.backToEmailDrilldown}
            getSmsIdDrilldownMessages={this.props.getSmsIdDrilldownMessages}
          />
        </div>
      );
    }
    return (
      <div className="visible-desktop">
        <br />
        {this.generateBackButton()}
        {this.generateModal()}
        <h5>Email ID: {this.props.selectedEmailID}</h5>
        {this.generateTable()}
      </div>
    );
  };

  /**
   * Generates the table that displays the logs for the drilldown
   * @returns
   */
  generateTable = () => {
    if (this.state.values.length > 0) {
      return (
        <div key="LogList" className="table">
          <h6>{this.state.values[0].customer_user_email}</h6>
          <br />
          <table width="100%" className="table-middle">
            <thead>
              <tr>
                <td
                  className="tableTop column-right column-left"
                  style={{
                    width: '200px',
                    marginLeft: '5px',
                  }}
                >
                  {formattedMessages.dateText}
                </td>
                <td
                  className="tableTop column-left"
                  style={{ marginLeft: '5px' }}
                >
                  {formattedMessages.smsIdText}
                </td>
                <td
                  className="tableTop column-left"
                  style={{ marginLeft: '5px' }}
                >
                  {formattedMessages.originText}
                </td>
                <td
                  className="tableTop column-left"
                  style={{ marginLeft: '5px' }}
                >
                  {formattedMessages.destinationText}
                </td>
                <td
                  className="tableTop column-left"
                  style={{ marginLeft: '5px' }}
                >
                  {formattedMessages.processingTimeText}
                </td>
                <td
                  className="tableTop column-left column-right"
                  style={{ marginLeft: '5px' }}
                >
                  {formattedMessages.resultText}
                </td>
              </tr>
            </thead>
            <tbody>{this.generateTableBody()}</tbody>
          </table>
          <br />
          <Paginator
            itemCount={this.state.values.length}
            changeEvent={this.changeEvent}
            changePageFunction={(ref) => (this.resetFunction = ref)}
            pageLength={this.pageLength}
          />
        </div>
      );
    } else {
      return <p>Loading...</p>;
    }
  };

  /**
   * will decrypt auth body based on entered salt/hash.
   * @param {Object} e event for input changes
   * @returns sets body in state
   */
  handleDecryption(e) {
    const target = e.target;
    let value = target.value;
    let { authDetails } = this.state;

    // increases hash to 32 characters if currently less.
    if (value.length < 32) value = value + '#'.repeat(32 - value.length);
    // slices hash to 32 characters if currently more.
    else if (value.length > 32) value = value.slice(value.length - 32);

    // decryption section. 'value' has to be 32 characters.
    const hash = JSON.parse(authDetails);
    const decipher = crypto.createDecipheriv(
      algorithm,
      value,
      Buffer.from(hash.iv, 'hex')
    );
    const decrypted = Buffer.concat([
      decipher.update(Buffer.from(hash.content, 'hex')),
      decipher.final(),
    ]);
    return this.setState({
      body: decrypted,
    });
  }

  /**
   * Generates the modal that appears for Auth request logs.
   * @returns modal
   */
  generateModal = () => {
    let { showAuthDetailsModal, authDetails, body } = this.state;
    if (authDetails) {
      let message = '(unable to find details)';

      // if decoded body exists in state, display it.
      if (body) message = body;
      // if no decoded body, it shows the encrypted 'content' of the stringified encrypted object
      else {
        const parsedObject = JSON.parse(authDetails);
        if (
          parsedObject &&
          parsedObject.content &&
          parsedObject.content !== null
        )
          message = parsedObject.content;
      }

      // returns modal to view and decrypt auth details.
      return (
        <Modal
          show={showAuthDetailsModal}
          onHide={() => this.onClickAuthModalClose()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Auth Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Details of authentication request: {'\n' + message}
            <br />
            <br />
            <input
              className="find-users input-name"
              type="text"
              name="hash_value"
              onChange={(event) => this.handleDecryption(event)}
            />
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.onClickAuthModalClose()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return null;
  };

  /** Shows modal for Auth request logs. */
  onClickAuthModal() {
    this.setState({
      showAuthDetailsModal: true,
    });
  }

  /** Hides modal for Auth request logs. */
  onClickAuthModalClose() {
    this.setState({
      showAuthDetailsModal: false,
      body: null,
    });
  }

  setSelectedResult(id) {
    console.log(id);
    if (this.state.selectedResult === id) {
      return this.setState({
        selectedResult: null,
      });
    }
    return this.setState({
      selectedResult: id,
    });
  }

  /**
   * Generates the body of the table to display all drilldown data
   * @returns
   */
  generateTableBody = () => {
    let rows = [];
    let { drilldownEmail } = this.props;
    let { values, pageNumber, authDetails, selectedResult } = this.state;
    let startDate = new Date(values[values.length - 1].log_time);

    // handles slicing the list to match paginator.
    let lowerLimit =
      values.length - pageNumber * this.pageLength < 0
        ? 0
        : values.length - pageNumber * this.pageLength;
    values = values.slice(
      lowerLimit,
      values.length - (pageNumber - 1) * this.pageLength
    );

    for (let i = values.length - 1; i >= 0; i--) {
      rows.push(
        <ListItem
          key={values[i].trace_log_id}
          id={values[i].trace_log_id}
          date={values[i].date}
          smsId={values[i].sms_id}
          dateRelative={new Date(values[i].log_time) - startDate}
          logLevel={values[i].log_level}
          destTn={values[i].dest_tn}
          origTn={values[i].orig_tn}
          result={values[i].log_message}
          onClickName={(e) => drilldownEmail(e)}
          onClickSmsId={(e) => this.drilldownSmsId(e)}
          onClickResult={(e) => this.setSelectedResult(e)}
          collapse={values[i].trace_log_id === selectedResult}
          jsonObject={values[i]}
          tag={values[i].tag}
          onClickAuth={
            // only the auth log gets this function and authResult has to be parsable.
            values[i].sms_id === undefined && authDetails
              ? (e) => this.onClickAuthModal(e)
              : null
          }
        />
      );
    }
    return rows;
  };

  /**
   * add selected sms id to state
   * @returns button component (as a p tag)
   */
  drilldownSmsId = (e) => {
    this.setState({
      selectedSmsId: e,
    });
  };

  /**
   * Generates back button to go back to the Recent logs page.
   * @returns button component (as a p tag)
   */
  generateBackButton = () => {
    return (
      <p onClick={() => this.props.backToRecent()} className="backLink">
        <i className="material-icons">keyboard_arrow_left</i>
        <span>Back</span>
      </p>
    );
  };

  /** renders the component */
  render() {
    return this.generateContent();
  }
}

export default DrilldownComponent;
