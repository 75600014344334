import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  activityBetween: (
    <FormattedMessage
      id="Billing.ActivityBetween"
      defaultMessage="Activity Between"
    />
  ),
  activity: (
    <FormattedMessage id="Billing.Activity" defaultMessage="Activity" />
  ),
  quickSearch: (
    <FormattedMessage id="Billing.quickSearch" defaultMessage="Quick Search" />
  ),
  searchBy: (
    <FormattedMessage id="Billing.searchBy" defaultMessage="Search By" />
  ),
  dateRange: (
    <FormattedMessage id="Billing.dateRange" defaultMessage="Date Range" />
  ),
  invoiced: (
    <FormattedMessage id="Billing.invoicedDate" defaultMessage="Invoiced" />
  ),
  paid: <FormattedMessage id="Billing.paidDate" defaultMessage="Paid" />,
  today: <FormattedMessage id="Billing.today" defaultMessage="Today" />,
  yesterday: (
    <FormattedMessage id="Billing.yesterday" defaultMessage="Yesterday" />
  ),
  lastWeek: (
    <FormattedMessage id="Billing.lastWeek" defaultMessage="Last Week" />
  ),
  lastFortnight: (
    <FormattedMessage
      id="Billing.lastFortnight"
      defaultMessage="Last Fortnight"
    />
  ),
  lastMonth: (
    <FormattedMessage id="Billing.lastMonth" defaultMessage="Last Month" />
  ),
  lastYear: (
    <FormattedMessage id="Billing.lastYear" defaultMessage="Last Year" />
  ),
  amountBetween: (
    <FormattedMessage
      id="Billing.amountBetween"
      defaultMessage="Amount Between"
    />
  ),
  all: <FormattedMessage id="Billing.all" defaultMessage="All" />,
  invoiced: (
    <FormattedMessage id="Billing.invoiced" defaultMessage="Invoiced" />
  ),
  pending: <FormattedMessage id="Billing.pending" defaultMessage="Pending" />,
  paid: <FormattedMessage id="Billing.paid" defaultMessage="Paid" />,
  failed: <FormattedMessage id="Billing.failed" defaultMessage="Failed" />,
  paidManual: (
    <FormattedMessage id="Billing.paidManual" defaultMessage="Paid - Manual" />
  ),
  applyFilter: (
    <FormattedMessage id="Billing.applyFilter" defaultMessage="Apply Filter" />
  ),
  salesAgent: (
    <FormattedMessage id="Billing.salesAgent" defaultMessage="Sales Agent" />
  ),
  find: <FormattedMessage id="Billing.find" defaultMessage="Find" />,
  status: <FormattedMessage id="Billing.status" defaultMessage="Status" />,
  provider: (
    <FormattedMessage id="Billing.provider" defaultMessage="Provider" />
  ),
  invoiceNo: (
    <FormattedMessage id="Billing.invoiceNo" defaultMessage="Invoice No" />
  ),
  customer: (
    <FormattedMessage id="Billing.customer" defaultMessage="Customer" />
  ),
  amount: <FormattedMessage id="Billing.amount" defaultMessage="Amount" />,
  currency: (
    <FormattedMessage id="Billing.currency" defaultMessage="Currency" />
  ),
  transitionToInvoiced: (
    <FormattedMessage
      id="Billing.TransitionInvoiced"
      defaultMessage="Transition to Invoiced"
    />
  ),
  processTransaction: (
    <FormattedMessage
      id="Billing.process"
      defaultMessage="Process Transaction"
    />
  ),
  failTransaction: (
    <FormattedMessage
      id="Billing.FailTransaction"
      defaultMessage="Fail Transaction"
    />
  ),
  cancelTransaction: (
    <FormattedMessage
      id="Billing.CancelTransaction"
      defaultMessage="Cancel Transaction"
    />
  ),
};

export default FormattedMessages;
