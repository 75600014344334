/*
  FormattedMessages.js - FormattedMessages for the drilldown component for the Monitor page.

  Author: Adarsh Anup (2022)
  Company: Red Oxygen
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  dateText: (
    <FormattedMessage id="Global.dateText" defaultMessage="Date (UTC)" />
  ),
  smsIdText: <FormattedMessage id="Global.smsIdText" defaultMessage="SMS Id" />,
  logLevelText: (
    <FormattedMessage id="Global.logLevelText" defaultMessage="Level" />
  ),
  originText: (
    <FormattedMessage id="Global.originText" defaultMessage="Origin" />
  ),
  destinationText: (
    <FormattedMessage
      id="Global.destinationText"
      defaultMessage="Destination"
    />
  ),
  resultText: (
    <FormattedMessage id="Global.resultText" defaultMessage="Result" />
  ),
  relativeTimeText: (
    <FormattedMessage
      id="Global.relativeTimeText"
      defaultMessage="Relative Time (ms)"
    />
  ),
  relativeToggleText: (
    <FormattedMessage
      id="Global.relativeToggleText"
      defaultMessage="Relative"
    />
  ),
  processingTimeText: (
    <FormattedMessage
      id="Global.processingTimeText"
      defaultMessage="Processing Time"
    />
  ),
};

export default FormattedMessages;
