import React, { Component } from 'react';
import ListComponent from '../ListComponent';
import { Button } from 'react-bootstrap';
import DrilldownComponent from '../DrilldownComponent';
import FormattedMessages from './FormattedMessages';
import DateRangeSelector from '../../../../components/DateRangeSelector';

class SearchComponent extends Component {
  constructor(props) {
    super(props);

    /*
            expected props:
                - getDrilldownMessages: Calls to the backend to retrieve drill down results.
                - values: Values that should be used for displaying the log search results.
                - getSearchMessages: Calls to the backend to retrieve search results.
        */

    this.state = {
      selectedEmailID: null,
      search_value: null,
      search_type: 'email',
      startDate: null,
      endDate: null,
      searchedAlready: false,
    };
  }

  /**
   * Drilldown into an email after being clicked.
   * @param {Event} e
   */
  drilldownEmail = (e) => {
    this.setState({ selectedEmailID: e });
  };

  /** Returns from drilldown back to recent by deleting 'selectedEmailID'. */
  backToRecent = () => {
    this.setState({ selectedEmailID: null });
  };

  /**
   * Handles the search type's dropdown input changes.
   * @param {Event} e
   * @returns
   */
  onChange(e) {
    const value = e.target.value;
    return this.setState({
      search_type: value,
    });
  }

  /**
   * Handles the text-input field's changes.
   * @param {*} e
   * @returns
   */
  handleInputChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    return this.setState({
      [name]: value,
    });
  }

  /** Prepares call to the backend when search button is pressed. */
  submitHandler = async () => {
    let { search_value, search_type, startDate, endDate } = this.state;

    let type = 0;
    switch (search_type) {
      default: // case email address (0)
        break;
      case 'emailID':
        type = 1;
        break;
      case 'phoneNumber':
        type = 2;
        break;
      case 'message':
        type = 3;
        break;
    }
    await this.props.getSearchMessages(search_value, type, startDate, endDate);
    this.setState({ searchedAlready: true });
    console.log(startDate, endDate);
  };

  /**
   * Generates the form for searching through log messages
   * @returns
   */
  generateSearchForm() {
    return (
      <div>
        <br></br>
        <table
          border="0"
          cellPadding="5px"
          style={{ marginLeft: '30px' }}
          width="80%"
          className="no-table-row-background"
        >
          <tbody>
            <tr valign="top">
              <td width="10%">{FormattedMessages.searchType}</td>
              <td>
                <select
                  name="search_type"
                  value={this.state.search_type}
                  onChange={(e) => {
                    this.onChange(e);
                  }}
                >
                  <option key={'email'} value={'email'}>
                    Email
                  </option>
                  <option key={'emailID'} value={'emailID'}>
                    Email ID
                  </option>
                  <option key={'phoneNumber'} value={'phoneNumber'}>
                    Phone Number
                  </option>
                  <option key={'message'} value={'message'}>
                    Message
                  </option>
                </select>
              </td>
            </tr>
            <tr valign="top">
              <td width="30%">{FormattedMessages.searchValue}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="search_value"
                  value={this.state.customer_id}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr valign="top">
              <td width="30%">{FormattedMessages.dateRange}</td>
              <td colSpan="4">
                <DateRangeSelector
                  rangeOnly={true}
                  rangeFunction={(startDate, endDate) =>
                    this.setState({ startDate: startDate, endDate: endDate })
                  }
                />
              </td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <br />
                <Button
                  className="btn btn-primary maxHeight"
                  onClick={() => this.submitHandler()}
                >
                  {FormattedMessages.searchButton}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  /**
   * Handles generating the search results (if any).
   * @returns JSX for the search results.
   */
  generateSearchResults() {
    if (this.props.values?.length > 0 || !this.state.searchedAlready) {
      return (
        <div className="table">
          <ListComponent
            logs={this.props.values}
            drilldownEmail={this.drilldownEmail}
          />
        </div>
      );
    }
    return <span className="main">{FormattedMessages.noResultsFound}</span>;
  }

  /**
   * Generates the content of the 'Search' tab, which could either be a search page or drilldown into an email ID.
   * @returns
   */
  generateSearchPage() {
    if (this.state.selectedEmailID) {
      return (
        <div>
          <DrilldownComponent
            selectedEmailID={this.state.selectedEmailID}
            backToRecent={this.backToRecent}
            getDrilldownMessages={this.props.getDrilldownMessages}
            getSmsIdDrilldownMessages={this.props.getSmsIdDrilldownMessages}
          />
        </div>
      );
    } else {
      return (
        <div>
          <br></br>
          <h5>{FormattedMessages.searchTitle}</h5>
          {this.generateSearchForm()}
          <br></br>
          {this.generateSearchResults()}
        </div>
      );
    }
  }

  /** renders the search page */
  render() {
    return this.generateSearchPage();
  }
}

export default SearchComponent;
