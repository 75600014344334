// NPM MODULES
import React, { Component } from 'react';

// FORMATTED MESSAGES
import formattedMessages from '../FormattedMessages';

export default class BackButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    /*
    PROPS LIST
    - onClick: Function to handle when the back button is clicked
    - hideDisplay: A boolean for whether to hide the back button or not
    */
  }

  /*
    Generates a back button
  */
  render() {
    if (this.props.hideDisplay) return null;

    return (
      <p onClick={() => this.props.onClick()} className="backLink">
        <i className="material-icons">keyboard_arrow_left</i>
        <span>{formattedMessages.back}</span>
      </p>
    );
  }
}
