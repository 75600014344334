// NPM
import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';

// COMPONENTS
import BillingTabs from '../index';
import ErrorList from '../../../components/ErrorList';

// I18N
import formattedMessages from './FormattedMessages';

// IMGS
import stripeLoge from '../../../images/Powered by Stripe - blurple.svg';

// NETWORK
import PaymentRequest from './PaymentRequest';

const paymentRequest = new PaymentRequest();

export default class PaymentMethod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      last_four: '',
      expiry: '',
      edits: {},
      isSaving: false,

      loading: true,
      cardUpdate: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.customer && this.props.customer !== null) {
      let { errorHandler } = this.props;
      console.log('component mounted');
      let { cc_name, cc_expiry, cc_last_four } =
        await paymentRequest.getCardDetails(errorHandler);
      this.setState({
        name: cc_name || 'N/A',
        last_four: cc_last_four || 'N/A',
        expiry: cc_expiry || 'N/A',
        loading: false,
      });
    }
  };

  toggleCardUpdate = async () => {
    this.setState({
      cardUpdate: !this.state.cardUpdate,
    });
  };

  saveCard = async () => {
    this.setState({
      isSaving: true,
    });
    let { edits } = this.state;
    let { errorHandler } = this.props;
    let hasError, type;
    let errors = [];
    // TODO: Improve input validation
    if (!edits.name) {
      hasError = true;
      errors.push('Invalid Card Name');
    }
    if (edits.name?.length > 64) {
      hasError = true;
      errors.push('Card Name cannot be longer than 64 characters');
    }
    if (edits.name?.trim().length <= 0) {
      hasError = true;
      errors.push('Card Name cannot be blank');
    }
    if (edits.number) {
      // Determine type from card number
      edits.number = edits.number.replace(/[.\-\s]/, '');
      const visaPattern = /^4[0-9]{12}(?:[0-9]{3})?$/;
      const masterCardPattern =
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/;
      const discoverPattern =
        /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/;
      const amexPattern = /^3[47][0-9]{13}$/;
      if (visaPattern.test(edits.number)) type = 'Visa';
      else if (masterCardPattern.test(edits.number)) type = 'Mastercard';
      else if (discoverPattern.test(edits.number)) type = 'Discover';
      else if (amexPattern.test(edits.number)) type = 'American Express';
      else {
        hasError = true;
        errors.push(
          'Unrecognised Card Type. Card must be VISA, Mastercard, Discover or American Express'
        );
      }
    } else {
      hasError = true;
      errors.push('Invalid Card Number');
    }
    // CVV should be 3, unless the card type is American Express in which case its 4
    if (
      !edits.cvv ||
      edits.cvv.length !== (type === 'American Express' ? 4 : 3)
    ) {
      console.log('Error with CVV', type);
      hasError = true;
      errors.push('Invalid Card CVV');
    }
    if (edits.expiryMonth && edits.expiryYear) {
      let date = new Date();
      let currentMonth = date.getMonth(); //An integer, between 0 and 11, representing the month for the given date according to local time: 0 for January, 1 for February, and so on.
      let currentYear = date.getFullYear();
      if (
        edits.expiryYear > currentYear ||
        (edits.expiryYear == currentYear &&
          edits.expiryMonth >= currentMonth + 1)
      ) {
        const yyyy = edits.expiryYear;
        const mm = edits.expiryMonth;
        const yy = yyyy.substring(2);
        const mmyy = mm + yy;
        if (!hasError) {
          edits.expiry = mmyy;
        }
      } else {
        console.log('error occurred');
        hasError = true;
        errors.push('Expiry date must be in the future');
      }
    } else {
      hasError = true;
      errors.push('Invalid Card Expiry');
    }
    console.log(hasError, errors);
    this.setState({
      hasError: hasError,
      errors: errors,
    });
    if (hasError) {
      return this.setState({
        isSaving: false,
      });
    }

    let { cc_name, cc_expiry, cc_last_four } =
      await paymentRequest.saveCardDetails(
        edits.name,
        edits.number,
        edits.expiry,
        edits.cvv,
        type,
        errorHandler
      );

    let updateObject = {};
    if (cc_name && cc_expiry && cc_last_four) {
      updateObject = {
        name: cc_name,
        last_four: cc_last_four,
        expiry: cc_expiry,
      };
    }
    return this.setState({
      ...updateObject,
      cardUpdate: false,
      isSaving: false,
      edits: [],
      errors: [],
      hasError: false,
    });
  };

  handleInputChange = (event) => {
    let { edits } = this.state;
    const target = event.target;
    const value = target.value;
    const name = target.name;
    edits[name] = value;
    console.log('edits', edits);

    return this.setState({
      edits: edits,
    });
  };

  handleCardExpiry = () => {
    let listMonth = [];
    let listYear = [];
    const date = new Date();
    let year = date.getFullYear();
    listMonth.push(
      <option selected disabled hidden key={null} value={null}>
        MM
      </option>
    );
    for (let i = 1; i <= 12; i++) {
      let month = i.toString();
      if (month.length === 1) {
        month = '0' + month;
      }
      listMonth.push(<option value={month}>{month}</option>);
    }
    listYear.push(
      <option selected disabled hidden key={null} value={null}>
        YYYY
      </option>
    );
    for (let i = 0; i <= 20; i++) {
      let expiryYear = year + i;
      listYear.push(<option value={expiryYear}>{expiryYear}</option>);
    }
    return (
      <>
        <select
          id="expiryMonth"
          name="expiryMonth"
          onChange={(event) => this.handleInputChange(event)}
        >
          {listMonth}
        </select>
        <select
          id="expiryYear"
          name="expiryYear"
          onChange={(event) => this.handleInputChange(event)}
        >
          {listYear}
        </select>
      </>
    );
  };

  generateUpdateInputs() {
    return (
      <Fragment>
        <div className="flex">
          <table
            border="0"
            cellPadding="5px"
            style={{ margin: '10px' }}
            className="no-table-row-background"
          >
            <tbody>
              <tr valign="top">
                <td width="10%">{formattedMessages.cardName}</td>
                <td width="90%">
                  <input
                    className="update-card input-name"
                    type="text"
                    name="name"
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </td>
              </tr>
              <tr valign="top">
                <td width="10%">{formattedMessages.cardNumber}</td>
                <td width="90%">
                  <input
                    className="update-card input-number"
                    type="number"
                    name="number"
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </td>
              </tr>
              <tr valign="top">
                <td width="10%">{formattedMessages.cardExpiry}</td>
                <td width="90%">{this.handleCardExpiry()}</td>
              </tr>
              <tr valign="top">
                <td width="10%">{formattedMessages.cardCVV}</td>
                <td width="90%">
                  <input
                    className="update-card input-cvv"
                    type="number"
                    name="cvv"
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <img
          src={stripeLoge}
          alt=""
          width="150px"
          border="0"
          className="payment"
        />
        <p className="payment">
          For security purposes your card details will be converted into a
          secure token before being saved in a secure vault.
        </p>
        {this.generateErrorList()}
        {this.generateButton()}
      </Fragment>
    );
  }

  /*
   Handles whether or not to display the save card button
 */
  generateButton = () => {
    if (this.state.isSaving) return <p>Saving...</p>;
    return (
      <Button className="btn btn-primary maxHeight" onClick={this.saveCard}>
        {formattedMessages.saveCard}
      </Button>
    );
  };

  /*
    Renders Error Text Component
  */
  generateErrorList = () => {
    return (
      <ErrorList hasError={this.state.hasError} errors={this.state.errors} />
    );
  };

  render() {
    let { accessLevel, accessType, customer } = this.props;

    if (!this.state.loading) {
      let { name, last_four, expiry } = this.state;
      return (
        <BillingTabs
          tab="paymentmethod"
          accessLevel={accessLevel}
          accessType={accessType}
          customerType={customer?.customer_type}
        >
          <Fragment>
            <h3>{formattedMessages.cardDetails}</h3>
            <table
              border="0"
              cellPadding="5px"
              style={{ margin: '10px' }}
              width="80%"
              className="no-table-row-background"
            >
              <tbody>
                <tr valign="top">
                  <td width="10%">{formattedMessages.cardName}</td>
                  <td width="90%">{name}</td>
                </tr>
                <tr valign="top">
                  <td width="10%">{formattedMessages.cardEnding}</td>
                  <td width="90%">#### #### #### {last_four}</td>
                </tr>
                <tr valign="top">
                  <td width="10%">{formattedMessages.cardExpiry}</td>
                  <td width="90%">{expiry}</td>
                </tr>
              </tbody>
            </table>
            <Button
              className="btn btn-primary maxHeight"
              onClick={this.toggleCardUpdate}
            >
              {last_four === 'N/A'
                ? formattedMessages.addCard
                : formattedMessages.replaceCard}
            </Button>
            {this.state.cardUpdate ? this.generateUpdateInputs() : null}
          </Fragment>
        </BillingTabs>
      );
    } else
      return (
        <BillingTabs
          tab="paymentmethod"
          accessLevel={accessLevel}
          accessType={accessType}
          customerType={customer?.customer_type}
        >
          <Fragment>
            <h3>{formattedMessages.cardDetails}</h3>
            <h4>Loading...</h4>
          </Fragment>
        </BillingTabs>
      );
  }
}
