/*
  List.js - Admin Tariff Scheme List

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Table, Button } from 'react-bootstrap';
// import {Form, Table, Button, Col, Row} from 'react-bootstrap';

// COMPONENTS
import ListItem from './ListItem';
import formattedMessages from '../Customer/FormattedMessages';

export default class ServiceCarrierDeviceList extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - tariffSchemes: List of TariffSchemes
        - addNewTariffScheme: Function to handle Add New Button click
        - showSingleTariffScheme: Function to handle viewing a single Tariff Scheme
        - errorHandler: A function to pass errors to
    */

    this.state = {};
  }

  /*
    Generates a Table for Tariff Schemes
  */
  generateTable() {
    let htmlRows = [];
    let { tariffSchemes } = this.props;

    for (let i = 0; i < tariffSchemes.length; i++) {
      htmlRows.push(
        <ListItem
          key={tariffSchemes[i].tariff_scheme_id}
          id={tariffSchemes[i].tariff_scheme_id}
          schemeName={tariffSchemes[i].tariff_scheme_name}
          schemeType={tariffSchemes[i].scheme_type_name}
          schemeStatus={tariffSchemes[i].scheme_status_name}
          scheme={tariffSchemes[i]}
          onClickName={(e) => this.props.showSingleTariffScheme(e)}
        />
      );
    }
    if (tariffSchemes.length > 0) {
      return (
        <div key="SchemeList" className="table">
          <div className="lander flex">
            <h2>List of Tariff Schemes</h2>
            <Button
              onClick={this.props.addNewTariffScheme}
              className="btn add-new-template border flexRight"
            >
              {formattedMessages.addNewText}
            </Button>
          </div>
          <br />
          <br />
          <Table>
            <thead>
              <tr>
                <td className="medium tableTop textLeft">Name</td>
                <td className="medium tableTop textLeft">Type</td>
                <td className="medium tableTop textLeft">Status</td>
              </tr>
            </thead>
            <tbody>{htmlRows}</tbody>
          </Table>
        </div>
      );
    } else {
      return (
        <div key="SchemeList" className="table">
          <div className="lander flex">
            <h2>List of Tariff Schemes</h2>
            <Button
              onClick={this.props.addNewTariffScheme}
              className="btn add-new-template border flexRight"
            >
              {formattedMessages.addNewText}
            </Button>
          </div>
          <br />
          Loading...
        </div>
      );
    }
  }

  render() {
    return (
      <div key="TariffSchemeList" className="table">
        {this.generateTable()}
      </div>
    );
  }
}
