// NPM MODULES
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

// STYLING
import './LoaderButton.css';

// CONFIG
// import config from '../Config';

// NETWORK
import LanguageRequest from './requests/LanguageRequest';

const languageRequest = new LanguageRequest();

class LanguagePicker extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - onChange: Function to handle when a choice is made on Select
        - returnDefaultLanguageId: Function to return default language_id
        - selectName: (Optional) A name to give the Select element. Defaults to 'language_id'
        - selectedLanguageId: (Optional) Sets as the selected option on the Select element
    */

    this.state = {
      languages: [],
    };
  }

  async componentDidMount() {
    let languages = await languageRequest.getLanguages();
    if (
      languages.length > 0 &&
      (this.props.returnDefaultLanguageId || this.props.selectedLanguageId)
    ) {
      // add || edit
      for (let i = 0; i < languages.length; i++) {
        if (
          languages[i].language_name.toLowerCase() === 'english' &&
          this.props.returnDefaultLanguageId
        )
          this.props.returnDefaultLanguageId(languages[i].language_id);
        if (languages[i].language_name.toLowerCase() === 'universal')
          languages.splice(i, 1);
      }
    }

    return this.setState({
      languages: languages,
    });
  }

  render() {
    let { languages } = this.state;
    let { selectedLanguageId, selectDefault } = this.props;
    let list = [];
    let startValue = selectedLanguageId;

    if (selectDefault) {
      list.push(
        <option selected disabled hidden key={null} value={null}>
          {'Select One'}
        </option>
      );
    }
    for (let i = 0; i < languages.length; i++) {
      list.push(
        <option key={languages[i].language_id} value={languages[i].language_id}>
          {languages[i].language_name}
        </option>
      );
    }

    return (
      <Form.Control
        as="select"
        name={this.props.optionName || 'language_id'}
        value={startValue}
        onChange={(e) => {
          if (this.props.onChange) this.props.onChange(e);
        }}
      >
        {list}
      </Form.Control>
    );
  }
}

export default LanguagePicker;
