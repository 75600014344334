/*
  PaymentRequest.js - Payment Request class

  Author: Ian Jackson (2021)
  Company: Red Oxygen
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';

// CONFIGURATION
import config from '../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class PaymentRequest {
  getCardDetails = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/billing/card/get`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'GET',
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      console.log('responseJSON:', responseJSON);
      return responseJSON;
    } catch (err) {
      errorHandler(err.message);
    }
  };

  saveCardDetails = async (
    cardName,
    cardNumber,
    cardExpiry,
    cardCVV,
    cardType,
    errorHandler
  ) => {
    try {
      const url = `${config.baseServerURL}/billing/card/save`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const body = {
        cardName: cardName,
        cardNumber: cardNumber,
        cardExpiry: cardExpiry,
        cardCVV: cardCVV,
        cardType: cardType,
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
    }
  };
}
