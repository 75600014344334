/*
  SingleCustomerView.js - Admin Customer Single View Main Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

// OUR COMPONENTS
import CountryPicker from '../../../../components/CountryPicker';

// FORMATTED MESSAGES
import formattedMessages from '../FormattedMessages';

// STYLING
import './Admin.css';

// NETWORKING
import AdminRequest from './AdminRequest';
const adminRequest = new AdminRequest();

export default class AdminCustomerSingleView extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - customerId: A Customer Id
        - backButtonClick: Function to handle Back Button click
    */

    this.state = {
      content: null,
      isEditing: false,
      edits: {},
    };
  }

  componentDidMount = async () => {
    let customer = await adminRequest.getSingleCustomer(this.props.customerId);
    console.log('Got customer:', customer);
    return this.setState({
      customer: customer,
    });
  };

  /*
    Handles Edit Mode on/off
  */
  toggleEdit = () => {
    return this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    console.log('Value is:', value);
    console.log('Name is:', name);

    let edits = this.state.edits;
    edits[name] = value;
    console.log('Edits is:', edits);
    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let updateData = this.state.edits;

      if (this.state.edits.formCountry) {
        updateData.country_code_id = updateData.formCountry;
        delete updateData.formCountry;
      }

      await adminRequest.updateCustomer(
        this.props.customer.customer_id,
        updateData,
        this.props.errorHandler
      );
      window.location.href = '/admin'; // Force reload - TODO: redo properly
    } catch (e) {
      // Handle error
    }
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="ProviderShow" className="lander provider">
        <p onClick={this.props.backButtonClick} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
        <h3>{formattedMessages.customerInformation}</h3>
      </div>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateBody = () => {
    let { customer } = this.state;
    let { isEditing } = this.state;
    let buttonText = isEditing ? 'Cancel' : 'Edit';

    // Not editing and have Provider data?  Display it
    if (customer && !this.state.isEditing) {
      return (
        <Form>
          {this.generateFormGroup(
            "Customer's Name:",
            customer.customer_name,
            'customer_name'
          )}
          {this.generateFormGroup(
            'Account Id:',
            customer.customer_account_no || '',
            'customer_account_no'
          )}
          {this.generateFormGroup(
            'Customer No (ABN):',
            customer.customer_abn || '',
            'customer_abn'
          )}
          {this.generateFormGroup(
            'Customer Reference No:',
            customer.customer_ref_no || '',
            'customer_ref_no'
          )}
          {this.generateFormGroup(
            'Default Originator:',
            customer.default_originator || '',
            'default_originator'
          )}
          {this.generateFormGroup(
            'Type - Class:',
            `${customer.customer_type} - ${customer.customer_class}`,
            'type_class'
          )}
          {this.generateFormGroup(
            'Status:',
            customer.customer_status
              ? customer.customer_status === 'A'
                ? 'Active'
                : 'Inactive'
              : '',
            'customer_status'
          )}
          {/* TODO - For below, get Sales Agent Name */}
          {this.generateFormGroup(
            'Sales Agent:',
            customer.sales_agent_id || '',
            'sales_agent_id'
          )}
          {/* TODO - For below, get Tariff Plan Name */}
          {this.generateFormGroup(
            'Tariff Plan:',
            customer.tariff_plan_id || '',
            'tariff_plan_id'
          )}
          {this.generateFormGroup(
            'Country:',
            customer.country_id || 'Australia',
            'country_id'
          )}
          {this.generateFormGroup(
            'Contract Form Id:',
            customer.contract_id || '',
            'contract_id'
          )}
          {/*{this.generateFormGroup('SMS In Credit:', (customer.customer_type === 'T') ? 'N/A' : customer.sms_in_credit, 'sms_in_credit')}*/}
          {/*{this.generateFormGroup('SMS In Excess:', customer.sms_in_excess || 0, 'sms_in_excess')}*/}
          {this.generateFormGroup(
            'HTTP Reply Template:',
            customer.reply_template || 'None',
            'reply_template'
          )}
          {this.generateFormGroup(
            'HTTP Reply URL:',
            customer.reply_url || '',
            'reply_url'
          )}
          {this.generateFormGroup(
            'Last Billed:',
            customer.last_billed || '',
            'last_billed'
          )}
          {/* TODO - For below, get from auth_codes table on customer_id */}
          {this.generateFormGroup(
            'Authorisation Code:',
            customer.auth_number || '',
            'auth_number'
          )}
          <Button onClick={this.toggleEdit}>{buttonText}</Button>
        </Form>
      );
    } else if (customer && this.state.isEditing) {
      return (
        <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
          <Form.Group as={Row} controlId="customer_name">
            <Form.Label column sm={2} className="info-label">
              * {"Customer's Name"}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                name="customer_name"
                type="text"
                defaultValue={customer.customer_name}
              />
            </Col>
          </Form.Group>
          {this.generateFormGroup(
            'Account Id:',
            customer.customer_account_no || '',
            'customer_account_no'
          )}
          {this.generateFormGroup(
            'Customer No (ABN):',
            customer.customer_abn || '',
            'customer_abn'
          )}
          <Form.Group as={Row} controlId="customer_ref_no">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.customerRef}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                name="customer_ref_no"
                type="text"
                defaultValue={customer.customer_ref_no}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="default_originator">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.defaulOriginator}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                defaultValue={customer.default_originator}
                name="default_originator"
              />
            </Col>
          </Form.Group>
          {/* TODO - Do Type - Class Select */}
          <Form.Group as={Row} controlId="type_class">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.typeClass}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                defaultValue={`${customer.customer_type} - ${customer.customer_class}`}
                name="type_class"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="customer_status">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.status}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="customer_status"
                defaultValue={customer.customer_status}
              >
                <option value="A">
                  {formattedMessages.active.props.defaultMessage.toString()}
                </option>
                <option value="C">
                  {formattedMessages.cancelled.props.defaultMessage.toString()}
                </option>
                <option value="P">
                  {formattedMessages.pendingCancelled.props.defaultMessage.toString()}
                </option>
                <option value="S">
                  {formattedMessages.suspended.props.defaultMessage.toString()}
                </option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="sales_agent_id">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.salesAgent}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                defaultValue={customer.sales_agent_id}
                name="sales_agent_id"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="tariff_plan_id">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.tariffPlan}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                defaultValue={customer.tariff_plan_id}
                name="tariff_plan_id"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formCountry">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.country}:
            </Form.Label>
            <Col sm={8}>
              <CountryPicker
                className="form-control"
                defaultValue={customer.country_code_id}
              ></CountryPicker>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="contract_id">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.contractId}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                defaultValue={customer.contract_id}
                name="contract_id"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formLang">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.language}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control as="select" name="formLang">
                <option value="English">English</option>
                <option value="Polish">Polish</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="active_creation">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.activeCreation}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={customer.active_creation === 'Y'}
                name="active_creation"
              ></Form.Check>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="red_alert_domain_flag">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.redAlertEnabled}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={customer.red_alert_domain_flag === 'Y'}
                name="red_alert_domain_flag"
              ></Form.Check>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="sales_tax_flag">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.gSTApplicable}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={customer.sales_tax_flag === 'Y'}
                name="sales_tax_flag"
              ></Form.Check>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="oms_flag">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.oMSFlag}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={customer.oms_flag === 'Y'}
                name="oms_flag"
              ></Form.Check>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="reciept_flag">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.receiptFlag}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={customer.reciept_flag === 'Y'}
                name="reciept_flag"
              ></Form.Check>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="employee_trust_flag">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.employeeTrustFlag}:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={customer.employee_trust_flag === 'Y'}
                name="employee_trust_flag"
              ></Form.Check>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="message_priority">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.messagePriority}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="message_priority"
                defaultValue={customer.message_priority}
              >
                <option value="0">
                  {formattedMessages.low.props.defaultMessage.toString()}
                </option>
                <option value="1">
                  {formattedMessages.med.props.defaultMessage.toString()}
                </option>
                <option value="2">
                  {formattedMessages.high.props.defaultMessage.toString()}
                </option>
              </Form.Control>
            </Col>
          </Form.Group>
          {/*<Form.Group as={Row} controlId='sms_in_credit'>*/}
          {/*  <Form.Label column sm={2} className='info-label'>*/}
          {/*    {formattedMessages.smsInCred}:*/}
          {/*  </Form.Label>*/}
          {/*  <Col sm={8}>*/}
          {/*    <Form.Control type='text' defaultValue={customer.sms_in_credit} name='sms_in_credit'/>*/}
          {/*  </Col>*/}
          {/*</Form.Group>*/}
          {/*<Form.Group as={Row} controlId='sms_in_excess'>*/}
          {/*  <Form.Label column sm={2} className='info-label'>*/}
          {/*    {formattedMessages.smsInExcess}:*/}
          {/*  </Form.Label>*/}
          {/*  <Col sm={8}>*/}
          {/*    <Form.Control type='text' defaultValue={customer.sms_in_excess} name='sms_in_excess'/>*/}
          {/*  </Col>*/}
          {/*</Form.Group>*/}
          <Form.Group as={Row} controlId="reply_template">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.httpReplyTempl}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="select"
                name="reply_template"
                defaultValue={customer.reply_template || ''}
              >
                <option value="">
                  {formattedMessages.none.props.defaultMessage.toString()}
                </option>
                <option value="RO4HttpReply.txt">RO4HttpReply.txt</option>
                <option value="RO4HttpReplyFormData.txt">
                  RO4HttpReplyFormData.txt
                </option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="reply_url">
            <Form.Label column sm={2} className="info-label">
              {formattedMessages.httpReplyUrl}:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                defaultValue={customer.reply_url}
                name="reply_url"
              />
            </Col>
          </Form.Group>
          <Button
            className="createProviderButton"
            variant="primary"
            type="submit"
          >
            {formattedMessages.save}
          </Button>
          <Button className="createProviderButton" onClick={this.toggleEdit}>
            {buttonText}
          </Button>
        </Form>
      );
    }

    return <span>{formattedMessages.loading}</span>;
  };

  render() {
    return (
      <div className="Home provider">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
