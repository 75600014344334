/*
  CustomerRequest.js - CustomerRequest Networking File
  
  Contains networking functionality for Customer Components

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../../RequestRabbit';

// CONFIGURATION
import config from '../../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class AdminRequest {
  getSingleCustomer = async (customerId, errorHandler) => {
    try {
      let url = `${config.baseServerURL}/admin/customer/${customerId}`;
      const options = {
        method: 'GET',
      };
      let response = await requestRabbit.request(url, options, errorHandler);
      return await response.json();
    } catch (e) {
      errorHandler(e.message);
      // throw e
    }
  };
}
