/*
  SingleView.js - Admin Carrier Ported Numbers Single View

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
// import {Form, Table, Button, Col, Row} from 'react-bootstrap';
import { Table, Button } from 'react-bootstrap';

// COMPONENTS
import ListItem from './ListItem';

// NETWORK
import CarrierRequest from '../CarrierRequest';

// INSTANTIATION
const carrierRequest = new CarrierRequest();

export default class ServiceCarrierPortedNumberList extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - country: A single Country object
        - carrier: A single Carrier object
        - addNewCarrierPortedNumber: Function to handle Add New Button click
        - showSingleCarrierPortedNumber: Function to handle Ported Number list click
        - backButtonClick: Function to handle Back Button click
        - errorHandler: A function to pass errors to
    */

    this.state = {
      portedNumbers: [],
      edits: {},
    };
  }

  async componentDidMount() {
    let { country, carrier, errorHandler } = this.props;

    let portedNumbers = await carrierRequest.getCarrierPortedNumbers(
      country.country_code_id,
      carrier.carrier_id,
      errorHandler
    );
    console.log('Got portedNumbers:', portedNumbers);
    return this.setState({
      portedNumbers: portedNumbers,
    });
  }

  generateTable() {
    let htmlRows = [];
    let { portedNumbers } = this.state;

    if (portedNumbers.length === 0) {
      return <div>No Carrier Ported Numbers</div>;
    }

    for (let i = 0; i < portedNumbers.length; i++) {
      htmlRows.push(
        <ListItem
          key={portedNumbers[i].ported_numbers_id}
          id={portedNumbers[i].ported_numbers_id}
          portedNumberText={portedNumbers[i].ported_number}
          portedNumber={portedNumbers[i]}
          onClickPortedNumber={(e) =>
            this.props.showSingleCarrierPortedNumber(e)
          }
        ></ListItem>
      );
    }

    return (
      <Table>
        <thead>
          <tr>
            <td className="medium tableTop column-left">Prefix</td>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  }

  render() {
    return (
      <div key="CarrierPrefixList" className="carrierprefix table">
        <h4>List of Carrier Ported Numbers</h4>
        <Button
          className="btn add-new-template"
          onClick={this.props.addNewCarrierPortedNumber}
        >
          Add New
        </Button>
        {this.generateTable()}
      </div>
    );
  }
}
