/*
  CountryRequest.js - Service Config - Country Networking File
  
  Contains networking functionality for Service Config - Country Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../containers/RequestRabbit';

// CONFIGURATION
import config from '../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class CountryListRequest {
  /*
    Gets a list of Countries
  */
  getCountries = async (errorHandler) => {
    let url = `${config.baseServerURL}/country/list`;
    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    if (response.status > 399 && response.status < 600) return [];

    let responseJSON = await response.json();
    return responseJSON;
  };

  getCountry = async (country_id, errorHandler) => {
    let url = `${config.baseServerURL}/country/${country_id}`;
    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    if (response.status > 399 && response.status < 600) return [];

    let responseJSON = await response.json();
    return responseJSON;
  };
}
