/*
  ListContact.js - List Component for listing Users in a Cost Centre

  Author: Julius Klabe (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';

// COMPONENTS
import ListItem from './ListItem';
import Paginator from '../../../../components/Paginator';

// I18N
import formattedMessages from './FormattedMessages';

// CONFIG
import config from '../../../../Config';

// NETWORK
import CostCentreRequest from '../CostCentreRequest';
const costCentreRequest = new CostCentreRequest();

export default class ListView extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - centre: The selected Cost Centre
    */

    this.state = {
      pageNumber: 1,
      error: null,
      displayLetters: [],
      filterLetter: '',
      loaded: false,
      users: [],
      costCenterId: null,
    };
    this.pageLength = config.defaultPL;
  }

  async componentDidMount() {
    let costCenterId = this.props.centre.cost_centre_id;
    let users = [];
    // Get Users for Cost Centre
    if (costCenterId) {
      users = await costCentreRequest.getUsersForCostCentre(
        costCenterId,
        this.props.errorHandler
      );
    }
    let sortedList = this.sortList(users);
    console.log('have users for cc:', users);
    return this.setState({
      users: sortedList,
      loaded: true,
      costCenterId: costCenterId,
    });
  }

  /*
    Handles when a ListItem is clicked on
  */
  returnListItem = (listItemId) => {
    this.props.callBack(listItemId);
  };

  /*
    Handles the change event when clicking a pagination number
  */
  changeEvent = (i) => {
    return this.setState({
      pageNumber: i + 1,
    });
  };

  sortList(data) {
    let newList = [].slice.call(data).sort(function (a, b) {
      if (a.user_name.toLowerCase() < b.user_name.toLowerCase())
        return -1;
      if (a.user_name.toLowerCase() > b.user_name.toLowerCase()) return 1;
      return 0;
    });
    return newList;
  }
  /*
    Generates rows of User objects
  */
  handleList = () => {
    if (this.state.loaded !== true) return null;

    let list = this.state.users;
    let { pageNumber } = this.state;
    let fullList = [];
    let letterMap = {};
    let letters = [];
    let displayList = [];

    if (!list || typeof list === 'undefined' || !Array.isArray(list))
      return null;

    for (let i = 0; i < list.length; i++) {
      let element = list[i];

      if (
        this.state.displayLetters.length === 0 &&
        !letterMap[element.user_name.toUpperCase().charAt(0)]
      ) {
        letterMap[element.user_name.toUpperCase().charAt(0)] = true;
        if (this.state.costCenterId === element.cost_centre_id) {
          letters.push(`${element.user_name.toUpperCase().charAt(0)}`);
        }
      }
      if (
        this.state.filterLetter === '' ||
        this.state.filterLetter.toLowerCase() ===
          element.user_name.charAt(0).toLowerCase()
      ) {
        fullList.push(element);
      }
    }
    displayList = fullList.slice(
      (pageNumber - 1) * this.pageLength,
      pageNumber * this.pageLength - 1 + 1
    );

    if (letters.length > 0) {
      letters.sort();
      this.setState({
        displayLetters: letters,
      });
    }
    return {
      displayList: displayList,
      fullList: fullList
    };
  };

  /*
    Generates rows of User objects (in JSX)
  */
  generateList = () => {
    let { displayList } = this.handleList();
    let fullList = [];
    for (let i = 0; i < displayList.length; i++) {
      let element = displayList[i];
      fullList.push(
        <ListItem
          key={element.customer_user_id}
          onClick={() => this.props.callBack(element)}
          name={element.user_name}
          email={element.user_email}
          number={element.user_tn}
          status={element.user_status}
          msgmax={element.max_sms_per_day}
          centre={element.cost_center_name || 'Not Assigned'}
        />
      );
    }
    return fullList;
  };

  generatePaginator = () => {
    let { fullList } = this.handleList();
    return (
      <Paginator
        itemCount={fullList.length}
        changeEvent={this.changeEvent}
        changePageFunction={(ref) => (this.resetFunction = ref)}
      />
    );
  };

  /*
      Handles click from the 'letter filter' component.
    */
  handleLetterFilter = async (letter) => {
    await this.setState({
      filterLetter: letter,
      pageNumber: 1
    });
    this.resetFunction();
    // this.formatData();
  };

  /*
      Generates a 'letter filter' component that allows a User to filter other Users who have the same first letter
      as the one they select.
    */
  generateFirstLetterFilter = () => {
    // NOTE: Extra space in end </span> is required to space letters out on display
    let letterHtml = this.state.displayLetters.map((el) => {
      return (
        <span key={el}>
          <span
            key={el}
            className="click-object"
            onClick={() => this.handleLetterFilter(el)}
          >
            {el}
          </span>{' '}
        </span>
      );
    });
    return <div>{letterHtml}</div>;
  };

  render() {
    if (this.state.loaded === false) {
      return <>{formattedMessages.loading}</>;
    } else {
      return (
        <Fragment>
          {this.generateFirstLetterFilter()}
          <Table id="inbox default-cursor">
            <thead>
              <tr key="head">
                <th className="medium textLeft tableTop">
                  {formattedMessages.name}
                </th>
                <th className="medium textLeft tableTop">
                  {formattedMessages.email}
                </th>
                <th className="medium textLeft tableTop">
                  {formattedMessages.cell}
                </th>
                <th className="medium textLeft tableTop">
                  {formattedMessages.status}
                </th>
                <th className="medium textLeft tableTop">
                  {formattedMessages.maxDailyMessage}
                </th>
              </tr>
            </thead>
            <tbody>{this.generateList()}</tbody>
          </Table>
          {this.generatePaginator()}
        </Fragment>
      );
    }
  }
}
