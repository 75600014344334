/*
  AdminSearch.js - Find Users Main Component

  Author: Elle Dunbar (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

// OUR COMPONENTS

// I18N
import formattedMessages from './FormattedMessages';

// STYLING
import '../../Users/Users.css';

export default class AdminSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_name: '',
      user_email: '',
      user_phone: '',
      user_id: '',
      customer_id: '',
      customer_name: '',
      customer_domain: '',
      customer_auth: '',
      customer_ip_address: '',
      admin_name: '',
      admin_email: '',
      error_name: '',
      error_email: '',
      destination_number: '',

      displayResults: false,
      searchType: null,
    };

    if (this.props.state !== null) {
      this.state = this.props.state;
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    return this.setState({
      [name]: value,
    });
  };

  submitHandler(type) {
    this.props.stateHandler(this.state);
    this.submitSearch(type);
  }

  submitSearch(type) {
    if (type === 'ADMIN SEARCH - CUSTOMER') {
      console.log('search for a customer...');
      this.props.searchHandler(
        type,
        this.state.customer_id,
        this.state.customer_name,
        this.state.customer_domain,
        this.state.customer_auth,
        this.state.customer_ip_address
      );
    } else if (type === 'ADMIN SEARCH - USER') {
      console.log('search for a user...');
      this.props.searchHandler(
        type,
        this.state.user_id,
        this.state.user_name,
        this.state.user_email,
        this.state.user_phone
      );
    } else if (type === 'ADMIN SEARCH - ADMIN') {
      console.log('search for a admin...');
      this.props.searchHandler(
        type,
        this.state.admin_name,
        this.state.admin_email
      );
    } else if (type === 'ADMIN SEARCH - EMAIL ERROR') {
      console.log('search for a error email...');
      this.props.searchHandler(
        type,
        this.state.error_name,
        this.state.error_email
      );
    } else if (type === 'ADMIN SEARCH - MESSAGES VIA DESTINATION') {
      console.log('search for a destination message... ');
      this.props.searchHandler(type, this.state.destination_number);
    }
  }

  render() {
    return (
      <div className="Home find-users">
        <h3>{formattedMessages.searchForACustomer}</h3>
        <table
          border="0"
          cellPadding="5px"
          style={{ marginLeft: '50px' }}
          width="80%"
          className="no-table-row-background"
        >
          <tbody>
            <tr valign="top">
              <td width="30%">{formattedMessages.accountID}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="customer_id"
                  value={this.state.customer_id}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr valign="top">
              <td width="30%">{formattedMessages.name}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="customer_name"
                  value={this.state.customer_name}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr valign="top">
              <td width="30%">{formattedMessages.domain}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="customer_domain"
                  value={this.state.customer_domain}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr valign="top">
              <td width="30%">{formattedMessages.authCode}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="customer_auth"
                  value={this.state.customer_auth}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr valign="top">
              <td width="30%">{formattedMessages.ipAddress}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="customer_ip_address"
                  value={this.state.customer_ip_address}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <Button
                  className="btn btn-primary maxHeight"
                  onClick={() => this.submitHandler('ADMIN SEARCH - CUSTOMER')}
                >
                  {formattedMessages.find}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />

        <h3>{formattedMessages.searchForAUser}</h3>
        <table
          border="0"
          cellPadding="5px"
          style={{ marginLeft: '50px' }}
          width="80%"
          className="no-table-row-background"
        >
          <tbody>
            <tr valign="top">
              <td width="30%">{formattedMessages.userID}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="user_id"
                  value={this.state.user_id}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr valign="top">
              <td width="30%">{formattedMessages.name}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="user_name"
                  value={this.state.user_name}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr valign="top">
              <td width="30%">{formattedMessages.email}</td>
              <td>
                <input
                  className="find-users input-email"
                  type="text"
                  name="user_email"
                  value={this.state.user_email}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr valign="top">
              <td width="30%">{formattedMessages.cell}</td>
              <td>
                <input
                  className="find-users input-phone"
                  type="text"
                  name="user_phone"
                  value={this.state.user_phone}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <Button
                  className="btn btn-primary maxHeight"
                  onClick={() => this.submitHandler('ADMIN SEARCH - USER')}
                >
                  {formattedMessages.find}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />

        <h3>{formattedMessages.searchForAnAdmin}</h3>
        <table
          border="0"
          cellPadding="5px"
          style={{ marginLeft: '50px' }}
          width="80%"
          className="no-table-row-background"
        >
          <tbody>
            <tr valign="top">
              <td width="30%">{formattedMessages.name}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="admin_name"
                  value={this.state.admin_name}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr valign="top">
              <td width="30%">{formattedMessages.email}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="admin_email"
                  value={this.state.admin_email}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <Button
                  className="btn btn-primary maxHeight"
                  onClick={() => this.submitHandler('ADMIN SEARCH - ADMIN')}
                >
                  {formattedMessages.find}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />

        <h3>{formattedMessages.findAnErrorByEmail}</h3>
        <table
          border="0"
          cellPadding="5px"
          style={{ marginLeft: '50px' }}
          width="80%"
          className="no-table-row-background"
        >
          <tbody>
            <tr valign="top">
              <td width="30%">{formattedMessages.email}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="error_email"
                  value={this.state.error_email}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <Button
                  className="btn btn-primary maxHeight"
                  onClick={() =>
                    this.submitHandler('ADMIN SEARCH - EMAIL ERROR')
                  }
                >
                  {formattedMessages.find}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />

        <h3>{formattedMessages.findMessagesViaDestNum}</h3>
        <span style={{ marginLeft: '50px' }}>
          <i>{formattedMessages.needToIncludeCountryCodeAndCell}</i>
        </span>
        <table
          border="0"
          cellPadding="5px"
          style={{ marginLeft: '50px' }}
          width="80%"
          className="no-table-row-background"
        >
          <tbody>
            <tr valign="top">
              <td width="30%">{formattedMessages.destNumb}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="destination_number"
                  value={this.state.destination_number}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <Button
                  className="btn btn-primary maxHeight"
                  onClick={() =>
                    this.submitHandler(
                      'ADMIN SEARCH - MESSAGES VIA DESTINATION'
                    )
                  }
                >
                  {formattedMessages.find}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
