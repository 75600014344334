/*
  UsageLog.js
  
  Component for generating and displaying the Usage Log content for
  Customer Contact Admin with Usage Log Access enabled.

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Form, Button, Table } from 'react-bootstrap';
import moment from 'moment';

// COMPONENTS
import ReportTabs from '../index';
import DateRangeSelector from '../../../components/DateRangeSelector';

// NETWORKING
import CostCentreReportRequest from './CostCentreReportRequests';

// I18N
import formattedMessages from './FormattedMessages';

// STYLING
import '../Reports.css';

// INSTANTIATE
const costCentreReportRequest = new CostCentreReportRequest();

export default class Centres extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props
        - customer: Customer Object
        - backClick: Function to call when clicking back
    */

    this.state = {
      data: [],
      searchDate: moment(new Date()), //today
      customer_id: null,
      user_email: null,
      fromDate: '',
      toDate: '',
      isSearching: false,
      search: false,
      isSending: false,
    };
  }

  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    return this.setState({
      [name]: value,
    });
  };

  /*
    Fetches SMS data relating to the search
  */
  searchUsageLog = async () => {
    try {
      this.setState({ isSearching: true, data: [], search: false });

      let { fromDate, toDate, user_email } = this.state;
      let { errorHandler } = this.props;
      let data = await costCentreReportRequest.fetchUsageLogData(
        fromDate,
        toDate,
        user_email,
        errorHandler
      );
      return this.setState({
        data: data,
        isSearching: false,
        search: true,
      });
    } catch (e) {
      return this.setState({
        error: e.message,
        isSearching: false,
      });
    }
  };

  /*
    Creates a request to generate a PDF and email it to the Admin
  */
  requestPdfReport = async () => {
    try {
      let { fromDate, toDate, user_email, pdfPassword } = this.state;
      let { errorHandler } = this.props;
      this.setState({ isSending: true });

      await costCentreReportRequest.generateUsageLog(
        fromDate,
        toDate,
        pdfPassword,
        user_email,
        errorHandler
      );
      return this.setState({
        pdfPassword: '',
        isSending: false,
      });
    } catch (e) {
      return this.setState({
        error: e.message,
      });
    }
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div className="lander">
        <h3>{formattedMessages.usageLogTitle}</h3>
        <p>
          {formattedMessages.accountText}{' '}
          {this.props.customer ? this.props.customer.customer_name : ''}
        </p>
      </div>
    );
  };

  /*
    Generates a back button
  */
  generateBackButton = () => {
    return (
      <p onClick={() => this.props.backClick()} className="backLink">
        <i className="material-icons">keyboard_arrow_left</i>
        <span>{formattedMessages.back}</span>
      </p>
    );
  };
  /*
    Generates the Search Tools for querying the data
  */
  generateSearchTools = () => {
    return (
      <Fragment>
        <table
          border="0"
          cellPadding="5px"
          style={{ marginLeft: '30px' }}
          width="80%"
          className="no-table-row-background"
        >
          <tbody>
            <tr valign="top">
              <td width="30%">{formattedMessages.searchByUserEmail}</td>
              <td>
                <input
                  className="find-users input-name"
                  type="text"
                  name="user_email"
                  value={this.state.user_email}
                  onChange={this.handleInputChange}
                />
              </td>
            </tr>
            <tr valign="top">
              <td width="30%">{formattedMessages.dateRange}</td>
              <td colSpan="4">
                <DateRangeSelector
                  rangeOnly={true}
                  rangeFunction={(fromDate, toDate) =>
                    this.setState({ fromDate: fromDate, toDate: toDate })
                  }
                ></DateRangeSelector>
              </td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <Button
                  className="maxHeight"
                  onClick={() => this.searchUsageLog()}
                >
                  {formattedMessages.showReport}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </Fragment>
    );
  };
  /*
    Generates the PDF Password field and button to submit to generate a PDF 
  */
  generatePdfPassword = () => {
    if (this.state.isSending) {
      return <p className="pdfPassword">Sending...</p>;
    }
    return (
      <div className="pdfPassword">
        <Form onChange={this.handleInputChange}>
          <table width="100%" className="no-table-row-background">
            <tbody>
              <tr valign="middle">
                <td style={{ width: '220px' }}>
                  {formattedMessages.enterPassword}
                </td>
                <td>
                  <Form.Control type="password" name="pdfPassword" />
                </td>
                <td>
                  <Button
                    className="searchRange"
                    onClick={this.requestPdfReport}
                    disabled={this.state.data.length === 0}
                  >
                    {formattedMessages.emailPDFFile}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      </div>
    );
  };
  /*
    Generates the Table which displays results from Search queries
  */
  generateTable = () => {
    if (this.state.isSearching) {
      return <span>{formattedMessages.searching}</span>;
    } else if (this.state.data.length === 0 && this.state.search) {
      return <span>{formattedMessages.noResult}</span>;
    } else if (this.state.search) {
      return (
        <div>
          {this.generatePdfPassword()}
          <br />
          <br />
          <Table>
            <thead>
              <tr>
                <td className="medium tableTop left column-right">
                  {formattedMessages.key}
                </td>
                <td className="medium tableTop column-right">
                  {formattedMessages.costCentre}
                </td>
                <td className="medium tableTop column-right">
                  {formattedMessages.userName}
                </td>
                <td className="medium tableTop column-right">
                  {formattedMessages.date}
                </td>
                <td className="medium tableTop column-right">
                  {formattedMessages.time}
                </td>
                <td className="medium tableTop column-right">
                  {formattedMessages.phone}
                </td>
                <td className="medium tableTop column-right">
                  {formattedMessages.type}
                </td>
                <td className="medium tableTop column-right">
                  {formattedMessages.quantity}
                </td>
                <td className="medium tableTop">{formattedMessages.text}</td>
              </tr>
            </thead>
            <tbody>{this.generateTableRows()}</tbody>
          </Table>
        </div>
      );
    }
  };
  /*
    Generates the rows of the table based on the received results
  */
  generateTableRows = () => {
    let { data } = this.state;
    let rows = [];
    // For each sms
    for (let i = 0; i < data.length; i++) {
      let d = data[i];
      let smsText;
      try {
        smsText = decodeURIComponent(d.sms_text);
      } catch (err) {
        smsText = 'ERROR: MALFORMED TEXT';
      }
      rows.push(
        <tr key={'list' + d.sms_id} id={d.sms_id} className="">
          <td className="medium column-right">{i}</td>
          <td className="medium column-right">{d.cost_centre_name}</td>
          <td className="medium column-right">{d.user_name}</td>
          <td className="medium column-right">
            {moment(d.sms_sent).format('DD MMM YYYY')}
          </td>
          <td className="medium column-right">
            {moment(d.sms_sent).format('h:mm A')}
          </td>
          <td className="medium column-right">(+){d.dest_tn}</td>
          <td className="medium column-right">{d.sms_type}</td>
          <td className="medium column-right">{d.no_of_sms_sent}</td>
          <td className="medium">{smsText}</td>
        </tr>
      );
    }
    return rows;
  };

  render() {
    let { accessLevel, accessType } = this.props;

    return (
      <ReportTabs
        tab="costCenter"
        accessLevel={accessLevel}
        accessType={accessType}
      >
        <div className="Home">
          {this.generateBackButton()}
          <div className="lander">{this.generateLander()}</div>
          {this.generateSearchTools()}
          {this.generateTable()}
        </div>
      </ReportTabs>
    );
  }
}
