/*
  CarrierRequest.js - Service Config - Carrier Networking File
  
  Contains networking functionality for Service Config - Carrier Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../../../RequestRabbit';

// CONFIGURATION
import config from '../../../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class CarrierRequest {
  /*
    Creates a new Carrier for the given Country Code Id.
  */
  createNewCarrier = async (countryCodeId, newCarrier, errorHandler) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(newCarrier),
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Updates a Carrier
  */
  updateCarrier = async (countryCodeId, carrierId, updates, errorHandler) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(updates),
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  getCarrierPrefixes = async (countryCodeId, carrierId, errorHandler) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/prefix`;
    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  createNewCarrierPrefix = async (
    countryCodeId,
    carrierId,
    newPrefix,
    errorHandler
  ) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/prefix`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(newPrefix),
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  updateCarrierPrefix = async (
    countryCodeId,
    carrierId,
    carrierPrefixId,
    updates,
    errorHandler
  ) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/prefix/${carrierPrefixId}`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(updates),
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  deleteCarrierPrefixById = async (
    countryCodeId,
    carrierId,
    carrierPrefixId,
    errorHandler
  ) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/prefix/${carrierPrefixId}`;
    const options = {
      method: 'DELETE',
    };
    await requestRabbit.request(url, options, errorHandler);
  };

  getCarrierDevices = async (countryCodeId, carrierId, errorHandler) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/device`;
    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Returns a list of ALL Devices minus ones that are already in use.
  */
  getSelectableDevices = async (countryCodeId, carrierId, errorHandler) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/device/select`;
    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  createNewCarrierDevice = async (
    countryCodeId,
    carrierId,
    device,
    applyProvider,
    errorHandler
  ) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/device`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    // Set apply_provider
    device.apply_provider = applyProvider;
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(device),
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  updateCarrierDevice = async (
    countryCodeId,
    carrierId,
    carrierDeviceId,
    device,
    applyProvider,
    errorHandler
  ) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/device/${carrierDeviceId}`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    // Set apply_provider
    if (applyProvider) device.apply_provider = applyProvider;
    else device.apply_provider = 'this';
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(device),
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  deleteCarrierDeviceById = async (
    countryCodeId,
    carrierId,
    carrierDeviceId,
    providerId,
    errorHandler
  ) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/device/${carrierDeviceId}`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify({ providerId }),
    };

    await requestRabbit.request(url, options, errorHandler);
  };

  getCarrierPortedNumbers = async (countryCodeId, carrierId, errorHandler) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/ported`;
    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  createNewCarrierPortedNumber = async (
    countryCodeId,
    carrierId,
    newPortedNumber,
    errorHandler
  ) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/ported`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(newPortedNumber),
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  /*
    Updates a Carrier's Ported Number.
  */
  updateCarrierPortedNumber = async (
    countryCodeId,
    carrierId,
    portedNumberId,
    updates,
    errorHandler
  ) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/ported/${portedNumberId}`;
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(updates),
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };

  deleteCarrierPortedNumberById = async (
    countryCodeId,
    carrierId,
    portedNumberId,
    errorHandler
  ) => {
    let url = `${config.baseServerURL}/admin/country/${countryCodeId}/carrier/${carrierId}/ported/${portedNumberId}`;
    const options = {
      method: 'DELETE',
    };
    await requestRabbit.request(url, options, errorHandler);
  };

  /*
    Fetches Carrier Device data for displaying in the Device Single.js component
    instead of for the Carrier Single.js component
  */
  getDeviceCarrierDevices = async (deviceId, errorHandler) => {
    let url = `${config.baseServerURL}/admin/device/${deviceId}/carrier/device`;
    const options = {
      method: 'GET',
    };
    let response = await requestRabbit.request(url, options, errorHandler);
    let responseJSON = await response.json();

    return responseJSON;
  };
}
