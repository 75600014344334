/*
  AdminCustomerCreateView.js - Admin Create Customer Main Content

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM
import React, { Component } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
// import { Redirect } from 'react-router-dom';
import { validatePhoneNumber } from '../../../utils/validate';

// COMPONENTS
import CountryPicker from '../../../components/CountryPicker';

// I18N
import formattedMessages from './FormattedMessages';

// STYLES
import '../Admin.css';

// NETWORKING
import CustomerRequest from './CustomerRequest';
const customerRequest = new CustomerRequest();

export default class CustomerAdd extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - provider: A single Provider object
        - backButtonClick: Function to handle Back Button click
        - errorHandler: A function to handle Errors
    */

    this.state = {
      edits: {},
      isSaving: false,
      tariff_plans: [],
      class_types: [],
      error: null,
      default_country_code_id: null,
      goBack: false,
    };
  }

  async componentDidMount() {
    let results = await customerRequest.getCustomerExtras(
      this.props.errorHandler
    );
    return this.setState({
      tariff_plans: results.tariff_plans,
      class_types: results.class_types,
    });
  }

  /*
    Captures changes in the form
  */
  handleFormChange = (event) => {
    let target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    let { edits } = this.state;

    // Convert val if checkbox
    if (target.type === 'checkbox') {
      value = value === true ? 'Y' : 'N';
    }

    if (name === 'code_type') {
      edits.customer_type = value.charAt(0);
      edits.customer_class = value.charAt(1);
    } else if (name === 'formCountry') {
      edits.country_code_id = value;
    } else {
      edits[name] = value;
    }

    return this.setState({
      edits: edits,
    });
  };

  /*
  Basic email validation on client side
  Taken from: https://stackoverflow.com/a/46181/8191495
*/
  checkEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  /*
    Handles form submission
  */
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState(
      {
        isSaving: true,
      },
      async () => {
        let customerData = this.state.edits;
        let { tariff_plans, class_types } = this.state;

        console.log('Handling submit');

        // Ensure required fields exist
        if (!customerData.customer_name)
          return this.setState({
            error: 'Missing Business Name',
            isSaving: false,
          });
        if (!customerData.contact_tn)
          return this.setState({
            error: 'Missing Contact Phone',
            isSaving: false,
          });
        if (
          !validatePhoneNumber(
            customerData.contact_tn ? customerData.contact_tn : ''
          )
        )
          return this.setState({
            error:
              'Incorrect contact phone format, should only contain numbers and "+", eg: +0123456789/0123456789',
            isSaving: false,
          });
        if (!customerData.country_code_id)
          return this.setState({
            error: 'Missing Contact Country',
            isSaving: false,
          });
        if (!customerData.contact_name)
          return this.setState({
            error: 'Missing Administrator Name',
            isSaving: false,
          });

        if (!customerData.contact_email)
          return this.setState({
            error: 'Missing Administrator Email',
            isSaving: false,
          });
        if (!this.checkEmail(customerData.contact_email))
          return this.setState({
            error: 'The email provided is not in a valid format.',
            isSaving: false,
          });

        // Set defaults if not set already
        if (!customerData.tariff_plan_id && tariff_plans.length > 0)
          customerData.tariff_plan_id = tariff_plans[0].tariff_plan_id;
        if (!customerData.customer_type && class_types.length > 0)
          customerData.customer_type = class_types[0].code_type;
        if (!customerData.customer_class && class_types.length > 0)
          customerData.customer_class = class_types[0].code_class;

        let newObject = await customerRequest.createNewCustomer(
          customerData,
          this.props.errorHandler
        );

        if (typeof newObject.error === 'undefined') {
          this.props.goBack('refresh');
        } else {
          this.toggleSaving();
        }
      }
    );
  };

  toggleSaving() {
    let toggle = !this.state.isSaving;
    this.setState({
      isSaving: toggle,
    });
  }

  goBack() {
    this.setState({
      goBack: true,
    });
  }

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="CustomerAdd" className="lander flex">
        <p onClick={() => this.props.goBack('list')} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
      </div>
    );
  };

  generateTariffPlanSelect = () => {
    let { tariff_plans } = this.state;
    let tariffPlanLists = [];

    // If no tariff plans, return nothing
    if (!tariff_plans || typeof tariff_plans === 'undefined') return null;

    // For each Tariff Plan
    for (let i = 0; i < tariff_plans.length; i++) {
      tariffPlanLists.push(
        <option
          key={tariff_plans[i].tariff_plan_id}
          value={tariff_plans[i].tariff_plan_id}
        >
          {tariff_plans[i].select_name}
        </option>
      );
    }
    return (
      <Form.Control
        as="select"
        name="tariff_plan_id"
        defaultValue={
          tariff_plans.length > 0 ? tariff_plans[0].tariff_plan_id : null
        }
      >
        {tariffPlanLists}
      </Form.Control>
    );
  };

  generateClassTypeSelect = () => {
    let { class_types } = this.state;
    let classTypeLists = [];

    if (typeof class_types === 'undefined') return null;
    for (let i = 0; i < class_types.length; i++) {
      classTypeLists.push(
        <option
          key={class_types[i].class_type_status_id}
          value={class_types[i].code_type + class_types[i].code_class}
        >
          {class_types[i].select_name}
        </option>
      );
    }

    return (
      <Form.Control
        as="select"
        name="code_type"
        defaultValue={class_types.length > 0 ? class_types[0].code_type : null}
      >
        {classTypeLists}
      </Form.Control>
    );
  };

  generateCustomerFormBody = () => {
    return (
      <div>
        <h3>{formattedMessages.businessInformation}</h3>
        <Form.Group as={Row} controlId="customer_name">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.businessName}:
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="customer_name" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="tariff_plan_id">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.tariffPlan}:
          </Form.Label>
          <Col sm={8}>{this.generateTariffPlanSelect()}</Col>
        </Form.Group>
        <Form.Group as={Row} controlId="customer_abn">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.customerABN}:
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="customer_abn" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="customer_ref_no">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.customerReferenceNo}:
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="customer_ref_no" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="type_class">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.typeClass}:
          </Form.Label>
          <Col sm={8}>{this.generateClassTypeSelect()}</Col>
        </Form.Group>
      </div>
    );
  };

  generateContactFormBody = () => {
    let { edits } = this.state;
    return (
      <div>
        <h3>{formattedMessages.contactInformation}</h3>
        <Form.Group as={Row} controlId="contact_tn">
          <Form.Label column sm={2} className="info-label">
            * {formattedMessages.phone}:
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="contact_tn" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="contact_fax">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.fax}:
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="contact_fax" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="contact_address1">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.streetAddress}:
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="contact_address1" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="contact_address2">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.continued}
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="contact_address2" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="contact_city">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.city}:
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="contact_city" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="contact_state">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.state}:
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="contact_state" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="contact_postcode">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.postcode}:
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="contact_postcode" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="country_code_id">
          <Form.Label column sm={2} className="info-label">
            * {formattedMessages.country}:
          </Form.Label>
          <Col sm={8}>
            <CountryPicker
              className="form-control"
              onChange={this.handleFormChange}
              defaultValue={edits.country_code_id}
              selectDefault={true}
            />
          </Col>
        </Form.Group>
      </div>
    );
  };

  generateAuthorisedContactFormBody = () => {
    return (
      <div>
        <h3>{formattedMessages.authorizedAdmin}</h3>
        <Form.Group as={Row} controlId="contact_name">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.name}
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="contact_name" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="contact_mobile">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.mobilePhoneNumber}
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="contact_mobile" type="text" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="contact_email">
          <Form.Label column sm={2} className="info-label">
            {formattedMessages.emailAddress}
          </Form.Label>
          <Col sm={8}>
            <Form.Control name="contact_email" type="text" />
          </Col>
        </Form.Group>
      </div>
    );
  };

  /*
    Generates an error message
  */
  generateError = () => {
    if (!this.state.error) return null;
    return <div className="text-danger">{this.state.error}</div>;
  };

  generateFormButton = () => {
    let { isSaving } = this.state;

    if (isSaving) {
      return <p>Saving...</p>;
    }

    return (
      <Button className="createProviderButton" variant="primary" type="submit">
        Create Customer
      </Button>
    );
  };

  /*
    Generates Main Body content
  */
  generateBody = () => {
    return (
      <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
        {this.generateCustomerFormBody()}
        {this.generateContactFormBody()}
        {this.generateAuthorisedContactFormBody()}
        <span className="hintText">{formattedMessages.requiredField}</span>
        {this.generateError()}
        <br />
        {this.generateFormButton()}
      </Form>
    );
  };

  render() {
    return (
      <div className="Home provider">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
