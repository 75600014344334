/*
  Admin.js - Admin Provider Main Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

// OUR COMPONENTS
import CountryPicker from '../../../components/CountryPicker';
import LanguagePicker from '../../../components/LanguagePicker';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

// STYLING
import '../Admin.css';

// NETWORKING
import AdminRequest from '../AdminRequest';
import ProviderRequest from './ProviderRequest';
const adminRequest = new AdminRequest();
const providerRequest = new ProviderRequest();

export default class AdminProviderSingleView extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - provider: A single Provider object
        - backButtonClick: Function to handle Back Button click
        - errorHandler: A function to pass errors to for displaying in the UI
    */

    this.state = {
      content: null,
      isEditing: false,
      edits: {},
      provider: null,
      tariff_plans: [],
      statuses: [],
      xero_accounts: [],
    };
  }

  async componentDidMount() {
    let { provider, errorHandler } = this.props;

    // Use this Provider instead of the props one as this has more data
    let singleProvider = await providerRequest.getSingleProvider(
      provider.provider_id,
      errorHandler
    );

    // Get additional data
    let tariffPlans = await providerRequest.getTariffPlans(
      provider.provider_id,
      errorHandler
    );
    let extras = await adminRequest.getProviderExtra(errorHandler);

    // Update the acting_provider_id cookie with the selected Provider
    this.props.updateActingProviderDetails(provider.provider_id);

    return this.setState({
      provider: singleProvider,
      tariff_plans: tariffPlans,
      statuses: extras.provider_statuses,
      xero_accounts: extras.xero_accounts,
    });
  }

  /*
    Handles Edit Mode on/off
  */
  toggleEdit = () => {
    return this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;

    if (name === 'formCountry') {
      edits.country_code_id = value;
    } else {
      edits[name] = value;
    }
    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let { edits } = this.state;

      await adminRequest.updateProvider(
        this.props.provider.provider_id,
        edits,
        this.props.errorHandler
      );
      window.location.href = '/admin/provider'; // Force reload - TODO: redo properly
    } catch (e) {
      // Handle error
    }
  };

  /*
    Generates the Tariff Plan Select control.
  */
  generateTariffPlanSelect = () => {
    let propProvider = this.props.provider;
    let { edits, provider, tariff_plans } = this.state;
    let tariffPlanLists = [];

    if (!provider) provider = propProvider;

    // If no tariff plans, return nothing
    if (!tariff_plans || typeof tariff_plans === 'undefined') return null;

    // For each Tariff Plan
    for (let i = 0; i < tariff_plans.length; i++) {
      tariffPlanLists.push(
        <option
          key={tariff_plans[i].tariff_plan_id}
          value={tariff_plans[i].tariff_plan_id}
        >
          {tariff_plans[i].tariff_plan_name}
        </option>
      );
    }
    return (
      <Form.Control
        as="select"
        name="tariff_plan_id"
        value={edits.tariff_plan_id || provider.tariff_plan_id}
      >
        {tariffPlanLists}
      </Form.Control>
    );
  };

  /*
    Generates the Select control for Provider Statuses
  */
  generateStatusSelect = () => {
    let propProvider = this.props.provider;
    let { edits, statuses, provider } = this.state;
    let statusOptions = null;
    let statusList = [];

    if (!provider) provider = propProvider;

    // Set to data fetched from API
    if (statuses && statuses.length > 0) statusOptions = statuses;
    // If no data from API, set default list
    if (!statusOptions || typeof statusOptions === 'undefined') {
      statusOptions = [
        { meaning: 'Trial' /*formattedMessages.trial*/, code: 'T' },
        { meaning: 'Active' /*formattedMessages.active*/, code: 'A' },
        { meaning: 'Cancelled' /*formattedMessages.cancelled*/, code: 'C' },
        { meaning: 'Suspended' /*formattedMessages.suspended*/, code: 'S' },
      ];
    }

    for (let i = 0; i < statusOptions.length; i++) {
      statusList.push(
        <option key={i} value={statusOptions[i].code}>
          {statusOptions[i].meaning}
        </option>
      );
    }
    return (
      <Form.Control
        as="select"
        name="provider_status"
        value={edits.provider_status || provider.provider_status}
      >
        {statusList}
      </Form.Control>
    );
  };
  /*
    Generates the Select control for Provider Xero Accounts
  */
  generateXeroSelect = () => {
    let propProvider = this.props.provider;
    let { edits, provider, xero_accounts } = this.state;
    let xeroAccountsList = [];

    if (!provider) provider = propProvider;

    for (let i = 0; i < xero_accounts.length; i++) {
      xeroAccountsList.push(
        <option
          key={xero_accounts[i].xero_accounts_id}
          value={xero_accounts[i].xero_accounts_id}
        >
          {xero_accounts[i].account_name}
        </option>
      );
    }

    return (
      <Form.Control
        as="select"
        name="xero_accounts_id"
        value={edits.xero_accounts_id || provider.xero_accounts_id}
      >
        {xeroAccountsList}
      </Form.Control>
    );
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="ProviderShow" className="lander provider">
        <p
          onClick={() => this.props.backButtonClick(false)}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
        <h3>{formattedMessages.providerInformation}</h3>
      </div>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateBody = () => {
    let propProvider = this.props.provider;
    let { isEditing, edits, provider } = this.state;

    if (!provider) provider = propProvider;

    // Not editing and have Provider data?  Display it
    if (provider && !isEditing) {
      // If still loading provider, set text as such
      let loadingText = !this.state.provider ? 'Loading...' : '';
      return (
        <Form>
          {this.generateFormGroup(
            'Name:',
            provider.provider_name,
            'provider_name'
          )}
          {this.generateFormGroup(
            'Copy From:',
            provider.copy_dlvry_from || '',
            'copy_dlvry_from'
          )}
          {this.generateFormGroup(
            'Status:',
            provider.provider_status_name || loadingText,
            'provider_status'
          )}
          {this.generateFormGroup(
            'Country:',
            provider.country_name || loadingText,
            'formCountry'
          )}
          {this.generateFormGroup(
            'Tariff Plan:',
            provider.tariff_plan_name || loadingText,
            'formTariff'
          )}
          {this.generateFormGroup(
            'Language on Web Pages:',
            provider.language_name || loadingText,
            'formLang'
          )}
          {this.generateFormGroup(
            'Active Creation:',
            provider.active_creation === 'Y' ? 'Yes' : 'No',
            'active_creation'
          )}
          {this.generateFormGroup(
            'Xero Account:',
            provider.xero_accounts_name || loadingText,
            'formXeroAccount'
          )}
        </Form>
      );
    } else if (provider && this.state.isEditing) {
      return (
        <Form onChange={this.handleFormChange}>
          <Form.Group as={Row} controlId="provider_name">
            <Form.Label column sm={2} className="info-label">
              Name:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                name="provider_name"
                type="text"
                defaultValue={provider.provider_name}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="copy_dlvry_from">
            <Form.Label column sm={2} className="info-label">
              Copy From:
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                name="copy_dlvry_from"
                type="text"
                defaultValue={provider.copy_dlvry_from}
              />
              <span className="hintText">
                (Root Provider: 16f5fd20-8911-11e9-8735-6772c8e323ae)
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="provider_status">
            <Form.Label column sm={2} className="info-label">
              Status:
            </Form.Label>
            <Col sm={8}>{this.generateStatusSelect()}</Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formCountry">
            <Form.Label column sm={2} className="info-label">
              Country:
            </Form.Label>
            <Col sm={8}>
              <CountryPicker
                className="form-control"
                defaultValue={edits.country_code_id || provider.country_code_id}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="tariff_plan_id">
            <Form.Label column sm={2} className="info-label">
              Tariff Plan:
            </Form.Label>
            <Col sm={8}>{this.generateTariffPlanSelect()}</Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formLang">
            <Form.Label column sm={2} className="info-label">
              Language on Web Pages:
            </Form.Label>
            <Col sm={8}>
              <LanguagePicker
                className="form-control"
                optionsName="language_id"
                selectedLanguageId={
                  edits.language_id || this.props.provider.language_id
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="active_creation">
            <Form.Label column sm={2} className="info-label">
              Active Creation:
            </Form.Label>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                defaultChecked={provider.active_creation === 'Y'}
                name="active_creation"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formXeroAccount">
            <Form.Label column sm={2} className="info-label">
              Xero Account:
            </Form.Label>
            <Col sm={8}>{this.generateXeroSelect()}</Col>
          </Form.Group>
        </Form>
      );
    }

    return <span> No Data </span>;
  };

  generateButtons = () => {
    let propProvider = this.props.provider;
    let { isEditing, provider } = this.state;
    if (!provider) provider = propProvider;

    let buttonText = isEditing ? 'Cancel' : 'Edit';

    // Show single Edit button if not editing
    if (provider && !isEditing)
      return (
        <Button className="maxHeight" onClick={this.toggleEdit}>
          {buttonText}
        </Button>
      );

    return (
      <Fragment>
        <Button
          className="maxHeight"
          variant="primary"
          onClick={this.handleSubmit}
        >
          Save
        </Button>
        <Button className="maxHeight" onClick={this.toggleEdit}>
          {buttonText}
        </Button>
      </Fragment>
    );
  };

  render() {
    return (
      <div className="Home provider">
        {this.generateLander()}
        {this.generateBody()}
        {this.generateButtons()}
      </div>
    );
  }
}
