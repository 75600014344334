/*
  FormattedMessages.js - FormattedMessages for Admin component

  Author: Elle Dunbar (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  loading: (
    <FormattedMessage
      id="App.LoadingText"
      defaultMessage="Loading..."
    ></FormattedMessage>
  ),
  provider: (
    <FormattedMessage
      id="Admin.Provider"
      defaultMessage="Providers"
    ></FormattedMessage>
  ),
  customers: (
    <FormattedMessage
      id="Admin.Customers"
      defaultMessage="Customers"
    ></FormattedMessage>
  ),
  errorLog: (
    <FormattedMessage
      id="Admin.ErrorLog"
      defaultMessage="Error Log"
    ></FormattedMessage>
  ),
  traceLog: (
    <FormattedMessage
      id="Admin.TraceLog"
      defaultMessage="Trace Log"
    ></FormattedMessage>
  ),
  serviceConfig: (
    <FormattedMessage
      id="Admin.ServiceConfig"
      defaultMessage="Service Config"
    ></FormattedMessage>
  ),
  countries: (
    <FormattedMessage
      id="Admin.Countries"
      defaultMessage="Service Config - Countries"
    ></FormattedMessage>
  ),
  tariffing: (
    <FormattedMessage
      id="Admin.Tariffing"
      defaultMessage="Tariffing"
    ></FormattedMessage>
  ),
  findCustomer: (
    <FormattedMessage
      id="Admin.findCustomer"
      defaultMessage="Find Customer"
    ></FormattedMessage>
  ),
  systemMonitor: (
    <FormattedMessage
      id="Admin.systemMonitor"
      defaultMessage="System Monitor"
    ></FormattedMessage>
  ),
  accessDenied: (
    <FormattedMessage
      id="Admin.accessDenied"
      defaultMessage="Access Denied."
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
