/*
  List.js - Customer Contact (Administrator) List Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';

// COMPONENTS
import ListItem from './ListItem';

// I18N
import formattedMessages from './FormattedMessages';

// CONFIG
import config from '../../../../Config';

class List extends Component {
  constructor(props) {
    super(props);

    /*
      PROPS LIST
        - admins: An array of administrators to display in a Table format
        - handleTableRowClick: Function to handle when clicking a table row
    */

    this.state = {
      pageNumber: 1,
      error: null,
    };
    this.pageLength = config.defaultPL;
  }

  /*
    Handles the change event when clicking a pagination number
  */
  changeEvent = (i) => {
    return this.setState({
      pageNumber: i + 1,
    });
  };

  /*
    Generates the table code with rows
  */
  generateTable = () => {
    let fullList = [];
    let { admins } = this.props;
    // let {pageNumber} = this.state;

    // Ensure admins exists and is an Array
    if (admins && typeof admins !== 'undefined' && Array.isArray(admins)) {
      // let displayList = admins.slice(((pageNumber-1)*this.pageLength), (pageNumber*this.pageLength-1)+1);
      let displayList = admins;
      for (let i = 0; i < displayList.length; i++) {
        let element = displayList[i];

        let contactType = [];

        // Generate Strings for display in Table
        if (element.sales_contact !== 'N') {
          contactType.push(
            `${element.sales_contact === 'P' ? 'Primary' : 'Secondary'} Sales`
          );
        }
        if (element.billing_contact !== 'N') {
          contactType.push(
            `${
              element.billing_contact === 'P' ? 'Primary' : 'Secondary'
            } Billing`
          );
        }
        if (element.technical_contact !== 'N') {
          contactType.push(
            `${
              element.technical_contact === 'P' ? 'Primary' : 'Secondary'
            } Technical`
          );
        }

        fullList.push(
          <ListItem
            key={element.customer_contact_id}
            onClick={() => this.props.handleTableRowClick(element)}
            id={element.customer_contact_id}
            name={element.contact_name}
            department={element.contact_dept}
            accessLevel={element.access_level_meaning}
            contactType={contactType.join(', ')}
          />
        );
      }

      return (
        <div>
          {this.props.generateFirstLetterFilter()}

          <Table key="adminlist" id="adminlist">
            <thead>
              <tr key="head">
                <th className="medium tableTop textLeft">
                  {formattedMessages.name}
                </th>
                <th className="medium tableTop textLeft">
                  {formattedMessages.department}
                </th>
                <th className="medium tableTop textLeft">
                  {formattedMessages.accessLevel}
                </th>
                <th className="medium tableTop textLeft">
                  {formattedMessages.contactType}
                </th>
                <th className="icon tableTop textLeft"></th>
              </tr>
            </thead>
            <tbody>{fullList}</tbody>
          </Table>
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <Fragment>
        {this.generateTable()}
        {/*<Paginator itemCount={this.props.admins ? this.props.admins.length : 0} changeEvent={this.changeEvent}/>*/}
      </Fragment>
    );
  }
}

export default List;
