/*
  ContactImporterRequest.js - Networking for the Contacts Importing

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../containers/RequestRabbit';
// CONFIG
import config from '../../Config';
// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class ContactImporterRequest {
  /*
    Makes a request to fetch all Contacts relating to the User.  This includes
    the Global Contacts list for the Customer, as well as the User's own Personal Contacts list.
    Inputs:
      - None
    Outputs:
      - An object containing two keys: 'global' and 'personal', each containing an array of
      Contact Objects.
  */
  getContacts = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/contacts`;
      const options = {
        method: 'GET',
      };

      // Get Contacts
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600)
        errorHandler(responseJSON.error);
      return responseJSON;
    } catch (err) {
      errorHandler(err);
    }
  };

  /*
    Takes a Contact Object and sends it as a POST request to the back-end in order to
    insert a new Contact.
    Inputs:
      - contact: Requires first_name, last_name, email, number and entry_type.
        Optionals are middle_name
    Outputs:
      - Newly inserted Contact Object
  */
  insertContact = async (contact, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/contacts`;
      const body = JSON.stringify(contact);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        headers: headers,
        body: body,
      };
      // Insert Contact
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600)
        errorHandler(responseJSON.error);

      // let responseJSON = await response.json(); //Get JSON verson of response
      return responseJSON;
    } catch (err) {
      errorHandler(err);
    }
  };

  /*
    Takes a Contact Object and sends it as a POST request to the back-end in order
    to update a Contact.
    Inputs:
      - contact: Requires entry_type and addressbook_id.
        Optionals are first_name, middle_name, last_name, email and number
    Outputs:
      - Updated Contact Object
  */
  updateContact = async (contact, errorHandler) => {
    const url = `${config.baseServerURL}/addressbook/contacts/${contact.addressbook_id}`;
    const body = JSON.stringify(contact);
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: body,
    };

    try {
      // Update Contact
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600)
        errorHandler(responseJSON.error);
      // let responseJSON = await response.json(); //Get JSON verson of response
      return responseJSON;
    } catch (err) {
      errorHandler(err);
    }
  };

  /*
    Takes a Contact ID and deletes it from the back-end.
    Inputs:
      - contactId: Address Book Contact Id
      - entryType: (Query) Either 'personal' or 'global'
    Outputs:
      - Success or Failure Status Code
  */
  deleteContact = async (contactId, entryType, errorHandler) => {
    const url = `${config.baseServerURL}/addressbook/contacts/${contactId}?entry_type=${entryType}`;
    const options = {
      method: 'DELETE',
    };

    try {
      // Update Contact
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600)
        errorHandler(responseJSON.error);

      if (response.status === 204) return;
      errorHandler(responseJSON.error);
      // throw new Error('Failed to delete Contact');
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Used to count the number of Contacts (Global and Personal) for the
    calling Customer User
  */
  countContacts = async (errorHandler) => {
    const url = `${config.baseServerURL}/addressbook/contacts/count`;
    const options = {
      method: 'GET',
    };

    try {
      // Get Contacts Count
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600)
        errorHandler(responseJSON.error);

      // let responseJSON = await response.json(); //Get JSON verson of response
      return responseJSON;
    } catch (err) {
      errorHandler(err);
    }
  };

  /*
    Deletes ALL Personal Contacts for the calling Customer User
  */
  deleteAllContacts = async (selected, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/contacts/delete/${selected}`;
      const options = {
        method: 'DELETE',
      };

      // Update Contact
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600)
        errorHandler(responseJSON.error);
      if (response.status === 204) return true;
      errorHandler(responseJSON.error);
      // throw new Error('Failed to delete all Contacts of type ' + selected);
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
    Creates a network request to upload a CSV file containing Contacts
  */
  importContacts = async (
    entryType,
    file,
    distributionListId,
    errorHandler
  ) => {
    try {
      const url = `${config.baseServerURL}/addressbook/import`;

      // Set body of multi-part form
      let formData = new FormData();
      formData.set('type', entryType);
      formData.set('file', file);
      // If we have a distribution list id, add it
      if (distributionListId)
        formData.set('distribution_list_id', distributionListId);
      const options = {
        method: 'POST',
        body: formData,
      };

      // Send request with file attachment
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Handle error response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        // throw responseJSON.error;
      }

      // Return response for success
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  exportFailedContacts = async (filename, errorHandler) => {
    try {
      let downloadUrl = `${config.baseServerURL}/addressbook/download/${filename}`;
      window.open(downloadUrl);
      return;
    } catch (e) {
      errorHandler(e);
    }
  };

  exportContacts = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/addressbook/export`;
      const options = {
        method: 'GET',
      };

      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();
      if (response.status > 399 && response.status < 600)
        errorHandler(responseJSON.error);
      if (response.status === 200) {
        // let responseJSON = await response.json(); //Get JSON verson of response
        let downloadUrl = `${config.baseServerURL}/addressbook/download/${responseJSON.filename}`;
        window.open(downloadUrl);
        return;
      }
      return;
    } catch (e) {
      errorHandler(e);
    }
  };
}
