import React, { Component } from 'react';

// STYLING
import '../../../../../Users/Users.css';

class RouteNameDrilldownComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      daemonConfig: [],
    };
  }

  componentDidMount() {
    this.getRouteNameDrilldown();
  }

  getRouteNameDrilldown = async () => {
    let daemonConfig = await this.props.getRouteNameDrilldown(
      this.props.selectedRouteName
    );
    this.setState({ daemonConfig: daemonConfig });
  };

  generateContent = () => {
    return (
      <div className="visible-desktop">
        {this.generateBackButton()}
        <h5>{this.props.selectedRouteName}</h5>
        {this.generateTable()}
      </div>
    );
  };

  generateTable = () => {
    let daemonConfig = this.state.daemonConfig;
    if (daemonConfig && Object.keys(daemonConfig).length > 0) {
      return (
        <div>
          <pre>{JSON.stringify(daemonConfig, null, 2)}</pre>
        </div>
      );
    } else {
      return <p>Loading...</p>;
    }
  };

  /**
   * Generates back button to go back to the Stats page.
   * @returns button component (as a p tag)
   */
  generateBackButton = () => {
    return (
      <p onClick={() => this.props.backToMuxIdDrilldown()} className="backLink">
        <i className="material-icons">keyboard_arrow_left</i>
        <span>Back</span>
      </p>
    );
  };

  /* renders the component */
  render() {
    return this.generateContent();
  }
}

export default RouteNameDrilldownComponent;
