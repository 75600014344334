// NPM MODULES
import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class CustomTooltip extends Component {
  constructor(props) {
    super(props);
    /*
          Expected Props:
            - tooltipText: String containing the text for the tooltip.
            - margin: the left margin in pixels as string (e.g. '5px'). 
            // TODO: combine margin with styling object to
            - className: the className for the tooltip's inner element.
            - iconStyling: styling for the icon.
            - textStyling: styling for the tooltip's paragraph (p) element.
        */
    this.state = {};
  }

  render() {
    let { margin, className } = this.props;

    let iconStyling = {
      marginLeft: margin ? margin : '0px',
      ...this.props.iconStyling,
    };

    let textStyling = {
      ...this.props.textStyling,
      textAlign: this.props.textStyling?.textAlign || 'left',
    };

    return (
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => (
          <Tooltip id="button-tooltip" className={className} {...props}>
            <p style={textStyling}>{this.props.tooltipText}</p>
          </Tooltip>
        )}
      >
        <i className="material-icons help-icon" style={iconStyling}>
          help
        </i>
      </OverlayTrigger>
    );
  }
}

export default CustomTooltip;
