import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';
// import { FormattedMessage } from 'react-intl';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

class ListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { list, loading } = this.props;

    if (loading) {
      return <div>{formattedMessages.loadingText}...</div>;
    } else
      return (
        <Fragment>
          <Table>
            <thead>
              <tr>
                <td className="medium tableTop left">
                  {formattedMessages.createdDate}
                </td>
                <td className="medium tableTop left">
                  {formattedMessages.contactName}
                </td>
                <td className="medium tableTop textLeft" colSpan="4">
                  {formattedMessages.comments}
                </td>
                <td className="medium tableTop"></td>
              </tr>
            </thead>
            <tbody>{list}</tbody>
          </Table>
        </Fragment>
      );
  }
}

export default ListComponent;
