// OUR MODULES
import RequestRabbit from '../../RequestRabbit';

// CONFIG
import config from '../../../Config';

const handleRequest = new RequestRabbit();

export default class TemplatesPersonal {
  /*
    Gets Templates (both personal and global) and returns them
  */
  getTemplates = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/templates`;
      const options = {
        method: 'GET',
      };

      // Send Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      //Error catching
      errorHandler(e);
    }
  };

  /*
    Gets the specified Template and returns it.
  */
  getTemplateById = async (templateId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/templates/${templateId}`;
      const options = {
        method: 'GET',
      };

      // Send Request
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      //Error catching
      errorHandler(e);
    }
  };

  // entryType is 'personal' or 'global'
  createTemplate = async (name, content, entryType, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/templates/`;
      const body = JSON.stringify({
        template_name: name,
        template_text: content,
        template_type: entryType,
      });
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        headers: headers,
        body: body,
      };
      // Create Template
      let response = await handleRequest.request(url, options, errorHandler);
      if (response.status > 399 && response.status < 600)
        errorHandler('Failed to create Template');
    } catch (e) {
      errorHandler(e);
    }
  };

  /*
    Given a name and/or content, updates a new Template in the correct entry
  */
  editTemplate = async (templateId, updates, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/templates/${templateId}`;
      const body = JSON.stringify(updates);
      const headers = new Headers({
        'Content-Type': 'application/json',
      });
      const options = {
        method: 'POST',
        headers: headers,
        body: body,
      };
      // Create Template
      let response = await handleRequest.request(url, options, errorHandler);
      if (response.status > 399 && response.status < 600)
        errorHandler('Failed to create Template');
    } catch (e) {
      errorHandler(e);
    }
  };

  deleteTemplate = async (templateId, entryType, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/templates/${templateId}?entry_type=${entryType}`;
      const options = {
        method: 'DELETE',
      };

      // Send Request
      let response = await handleRequest.request(url, options, errorHandler);
      if (response.status > 399 && response.status < 600)
        errorHandler('Delete Template Request failed for id: ' + templateId);
      return;
    } catch (e) {
      errorHandler(e);
    }
  };
}
