import React, { Component, Fragment } from 'react';

// Custom Components
import { Table } from 'react-bootstrap';
import FormattedMessage from '../FormattedMessages';

class ListComponent extends Component {
  constructor(props) {
    super(props);
  }

  generateCancelledTable = () => {
    const { totals, listCancelledCustomers } = this.props;

    if (!listCancelledCustomers || listCancelledCustomers.length === 0)
      return 'No cancelled customers found for the given month';

    return (
      <div>
        <br />
        <Table className="invoice-table commission-report table-new">
          <thead>
            <tr>
              <td className="medium tableTop left">
                {FormattedMessage.createdDate}
              </td>
              <td className="medium tableTop">{FormattedMessage.date}</td>
              <td className="medium tableTop">{FormattedMessage.customerID}</td>
              <td className="medium tableTop">
                {FormattedMessage.customerName}
              </td>
              <td className="medium tableTop">
                {FormattedMessage.customerType}
              </td>
              <td className="medium tableTop">
                {FormattedMessage.customerLocation}
              </td>
              <td className="medium tableTop">{FormattedMessage.agent}</td>
              <td className="medium tableTop">{FormattedMessage.cancelled}</td>
            </tr>
          </thead>
          <tbody>
            {listCancelledCustomers}
            <tr>
              <td className="medium tableTop left" colSpan="7">
                {FormattedMessage.total}
              </td>
              <td className="medium tableTop column-left" colSpan="1">
                {totals.totalUsers !== 0 ? '-' : ''}
                {totals.totalUsers} Users
                <br />
                {totals.Cancelled !== 0 ? '-' : ''}
                {totals.Cancelled} Customers
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  generateNewTable = () => {
    const { totals, listNewCustomers } = this.props;

    if (!listNewCustomers || listNewCustomers.length === 0)
      return (
        <div>
          No new customers found for the given month
          <br />
        </div>
      );

    return (
      <div>
        <br />
        <Table className="invoice-table commission-report table-new">
          <thead>
            <tr>
              <td className="medium tableTop left">
                {FormattedMessage.createdDate}
              </td>
              <td className="medium tableTop">{FormattedMessage.date}</td>
              <td className="medium tableTop">{FormattedMessage.customerID}</td>
              <td className="medium tableTop">
                {FormattedMessage.customerName}
              </td>
              <td className="medium tableTop">
                {FormattedMessage.customerType}
              </td>
              <td className="medium tableTop">
                {FormattedMessage.alreadyBilled}
              </td>
              <td className="medium tableTop">
                {FormattedMessage.customerLocation}
              </td>
              <td className="medium tableTop ">{FormattedMessage.agent}</td>
              <td className="medium tableTop ">
                {FormattedMessage.minCommitment}
              </td>
            </tr>
          </thead>
          <tbody>
            {listNewCustomers}
            <tr>
              <td className="medium tableTop left" colSpan="8">
                {FormattedMessage.total}
              </td>
              <td className="medium tableTop column-left" colSpan="1">
                ${totals.Total}
                <br />+{totals.Count} Customers
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <h5>{FormattedMessage.newCustomers}</h5>
        {this.generateNewTable()}
        <br />
        <h5>{FormattedMessage.cancelledCustomers}</h5>
        {this.generateCancelledTable()}
      </Fragment>
    );
  }
}

export default ListComponent;
