import React from 'react';

const ListItem = ({
  id,
  provider,
  status,
  customers,
  Direct = { sent: 0, parts: 0 },
  Standard = { sent: 0, parts: 0 },
  Replies = { sent: 0, parts: 0 },
  Sent = { sent: 0, parts: 0 },
  users,
}) => (
  <tr key={'list' + id} id={id} className="">
    <td className="medium textLeft wrap" name="provider" colSpan={2}>
      <span>{provider}</span>
    </td>
    <td className="medium " name="status">
      <span>{status}</span>
    </td>
    <td className="medium " name="customers">
      <span>{customers}</span>
    </td>
    <td className="medium " name="users">
      <span>{users}</span>
    </td>
    <td className="medium column-left" name="directMessages">
      <span>{Direct.sent + ` (${Direct.parts})`}</span>
    </td>
    <td className="medium" name="standardMessages">
      <span>{Standard.sent + ` (${Standard.parts})`}</span>
    </td>
    <td className="medium column-right" name="replies">
      <span>{Replies.sent + ` (${Replies.parts})`}</span>
    </td>
    <td className="medium" name="reminders">
      <span>{Sent.sent + ` (${Sent.parts})`}</span>
    </td>
  </tr>
);

export default ListItem;
