import React, { Component, Fragment } from 'react';
import ListItem from './ListItem';
import InlineListItem from './InlineListItem';
import formattedMessages from './../FormattedMessages';

class FullUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      list: null,
      activePage: 0,
      pages: [],
      pagination: null,
      pagModal: false,
      typedPage: 0,
    };
  }

  componentDidMount = () => {
    this.props.getDataFunction();
  };

  /*
  Formats the data in a manner required for the Table
  to properly display the data
*/
  generateTable = () => {
    // Otherwise create JSX data
    let data = this.props.fullUsageData.map((el, index) => {
      return (
        <Fragment key={index + 1}>
          <ListItem id={index + 1} costCenter={el.cost_centre_name} />
          {el.messages.map((message, messageIndex) => {
            return (
              <Fragment key={messageIndex}>
                <InlineListItem
                  user={message.user_name}
                  date={message.date}
                  time={message.time}
                  phone={message.dest_tn}
                  type={message.revenue_type}
                  quantity={message.quantity}
                  revenue={message.revenue}
                  translation={message.tariff_type}
                />
              </Fragment>
            );
          })}
        </Fragment>
      );
    });

    return data;
  };

  changeEvent = (o) => {
    console.log(o);
  };
  render() {
    if (!this.props.fullUsageData) {
      return (
        <Fragment>
          <p>{formattedMessages.loading}</p>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <h2>{formattedMessages.fullUsage}</h2>
        <p>
          <b>
            {this.props.billDates.start_date} {formattedMessages.to}{' '}
            {this.props.billDates.end_date}
          </b>
        </p>
        <table width="100%" className="invoice-table full-usage">
          <tbody>
            <tr>
              <td className="medium tableTop">{formattedMessages.key}</td>
              <td className="medium tableTop">
                {formattedMessages.costCenter}
              </td>
              <td className="medium tableTop">{formattedMessages.usersName}</td>
              <td className="medium tableTop">{formattedMessages.date}</td>
              <td className="medium tableTop">{formattedMessages.time}</td>
              <td className="medium tableTop">{formattedMessages.phone}</td>
              <td className="medium tableTop">{formattedMessages.type}</td>
              <td className="medium tableTop">{formattedMessages.quantity}</td>
              <td className="medium tableTop">{formattedMessages.revenue}</td>
            </tr>
            {this.generateTable()}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

export default FullUsage;
