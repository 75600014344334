import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  nameText: (
    <FormattedMessage
      id="Websms.NameText"
      defaultMessage="Name"
    ></FormattedMessage>
  ),
  saveText: (
    <FormattedMessage
      id="Websms.SaveText"
      defaultMessage="Save"
    ></FormattedMessage>
  ),
  saving: (
    <FormattedMessage
      id="Websms.Saving"
      defaultMessage="Saving..."
    ></FormattedMessage>
  ),
  confirmDeleteDistributionList: (
    <FormattedMessage
      id="Websms.confirmDeleteDistributionList"
      defaultMessage="Are you sure you want to delete this distribution list?"
    ></FormattedMessage>
  ),
  cancelText: (
    <FormattedMessage
      id="Websms.CancelText"
      defaultMessage="Cancel"
    ></FormattedMessage>
  ),
  deleting: (
    <FormattedMessage
      id="Websms.deleting"
      defaultMessage="Deleting..."
    ></FormattedMessage>
  ),
  distributionListName: (
    <FormattedMessage
      id="Websms.distributionListName"
      defaultMessage="Distribution List Name: "
    ></FormattedMessage>
  ),
  backButton: (
    <FormattedMessage
      id="App.BackButton"
      defaultMessage="Back"
    ></FormattedMessage>
  ),
  distListDetailsTitle: (
    <FormattedMessage
      id="Websms.DistListDetailsTitle"
      defaultMessage="Distribution List Details"
    ></FormattedMessage>
  ),
  addContactsTitle: (
    <FormattedMessage
      id="Websms.AddContactsTitle"
      defaultMessage="Add Contacts"
    ></FormattedMessage>
  ),
  deleteText: (
    <FormattedMessage
      id="Websms.DeleteText"
      defaultMessage="Delete"
    ></FormattedMessage>
  ),
  exportText: (
    <FormattedMessage
      id="Websms.ExportText"
      defaultMessage="Export"
    ></FormattedMessage>
  ),
  importText: (
    <FormattedMessage
      id="Websms.ImportText"
      defaultMessage="Import"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
