/*
  Sent/List.js - Component to generate the list of Prepaid Message Bundles
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Table, Button } from 'react-bootstrap';

// COMPONENTS

// CONFIG
import config from '../../../../Config';
import formattedMessages from '../FormattedMessages';

export default class ListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      error: null,
    };
    this.pageLength = config.defaultPL;
  }

  /*
    Handles the change event when clicking a pagination number
  */
  changeEvent = (i) => {
    return this.setState({
      pageNumber: i + 1,
    });
  };

  generateTableBody = () => {
    let { list } = this.props;
    let rows = [];

    for (let i = 0; i < list.length; i++) {
      let element = list[i];
      rows.push(
        <tr key={i} id={element.tariff_line_id} valign="middle">
          <td>
            <span>
              {element.tariff_name} for ${element.rate}
            </span>
          </td>
          <td>
            <Button
              className="add-new-template float-right"
              onClick={() => this.props.purchase(element)}
            >
              {formattedMessages.purchase}
            </Button>
          </td>
        </tr>
      );
    }
    return rows;
  };

  generateMobileTableBody = () => {
    let { list } = this.props;
    let rows = [];
    let { pageNumber } = this.state;
    let displayList = list.slice(
      (pageNumber - 1) * this.pageLength,
      pageNumber * this.pageLength - 1 + 1
    );
    for (let i = 0; i < displayList.length; i++) {
      let element = displayList[i];

      rows.push(
        <tr key={i} id={element.sms_id}>
          <td>
            <span className="blue" onClick={() => this.props.purchase()}>
              {element.tariff_name} for ${element.rate}
            </span>
          </td>
          <td>
            <Button
              className="add-new-template float-right"
              onClick={() => this.props.purchase()}
            >
              {formattedMessages.purchase}
            </Button>
          </td>
        </tr>
      );
    }
    return rows;
  };

  render() {
    return (
      <Fragment>
        <div className="visible-desktop">
          <Table id="inbox">
            <thead>
              <tr key="head">
                <th className="wide textLeft tableTop">
                  {'PrePaid Message bundles available:'}
                </th>
                <th className="wide textLeft tableTop"></th>
              </tr>
            </thead>
            <tbody>{this.generateTableBody()}</tbody>
          </Table>
        </div>
        <div className="visible-device">
          <table id="sent" width="100%" cellSpacing="0">
            <thead>
              <tr>
                <th className="medium textLeft tableTop"></th>
              </tr>
            </thead>
            <tbody>{this.generateMobileTableBody()}</tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}
