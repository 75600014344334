import React from 'react';

const ListItem = ({
  id,
  prefixName = '',
  onClickName = null,
  prefix = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="column-right" name="name">
      <span className="blue" onClick={() => onClickName(prefix)}>
        {prefixName}
      </span>
    </td>
  </tr>
);

export default ListItem;
