/**
 * gets character count for a message
 * @param {String} msgValue
 * @returns An object with the standard, unicode and total character counts as well as a boolean concluding whether the
 * message is unicode or not.
 */
exports.getCharacterCount = (msgValue) => {
  if (msgValue && typeof msgValue === 'string') {
    // Creates a new string which is msgValue without unicode characters
    let noUnicodeMessage = msgValue.replace(
      /[^\u000A\u0020-\u007E]/g && /[^\x00-\x7F]/g,
      ''
    );

    let standardCharacterOnly = noUnicodeMessage.length; // Calculate ASCII char only
    let unicodeCharacterOnly = msgValue.length - noUnicodeMessage.length; // Calculate Unicode Only

    let characterCount = standardCharacterOnly + unicodeCharacterOnly;

    return {
      characterCount: characterCount,
      standardCharacterOnly: standardCharacterOnly,
      unicodeCharacterOnly: unicodeCharacterOnly,
      isUnicodeMessage: unicodeCharacterOnly > 0,
    };
  }

  // if no message, return defaults of 0
  return {
    characterCount: 0,
    standardCharacterOnly: 0,
    unicodeCharacterOnly: 0,
    isUnicodeMessage: false,
  };
};

/**
 * Function that checks if the provided message would be within the character limit
 * @param {String} msgValue
 * @param {Boolean} isUnicode
 * @returns true if within the character limit, otherwise false.
 */
exports.withinCharacterLimit = (msgValue, isUnicode) => {
  if (msgValue && typeof msgValue === 'string') {
    // If no bool passed in, will calculate with above function
    if (isUnicode === undefined)
      // Note that non-declarative destructuring requires brackets
      ({ isUnicodeMessage: isUnicode } = this.getCharacterCount(msgValue));

    // Messages over 335 and 765 exceed the character limits for unicode and standard respectively.
    if (
      (isUnicode && msgValue.length > 335) ||
      (!isUnicode && msgValue.length > 765)
    )
      return false;
  }
  return true;
};

/**
 * A function that, given a message, determines how many parts it would be split into, taking the encoding (unicode or regular)
 * into account. TODO: This function and its implementation could be modified to take more parameters to cut down on
 * getCharacterCount calls. For now, I thought I would keep it simple.
 * @param {String} msgValue The value for the message about to be sent
 * @returns Returns an integer which represents the amount of message parts the message would be sent as.
 */
exports.getMessageParts = (msgValue) => {
  let { isUnicodeMessage, characterCount } = this.getCharacterCount(msgValue);
  let messagePartCount;
  // If unicode
  if (isUnicodeMessage) {
    // One unicode message consists of 70 or less characters
    if (characterCount <= 70) messagePartCount = 1;
    // Else a unicode message above 70 characters will be broken into 67 characters instead
    else messagePartCount = Math.ceil(characterCount / 67);
  }
  // Else if standard
  else {
    // One standard message consists of 160 or less characters
    if (characterCount <= 160) messagePartCount = 1;
    // Else a standard message above 160 characters will be broken into 153 characters instead
    else messagePartCount = Math.ceil(characterCount / 153);
  }
  return messagePartCount;
};

/**
 * Simple function that returns either the Unicode message limit and Plain text limit
 * @param {Boolean} isUnicodeMessage Predetermined whether the message would be unicode or not.
 * @returns Number representing the appropriate limit
 */
exports.getMessageMaxLength = (isUnicodeMessage) => {
  // 5 parts of 67 and 153 respectively
  let messageMaxLength = isUnicodeMessage ? 335 : 765;

  return messageMaxLength;
};
