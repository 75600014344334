/*
  Edit.js - Cost Centres Edit Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

// COMPONENTS
import ErrorText from '../../../components/ErrorText';
import List from './ListContacts';

// I18N
import formattedMessages from './FormattedMessages';

// NETWORK
import CostCentreRequest from './CostCentreRequest';
import AdministratorRequest from '../Administrators/AdministratorRequest';
const costCentreRequest = new CostCentreRequest();
const administratorRequest = new AdministratorRequest();

export default class CostCentreEdit extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - centre: The selected Cost Centre
        - errorHandler: A function to handle error messages
        - handleBackClick: A function to handle back clicks from this Component
    */

    this.state = {
      contacts: [],
      edits: {},
      isSaving: false,
      error: null,
    };
  }

  async componentDidMount() {
    // Fetches all Customer Contacts for this Customer
    let contacts = await administratorRequest.getAdministrators(
      this.props.errorHandler
    );
    contacts = this.sortContacts(contacts);
    return this.setState({
      contacts: contacts,
    });
  }

  /*
   Sort contacts alphabetically by contact_name
  */
  sortContacts(contacts) {
    let sortedContacts = contacts.sort(function (a, b) {
      if (a.contact_name.toLowerCase() < b.contact_name.toLowerCase()) {
        return -1;
      }
      if (a.contact_name.toLowerCase() > b.contact_name.toLowerCase()) {
        return 1;
      }
      return 0;
      //sorts alphabetically
    });
    return sortedContacts;
  }

  /*
    Holds state changes from the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let { edits } = this.state;

    // Delete if User selecting None for Cost Centre Contact
    if (name === 'customer_contact_id' && value === 'None') {
      edits.customer_contact_id = null;
    } else {
      edits[name] = value;
    }

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles form submission
  */
  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState(
      {
        isSaving: true,
      },
      async () => {
        let { centre } = this.props;
        let { edits } = this.state;

        // Edit Cost Centre
        await costCentreRequest.editCostCentre(
          centre.cost_centre_id,
          edits,
          this.props.errorHandler
        );

        this.props.handleBackClick(true);
      }
    );
  };

  /*
    Handles when the Delete button is clicked
  */
  // handleDeleteClick = async (e) => {
  //   e.preventDefault();

  //   this.setState({
  //     isSaving: true
  //   }, async () => {
  //     let {centre} = this.props;
  //     // Delete Cost Centre
  //     await costCentreRequest.deleteCostCentre(centre.cost_centre_id, this.props.errorHandler);

  //     this.props.handleBackClick(true)
  //   });
  // };

  /*
    Renders Error Component
  */
  generateErrorText = () => {
    if (!this.state.error) return null;
    return <ErrorText hasError={true} errorText={this.state.error} />;
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="AddCostCentre" className="lander">
        <p onClick={() => this.props.handleBackClick()} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backToList}</span>
        </p>
        <h3>{formattedMessages.editCostCenter}</h3>
      </div>
    );
  };

  /*
    Generate the buttons for the form
  */
  generateButtons = () => {
    if (this.state.isSaving) return <p>Saving...</p>;

    return (
      <Form.Group controlId="buttons">
        <Button onClick={this.handleSubmit} className="maxHeight">
          {formattedMessages.save}
        </Button>
        <Button
          onClick={() => this.props.handleBackClick()}
          className="maxHeight"
        >
          {formattedMessages.cancel}
        </Button>
      </Form.Group>
    );
  };

  /*
    Generates a Select element with options containing
    Customer Contacts.
  */
  generateContactSelect = () => {
    let { contacts, edits } = this.state;
    let { centre } = this.props;
    let contactOptions = [];

    contactOptions.push(
      <option key={'None'} value={'None'}>
        {'None' /*formattedMessages.none*/}
      </option>
    );

    for (let i = 0; i < contacts.length; i++) {
      contactOptions.push(
        <option
          key={contacts[i].customer_contact_id}
          value={contacts[i].customer_contact_id}
        >
          {contacts[i].contact_name}
        </option>
      );
    }
    return (
      <Form.Control
        as="select"
        name="customer_contact_id"
        value={edits.customer_contact_id || centre.customer_contact_id}
      >
        {contactOptions}
      </Form.Control>
    );
  };

  generateForm = () => {
    let { centre } = this.props;
    return (
      <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
        <Form.Group as={Row} controlId="cost_centre_name">
          <Form.Label column sm={2} className="detail-label">
            * {formattedMessages.name}:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              required
              name="cost_centre_name"
              type="text"
              defaultValue={centre.cost_centre_name}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="customer_contact_id">
          <Form.Label column sm={2} className="detail-label">
            {formattedMessages.contactName}:
          </Form.Label>
          <Col sm={8}>{this.generateContactSelect()}</Col>
        </Form.Group>
        {this.generateErrorText()}
        {this.generateButtons()}
      </Form>
    );
  };

  render() {
    return (
      <Fragment>
        {this.generateLander()}
        {this.generateForm()}
        <span className="main">
          <List centre={this.props.centre} />
        </span>
      </Fragment>
    );
  }
}
