import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import DateRangeSelector from '../../../../components/DateRangeSelector';
import AdminRequest from '../../AdminRequest';
import FormattedMessages from '../RecentComponent/FormattedMessages';
const adminRequest = new AdminRequest();
class CollapsedComponent extends Component {
  constructor(props) {
    super(props);

    /*
      expected props:
          - values: Values that should be used for displaying the recent log messages.
    */

    this.state = {
      selectorKeyRefresh: false,
      open: false,
    };
  }

  /*
    Handles searching User Data between two dates
  */
  handleUserDataSearch = async (fromDate, toDate, timeRange) => {
    try {
      this.setState({ loading: true, data: undefined });
      const searchParams = {
        timeRange: timeRange,
        timeRangeStart: fromDate,
        timeRangeEnd: toDate,
        accountFilter: this.state.accountFilter,
        emailFilter: this.state.emailFilter,
      };

      let collapsedLogData = await adminRequest.getCollapsedLogMessages(
        this.props.errorHandler,
        searchParams
      );
      console.log('data: ', collapsedLogData.length);
      this.setState({
        loading: false,
        data: collapsedLogData,
        pageCount: collapsedLogData.length,
        timeRange: timeRange,
        pageLoaded: true,
        currentlySearching: {
          accountFilter: searchParams.accountFilter,
          emailFilter: searchParams.emailFilter,
        },
      });
    } catch (e) {
      console.error(e);
      return this.setState({
        error: e.message,
        loading: false,
      });
    }
  };

  generateTableBody = () => {
    const { data } = this.state;

    return data.map((el, index) => {
      return (
        <tr key={index}>
          <td name="date">
            <span>{el.date}</span>
          </td>
          <td name="accountNo">
            <span>{el.accountNo}</span>
          </td>
          <td
            name="email"
            className="blue"
            onClick={() => this.props.goToRecent(el.email)}
          >
            <span>{el.email}</span>
          </td>
          <td name="total">
            <span>{el.total}</span>
          </td>
          <td name="failures">
            <span>{el.failed}</span>
          </td>
        </tr>
      );
    });
  };

  generateTable = () => {
    return (
      <div key="LogList">
        <table width="100%" className="table-new td-border">
          <thead>
            <tr>
              <td
                className="tableTop"
                style={{ width: '200px', marginLeft: '5px' }}
              >
                {'Recent Date'}
              </td>
              <td className="tableTop" style={{ marginLeft: '5px' }}>
                {'Account ID'}
              </td>
              <td className="tableTop" style={{ marginLeft: '5px' }}>
                {'Email'}
              </td>
              <td className="tableTop" style={{ marginLeft: '5px' }}>
                {'Total'}
              </td>
              <td className="tableTop" style={{ marginLeft: '5px' }}>
                {'Failures'}
              </td>
            </tr>
          </thead>
          <tbody>{this.generateTableBody()}</tbody>
        </table>
      </div>
    );
  };

  /*
    Convenience function to handle the updating of a form element
  */
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.type === 'text' && target.value === '') delete target.value;

    console.log('handleInputChange event name:', name);
    console.log('handleInputChange event value:', value);

    this.setState({
      [name]: value,
    });
  }

  clearFilters = () => {
    this.setState({
      accountFilter: null,
      emailFilter: null,
    });
    document.getElementById('filter-form').reset();
  };

  toggleMenu = () => this.setState(({ open }) => ({ open: !open }));

  generateCollapsedPage() {
    const { selectorKeyRefresh, currentlySearching, open } = this.state;
    let content = 'Loading...';
    if (this.state.data && this.state.data.length === 0) {
      content = 'No results found.';
    } else if (this.state.data) {
      content = (
        <div className="table table-middle">{this.generateTable()}</div>
      );
    }

    // Destructures which filters the table is currently displaying fields on.
    let { accountFilter } = currentlySearching || {};

    let filterText = [];
    let filterArray = [accountFilter];
    let values = ['Account:'];

    // Pushes spans to an array for rendering. Iterates to avoid code duplication.
    filterArray.map((filter, i) => {
      if (filter)
        filterText.push(
          <span>
            {filterText.length === 0 ? '' : '; '} <b>{values[i]}</b> {filter}
          </span>
        );
    });

    return (
      <div>
        <br />
        <Button
          className="maxHeight float-right"
          onClick={() =>
            this.setState({
              selectorKeyRefresh: !this.state.selectorKeyRefresh,
            })
          }
        >
          {'Refresh'}
        </Button>
        <h5>Collapsed</h5>
        <DateRangeSelector
          key={selectorKeyRefresh ? 1 : 0} // Changing the key remounts it
          handleDateSearch={(fromDate, toDate, timeRange) =>
            this.handleUserDataSearch(fromDate, toDate, timeRange)
          }
        ></DateRangeSelector>
        <br />
        <div className="Home">
          <div className="lander">
            <br />
            <span className="showHideLink-left">
              <span className="advanced-filters" onClick={this.toggleMenu}>
                {open ? (
                  <i className="material-icons">keyboard_arrow_down</i>
                ) : (
                  <i className="material-icons">keyboard_arrow_right</i>
                )}
                Advanced Filters
              </span>

              <div className="clear-filters" onClick={this.clearFilters}>
                {FormattedMessages.clear}
              </div>
            </span>

            {open && (
              <div>
                <Form id="filter-form">
                  <table className="no-table-row-background">
                    <tbody className="filter-table">
                      <div className="filter">
                        <div className="filter-item">
                          <td>
                            <Form.Label className="searchRange filter-label">
                              Account:
                            </Form.Label>
                          </td>
                          <td>
                            <div>
                              <Form.Control
                                type="text"
                                name="accountFilter"
                                id="accountFilter"
                                className="filter-control"
                                onChange={(e) => this.handleInputChange(e)}
                                placeholder="Search Account"
                              />
                            </div>
                          </td>
                        </div>
                      </div>
                    </tbody>
                  </table>
                </Form>
                <br></br>
              </div>
            )}
            <div className="flex-between">
              {filterText.length > 0 && <div>{filterText}</div>}
            </div>
          </div>
        </div>
        {content}
      </div>
    );
  }

  render() {
    return this.generateCollapsedPage();
  }
}

export default CollapsedComponent;
