/*
  ErrorRequest.js - ErrorRequest Networking File
  
  Contains networking functionality for Error Log Components

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';

// CONFIGURATION
import config from '../../../Config';

// REQUEST
const requestRabbit = new RequestRabbit();

export default class ErrorRequest {
  searchErrorLog = async (searchObject, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/admin/errorlog`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify(searchObject) || null,
        headers: headers,
      };
      console.log('searchObject');
      console.log(searchObject);

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      console.log(`response: ${JSON.stringify(response, null, 2)}`);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      // console.log(responseJSON);
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };
}
