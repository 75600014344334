import React from 'react';

const InlineListItem = ({
  user,
  date,
  time,
  phone,
  type,
  quantity,
  revenue,
  translation,
}) => (
  <tr>
    <td className="left"></td>
    <td></td>
    <td>{user}</td>
    <td>{date}</td>
    <td>{time}</td>
    <td>{phone}</td>
    <td>
      {type} {translation && `(${translation})`}
    </td>
    <td>{quantity}</td>
    <td>{revenue}</td>
  </tr>
);

export default InlineListItem;
