/*
  MessageReportRequest.js - Handles Search for Message Report Data
*/

// OUR MODULES
import RequestRabbit from '../../RequestRabbit';
import config from '../../../Config';

// INSTANTIATE
const handleRequest = new RequestRabbit();

export default class SearchRequest {
  /*
    Get Report Data for Date Range
  */
  getMessageReportData = async (searchObject, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/reports/message`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(searchObject) || null,
        headers: headers,
      };
      // Request data
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };

  /*
    Get Report Data for Date Range
  */
  getSingleMessageData = async (smsId, entryType, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/reports/message/` + smsId;
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        method: 'POST',
        body: JSON.stringify({ entryType }) || null,
        headers: headers,
      };
      // Request data
      let response = await handleRequest.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };
}
