// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

// OUR COMPONENTS
import GlobalList from './GlobalListComponent';
import PersonalList from './PersonalListComponent';
import SingleView from './SingleTemplateComponent';

// NETWORKING
import TemplateRequest from './TemplateRequest';

// I18N
import FormattedMessage from './FormattedMessages';
import WebSMSTabs from '../index';
import FormattedMessages from './FormattedMessages';

// INSTANTATION
const templateRequest = new TemplateRequest();

export default class TemplateList extends Component {
  constructor(props) {
    super(props);
    console.log('template props', this.props);

    this.state = {
      stv: false,
      tab: 'Global',
      data: { global: [], personal: [] },
      selected: null,
      loading: true,
      error: false,
      errorMessage: '',
      saveDisabled: false,
    };
  }

  async componentDidMount() {
    if (this.props.customer && this.props.customer !== null) {
      let data = await templateRequest.getTemplates(this.props.errorHandler);

      let sortedGlobal = this.sortList(data.global);
      let sortedPersonal = this.sortList(data.personal);

      this.setState({
        data: { global: sortedGlobal, personal: sortedPersonal },
        loading: false,
      });
    }
  }

  sortList(data) {
    let sortedList = data.sort(function (a, b) {
      if (a.template_name < b.template_name) {
        return -1;
      }
      if (a.template_name > b.template_name) {
        return 1;
      }
      return 0;
    });

    return sortedList;
  }

  showSingleViewCallback = () => {
    return this.setState({
      stv: true,
    });
  };

  handleBackClick = () => {
    return this.setState({
      stv: false,
      selected: null,
    });
  };

  setTab = (event) => {
    this.setState({
      tab: event,
    });
  };

  handleSelect = async (template) => {
    return this.setState({
      stv: true,
      selected: template,
    });
  };

  handleSaveClick = async (templateName, templateContent) => {
    if (this.state.saveDisabled) {
      // console.log('save disabled');
    } else {
      this.setState({
        saveDisabled: true,
      });
      await templateRequest.createTemplate(
        templateName,
        templateContent,
        this.state.tab,
        this.props.errorHandler
      );

      // Refresh data
      let data = await templateRequest.getTemplates();
      return this.setState({
        saveDisabled: false,
        data: data,
        selected: null,
      });
    }
  };

  handleEditClick = async (templateName, templateContent) => {
    let updates = { template_type: this.state.tab };
    if (templateName && typeof templateName !== 'undefined')
      updates.template_name = templateName;
    if (templateContent && typeof templateContent !== 'undefined')
      updates.template_text = templateContent;
    // console.log('updates', updates);
    // console.log(this.state);

    await templateRequest.editTemplate(
      this.state.selected.template_sms_id,
      updates,
      this.props.errorHandler
    );

    let data = await templateRequest.getTemplates();
    return this.setState({
      data: data,
    });
  };

  handleDeleteClick = async (templateId) => {
    await templateRequest.deleteTemplate(
      templateId,
      this.state.tab,
      this.props.errorHandler
    );

    // Refresh data
    let data = await templateRequest.getTemplates();
    return this.setState({
      data: data,
      selected: null,
    });
  };

  /**
   *  Resets the page's info when the tab is clicked.
   */
  handlePageReset = () => {
    this.setState({
      selected: null,
      tab: 'Global',
      stv: false,
    });
  };

  generateBody = () => {
    let { error, loading, data } = this.state;
    let { accessLevel, accessType } = this.props;
    if (error) return <div>Error: {this.state.errorMessage}</div>;
    else if (loading) return <div>{FormattedMessage.loadingText}...</div>;
    let globalTemplate = data.global;
    let personalTempate = data.personal;

    // If have single view
    if (this.props.hideLeftMenu) {
      if (this.state.stv) {
        return (
          <span className="singleView">
            <SingleView
              accessLevel={accessLevel}
              accessType={accessType}
              tab={this.state.tab}
              selected={this.state.selected}
              callBack={this.handleBackClick}
              handleSave={this.handleSaveClick}
              saveDisabled={this.state.saveDisabled}
              handleEdit={this.handleEditClick}
              handleDelete={this.handleDeleteClick}
              errorHandler={this.props.errorHandler}
            />
          </span>
        );
      }
      return (
        <span className="main">
          <h3>{FormattedMessages.templateTitle}</h3>
          <Tabs activeKey={this.state.tab} onSelect={this.setTab}>
            <Tab eventKey="Global" title="Global Templates">
              <GlobalList
                accessLevel={accessLevel}
                accessType={accessType}
                list={globalTemplate}
                callBack={this.showSingleViewCallback}
                selectOnly={true}
                handleSelect={this.props.handleSelect}
                errorHandler={this.props.errorHandler}
              />
            </Tab>
            <Tab eventKey="Personal" title="Personal Templates">
              <PersonalList
                list={personalTempate}
                callBack={this.showSingleViewCallback}
                selectOnly={true}
                handleSelect={this.props.handleSelect}
                errorHandler={this.props.errorHandler}
              />
            </Tab>
          </Tabs>
        </span>
      );
    } else if (this.state.stv) {
      return (
        <span className="singleView">
          <SingleView
            accessLevel={accessLevel}
            accessType={accessType}
            tab={this.state.tab}
            selected={this.state.selected}
            callBack={this.handleBackClick}
            handleSave={this.handleSaveClick}
            saveDisabled={this.state.saveDisabled}
            handleEdit={this.handleEditClick}
            handleDelete={this.handleDeleteClick}
            errorHandler={this.props.errorHandler}
          />
        </span>
      );
    }

    return (
      <span className="main">
        <h3>{FormattedMessages.templateTitle}</h3>
        <Tabs activeKey={this.state.tab} onSelect={this.setTab}>
          <Tab eventKey="Global" title="Global Templates">
            <GlobalList
              accessLevel={accessLevel}
              accessType={accessType}
              list={globalTemplate}
              callBack={this.showSingleViewCallback}
              selectOnly={true}
              handleSelect={this.handleSelect}
              errorHandler={this.props.errorHandler}
            />
          </Tab>
          <Tab eventKey="Personal" title="Personal Templates">
            <PersonalList
              accessLevel={accessLevel}
              accessType={accessType}
              list={personalTempate}
              callBack={this.showSingleViewCallback}
              selectOnly={true}
              handleSelect={this.handleSelect}
              errorHandler={this.props.errorHandler}
            />
          </Tab>
        </Tabs>
      </span>
    );
  };

  render() {
    if (this.props.hideLeftMenu) {
      return (
        <Fragment>
          <div className="Home">{this.generateBody()}</div>
        </Fragment>
      );
    }
    return (
      <WebSMSTabs
        tab="template"
        mobileMenuOpen={this.props.mobileMenuOpen}
        navigate={this.props.navigate}
        toggleMobileMenu={this.props.toggleMobileMenu}
        handlePageReset={this.handlePageReset}
      >
        <div className="Home">
          <div className="lander">{this.generateBody()}</div>
        </div>
      </WebSMSTabs>
    );
  }
}
