/*
  ContactImporter - Component to handle importation of Contacts
*/
// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Button, Modal } from 'react-bootstrap';

// NPM COMPONENTS
import { FilePicker } from 'react-file-picker';

// STYLING
import './ContactImporter.css';

// FORMATTED MESSAGES
import formattedMessages from './FormattedMessages';

// NETWORKING
import ContactImporterRequest from './ContactImporterRequest';
const contactImporterRequest = new ContactImporterRequest();

export default class ContactImporter extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - handleBackClick: A function to handle what happens when the Back button is clicked
        - distributionListId: (Optional) If passed in, will save imported contacts
        - update: Calls this function after importing Contacts (to go back to the new/edit dist list view)
        - errorHandler: Function to handle error capturing
    */

    this.state = {
      contactType: 'Personal',
      errorText: '',
      failureModal: false,
      errorFilename: undefined,
    };
  }

  componentDidMount = async () => {
    if (this.props.tab) {
      await this.setState({ contactType: this.props.tab });
    }
  };

  generateTypeSelect = () => {
    let { accessLevel, accessType } = this.props;
    if (
      this.props.tab === 'Personal' &&
      accessLevel === '-1' &&
      accessType === '0'
    )
      return (
        <div>
          <p>{formattedMessages.selectImportContact}</p>
          <select
            name="contactType"
            className="form-control halfWidth"
            value={this.state.contactType}
            onChange={(e) => {
              this.handleForm(e);
            }}
          >
            <option key="personal" value="Personal">
              Personal
            </option>
          </select>
        </div>
      );
    else if (this.props.tab === 'Global')
      return (
        <div>
          <p>{formattedMessages.selectImportContact}</p>
          <select
            name="contactType"
            className="form-control halfWidth"
            value={this.state.contactType}
            onChange={(e) => {
              this.handleForm(e);
            }}
          >
            <option key="global" value="Global">
              Global
            </option>
          </select>
        </div>
      );
    else
      return (
        <div>
          <p>{formattedMessages.selectImportContact}</p>
          <select
            name="contactType"
            className="form-control halfWidth"
            value={this.state.contactType}
            onChange={(e) => {
              this.handleForm(e);
            }}
          >
            <option key="personal" value="Personal">
              Personal
            </option>
            <option key="global" value="Global">
              Global
            </option>
          </select>
        </div>
      );
  };

  captureFile = async (file) => {
    console.log('Got here with file:', file);
    this.setState({
      file: file,
      errorMessage: '',
      errorText: '',
    });
  };

  generateFileSelect = () => {
    return (
      <div className="marginTopMedium">
        <p>2) Select the CSV file to import contacts from</p>
        <FilePicker
          extensions={['csv']}
          className="marginBottomMedium"
          onChange={(fileObject) => this.captureFile(fileObject)}
          onError={(errMsg) => this.setState({ errorMessage: errMsg })}
        >
          <button>Choose File...</button>
        </FilePicker>
        {this.state.file ? `Selected File: ${this.state.file.name}` : ''}
        {this.generateFileSelectError()}
        <div className="marginTopMedium">
          Please Note: The CSV Format including headers should match{' '}
          <a
            className="inline"
            href="/static/example_contact_import.csv"
            download
          >
            this file
          </a>
        </div>
      </div>
    );
  };

  generateFileSelectError = () => {
    if (!this.state.errorMessage) return null;
    return <p className="errText">{this.state.errorMessage}</p>;
  };

  uploadContacts = async () => {
    try {
      let type = this.state.contactType;
      let file = this.state.file;

      if (!file || typeof file === 'undefined') {
        // Handle no file error
        return this.setState({
          errorText: 'Must select a valid CSV file to upload',
        });
      }
      // Import Contacts request
      let response = await contactImporterRequest.importContacts(
        type,
        file,
        this.props.distributionListId,
        this.props.errorHandler
      );

      if (response && this.props.showContactImporter) {
        if (response.errorFilename) {
          this.setState({
            failureModal: true,
            errorFilename: response.errorFilename,
          });
        } else this.props.showContactImporter();
      } else if (response && this.props.update) {
        this.props.update(response);
      }
    } catch (e) {
      // Handle Upload Error
      console.log('Got upload err:', e);
      return this.setState({
        errorText: e,
      });
    }
  };

  generateSubmitButton = () => {
    return (
      <Button
        className="contact-importer maxHeight btn btn-primary"
        onClick={this.uploadContacts}
        disabled={!this.state.file}
      >
        {formattedMessages.importText}
      </Button>
    );
  };

  generateError = () => {
    if (!this.state.errorText) return null;

    return <div className="text-danger">{this.state.errorText}</div>;
  };

  /*
      Handles changes in the form
  */
  handleForm = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  /*
    Convenience function to display Template Modal
  */
  handleModal = () => {
    this.setState({
      failureModal: false,
    });
    if (this.props.showContactImporter) this.props.showContactImporter();
  };

  handleDownload = () => {
    let { errorFilename } = this.state;
    if (errorFilename)
      contactImporterRequest.exportFailedContacts(
        errorFilename,
        this.props.errorHandler
      );
    if (this.props.showContactImporter) this.props.showContactImporter();
  };

  render() {
    return (
      <Fragment>
        <div>
          <p onClick={this.props.handleBackClick} className="backLink">
            <i className="material-icons">keyboard_arrow_left</i>
            <span>{formattedMessages.backText}</span>
          </p>
          <h3> {formattedMessages.importText} </h3>
        </div>
        {this.generateTypeSelect()}
        <br />
        {this.generateFileSelect()}
        {this.generateError()}
        {this.generateSubmitButton()}
        <Modal
          show={this.state.failureModal}
          onHide={this.handleModal}
          backdrop="static"
        >
          <Modal.Header className="error-card" closeButton>
            <Modal.Title>{formattedMessages.modalWarning}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            One or more contacts failed to be added. The failed contacts can be
            downloaded below. The other rows were added successfully.
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button
              className="maxHeight"
              variant="primary"
              onClick={this.handleDownload}
            >
              Download
            </Button>
            <Button
              className="maxHeight btn-red"
              variant="primary"
              onClick={this.handleModal}
            >
              Ignore
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}
