/*
  SingleView.js - Admin Carrier Prefix Single View

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component, Fragment } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

// NETWORK
import CarrierRequest from '../CarrierRequest';
import formattedMessages from '../../../../Provider/FormattedMessages';

// INSTANTIATION
const carrierRequest = new CarrierRequest();

export default class CarrierPrefixSingleView extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - carrier: A Carrier object to save a Carrier Prefix against
        - carrierPrefix: A single carrierPrefix object (optional)
        - backButtonClick: Function to handle Back Button click
        - isNew: Whether or not it's handling a new Carrier Prefix,
        - errorHandler: A function to pass errors to
    */

    console.log('Have Carrier Prefix props:', this.props);

    this.state = {
      isEditing: false,
      edits: {},
    };
  }

  /*
    Handles changes in the form
  */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let edits = this.state.edits;
    edits[name] = value;

    console.log('Edits is:', edits);

    return this.setState({
      edits: edits,
    });
  };

  /*
    Handles Edit form submission
  */
  handleSubmit = async (e) => {
    try {
      console.log('Handle submit');
      e.preventDefault();
      let data = this.state.edits;
      let { carrier, errorHandler, isNew, carrierPrefix } = this.props;

      // Have new Object, create
      if (isNew) {
        await carrierRequest.createNewCarrierPrefix(
          carrier.country_code_id,
          carrier.carrier_id,
          data,
          errorHandler
        );
      }
      // Editing object, update
      else {
        await carrierRequest.updateCarrierPrefix(
          carrier.country_code_id,
          carrier.carrier_id,
          carrierPrefix.carrier_prefix_id,
          data,
          errorHandler
        );
      }

      window.location.href = '/admin/serviceconfig-country'; // Force reload - TODO: redo properly
    } catch (e) {
      console.log('Have err:', e);
      // Handle error
    }
  };

  /*
      Handles Delete of Country Prefix Map
    */
  handleDelete = async (e) => {
    let { carrier, errorHandler, carrierPrefix } = this.props;
    await carrierRequest.deleteCarrierPrefixById(
      carrier.country_code_id,
      carrier.carrier_id,
      carrierPrefix.carrier_prefix_id,
      errorHandler
    );
    window.location.href = '/admin/serviceconfig-country'; // Force reload - TODO: redo properly
  };

  /*
    Handles Edit Mode on/off
  */
  toggleEdit = () => {
    return this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  generateEditButton = () => {
    let { isEditing } = this.state;
    let buttonText = isEditing ? 'Cancel' : 'Edit';

    if (!this.props.isNew || typeof this.props.isNew === 'undefined') {
      return (
        <Button className="maxHeight" onClick={this.toggleEdit}>
          {buttonText}
        </Button>
      );
    }
    return null;
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="CountryShow" className="lander carrier-prefix">
        <p
          onClick={() => this.props.backButtonClick('list')}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
        <h3>Carrier Prefix Information</h3>
      </div>
    );
  };

  generateBody = () => {
    let { carrierPrefix, isNew } = this.props;
    let { isEditing } = this.state;

    console.log('isEditing:', isEditing);

    if (isNew) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="prefix">
              <Form.Label column sm={2} className="info-label">
                * Prefix:
              </Form.Label>
              <Col sm={8}>
                <Form.Control name="prefix" type="text" />
              </Col>
            </Form.Group>
            <Button
              className="createProviderButton"
              variant="primary"
              type="submit"
            >
              Save
            </Button>
          </Form>
          {this.generateEditButton()}
        </Fragment>
      );
    } else if (isEditing && carrierPrefix) {
      return (
        <Fragment>
          <Form onSubmit={this.handleSubmit} onChange={this.handleFormChange}>
            <Form.Group as={Row} controlId="prefix">
              <Form.Label column sm={2} className="info-label">
                * Prefix:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  name="prefix"
                  type="text"
                  defaultValue={carrierPrefix.prefix}
                />
              </Col>
            </Form.Group>
            <Button
              className="createProviderButton"
              variant="primary"
              type="submit"
            >
              Save
            </Button>
          </Form>
          {this.generateEditButton()}
        </Fragment>
      );
    } else if (carrierPrefix) {
      return (
        <Fragment>
          <Form>
            {this.generateFormGroup(
              '* Prefix:',
              carrierPrefix.prefix,
              'prefix'
            )}
          </Form>
          {this.generateEditButton()}
          <Button
            className="delete btn-red btn btn-danger"
            onClick={this.handleDelete}
          >
            Delete
          </Button>
        </Fragment>
      );
    }

    return <span>No Data</span>;
  };

  render() {
    return (
      <div className="Home Carrier-Prefix">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
