/*
  AddDN.js - Displays form for adding a new Dedicated Number to a user

  Author: David Mackenzie (copying directly from Kyle Combeer (2019))
  Company: Virtual Ark
*/

// NPM
import React, { Component, Fragment } from 'react';
import { Button, Form, Row, Col, Table, Modal } from 'react-bootstrap';

// COMPONENTS
//import CountryPicker from '../../../components/CountryPicker';
import ErrorText from '../../../components/ErrorText';

// I18N
import formattedMessages from './FormattedMessages';

// NETWORK
import UserRequest from './UserRequest';
const userRequest = new UserRequest();

export default class UserAddDN extends Component {
  constructor(props) {
    super(props);

    /*
      Props List
        - errorHandler: A function to call when handling errors
    */

    this.state = {
      error: null,
      hasError: false,
      available: [], // list of numbers that are available
      desired: [], // list of numbers to be added
      dedicated: null, // dedicated numbers
      undesired: {}, // list of numbers to be removed
      edits: {},
      isSearching: false, // should toggle while waiting for twilio response.
      isProvisioning: false,
      isReleasing: false,
      legacyReleaseCheck: false,
      selected: 0, // number of numbers selected for provisioning?
      campaigns: null,
      campaignEdits: {},
      selectedGateway: 'Twilio',
      selectedTemplateDevice: 'Twilio USA #3301 TxtMe (RO Ian)',
      templateDeviceValue: '',
    };
  }

  async componentDidMount() {
    let { errorHandler, user } = this.props;
    let dedicatedNumbers = await userRequest.getDedicatedNumbers(
      user.customer_user_id,
      errorHandler
    );
    let dns = dedicatedNumbers;

    let ud = {};
    ud.numbers = [];
    let campaigns = await userRequest.getCustomerCampaignData(
      user.customer_id,
      errorHandler
    );

    return this.setState({
      available: [],
      desired: [],
      undesired: ud,
      dedicated: dns,
      campaigns: campaigns,
    });
  }

  /*
    Renders Error Text Component
  */
  generateErrorText = () => {
    return (
      <ErrorText hasError={this.state.hasError} errorText={this.state.error} />
    );
  };

  /*
    Handles any changes from the form
  */
  handleChange = (e) => {
    let { edits, desired, undesired } = this.state;
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    console.log('handleChange called');

    if (target.type === 'checkbox') {
      console.log('Checked a checkbox');
      console.log('target:', target);

      if (target.name === 'provision') {
        if (target.checked) {
          desired.push(target.value);
        } else {
          let pos = desired.indexOf(target.value);
          desired.splice(pos, 1);
        }
        console.log('desired:', desired);
      }

      if (target.name === 'release') {
        let udItem = {};
        udItem.device_id = target.id;
        udItem.phoneNumber = target.value;

        if (target.checked) {
          undesired.numbers.push(udItem);
        } else {
          let pos = undesired.numbers.indexOf(udItem);
          undesired.numbers.splice(pos, 1);
        }
        console.log('undesired', undesired);
      }
    }

    if (name === 'campaignChange') {
      let { dedicated, campaignEdits } = this.state;
      let index = dedicated.findIndex((e) => {
        if (e.device_id === target.id) {
          if (value === 'N/A' && e.campaignCode !== 'N/A') return true;
          else if (value !== 'N/A' && value !== e.campaignCode) return true;
        }
      });

      if (index !== -1) campaignEdits[target.id] = value;
      // If its the same campaign, then delete from the edits object.
      else delete campaignEdits[target.id];

      return this.setState({ campaignEdits: campaignEdits });
    }

    edits[name] = value;
    console.log('Edits:', edits);

    return this.setState({
      edits: edits,
      desired: desired,
      undesired: undesired,
      hasError: false,
      error: '',
    });
  };

  /*
    Validates form.  Returns whether form is valid and, if not valid,
    returns an error text as well.
  */
  validateForm = () => {
    let isValidForm = false;
    let errText = '';
    let { edits } = this.state;

    // Below is an example of normal field verification...
    // if (!edits.user_name) {
    //   isValidForm = false;
    //   errText = 'Requires a User Name.'
    // }

    console.log('edits.prefix: ', edits.prefix);
    if (edits.prefix === undefined) {
      isValidForm = false;
      errText = 'Requires a 3-digit prefix.';
    } else {
      if (edits.prefix.length < 3 || edits.prefix.length > 3) {
        isValidForm = false;
        errText = 'Requires a 3-digit prefix.';
      }
      if (isNaN(edits.prefix)) {
        isValidForm = false;
        errText = 'Requires a 3-digit prefix.';
      }
    }

    // If no error text, we have a valid form
    if (!errText) isValidForm = true;

    this.setState({
      hasError: !isValidForm,
      error: errText,
    });

    return {
      isValidForm: isValidForm,
      formError: errText,
    };
  };

  /*
    Generates the Lander section
  */
  generateLander = () => {
    return (
      <Fragment>
        <p onClick={this.props.callBack} className="backLink">
          <i className="material-icons">keyboard_arrow_left</i>
          <span>Back to User</span>
        </p>
        <div className="flex">
          <h4>{formattedMessages.addDN}</h4>
          {this.generateReleaseButton()}
        </div>
      </Fragment>
    );
  };

  /*
    Generates the Save Button for the form based on the state
  */
  generateSearchButton = () => {
    // If in Search State
    if (this.state.isSearching) return <p>Searching...</p>;

    return (
      <Button
        type="button"
        onClick={this.handleSearch}
        className="maxHeight"
        variant="primary"
      >
        Search for available numbers
      </Button>
    );
  };

  generateProvisionButton = () => {
    // If in Provisioning State
    if (this.state.isProvisioning) return <p>Provisioning...</p>;

    let { desired } = this.state;
    if (desired.length <= 0) return;

    return (
      <Button
        type="button"
        onClick={this.handleProvision}
        className="maxHeight"
        variant="primary"
      >
        Provision selected
      </Button>
    );
  };

  generateReleaseButton = () => {
    // If in Releasing State
    if (this.state.isReleasing)
      return <p className="flexRight">Releasing...</p>;

    let { undesired } = this.state;
    console.log('undesired:', undesired);
    let nums = undesired.numbers;
    if ((nums ? nums.length : 0) <= 0) return;

    return (
      <Button
        type="button"
        onClick={this.handleRelease}
        className="flexRight maxHeight"
        variant="primary"
      >
        Release selected
      </Button>
    );
  };

  generateWarningModal = () => {
    return (
      <Modal
        show={this.state.legacyReleaseCheck}
        onHide={() => {
          this.setState({ legacyReleaseCheck: false, isReleasing: false });
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{formattedMessages.modalWarning}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{formattedMessages.modalBody}</Modal.Body>
        <Modal.Footer>
          <Button
            className="maxHeight"
            variant="primary"
            onClick={() => this.handleRelease(false)}
          >
            {formattedMessages.modalYes}
          </Button>
          <Button
            className="no-button btn btn-secondary"
            variant="secondary"
            onClick={() => {
              this.setState({ legacyReleaseCheck: false, isReleasing: false });
            }}
          >
            {formattedMessages.modalCancel}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  /*
    Handles clicking the Add Dedicated Number Button
  */
  handleSearch = async () => {
    let result = this.validateForm();
    console.log(result);
    if (!result.isValidForm) {
      this.generateErrorText();

      console.log('form Validation Failed');
      return;
    }
    const data = {
      prefix: this.state.edits.prefix,
      country: this.state.edits.country || 'US',
      gateway: this.state.selectedGateway,
    };

    this.toggleSearching();
    // console.log(data);
    //let { countryCode, prefix } = this.state;
    let { errorHandler /*user*/ } = this.props;
    let newNumbersObject = await userRequest.searchAvailableNumbers(
      data,
      errorHandler
    );

    // console.log('handleSearch1: ');
    // console.log(newNumbersObject);
    if (newNumbersObject === 'undefined') {
      console.log('Error retrieving numbers from Twilio');
      this.props.callBack(true);
    } else {
      this.setState({
        available: newNumbersObject,
        desired: [],
      });
      //this.generateAvailableTable();
      console.log('handleSearch2: ', this.state.available);
      this.clearCheckboxes('provision');
      this.toggleSearching();
    }
  };

  clearCheckboxes(name) {
    var elements = document.getElementsByClassName(
      'form-check-input position-static'
    );
    for (var i = 0, len = elements.length; i < len; i++) {
      if ((elements[i] ? elements[i].name : null) === name)
        elements[i].checked = false;
    }
  }

  changeCampaignForNumber = async () => {
    this.setState({
      isChangingCampaign: true,
    });
    let { campaignEdits } = this.state;
    let { errorHandler, user } = this.props;

    let response = await userRequest.changeCampaignForNumbers(
      campaignEdits,
      user.customer_user_id,
      errorHandler
    );

    let dedicatedNumbers = await userRequest.getDedicatedNumbers(
      user.customer_user_id,
      errorHandler
    );
    console.log('Dedicated Numbers after provision:', response);

    this.setState({
      campaignEdits: {},
      dedicatedNumbers: dedicatedNumbers,
      isChangingCampaign: false,
    });
  };

  handleProvision = async () => {
    console.log('handleProvision called');
    this.toggleProvisioning();

    const { errorHandler, user } = this.props;
    const { desired, edits, selectedGateway } = this.state;
    const { number } = this.getTemplateDeviceOptions(selectedGateway) || {};
    const data = {
      templateDeviceTn: number,
      numbers: desired,
      customerUserId: user.customer_user_id,
      customerId: user.customer_id,
      campaignCode: edits.campaignCode,
      gateway: selectedGateway,
    };
    console.log('data:', data);
    let response = await userRequest.provisionNumbers(data, errorHandler);
    console.log('Provision response:', response);

    response = await userRequest.getDedicatedNumbers(
      user.customer_user_id,
      errorHandler
    );
    console.log('Dedicated after provision:', response);
    if (edits?.campaignCode) {
      delete edits.campaignCode;
    }

    this.clearCheckboxes('provision');
    this.toggleProvisioning();

    return this.setState({
      dedicated: response,
      available: [],
      desired: [],
      edits: edits,
    });
  };

  handleRelease = async (checkFlag) => {
    console.log('handleRelease called');
    this.toggleReleasing(true);
    this.setState({ legacyReleaseCheck: false });
    let { errorHandler, user } = this.props;
    let { undesired, dedicated } = this.state;

    console.log('undesired:', undesired);

    if (checkFlag) {
      for (let i = 0; i < undesired.numbers.length; i++) {
        let undesiredNumber = undesired.numbers[i];
        // To get the device info of the number to be released.
        let deviceIndex = dedicated.findIndex((element) => {
          return element.device_id === undesiredNumber.device_id;
        });

        // If it didn't find the dedicated number with the selected device id, there has been an error somewhere.
        if (deviceIndex !== -1) {
          let legacyCheck = dedicated[deviceIndex].device_name?.indexOf('AWS');
          if (legacyCheck === -1 || legacyCheck === undefined) {
            return this.setState({
              legacyReleaseCheck: true,
            });
          }
        }
      }
    }
    console.log('data:', undesired);
    await userRequest.releaseTwilioNumbers(undesired, errorHandler);

    let response = await userRequest.getDedicatedNumbers(
      user.customer_user_id,
      errorHandler
    );
    console.log('Dedicated after release:', response);

    let ud = {};
    ud.numbers = [];

    this.clearCheckboxes('release');
    this.toggleReleasing(false);

    return this.setState({
      dedicated: response,
      undesired: ud,
    });
  };

  toggleSearching() {
    let toggle = !this.state.isSearching;
    return this.setState({
      isSearching: toggle,
    });
  }

  toggleProvisioning() {
    let toggle = !this.state.isProvisioning;
    return this.setState({
      isProvisioning: toggle,
    });
  }

  toggleReleasing(target) {
    let toggle = !this.state.isReleasing;
    return this.setState({
      isReleasing: target || toggle,
    });
  }

  generateAvailableTable = () => {
    let htmlRows = [];
    const { available } = this.state;

    console.log('generateAvailableTable:', available);

    if (available === undefined || !available || available.length === 0) {
      return;
    }
    available.forEach((item) => {
      const locality =
        item.locality?.charAt(0).toUpperCase() +
        item.locality?.slice(1).toLowerCase();
      htmlRows.push(
        <tr style={{ textAlign: 'left' }}>
          <td>{item.phoneNumber}</td>
          <td>{locality}</td>
          <td>
            <Col sm={8}>
              <Form.Check
                type="checkbox"
                value={item.phoneNumber}
                name="provision"
                class="provision-checks"
              />
            </Col>
          </td>
        </tr>
      );
    });

    return (
      <div>
        {this.generateCampaignDropdown()}
        <Table style={{ marginTop: '20px' }} className="table-new">
          <thead>
            <tr>
              <td className="medium tableTop textLeft">Phone Number</td>
              <td className="medium tableTop textLeft">Locality</td>
              <td className="medium tableTop textLeft">Provision?</td>
            </tr>
          </thead>
          <tbody>{htmlRows}</tbody>
        </Table>
      </div>
    );
  };

  generateDedicatedNumbersTable = () => {
    let htmlRows = [];
    let { dedicated, campaigns, campaignEdits, isChangingCampaign } =
      this.state;

    if (!dedicated) {
      return <p>Loading...</p>;
    }
    if (dedicated.length === 0) {
      return <p>No result found</p>;
    }

    let options = [
      <option key={'N/A'} value={'N/A'}>
        {'N/A'}
      </option>,
    ];

    for (let i = 0; i < campaigns.length; i++) {
      // Push unassigned option
      options.push(
        <option key={i} value={campaigns[i].campaign_code}>
          {campaigns[i].campaign_code}
        </option>
      );
    }

    dedicated.forEach((item, i) => {
      if (item !== null) {
        let { device_name, device_id, route_name, campaignCode, reply_tn } =
          item;

        // Creates dropdown select for the campaign if current campaign is valid for the new system.
        let campaignContent = (
          <Form.Control
            className="campaigns-dropdown-menu"
            onChange={this.handleChange}
            as="select"
            name="campaignChange"
            defaultValue={campaignCode || 'N/A'}
            id={device_id}
          >
            {options}
          </Form.Control>
        );

        // If campaign wasn't found for the customer in the new system, its probably legacy or broken.
        if (
          !campaigns ||
          campaigns.findIndex((e) => {
            if (e.campaign_code === campaignCode || campaignCode === 'N/A')
              return true;
          }) === -1
        ) {
          campaignContent = campaignCode || 'N/A';
        }

        htmlRows.push(
          <tr key={i} style={{ textAlign: 'center' }}>
            <td className="dedicatednumbers-table">{device_name}</td>
            <td className="dedicatednumbers-table">{campaignContent}</td>
            <td className="dedicatednumbers-table">{route_name}</td>
            <td className="dedicatednumbers-table">{reply_tn}</td>
            <td className="checkbox-width">
              <Col sm={8} className="margin-auto">
                <Form.Check
                  type="checkbox"
                  id={device_id}
                  value={reply_tn}
                  name="release"
                />
              </Col>
            </td>
          </tr>
        );
      }
    });

    let {
      campaign,
      deviceName,
      release,
      gateway,
      dedicatedNumber,
      saveChanges,
      saving,
    } = formattedMessages;

    let button = null;
    if (isChangingCampaign) {
      button = <p>{saving}</p>;
    } else if (campaignEdits && Object.keys(campaignEdits).length !== 0) {
      button = (
        <Button
          type="button"
          onClick={this.changeCampaignForNumber}
          className="flexRight maxHeight"
          variant="primary"
        >
          {saveChanges}
        </Button>
      );
    }

    return (
      <div>
        <Table style={{ marginTop: '20px' }} className="table-new">
          <thead>
            <tr>
              <th className="medium tableTop column-left">{deviceName}</th>
              <th className="medium tableTop column-left">{campaign}</th>
              <th className="medium tableTop column-left">{gateway}</th>
              <th className="medium tableTop column-left">{dedicatedNumber}</th>
              <th className="medium tableTop column-left">{release}</th>
            </tr>
          </thead>
          <tbody>{htmlRows}</tbody>
        </Table>
        {button}
      </div>
    );
  };

  /*
    Handles the gateway dropdown field.
  */
  handleGatewayDropdown = (event) => {
    const selectedValue = event.target.value;
    const { name: selectedTemplateDevice } =
      this.getTemplateDeviceOptions(selectedValue) || {};
    this.setState({
      selectedGateway: selectedValue,
      selectedTemplateDevice: selectedTemplateDevice,
      templateDeviceValue: '',
      available: [],
      desired: [],
    });
    this.clearCheckboxes('provision');
  };

  /*
      Handles the template device dropdown field.
    */
  handleTemplateDeviceDropdown = (event) => {
    const selectedValue = event.target.value;

    this.setState({
      selectedTemplateDevice: selectedValue,
      templateDeviceValue: '',
    });
  };

  /*
    Get template device options based on the gateway
  */
  getTemplateDeviceOptions = (selectedValue) => {
    if (selectedValue === 'Twilio')
      return {
        name: 'Twilio USA #3301 TxtMe (RO Ian)',
        number: '+18572142673',
      };
    else if (selectedValue === 'Telnyx')
      return {
        name: 'Telnyx #7001 TxtMe (Template)',
        number: 'TelnyxDedicated',
      };
  };

  generateDropdowns = () => {
    let options = [];
    let { selectedGateway } = this.state;
    if (selectedGateway === 'Twilio') {
      options.push(
        <option key={'TwilioDedicated'} value={'Twilio'}>
          {'Twilio USA #3301 TxtMe (RO Ian)'}
        </option>
      );
    } else {
      options.push(
        <option key={'TelnyxDedicated'} value={'Telnyx'}>
          {'Telnyx #7001 TxtMe (Template)'}
        </option>
      );
    }

    return (
      <div>
        <Form.Group as={Row} controlId="gateway">
          <Form.Label column sm={2} className="info-label">
            Gateway:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              name="gateway"
              value={this.state.selectedGateway}
              onChange={this.handleGatewayDropdown}
            >
              <option value="Twilio">Twilio</option>
              <option value="Telnyx">Telnyx</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="template_device_id">
          <Form.Label column sm={2} className="info-label">
            Template Device:
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              name="template_device_id"
              value={this.state.selectedTemplateDevice}
              onChange={this.handleTemplateDeviceDropdown}
            >
              {options}
            </Form.Control>
          </Col>
        </Form.Group>
      </div>
    );
  };

  generateCampaignDropdown = () => {
    let { campaigns } = this.state;

    if (!campaigns) return null;
    let { selectedGateway } = this.state;

    let options = [
      <option key={'None'} value={undefined}>
        {'None'}
      </option>,
    ];

    for (let i = 0; i < campaigns.length; i++) {
      if (
        selectedGateway === 'Twilio' &&
        campaigns[i].campaign_type === 'Twilio'
      ) {
        options.push(
          <option key={i} value={campaigns[i].campaign_code}>
            {campaigns[i].campaign_code}
          </option>
        );
      } else if (
        selectedGateway === 'Telnyx' &&
        campaigns[i].campaign_type === 'Telnyx'
      ) {
        options.push(
          <option key={i} value={campaigns[i].campaign_code}>
            {campaigns[i].campaign_code}
          </option>
        );
      }
    }

    return (
      <Form.Group as={Row} controlId="campaignCode">
        <Form.Label column sm={2} className="info-label">
          Provision against campaign:
        </Form.Label>
        <Col sm={8}>
          <Form.Control as="select" name="campaignCode" defaultValue={'None'}>
            {options}
          </Form.Control>
        </Col>
      </Form.Group>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateTextFormGroup = (label, controlId, isRequired, setType) => {
    let type = 'text';
    if (setType) type = setType;
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            required={isRequired || true}
            name={controlId}
            autoComplete="new-password"
            type={type}
          />
        </Col>
      </Form.Group>
    );
  };

  /*
    Generates the Country Picker Options
  */
  generateCountryPicker = () => {
    let options = [];

    // Push unassigned option
    options = [
      <option key={'country1'} value={'US'}>
        {'United States'}
      </option>,
      <option key={'country2'} value={'CA'}>
        {'Canada'}
      </option>,
    ];

    return (
      <Form.Group as={Row} controlId="country">
        <Form.Label column sm={2} className="info-label">
          Country:
        </Form.Label>
        <Col sm={8}>
          <Form.Control as="select" name="country" defaultValue={'US'}>
            {options}
          </Form.Control>
        </Col>
      </Form.Group>
    );
  };

  generateForm = () => {
    //let { edits } = this.state;
    //let { user } = this.props;

    return (
      <Form onChange={this.handleChange}>
        {this.generateDedicatedNumbersTable()}
        {this.generateDropdowns()}
        {this.generateCountryPicker()}
        {this.generateTextFormGroup('* Prefix', 'prefix', true)}
        {this.generateErrorText()}
        <div className="flex">
          {this.generateSearchButton()}
          {this.generateProvisionButton()}
        </div>
        {this.generateAvailableTable()}
      </Form>
    );
  };

  render() {
    return (
      <Fragment>
        {this.generateLander()}
        <br />
        {this.generateWarningModal()}
        {this.generateForm()}
      </Fragment>
    );
  }
}
