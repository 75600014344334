import React, { Component, Fragment } from 'react';

// Custom Components
import { Table } from 'react-bootstrap';
import FormattedMessage from '../FormattedMessages';

class ListComponent extends Component {
  constructor(props) {
    super(props);
  }

  generateNewTable = () => {
    const { totals, listNewCustomers } = this.props;

    if (!listNewCustomers || listNewCustomers.length === 0)
      return (
        <div>
          No new customers found for the given month
          <br />
        </div>
      );

    return (
      <div>
        <br />
        <Table className="invoice-table commission-report table-new">
          <thead>
            <tr>
              <td className="medium tableTop left">
                {FormattedMessage.createdDate}
              </td>
              <td className="medium tableTop">{FormattedMessage.billDate}</td>
              <td className="medium tableTop">{FormattedMessage.customerID}</td>
              <td className="medium tableTop">
                {FormattedMessage.customerName}
              </td>
              <td className="medium tableTop">
                {FormattedMessage.customerType}
              </td>
              <td className="medium tableTop">
                {FormattedMessage.customerLocation}
              </td>
              <td className="medium tableTop ">{FormattedMessage.agent}</td>
              <td className="medium tableTop">
                {FormattedMessage.monthlyRevenue}
              </td>
              <td className="medium tableTop">{FormattedMessage.currency}</td>
              <td className="medium tableTop">
                {FormattedMessage.minCommitment}
              </td>
            </tr>
          </thead>
          <tbody>
            {listNewCustomers}
            <tr>
              <td className="medium tableTop left" colSpan="9">
                {FormattedMessage.total}
              </td>
              <td className="medium tableTop column-left" colSpan="1">
                +{totals.Count} Customers
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        {this.generateNewTable()}
        <br />
      </Fragment>
    );
  }
}

export default ListComponent;
