/*
  Single.js - Failed SMS Single Component

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import formattedMessages from './FormattedMessages';
import { DateTime } from 'luxon';

// UTIL
import { errorCodes } from '../../../utils/errorCodes';

// FORMATTED MESSAGES
// import formattedMessages from '../FormattedMessages';

// NETWORK
// import FailedRequest from './FailedRequest';
// const failedRequest = new FailedRequest();

export default class FailedSmsSingle extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - sms: The Failed SMS to display information for
        - backButtonClick: Function to go back to List view
    */

    this.state = {};
  }

  /*
    Generates the Lander heading and text section
  */
  generateLander = () => {
    return (
      <div key="ProviderShow">
        <p
          onClick={() => this.props.backButtonClick(false)}
          className="backLink"
        >
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{formattedMessages.backText}</span>
        </p>
      </div>
    );
  };

  /*
    Convenience function to generate Form Groups
  */
  generateFormGroup = (label, displayValue, controlId) => {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={2} className="info-label">
          {label}
        </Form.Label>
        <Col sm={8}>
          <p>{displayValue}</p>
        </Col>
      </Form.Group>
    );
  };

  generateBody = () => {
    let { sms } = this.props;
    // Display sms information
    if (sms) {
      let deliverDate = sms.sms_delivered
        ? DateTime.fromISO(sms.sms_delivered)
        : 'Pending Delivery';
      let failedDate = DateTime.fromISO(sms.sms_sent);
      let rezoned = failedDate.setZone(localStorage.getItem('timeShort'), {
        keepLocalTime: false,
      });
      let smsText;
      try {
        smsText = decodeURIComponent(sms.sms_text);
      } catch (err) {
        smsText = 'ERROR: MALFORMED TEXT';
      }

      return (
        <div>
          <h2>SMS Information</h2>
          <b>{formattedMessages.queuedHeader}</b>
          <br />
          <span>
            {rezoned.toFormat('dd-LLL-yyyy h:mm a') +
              ' ' +
              localStorage.getItem('timeString')}
          </span>
          <br />
          <br />
          <b>{formattedMessages.failedHeader}</b>
          <br />
          <span>
            {sms.sms_delivered
              ? deliverDate
                  .setZone(localStorage.getItem('timeShort'), {
                    keepLocalTime: false,
                  })
                  .toFormat('dd-LLL-yyyy h:mm a')
              : 'Pending Delivery'}
          </span>
          <br />
          <br />
          <b>{formattedMessages.toHeader}</b>
          <br />
          <span>{sms.dest_name || sms.dest_tn || sms.dest_email}</span>
          <br />
          <br />
          <b>{formattedMessages.statusHeader}</b>
          <br />
          <span>{sms.failed_flag_name}</span>
          <br />
          <br />
          {this.generateErrorComponent(sms.error_code_text)}
          <b>{formattedMessages.encodingHeader}</b>
          <br />
          <span>{sms.smsc_params}</span>
          <br />
          <br />
          <b>{formattedMessages.messageHeader}</b>
          <br />
          <span>{smsText}</span>
          <br />
          <br />
        </div>
      );
    }

    return <span> No Data </span>;
  };

  generateErrorComponent = (errorCode) => {
    if (!errorCode || typeof errorCode === 'undefined') return;
    let code = errorCodes(errorCode);
    return (
      <>
        <b>{formattedMessages.errorCodeHeader}</b>
        <br />
        <span>{code.roxCode}</span>
        <br />
        <br />
        <b>{formattedMessages.errorTextHeader}</b>
        <br />
        <span>{code.meaning}</span>
        <br />
        <br />
      </>
    );
  };

  render() {
    return (
      <div className="Home">
        {this.generateLander()}
        {this.generateBody()}
      </div>
    );
  }
}
