/*
  HomeRequest.js - HomeRequest Networking File
  
  Contains networking functionality for Home Component

  Author: Kyle Combeer (2019)
  Company: Virtual Ark
*/

// OUR MODULES
import RequestRabbit from '../RequestRabbit';

// CONFIGURATION
import config from '../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class HomeRequest {
  /*
    Fetches Customer and CustomerUser data
  */
  getUser = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/users/user`;
      const options = {
        method: 'GET',
      };
      // Request
      const response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  getSessionData = async (errorHandler) => {
    try {
      const url = `${config.baseServerURL}/session/data`;
      const options = {
        method: 'GET',
      };
      // Request
      const response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
