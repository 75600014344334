/*
  SentRequest.js - Networking for Sent SMS Items

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// CONFIG
import config from '../../../Config';

// NETWORK
import RequestRabbit from '../../RequestRabbit';
const requestRabbit = new RequestRabbit();

export default class SentRequest {
  /*
    Gets Sent Items based on search criteria (if provided)
  */
  searchSentItems = async (searchObject, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sentsms/`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      console.log('searchObject in searchSentItems: ', searchObject);
      const options = {
        method: 'POST',
        body: JSON.stringify(searchObject) || null,
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };

  /*
     Handles the click for "Cancel Pending Message" button.
  */
  cancelPendingMessage = async (smsId, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sentsms/cancel`;
      const headers = { 'Content-Type': 'application/json' };
      const body = JSON.stringify({
        smsId: smsId,
      });
      const options = { method: 'POST', body: body, headers: headers };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };

  /*
     Handles the click for "Cancel All Pending Messages" button.
  */
  cancelAllPendingMessages = async (smsIdList, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sentsms/cancelall`;
      const headers = { 'Content-Type': 'application/json' };
      const body = JSON.stringify({
        smsIdList,
      });
      const options = { method: 'POST', body: body, headers: headers };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
    }
  };
}
