import { FormattedMessage } from 'react-intl';
import React from 'react';

const idPrefix = 'LoginIndex';

const FormattedMessages = {
  emailText: (
    <FormattedMessage
      id={`${idPrefix}.EmailLabel`}
      defaultMessage="Email"
    ></FormattedMessage>
  ),
  passwordText: (
    <FormattedMessage
      id={`${idPrefix}.PasswordLabel`}
      defaultMessage="Password"
    ></FormattedMessage>
  ),
  loginText: (
    <FormattedMessage
      id={`${idPrefix}.LoginButton`}
      defaultMessage="Login"
    ></FormattedMessage>
  ),
  loginLoadingText: (
    <FormattedMessage
      id={`${idPrefix}.LoginLoadingText`}
      defaultMessage="Logging in..."
    ></FormattedMessage>
  ),
  signUpText: (
    <FormattedMessage
      id={`${idPrefix}.SignupText`}
      defaultMessage={"Don't have an account? "}
    ></FormattedMessage>
  ),
  registerText: (
    <FormattedMessage
      id={`${idPrefix}.RegisterLink`}
      defaultMessage="Register here!"
    ></FormattedMessage>
  ),
  forgotPassword: (
    <FormattedMessage
      id={`${idPrefix}.forgetPasswordLink`}
      defaultMessage="Forgot Password?"
    ></FormattedMessage>
  ),
};

export default FormattedMessages;
