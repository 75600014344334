import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  commissionReport: (
    <FormattedMessage
      id="CommissionReport.commissionReport"
      defaultMessage="Commission Report"
    ></FormattedMessage>
  ),
  newCustomerReport: (
    <FormattedMessage
      id="NewCustomerReport.NewCustomerReport"
      defaultMessage="New Customer Report"
    ></FormattedMessage>
  ),
  activityReport: (
    <FormattedMessage
      id="CommissionReport.activityReport"
      defaultMessage="Activity Report"
    ></FormattedMessage>
  ),
  showReport: (
    <FormattedMessage
      id="CommissionReport.showReport"
      defaultMessage="Show Report"
    ></FormattedMessage>
  ),
  newCustomers: (
    <FormattedMessage
      id="CommissionReport.newCustomers"
      defaultMessage="New Customers"
    ></FormattedMessage>
  ),
  createdDate: (
    <FormattedMessage
      id="CommissionReport.createdDate"
      defaultMessage="Created Date"
    ></FormattedMessage>
  ),
  status: (
    <FormattedMessage
      id="CommissionReport.status"
      defaultMessage="Status"
    ></FormattedMessage>
  ),
  date: (
    <FormattedMessage
      id="CommissionReport.date"
      defaultMessage="Status Date"
    ></FormattedMessage>
  ),
  customerID: (
    <FormattedMessage
      id="CommissionReport.customerID"
      defaultMessage="ID"
    ></FormattedMessage>
  ),
  customerName: (
    <FormattedMessage
      id="CommissionReport.customerName"
      defaultMessage="Name"
    ></FormattedMessage>
  ),
  customerType: (
    <FormattedMessage
      id="CommissionReport.customerType"
      defaultMessage="Type"
    ></FormattedMessage>
  ),
  customerLocation: (
    <FormattedMessage
      id="CommissionReport.customerLocation"
      defaultMessage="Location"
    ></FormattedMessage>
  ),
  alreadyBilled: (
    <FormattedMessage
      id="CommissionReport.alreadyBilled"
      defaultMessage="Already Billed"
    ></FormattedMessage>
  ),
  sales: (
    <FormattedMessage
      id="CommissionReport.Sales"
      defaultMessage="Sales"
    ></FormattedMessage>
  ),
  agent: (
    <FormattedMessage
      id="CommissionReport.Agent"
      defaultMessage="Agent"
    ></FormattedMessage>
  ),
  minCommitment: (
    <FormattedMessage
      id="CommissionReport.MinimumCommitment"
      defaultMessage="Minimum Commitment"
    ></FormattedMessage>
  ),
  cancelledCustomers: (
    <FormattedMessage
      id="CommissionReport.CancelledCustomers"
      defaultMessage="Cancelled Customers"
    ></FormattedMessage>
  ),
  total: (
    <FormattedMessage
      id="CommissionReport.Total"
      defaultMessage="Total"
    ></FormattedMessage>
  ),
  back: (
    <FormattedMessage
      id="Global.BackText"
      defaultMessage="back"
    ></FormattedMessage>
  ),
  January: (
    <FormattedMessage
      id="CommissionReport.January"
      defaultMessage="January"
    ></FormattedMessage>
  ),
  February: (
    <FormattedMessage
      id="CommissionReport.February"
      defaultMessage="February"
    ></FormattedMessage>
  ),
  March: (
    <FormattedMessage
      id="CommissionReport.March"
      defaultMessage="March"
    ></FormattedMessage>
  ),
  April: (
    <FormattedMessage
      id="CommissionReport.April"
      defaultMessage="April"
    ></FormattedMessage>
  ),
  May: (
    <FormattedMessage
      id="CommissionReport.May"
      defaultMessage="May"
    ></FormattedMessage>
  ),
  June: <FormattedMessage id="CommissionReport.June" defaultMessage="June" />,
  July: (
    <FormattedMessage
      id="CommissionReport.July"
      defaultMessage="July"
    ></FormattedMessage>
  ),
  August: (
    <FormattedMessage
      id="CommissionReport.August"
      defaultMessage="August"
    ></FormattedMessage>
  ),
  September: (
    <FormattedMessage
      id="CommissionReport.September"
      defaultMessage="September"
    ></FormattedMessage>
  ),
  October: (
    <FormattedMessage
      id="CommissionReport.October"
      defaultMessage="October"
    ></FormattedMessage>
  ),
  November: (
    <FormattedMessage
      id="CommissionReport.November"
      defaultMessage="November"
    ></FormattedMessage>
  ),
  December: (
    <FormattedMessage
      id="CommissionReport.December"
      defaultMessage="December"
    ></FormattedMessage>
  ),
  loading: <FormattedMessage id="Global.Loading" defaultMessage="Loading..." />,
  cancelled: (
    <FormattedMessage id="Global.cancelled" defaultMessage="Cancelled Users" />
  ),
};

export default FormattedMessages;
