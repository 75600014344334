/*
  Single.js - create invoice adjustment
*/

import React, { Component, Fragment } from 'react';
import formattedMessages from '../FormattedMessages';
import { Button, Form, Col, Row } from 'react-bootstrap';
import ErrorText from '../../../components/ErrorText';
import CustomTooltip from '../../../components/CustomTooltip';
import { multiply } from '../../../utils/maths';

// NETWORKING
import InvoiceRequest from './InvoiceRequest';
const invoiceRequests = new InvoiceRequest();

export default class InvoiceAdjustment extends Component {
  constructor(props) {
    super(props);
    console.log('adjustment prop', this.props);
    this.state = {
      thisInvoice: false,
      edits: {},
      isSaving: false,
      isRefund: false,
      hasError: false,
      errorText: '',
      defaultQuantity: 1,
      salesTaxFlag: null,
      taxRate: null,
      customerType: null,
      revenue: '',
      tax: '',
      creditCardSurcharge: '',
      total: '',
      refundTotal: 0,
      invoiceTotal: '',
      loading: true,
      defaultRefundRevenue: null,
      stripeRate: null,
    };
  }

  componentDidMount = async () => {
    let { bill, errorHandler } = this.props;
    console.log('adjustment for bill:', bill);
    if (bill && bill !== null) {
      let {
        sales_tax_flag,
        taxRate,
        customer_type,
        refundsForBill,
        stripeRate,
      } =
        (await invoiceRequests.getExtraData(
          bill.customer_id,
          bill.bill_id,
          errorHandler
        )) || {};
      console.log(
        'extra data',
        sales_tax_flag,
        taxRate,
        customer_type,
        refundsForBill,
        stripeRate
      );
      let invoiceTotal = this.roundPricing(this.props.bill.amount, 2);

      let refundTotal = '';
      for (let i = 0; i < refundsForBill.length; i++) {
        refundTotal =
          Number(refundsForBill[i].charge_amount) + Number(refundTotal);
      }
      refundTotal = this.roundPricing(refundTotal, 2);
      this.setState({
        stripeRate: stripeRate,
        salesTaxFlag: sales_tax_flag,
        customerType: customer_type,
        taxRate,
        refundsForBill,
        invoiceTotal: invoiceTotal,
        refundTotal: refundTotal ? refundTotal : 0,
        loading: false,
      });
    }
  };

  handleToggle = (event) => {
    const value = event.target.value;
    if (value === 'refund') {
      // Calculate a default value for the refund based on what is remaining
      const { invoiceTotal, refundTotal, taxRate, customerType } = this.state;
      const total = invoiceTotal + refundTotal;
      let revenue;
      if (customerType === 'A') {
        // post-paid (revenue is tax-exclusive) so the tax has to be added on after.
        revenue = this.roundPricing(total / (1 + taxRate));
      } else if (customerType === 'B') {
        // pre-paid (revenue is tax-inclusive) so the tax is included in the revenue.
        revenue = this.roundPricing(total);
      }
      revenue = -revenue;
      return this.setState(
        {
          isRefund: true,
          thisInvoice: false,
          hasError: false,
          defaultRefundRevenue: revenue,
          edits: {
            charge_quantity: 1,
            general_desc: this.state.edits.general_desc,
            rate: revenue,
          },
        },
        () =>
          this.handleChange({
            target: {
              name: 'rate',
              value: revenue,
            },
          })
      );
    } else {
      this.setState({
        revenue: '',
        tax: '',
        total: '',
        creditCardSurcharge: '',
        edits: {
          charge_quantity: 1,
          general_desc: this.state.edits.general_desc,
        },
      });
    }
    return this.setState({
      thisInvoice: value === 'true' ? false : true,
      isRefund: false,
      hasError: false,
    });
  };

  /*
    Handles the change action for a toggle
  */
  handleChange = (event) => {
    let { edits } = this.state;
    const { name, value } = event.target;

    edits[name] = value;
    console.log('edits', edits);
    //Do the calculation of revenue, tax and total with edit value
    let { revenue, total, tax, creditCardSurcharge } = this.calculateRevenue(
      edits.rate,
      edits.charge_quantity
    );

    return this.setState({
      edits: edits,
      revenue: revenue,
      total: total,
      tax: tax,
      creditCardSurcharge: creditCardSurcharge,
    });
  };

  validateNumber = (value, int) => {
    let regExPattern;

    // If expecting number to be an integer, not a decimal
    if (int) {
      if (!value || value === '') return false;
      // regex pattern matches against all positive whole numbers
      regExPattern = /^[1-9][0-9]*$/;

      // Else expecting a decimal
    } else {
      if (!value || value === '') return false;
      // regex pattern matches against for all numbers and decimal points (i think)
      regExPattern = /^[+-]?\d+(\.\d+)?$/;
    }
    return regExPattern.test(value);
  };

  validateRefundAmount = () => {
    let { salesTaxFlag, taxRate, total, revenue, refundTotal, invoiceTotal } =
      this.state;

    if (salesTaxFlag === 'Y' && taxRate > 0) {
      if (Math.abs(total + refundTotal) > invoiceTotal) return false;
    } else if (Math.abs(revenue + refundTotal) > invoiceTotal) {
      return false;
    }
    return true;
  };

  validateForm = (edits) => {
    let { isRefund } = this.state;

    let hasError = false;
    let errorText = '';
    if (!edits.general_desc || typeof edits.general_desc === 'undefined') {
      hasError = true;
      errorText = 'Requires a charge description.';
    } else if (!this.validateNumber(edits.charge_quantity, true)) {
      hasError = true;
      errorText = 'Quantity must be a positive number.';
    } else if (!this.validateNumber(edits.rate, false)) {
      hasError = true;
      errorText = 'Rate must be a non-zero number.';
    } else if (!isRefund && edits.rate < 0) {
      hasError = true;
      errorText = 'Adjustment rate must be a positive number.';
    } else if (isRefund && edits.rate >= 0) {
      hasError = true;
      errorText = 'Refund rate must be a negative number.';
    } else if (isRefund && !this.validateRefundAmount()) {
      hasError = true;
      errorText =
        'Invalid refund amount. The requested refund exceeds the refundable amount for this invoice';
    }
    return {
      hasError: hasError,
      errorText: errorText,
    };
  };

  handleSubmit = async () => {
    this.setState({ isSaving: true, hasError: false, errorText: '' });
    let { bill, errorHandler } = this.props;
    let { edits, defaultQuantity, revenue, thisInvoice } = this.state;

    if (!edits.charge_quantity && typeof edits.charge_quantity === 'undefined')
      edits.charge_quantity = defaultQuantity;

    let { hasError, errorText } = this.validateForm(edits);

    if (!hasError) {
      edits.charge_quantity = Number(edits.charge_quantity);
      edits.rate = Number(edits.rate);
      edits.charge_amount = Number(revenue);
      edits.applyToNextInvoice = thisInvoice;

      await invoiceRequests.createInvoiceAdjustment(
        bill.customer_id,
        bill.bill_id,
        edits,
        errorHandler
      );
      this.props.backButton(true);
    } else {
      this.setState({
        hasError: hasError,
        errorText: errorText,
        isSaving: false,
      });
    }
  };

  /*
    Renders Error Text Component
  */
  generateErrorText = () => {
    return (
      <ErrorText
        hasError={this.state.hasError}
        errorText={this.state.errorText}
      />
    );
  };

  /*
     Rounds pricing accurately to n decimal places.
   */
  roundPricing = (value, precision) => {
    if (!precision || typeof precision === 'undefined') precision = 2;
    let newValue = +(
      Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision)
    ).toFixed(precision);
    return newValue;
  };

  /*
    Calculate revenue, tax and total based on given rate and quantity
  */
  calculateRevenue = (rate, quantity) => {
    let { taxRate, customerType, defaultQuantity, stripeRate } = this.state;
    let revenue = '';
    let total = '';
    let tax = '';
    let creditCardSurcharge = '';
    if (!quantity && typeof quantity === 'undefined') {
      quantity = defaultQuantity;
    }

    if (rate && quantity) {
      revenue = this.roundPricing(rate * quantity, 2);
      tax = this.roundPricing(revenue * taxRate, 2);
      if (customerType === 'A') {
        // post-paid (revenue is tax-exclusive) so the tax has to be added on after.
        total = this.roundPricing(revenue + tax, 2);
      } else if (customerType === 'B') {
        // pre-paid (revenue is tax-inclusive) so the tax is included in the revenue.
        total = revenue;
      }
    }

    // Adds the credit card surcharge to the estimate
    if (stripeRate && total > 0) {
      creditCardSurcharge = this.roundPricing(multiply(total, stripeRate), 2);
      total = this.roundPricing(total + creditCardSurcharge, 2);
    }

    return {
      revenue: revenue,
      total: total,
      tax: tax,
      creditCardSurcharge: creditCardSurcharge,
    };
  };

  generateGSTDisplay = () => {
    let {
      salesTaxFlag,
      taxRate,
      total,
      tax,
      creditCardSurcharge,
      isRefund,
      stripeRate,
    } = this.state;
    let gstDisplay = (
      <div>
        <td style={{ width: '200px' }}>
          <Form.Label className="bold">{formattedMessages.tax}</Form.Label>
        </td>
        <td>
          <div>
            <Form.Control type="text" name="tax" value={tax} readOnly />
          </div>
        </td>
      </div>
    );
    let totalDisplay = (
      <div>
        <td style={{ width: '200px' }}>
          <Form.Label className="bold">
            {formattedMessages.total}
            <CustomTooltip
              tooltipText={
                'The total also includes the stripe credit card surcharge for Business CC customers'
              }
              margin="5px"
              className="tooltip-Record"
            />{' '}
            :
          </Form.Label>
        </td>
        <td>
          <div>
            <Form.Control type="text" name="total" value={total} readOnly />
          </div>
        </td>
      </div>
    );
    let surchargeDisplay = (
      <div>
        <td style={{ width: '200px' }}>
          <Form.Label className="bold">Credit Card Surcharge:</Form.Label>
        </td>
        <td>
          <div>
            <Form.Control
              type="text"
              name="tax"
              value={creditCardSurcharge}
              readOnly
            />
          </div>
        </td>
      </div>
    );
    if (salesTaxFlag === 'Y' && taxRate > 0) {
      return (
        <div>
          {gstDisplay}
          {!isRefund && stripeRate !== 0 && surchargeDisplay}
          {totalDisplay}
        </div>
      );
    }
    if (!isRefund && stripeRate !== 0) {
      return (
        <div>
          {surchargeDisplay}
          {totalDisplay}
        </div>
      );
    }
    return;
  };

  generateRefundDisplay = () => {
    let { refundTotal, invoiceTotal } = this.state;
    let { bill } = this.props;

    return (
      <div>
        <Form.Group as={Row} controlId="invoice_date">
          <Form.Label className="bold col-sm-4-adjust">
            {formattedMessages.invoiceTotal}
          </Form.Label>
          <Col>{`${invoiceTotal} ${bill.plan_currency}`}</Col>
        </Form.Group>
        <Form.Group as={Row} controlId="invoice_date">
          <Form.Label className="bold col-sm-4-adjust">
            {formattedMessages.refundTotal}
          </Form.Label>
          <Col>{`${refundTotal} ${bill.plan_currency}`}</Col>
        </Form.Group>
      </div>
    );
  };

  generateBody = () => {
    let { bill } = this.props;
    let { revenue, defaultQuantity, isRefund, edits } = this.state;
    return (
      <div className="Home">
        <div className="lander">
          <p
            onClick={() => {
              this.props.backButton();
            }}
            className="backLink"
          >
            <i className="material-icons">keyboard_arrow_left</i>
            <span>{formattedMessages.backText}</span>
          </p>
          <h3>{formattedMessages.createInvoiceAdjustment}</h3>
          <br />
          <Form.Group as={Row} controlId="customer_name">
            <Form.Label className="bold col-sm-4-adjust">
              {formattedMessages.customerName}
            </Form.Label>
            <Col>{`${bill.customer_name}`}</Col>
          </Form.Group>
          <Form.Group as={Row} controlId="account_no">
            <Form.Label className="bold col-sm-4-adjust">
              {formattedMessages.customerAccountNo}
            </Form.Label>
            <Col>{`${bill.account_no}`}</Col>
          </Form.Group>
          <Form.Group as={Row} controlId="invoice_number">
            <Form.Label className="bold col-sm-4-adjust">
              {formattedMessages.invoiceNumber}
            </Form.Label>
            <Col>{`${bill.invoice_number}`}</Col>
          </Form.Group>
          <Form.Group as={Row} controlId="invoice_date">
            <Form.Label className="bold col-sm-4-adjust">
              {formattedMessages.invoiceDueDate}
            </Form.Label>
            <Col>{`${bill.invoice_date}`}</Col>
          </Form.Group>
          {this.generateRefundDisplay()}
        </div>
        <Form.Group as={Row}>
          <Col sm={8}>
            <Form.Check
              inline
              label={formattedMessages.thisInvoice}
              name="listToggle"
              value={true}
              onChange={this.handleToggle}
              type="radio"
              defaultChecked
            />
            {this.state.customerType === 'A' && (
              <Form.Check
                inline
                label={formattedMessages.nextInvoice}
                name="listToggle"
                value={false}
                onChange={this.handleToggle}
                type="radio"
              />
            )}
            {bill.billing_status.toLowerCase() === 'paid' && (
              <Form.Check
                inline
                label={formattedMessages.refundNow}
                name="listToggle"
                value={'refund'}
                onChange={this.handleToggle}
                type="radio"
              />
            )}
          </Col>
        </Form.Group>

        <div>
          <Form onChange={this.handleChange}>
            <table className="no-table-row-background">
              <tbody className="filter-table">
                <div className="lander">
                  <div>
                    <td style={{ width: '200px' }}>
                      <Form.Label className="bold">
                        {formattedMessages.charge}
                      </Form.Label>
                    </td>
                    <td>
                      <div>
                        <Form.Control type="text" name="general_desc" />
                      </div>
                    </td>
                  </div>
                  {!isRefund && (
                    <div>
                      <td style={{ width: '200px' }}>
                        <Form.Label className="bold">
                          {formattedMessages.quantity}
                        </Form.Label>
                      </td>
                      <td>
                        <div>
                          <Form.Control
                            type="text"
                            name="charge_quantity"
                            defaultValue={defaultQuantity}
                          />
                        </div>
                      </td>
                    </div>
                  )}
                  <div>
                    <td style={{ width: '200px' }}>
                      <Form.Label className="bold">
                        {formattedMessages.rate}
                      </Form.Label>
                    </td>
                    <td>
                      <div>
                        <Form.Control
                          type="text"
                          name="rate"
                          value={edits.rate || ''}
                        />
                      </div>
                    </td>
                  </div>
                  <div>
                    <td style={{ width: '200px' }}>
                      <Form.Label className="bold">
                        {formattedMessages.revenue}
                      </Form.Label>
                    </td>
                    <td>
                      <div>
                        <Form.Control
                          type="text"
                          name="charge_amount"
                          value={revenue}
                          readOnly
                        />
                      </div>
                    </td>
                  </div>
                  {this.generateGSTDisplay()}
                </div>
              </tbody>
            </table>
          </Form>

          <br></br>
        </div>
      </div>
    );
  };

  generateFormButtons = () => {
    if (this.state.isSaving) return <p>{formattedMessages.saving}</p>;

    return (
      <Fragment>
        <Button
          type="submit"
          className="maxHeight"
          variant="primary"
          onClick={() => this.handleSubmit()}
        >
          {formattedMessages.save}
        </Button>
        <Button
          type="submit"
          className="maxHeight"
          variant="primary"
          onClick={() => {
            this.props.backButton();
          }}
        >
          {formattedMessages.cancel}
        </Button>
      </Fragment>
    );
  };

  render() {
    if (this.state.loading) return 'Loading...';

    return (
      <div className="Home">
        {this.generateBody()}
        {this.generateFormButtons()}
        {this.generateErrorText()}
      </div>
    );
  }
}
