import React from 'react';

const ListItem = ({
  id,
  name = '',
  userCount = 0,
  Direct = { sent: 0, parts: 0 },
  Standard = { sent: 0, parts: 0 },
  Replies = { sent: 0, parts: 0 },
  Sent = { sent: 0, parts: 0 },
  Pending = { sent: 0, parts: 0 },
  onClickName = null,
}) => (
  <tr key={'list' + id} id={id} className="">
    <td className="medium left" name="name">
      <span className="blue" onClick={() => onClickName(id)}>
        {name}
      </span>
    </td>
    <td className="medium" name="userCount">
      <span>{userCount}</span>
    </td>
    <td className="medium column-left" name="Direct">
      {Direct.sent + ` (${Direct.parts})`}
    </td>
    <td className="medium" name="Standard">
      {Standard.sent + ` (${Standard.parts})`}
    </td>
    <td className="medium column-right" name="Replies">
      {Replies.sent + ` (${Replies.parts})`}
    </td>
    <td className="medium" name="Sent">
      {Sent.sent + ` (${Sent.parts})`}
    </td>
    <td className="medium column-right" name="Pending">
      {Pending.sent + ` (${Pending.parts})`}
    </td>
  </tr>
);

export default ListItem;
