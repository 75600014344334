/*
  InboxRequests.js -  InboxRequests Networking File

  Contains networking functionality for  Inbox Component

  Author: Elle Dunbar (2020)
  Company: Virtual Ark
*/

// UTILS
import RequestRabbit from '../../RequestRabbit';

// CONFIG
import config from '../../../Config';

// INSTANTIATE
const requestRabbit = new RequestRabbit();

export default class InboxRequests {
  /*
    Fetches sent data
  */
  getInbox = async (fromDate, toDate, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sms/replies/?from_date=${fromDate}&to_date=${toDate}&type=SMS`;
      const options = { method: 'GET' };
      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
      return [];
    }
  };

  /*
    Gets Sent Items based on search criteria (if provided)
  */
  searchInboxItems = async (searchObject, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sms/replies/`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      console.log('searchObject in searchSentItems: ', searchObject);
      const options = {
        method: 'POST',
        body: JSON.stringify(searchObject) || null,
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };

  /**
   * Gets data for a single inbox reply
   * @param {Number} replyId an email reply id or sms reply id
   * @param {Boolean} isEmailReply distinguish whether its an email reply or sms reply
   * @param {Function} errorHandler the error handler
   * @returns Reply data for a single reply as an object
   */
  getInboxReply = async (replyId, isEmailReply, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/sms/replies/${replyId}?isEmailReply=${isEmailReply}`;
      const options = { method: 'GET' };
      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e.message);
      return [];
    }
  };
}
