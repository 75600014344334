import React from 'react';
import CommentEdit from '../../CommentEdit';

const ListItem = ({
  commentId,
  customerId,
  createdDate = '',
  contactName = '',
  comment = '',
  onClickEdit = null,
  onClickDelete = null,
  formatDate = null,
  toggleEditState = null,
  updateComment = null,
  item = {},
  ...props
}) => (
  <tr key={'list' + commentId} id={commentId} className="">
    <td className="medium textLeft" name="createdDate">
      <span>{formatDate(createdDate)}</span>
    </td>
    <td className="medium textLeft" name="contactName">
      <span>{contactName}</span>
    </td>
    <CommentEdit
      toggleEditState={onClickEdit}
      updateComment={updateComment}
      item={item}
      comment={comment}
      onClickDelete={onClickDelete}
      commentId={commentId}
    />
  </tr>
);

export default ListItem;
