/*
  SingleInvoice.js - Component to handle viewing a Single Invoice

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { useEffect, useState, Fragment } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

// FORMATTED MESSAGES
import formattedMessages from '../FormattedMessages';

// IMGS
import roxLogo from '../../../../images/Logo-RO4.gif';

// NETWORKING
import InvoiceRequest from '../../Invoices/InvoiceRequest';
const invoiceRequests = new InvoiceRequest();

const SingleInvoice = (props) => {
  /*
    EXPECTED PROPS
      - invoice: Bill object
  */

  //const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState(true);
  console.log(invoice); // TODO: make code use state as a fallback

  const { customer, tab } = useParams();

  useEffect(() => {
    async function getData() {
      console.log('invoice data:', props?.invoice);
      if (!props.invoice && customer && tab) {
        let singleInvoiceData = await invoiceRequests.getSingleInvoice(
          customer,
          tab,
          props.errorHandler
        );
        setInvoice(singleInvoiceData);
      }
    }

    getData();
  }, [props, customer, tab]);

  /*
    Generates an Invoice Header
  */
  const generateInvoiceHeader = () => {
    let billerAddress = '';

    if (props.invoice) billerAddress = props.invoice.billerAddress;

    return (
      <div className="invoice-header-flex">
        <div>
          <img src={roxLogo} alt="Red Oxygen Logo" />
        </div>
        <div>
          <div className="biller-address">
            <h2>{formattedMessages.taxInvoice}</h2>
            <p dangerouslySetInnerHTML={{ __html: billerAddress }}></p>
          </div>
        </div>
      </div>
    );
  };

  const generateCustomerHeader = () => {
    let attentionTo = 'Admin';
    let customerName = '';

    // If have customer name, set as Attn and Customer
    if (
      props.invoice &&
      props.invoice.address_to &&
      props.invoice.address_to.customer_name
    ) {
      attentionTo = props.invoice.address_to.customer_name;
      customerName = props.invoice.address_to.customer_name;
    }
    // If have contact name, set this as Attn instead
    if (
      props.invoice &&
      props.invoice.address_to &&
      props.invoice.address_to.contact_name
    ) {
      attentionTo = props.invoice.address_to.contact_name;
    }

    return (
      <div className="pad-medium float-left">
        {formattedMessages.attention}: {attentionTo}
        <br />
        <b>{customerName}</b>
      </div>
    );
  };

  /*
    Generate the Dates for the Invoice
  */
  const generateInvoiceDates = () => {
    let invoice = {};
    if (props.invoice) invoice = props.invoice;
    return (
      <div className="float-right pad-medium">
        <table width="400px">
          <tbody>
            <tr>
              <td>{formattedMessages.invoicedDate}:</td>
              <td>{invoice.bill_dates ? invoice.bill_dates.bill_date : ''}</td>
            </tr>
            <tr>
              <td>{formattedMessages.yourRef}:</td>
              <td>{invoice.your_ref || '-'}</td>
            </tr>
            <tr>
              <td>{formattedMessages.invoiceNo}:</td>
              <td>{invoice.invoice_no}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  /*
    Generate the Currency Type for the Invoice
  */
  const generateInvoiceCurrency = () => {
    let { invoice } = props;
    let currency = 'AUD'; // Default to AUD currency
    if (invoice && invoice.currency_type) currency = invoice.currency_type;

    return (
      <div className="invoice-header-box">
        <div className="float-right pad-medium">
          <b>
            {currency} {invoice.currency_symbol}
          </b>
        </div>
      </div>
    );
  };

  const generateInvoiceBillPeriods = () => {
    let startDate = '',
      endDate = '';
    let { invoice } = props;

    if (invoice && invoice.bill_dates) {
      startDate = moment(invoice.bill_dates.start_date).format('DD MMM YYYY');
      endDate = moment(invoice.bill_dates.end_date).format('DD MMM YYYY');
    }

    if (startDate === endDate) {
      // prepaid bills don't have a range.  They just happen.
      return (
        <div className="invoice-header-box">
          <div className="float-left pad-medium">
            <b>
              {formattedMessages.prepaidBillDate} {startDate}
            </b>
          </div>
        </div>
      );
    }

    return (
      <div className="invoice-header-box">
        <div className="float-left pad-medium">
          <b>
            {formattedMessages.licenseAndMessagesFor} {startDate}{' '}
            {formattedMessages.to} {endDate}
          </b>
        </div>
      </div>
    );
  };

  const generateSoftwareLicenseFees = () => {
    let { invoice } = props;
    let html = null;

    if (
      invoice &&
      invoice.software_license_fees &&
      invoice.software_license_fees.length !== 0
    ) {
      html = invoice.software_license_fees.map((fee) => {
        let currency_prefix = invoice.currency_symbol;
        let fee_rate_display = Math.abs(fee.rate).toFixed(4);
        if (fee.rate < 0) {
          currency_prefix = '-' + currency_prefix;
        }
        return (
          <tr key={invoice.software_license_fees.indexOf(fee)}>
            <td className="pad-table-left-big pad-table-right">
              {fee.translation || fee.revenue_type}
            </td>
            <td></td>
            <td>{fee.quantity}</td>
            <td>@</td>
            <td>
              {currency_prefix}
              {fee_rate_display}
            </td>
            <td align="right" style={{ width: '150px' }}>
              <b>{fee.revenue}</b>
            </td>
          </tr>
        );
      });

      return (
        <Fragment>
          <tr>
            <td className="pad-table-left pad-table-right">
              {formattedMessages.softwareLicenseFees}
            </td>
          </tr>
          {html}
        </Fragment>
      );
    }
    return null;
  };

  const generateSetupAndTxtMeFees = () => {
    let { invoice } = props;
    if (
      invoice &&
      invoice.setup_and_textme_fees &&
      invoice.setup_and_textme_fees.length !== 0
    ) {
      let html = invoice.setup_and_textme_fees.map((fee) => {
        return (
          <tr key={invoice.setup_and_textme_fees.indexOf(fee)}>
            <td width="40%" className="pad-table-left pad-table-right">
              {fee.translation || fee.revenue_type}
            </td>
            <td></td>
            <td>{fee.quantity}</td>
            <td>@</td>
            <td>
              {invoice.currency_symbol}
              {fee.rate}
            </td>
            <td align="right">
              <b>{fee.revenue}</b>
            </td>
          </tr>
        );
      });
      return <Fragment>{html}</Fragment>;
    }
    return null;
  };

  // text_message_fees
  const generatePlanFees = () => {
    let { invoice } = props;
    let html = null;
    if (
      invoice &&
      invoice.red_oxygen_plan_fees &&
      invoice.red_oxygen_plan_fees.length !== 0
    ) {
      html = invoice.red_oxygen_plan_fees.map((fee) => {
        return (
          <tr key={invoice.red_oxygen_plan_fees.indexOf(fee)}>
            <td className="pad-table-left-big pad-table-right">
              {fee.translation || fee.revenue_type}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td align="right" style={{ width: '150px' }}>
              <b>{fee.revenue}</b>
            </td>
          </tr>
        );
      });
      return html;
    }
    return null;
  };

  const generateMessageFees = () => {
    let { invoice } = props;
    let html = null;

    let planFees = generatePlanFees();

    if (
      invoice &&
      ((invoice.text_message_fees && invoice.text_message_fees.length !== 0) ||
        planFees)
    ) {
      html = invoice.text_message_fees.map((fee) => {
        let currency_prefix = invoice.currency_symbol;
        let fee_rate_display = Math.abs(fee.rate).toFixed(4);
        if (fee.rate < 0) {
          currency_prefix = '-' + currency_prefix;
        }
        return (
          <tr key={invoice.text_message_fees.indexOf(fee)}>
            <td className="pad-table-left-big pad-table-right">
              {fee.translation || fee.revenue_type}
            </td>
            <td></td>
            <td>{fee.quantity}</td>
            <td>@</td>
            <td>
              {currency_prefix}
              {fee_rate_display}
            </td>
            <td align="right" style={{ width: '150px' }}>
              <b>{fee.revenue}</b>
            </td>
          </tr>
        );
      });

      return (
        <Fragment>
          <tr>
            <td className="pad-table-left pad-table-right">
              {formattedMessages.messageFees}
            </td>
          </tr>
          {planFees}
          {html}
        </Fragment>
      );
    }
    return null;
  };

  const generateRebates = () => {
    let { invoice } = props;
    let html = null;
    if (invoice && invoice.rebates && invoice.rebates.length !== 0) {
      html = invoice.rebates.map((fee) => {
        let currency_prefix = invoice.currency_symbol;
        let fee_rate_display = Math.abs(fee.rate).toFixed(4);
        if (fee.rate < 0) {
          currency_prefix = '-' + currency_prefix;
        }
        return (
          <tr key={invoice.rebates.indexOf(fee)}>
            <td className="pad-table-left-big pad-table-right">
              {fee.translation || fee.revenue_type}
            </td>
            <td></td>
            <td>{fee.quantity}</td>
            <td>@</td>
            <td>
              {currency_prefix}
              {fee_rate_display}
            </td>
            <td align="right" style={{ width: '150px' }}>
              <b>{fee.revenue}</b>
            </td>
          </tr>
        );
      });
      return html;
    }
    return null; // Return blank
  };

  const generateBillAdjustments = () => {
    let { invoice } = props;
    let html = null;

    if (
      invoice &&
      invoice.bill_adjustments &&
      invoice.bill_adjustments.length !== 0
    ) {
      html = invoice.bill_adjustments.map((fee) => {
        let currency_prefix = invoice.currency_symbol;
        let fee_rate_display = Math.abs(fee.rate).toFixed(4);
        if (fee.rate < 0) {
          currency_prefix = '-' + currency_prefix;
        }
        return (
          <tr key={invoice.bill_adjustments.indexOf(fee)}>
            <td className="pad-table-left pad-table-right">
              {fee.translation || fee.revenue_type} - {fee.bill_desc}
            </td>
            <td></td>
            <td>{fee.quantity}</td>
            <td>@</td>
            <td>
              {currency_prefix}
              {fee_rate_display}
            </td>
            <td align="right">
              <b>{fee.revenue}</b>
            </td>
          </tr>
        );
      });
      return html;
    }
    return null; // Return blank
  };

  const generateTotalCharges = () => {
    const { invoice } = props;
    if (!invoice) return;

    const { credit_card_surcharge, country, currency_symbol } = invoice;
    const { inc_gst, exc_gst, gst } = invoice.bill_totals;
    const total_display = Math.abs(inc_gst).toFixed(2);

    // If the total is a negative, add a minus before the currency symbol.
    let currency_prefix = currency_symbol;
    if (inc_gst < 0) currency_prefix = '-' + currency_prefix;

    let creditCardCharge;
    if (credit_card_surcharge)
      creditCardCharge = (
        <tr>
          <td width="40%" className="pad-table-left pad-table-right">
            {formattedMessages.creditCardSurcharge}
          </td>
          <td colSpan="4"></td>
          <td colSpan="1" align="right" className="payment-cell-thin">
            {credit_card_surcharge.revenue}
          </td>
        </tr>
      );

    return (
      <Fragment>
        <tr>
          <td width="40%" className="pad-table-left pad-table-right">
            {formattedMessages.totalChargesExcludingTax}
          </td>
          <td colSpan="4"></td>
          <td
            colSpan="1"
            align="right"
            style={{ borderTop: '1px solid black' }}
          >
            {exc_gst}
          </td>
        </tr>
        <tr>
          <td width="40%" className="pad-table-left pad-table-right">
            {country === 'Australia'
              ? formattedMessages.gst
              : formattedMessages.tax}
          </td>
          <td colSpan="4"></td>
          <td
            colSpan="1"
            align="right"
            className={credit_card_surcharge ? '' : 'payment-cell-thick'}
          >
            {gst}
          </td>
        </tr>
        {creditCardCharge}
        <tr>
          <td width="40%" className="pad-table-left pad-table-right">
            <b>{formattedMessages.totalCharges}</b>
          </td>
          <td colSpan="4"></td>
          <td colSpan="1" align="right" className="payment-cell-thick">
            <b>
              {currency_prefix}
              {total_display}
            </b>
          </td>
        </tr>
      </Fragment>
    );
  };

  const generatePayment = () => {
    const { invoice } = props;
    const { successfulPayment, failedPayments } = invoice;

    let html = [];

    // Has a payment
    if (successfulPayment) {
      const { paid_date, paid_amount, cc_name, cc_number_suffix } =
        successfulPayment;

      const paymentText = (
        <b>
          {paid_date} {'(UTC)'} {formattedMessages.youPaid} {paid_amount}
        </b>
      );
      html.push(
        <Fragment key="success">
          {paymentText}
          <br />
          {formattedMessages.nameOnCC}: {cc_name} <br />
          {formattedMessages.cCLastFour}: xxxxxxxxxxxx
          {cc_number_suffix} <br />
        </Fragment>
      );
    }
    // Still needs to pay
    else {
      html.push(
        <Fragment key="due">
          {formattedMessages.paymentDueBy} {invoice.bill_dates.due_date}
          <br />
        </Fragment>
      );
    }

    if (failedPayments && failedPayments.length > 0) {
      for (const failedPayment of failedPayments) {
        const {
          paid_date,
          response_text,
          cc_name,
          cc_number_suffix,
          payment_id,
        } = failedPayment;
        html.push(
          <Fragment key={payment_id}>
            <br />
            <b className="red">
              {paid_date} {'(UTC)'} {'Payment failed'}
            </b>
            <br />
            {formattedMessages.nameOnCC}: {cc_name} <br />
            {formattedMessages.cCLastFour}: xxxxxxxxxxxx
            {cc_number_suffix} <br />
            Error: <i>{response_text}</i>
            <br />
          </Fragment>
        );
      }
    }

    return (
      <div className="invoice-header-box">
        <div className="float-right pad-medium">{html}</div>
      </div>
    );
  };

  const generateBankDetails = () => {
    let bankAddress = '';

    if (props.invoice) bankAddress = props.invoice.bankAddress;

    return (
      <div className="invoice-header-box">
        <div className="bank-address">
          <p dangerouslySetInnerHTML={{ __html: bankAddress }}></p>
        </div>
      </div>
    );
  };

  // render return
  return (
    <div>
      {generateInvoiceHeader()}
      <br />
      <div className="invoice-header-box">
        {generateCustomerHeader()}
        {generateInvoiceDates()}
      </div>
      {generateInvoiceCurrency()}
      {generateInvoiceBillPeriods()}
      <div className="invoice-header-box">
        <table width="100%">
          <tbody>
            {generateSoftwareLicenseFees()}
            {generateSetupAndTxtMeFees()}
            {generateMessageFees()}
            {generateRebates()}
            {generateBillAdjustments()}
            {generateTotalCharges()}
          </tbody>
        </table>
        <br />
        <br />
      </div>
      {generatePayment()}
      {generateBankDetails()}
    </div>
  );
};

export default SingleInvoice;
