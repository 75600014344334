import React from 'react';

const ListItem = ({
  id,
  portedNumberText = '',
  onClickPortedNumber = null,
  portedNumber = null,
}) => (
  <tr key={`list${id}`} id={id}>
    <td className="column-right" name="name">
      <span className="blue" onClick={() => onClickPortedNumber(portedNumber)}>
        {portedNumberText}
      </span>
    </td>
  </tr>
);

export default ListItem;
