/*
  FailedRequest.js - Networking for Failed SMS Items

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// CONFIG
import config from '../../../Config';

// NETWORK
import RequestRabbit from '../../RequestRabbit';
const requestRabbit = new RequestRabbit();

export default class FailedRequest {
  /*
    Gets all Failed SMS for the calling Customer User
  */
  getFailedSms = async (fromDate, toDate, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/error/customerUser?startDate=${fromDate}&endDate=${toDate}`;
      const options = {
        method: 'GET',
      };
      // Send Request
      let response = await requestRabbit.request(url, options, errorHandler);
      if (response.status === 200) {
        let responseJSON = await response.json();
        console.log('Error Log Search Result:', responseJSON);
        return responseJSON;
      }
      console.log('Got error:', response);
    } catch (e) {
      errorHandler(e);
    }
  };
  /*
    Gets Failed Items based on search criteria (if provided)
  */
  searchFailedSms = async (searchObject, errorHandler) => {
    try {
      const url = `${config.baseServerURL}/error/customerUser`;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = {
        method: 'POST',
        body: JSON.stringify(searchObject) || null,
        headers: headers,
      };

      // Request
      let response = await requestRabbit.request(url, options, errorHandler);
      let responseJSON = await response.json();

      // Response
      if (response.status > 399 && response.status < 600) {
        errorHandler(responseJSON.error);
        return [];
      }
      return responseJSON;
    } catch (e) {
      errorHandler(e);
      return [];
    }
  };
}
