/*
  List.js - Admin Device Search List

  Author: Kyle Combeer (2020)
  Company: Virtual Ark
*/

// NPM MODULES
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

// COMPONENTS
import ListItem from './ListItem';

export default class DeviceSearchList extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - devices: Array of Device objects
        - showSingleDevice: A function to handle when a Device Name is clicked
        - showSingleCustomer: A function to handle when a Customer Name is clicked
        - showSingleUserReport: A function to handle when a User's Email is clicked
        - showSingleNumber: A function to handle when a Phone Number is clicked
        - errorHandler: A function to pass errors to
    */

    this.state = {};
  }

  generateTable() {
    let htmlRows = [];
    let { devices } = this.props;

    if (!devices) return <div>Loading...</div>;
    else if (devices.length === 0) return <div>No Device Results</div>;

    for (let i = 0; i < devices.length; i++) {
      let className = '';
      let device = devices[i];

      // Set the background colour
      if (device.customer && device.customer.customer_type === 'T')
        className = 'trial-bg';
      else if (device.customer && device.customer.customer_type === 'S')
        className = 'suspended-bg';
      else if (device.user && typeof device.user !== 'undefined')
        className = 'dedicated-bg';

      htmlRows.push(
        <ListItem
          key={devices[i].device_id}
          id={devices[i].device_id}
          deviceName={devices[i].device_name}
          customer={devices[i].customer}
          user={devices[i].user}
          userEmail={devices[i].user_email}
          phoneNumber={devices[i].reply_tn}
          device={devices[i]}
          className={className}
          onClickDeviceName={(e) => this.props.showSingleDevice(e)}
          onClickCustomerName={(e) => this.props.showSingleCustomer(e)}
          onClickEmail={(e) => this.props.showSingleUserReport(e)}
          onClickNumber={this.props.showSinglePhoneNumber}
        ></ListItem>
      );
    }

    return (
      <Table className="table-new">
        <thead>
          <tr>
            <td className="medium tableTop textLeft">Device Id</td>
            <td className="medium tableTop textLeft">Device Name</td>
            <td className="medium tableTop textLeft">Customer Name</td>
            <td className="medium tableTop textLeft">Customer Status</td>
            <td className="medium tableTop textLeft">User Email Address</td>
            <td className="medium tableTop textLeft">Phone Number</td>
          </tr>
        </thead>
        <tbody>{htmlRows}</tbody>
      </Table>
    );
  }

  render() {
    return (
      <div key="DeviceSearchList" className="table">
        <h4>Results</h4>
        {this.generateTable()}
      </div>
    );
  }
}
