import React, { Component, Fragment } from 'react';

// Custom Components
import { Table } from 'react-bootstrap';
import FormattedMessage from '../FormattedMessages';

class ListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentDidMount = () => {
    //TODO - uncomment once backend data is set up
  };
  changeEvent = (o) => {
    console.log(o);
  };

  render() {
    return (
      <Fragment>
        <Table className="invoice-table">
          <thead>
            <tr>
              <td className="medium tableTop left">
                {FormattedMessage.account}
              </td>
              <td className="medium tableTop">{FormattedMessage.name}</td>
              <td className="medium tableTop">{FormattedMessage.email}</td>
              <td className="medium tableTop">{FormattedMessage.phone}</td>
              <td className="medium tableTop">{FormattedMessage.nameOnCard}</td>
              <td className="medium tableTop">{FormattedMessage.cardType}</td>
              <td className="medium tableTop">{FormattedMessage.cardNumber}</td>
              <td className="medium tableTop">{FormattedMessage.expiry}</td>
            </tr>
          </thead>
          <tbody>{this.props.list}</tbody>
        </Table>
      </Fragment>
    );
  }
}

export default ListComponent;
