/*
  FormattedMessages.js - FormattedMessages for Root Component

  Author: Julius Klaebe (2019)
  Company: Virtual Ark
*/

// NPM MODULES
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FormattedMessages = {
  back: (
    <FormattedMessage
      id="Global.BackText"
      defaultMessage="Back"
    ></FormattedMessage>
  ),
  go: <FormattedMessage id="Global.Go" defaultMessage="Go"></FormattedMessage>,
};

export default FormattedMessages;
