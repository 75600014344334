// NPM
import React, { Component } from 'react';

// STYLING
import './LoaderButton.css';

// NETWORKING
import CountryRequest from './requests/CountryRequest';

// INSTANTIATE
const countryRequest = new CountryRequest();

class CountryPicker extends Component {
  constructor(props) {
    super(props);

    /*
      Expected Props:
        - onChange: Function to handle when a choice is made on Select
        - className: A string containing valid CSS class names
        - returnDefaultCountryCode: Function to return the first Country in the list's country_code_id
        - defaultValue: Value to set as the default on the Select
    */

    this.state = {
      countryList: [],
      properDefaultValue: '',
    };
  }

  async componentDidMount() {
    let { returnDefaultCountryCode } = this.props;
    let countries = await countryRequest.getCountries();
    let properDefaultValue = '';

    for (let i = 0; i < countries.length; i++) {
      if (countries[i].country_name === 'U S A & Canada') {
        properDefaultValue = countries[i].country_code_id;
      }
    }

    // Pass back default Country Code to func
    if (returnDefaultCountryCode && properDefaultValue)
      returnDefaultCountryCode(properDefaultValue);

    return this.setState({
      countryList: countries,
      properDefaultValue: properDefaultValue,
    });
  }

  /**
   * Retrieves the country code for a country id
   * @param {string} countryId
   * @returns The country code
   */
  async getCountryCodeById(countryId) {
    let country = await countryRequest.getCountry(countryId);
    return country.country_code;
  }

  render() {
    let { countryList, properDefaultValue } = this.state;
    let { defaultValue, onChange, selectDefault } = this.props;
    let list = [];
    let startValue;

    if (selectDefault) {
      startValue = defaultValue;
      list.push(
        <option selected disabled hidden key={null} value={null}>
          {'Select One'}
        </option>
      );
    } else {
      startValue = defaultValue || properDefaultValue;
    }

    // Set options for Select
    for (let i = 0; i < countryList.length; i++) {
      list.push(
        <option
          key={countryList[i].country_code_id}
          value={countryList[i].country_code_id}
        >
          {countryList[i].country_name}
        </option>
      );
    }

    return (
      <select
        name="formCountry"
        value={startValue}
        className={this.props.className}
        onChange={(e) => {
          if (onChange) onChange(e);
        }}
      >
        {list}
      </select>
    );
  }
}

export default CountryPicker;
